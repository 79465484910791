// https://use.typekit.net/klq8acc.css

export const Fonts = `
@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff2"),url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("woff"),url("https://use.typekit.net/af/b683e3/00000000000000003b9b306c/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n9&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff2"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("woff"),url("https://use.typekit.net/af/949f99/00000000000000003b9b3068/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n7&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff2"),url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("woff"),url("https://use.typekit.net/af/4c4052/00000000000000003b9b3069/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i7&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff2"),url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("woff"),url("https://use.typekit.net/af/d82519/00000000000000003b9b306a/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n8&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff2"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("woff"),url("https://use.typekit.net/af/576d53/00000000000000003b9b3066/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n6&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff2"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("woff"),url("https://use.typekit.net/af/705e94/00000000000000003b9b3062/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n4&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff2"),url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("woff"),url("https://use.typekit.net/af/5c70f2/00000000000000003b9b3063/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=i4&v=3") format("opentype");
font-display:swap;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"ProximaNova";
src:url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/l?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff2"),url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/d?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("woff"),url("https://use.typekit.net/af/6e816b/00000000000000003b9b3064/27/a?primer=7fa3915bdafdf03041871920a205bef951d72bf64dd4c4460fb992e3ecc3a862&fvd=n5&v=3") format("opentype");
font-display:swap;font-style:normal;font-weight:500;font-stretch:normal;
}
`;

export const Fonts2 = `
@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Black.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:900;font-stretch:normal;
}

font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Black Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:900;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Extrabold.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Extrabold Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:800;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Bold.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Bold Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:700;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Semibold.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Semibold Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:600;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Regular.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Regular Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:400;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Light.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Light Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:300;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Thin.otf") format("opentype");
font-display:swap;font-style:normal;font-weight:100;font-stretch:normal;
}

@font-face {
font-family:"TestProximaNova";
src:url("/fonts/proxima_nova/Proxima Nova Thin Italic.otf") format("opentype");
font-display:swap;font-style:italic;font-weight:100;font-stretch:normal;
}
`;
