import { LineItem, LineItemProps } from '@shared/components/line-items';
import { GetModelSizesProps, getModelSizes } from './helpers';

// todo move to entities/models/ui

type Props = Omit<Partial<LineItemProps>, 'size'> & GetModelSizesProps;

export const ModelSizes = ({
    size,
    units,
    convertTo,
    label = 'Size',
    children,
    ...props
}: Props) => {
    const { formattedSizes, viewUnits } = getModelSizes({
        size,
        units,
        convertTo,
    });

    return formattedSizes ? (
        <LineItem label={label} {...props}>
            {formattedSizes} {children || viewUnits}
        </LineItem>
    ) : null;
};
