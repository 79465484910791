import { useEffect } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { FieldValues } from 'react-hook-form';
import { UseFormInitProps, useFormInit } from './use-form-init';

export interface UseFormPopupInitProps<TFieldValues extends FieldValues = FieldValues>
    extends UseFormInitProps<TFieldValues> {
    resetOnClose?: boolean;
}

export const useFormPopupInit = <TFieldValues extends FieldValues>({
    data,
    schema,
    resetOnClose = true,
    ...config
}: UseFormPopupInitProps<TFieldValues>) => {
    const formApi = useFormInit<TFieldValues>({
        data,
        schema,
        ...config,
    });
    const { reset } = formApi;
    const { onOpen, onClose, isOpen } = useDisclosure();

    useEffect(() => {
        if (!isOpen && resetOnClose) {
            setTimeout(() => reset(), 100); // wait on closing animation, todo unmount case is not processed

            // if (process.env.NODE_ENV !== 'production') {
            //     console.log('reset(isOpen)');
            // }
        }
    }, [isOpen, reset, resetOnClose]);

    return {
        onOpen,
        onClose,
        isOpen,
        ...formApi,
    };
};
