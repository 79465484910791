import { Icon, IconProps } from '@chakra-ui/react';

export const StarsIcon = (props: IconProps) => (
    <Icon viewBox="0 0 10 10" fill="none" {...props}>
        <path
            d="M5.8595 1.74984L6.8889 2.03341L7.204 2.95981L7.5191 2.03341L8.5485 1.74984L7.5191 1.46619L7.204 0.539764L6.8889 1.46619L5.8595 1.74984Z"
            fill="currentColor"
        />
        <path
            d="M3.71199 3.51292L0.24649 4.58847L3.71199 5.66403L4.77299 9.17695L5.83379 5.66403L9.299 4.58847L5.83379 3.51292L4.77299 0L3.71199 3.51292Z"
            fill="currentColor"
        />
        <path
            d="M6.3434 8.09331L7.6845 8.54028L8.095 10L8.5056 8.54028L9.847 8.09331L8.5056 7.64656L8.095 6.18661L7.6845 7.64656L6.3434 8.09331Z"
            fill="currentColor"
        />
    </Icon>
);
