import React, {
    MutableRefObject,
    ReactElement,
    ReactNode,
    RefAttributes,
    forwardRef,
    useMemo,
} from 'react';
import {
    chakraComponents,
    Select as StandardSelect,
    Props,
    CreatableSelect,
    CreatableProps,
    AsyncSelect,
    AsyncProps,
    AsyncCreatableSelect,
    AsyncCreatableProps,
    OptionBase,
    GroupBase,
    OptionsOrGroups,
    SelectInstance,
} from 'chakra-react-select';
import { HStack, Text } from '@chakra-ui/react';
import { PlusCircleIcon } from '@shared/components/icons';
import { useFieldStyles } from '../hooks';

// parts
export function selectCreateLabel(newItem: string) {
    return (
        <HStack spacing="5px" fontWeight="600" color="primary.default">
            <PlusCircleIcon />
            <Text>{`Create "${newItem}"`}</Text>
        </HStack>
    );
}

export const customizedSelectComponents = {
    LoadingIndicator: (props: any) => (
        <chakraComponents.LoadingIndicator
            spinnerSize="xs"
            speed="0.45s"
            thickness="2px"
            color="primary.default"
            emptyColor="neutral.subtle"
            {...props}
        />
    ),
};

// component
export interface OptionType<T = string> extends OptionBase {
    label: ReactNode;
    value: T;
}

export enum SelectComponent {
    Standard = 'standard',
    Creatable = 'creatable',
    Async = 'async',
    AsyncCreatable = 'async-creatable',
}

interface SelectPropsMap<
    Option extends OptionType = OptionType,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
> {
    standard: Props<Option, IsMulti, Group>;
    creatable: CreatableProps<Option, IsMulti, Group>;
    async: AsyncProps<Option, IsMulti, Group>;
    'async-creatable': AsyncCreatableProps<Option, IsMulti, Group>;
}

function isCreatable(type?: SelectComponent) {
    return type === SelectComponent.Creatable || type === SelectComponent.AsyncCreatable;
}

// type SelectComponent = keyof SelectPropsMap & string;

function getComponent(type?: SelectComponent) {
    switch (type) {
        case SelectComponent.Creatable:
            return CreatableSelect;
        case SelectComponent.Async:
            return AsyncSelect;
        case SelectComponent.AsyncCreatable:
            return AsyncCreatableSelect;
        default:
            return StandardSelect;
    }
}

function isGroups<Option extends OptionType, Group extends GroupBase<Option>>(
    options: OptionsOrGroups<Option, Group>,
): options is readonly Group[] {
    return (options as unknown as Group).options !== undefined;
}

function isOptions<Option extends OptionType, Group extends GroupBase<Option>>(
    options: OptionsOrGroups<Option, Group>,
): options is readonly Option[] {
    return (options as unknown as Group).options === undefined;
}

const getMultiValue = <Option extends OptionType, Group extends GroupBase<Option>>(
    options?: OptionsOrGroups<Option, Group>,
    values?: string[],
) => {
    if (!options || !values?.length) {
        return [];
    }

    if (isGroups(options)) {
        return options
            .map(group => group.options.filter(option => values.includes(option.value)))
            .flat();
    } else if (isOptions(options)) {
        return options.filter(option => values.includes(option.value));
    }

    return [];
};

const getSingleValue = <Option extends OptionType, Group extends GroupBase<Option>>(
    options?: OptionsOrGroups<Option, Group>,
    value?: string,
) => {
    if (!options || (!value && value !== '')) {
        return null;
    }

    if (isGroups(options)) {
        let result = null;

        for (let group of options) {
            for (let option of group.options) {
                if (value === option.value) {
                    return option;
                }
            }
        }

        return result;
    } else if (isOptions(options)) {
        return options.find(option => value === option.value) ?? null;
    }

    return null;
};

type WithEmptyOption = {
    allowedEmpty?: boolean;
    emptyValue?: null | string;
    emptyLabel?: string;
};

export type SelectProps<
    Component extends SelectComponent = SelectComponent,
    Option extends OptionType = OptionType,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
    // todo update generic with InputValueAsString boolean and override value type
> = {
    component?: Component;
    inputValueAsString?: boolean;
    variant?: 'outline' | 'pill';
} & WithEmptyOption &
    SelectPropsMap<Option, IsMulti, Group>[Component] &
    RefAttributes<SelectInstance<Option, IsMulti, Group>>;

export type SelectComponentType = <
    Component extends SelectComponent = SelectComponent,
    Option extends OptionType = OptionType,
    IsMulti extends boolean = boolean,
    Group extends GroupBase<Option> = GroupBase<Option>,
>(
    props: SelectProps<Component, Option, IsMulti, Group>,
) => ReactElement;

export const Select = forwardRef(
    <
        Option extends OptionType,
        IsMulti extends boolean,
        Group extends GroupBase<Option>,
        Component extends SelectComponent = SelectComponent,
    >(
        {
            component,
            inputValueAsString = false, // with rhf we don't want to always find and pass objects from options as value
            allowedEmpty = false,
            emptyValue = null,
            emptyLabel = '___',
            // component = SelectComponent.Standard,
            // component = 'standard' as SelectComponent,
            isMulti,
            options: _options,
            className = 'react-select-container',
            classNamePrefix = 'react-select',
            chakraStyles = {},
            value,
            ...props
        }: SelectProps<Component, Option, IsMulti, Group>,
        ref:
            | ((instance: SelectInstance<Option, IsMulti, Group> | null) => void)
            | MutableRefObject<SelectInstance<Option, IsMulti, Group> | null>
            | null,
    ) => {
        const theme = useFieldStyles('select', props);
        const size = theme?.size || 'md';
        const variant = theme?.variant || 'outline';
        const Component = getComponent(component);
        const {
            control,
            valueContainer,
            dropdownIndicator,
            indicatorSeparator,
            menu,
            menuList,
            option,
            noOptionsMessage,
            multiValue,
            multiValueLabel,
            multiValueRemove,
            loadingMessage,
            clearIndicator,
        } = chakraStyles;

        const options = useMemo(
            () =>
                allowedEmpty && _options && isOptions(_options)
                    ? ([
                          {
                              label: emptyLabel,
                              value: emptyValue,
                          },
                          ..._options,
                      ] as OptionsOrGroups<Option, Group>)
                    : _options,
            [_options, allowedEmpty, emptyLabel, emptyValue],
        );

        return (
            <Component
                {...theme}
                ref={ref}
                size={size}
                isMulti={isMulti}
                // menuIsOpen
                menuPortalTarget={document.body} // https://github.com/csandman/chakra-react-select/tree/v3#caveats
                closeMenuOnSelect={!isMulti}
                options={options}
                /* todo this transformation breaks async select (check DiscountClientsSelect example)
                    https://github.com/JedWatson/react-select/issues/3063#issuecomment-737588840
                    https://github.com/JedWatson/react-select/issues/3761#issuecomment-748267405 */
                value={
                    inputValueAsString
                        ? isMulti
                            ? getMultiValue(options, value as unknown as string[])
                            : getSingleValue(options, value as unknown as string)
                        : value
                }
                className={className}
                classNamePrefix={classNamePrefix}
                components={customizedSelectComponents}
                createOptionPosition={isCreatable(component) ? 'first' : undefined}
                formatCreateLabel={isCreatable(component) ? selectCreateLabel : undefined}
                chakraStyles={{
                    ...chakraStyles,
                    control: (provided, props) => {
                        const minH = {
                            outline: {
                                sm: 7,
                                md: 9,
                                lg: 9,
                            },
                            pill: {
                                sm: 5,
                                md: 7,
                                lg: 9,
                            },
                        }[variant][size];

                        const borderRadius = {
                            outline: 'base',
                            pill: 8,
                        }[variant];

                        const bg = {
                            outline: undefined,
                            pill: 'neutral.subtle',
                        }[variant];

                        const fonts = {
                            outline: undefined,
                            pill: { fontSize: 'xs', lineHeight: 1 },
                        }[variant];

                        return {
                            ...provided,
                            minH,
                            borderRadius,
                            bg,
                            ...fonts,
                            ...control?.(provided, props),
                        };
                    },
                    dropdownIndicator: (provided, { selectProps, ...rest }) => {
                        const paddingX = {
                            outline: {
                                sm: 3,
                                md: 3,
                                lg: 3,
                            },
                            pill: {
                                sm: 2,
                                md: 3,
                                lg: 3,
                            },
                        }[variant][size];

                        return {
                            ...provided,
                            fontSize: size,
                            backgroundColor: 'transparent',
                            paddingX,
                            cursor: 'inherit',
                            color:
                                selectProps.menuIsOpen || variant === 'pill'
                                    ? undefined
                                    : 'neutral.darkest',
                            '> svg': {
                                transition: 'transform 0.2s',
                                transform: `translateY(${
                                    selectProps.menuIsOpen ? '-1px' : 0
                                }) rotate(${selectProps.menuIsOpen ? -180 : 0}deg)`,
                            },
                            ...dropdownIndicator?.(provided, { selectProps, ...rest }),
                        };
                    },
                    clearIndicator: (provided, { selectProps, ...rest }) => ({
                        ...provided,
                        fontSize: '8px',
                        paddingRight: 2,
                        color: selectProps.menuIsOpen ? undefined : 'neutral.darkest',
                        ...clearIndicator?.(provided, { selectProps, ...rest }),
                    }),
                    indicatorSeparator: (provided, props) => ({
                        ...provided,
                        my: 2,
                        h: 'auto',
                        alignSelf: 'stretch',
                        // display: 'none',
                        ...indicatorSeparator?.(provided, props),
                    }),
                    valueContainer: (provided, props) => {
                        const px = {
                            sm: '0.5rem',
                            md: '1rem',
                            lg: '1rem',
                        }[size];
                        return {
                            ...provided,
                            padding: `0.125rem ${px}`,
                            ...valueContainer?.(provided, props),
                        };
                    },
                    menu: (provided, props) => ({
                        ...provided,
                        boxShadow: 'menu',
                        ...menu?.(provided, props),
                    }),
                    menuList: (provided, props) => {
                        const maxHeight = {
                            sm: 250,
                            md: 264,
                            lg: 264,
                        }[size];
                        return {
                            ...provided,
                            minW: 'auto',
                            maxHeight,
                            ...menuList?.(provided, props),
                        };
                    },
                    option: (provided, props) => ({
                        ...provided,
                        p: '2 4',
                        ...option?.(provided, props),
                    }),
                    noOptionsMessage: (provided, props) => ({
                        ...provided,
                        textStyle: 'typography-sm',
                        fontSize: 'sm',
                        color: 'font.primary',
                        ...noOptionsMessage?.(provided, props),
                    }),
                    multiValue: (provided, props) => ({
                        ...provided,
                        layerStyle: 'defaultBorder',
                        ...multiValue?.(provided, props),
                    }),
                    multiValueLabel: (provided, props) => ({
                        ...provided,
                        fontWeight: '600',
                        ...multiValueLabel?.(provided, props),
                    }),
                    multiValueRemove: (provided, props) => {
                        return {
                            ...provided,
                            display: props.isDisabled && 'none',
                            ...multiValueRemove?.(provided, props),
                        };
                    },
                }}
                {...props}
                styles={{
                    ...props?.styles,
                    menuPortal: provided => ({ ...provided, zIndex: 1500 }),
                }}
            />
        );
    },
) as SelectComponentType;

// utils
export const objectToSelectOptions = (obj: Record<string, string>) =>
    Object.keys(obj).map(value => ({
        label: obj[value],
        value,
    }));
