import axios from 'axios';
import partial from 'lodash/partial';
import { buildUrl } from '@shared/utils/strings';

export const baseURL = process.env.REACT_APP_DF_API_BASE_URL ?? window.location.origin;

export const getBackendUrl = partial(buildUrl('/'), baseURL);
export const getMediaUrl = partial(buildUrl(), baseURL);

export const axiosInstance = axios.create({
    baseURL,
    headers: {
        accept: 'application/json',
        'Accept-Language': 'en',
    },
});
