import { Link as RoterDomLink, LinkProps } from 'react-router-dom';
import { Link } from '@chakra-ui/react';
import { FCC } from '@shared/types';

interface RouterLinkProps extends LinkProps {
    variant: string;
}

/**
 * If you see <Link as={RouterLink} ... /> and it has relative to path, replace it with this component <RouterLink ... />
 */

export const RouterLink: FCC<RouterLinkProps> = ({ variant, children, ...props }) => {
    return (
        <Link as={RoterDomLink} variant={variant} {...props}>
            {children}
        </Link>
    );
};
