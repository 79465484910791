import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        discountConditionsRetrieve: build.query<
            DiscountConditionsRetrieveApiResponse,
            DiscountConditionsRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/discount_conditions/${queryArg.id}/` }),
        }),
        discountConditionsPartialUpdate: build.mutation<
            DiscountConditionsPartialUpdateApiResponse,
            DiscountConditionsPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/discount_conditions/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDiscountConditionRequest,
            }),
        }),
        discountConditionsDestroy: build.mutation<
            DiscountConditionsDestroyApiResponse,
            DiscountConditionsDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/discount_conditions/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        discountsList: build.query<DiscountsListApiResponse, DiscountsListApiArg>({
            query: queryArg => ({
                url: `/v2/discounts/`,
                params: {
                    active: queryArg.active,
                    description_for_user: queryArg.descriptionForUser,
                    id: queryArg.id,
                    limit: queryArg.limit,
                    name: queryArg.name,
                    name_for_user: queryArg.nameForUser,
                    offset: queryArg.offset,
                    show_in_widget: queryArg.showInWidget,
                    type: queryArg['type'],
                },
            }),
        }),
        discountsCreate: build.mutation<DiscountsCreateApiResponse, DiscountsCreateApiArg>({
            query: queryArg => ({
                url: `/v2/discounts/`,
                method: 'POST',
                body: queryArg.discountSerializerV2Request,
            }),
        }),
        discountsRetrieve: build.query<DiscountsRetrieveApiResponse, DiscountsRetrieveApiArg>({
            query: queryArg => ({ url: `/v2/discounts/${queryArg.id}/` }),
        }),
        discountsPartialUpdate: build.mutation<
            DiscountsPartialUpdateApiResponse,
            DiscountsPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/discounts/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedDiscountSerializerV2Request,
            }),
        }),
        discountsDestroy: build.mutation<DiscountsDestroyApiResponse, DiscountsDestroyApiArg>({
            query: queryArg => ({ url: `/v2/discounts/${queryArg.id}/`, method: 'DELETE' }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type DiscountConditionsRetrieveApiResponse = /** status 200  */ DiscountCondition;
export type DiscountConditionsRetrieveApiArg = {
    id: number;
};
export type DiscountConditionsPartialUpdateApiResponse = /** status 200  */ DiscountCondition;
export type DiscountConditionsPartialUpdateApiArg = {
    id: number;
    patchedDiscountConditionRequest: PatchedDiscountConditionRequest;
};
export type DiscountConditionsDestroyApiResponse = unknown;
export type DiscountConditionsDestroyApiArg = {
    id: number;
};
export type DiscountsListApiResponse = /** status 200  */ PaginatedDiscountSerializerV2List;
export type DiscountsListApiArg = {
    active?: boolean;
    descriptionForUser?: string;
    id?: number;
    /** Number of results to return per page. */
    limit?: number;
    name?: string;
    nameForUser?: string;
    /** The initial index from which to return the results. */
    offset?: number;
    showInWidget?: boolean;
    /** * `promo` - Promo discount
     * `client` - Client discount
     * `order` - Order discount
     * `batch` - Batch discount
     * `hourly` - Hourly discount
     * `sq_cm_of_material` - 2d cutting material discount */
    type?: 'batch' | 'client' | 'hourly' | 'order' | 'promo' | 'sq_cm_of_material';
};
export type DiscountsCreateApiResponse = /** status 201  */ DiscountSerializerV2;
export type DiscountsCreateApiArg = {
    discountSerializerV2Request: DiscountSerializerV2Request;
};
export type DiscountsRetrieveApiResponse = /** status 200  */ DiscountSerializerV2;
export type DiscountsRetrieveApiArg = {
    id: number;
};
export type DiscountsPartialUpdateApiResponse = /** status 200  */ DiscountSerializerV2;
export type DiscountsPartialUpdateApiArg = {
    id: number;
    patchedDiscountSerializerV2Request: PatchedDiscountSerializerV2Request;
};
export type DiscountsDestroyApiResponse = unknown;
export type DiscountsDestroyApiArg = {
    id: number;
};
export type DiscountCondition = {
    id: number;
    discount_value: number;
    condition_value?: number;
    code?: string;
};
export type PatchedDiscountConditionRequest = {
    discount_value?: number;
    condition_value?: number;
    code?: string;
};
export type DiscountTypeEnum =
    | 'promo'
    | 'client'
    | 'order'
    | 'batch'
    | 'hourly'
    | 'sq_cm_of_material';
export type DiscountSerializerV2 = {
    id: number;
    conditions: DiscountCondition[];
    active?: boolean;
    type: DiscountTypeEnum;
    name: string;
    show_in_widget?: boolean;
    name_for_user?: string;
    description_for_user?: string;
    clients?: number[];
    materials?: number[];
};
export type PaginatedDiscountSerializerV2List = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: DiscountSerializerV2[];
};
export type DiscountConditionRequest = {
    discount_value: number;
    condition_value?: number;
    code?: string;
};
export type DiscountSerializerV2Request = {
    conditions: DiscountConditionRequest[];
    active?: boolean;
    type: DiscountTypeEnum;
    name: string;
    show_in_widget?: boolean;
    name_for_user?: string;
    description_for_user?: string;
    clients?: number[];
    materials?: number[];
};
export type PatchedDiscountSerializerV2Request = {
    conditions?: DiscountConditionRequest[];
    active?: boolean;
    type?: DiscountTypeEnum;
    name?: string;
    show_in_widget?: boolean;
    name_for_user?: string;
    description_for_user?: string;
    clients?: number[];
    materials?: number[];
};
export const {
    useDiscountConditionsRetrieveQuery,
    useDiscountConditionsPartialUpdateMutation,
    useDiscountConditionsDestroyMutation,
    useDiscountsListQuery,
    useDiscountsCreateMutation,
    useDiscountsRetrieveQuery,
    useDiscountsPartialUpdateMutation,
    useDiscountsDestroyMutation,
} = injectedRtkApi;
