import { ReactNode } from 'react';
import { Grid, GridProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

export interface UntitledBoxProps extends GridProps {
    action?: ReactNode;
}

export const UntitledBox = forwardRef<UntitledBoxProps, 'div'>(
    ({ action, children, ...rest }, ref) => (
        <Grid
            ref={ref}
            layerStyle="lightGrayBox"
            // boxShadow: 'inset 0px 4px 4px 0px #E4E9F0',
            alignItems="center"
            gap={4}
            gridTemplateColumns={action ? '1fr auto' : undefined}
            {...rest}
        >
            {children}
            {action}
        </Grid>
    ),
);
