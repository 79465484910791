import * as Yup from 'yup';
import { useEffect, useMemo } from 'react';
import { useForm, UseFormProps, FieldValues } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

export interface UseFormInitProps<TFieldValues extends FieldValues = FieldValues>
    extends Omit<UseFormProps<TFieldValues>, 'defaultValues' | 'resolver'> {
    data?: TFieldValues; // default values
    schema: Yup.AnyObjectSchema;
    stripUnknown?: boolean;
    overwriteDefaultValues?: (data: TFieldValues) => any;
}

export const useFormInit = <TFieldValues extends FieldValues>({
    data,
    schema,
    overwriteDefaultValues,
    stripUnknown = true,
    ...config
}: UseFormInitProps<TFieldValues>) => {
    const defaultValues = useMemo(() => {
        const initial =
            data && overwriteDefaultValues ? { ...data, ...overwriteDefaultValues(data) } : data;

        // const cast = initial
        //     ? schema.cast(initial, { stripUnknown, assert: false })
        //     : schema.getDefault();
        return initial ? schema.cast(initial, { stripUnknown }) : schema.getDefault();
    }, [schema, stripUnknown, data, overwriteDefaultValues]);

    const formApi = useForm<TFieldValues>({
        defaultValues,
        resolver: yupResolver(schema),
        ...config,
    });
    const { reset } = formApi;

    // https://react-hook-form.com/docs/useform/reset
    useEffect(() => {
        reset(defaultValues);
        // if (process.env.NODE_ENV !== 'production') {
        //     console.log('reset(defaultValues)');
        // }
    }, [defaultValues, reset]);

    return formApi;
};
