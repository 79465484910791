/*
    Based on codegen.ts
*/

import {
    enhancedApi as api,
    BlankEnum,
    MachineGroup,
    ComplexityBracketing,
    Feature,
    FeatureRequest,
    PatchedFeatureRequest,
    PatchedPriorityRequest,
    PatchedToleranceRequest,
} from '../codegen';
import { getMachineSlugByTechnology } from '../helpers';
import type {
    MachineMilling3,
    Machine3Dp,
    MachineCutter,
    MachineSheetMetal,
    MachineFdm,
    MachineMjf,
    MachineMilling5,
    MachineMultijet,
    MachinePolyjet,
    MachineSla,
    MachineSlm,
    MachineSls,
    MachineTurning,
    MachineMilling3Request,
    Machine3DpRequest,
    MachineCutterRequest,
    MachineSheetMetalRequest,
    MachineFdmRequest,
    MachineMjfRequest,
    MachineMilling5Request,
    MachineMultijetRequest,
    MachinePolyjetRequest,
    MachineSlaRequest,
    MachineSlmRequest,
    MachineSlsRequest,
    MachineTurningRequest,
    ComplexityBracketingRequest,
    PriorityRequest,
    ToleranceRequest,
    Priority,
    Tolerance,
} from './codegen';

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        machinesList: build.query<MachinesListApiResponse, MachinesListApiArg>({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/`,
            }),
        }),
        machinesUpdate: build.mutation<MachinesUpdateApiResponse, MachinesUpdateApiArg>({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/`,
                method: 'PUT',
                body: queryArg.machineRequest,
            }),
        }),
        machinesRetrieve: build.query<MachinesRetrieveApiResponse, MachinesRetrieveApiArg>({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
            }),
        }),
        machinesPartialUpdate: build.mutation<
            MachinesPartialUpdateApiResponse,
            MachinesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
                method: 'PATCH',
                body: queryArg.patchedMachineRequest,
            }),
        }),
        machinesDestroy: build.mutation<MachinesDestroyApiResponse, MachinesDestroyApiArg>({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
                method: 'DELETE',
            }),
        }),
        machinesDuplicateCreate: build.mutation<
            MachinesDuplicateCreateApiResponse,
            MachinesDuplicateCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/duplicate/`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
// export { injectedRtkApi as enhancedMachinesApi };

export type MachinesListApiResponse = /** status 200  */ Machine[];
export type MachinesListApiArg = {
    technologyId: number;
};
export type MachinesUpdateApiResponse = /** status 200  */ Machine;
export type MachinesUpdateApiArg = {
    technologyId: number;
    machineRequest: MachineRequest;
};
export type MachinesRetrieveApiResponse = /** status 200  */ Machine;
export type MachinesRetrieveApiArg = {
    id: number;
    technologyId: number;
};
export type MachinesPartialUpdateApiResponse = /** status 200  */ Machine;
export type MachinesPartialUpdateApiArg = {
    id: number;
    technologyId: number;
    patchedMachineRequest: PatchedMachineRequest;
};
export type MachinesDestroyApiResponse = unknown;
export type MachinesDestroyApiArg = {
    id: number;
    technologyId: number;
};
export type MachinesDuplicateCreateApiResponse = /** status 200  */ MachineGroup;
export type MachinesDuplicateCreateApiArg = {
    id: number;
    technologyId: number;
};

export type MachineCombined_ = MachineMilling3 &
    Machine3Dp &
    MachineCutter &
    MachineSheetMetal &
    MachineFdm &
    MachineMjf &
    MachineMilling5 &
    MachineMultijet &
    MachinePolyjet &
    MachineSla &
    MachineSlm &
    MachineSls &
    MachineTurning;

export type MachineRequestCombined_ = MachineMilling3Request &
    Machine3DpRequest &
    MachineCutterRequest &
    MachineSheetMetalRequest &
    MachineFdmRequest &
    MachineMjfRequest &
    MachineMilling5Request &
    MachineMultijetRequest &
    MachinePolyjetRequest &
    MachineSlaRequest &
    MachineSlmRequest &
    MachineSlsRequest &
    MachineTurningRequest;

export type Machine = Omit<
    MachineCombined_,
    'features' | 'priorities' | 'tolerances' | 'bracketing'
> & {
    features?: Feature[];
    priorities?: Priority[];
    tolerances?: Tolerance[];
    bracketing?: ComplexityBracketing;
};

export type MachineRequest = Omit<
    MachineRequestCombined_,
    'features' | 'priorities' | 'tolerances' | 'bracketing'
> & {
    features?: FeatureRequest[];
    priorities?: PriorityRequest[];
    tolerances?: ToleranceRequest[];
    bracketing?: ComplexityBracketingRequest;
};

export type PatchedMachineRequest = Partial<
    Omit<MachineRequestCombined_, 'features' | 'priorities' | 'tolerances' | 'bracketing'>
> & {
    features?: PatchedFeatureRequest[];
    priorities?: PatchedPriorityRequest[];
    tolerances?: PatchedToleranceRequest[];
    bracketing?: ComplexityBracketingRequest;
};

export const {
    useMachinesListQuery,
    useMachinesUpdateMutation,
    useMachinesRetrieveQuery,
    useMachinesPartialUpdateMutation,
    useMachinesDestroyMutation,
    useMachinesDuplicateCreateMutation,
} = injectedRtkApi;
