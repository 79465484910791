import { useEffect, useState } from 'react';

function getScript(url: string) {
    return document.querySelector(`script[src="${url}"]`) as HTMLScriptElement | null;
}

interface UseScriptInjection {
    url: HTMLScriptElement['src'];
    integrity?: HTMLScriptElement['integrity'];
    async?: HTMLScriptElement['async'];
    crossOrigin?: HTMLScriptElement['crossOrigin'];
    removeOnUnmount: boolean;
    placeInjectionCallback: (script: HTMLScriptElement) => void;
}

export const useScriptInjection = ({
    url,
    integrity,
    async = true,
    crossOrigin = 'anonymous',
    removeOnUnmount,
    placeInjectionCallback,
}: UseScriptInjection) => {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const existScript = getScript(url);

        if (Boolean(existScript)) {
            setIsLoaded(true);
            return;
        }

        const scriptElement = document.createElement('script');

        scriptElement.src = url;
        scriptElement.async = async;
        scriptElement.crossOrigin = crossOrigin;
        if (integrity) {
            scriptElement.integrity = integrity;
        }

        const fn = () => setIsLoaded(true);

        scriptElement.addEventListener('load', fn);

        placeInjectionCallback(scriptElement);

        return () => {
            if (removeOnUnmount) {
                scriptElement.removeEventListener('load', fn);
                scriptElement.remove();
            }
        };
    }, [url, async, crossOrigin, integrity, removeOnUnmount, placeInjectionCallback]);

    return {
        isLoaded,
    };
};
