import { useMemo } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectCurrency } from '@ducks/app';
import { getSingleFieldConfig } from '@shared/components/forms-v2';
import { PurchaseFieldsUnion, getPurchaseConfig } from './config';

export interface UsePurchaseFieldProps {
    name: PurchaseFieldsUnion;
    value?: string | number | null;
}

export const usePurchaseField = ({ name, value }: UsePurchaseFieldProps) => {
    const currency = useAppSelector(selectCurrency)!;

    return useMemo(
        () =>
            getSingleFieldConfig(
                getPurchaseConfig({ currencySign: currency.symbol }).fields[name],
                value,
            ),
        [currency, name, value],
    );
};
