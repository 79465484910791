import { ComponentProps } from 'react';
import { compareElementsFunction } from '@shared/utils/arrays';
import { MultipleCheckboxes } from '../inputs';
import { withControllableField } from './field';

export const MultipleCheckboxesField = withControllableField(MultipleCheckboxes, {
    isBooleanGroup: true,
    // we have to sort the values for proper drf dirty check behavior
    transformInput: values => values?.sort(compareElementsFunction),
    transformOutput: values => values?.sort(compareElementsFunction),
});

export type MultipleCheckboxesFieldProps = ComponentProps<typeof MultipleCheckboxesField>;
