import { Collapse, Flex, FlexProps, Portal } from '@chakra-ui/react';
import { FCC } from '@shared/types';

interface Props extends Omit<FlexProps, 'onSubmit'> {
    in: boolean;
}

export const FullPageBanner: FCC<Props> = ({ in: isOpen, children, ...props }) => {
    return (
        <Portal>
            <Collapse in={isOpen} animateOpacity>
                <Flex
                    zIndex="banner"
                    position="fixed"
                    py={3.5}
                    px={7}
                    bottom={0}
                    left={0}
                    right={0}
                    bg="primary.default"
                    justifyContent="flex-end"
                    {...props}
                >
                    {children}
                </Flex>
            </Collapse>
        </Portal>
    );
};
