import kebabCase from 'lodash/kebabCase';
import { useCallback, useEffect, useState } from 'react';

type Tabs = ReadonlyArray<string>;

interface Props {
    defaultValue?: number;
    tabs: Tabs;
}

export function useHashTabs({ defaultValue = 0, tabs }: Props) {
    const [tabIndex, _setTabIndex] = useState(defaultValue);

    const setTabIndex = useCallback(
        (index: number) => {
            window.location.hash = kebabCase(tabs[index]);
            _setTabIndex(index);
        },
        [tabs],
    );

    useEffect(() => {
        const hash = decodeURIComponent(window.location.hash).replace('#', '');

        tabs.forEach((tab, index) => {
            if (kebabCase(tab) === kebabCase(hash)) {
                _setTabIndex(index);
            }
        });
    }, [tabIndex, tabs]);

    return [tabIndex, setTabIndex] as const;
}
