import invariant from 'tiny-invariant';
import { LinkProps, useMatch, useResolvedPath } from 'react-router-dom';

export const isAbsoluteUrl = (url: string) => /^[a-z][a-z0-9+.-]*:/.test(url);

export function isValidUrl(url: string) {
    try {
        return Boolean(new URL(url));
    } catch (_) {
        return false;
    }
}

export function castPathId(id?: string) {
    invariant(!!id, 'Id must be present');

    const idAsInt = parseInt(id);
    invariant(!isNaN(idAsInt), 'Id must be numeric');

    return idAsInt;
}

// https://github.com/remix-run/react-router/blob/main/examples/custom-link/src/App.tsx
export function useActiveRoute({ to }: Pick<LinkProps, 'to' | 'state'>) {
    const resolved = useResolvedPath(to);
    const isActive = Boolean(useMatch({ path: resolved.pathname, end: true }));

    return { isActive };
}
