import { createSlice, createSelector } from '@reduxjs/toolkit';
import type {
    ActionReducerMapBuilder,
    PayloadAction,
    SliceCaseReducers,
    ValidateSliceCaseReducers,
} from '@reduxjs/toolkit';
import { RowSelectionState } from '@tanstack/react-table';
import type { TableColumnConfig } from '@shared/components/react-table';
import { RootState } from '@app/types';

// todo move to shared/components/react-table/helpers

// https://redux-toolkit.js.org/usage/usage-with-typescript#wrapping-createslice

interface ReactTableSliceState {
    columnsConfig?: TableColumnConfig[];
    rowSelection: RowSelectionState;
}

export const createReactTableSlice = <
    S extends object,
    Reducers extends SliceCaseReducers<ReactTableSliceState & S>,
>({
    namespace,
    initialState,
    reducers,
    extraReducers,
}: {
    namespace: string;
    initialState: ReactTableSliceState & S;
    reducers: ValidateSliceCaseReducers<ReactTableSliceState & S, Reducers>;
    extraReducers?: (builder: ActionReducerMapBuilder<ReactTableSliceState & S>) => void;
}) => {
    const name = `${namespace}/table`;

    return createSlice({
        name,
        initialState,
        reducers: {
            setColumnsConfig(state, action: PayloadAction<TableColumnConfig[]>) {
                state.columnsConfig = action.payload;
            },
            setRowSelection(state, action: PayloadAction<RowSelectionState>) {
                state.rowSelection = action.payload;
            },
            ...reducers,
        },
        extraReducers,
        selectors: {
            selectColumnsConfig: state => state.columnsConfig,
            selectRowSelection: state => state.rowSelection,
            // selectTodoById: (state, todoId) => state.todos.find(todo => todo.id === todoId),
        },
    });
};

// https://github.com/reduxjs/redux-toolkit/discussions/3387
export const getReactTableSelectors = <Slice extends ReturnType<typeof createReactTableSlice>>(
    slice: Slice,
    selectState: (rootState: RootState) => ReturnType<Slice['selectSlice']>,
) => {
    const basicSelectors = slice.getSelectors(selectState);
    // const basicSelectors = slice.selectors;

    const selectColumnsOrder = createSelector(basicSelectors.selectColumnsConfig, columnsConfig =>
        columnsConfig?.map(config => config.id),
    );
    const selectColumnsVisibility = createSelector(
        basicSelectors.selectColumnsConfig,
        columnsConfig =>
            columnsConfig?.reduce(
                (acc, { id, checked }) => ({
                    ...acc,
                    [id!]: checked,
                }),
                {},
            ),
    );

    return {
        ...basicSelectors,
        selectColumnsOrder,
        selectColumnsVisibility,
    };
};

// const wrappedSlice = createReactTableSlice({
//     namespace: 'test',
//     initialState: { rowSelection: {}, status: 'loading' },
//     reducers: {
//         magic(state) {
//             state.status = 'finished';
//         },
//     },
// });
