import { Icon, IconProps } from '@chakra-ui/react';

export const DragHandleIcon = (props: IconProps) => (
    <Icon viewBox="0 0 5 8" fill="none" {...props}>
        <path
            d="M0 1C0 0.447715 0.447715 0 1 0C1.55228 0 2 0.447715 2 1C2 1.55228 1.55228 2 1 2C0.447715 2 0 1.55228 0 1Z"
            fill="currentColor"
        />
        <path
            d="M3 1C3 0.447715 3.44772 0 4 0C4.55228 0 5 0.447715 5 1C5 1.55228 4.55228 2 4 2C3.44772 2 3 1.55228 3 1Z"
            fill="currentColor"
        />
        <path
            d="M0 4C0 3.44772 0.447715 3 1 3C1.55228 3 2 3.44772 2 4C2 4.55228 1.55228 5 1 5C0.447715 5 0 4.55228 0 4Z"
            fill="currentColor"
        />
        <path
            d="M3 4C3 3.44772 3.44772 3 4 3C4.55228 3 5 3.44772 5 4C5 4.55228 4.55228 5 4 5C3.44772 5 3 4.55228 3 4Z"
            fill="currentColor"
        />
        <path
            d="M0 7C0 6.44772 0.447715 6 1 6C1.55228 6 2 6.44772 2 7C2 7.55228 1.55228 8 1 8C0.447715 8 0 7.55228 0 7Z"
            fill="currentColor"
        />
        <path
            d="M3 7C3 6.44772 3.44772 6 4 6C4.55228 6 5 6.44772 5 7C5 7.55228 4.55228 8 4 8C3.44772 8 3 7.55228 3 7Z"
            fill="currentColor"
        />
    </Icon>
);
