import { PayloadAction } from '@reduxjs/toolkit';
import { TechnologyIdsUnion } from '@shared/constants';
import { MaterialEntityNameUnion, MaterialUnion, Nullable } from '@shared/types';

export interface InitialState {
    currentMaterial: Nullable<MaterialUnion>;
    technologies: any[];
    isLoadingMaterial: boolean;
    isLoadingTechnologies: boolean;
    isFinishedEditMaterial: boolean;
    isFinishedEditMaterialEntities: boolean;
    isLoadingEditMaterialEntities: boolean;
}

export type FetchTechnologiesSuccessAction = PayloadAction<any[]>;
export type FetchMaterialAction = PayloadAction<{
    materialId: number | string;
    technologyId: TechnologyIdsUnion;
}>;
export type FetchMaterialSuccessAction = PayloadAction<MaterialUnion>;

export type EditMaterialAction = PayloadAction<{
    data: Partial<MaterialUnion>;
    materialId: number;
    hasNotification?: boolean;
    technologyId: TechnologyIdsUnion;
}>;

export type EditMaterialSuccessAction = PayloadAction<{
    data: Partial<MaterialUnion>;
}>;

export type DeleteMaterialEntitySuccessAction = PayloadAction<{
    id: number | string;
    entity: MaterialEntityNameUnion;
}>;

export type CreateMaterialEntitySuccessAction = PayloadAction<{
    data: any;
    entity: MaterialEntityNameUnion;
}>;

export type EditMaterialEntitySuccessAction = PayloadAction<{
    id: string | number;
    data: any;
    entity: MaterialEntityNameUnion;
}>;

export type StartEditMaterialEntitiesAction = PayloadAction<{
    materialId: number;
    queueEdition: any[];
    queueDeletion: any[];
    queueCreation: any[];
    entity: MaterialEntityNameUnion;
    hasNotification?: boolean;
}>;

export const additionalBendFields = [
    'time_increment_weight_minutes_press_brake',
    'weight_increment_kg_press_brake',
    'time_increment_length_minutes_press_brake',
    'length_increment_mm_press_brake',
];
