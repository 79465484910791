import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';

const variantHighlighted: SystemStyleFunction = props => {
    const { colorScheme: c } = props;

    return {
        color: mode(`${c}.default`, `${c}.subtle`)(props),
        textDecoration: 'none',
        _hover: {
            color: mode(`${c}.accent`, `${c}.subtlest`)(props),
            textDecoration: 'none',
        },
        _active: {
            color: mode(`${c}.subtle`, `${c}.default`)(props),
        },
    } as const;
};

const variantUnderlined: SystemStyleFunction = props => {
    const { colorScheme: c } = props;

    return {
        color: mode(`${c}.default`, `${c}.subtle`)(props),
        textDecoration: 'underline',
        _hover: {
            color: mode(`${c}.accent`, `${c}.subtlest`)(props),
            textDecoration: 'underline',
        },
        _active: {
            color: mode(`${c}.subtle`, `${c}.default`)(props),
        },
    } as const;
};

export const LinkStyles = {
    baseStyle: {} as const,
    variants: {
        highlighted: variantHighlighted,
        underlined: variantUnderlined,
    } as const,
    defaultProps: {
        colorScheme: 'primary',
    } as const,
};
