import { axiosInstance } from './instance';
import { ComplexityBracketingParams, MachinesMaterials } from '@shared/types';

export class MachineMaterialsService {
    static init() {
        return new MachineMaterialsService();
    }

    public fetchMachinesMaterials() {
        return axiosInstance.get<MachinesMaterials[]>(`/v2/machines_materials/`);
    }

    public fetchListTechnologies() {
        return axiosInstance.get(`/v2/admin/technologies/`);
    }

    public fetchComplexityBracketing(params?: ComplexityBracketingParams) {
        // fetch sample
        return axiosInstance.get('/v2/sample_models/', {
            params,
        });
    }
}
