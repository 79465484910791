import { Icon, IconProps } from '@chakra-ui/react';

export const MinimizeIcon = (props: IconProps) => (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path d="M2 7H5V10" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M10 5H7V2" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M7 5L10.5 1.5"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M1.5 10.5L5 7"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
