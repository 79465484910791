import { Fonts, Fonts2 } from '@assets/fonts/fonts';
import { Global } from '@emotion/react';
import { CKEditorStyles } from '@shared/components/ckeditor';

// const Base = `
//     html {
//          margin-right: calc(100% - 100vw);
//     }
//     body {
//          scroll-behavior: smooth;
//     }
// `;

// todo remove #page-wrapper after migrating from django app

const Base = `
body {
     scroll-behavior: smooth;
}

body label {
    margin-bottom: 0px;
    font-size: 0.75rem;
    font-family: 'ProximaNova';
}

#root {
    min-height: 100vh;
}

#page-wrapper {
  padding: 0;
  min-height: 0;
}

b.chakra-text {
  fontWeight: 600;
}
`;

const FocusVisible = `
 .js-focus-visible :focus:not([data-focus-visible-added]) {
    outline: none;
    box-shadow: none;
}
.js-focus-visible[data-checked] {
    outline: none;
    box-shadow: none;
}

.js-focus-visible :focus:not(.focus-visible),
.js-focus-visible :focus:not(.focus-visible) + [data-focus] {
  outline: none;
  box-shadow: none;
}
`;

const ColorInput = `
input[type="color"]::-moz-color-swatch {
  border-radius: 50%;
  border-style: none;
}

input[type=color]::-webkit-color-swatch {
  border: none;
  border-radius: 50%;
  padding: 0;
}

input[type=color]::-webkit-color-swatch-wrapper {
    border: none;
    border-radius: 50%;
    padding: 0;
}
`;

// todo, these styles should be placed in the viewer package
const Viewer = `
.visualizer {
  width: 100%;
  height: 100%;
  outline: none;
  @include rmd-theme(background-color, background);
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
}

.visualizer > canvas {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;

export const Globally = () => (
    <Global
        styles={[
            Base,
            // remote font files
            Fonts,
            // local font files
            // Fonts2,
            FocusVisible,
            ColorInput,
            CKEditorStyles,
            Viewer,
        ].join('')}
    />
);
