import { combineReducers } from '@reduxjs/toolkit';
import { configReducer } from './config';
import { modelsSlice } from './models';
import { metadataReducer } from './metadata';

export * from './constants';
export * from './config';
export * from './helpers';
export * from './models';
export * from './metadata';

// reset with createReducer
export const priceMatchReducer = combineReducers({
    config: configReducer,
    models: modelsSlice.reducer,
    metadata: metadataReducer,
});
