import { MutableRefObject, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import isUndefined from 'lodash/isUndefined';
import { format } from 'date-fns';
import {
    OneOrTwoDates,
    getNewSelectedDates,
    RangeDatepicker,
    parseDate,
    convertDateRange,
} from '@shared/components/datepicker';
import { Filter, FilterTriggerButton } from '@shared/components/common-filters';
import { PlainPopover } from '@shared/components/common-popovers';
import { CalendarIcon } from '@shared/components/icons';
import { useUpdateEffect } from '@shared/hooks';
import { DATE_QUERY_PARAMS_FORMAT } from '@shared/constants';
import { getNodeIdComposer } from '@shared/utils/strings';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { CurrentPageParam, PaginationQueryParams } from '../pagination';

interface DateFilterProps {
    filterId: string;
    buttonLabel?: string;
    dateFromKey: string;
    dateToKey: string;
    popoverPortalRef?: MutableRefObject<HTMLElement | null>;
}

export const DateFilter = ({
    filterId,
    dateFromKey,
    dateToKey,
    buttonLabel = 'Date',
    popoverPortalRef,
}: DateFilterProps) => {
    const [query, setQuery] = useQueryParams({
        [dateFromKey]: withDefault(StringParam, undefined),
        [dateToKey]: withDefault(StringParam, undefined),
        [PaginationQueryParams.PageNumber]: CurrentPageParam,
    });
    const dateFrom = query[dateFromKey] ? String(query[dateFromKey]) : undefined;
    const dateTo = query[dateToKey] ? String(query[dateToKey]) : undefined;
    const [selectedDates, setSelectedDates] = useState<OneOrTwoDates | undefined>(
        convertDateRange({ dateFrom, dateTo }),
    );

    useUpdateEffect(() => {
        if (isUndefined(dateFrom) && isUndefined(dateTo)) {
            setSelectedDates(undefined);
        }
    }, [dateFrom, dateTo]);

    const handleApply = () => {
        const [firstDate, secondDate] = selectedDates!;
        setQuery({
            page: undefined,
            [dateFromKey]: format(firstDate!, DATE_QUERY_PARAMS_FORMAT),
            ...(secondDate
                ? { [dateToKey]: format(secondDate!, DATE_QUERY_PARAMS_FORMAT) }
                : { [dateToKey]: format(firstDate!, DATE_QUERY_PARAMS_FORMAT) }),
        });
    };

    const handleClose = () => {
        setSelectedDates(convertDateRange({ dateFrom, dateTo }));
    };

    const handleButtonClick = (newSelectedDates: Readonly<[Date, Date]>) => {
        const [firstDate, secondDate] = newSelectedDates;
        setSelectedDates(undefined);
        setSelectedDates(dates => getNewSelectedDates(dates as OneOrTwoDates, firstDate));
        setSelectedDates(dates => getNewSelectedDates(dates as OneOrTwoDates, secondDate));
    };

    const isDateActive = !isEmpty(dateFrom) || !isEmpty(dateTo);
    const popoverId = getNodeIdComposer(filterId, 'popover');

    return (
        <Filter
            isActive={isDateActive}
            onReset={() => {
                setQuery({
                    [dateFromKey]: undefined,
                    [dateToKey]: undefined,
                    page: undefined,
                });
            }}
        >
            <PlainPopover
                popoverId={popoverId()}
                header="Date range"
                triggerNode={
                    <FilterTriggerButton
                        id={popoverId('trigger')}
                        icon={<CalendarIcon />}
                        isActive={isDateActive}
                    >
                        <span>{buttonLabel}</span>
                    </FilterTriggerButton>
                }
                onConfirm={handleApply}
                buttonIsDisabled={!selectedDates}
                onClose={handleClose}
                popoverPortalRef={popoverPortalRef}
            >
                <RangeDatepicker
                    selectedDates={selectedDates}
                    onChangeDate={setSelectedDates}
                    onButtonClick={handleButtonClick}
                    defaultDate={parseDate(dateFrom)}
                />
            </PlainPopover>
        </Filter>
    );
};
