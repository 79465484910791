import { combineReducers } from '@reduxjs/toolkit';
import { getDocumentReducer } from './get-document';
import { purchasesSlice } from './purchases';
import { ordersModelsSlice } from './models';

export * from './get-document';
export * from './purchases';
export * from './models';

export const orderReducer = combineReducers({
    getDocument: getDocumentReducer,
    purchases: purchasesSlice.reducer,
    models: ordersModelsSlice.reducer,
});
