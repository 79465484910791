import { ValuesType } from '@shared/types';

export const UserRoleTypes = {
    CompanyOwner: 'company_owner',
    BlockedClient: 'blocked_client',
    Vendor: 'vendor',
    Salesman: 'salesman',
    SandboxOwner: 'sandbox_owner',
} as const;

export type UserRole = ValuesType<typeof UserRoleTypes>;
export type UserRoles = Array<UserRole>;
