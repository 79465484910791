import { Icon, IconProps } from '@chakra-ui/react';

export const BellIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M12 5.33325C12 4.27239 11.5786 3.25497 10.8284 2.50482C10.0783 1.75468 9.06087 1.33325 8 1.33325C6.93913 1.33325 5.92172 1.75468 5.17157 2.50482C4.42143 3.25497 4 4.27239 4 5.33325C4 9.99992 2 11.3333 2 11.3333H14C14 11.3333 12 9.99992 12 5.33325Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M9.15042 14C9.03321 14.2021 8.86498 14.3698 8.66257 14.4864C8.46016 14.6029 8.23067 14.6643 7.99708 14.6643C7.7635 14.6643 7.53401 14.6029 7.3316 14.4864C7.12919 14.3698 6.96096 14.2021 6.84375 14"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
