import { TechnologyTitlesUnion } from '@shared/constants';
import {
    MaterialUnion,
    MaterialPreset,
    MaterialEntityUnion,
    MaterialEntityNameUnion,
} from '@shared/types';
import { axiosInstance } from './instance';

export class MaterialService {
    static init() {
        return new MaterialService();
    }

    public createMaterial({ name, data }: { name: string; data: Partial<MaterialUnion> }) {
        return axiosInstance.put<MaterialUnion>(`/v2/materials/${name}/`, data);
    }

    public editMaterial({
        id,
        name,
        data,
    }: {
        id: number | string;
        name: TechnologyTitlesUnion;
        data: Partial<MaterialUnion>;
    }) {
        return axiosInstance.patch(`/v2/materials/${name}/${id}/`, data);
    }

    public duplicateMaterial({ id, name }: { id: number | string; name: string }) {
        return axiosInstance.post(`/v2/materials/${name}/${id}/duplicate/`);
    }

    public deleteMaterial({ id, name }: { id: number | string; name: string }) {
        return axiosInstance.delete(`/v2/materials/${name}/${id}`);
    }

    public editMaterialEntity({
        id,
        data,
        entity,
        entityId,
    }: {
        entityId: string;
        id: number | string;
        data: any;
        entity: MaterialEntityNameUnion;
    }) {
        return axiosInstance.patch(`/v2/materials/${id}/${entity}/${entityId}/`, data);
    }

    public createMaterialEntity({
        id,
        data,
        entity,
    }: {
        id: number | string;
        data: MaterialEntityUnion;
        entity: MaterialEntityNameUnion;
    }) {
        return axiosInstance.put(`/v2/materials/${id}/${entity}/`, data);
    }

    public deleteMaterialEntity({
        id,
        entity,
        entityId,
    }: {
        entityId: string;
        id: number | string;
        entity: MaterialEntityNameUnion;
    }) {
        return axiosInstance.delete(`/v2/materials/${id}/${entity}/${entityId}/`);
    }

    public fetchMaterial({ id, name }: { id: number | string; name: TechnologyTitlesUnion }) {
        return axiosInstance.get<MaterialUnion>(`/v2/materials/${name}/${id}/`);
    }

    public fetchMaterialPresets() {
        return axiosInstance.get<MaterialPreset[]>('/v2/materials/presets/');
    }

    public fetchListExecution(id: number | string) {
        return axiosInstance.get<MaterialPreset[]>(`/v2/materials/${id}/executions/`);
    }
}
