import { AddressTypeEnum, enhancedApi as api } from './codegen';

const getAddressesField = (type: AddressTypeEnum) =>
    type === 'delivery' ? 'delivery_addresses' : 'billing_addresses';

api.enhanceEndpoints({
    endpoints: {
        organizationsList: {
            providesTags: [{ type: 'Organization', id: 'LIST' }],
        },
        organizationsSuggestList: {
            providesTags: [{ type: 'OrganizationSuggestion', id: 'LIST' }],
        },
        organizationsRetrieve: {
            providesTags: (result, error, arg) => [{ type: 'Organization', id: arg.id }],
        },
        organizationsCreate: {
            invalidatesTags: [
                { type: 'Organization', id: 'LIST' },
                { type: 'OrganizationSuggestion', id: 'LIST' },
            ],
        },
        organizationsPartialUpdate: {
            invalidatesTags: (result, error, arg) => [
                { type: 'Organization', id: 'LIST' },
                { type: 'Organization', id: arg.id },
            ],
        },
        organizationsAddressesCreate: {
            async onQueryStarted({ organizationId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            'organizationsRetrieve',
                            { id: organizationId },
                            draft => {
                                const addressType = getAddressesField(data.address_type);

                                if (draft[addressType]) {
                                    draft[addressType]!.unshift(data);
                                } else {
                                    draft[addressType] = [data];
                                }
                            },
                        ),
                    );
                } catch {}
            },
        },
        organizationsAddressesPartialUpdate: {
            async onQueryStarted({ organizationId, id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            'organizationsRetrieve',
                            { id: organizationId },
                            draft => {
                                const addressType = getAddressesField(data.address_type);

                                const index = draft[addressType]!.findIndex(adr => adr.id === id);
                                if (index !== -1) {
                                    draft[addressType]![index] = data;
                                } else {
                                    // address_type was updated
                                    dispatch(
                                        api.util.invalidateTags([
                                            { type: 'Organization', id: organizationId },
                                        ]),
                                    );
                                }
                            },
                        ),
                    );
                } catch {}
            },
        },
    },
});
