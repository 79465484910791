import { Icon, IconProps } from '@chakra-ui/react';

export const CardIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M1.60254 5.71418V4.57132C1.60254 3.94047 2.08041 3.42847 2.6692 3.42847H13.3359C13.9247 3.42847 14.4026 3.94047 14.4026 4.57132V5.71418H1.60254ZM14.4026 7.42847V11.4285C14.4026 12.0593 13.9247 12.5713 13.3359 12.5713H2.6692C2.08041 12.5713 1.60254 12.0593 1.60254 11.4285V7.42847H14.4026ZM6.40254 10.2856H3.20254V10.857H6.40254V10.2856ZM8.00254 9.14275H3.20254V9.71418H8.00254V9.14275ZM12.8026 9.14275H11.2025V9.71418H12.8026V9.14275Z"
            fill="currentColor"
        />
    </Icon>
);
