import { CompanyAddressCrm } from '@services/df/app';
import { COUNTRY_LABELS } from '@services/df/codegen-enums-labels';
import { USA_STATES, CANADIAN_STATES } from './constants';

export const getStateObject = (
    country: CompanyAddressCrm['country'],
    state: CompanyAddressCrm['state'],
) => {
    if (!country || !state) return;
    switch (country) {
        case 'CA':
            return CANADIAN_STATES.find(s => s.code === state);
        case 'US':
            return USA_STATES.find(s => s.code === state);
        default:
            return;
    }
};

export const getStates = (country?: CompanyAddressCrm['country']) => {
    switch (country) {
        case 'CA':
            return CANADIAN_STATES.slice();
        case 'US':
            return USA_STATES.slice();
        default:
            return [];
    }
};

export const getInitialState = (country?: CompanyAddressCrm['country']) => {
    const states = getStates(country);
    const hasStates = Boolean(states.length);
    return hasStates ? states[0].code : '';
};

export const getValidState = (
    country: CompanyAddressCrm['country'],
    suspiciousState: CompanyAddressCrm['state'],
) => {
    const states = getStates(country);
    const isValid = (states as ReadonlyArray<{ code: string }>).find(
        stateObj => stateObj.code === suspiciousState,
    );

    if (isValid) {
        return suspiciousState;
    }

    return getInitialState(country);
};

export function formatAddress(address: CompanyAddressCrm) {
    return {
        ...address,
        country: address.country ? COUNTRY_LABELS[address.country] : '',
        state: getStateObject(address.country, address.state)?.name,
    };
}
