import { ReactNode } from 'react';
import { Divider, Flex, FormLabel, FormLabelProps } from '@chakra-ui/react';
import { FCC } from '@shared/types';
import { Hint, HintProps } from './hint';
import { useFieldStyles } from '../hooks';

interface Props extends FormLabelProps {
    label: ReactNode;
    tooltip?: ReactNode;
    hintProps?: HintProps;
}

export const LabelControl: FCC<Props> = ({ label, tooltip, hintProps, children, ...rest }) => {
    const props = useFieldStyles('label', rest);
    return (
        <FormLabel position="relative" {...props}>
            <Flex align="center">
                {label}
                {!!tooltip && <Hint label={tooltip} {...hintProps} />}
                {children && (
                    <>
                        <Divider
                            orientation="vertical"
                            opacity="1"
                            height="3.5"
                            borderColor="gray.400"
                            ml="2"
                            mr="2"
                        />

                        {children}
                    </>
                )}
            </Flex>
        </FormLabel>
    );
};
