import { createPersistReducer } from '@ducks/helpers';
import { createReactTableSlice, getReactTableSelectors } from '@ducks/react-table-slice';

export const clientsTableSlice = createReactTableSlice({
    namespace: 'clients',
    initialState: {
        rowSelection: {},
    },
    reducers: {},
});

export const { setColumnsConfig: setClientsColumnsConfig, setRowSelection: setSelectedClients } =
    clientsTableSlice.actions;

export const {
    selectColumnsConfig: selectClientsColumnsConfig,
    selectRowSelection: selectSelectedClients,
    selectColumnsOrder: selectClientsColumnsOrder,
    selectColumnsVisibility: selectClientsColumnsVisibility,
} = getReactTableSelectors(clientsTableSlice, state => state.clients.table);

export const clientsTableReducer = createPersistReducer(clientsTableSlice, {
    whitelist: ['columnsConfig'],
});
