import has from 'lodash/has';
import { enhancedApi as api } from './codegen';

api.enhanceEndpoints({
    endpoints: {
        backofficeSettingsRetrieve: {
            providesTags: ['BackofficeAppSettings'],
        },
        companyExtendTrialPartialUpdate: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('backofficeSettingsRetrieve', undefined, draft => {
                            return { ...draft, ...data };
                        }),
                    );
                } catch {}
            },
        },
        companyPartialUpdate: {
            // invalidatesTags: ['BackofficeAppSettings'],
            async onQueryStarted({ patchedEditCompanyCrmRequest }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.updateQueryData('companyRetrieve', undefined, draft => {
                            Object.assign(draft, data);
                        }),
                    );

                    if (
                        [
                            'custom_pdf_css',
                            'company_title',
                            'currency',
                            'currency_decimal_digits',
                            'currency_format_sign_position',
                            'currency_format_decimal_separator',
                            'currency_format_thousand_separator',
                            'currency_format_swap_thousand_separator',
                            'tags',
                            'tax_name',
                            'tax_number_name',
                        ].some(field => has(patchedEditCompanyCrmRequest, field))
                    ) {
                        dispatch(api.util.invalidateTags(['BackofficeAppSettings']));
                        // or maybe is better to invalidate 'BackofficeAppSettings' (uncomment code above),
                        // because we can forget about this condition for new settings
                    }
                } catch {}
            },
        },
        companyAddressPartialUpdate: {
            async onQueryStarted(
                { patchedCompanyAddressCrmRequest },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            'companyAddressRetrieve',
                            undefined,
                            draft => data,
                        ),
                    );
                } catch {}
            },
        },
        companyDeliveryCustomShippingCreate: {
            async onQueryStarted({ companyCustomShippingRequest }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            'companyDeliveryCustomShippingList',
                            undefined,
                            draft => {
                                draft.push(data);
                            },
                        ),
                    );
                } catch {}
            },
        },
        companyDeliveryCustomShippingPartialUpdate: {
            async onQueryStarted(
                { id, patchedCompanyCustomShippingRequest },
                { dispatch, queryFulfilled },
            ) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        'companyDeliveryCustomShippingList',
                        undefined,
                        draft => {
                            const index = draft.findIndex(todo => todo.id === id);
                            if (index !== -1)
                                draft[index] = {
                                    ...draft[index],
                                    ...patchedCompanyCustomShippingRequest,
                                };
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        },
        companyDeliveryCustomShippingDestroy: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData(
                        'companyDeliveryCustomShippingList',
                        undefined,
                        draft => {
                            const index = draft.findIndex(todo => todo.id === id);
                            if (index !== -1) draft.splice(index, 1);
                        },
                    ),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        },
        companyIntegrationsList: {
            providesTags: ['Integrations'],
        },
        companyIntegrationsDestroy: {
            invalidatesTags: [{ type: 'Integrations' }],
        },
        companyCustomEmailsCreate: {
            invalidatesTags: [{ type: 'Emails', id: 'EMAIL' }],
        },
        companyCustomEmailsDestroy: {
            invalidatesTags: [{ type: 'Emails', id: 'EMAIL' }],
        },
        companyCustomEmailsRetrieve: {
            providesTags: [{ type: 'Emails', id: 'EMAIL' }],
        },
        companyVerifiedIdentityCreate: {
            async onQueryStarted(
                { companySendersVerifiedIdentityRequestRequest },
                { dispatch, queryFulfilled },
            ) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData(
                            'companyVerifiedIdentityList',
                            undefined,
                            draft => {
                                draft.push(data);
                            },
                        ),
                    );
                } catch {}
            },
        },
    },
});
