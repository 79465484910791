import React from 'react';
import { UseFormTrigger } from 'react-hook-form';
import { Button, InputRightElement, Text } from '@chakra-ui/react';
import {
    RequestResetPasswordRequest,
    usePasswordRecoveryRequestCreateMutation,
} from '@services/df/auth';
import { SignInForm } from './auth-sign-in-form';

interface Props {
    email: string;
    trigger: UseFormTrigger<SignInForm>;
}

export const AuthResetPasswordButton = ({ email, trigger }: Props) => {
    const [send, { isLoading: isPasswordRecoverySending, isSuccess: isPasswordRecoverySuccess }] =
        usePasswordRecoveryRequestCreateMutation();

    const handleResetPasswordClick = async (): Promise<void> => {
        const passedValidation = await trigger('email', { shouldFocus: true });
        if (passedValidation) {
            let payload = {
                email,
            } as RequestResetPasswordRequest;

            // from localhost backend can't check netloc
            if (process.env.NODE_ENV === 'production') {
                payload.redirect_path = window.location.href;
            }

            await send({
                requestResetPasswordRequest: payload,
            });
        }
    };

    return (
        <InputRightElement w="auto" pr={4}>
            {!isPasswordRecoverySending && isPasswordRecoverySuccess ? (
                <Text textStyle="button-md" color="success.default">
                    Email sent
                </Text>
            ) : (
                <Button
                    variant="link"
                    isLoading={isPasswordRecoverySending}
                    onClick={handleResetPasswordClick}
                >
                    Reset
                </Button>
            )}
        </InputRightElement>
    );
};
