import { AppThunk } from '@app/types';
import { TechnologyIdsUnion } from '@shared/constants';
import { fetchMaterial } from './reducers';

export const initMaterialEditPage = (
    materialId: number,
    technologyId: TechnologyIdsUnion,
): AppThunk => {
    return (dispatch, getState) => {
        dispatch(
            fetchMaterial({
                materialId,
                technologyId,
            }),
        );
    };
};
