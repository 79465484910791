import currency, { Options } from 'currency.js';
import { Currency } from '@services/df/app';

export function formatPrice(price: number | string = 0, currencyConfig: Currency, sign = '') {
    const { symbol, decimal, separator, position, decimal_digits: precision = 0 } = currencyConfig;
    const opts: Options = {
        symbol,
        decimal,
        separator,
    };

    const basePattern = position === 'right' ? '# !' : '!#';
    opts.pattern = sign + basePattern;
    opts.negativePattern = sign ? sign + basePattern : '-' + basePattern; // if there is no sign, force draw a minus

    return currency(price, { decimal: '.', precision }).format(opts);
}
