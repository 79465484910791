import React from 'react';
import { forwardRef } from '@chakra-ui/system';
import { Box, BoxProps } from '@chakra-ui/react';
import { ReactComponent as DigifabsterLogo } from './df-logo.svg';

interface AppLogoProps extends BoxProps {}

export const AppLogo = forwardRef<AppLogoProps, 'div'>(({ children, ...rest }, ref) => {
    return (
        <Box
            ref={ref}
            display="inline-flex"
            alignItems="center"
            color="secondary.default"
            {...rest}
        >
            <DigifabsterLogo width="100%" height="100%" />
        </Box>
    );
});
