import { RootState } from '@app/types';
import { createSelector } from '@reduxjs/toolkit';

export const selectUploadJob = (state: RootState) => state.models.upload.uploadJob;

export const selectModels = (state: RootState) => state.models.upload.models;

export const selectAcceptedFiles = (state: RootState) => state.models.upload.acceptedFiles;
export const selectRejectedFiles = (state: RootState) => state.models.upload.rejectedFiles;

export const selectObjectModelsIds = createSelector(selectModels, modelsStatus =>
    modelsStatus.map(({ id }) => id),
);

export const selectReadyModelsIds = (state: RootState) => state.models.upload.readyModelsIds;
