import flatten from 'lodash/flatten';
import isArray from 'lodash/isArray';
import React from 'react';
import { Text } from '@chakra-ui/react';
import { DropzoneOptions, ErrorCode, FileError, FileRejection } from 'react-dropzone';
import { formatBytes } from '@shared/utils/strings';
import { formatDropzoneRejections } from './helpers';

type Code = FileError['code'];
type Options = Pick<DropzoneOptions, 'minSize' | 'maxSize' | 'maxFiles'> & {
    accept?: string[];
};

function formatOptions({ minSize, maxSize, accept, maxFiles }: Options) {
    return {
        accept: accept && isArray(accept) ? accept.join(', ') : accept,
        maxSize: maxSize && formatBytes(maxSize, { expressIn: 'MB' }),
        minSize: minSize && formatBytes(minSize, { expressIn: 'MB' }),
        maxFiles,
    };
}

export function getFileError(filename: string, code: Code, options: Options) {
    const { accept, maxSize, minSize, maxFiles } = formatOptions(options);

    switch (code) {
        case ErrorCode.FileTooLarge:
            return (
                <>
                    <strong>{filename}</strong> is too big, maximum file size is {maxSize} MB.
                </>
            );
        case ErrorCode.FileTooSmall:
            return (
                <>
                    <strong>{filename}</strong> is smaller than {minSize} MB.
                </>
            );
        case ErrorCode.FileInvalidType:
            return (
                <>
                    <strong>{filename}</strong> has wrong file extension, uploads available only for{' '}
                    {accept} files.
                </>
            );
        case ErrorCode.TooManyFiles:
            return <>Files limit exceeded. Maximum {maxFiles} files at one time</>;
        default:
            return (
                <>
                    <strong>{filename}</strong> {code}
                </>
            );
    }
}

interface DropzoneErrorsProps {
    files: FileRejection[];
    opts: Options;
}

export const DropzoneErrors: React.FC<DropzoneErrorsProps> = ({ files, opts }) => {
    if (!files.length) return null;

    const rejections = formatDropzoneRejections(files);

    const messages: Array<string | React.ReactElement> = rejections.some(file =>
        file.errorCodes.includes(ErrorCode.TooManyFiles),
    )
        ? [getFileError('', ErrorCode.TooManyFiles, opts)]
        : [];

    const errors: Array<Array<string | React.ReactElement>> = rejections.map(
        ({ fileName, errorCodes, codeValues }) => {
            return errorCodes
                .filter(code => code !== ErrorCode.TooManyFiles)
                .map(code => getFileError(fileName, code, { ...opts, ...codeValues[code] }));
        },
    );

    messages.push(...flatten(errors).filter(Boolean));

    return (
        <>
            {messages.length > 1
                ? messages.map((message, i) => <Text key={i}>{message}</Text>)
                : messages[0]}
        </>
    );
};
