export const ModalStyles = {
    baseStyle: {
        dialog: {
            overflow: 'clip',
            borderRadius: 12,
        },
        overlay: {
            background: 'rgba(0, 0, 0, 0.2)',
        },
        header: {
            px: 6,
            py: 6,
        },
        body: {
            py: 6,
            paddingTop: 0,
            paddingBottom: 6,
        },
        footer: {
            py: 6,
            paddingTop: 0,
            paddingBottom: 6,
        },
        closeButton: {
            color: 'neutral.darkest',
            _hover: { color: 'neutral.dark' },
        },
    },
    variants: {
        alert: {
            header: {
                paddingBottom: 4,
                fontSize: 'lg',
                fontWeight: 'normal',
            },
            footer: {
                justifyContent: 'flex-end',
            },
        },
    },
    defaultProps: {},
};
