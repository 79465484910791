export const FormErrorStyles = {
    baseStyle: {
        // icon: {},
        text: {},
    },
    sizes: {
        xs: {
            text: { fontSize: 'xs' },
        },
    },
};
