import { createEntityAdapter, isAnyOf, isAllOf } from '@reduxjs/toolkit';
import { enhancedApi as api } from '@services/df/price-match';
import { AppThunk, RootState } from '@app/types';
import { createAppSlice } from '@app/helpers';
import { createPersistReducer, hasUiEventMeta } from '../helpers';
import { priceMatchUpdateConfig } from './config';
import { priceMatchFetchModels, priceMatchRemoveModel, priceMatchRemoveModels } from './models';

export interface PriceMatchMetadata {
    // uuid?: string;
    title: string;
    modelId: number;
    perOne: number | null;
    perTen: number | null;
}

const metadataAdapter = createEntityAdapter<PriceMatchMetadata, number>({
    selectId: price => price.modelId,
});

const metadataSlice = createAppSlice({
    name: 'priceMatch/metadata',
    initialState: metadataAdapter.getInitialState({ taskId: '' }),
    reducers: {
        upsertMany: metadataAdapter.upsertMany,
    },
    extraReducers: builder => {
        builder.addCase(priceMatchRemoveModel, metadataAdapter.removeOne);
        builder.addCase(priceMatchRemoveModels, metadataAdapter.removeAll);
        builder.addCase(priceMatchFetchModels.fulfilled, (state, { meta, payload }) => {
            if (meta.arg.case === 'refetch') return;

            // store metadata on add/reset models
            const metadata = payload.map(({ id: modelId, title }) => ({
                modelId,
                title: title ?? '',
                perOne: null,
                perTen: null,
            }));

            meta.arg.case === 'set'
                ? metadataAdapter.setAll(state, metadata)
                : metadataAdapter.upsertMany(state, metadata);
        });
        builder.addMatcher(api.endpoints.priceMatchCreate.matchFulfilled, (state, { payload }) => {
            state.taskId = payload.task_id;
        });
        builder.addMatcher(
            isAnyOf(
                isAllOf(priceMatchUpdateConfig, hasUiEventMeta),
                priceMatchRemoveModel,
                priceMatchRemoveModels,
                priceMatchFetchModels.fulfilled,
            ),
            (state, action) => {
                if (
                    priceMatchFetchModels.fulfilled.match(action) &&
                    action.meta.arg.case === 'refetch'
                ) {
                    return;
                }

                state.taskId = '';
            },
        );
    },
});

export const metadataReducer = createPersistReducer(metadataSlice, {
    dependsOnCompany: true,
});

export const { upsertMany: priceMatchUpsertMetadata } = metadataSlice.actions;

export const { selectAll: selectPriceMatchMetadata, selectIds: selectPriceMatchModelsIds } =
    metadataAdapter.getSelectors((state: RootState) => state.priceMatch.metadata);

export const selectPriceMatchTaskId = (state: RootState) => state.priceMatch.metadata.taskId;

export const fetchPriceMatchModels = (): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const ids = selectPriceMatchModelsIds(state);
        ids.length && dispatch(priceMatchFetchModels({ ids, case: 'refetch' }));
    };
};
