import isString from 'lodash/isString';
import { ReactNode, Children } from 'react';
import {
    Box,
    BoxProps,
    Divider,
    DividerProps,
    HStack,
    StackProps,
    Image,
    Text,
} from '@chakra-ui/react';
import {
    StylesProvider,
    ThemingProps,
    forwardRef,
    omitThemingProps,
    useMultiStyleConfig,
    useStyles,
} from '@chakra-ui/system';
import { cx } from '@chakra-ui/utils';
import { SectionHeading } from './section-heading';

const sectionHeadProps: Record<string, Record<string, object>> = {
    sm: {
        header: {
            size: 'sm',
        },
    },
};

export interface SectionHeadProps extends Pick<ThemingProps<'Section'>, 'size'>, StackProps {
    image?: ReactNode;
    header?: ReactNode;
    description?: ReactNode;
}

export const SectionHead = forwardRef<SectionHeadProps, 'div'>(
    ({ size = 'lg', image, header, description, children, ...rest }, ref) => {
        const propsBySize = sectionHeadProps[size] ?? {};

        const _image = isString(image) ? (
            <Image className="chakra-section__image" src={image} />
        ) : (
            image
        );
        const _header = isString(header) ? (
            <SectionHeading title={header} {...propsBySize.header} />
        ) : (
            header
        );
        const _description = isString(description) ? (
            <Text className="chakra-section__description" mt={3}>
                {description}
            </Text>
        ) : (
            description
        );

        const head = (
            <Box className="chakra-section__heading-box">
                {_header}
                {_description}
            </Box>
        );

        return (
            <HStack
                ref={ref}
                className="chakra-section__head"
                justifyContent="space-between"
                flexFlow="row wrap"
                gap={2}
                spacing="0"
                {...rest}
            >
                {_image ? (
                    <HStack spacing={2}>
                        {_image}
                        {head}
                    </HStack>
                ) : (
                    head
                )}

                {children}
            </HStack>
        );
    },
);

export const SectionDivider = forwardRef<DividerProps, 'hr'>(({ className, ...rest }, ref) => {
    const styles = useStyles();
    return (
        <Divider
            ref={ref}
            className={cx('chakra-section__divider', className)}
            __css={styles.divider}
            {...rest}
        />
    );
});

type SectionParts = Pick<SectionHeadProps, 'image' | 'header' | 'description'> & {
    actions?: ReactNode;
    head?: ReactNode;
    divider?: boolean;
};

export type SectionProps = SectionParts & BoxProps & ThemingProps<'Section'>;

export const Section = forwardRef<SectionProps, 'div'>((props, ref) => {
    const styles = useMultiStyleConfig('Section', props);
    const {
        image,
        header,
        description,
        actions,
        head,
        divider = true,
        className,
        children,
        ...rest
    } = omitThemingProps(props);

    const hasHead = [head, header, description, actions].some(Boolean);
    const hasChildren = Boolean(Children.toArray(children).filter(Boolean).length);
    const shouldRenderDivider = divider && hasHead && hasChildren;

    return (
        <StylesProvider value={styles}>
            <Box
                ref={ref}
                className={cx('chakra-section', className)}
                {...rest}
                __css={styles.container}
            >
                {hasHead &&
                    (head || (
                        <SectionHead
                            size={props.size}
                            image={image}
                            header={header}
                            description={description}
                        >
                            {actions}
                        </SectionHead>
                    ))}
                {shouldRenderDivider && <SectionDivider />}
                {children}
            </Box>
        </StylesProvider>
    );
});

// Nested section boxes
export type SectionTileProps = SectionParts & BoxProps;

export const SectionTile = forwardRef<SectionTileProps, 'div'>((props, ref) => (
    <Section size="sm" divider={false} ref={ref} {...props} />
));

// todo separate from section? Tile with variants
export const SectionAccentTile = forwardRef<SectionTileProps, 'div'>((props, ref) => (
    <Section size="sm" variant="accent" divider={false} ref={ref} {...props} />
));
