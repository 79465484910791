import React from 'react';
import { withBaseErrorBoundary } from '@shared/components/error-boundaries';
import { useAuthCreateSessionWithTokenCreateMutation } from '@services/df/auth';
import { AuthPageLayout, AuthSection, AuthSignInForm, SignInForm } from '../ui';
import { SignInHead } from '@modules/auth/sign-in/sign-in-head';

// Discussion: https://digifabster.slack.com/archives/C1ENJ3LCW/p1737467281597799?thread_ts=1737466064.182399&cid=C1ENJ3LCW

export const CompanyLoginPage = withBaseErrorBoundary(() => {
    const [loginCompany, { isLoading: isTokenLoading }] =
        useAuthCreateSessionWithTokenCreateMutation();

    const request = async (form: SignInForm) =>
        await loginCompany({
            obtainTokenRequestRequest: { company_name: '4taps', ...form },
        }).unwrap();

    return (
        <AuthPageLayout id="company_login_page" head={<SignInHead />}>
            <AuthSection header="Log in to Your Logo Back-office">
                <AuthSignInForm
                    formId="company_login_form"
                    request={request}
                    isSubmitting={isTokenLoading}
                />
            </AuthSection>
        </AuthPageLayout>
    );
});
