import { createSlice } from '@reduxjs/toolkit';
import {
    AcceptValues,
    PriceMatchResultState,
    RecalculatePrices,
    RecalculatePricesSuccess,
} from './types';
import { enhancedApi as pmApi } from '@services/df/price-match';

export const calculationResultSlice = createSlice({
    name: 'priceMatch/calculationResult',
    initialState: {
        fields: {},
        prices: null,
        isError: false,
        isUpdating: false,
    } as PriceMatchResultState,
    reducers: {
        recalculatePrices(state, action: RecalculatePrices) {
            state.isUpdating = true;
        },
        recalculatePricesSuccess(state, action: RecalculatePricesSuccess) {
            const { prices_1, prices_10 } = action.payload;
            state.prices!.automatic_prices = { prices_1, prices_10 };
            state.isUpdating = false;
        },
        recalculatePricesFailure(state) {
            state.isError = true;
            state.isUpdating = false;
        },

        acceptValues(state, action: AcceptValues) {},
        acceptValuesSuccess() {},
        acceptValuesFailure(state) {
            state.isError = true;
        },

        resetError(state) {
            state.isError = false;
        },
    },
    extraReducers: builder => {
        builder.addMatcher(
            pmApi.endpoints.priceMatchRetrieve.matchFulfilled,
            (state, { payload }) => {
                if (payload.status === 'failed') {
                    state.isError = true;
                    return;
                }

                if (!payload.result) return;

                const { automatic_prices, user_prices, fields } = payload.result;
                state.fields = fields;
                state.prices = { automatic_prices, user_prices };
            },
        );
    },
});

export const {
    resetError,
    acceptValues,
    acceptValuesSuccess,
    acceptValuesFailure,
    recalculatePrices,
    recalculatePricesSuccess,
    recalculatePricesFailure,
} = calculationResultSlice.actions;
