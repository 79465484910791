import isNil from 'lodash/isNil';
import { toPercent, toCoefficient } from '@shared/utils/strings';
import { CompanyCrm, PatchedEditCompanyCrmRequest } from '@services/df/app';
import { compareElementsFunction } from '@shared/utils/arrays';

// todo duplicate with src/modules/setttings/company/order-statuses/config.ts
export type StatusField = {
    code: string;
    title: string;
};

export const overwriteCompanyDefaultValues = (data: CompanyCrm) => ({
    available_carriers: data.available_carriers.concat().sort(compareElementsFunction),
    tax: toPercent(data?.tax).value,
    custom_order_status_titles: data?.custom_order_status_titles
        ? Object.entries(data?.custom_order_status_titles as Record<string, string>).reduce(
              (acc, [code, title]) => [...acc, { code, title }],
              [] as StatusField[],
          )
        : [],
});

export const formatCompanyPayload = (_data: PatchedEditCompanyCrmRequest) => {
    const data = { ..._data };

    if (!isNil(data.tax)) {
        data.tax = toCoefficient(data.tax).value;
    }

    if (data.spec_string_template) {
        data.spec_string_template =
            data.spec_string_template?.concat().sort(compareElementsFunction) ?? [];
    }

    if (data.custom_order_status_titles) {
        data.custom_order_status_titles = (data.custom_order_status_titles as StatusField[])
            .filter((field: StatusField) => Boolean(field.title))
            .reduce(
                (acc, field) => ({ ...acc, [field.code]: field.title }),
                {} as Record<string, string>,
            );
    }

    return data;
};
