import isBoolean from 'lodash/isBoolean';
import { Navigate, NavigateProps, Outlet, useLocation } from 'react-router-dom';
import { useAppSelector } from '@app/hooks';
import { AppLocationState } from '@app/types';
import { selectSettings } from '@ducks/app';
import {
    Permissions,
    selectAppInitialSuccess,
    selectIsAuthenticated,
    selectUserPermissions,
} from '@entities';
import { BackofficeAppSettings } from '@services/df/app';
import { FCC } from '@shared/types';
import { ROUTES } from '@shared/constants/routes';

export interface PrivateRouteChecker {
    settings: BackofficeAppSettings;
    isAuthenticated: boolean;
    permissions: Permissions;
}

// for routes with flexible checks
interface PrivateRouteProps extends Partial<NavigateProps> {
    isAllowed: boolean | ((props: PrivateRouteChecker) => boolean);
}

export const PrivateRoute: FCC<PrivateRouteProps> = ({
    isAllowed: _isAllowed,
    children,
    to = ROUTES.NOT_FOUND,
    ...rest
}) => {
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const settings = useAppSelector(selectSettings)!;
    const permissions = useAppSelector(selectUserPermissions);

    const isAllowed = isBoolean(_isAllowed)
        ? _isAllowed
        : _isAllowed({ isAuthenticated, settings, permissions });

    if (!isAllowed) {
        return <Navigate replace to={to} {...rest} />;
    }

    return children ? <>{children}</> : <Outlet />;
};

// for auth required routes
interface AuthRouteProps {
    customAuthChecker?: (props: PrivateRouteChecker) => boolean; // e.g. checks anonymous access
}
// todo use it after migrating auth to LS with token
export const AuthRoute: FCC<AuthRouteProps> = ({
    children,
    customAuthChecker = ({ isAuthenticated }) => isAuthenticated,
}) => {
    return (
        <PrivateRoute isAllowed={customAuthChecker} to={ROUTES.SIGN_IN}>
            {children ? children : <Outlet />}
        </PrivateRoute>
    );
};

// for anonymous
interface GuestRouteProps {}

export const GuestRoute: FCC<GuestRouteProps> = ({ children }) => {
    const location = useLocation();
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const appInitialSuccess = useAppSelector(selectAppInitialSuccess);

    // wait for v2/obtain_session_token
    // todo remove this line after migrating auth to LS with token
    if (!appInitialSuccess) return null;

    const next = (location.state as AppLocationState)?.from || ROUTES.ORDERS_MAIN;

    if (isAuthenticated) return <Navigate to={next} replace />;

    return children ? <>{children}</> : <Outlet />;
};
