import React from 'react';
import { ErrorBoundaryProps, FallbackRender, withErrorBoundary } from '@sentry/react';
import { Box, BoxProps, Button, Container, Flex, Text } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { Alert } from '@shared/components/alert';

type FallbackProps = Parameters<FallbackRender>[0];

interface BaseErrorBoundaryFallbackProps extends BoxProps {
    label?: string;
}

export const BaseErrorBoundaryFallback = forwardRef<
    BaseErrorBoundaryFallbackProps & FallbackProps,
    'div'
>(
    (
        { label = 'Something went wrong!', eventId, error, componentStack, resetError, ...rest },
        ref,
    ) => {
        return (
            <Box ref={ref} {...rest}>
                <Alert status="error" title={label}>
                    <Text textStyle="typography-sm" as="div" mt="2">
                        <Text as="span" fontWeight="600" whiteSpace="nowrap">
                            Event ID:{' '}
                        </Text>
                        {eventId}
                    </Text>

                    <Box as="pre" mt="2">
                        {error.message || JSON.stringify(error)}
                    </Box>
                </Alert>

                <Button mt={2} onClick={resetError}>
                    Refresh
                </Button>
            </Box>
        );
    },
);

export const getBaseErrorBoundaryProps = (
    props: BaseErrorBoundaryFallbackProps = {},
): ErrorBoundaryProps => ({
    fallback: _props => (
        <BaseErrorBoundaryFallback
            {..._props}
            label="Page level error"
            as={Container}
            mt={32}
            {...props}
        />
    ),
    onReset: () => {
        window.location.assign(window.location.pathname);
    },
});

export function withBaseErrorBoundary<P extends Record<string, any>>(
    Component: React.ComponentType<P>,
    errorBoundaryProps: ErrorBoundaryProps = getBaseErrorBoundaryProps(),
) {
    return withErrorBoundary(Component, errorBoundaryProps);
}
