export const HeadingStyles = {
    baseStyle: { fontWeight: '600' },
    sizes: {
        '3xl': {
            fontSize: ['xxxl', null, 'xxxxl'],
            letterSpacing: 'normal',
            fontWeight: '800',
        },
        '2xl': {
            fontSize: ['xl', 'xxl', 'xxxl'], // h1
            lineHeight: '46px',
            letterSpacing: 'normal',
            fontWeight: '800',
        },
        xl: {
            fontSize: ['xl', null, 'xxl'], // h2
            lineHeight: '32px',
            letterSpacing: 'normal',
            fontWeight: '700',
        },
        lg: {
            fontSize: ['lg', null, 'xl'], // h3
            lineHeight: '24px',
            letterSpacing: 'normal',
            fontWeight: '700',
        },
        md: { fontSize: 'lg' }, // h4
        sm: { fontSize: 'md' }, // h5
        xs: { fontSize: 'sm' }, // h6
    },
    // defaultProps: { size: 'xl' },
};
