import zipObject from 'lodash/zipObject';
import { v4 as uuidv4 } from 'uuid';
import React, { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { AlertStatus as ChakraAlertStatus, Text } from '@chakra-ui/react';
import { useDropzone, DropzoneOptions } from 'react-dropzone';
import { createToast as toast } from '@shared/components/toast';
import { DropzoneErrors } from './dropzone-errors';
import { createAttachment, isAttachedFile, isAttachedLink } from './helpers';
import { AttachedFile, AttachedLink, AttachedObject, InitialFile } from './types';
import {
    DEFAULT_MAX_FILES_QUANTITY,
    DEFAULT_MAX_FILE_SIZE,
    DEFAULT_MIN_FILE_SIZE,
} from './constants';

type AlertStatus = Exclude<ChakraAlertStatus, 'warning'>;

export interface UseFilesAttachmentProps {
    dropzoneProps?: DropzoneOptions;
    initialFiles?: Array<InitialFile>;
    showAlerts?: boolean | Array<AlertStatus>;
    replaceAllowed?: boolean;
    submitFiles?: (files: AttachedFile[]) => Promise<Array<Omit<AttachedLink, 'type'> | string>>;
    onChange?: (files: AttachedObject[]) => void;
    onDelete?: (file: AttachedObject, index: number) => void;
    getFileAddedMessage?: (file: string) => React.ReactElement;
    getFileRemovedMessage?: (file: string) => React.ReactElement;
    getFileLimitExceedMessage?: (maxFiles: number) => React.ReactElement;
}

export function useFilesAttachment({
    dropzoneProps,
    initialFiles,
    showAlerts = ['error'],
    replaceAllowed = false,
    submitFiles,
    onChange,
    onDelete,
    getFileAddedMessage: _getFileAddedMessage,
    getFileRemovedMessage: _getFileRemovedMessage,
    getFileLimitExceedMessage: _getFileLimitExceedMessage,
}: UseFilesAttachmentProps) {
    const [attachments, setAttachments] = useState<AttachedObject[]>(() =>
        initialFiles ? initialFiles.map(createAttachment) : [],
    );

    // don't want the first render to trigger the onChange callback, but all changes afterwards should.
    const rendered = useRef(false);

    useEffect(() => {
        if (rendered.current && onChange) {
            onChange(attachments);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [attachments]);

    useEffect(() => {
        rendered.current = true;
        return () => {
            rendered.current = false;
        };
    }, []);

    // alerts
    // const toast = useToast();
    const shouldShowAlerts = useCallback(
        (type: AlertStatus) =>
            (typeof showAlerts === 'boolean' && showAlerts) ||
            (Array.isArray(showAlerts) && showAlerts.includes(type)),

        [showAlerts],
    );
    const showErrorAlerts = shouldShowAlerts('error');
    const showSuccessAlerts = shouldShowAlerts('success');
    const showInfoAlerts = shouldShowAlerts('info');

    const getFileAddedMessage =
        _getFileAddedMessage ||
        (file => (
            <Text>
                File <strong>{file}</strong> successfully added.
            </Text>
        ));
    const getFileLimitExceedMessage =
        _getFileLimitExceedMessage ||
        (maxFiles => <Text>Files limit exceeded. Maximum {maxFiles} files at one time</Text>);
    const getFileRemovedMessage =
        _getFileRemovedMessage ||
        (file => (
            <Text>
                File <strong>{file}</strong> removed.
            </Text>
        ));

    const dropzoneParameters = {
        ...dropzoneProps,
        minSize: dropzoneProps?.minSize ?? DEFAULT_MIN_FILE_SIZE,
        maxSize: dropzoneProps?.maxSize ?? DEFAULT_MAX_FILE_SIZE,
        maxFiles: dropzoneProps?.maxFiles ?? DEFAULT_MAX_FILES_QUANTITY,
    };

    const { accept, minSize, maxSize, maxFiles } = dropzoneParameters;
    const isMultiple = maxFiles > 1;
    const disabled = !replaceAllowed && attachments.length === maxFiles;

    const handleDropAccepted: DropzoneOptions['onDropAccepted'] = async (acceptedFiles, evt) => {
        const limitExceeded = isMultiple && attachments.length + acceptedFiles.length > maxFiles;

        if (!replaceAllowed && limitExceeded) {
            showErrorAlerts &&
                toast({
                    status: 'error',
                    description: getFileLimitExceedMessage(maxFiles),
                });
            return;
        }

        // Notify Drop event
        if (dropzoneProps?.onDropAccepted) {
            dropzoneProps.onDropAccepted(acceptedFiles, evt);
        }

        const _attachedFiles = acceptedFiles.map(file => {
            return {
                type: 'file',
                uuid: uuidv4(),
                // while uploading, files in the list are disabled,
                // if user delete file before uploading is complete, we won't be able to match them against the list
                isSubmitting: Boolean(submitFiles),
                file,
            } as const;
        });
        const attachedFiles = isMultiple ? _attachedFiles : [_attachedFiles[0]];

        // update files view
        setAttachments(exists => {
            if (replaceAllowed && limitExceeded) {
                return attachedFiles;
            }

            return exists.concat(attachedFiles);
        });

        if (submitFiles) {
            const response = await submitFiles(attachedFiles);
            const matchedResponse = zipObject(
                attachedFiles.map(file => file.uuid),
                response,
            );

            // update files view
            setAttachments(exists =>
                exists.reduce(
                    (acc, fileObj) =>
                        isAttachedFile(fileObj) && matchedResponse[fileObj.uuid]
                            ? [...acc, createAttachment(matchedResponse[fileObj.uuid])]
                            : [...acc, fileObj],
                    [] as AttachedObject[],
                ),
            );
        }

        // Display message
        if (showSuccessAlerts) {
            const messages = attachedFiles.map(fileObj => getFileAddedMessage(fileObj.file.name));
            toast({
                status: 'success',
                description: (
                    <>
                        {messages.length > 1
                            ? messages.map((message, i) => <Text key={i}>{message}</Text>)
                            : messages[0]}
                    </>
                ),
            });
        }
    };

    const handleDropRejected: DropzoneOptions['onDropRejected'] = (rejectedFiles, evt) => {
        if (dropzoneProps?.onDropRejected) {
            dropzoneProps.onDropRejected(rejectedFiles, evt);
        }

        if (showErrorAlerts) {
            const limitExceeded = attachments.length + rejectedFiles.length > maxFiles;
            toast({
                status: 'error',
                description: limitExceeded ? (
                    getFileLimitExceedMessage(maxFiles)
                ) : (
                    <DropzoneErrors
                        files={rejectedFiles}
                        opts={{
                            accept: accept ? Object.values(accept).flat() : undefined,
                            minSize,
                            maxSize,
                            maxFiles,
                        }}
                    />
                ),
            });
        }
    };

    const handleRemove =
        (attachedObject: AttachedObject, fileIndex: number) => (event: MouseEvent) => {
            event.stopPropagation(); // case into dropzone

            const isLink = isAttachedLink(attachedObject);

            // Notify removed file
            if (onDelete) {
                onDelete(attachedObject, fileIndex);
            }

            // Update local state
            setAttachments(exists =>
                exists.filter(_attachedObject => _attachedObject !== attachedObject),
            );

            if (showInfoAlerts) {
                const name = isLink ? attachedObject.name : attachedObject.file.name;
                toast({
                    status: 'info',
                    title: getFileRemovedMessage(name),
                });
            }
        };

    const dzProps = useDropzone({
        ...dropzoneParameters,
        onDropAccepted: handleDropAccepted,
        onDropRejected: handleDropRejected,
        disabled,
        multiple: isMultiple,
    });

    return {
        ...dzProps,
        attachments,
        disabled,
        handleRemove,
    };
}
