import { formatISO } from 'date-fns';
import { useController } from 'react-hook-form';
import { DateInput, DateInputProps } from '@shared/components/datepicker';
import { FieldControl } from '../common';
import { ControllerFieldProps } from '../types';
import { getFieldState } from '../helpers';

export type DateFieldProps = Partial<Omit<DateInputProps, 'value'>> &
    ControllerFieldProps & { onChangeAdditional?: (date: Date) => void };

export const DateField = ({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    tooltip,
    helpText,
    helpTextAsTooltip,
    errorText: _,

    formControlProps,
    labelProps,
    errorProps,
    helpTextProps,
    tooltipProps,
    onChangeAdditional,
    ...input
}: DateFieldProps) => {
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const { field } = state;
    return (
        <FieldControl
            label={label}
            labelProps={labelProps}
            tooltip={tooltip}
            tooltipProps={tooltipProps}
            helpText={helpText}
            helpTextAsTooltip={helpTextAsTooltip}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            {...formControlProps}
        >
            <DateInput
                {...input}
                onDateChange={(date: Date) => {
                    field.onChange(formatISO(date, { representation: 'date' }));
                    if (onChangeAdditional) {
                        onChangeAdditional(date);
                    }
                }}
                value={field.value}
            />
        </FieldControl>
    );
};
