import { assign, isEmpty } from 'lodash';
import { Model } from '@services/df/orders';
import { convertUnits } from '@shared/utils/convert-units';

const MODEL_UNITS_LABELS = {
    mm: { units: 'mm', label: 'Millimeters' },
    cm: { units: 'mm', label: 'Centimeters' },
    in: { units: 'in', label: 'Inches' },
} as const;

export function getModelExtension(model: Model) {
    const { title } = model;
    if (title) {
        const modelTitleArr = title.split('.');
        const modelExtension = modelTitleArr[modelTitleArr.length - 1].toUpperCase();
        return `.${modelExtension}`;
    } else return '';
}

export function getModelSize(model: Model) {
    const { size, units } = model;
    if (!size || !units) return null;

    let modelSize = `${MODEL_UNITS_LABELS[units].label}: ${size.x?.toFixed(1)} x
        ${size.y?.toFixed(1)}`;
    if (size.z) {
        modelSize = modelSize + ` x ${size.z?.toFixed(1)}`;
    }
    return modelSize;
}

export function getModelVolume(model: Model) {
    const { volume, units } = model;
    if (!volume || !units) return null;

    if (units === MODEL_UNITS_LABELS.mm.units) {
        return `${convertUnits({
            value: volume,
            from: 'mm3',
            to: 'cm3',
        }).toFixed(1)} cm3`;
    }

    return `${volume.toFixed(1)} ${units}3`;
}

export function getModelSurface(model: Model) {
    const { surface, units } = model;
    if (!surface || !units) return null;

    if (units === MODEL_UNITS_LABELS.mm.units) {
        return `${convertUnits({
            value: surface,
            from: 'mm2',
            to: 'cm2',
        }).toFixed(2)} cm2`;
    }

    return `${surface} ${units}2`;
}

export function getOrderPurchaseModelData(model: Model) {
    const title = model.title || 'Untitled model';
    const extension = getModelExtension(model);
    let size = getModelSize(model);
    const volume = getModelVolume(model);
    const surface = getModelSurface(model);
    const repairedRes = model?.repaired_res;
    const numberOfFacets = repairedRes?.number_of_facets
        ? `Number of facets: ${repairedRes?.number_of_facets};`
        : null;
    const facetsRemoved = repairedRes?.facets_removed
        ? `Facets removed: ${repairedRes?.facets_removed};`
        : null;
    const facetsAdded = repairedRes?.number_added
        ? `Facets added: ${repairedRes?.number_added};`
        : null;
    const facetsReversed = repairedRes?.facets_reversed
        ? `Edges fixed: ${repairedRes?.facets_reversed};`
        : null;
    const edgesFixed = repairedRes?.edges_fixed
        ? `Edges fixed: ${repairedRes?.edges_fixed};`
        : null;
    const status = {};

    if (numberOfFacets) assign(status, { numberOfFacets });
    if (facetsRemoved) assign(status, { facetsRemoved });
    if (facetsAdded) assign(status, { facetsAdded });
    if (facetsReversed) assign(status, { facetsReversed });
    if (edgesFixed) assign(status, { edgesFixed });

    const fileSize = model?.filesize ? `${model?.filesize} Mb` : null;
    const dateCreated = model?.date_created
        ? new Date(model?.date_created as string).toLocaleDateString('us-US', {
              day: '2-digit',
              month: '2-digit',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
          })
        : null;
    const author = model?.client?.email;
    const originalFile = model?.object_model_original_url;
    const processedFile = model?.file_model_stl_repaired_url;
    const parentModel = model?.parent_model;
    const childrenModels = model?.child_models;

    const data = {
        title,
        values: {},
        labels: {
            extension: 'Extension',
            size: 'Size',
            volume: 'Volume',
            surface: 'Surface',
            status: 'Status',
            fileSize: 'File size',
            dateCreated: 'Uploaded',
            author: 'Author',
            originalFile: 'Original file',
            processedFile: 'Processed file',
            parentModel: 'Pre-oriented model',
            childrenModels: 'Auto-oriented models',
        },
    };

    if (extension) assign(data.values, { extension });
    if (size) assign(data.values, { size });
    if (volume) assign(data.values, { volume });
    if (surface) assign(data.values, { surface });
    if (!isEmpty(status)) assign(data.values, { status });
    if (fileSize) assign(data.values, { fileSize });
    if (dateCreated) assign(data.values, { dateCreated });
    if (author) assign(data.values, { author });
    if (originalFile) assign(data.values, { originalFile });
    if (processedFile) assign(data.values, { processedFile });
    if (parentModel) assign(data.values, { parentModel });
    if (childrenModels.length > 0) assign(data.values, { childrenModels });

    return data;
}
