import isNil from 'lodash/isNil';
import { Machine, PatchedMachineRequest } from '@services/df/machines-materials';
import { toCoefficient, toPercent } from '@shared/utils/strings';

const coefficientFields = [
    'max_heat_absorption',
    'machining_operator_involvement',
    'features_operator_involvement',
] as const;

export const overwriteMachineDefaultValues = (data: Machine) => {
    const _coefficientFields = coefficientFields.reduce((acc, field) => {
        const value = data[field];

        if (!isNil(value)) {
            acc[field] = toPercent(value).value;
        }

        return acc;
    }, {} as Partial<Machine>);

    return _coefficientFields;
};

export function formatMachinePayload<T extends PatchedMachineRequest = PatchedMachineRequest>(
    _data: T,
) {
    const data = { ..._data };

    // the backend is waiting for the coefficients, and in the form we have percentages
    coefficientFields.forEach(field => {
        const value = data[field];
        if (!isNil(value)) {
            data[field] = toCoefficient(value).value;
        }
    });

    return data;
}
