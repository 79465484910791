import omit from 'lodash/omit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@app/types';
import { createPersistReducer } from '../helpers';

// todo move file to shared/components/filters

// todo move to order
// table, list, etc...
export enum View {
    Orders = 'orders',
    LineItems = 'lineItems',
    Drafts = 'drafts',
    Carts = 'carts',
}

export type ViewUnion = `${View}`; // todo string?

type QueryParams = Record<string, any>;
type QueryParamsMap = Record<ViewUnion, QueryParams | undefined>;

const NON_PERSISTENT_FILTERS: string[] = ['statusNotIn', 'ordering', 'search', 'page', 'offset'];

interface FiltersState {
    synchronized: boolean;
    lastView?: ViewUnion;
    queryParams: QueryParamsMap;
}

const initialState: FiltersState = {
    synchronized: !!window.location.search, // ignore params from storage if they are passed in query
    queryParams: {} as QueryParamsMap,
};

export const filtersSlice = createSlice({
    name: 'filters',
    initialState,
    reducers: {
        synced(state, action: PayloadAction<ViewUnion>) {
            state.lastView = action.payload;
            state.synchronized = true;
        },
        viewChanged(state, action: PayloadAction<ViewUnion>) {
            state.lastView = action.payload;
            state.synchronized = false;
        },
        changed(
            state,
            action: PayloadAction<{ view: ViewUnion; params: QueryParams; ignore?: string[] }>,
        ) {
            if (!state.synchronized) return;

            state.queryParams[action.payload.view] = omit(
                action.payload.params,
                NON_PERSISTENT_FILTERS.concat(action.payload.ignore ?? []),
            );
        },
    },
});

export const {
    synced: filtersSynced,
    viewChanged: filtersViewChanged,
    changed: filtersChanged,
} = filtersSlice.actions;

export const selectFiltersLastView = (state: RootState) => state.filters.lastView;
export const selectHasSyncedParams = (state: RootState) => state.filters.synchronized;
export const selectStoredQueryParams = (state: RootState) => state.filters.queryParams;

// sync query parameters with storage when opening a page or changing a view
export const syncQueryParams = (
    view: ViewUnion,
    syncer: (queryParams: QueryParamsMap) => void,
): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        // const lastView = selectFiltersLastView(state);
        const synchronized = selectHasSyncedParams(state);

        // if (lastView ? lastView === view : synchronized) return;
        if (synchronized) return;

        const params = selectStoredQueryParams(state);
        syncer(params);
        dispatch(filtersSynced(view));
    };
};

export const filtersReducer = createPersistReducer(filtersSlice, {
    whitelist: ['queryParams'],
});
