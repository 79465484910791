import { useController } from 'react-hook-form';
import { ControllerFieldProps } from '../types';
import { FieldControl, Hint } from '../common';
import { Switch, SwitchProps } from '../inputs';
import { getFieldState } from '../helpers';

export type SwitchFieldProps = SwitchProps & Omit<ControllerFieldProps, 'labelProps'>;

export const SwitchField = ({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    helpText,
    helpTextAsTooltip,
    tooltip: _tooltip,

    formControlProps,
    tooltipProps,
    errorProps,
    helpTextProps,
    children,
    ...input
}: SwitchFieldProps) => {
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const { field } = state;
    const tooltip = helpTextAsTooltip ? helpText : _tooltip;
    return (
        <FieldControl
            helpText={helpText}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            // display="flex"
            // alignItems="center"
            {...formControlProps}
        >
            <Switch
                {...input}
                {...field}
                isChecked={field.value}
                onChange={e => {
                    field.onChange(e.target.checked);
                    input?.onChange?.(e);
                }}
            >
                {label}
            </Switch>

            {!!tooltip && <Hint label={tooltip} {...tooltipProps} />}
            {children}
        </FieldControl>
    );
};
