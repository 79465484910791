import has from 'lodash/has';
import { enhancedApi as api } from './codegen';

api.enhanceEndpoints({
    endpoints: {
        discountsList: {
            providesTags: ['Discounts'],
        },
        discountsCreate: {
            async onQueryStarted({}, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;

                    dispatch(
                        api.util.updateQueryData('discountsList', {}, draft => {
                            const { results: discounts } = draft || { results: [] };
                            discounts.push(data);
                        }),
                    );
                } catch {}
            },
        },
        discountsPartialUpdate: {
            async onQueryStarted(
                { id, patchedDiscountSerializerV2Request },
                { dispatch, queryFulfilled },
            ) {
                // optimistic updates
                if (has(patchedDiscountSerializerV2Request, 'active')) {
                    const patchResult = dispatch(
                        api.util.updateQueryData('discountsList', {}, draft => {
                            const { results: discounts } = draft;
                            const index = discounts.findIndex(discount => discount.id === id);
                            if (index !== -1)
                                discounts[index].active = patchedDiscountSerializerV2Request.active;
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                } else {
                    try {
                        const { data } = await queryFulfilled;

                        dispatch(
                            api.util.updateQueryData('discountsRetrieve', { id }, draft => data),
                        );

                        dispatch(
                            api.util.updateQueryData('discountsList', {}, draft => {
                                const { results: discounts } = draft || { results: [] };
                                const index = discounts.findIndex(discount => discount.id === id);
                                if (index !== -1) {
                                    discounts[index] = data;
                                }
                            }),
                        );
                    } catch {
                        dispatch(api.util.invalidateTags(['Discounts']));
                    }
                }
            },
        },
        discountsDestroy: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData('discountsList', {}, draft => {
                        const { results: discounts } = draft;
                        const index = discounts.findIndex(discount => discount.id === id);
                        if (index !== -1) discounts.splice(index, 1);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        },
    },
});
