import { ReactNode } from 'react';
import { HStack, VStack, StackProps, Text } from '@chakra-ui/react';
import { MaybeRenderProp } from '@chakra-ui/react-utils';
import { runIfFn } from '@chakra-ui/utils';
import { forwardRef } from '@chakra-ui/system';
import { Organization, SuggestOrganization } from '@services/df/organizations';
import { SectionTile, SectionTileProps } from '@shared/components/section';

type Props = Pick<Organization, 'title' | 'domain'> &
    StackProps & {
        action?: ReactNode;
    };

const OrganizationCardLayout = ({ title, domain, action, children, ...rest }: Props) => (
    <HStack justifyContent="space-between" {...rest}>
        <VStack alignItems="flex-start">
            <Text textStyle="typography-md">
                {title} ({domain})
            </Text>
            {children}
        </VStack>
        {action}
    </HStack>
);

export type OrganizationCardActionArgs = Pick<Organization, 'id' | 'title' | 'domain'>;

type OrganizationCardProps = Organization &
    Omit<SectionTileProps, 'id' | 'children'> & {
        children?: MaybeRenderProp<OrganizationCardActionArgs>;
    };

export const OrganizationCard = forwardRef<OrganizationCardProps, 'div'>(
    (
        {
            id,
            title,
            domain,
            tax_number,
            description,
            vban_bank_transfer_enabled,
            children,
            ...rest
        },
        ref,
    ) => (
        <SectionTile ref={ref} {...rest}>
            <OrganizationCardLayout
                title={title}
                domain={domain}
                action={runIfFn(children, {
                    id,
                    title,
                    domain,
                })}
            >
                {tax_number && <Text textStyle="typography-sm">Tax number: {tax_number}</Text>}
                {description && <Text textStyle="typography-sm">Description: {description}</Text>}
            </OrganizationCardLayout>
        </SectionTile>
    ),
);

type OrganizationSuggestionCardProps = SuggestOrganization & SectionTileProps;

export const OrganizationSuggestionCard = forwardRef<OrganizationSuggestionCardProps, 'div'>(
    ({ title, domain, users_count, children, ...rest }, ref) => (
        <SectionTile ref={ref} {...rest}>
            <OrganizationCardLayout title={title} domain={domain} action={children}>
                <Text textStyle="typography-sm">Users: {users_count}</Text>
            </OrganizationCardLayout>
        </SectionTile>
    ),
);
