import _map from 'lodash/map';
import theme from '@app/styles/theme';
import { RootState } from '@app/types';
import { createSelector } from '@reduxjs/toolkit';
import { enhancedApi as api, MachineGroup } from '@services/df/machines-materials';
import { selectMachinesMaterials } from '@ducks/machines-materials';
import { selectPriceMatchMaterialId, selectPriceMatchMetadata } from '@ducks/price-match';
import { TechnologyIdsUnion } from '@shared/constants';
import { isMultiAxisMillingGroup } from '@shared/utils/technology-guard';

export const selectError = (state: RootState) =>
    state.priceMatchCalculation.calculationResult.isError;

export const selectIsUpdating = (state: RootState) =>
    state.priceMatchCalculation.calculationResult.isUpdating;

export const selectFields = (state: RootState, technologyId?: TechnologyIdsUnion) => {
    const fields = { ...state.priceMatchCalculation.calculationResult.fields };
    if (isMultiAxisMillingGroup(technologyId)) {
        fields['price_per_hour'] = 100;
    }

    return fields;
};

export const selectPrices = (state: RootState) =>
    state.priceMatchCalculation.calculationResult.prices;

export const selectModelsPriceMatching = createSelector(
    selectPriceMatchMetadata,
    selectPrices,
    (metadata, prices) => {
        return prices
            ? _map(metadata, 'title')
                  ?.map((label, index) => {
                      return {
                          label,
                          user_prices: [
                              prices.user_prices.prices_1[index],
                              prices.user_prices.prices_10[index],
                          ],
                          automatic_prices: [
                              prices.automatic_prices.prices_1[index],
                              prices.automatic_prices.prices_10[index],
                          ],
                      };
                  })
                  .sort((a, b) => {
                      return a.user_prices[0] - b.user_prices[0];
                  })
            : undefined;
    },
);

export const selectPriceMatchingLabels = createSelector(selectModelsPriceMatching, modelsPrices =>
    modelsPrices?.map(({ label }) => label),
);

export const selectChartData = createSelector(selectModelsPriceMatching, correctPrices => {
    if (!correctPrices) return;

    const userOne = [] as number[];
    const userTwo = [] as number[];
    const automaticOne = [] as number[];
    const automaticTwo = [] as number[];

    const userBgColorOne = [] as string[];
    const userBgColorTwo = [] as string[];
    const automaticBgColorOne = [] as string[];
    const automaticBgColorTwo = [] as string[];

    const userSwappedOne = [] as number[];
    const userSwappedTwo = [] as number[];
    const automaticSwappedOne = [] as number[];
    const automaticSwappedTwo = [] as number[];

    for (let price of correctPrices) {
        const { user_prices, automatic_prices } = price;
        // ** user_prices[1] - per ten **
        // ** user_prices[0] - per one **

        userOne.push(user_prices[0]);
        userTwo.push(user_prices[1]);
        automaticOne.push(automatic_prices[0]);
        automaticTwo.push(automatic_prices[1]);
        userBgColorTwo.push(theme.colors.success['100']);
        userBgColorOne.push(theme.colors.success['50']);

        automaticBgColorTwo.push(theme.colors.primary['100']);
        automaticBgColorOne.push(theme.colors.primary['50']);
    }

    // ** prices are swapped for correct display in the chart **

    userOne.forEach((perOne, i) => {
        const perTen = userTwo[i];

        if (perTen > perOne) {
            userTwo[i] = perOne;
            userOne[i] = perTen;
            userBgColorTwo[i] = theme.colors.success['50'];
            userBgColorOne[i] = theme.colors.success['100'];
        }
    });

    automaticOne.forEach((perOne, i) => {
        const perTen = automaticTwo[i];

        if (perTen > perOne) {
            automaticTwo[i] = perOne;
            automaticOne[i] = perTen;
            automaticBgColorTwo[i] = theme.colors.primary['50'];
            automaticBgColorOne[i] = theme.colors.primary['100'];
        }
    });

    // ** swapped real prices for tooltip **

    userSwappedOne.push(...userOne);
    userSwappedTwo.push(...userTwo);
    automaticSwappedOne.push(...automaticOne);
    automaticSwappedTwo.push(...automaticTwo);

    // ** difference swapped prices for correct display in the chart **

    userOne.forEach((perOne, i) => {
        const perTen = userTwo[i];

        if (perOne > perTen) {
            userOne[i] = perOne - perTen;
        } else {
            userTwo[i] = perTen - perOne;
        }
    });

    automaticOne.forEach((perOne, i) => {
        const perTen = automaticTwo[i];

        if (perOne > perTen) {
            automaticOne[i] = perOne - perTen;
        } else {
            automaticTwo[i] = perTen - perOne;
        }
    });

    return {
        user_two_slice: {
            prices: userTwo.map(price => price.toFixed(2)),
            realPrices: userSwappedTwo.map(price => price.toFixed(2)),
            bgColors: userBgColorTwo,
        },
        user_one_slice: {
            prices: userOne.map(price => price.toFixed(2)),
            realPrices: userSwappedOne.map(price => price.toFixed(2)),
            bgColors: userBgColorOne,
        },
        automatic_two_slice: {
            prices: automaticTwo.map(price => price.toFixed(2)),
            realPrices: automaticSwappedTwo.map(price => price.toFixed(2)),
            bgColors: automaticBgColorTwo,
        },
        automatic_one_slice: {
            prices: automaticOne.map(price => price.toFixed(2)),
            realPrices: automaticSwappedOne.map(price => price.toFixed(2)),
            bgColors: automaticBgColorOne,
        },
    };
});

export const selectPriceMatchMachine = createSelector(
    selectPriceMatchMaterialId,
    selectMachinesMaterials,
    (preselectedMaterialId, machines) => {
        if (!machines) return;

        for (const machine of machines) {
            const { materials, ...rest } = machine as MachineGroup & {
                technology: TechnologyIdsUnion;
            };
            const material = materials.find(material => {
                return material.id === preselectedMaterialId;
            });

            if (material) {
                return {
                    ...rest,
                    material,
                };
            }
        }
    },
);

// https://redux-toolkit.js.org/rtk-query/usage/usage-without-react-hooks#accessing-cached-data--request-status

// todo move to component level as hook, these values are only used in the epic

export const materialExecutionsSelector = createSelector(
    selectPriceMatchMaterialId,
    preselectedMaterialId =>
        api.endpoints.materialsExecutionsList.select({
            materialId: preselectedMaterialId!,
            executionType: 'layer_thickness',
        }),
);

export const selectLayerThickness = createSelector(
    (state: RootState) => state,
    materialExecutionsSelector,
    (state, selector) => selector(state).data,
);

export const machinesFeaturesSelector = createSelector(selectPriceMatchMachine, machine =>
    api.endpoints.machinesFeaturesList.select({ machineId: machine!.id }),
);

export const selectFeatures = createSelector(
    (state: RootState) => state,
    machinesFeaturesSelector,
    (state, selector) => selector(state).data,
);

export const machinesComplexityBracketingSelector = createSelector(
    selectPriceMatchMachine,
    machine => api.endpoints.machinesComplexityBracketingList.select({ machineId: machine!.id }),
);

export const selectComplexityBracketing = createSelector(
    (state: RootState) => state,
    machinesComplexityBracketingSelector,
    (state, selector) => selector(state).data,
);
