import { Icon, IconProps } from '@chakra-ui/react';

export interface CheckboxIconProps extends IconProps {
    isIndeterminate?: boolean;
    isChecked?: boolean;
}

export const CheckboxIcon = (props: CheckboxIconProps) => {
    const { isIndeterminate, isChecked, ...rest } = props;

    const d = isIndeterminate ? 'M2 4H6' : 'M6.66659 2L2.99992 5.66667L1.33325 4';

    return isChecked || isIndeterminate ? (
        <Icon viewBox="0 0 8 8" fill="none" {...rest}>
            <path
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
                d={d}
            />
        </Icon>
    ) : null;
};
