import { ComponentProps } from 'react';
import { SingleValue } from 'chakra-react-select';
import { Select, OptionType } from '../inputs';
import { withControllableField } from './field';

export const SelectField = withControllableField(Select, {
    inputValueAsString: true,
    transformOutput: value => {
        if (value && Array.isArray(value)) {
            return value.map(val => val.value);
        } else {
            return (value as SingleValue<OptionType>)?.value ?? null;
        }
    },
});

export type SelectFieldProps = ComponentProps<typeof SelectField>;
