import { Grid, HStack, Text } from '@chakra-ui/react';
import { ObtainCompaniesResponse } from '@services/df/auth';
import { getMediaUrl } from '@services/instance';
import { Thumb } from '@shared/components/thumb';
import { isAbsoluteUrl } from '@shared/utils/routes';
import { SignInForm } from '../ui';

const interactiveStyles = { bgColor: 'neutral.subtlest' };

interface Props {
    isLoading: boolean;
    companies: ObtainCompaniesResponse[];
    onCompanyClick: (company_name: string, _form?: SignInForm) => Promise<void>;
}

export const SignInCompanies = ({ companies, onCompanyClick, isLoading }: Props) => {
    return (
        <Grid rowGap="2">
            <Text textStyle="typography-sm" color="font.secondary">
                Select a company:
            </Text>

            {companies.map(({ company_id, company_logo, company_short_name, company_title }) => (
                <HStack
                    as="button"
                    disabled={isLoading}
                    spacing={4}
                    borderRadius="8"
                    p={2}
                    _hover={interactiveStyles}
                    _focus={interactiveStyles}
                    key={company_id}
                    onClick={async () => {
                        await onCompanyClick(company_short_name);
                    }}
                >
                    <Thumb
                        borderRadius="8"
                        flex="0 0 auto"
                        src={isAbsoluteUrl(company_logo) ? company_logo : getMediaUrl(company_logo)}
                        alt={company_title}
                    />
                    <Text textStyle="button-lg" align="left">
                        {company_title}
                    </Text>
                </HStack>
            ))}
        </Grid>
    );
};
