import * as yup from 'yup';
import { Link, Text } from '@chakra-ui/react';
import {
    CompoundFieldConfigs,
    CompoundFieldType,
    defineField,
    definePercentField,
    FieldType,
    objectToCheckboxesButtons,
} from '@shared/components/forms-v2';
import { PricingMethod } from '@services/df/codegen-enums';
import { PRICING_METHOD_LABELS } from '@services/df/codegen-enums-labels';
import { Option } from '@shared/types';

type ConfigArgs = {
    currencySign?: string;
    tagOptions?: Option<string>[];
    isBelong2D?: boolean;
};

export const getMaterialFieldsConfig = ({
    currencySign = '$',
    tagOptions = [],
    isBelong2D = false,
}: ConfigArgs = {}) => {
    const tags = defineField({
        type: FieldType.Select,
        name: 'tags',
        scheme: yup.array(yup.string()),
        props: {
            label: 'Tags',
            isMulti: true,
            options: tagOptions,
        },
    });

    const material_name = defineField({
        type: FieldType.Text,
        name: 'title',
        scheme: yup.string().ensure().trim().required(),
        props: {
            label: 'Material name',
        },
    });

    const pricing_method = defineField({
        type: FieldType.Radio,
        name: 'pricing_method',
        scheme: yup.string().default(PricingMethod.PerGram),
        props: {
            label: 'Material pricing method',
            buttons: objectToCheckboxesButtons(PRICING_METHOD_LABELS),
        },
    });

    const display_name = defineField({
        type: FieldType.Text,
        name: 'display_name',
        scheme: yup.string().default(''),
        props: {
            tooltip:
                'This is the name of the material your customer sees. Best to be as short and informative as possible, space on the widget is limited.',
            helpText: 'Input your own material name, like "hard tough plastic.',
            label: 'Display name',
        },
    });

    const note_for_user = defineField({
        type: FieldType.Text,
        name: 'note_for_user',
        scheme: yup.string().default(''),
        props: {
            label: 'Note for user',
            tooltip:
                'This note helps your customer to understand the specific properties of the material and make the right choice.',
        },
    });

    const spec_sheet_url = defineField({
        type: FieldType.Text,
        name: 'spec_sheet_url',
        scheme: yup.string().default(''),
        props: {
            label: 'Material Spec Sheet URL',
            tooltip: `This link will be clickable from the widget. Copy/paste the URL of the specs for this material from your material supplier's web page.`,
        },
    });

    const startup_cost = defineField({
        type: FieldType.Number,
        name: 'startup_cost',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Startup cost',
            helpText:
                'Startup cost reflects the cost of loading the tooling for this material inside of the machine',
            tooltip: `This is the minimum contribution you ask your customer to make for the work of changing
            the material in the printer. It is counted only once per material per order. If you want
            a minimum contribution for every copy, for example for cleaning it up after printing,
            please use the "per part fee" on the company page.`,
            rightAddon: currencySign,
        },
    });

    const startup_cost_per_sheet_percent = definePercentField({
        name: 'startup_cost_per_sheet_percent',
        defaultValue: 80,
        props: {
            label: 'Per sheet startup cost',
            helpText: 'Percentage of total sheet value',
            rightAddon: '%',
        },
    });

    const production_days = defineField({
        type: FieldType.Number,
        name: 'production_days',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Expected production time',
            tooltip: 'Expected average number of production days.',
            rightAddon: 'days',
        },
    });

    const sorting_priority = defineField({
        type: FieldType.Number,
        name: 'sorting_priority',
        scheme: yup.number().requiredNullEqualTo0(100).min(1).max(1000),
        props: {
            label: 'Sorting priority',
            tooltip: (
                <Text>
                    Enter a numerical value to determine this material position in the list. Items
                    with <strong>lower</strong> values are <strong>higher</strong> in the list.
                </Text>
            ),
        },
    });

    const programming_cost = defineField({
        type: FieldType.Number,
        name: 'programming_cost',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Programming cost',
            helpText:
                'Programming cost reflects the cost of generating the machine code for the model.',
            rightAddon: currencySign,
        },
    });

    const programming_cost_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'programming_cost_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Programming cost, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const cost_of_jigs_and_fixtures = defineField({
        type: FieldType.Number,
        name: 'cost_of_jigs_and_fixtures',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Jigs and fixtures cost',
            rightAddon: currencySign,
        },
    });

    const price = defineField({
        type: FieldType.Number,
        name: 'price',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price for model volume, without supports',
            tooltip: `This is the most traditional and straightforward, but least accurate
            parameter: it simply counts the geometric volume of the model itself,
            without supports and without any consideration of the space taken up in
            the printer envelope.`,
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const print_speed_multiplier = defineField({
        type: FieldType.Number,
        name: 'print_speed_multiplier',
        scheme: yup.number().requiredNullEqualTo0(100),
        props: {
            label: 'Printing speed multiplier',
            tooltip: `By inputting a number lower than 100 (%), the print time will be
            calculated at a lower print speed than you input on the printer page. A
            number higher than 100 (%) will increase the print speed.`,
            rightAddon: '%',
        },
    });

    const density = defineField({
        type: FieldType.Number,
        name: 'density',
        scheme: yup.number().requiredNullEqualTo0(isBelong2D ? 7.9 : 1),
        props: {
            label: 'Density of material',
            ...(!isBelong2D && {
                tooltip: `This is another approach: DigiFabster will calculate the real consumption of material,
            taking into account the filling rate and the supports. Since the calculator does not
            know the factual density of this material you're setting up now we will have to
            recalculate from the density of the nominal material in the calculation to this material
            ((nominal material weight/nominal material density)*typical density of this material).`,
            }),
            rightAddon: 'gram per cm³',
            title: 'Material consumption',
        },
    });

    const price_per_gram = defineField({
        type: FieldType.Number,
        name: 'price_per_gram',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per gram of material, per piece printing',
            tooltip: `Filament is often sold by the kilo (e.g. 20$ per kg). Here we ask the
            price per gram. Don't forget to divide your buying price+margin by 1000.`,
            rightAddon: currencySign,
        },
    });

    const minimum_price_per_part = defineField({
        type: FieldType.Number,
        name: 'minimum_price_per_part',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: 'Minimum cost per part',
            helpText: `The minimum price per part you will allow. If a customers
                part falls below this, the price will be rounded up to this value.`,
            rightAddon: currencySign,
        },
    });

    const price_per_gram_batch = defineField({
        type: FieldType.Number,
        name: 'price_per_gram_batch',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per gram of material, batch printing',
            tooltip: `If you use price per gram batch printing, the price per gram will be counted only
            once, however many copies of the model are ordered.`,
            rightAddon: currencySign,
        },
    });

    const price_per_gram_batch_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'price_per_gram_batch_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Price per gram of material, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const minimal_wall_thickness = defineField({
        type: FieldType.Number,
        name: 'minimal_wall_thickness',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Minimal wall thickness',
            tooltip: `A wall thinner than this will not be printed correctly, but will deform under its own
            weight or show up holes. A file containing too thin a wall will cause the widget to give
            off a warning, but the price will still be calculated. This parameter does not influence
            pricing.`,
            rightAddon: 'mm',
        },
    });

    const power_for_volume = defineField({
        type: FieldType.Number,
        name: 'power_for_volume',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Model volume discount exponent',
            labelProps: {
                children: (
                    <Link
                        variant="highlighted"
                        href="https://help.digifabster.com/en/articles/581187-using-exponents-for-volume-discounts"
                        isExternal
                    >
                        Help
                    </Link>
                ),
            },
            tooltip: `The chosen value can be anything between -1 and +1 except 0 (zero) Effect: The bigger
            the volume of the model, the cheaper each cubic centimeter.`,
            rightAddon: '^',
        },
    });

    const power_for_bb_volume = defineField({
        type: FieldType.Number,
        name: 'power_for_bb_volume',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Bounding box volume discount exponent',
            tooltip: `The chosen value can be anything between -1 and +1 except 0 (zero). Effect: The bigger
            the volume of the bounding box, the cheaper each cubic centimeter.`,
            rightAddon: '^',
        },
    });

    const filling_level_of_support = defineField({
        type: FieldType.Number,
        name: 'filling_level_of_support',
        scheme: yup.number().requiredNullEqualTo0(15),
        props: {
            label: 'Filling level of support',
            tooltip: `This parameter also has quite a big influence on material consumption, and the materials
            is quite expensive, so please consider carefully.`,
            rightAddon: '%',
        },
    });

    const density_of_support = defineField({
        type: FieldType.Number,
        name: 'density_of_support',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Density of support',
            tooltip: `The calculator does not know the factual density of the support material you're setting up so we will have to recalculate from the density of the nominal material in the calculation to this material ((nominal material weight/nominal material density)*typical density of this material).`,
            rightAddon: 'gram per cm³',
        },
    });

    const price_per_gram_of_support = defineField({
        type: FieldType.Number,
        name: 'price_per_gram_of_support',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per gram of support',
            tooltip: `Support material is usually sold by the liter, but here we ask the price per gram. Don't forget to divide your buying price+margin by 1000.`,
            rightAddon: currencySign,
        },
    });

    const cost_per_subtracted_material = defineField({
        type: FieldType.Number,
        name: 'cost_per_subtracted_material',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per subtracted material',
            labelProps: {
                children: (
                    <Link
                        variant="highlighted"
                        href="https://docs.google.com/presentation/d/10YqLy7_VNkPiH9QF5uTzD8UzX-NSbt6CNtlG8lqkxW8/edit#slide=id.p"
                        isExternal
                    >
                        Help
                    </Link>
                ),
            },
            tooltip: `The volume of the subtracted material will be multiplied by the inherent
                complexity of the model, raised to the power of the exponent and the
                price per cm3.`,
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const subtracted_material_coeff = defineField({
        type: FieldType.Number,
        name: 'subtracted_material_coeff',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Exponent for subtracted material',
            borderRadius: '4px !important',
        },
    });

    const subtracted_material: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            groupProps: { gap: '32px' },
            formControlProps: { marginInlineStart: '0 !important' },
        },
        fields: [cost_per_subtracted_material, subtracted_material_coeff],
    };

    const cost_per_surface_area = defineField({
        type: FieldType.Number,
        name: 'cost_per_surface_area',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price for surface area',
            labelProps: {
                children: (
                    <Link
                        variant="highlighted"
                        href="https://docs.google.com/presentation/d/10YqLy7_VNkPiH9QF5uTzD8UzX-NSbt6CNtlG8lqkxW8/edit#slide=id.p"
                        isExternal
                    >
                        Help
                    </Link>
                ),
            },
            tooltip: `The surface area of the model will be multiplied by the inherent
            complexity of the model, raised to the power of the exponent and the
            price per cm2.`,
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const surface_area_coeff = defineField({
        type: FieldType.Number,
        name: 'surface_area_coeff',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Exponent for surface area',
            tooltip: `The surface area of the model will be multiplied by the inherent
                complexity of the model, raised to the power of the exponent and the
                price per cm2.`,
            borderRadius: '4px !important',
        },
    });

    const time_increment_minutes_sheet_loading = defineField({
        type: FieldType.Number,
        name: 'time_increment_minutes_sheet_loading',
        scheme: yup.number().requiredNullEqualTo0(10),
        props: {
            rightAddon: 'min',
        },
    });

    const weight_increment_kg_sheet_loading = defineField({
        type: FieldType.Number,
        name: 'weight_increment_kg_sheet_loading',
        scheme: yup.number().requiredNullEqualTo0(50),
        props: {
            rightAddon: 'kg',
            borderTopLeftRadius: '4px !important',
            borderBottomLeftRadius: '4px !important',
        },
    });

    const surface_area: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '32px' },
        },
        fields: [cost_per_surface_area, surface_area_coeff],
    };

    const sheet_loading_time: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Sheet loading time (cutting)',
            hints: [
                {
                    hint: (
                        <Text
                            alignSelf="center"
                            textStyle="typography-xs"
                            fontWeight={700}
                            textTransform="uppercase"
                            color="font.secondary"
                        >
                            Every
                        </Text>
                    ),
                    positionIndex: 1,
                },
            ],
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [time_increment_minutes_sheet_loading, weight_increment_kg_sheet_loading],
    };

    const cost_per_volume_bb = defineField({
        type: FieldType.Number,
        name: 'cost_per_volume_bb',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per volume bounding box',
            labelProps: {
                children: (
                    <Link
                        variant="highlighted"
                        href="https://docs.google.com/presentation/d/10YqLy7_VNkPiH9QF5uTzD8UzX-NSbt6CNtlG8lqkxW8/edit#slide=id.p"
                        isExternal
                    >
                        Help
                    </Link>
                ),
            },
            tooltip: `The volume of the model will be multiplied by the inherent
                complexity of the model, raised to the power of the exponent and the
                price per cm3.`,
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const volume_bb_coeff = defineField({
        type: FieldType.Number,
        name: 'volume_bb_coeff',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Exponent for bounding box',
            borderRadius: '4px !important',
        },
    });

    const volume_bb: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '32px' },
        },
        fields: [cost_per_volume_bb, volume_bb_coeff],
    };

    const time_of_programming_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per line item, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per line item, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per line item',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '32px' },
        },
        fields: [time_of_programming_recurring, time_of_programming_non_recurring],
    };

    const time_of_programming_per_face_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_face_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per face, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_face_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_face_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per face, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_face: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per face',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '32px' },
        },
        fields: [
            time_of_programming_per_face_recurring,
            time_of_programming_per_face_non_recurring,
        ],
    };

    const time_of_programming_per_subtracted_material_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_subtracted_material_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm3 of subtracted material, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_subtracted_material_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_subtracted_material_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm3 of subtracted material, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_subtracted_material_exponent = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_subtracted_material_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Complexity enhancing exponent',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderRadius: '4px !important',
        },
    });

    const time_of_programming_per_subtracted_material: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per subtracted material',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [
            time_of_programming_per_subtracted_material_recurring,
            time_of_programming_per_subtracted_material_non_recurring,
            time_of_programming_per_subtracted_material_exponent,
        ],
    };

    const time_of_programming_per_surface_area_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_surface_area_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm2 of surface area, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_surface_area_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_surface_area_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm2 of surface area, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_surface_area_exponent = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_surface_area_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Complexity enhancing exponent',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderRadius: '4px !important',
        },
    });

    const time_of_programming_per_cm_of_perimeter_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_cm_of_perimeter_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm of perimeter, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_cm_of_perimeter_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_cm_of_perimeter_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm of perimeter, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_cm_of_perimeter_exponent = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_cm_of_perimeter_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Complexity enhancing exponent',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderRadius: '4px !important',
        },
    });

    const time_of_programming_per_area_bb_rectangle_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_area_bb_rectangle_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm2 of  bounding rectangle, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_area_bb_rectangle_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_area_bb_rectangle_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm2 of  bounding rectangle, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_area_bb_rectangle_exponent = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_area_bb_rectangle_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Complexity enhancing exponent',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderRadius: '4px !important',
        },
    });

    const time_of_programming_per_surface_area: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per surface area',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [
            time_of_programming_per_surface_area_recurring,
            time_of_programming_per_surface_area_non_recurring,
            time_of_programming_per_surface_area_exponent,
        ],
    };

    const time_of_programming_per_centimeter_of_perimeter: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per centimeter of perimeter',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [
            time_of_programming_per_cm_of_perimeter_recurring,
            time_of_programming_per_cm_of_perimeter_non_recurring,
            time_of_programming_per_cm_of_perimeter_exponent,
        ],
    };

    const time_of_programming_per_area_bb_rectangle: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per surface area bounding rectangle',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [
            time_of_programming_per_area_bb_rectangle_recurring,
            time_of_programming_per_area_bb_rectangle_non_recurring,
            time_of_programming_per_area_bb_rectangle_exponent,
        ],
    };

    const time_of_programming_per_volume_bb_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_volume_bb_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm3 of volume bounding box, recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
        },
    });

    const time_of_programming_per_volume_bb_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_volume_bb_non_recurring',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Minutes per cm3 of volume bounding box, non-recurring',
            rightAddon: 'min',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderStartRadius: '4px !important',
        },
    });

    const time_of_programming_per_volume_bb_exponent = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_per_volume_bb_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Complexity enhancing exponent',
            labelProps: { fontSize: '10px', lineHeight: '12px' },
            borderRadius: '4px !important',
        },
    });
    const time_of_programming_per_volume_bb: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Per volume bounding box',
            labelProps: { fontWeight: '600' },
            formControlProps: { marginInlineStart: '0 !important' },
            groupProps: { gap: '16px' },
        },
        fields: [
            time_of_programming_per_volume_bb_recurring,
            time_of_programming_per_volume_bb_non_recurring,
            time_of_programming_per_volume_bb_exponent,
        ],
    };

    const machinability = defineField({
        type: FieldType.Number,
        name: 'machinability',
        scheme: yup.number().requiredNullEqualTo0(100),
        props: {
            label: 'Machinability',
            tooltip: `A percentage smaller than 100 will proportionally increase
                machine time, a percentage bigger than 100 will decrease it.`,
            rightAddon: '%',
        },
    });

    const work_hardening_exponent = defineField({
        type: FieldType.Number,
        name: 'work_hardening_exponent',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Work hardening exponent',
            tooltip: `A measure for how quickly the material gains strength when it is being deformed`,
        },
    });

    const price_per_sq_cm = defineField({
        type: FieldType.Number,
        name: 'price_per_sq_cm',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per square cm of surface',
            tooltip: `Surface area of the model can account for complex work or finishing
            passes.`,
            rightAddon: currencySign,
        },
    });

    const price_per_cm_of_contour = defineField({
        type: FieldType.Number,
        name: 'price_per_cm_of_contour',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per centimeter of contour',
            tooltip: `Price per cm of model perimeter. Especially useful when majority of
            parts require 2.5D cutting.`,
            rightAddon: currencySign,
        },
    });

    const price_per_kilogram = defineField({
        type: FieldType.Number,
        name: 'price_per_kilogram',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per kilogram',
            rightAddon: currencySign,
        },
    });

    const cost_per_cm_of_perimeter = defineField({
        type: FieldType.Number,
        name: 'cost_per_cm_of_perimeter',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per centimeter of perimeter',
            rightAddon: `${currencySign} per cm`,
        },
    });

    const cm_of_perimeter_coeff = defineField({
        type: FieldType.Number,
        name: 'cm_of_perimeter_coeff',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Exponent for perimeter',
            borderRadius: '4px !important',
        },
    });

    const cm_of_perimeter: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            groupProps: { gap: '32px' },
            formControlProps: { marginInlineStart: '0 !important' },
        },
        fields: [cost_per_cm_of_perimeter, cm_of_perimeter_coeff],
    };

    const cost_per_surface_area_bb_rectangle = defineField({
        type: FieldType.Number,
        name: 'cost_per_surface_area_bb_rectangle',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per surface area bounding rectangle',
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const surface_area_bb_rectangle_coeff = defineField({
        type: FieldType.Number,
        name: 'surface_area_bb_rectangle_coeff',
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'Exponent for bounding rectangle',
            borderStartRadius: '4px !important',
        },
    });

    const surface_area_bb_rectangle: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            groupProps: { gap: '32px' },
            formControlProps: { marginInlineStart: '0 !important' },
        },
        fields: [cost_per_surface_area_bb_rectangle, surface_area_bb_rectangle_coeff],
    };

    const price_per_gram_of_support_batch = defineField({
        type: FieldType.Number,
        name: 'price_per_gram_of_support_batch',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Price per gram of support, batch printing',
            tooltip: `Support material is usually sold by the liter, but here we ask the price per gram. Don't forget to divide your buying price+margin by 1000.`,
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const bb_margin_x = defineField({
        type: FieldType.Number,
        name: 'bb_margin_x',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            placeholder: 'X',
        },
    });

    const bb_margin_y = defineField({
        type: FieldType.Number,
        name: 'bb_margin_y',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            placeholder: 'Y',
        },
    });

    const bb_margin_z = defineField({
        type: FieldType.Number,
        name: 'bb_margin_z',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            placeholder: 'Z',
        },
    });

    const extra_material_added_to_bounding_box = {
        type: CompoundFieldType.MultipleInput,
        fields: [bb_margin_x, bb_margin_y, bb_margin_z],
        props: {
            label: 'Extra material added to bounding box',
            tooltip: `The entered number will be added as an offset to resp. the length, the width
            and/or the height of the model, adding to both cycle time and material
            consumption`,
            rightAddon: 'mm',
        },
    };

    const extra_material_added_to_bounding_cylinder = {
        type: CompoundFieldType.MultipleInput,
        fields: [
            { ...bb_margin_x, props: { ...bb_margin_x.props, placeholder: 'Length' } },
            { ...bb_margin_y, props: { ...bb_margin_y.props, placeholder: 'Diameter' } },
        ],
        props: {
            label: 'Extra material added to bounding cylinder',
            tooltip: `The entered number will be added as an offset to resp. the length and/or the diameter of the model, adding to both cycle time and material consumption`,
            rightAddon: 'mm',
        },
    };

    const extra_material_added_to_bounding_rectangle = {
        type: CompoundFieldType.MultipleInput,
        fields: [bb_margin_x, bb_margin_y],
        props: {
            label: 'Extra material added to bounding rectangle',
            tooltip: `This is added to the XY dimensions of the model to get the area of material used
        for material cost calculations`,
            rightAddon: 'mm',
        },
    };

    return {
        material_name,
        pricing_method,
        display_name,
        note_for_user,
        spec_sheet_url,
        startup_cost,
        startup_cost_per_sheet_percent,
        production_days,
        sorting_priority,
        programming_cost,
        programming_cost_recurring,
        cost_of_jigs_and_fixtures,
        price,
        print_speed_multiplier,
        density,
        price_per_gram,
        minimum_price_per_part,
        price_per_gram_batch,
        price_per_gram_batch_recurring,
        minimal_wall_thickness,
        power_for_volume,
        power_for_bb_volume,
        filling_level_of_support,
        density_of_support,
        price_per_cm_of_contour,
        price_per_kilogram,
        price_per_gram_of_support_batch,
        price_per_gram_of_support,
        cost_per_surface_area_bb_rectangle,
        surface_area_bb_rectangle_coeff,
        surface_area_bb_rectangle,
        cost_per_cm_of_perimeter,
        cm_of_perimeter_coeff,
        cm_of_perimeter,
        cost_per_subtracted_material,
        subtracted_material_coeff,
        subtracted_material,
        cost_per_surface_area,
        surface_area_coeff,
        surface_area,
        sheet_loading_time,
        cost_per_volume_bb,
        volume_bb_coeff,
        volume_bb,
        time_of_programming,
        time_of_programming_per_face,
        time_of_programming_per_subtracted_material,
        time_of_programming_per_surface_area,
        time_of_programming_per_centimeter_of_perimeter,
        time_of_programming_per_area_bb_rectangle,
        time_of_programming_per_volume_bb,
        machinability,
        work_hardening_exponent,
        price_per_sq_cm,
        tags,
        bb_margin_x,
        bb_margin_y,
        bb_margin_z,
        extra_material_added_to_bounding_box,
        extra_material_added_to_bounding_cylinder,
        extra_material_added_to_bounding_rectangle,
        time_of_programming_recurring,
        time_of_programming_non_recurring,
        time_of_programming_per_face_non_recurring,
        time_of_programming_per_face_recurring,
        time_of_programming_per_surface_area_recurring,
        time_of_programming_per_surface_area_non_recurring,
        time_of_programming_per_surface_area_exponent,
        time_of_programming_per_volume_bb_non_recurring,
        time_of_programming_per_volume_bb_recurring,
        time_of_programming_per_volume_bb_exponent,
        time_of_programming_per_subtracted_material_non_recurring,
        time_of_programming_per_subtracted_material_recurring,
        time_of_programming_per_subtracted_material_exponent,
        time_of_programming_per_cm_of_perimeter_non_recurring,
        time_of_programming_per_cm_of_perimeter_recurring,
        time_of_programming_per_cm_of_perimeter_exponent,
        time_of_programming_per_area_bb_rectangle_non_recurring,
        time_of_programming_per_area_bb_rectangle_recurring,
        time_of_programming_per_area_bb_rectangle_exponent,
    };
};

export type MaterialFieldsConfig = ReturnType<typeof getMaterialFieldsConfig>;
export type MaterialCompoundFields =
    | 'extra_material_added_to_bounding_box'
    | 'extra_material_added_to_bounding_cylinder'
    | 'extra_material_added_to_bounding_rectangle';
export type MaterialCommonFields = Exclude<keyof MaterialFieldsConfig, MaterialCompoundFields>;
