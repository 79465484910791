import isNumber from 'lodash/isNumber';
import mapValues from 'lodash/mapValues';
import type { Options } from 'currency.js';
import Currency from 'currency.js';
import { ModelSerializerV2, ModelUnitsEnum } from '@services/df/models';
import { ModelUnits } from '@services/df/codegen-enums';
import { ConvertOptions, convertUnits, formatUnits } from '@shared/utils/convert-units';
import { getFileExtensionByUrl } from '@shared/utils/strings';
import { COMPLEXITY_BRACKETS } from '@entities';
import { ModelPerimeter } from '@ducks/models/ui/model-perimeter';

// todo move to entities/models/helpers

type ModelConvertOptions = ConvertOptions<NonNullable<ModelSerializerV2['units']>>;

// force mm to cm
export function getForcedUnits(target?: ModelUnitsEnum) {
    if (target === ModelUnits.Mm) {
        return ModelUnits.Cm;
    } else {
        return target;
    }
}

// volume
export function convertVolume(value: ModelSerializerV2['volume'], opts: ModelConvertOptions) {
    const options = mapValues(opts, unit => `${unit}3` as const);
    return value && convertUnits({ value, ...options });
}

export function formatVolume(volume: ModelSerializerV2['volume'], units: ModelUnitsEnum) {
    const formatOptions: Options = {};

    switch (units) {
        case ModelUnits.In: {
            formatOptions.precision = 4;
            break;
        }
    }

    return formatUnits(volume!, formatOptions);
}

export interface GetModelVolumeProps {
    model: ModelSerializerV2;
    unit_price?: number;
    convertTo?: ModelSerializerV2['units'];
}

export const getModelVolume = ({
    model: { geometry_type, volume: _volume, units },
    convertTo,
    unit_price = 0,
}: GetModelVolumeProps) => {
    const fromUnits = units!;
    const toUnits = getForcedUnits(convertTo || fromUnits)!;

    const volume = geometry_type !== 'flat_sheet' && _volume;
    const convertedVolume = volume ? convertVolume(volume, { from: fromUnits, to: toUnits }) : null;
    const formattedVolume = volume ? formatVolume(convertedVolume, toUnits) : '';
    const pricePerUnit = Currency(unit_price).divide(convertedVolume!).value;

    return {
        convertedVolume,
        formattedVolume,
        viewUnits: toUnits,
        volumeWithSymbol: `${formattedVolume} ${toUnits}³`,
        pricePerUnit,
    };
};

// surface
export function convertSurface(value: ModelSerializerV2['surface'], opts: ModelConvertOptions) {
    const options = mapValues(opts, unit => `${unit}2` as const);
    return value && convertUnits({ value, ...options });
}

export interface GetModelSurfaceProps {
    surface: ModelSerializerV2['surface'];
    units: ModelSerializerV2['units'];
    convertTo?: ModelSerializerV2['units'];
}

export const getModelSurface = ({ surface, units, convertTo }: GetModelSurfaceProps) => {
    const fromUnits = units!;
    const toUnits = getForcedUnits(convertTo || fromUnits)!;

    const convertedSurface = convertSurface(surface, { from: fromUnits, to: toUnits });
    const formattedSurface = convertedSurface ? formatUnits(convertedSurface) : '';

    return {
        convertedSurface,
        formattedSurface,
        viewUnits: toUnits,
        surfaceWithSymbol: `${formattedSurface} ${toUnits}²`,
    };
};

// perimeter
export interface GetModelPerimeterProps {
    perimeter: ModelSerializerV2['perimeter'];
    units: ModelSerializerV2['units'];
    convertTo?: ModelSerializerV2['units'];
}

export const getModelPerimeter = ({ perimeter, units, convertTo }: GetModelPerimeterProps) => {
    const fromUnits = units!;
    const toUnits = getForcedUnits(convertTo || fromUnits)!;

    const convertedPerimeter =
        perimeter && convertUnits({ value: perimeter, ...{ from: fromUnits, to: toUnits } });
    const formattedPerimeter = convertedPerimeter ? formatUnits(convertedPerimeter) : '';

    return {
        convertedPerimeter,
        formattedPerimeter,
        viewUnits: toUnits,
        perimeterWithSymbol: `${formattedPerimeter} ${toUnits}`,
    };
};

// sizes
export function convertSizes(size: ModelSerializerV2['size'], convertOptions: ModelConvertOptions) {
    return mapValues(size, value =>
        isNumber(value) ? convertUnits({ value, ...convertOptions }) : value,
    );
}

export function formatSizes(
    { x, y, z }: ModelSerializerV2['size'],
    units: ModelSerializerV2['units'],
) {
    const formatOptions: Options = {};

    switch (units) {
        case ModelUnits.In: {
            formatOptions.precision = 3;
            break;
        }
    }

    return [x, y, z]
        .filter(Boolean)
        .map(value => formatUnits(value, formatOptions))
        .join(' x ');
}

export interface GetModelSizesProps {
    size: ModelSerializerV2['size'];
    units: ModelSerializerV2['units'];
    convertTo?: ModelSerializerV2['units'];
}

export const getModelSizes = ({ size, units, convertTo }: GetModelSizesProps) => {
    const fromUnits = units!;
    const toUnits = convertTo || fromUnits;
    const convertedSizes = convertSizes(size, { from: fromUnits, to: toUnits });
    const formattedSizes = formatSizes(convertedSizes, toUnits);

    return {
        convertedSizes,
        formattedSizes,
        viewUnits: toUnits,
        sizeWithSymbol: `${formattedSizes} ${toUnits}`,
    };
};

// complexity bracketing
export interface GetModelComplexityBracketingProps {
    level: ModelSerializerV2['cnc_complexity_level'];
}

export const getModelComplexityBracketing = ({ level }: GetModelComplexityBracketingProps) => {
    const bracket = COMPLEXITY_BRACKETS.find(bracket => bracket.level === level);
    return {
        bracket,
        bracketLabel: `${level} (${bracket?.title})`,
    };
};

// props, todo change to DTO
export const getModelProps = ({ model }: { model: ModelSerializerV2 }) => {
    const fileExtension = getFileExtensionByUrl(model.file_model_viewer_url); // TODO model.extension?
    const isDxf = fileExtension === 'dxf';

    return {
        fileExtension,
        isDxf,
        hasViewer: model.file_model_viewer_url && model.is_processable && !isDxf,
    };
};
