import React from 'react';
import { Button, ButtonProps, VStack, StackProps, Text } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { VectorIcon } from '@shared/components/icons';

export const EMPTY_RESULT_BUTTON_PROPS = {
    size: 'sm',
    variant: 'ghost',
    colorScheme: 'gray',
    color: 'inherit',
    rightIcon: <VectorIcon transform="scale(-1)" />,
};

export const EmptyResultButton = forwardRef<ButtonProps, 'button'>(
    ({ children, ...props }, ref) => {
        return (
            <Button ref={ref} {...EMPTY_RESULT_BUTTON_PROPS} {...props}>
                <span>{children}</span>
            </Button>
        );
    },
);

interface Props extends StackProps {
    message?: string;
}

export const EmptyResult = forwardRef<Props, 'div'>(
    ({ message = 'There’re no results', children, ...rest }, ref) => (
        <VStack ref={ref} alignItems="center" spacing={3} py={20} {...rest}>
            <Text textStyle="typography-sm" color="gray.600">
                {message}
            </Text>
            {children}
        </VStack>
    ),
);
