import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/types';

type ModelsIds = number[];

const initialState: {
    selected: ModelsIds;
} = {
    selected: [],
};

export const ordersModelsSlice = createSlice({
    name: 'order/models',
    initialState,
    reducers: {
        setSelected(state, action: PayloadAction<ModelsIds>) {
            state.selected = action.payload;
        },
        resetSelected(state) {
            state.selected = [];
        },
    },
});

export const { setSelected: setSelectedOrderModels, resetSelected: resetSelectedOrderModels } =
    ordersModelsSlice.actions;
export const selectSelectedOrderModelsIds = (state: RootState) => state.order.models.selected;
