import merge from 'lodash/merge';
import { useMemo } from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { cx } from '@chakra-ui/utils';

const BASE_STYLES = {
    wordBreak: 'break-all',
    sx: {
        a: {
            color: 'primary.200',
        },
    },
} as const;

const NOTES_STYLES = merge(BASE_STYLES, {
    textStyle: 'typography-sm',
    sx: {
        'ol,ul': { mt: 2, pl: 4 },
        'ol li,ul li': { mt: 1, _first: { mt: 0 } },
        h2: { textStyle: 'typography-xxl' },
        h3: { textStyle: 'typography-xl' },
        h4: { textStyle: 'typography-lg' },
    },
} as const);

const STYLES_CONFIG = {
    notes: NOTES_STYLES,
};

interface Props extends BoxProps {
    data: string;
    config: keyof typeof STYLES_CONFIG;
}

export const CKEditorView = ({ data, config, className, sx: _sx, ...props }: Props) => {
    const styles = STYLES_CONFIG[config];
    const sx = useMemo(() => (_sx ? merge(styles.sx, _sx) : styles.sx), [styles.sx, _sx]);
    return (
        <Box
            dangerouslySetInnerHTML={{ __html: data }}
            className={cx('ck-content', className)}
            {...styles}
            {...props}
            sx={sx}
        />
    );
};
