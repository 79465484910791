import { AxiosRequestConfig } from 'axios';
import { Store } from '@reduxjs/toolkit';
import { ModelSerializerV2 } from '@services/df/models';
import {
    LoadModelsParams,
    ModelsUploadResponse,
    PaginatedResponse,
    UploadJobResponse,
    UploadStatusResponse,
} from '@shared/types';
import { UploadFileAcceptType } from '@shared/components/upload';
import { requestParamsSerializer, requestParamsSerializerNoIndices } from '@shared/utils/http';
import { updateProgress } from '@ducks/models-upload';
import { axiosInstance } from './instance';

export class ModelsService {
    static store: Store;

    static init() {
        return new ModelsService();
    }

    public loadUsersModelsList(params?: LoadModelsParams, config?: AxiosRequestConfig) {
        return axiosInstance.get<PaginatedResponse<ModelSerializerV2>>('/v2/users/models/', {
            params,
            paramsSerializer: requestParamsSerializer,
            ...config,
        });
    }

    public loadModelsList(params?: LoadModelsParams, config?: AxiosRequestConfig) {
        return axiosInstance.get<PaginatedResponse<ModelSerializerV2>>('/v2/models/', {
            params,
            paramsSerializer: requestParamsSerializer,
            ...config,
        });
    }

    createUploadJob() {
        return axiosInstance.post<UploadJobResponse>('/v2/upload_job/', {});
    }

    uploadModels(uj: string, acceptedFiles: UploadFileAcceptType[]) {
        const formData = new FormData();

        const files = acceptedFiles.map(model => model.file);

        files.forEach(file => formData.append('models', file));

        formData.append('upload_job_id', uj);

        return axiosInstance.post<ModelsUploadResponse[]>('/v2/upload_models/', formData, {
            onUploadProgress: progressEvent => {
                const { progress: _progress } = progressEvent;
                const progress = _progress ? Math.floor(_progress * 100) : 0;

                progress &&
                    ModelsService.store.dispatch(
                        updateProgress({
                            UUIDs: acceptedFiles.map(file => file.uuid),
                            progress,
                        }),
                    );
            },
        });
    }

    checkStatus(modelsIds: number[]) {
        return axiosInstance.get<UploadStatusResponse>('/v2/model_status/', {
            params: {
                model_id: modelsIds,
                format: 'json',
            },
            paramsSerializer: requestParamsSerializerNoIndices,
        });
    }
}
