import type { EditorConfig } from '@ckeditor/ckeditor5-core';

export const NOTES_CKEDITOR_CONFIG: EditorConfig = {
    toolbar: [
        'undo',
        'redo',
        '|',
        'bold',
        'italic',
        '|',
        'link',
        '|',
        'bulletedList',
        'numberedList',
        'outdent',
        'indent',
    ],
    link: {
        addTargetToExternalLinks: true,
    },
};
