import { cssVar } from '@chakra-ui/theme-tools';

const $width = cssVar('switch-track-width');
const $height = cssVar('switch-track-height');

export const SwitchStyles = {
    baseStyle: {
        container: { display: 'inline-flex', alignItems: 'center' },
        track: {
            _checked: {
                background: 'success.100',
            },
        },
        label: { lineHeight: 'none' },
    },
    sizes: {
        lg: {
            container: {
                [$width.variable]: '2.375rem',
                [$height.variable]: '1rem',
            },
            track: {
                p: '1',
            },
            label: { fontSize: 'md', marginStart: '3' },
        },
        md: {
            container: {
                [$width.variable]: '1.625rem',
                [$height.variable]: '0.75rem',
            },
            label: {
                fontSize: 'sm',
                '.chakra-popover-form__content &': { fontSize: 'xs', marginStart: '1' },
            },
        },
    },
    variants: {},
    defaultProps: {},
};
