import * as yup from 'yup';
import type { BaseSchema, NumberSchema } from 'yup';
import cloneDeep from 'lodash/cloneDeep';
import merge from 'lodash/merge';
// import { Override } from '@shared/types';
import { FieldConfig, FieldType } from '../types';
// import { FieldConfig, FieldType, FieldConfigsUnion } from '../types';

// todo return callable interface (check createAction example), e.g. myField.copy(rewriteProps),
//  replace redefineSchemePrors for copy method

type FieldApi<Config extends FieldConfig<Property>, Property extends FieldType = FieldType> = {
    updateProps: (props: Config['props']) => Config;
    // copy: (props?: Partial<Config>) => Config;
};

// todo refactor to class? add some methods e.g. 'getSingleFieldConfig'
//  + class wrapper or interface for config
//  FormHelper with getDefaultValues, overwriteDefaultValues, overwriteSubmittedData...
export function defineField<
    Config extends object,
    Property extends FieldType = FieldType,
    Plain extends boolean = false,
    Scheme extends BaseSchema = BaseSchema,
    // >(config: FieldConfig<Property, Plain, Scheme>) {
    // >(config: Override<Config, FieldConfig<Property, Plain, Scheme>>) {
    // >(config: Config & FieldConfig<Property, Plain, Scheme> & FieldApi<Config, Property>) {
>(config: Config & FieldConfig<Property, Plain, Scheme>) {
    // const field: FieldApi<Config> = {};
    // field.updateProps = props => {
    //     merge(config.props, props);
    //     return config;
    // };
    // field.copy = props => (props ? merge(cloneDeep(config), props) : cloneDeep(config));
    // return field;
    return {
        ...config,
        updateProps(props: FieldConfig<Property, Plain, Scheme>['props']) {
            // updateProps: (props: any) => {
            merge(config.props, props);
            return this;
        },
        default(value: any) {
            this.scheme = config.scheme?.clone({ default: value });
            return this;
        },
        copy(props?: Partial<Config & FieldConfig<Property, Plain, Scheme>>) {
            return props ? merge(cloneDeep(config), props) : cloneDeep(config);
        },
    };
}

// const bb_margin_z = defineField({
//     type: FieldType.Number,
//     name: 'bb_margin_z',
//     scheme: yup.number().required().default(0),
//     props: {
//         placeholder: 'Z',
//     },
// });
// const dsfs = bb_margin_z.updateProps({ min: 3 });
// dsfs.updateProps();

// todo partial (defineField with predefined values)
export function definePercentField({
    defaultValue = 0,
    props,
    ...rest
}: Omit<FieldConfig<FieldType.Number, false>, 'type' | 'scheme'> & {
    defaultValue?: number;
}): FieldConfig<FieldType.Number, false, NumberSchema> {
    const scheme = yup.number().min(0).max(100).default(defaultValue);
    const baseProps = {
        min: 0,
        max: 100,
        rightAddon: '%',
    };
    return {
        type: FieldType.Number,
        props: {
            ...baseProps,
            ...props,
        },
        ...rest,
        scheme,
    };
}

// type sdfsdf = FieldConfig<FieldType.Number, boolean, NumberSchema> extends FieldConfigsUnion
//     ? true
//     : false;
