import omit from 'lodash/omit';
import { useNumberInput, UseNumberInputProps } from '@chakra-ui/react';
import { useFormControlProps } from '@chakra-ui/form-control';
import { ThemingProps, forwardRef, HTMLChakraProps } from '@chakra-ui/system';
import { Input, WithInputGroupProps } from './input';

interface InputOptions extends Pick<HTMLChakraProps<'input'>, 'placeholder'> {
    /**
     * The border color when the input is focused. Use color keys in `theme.colors`
     * @example
     * focusBorderColor = "blue.500"
     */
    focusBorderColor?: string;
    /**
     * The border color when the input is invalid. Use color keys in `theme.colors`
     * @example
     * errorBorderColor = "red.500"
     */
    errorBorderColor?: string;
    /**
     * If `true`, the input element will span the full width of its parent
     *
     * @deprecated
     * This component defaults to 100% width,
     *  please use the props `maxWidth` or `width` to configure
     */
    isFullWidth?: boolean;
}

export type NumberInputProps = UseNumberInputProps &
    ThemingProps<'Input'> &
    InputOptions &
    WithInputGroupProps;

export const NumberInput = forwardRef<NumberInputProps, 'input'>((props, ref) => {
    const controlProps = useFormControlProps(props);
    const { getInputProps, htmlProps: otherProps } = useNumberInput(controlProps);
    return (
        <Input
            {...getInputProps(
                omit(controlProps, ['onChange', 'onKeyDown', 'onFocus', 'onBlur']),
                ref,
            )}
            pattern="[\+\-]?([0-9]*[.])?[0-9]+([eE][\+\-]?[0-9]+)?" // default chakra pattern is incorrect for negative numbers, for example '-23.89'
            {...otherProps}
        />
    );
});
