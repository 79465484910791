import has from 'lodash/has';
import pick from 'lodash/pick';
import { RootState } from '@app/types';
import { createSelector } from '@reduxjs/toolkit';
import { createColorOption } from '@shared/components/forms-v2';
import { MaterialUnion, MaterialWithBarStockUnion, MaterialWithBendUnion } from '@shared/types';
import { additionalBendFields } from './types';

export const selectIsLoadingTechnologies = (state: RootState) =>
    state.editMaterial.isLoadingTechnologies;
export const selectIsLoadingMaterial = (state: RootState) => state.editMaterial.isLoadingMaterial;
export const selectMaterialIsExist = (state: RootState) =>
    Boolean(state.editMaterial.currentMaterial);

export const selectIsFinishedMaterialEdit = (state: RootState) =>
    state.editMaterial.isFinishedEditMaterial;

export const selectIsFinishedMaterialEditEntities = (state: RootState) =>
    state.editMaterial.isFinishedEditMaterialEntities;

export const selectIsLoadingMaterialEditEntities = (state: RootState) =>
    state.editMaterial.isLoadingEditMaterialEntities;

export const selectTechnologies = (state: RootState) => state.editMaterial.technologies;
export const selectCurrentMaterial = (state: RootState) => state.editMaterial.currentMaterial;

// select nested entities in material

export const selectLayerThickness = createSelector(
    selectCurrentMaterial,
    material => material!.execution.filter(item => item.execution_type === 'layer_thickness') ?? [],
);

export const selectFilling = createSelector(
    selectCurrentMaterial,
    material => material!.execution.filter(item => item.execution_type === 'filling') ?? [],
);

export const selectColors = createSelector(
    selectCurrentMaterial,
    material => material!.execution.filter(item => item.execution_type === 'color') ?? [],
);

export const selectCustomOptions = createSelector(
    selectCurrentMaterial,
    material => material!.extra_fieldsets,
);

export const selectBarStocks = createSelector(
    selectCurrentMaterial,
    material => (material as MaterialWithBarStockUnion).bar_stocks,
);

export const selectBends = createSelector(selectCurrentMaterial, material => {
    return pick(material as MaterialWithBendUnion, ['bends', ...additionalBendFields]);
});

export const selectPostProduction = createSelector(
    selectCurrentMaterial,
    material => material?.postproduction,
);

export const selectPostProductionValues = createSelector(selectCurrentMaterial, material =>
    material?.postproduction.map(item => {
        const organization_price = {
            price: item.price,
            price_units: { value: item.price_units },
        };
        const colors = item?.colors?.map(createColorOption) || [];

        return { ...item, organization_price, colors };
    }),
);

export const selectPickMaterialFieldsByKey = <M extends MaterialUnion>(
    paths: (keyof M)[] | keyof M,
) =>
    createSelector(selectCurrentMaterial, (material): Pick<M, keyof M> | null => {
        if (!material && has(material, paths)) {
            return null;
        }
        return pick(material as M, paths);
    });
