import isString from 'lodash/isString';
import React, { MutableRefObject, ReactNode } from 'react';
import {
    Button,
    Divider,
    HStack,
    StackProps,
    Popover,
    PopoverProps,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { runIfFn } from '@chakra-ui/utils';
import { FieldStyles } from '@shared/components/forms-v2';
import { FCC } from '@shared/types';
import { composeNodeId } from '@shared/utils/strings';
import { FormPopoverContent } from './form-popover-content';
import { POPOVER_FIELD_STYLES } from './constants';

export const PlainPopoverHeader = forwardRef<TextProps, 'p'>(({ children, ...rest }, ref) => (
    <Text ref={ref} textStyle="typography-sm" fontWeight="600" color="darkblue" {...rest}>
        {children}
    </Text>
));

interface PlainPopoverHeadProps extends StackProps {
    label: string;
}

export const PlainPopoverHead = forwardRef<PlainPopoverHeadProps, 'div'>(
    ({ label, children, ...rest }, ref) => (
        <HStack ref={ref} justify="space-between" {...rest}>
            <PlainPopoverHeader>{label}</PlainPopoverHeader>
            {children}
        </HStack>
    ),
);

interface Props {
    triggerNode: PopoverProps['children'];
    header?: ReactNode;
    headerActions?: ReactNode;
    onConfirm?: () => void;
    onClose?: () => void;
    popoverId: string;
    buttonText?: string;
    closeOnConfirm?: boolean;
    buttonIsDisabled?: boolean;
    showCloseButton?: boolean;
    styles?: FieldStyles;
    popoverPortalRef?: MutableRefObject<HTMLElement | null>;
}

export const PlainPopover: FCC<Props> = ({
    triggerNode,
    header,
    headerActions,
    children,
    onConfirm,
    onClose: onCloseProps,
    popoverId,
    buttonText = 'apply',
    closeOnConfirm = true,
    buttonIsDisabled = false,
    showCloseButton = false,
    styles = POPOVER_FIELD_STYLES,
    popoverPortalRef,
}) => {
    return (
        <Popover placement="bottom-start" isLazy onClose={onCloseProps}>
            {({ isOpen, onClose, forceUpdate }) => (
                <>
                    <PopoverTrigger>
                        {runIfFn(triggerNode, {
                            isOpen,
                            onClose,
                            forceUpdate,
                        })}
                    </PopoverTrigger>
                    <Portal containerRef={popoverPortalRef}>
                        <FormPopoverContent showCloseButton={showCloseButton} styles={styles}>
                            {header && (
                                <PopoverHeader>
                                    {isString(header) ? (
                                        <PlainPopoverHead label={header}>
                                            {headerActions}
                                        </PlainPopoverHead>
                                    ) : (
                                        header
                                    )}
                                    <Divider mt={2} borderColor="gray.200" />
                                </PopoverHeader>
                            )}

                            <PopoverBody>{children}</PopoverBody>
                            <PopoverFooter>
                                <Button
                                    id={composeNodeId(popoverId, 'apply')}
                                    // forced styles to prevent overwriting with ButtonGroup
                                    variant="solid"
                                    colorScheme="primary"
                                    size="sm"
                                    isDisabled={buttonIsDisabled}
                                    onClick={async () => {
                                        await onConfirm?.();
                                        closeOnConfirm && onClose();
                                    }}
                                >
                                    {buttonText}
                                </Button>
                            </PopoverFooter>
                        </FormPopoverContent>
                    </Portal>
                </>
            )}
        </Popover>
    );
};
