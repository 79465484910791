import { Icon, IconProps } from '@chakra-ui/react';

// This icon is looks like a opened Lock

export const UnlockIcon = (props: IconProps) => (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
        <rect
            x="3"
            y="11"
            width="18"
            height="11"
            rx="2"
            ry="2"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            />
        <path
            d="M7 11V7a5 5 0 0 1 9.9-1"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
