export const CKEditorStyles = `
.ck-body-wrapper {
    --ck-z-modal: 9999;
}

.ck-editor .ck-content {
    min-height: 240px;
}

.ck-content {
    > *:first-child {
        margin-top: 0;
    }
    
    > *:last-child {
        margin-bottom: 0;
    }

    ol,
    ul {
        padding: revert;
    }

    * {
        line-height: initial;
    }

    a {
        color: revert;
    }

    h2 {
        font-size: 1.875rem;
    }

    h3 {
        font-size: 1.5rem;
    }

    h4 {
        font-size: 1.172rem;
    }
}
`;
