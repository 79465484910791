import { createSlice } from '@reduxjs/toolkit';
import { enhancedApi as api } from '@services/df/auth';
import { responseWithRedirect } from '@shared/utils/http';
import { RootState } from '@app/types';

interface AuthState {
    token: string;
    // todo remove this flag after migrating auth to LS with token
    initialSuccess: boolean;
    isAuthenticated: boolean;
    shouldLogin: boolean;
}

const initialState: AuthState = {
    token: '',
    initialSuccess: false,
    isAuthenticated: false,
    shouldLogin: false,
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            api.endpoints.authCreateSessionWithTokenCreate.matchFulfilled,
            (state, { payload }) => {
                state.token = payload.token;
                state.isAuthenticated = true;
                state.shouldLogin = false;
            },
        );
        builder.addMatcher(api.endpoints.auth.matchFulfilled, (state, { payload, meta }) => {
            state.initialSuccess = true;

            // there is no current logged session
            if (responseWithRedirect(meta)) {
                state.shouldLogin = true;
            } else {
                state.token = payload.token;
                state.isAuthenticated = true;
                state.shouldLogin = false;
            }
        });
    },
});

export const selectToken = (state: RootState) => state.auth.token;
export const selectShouldLogin = (state: RootState) => state.auth.shouldLogin;
export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated;
export const selectAppInitialSuccess = (state: RootState) => state.auth.initialSuccess;
