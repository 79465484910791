import { combineReducers } from '@reduxjs/toolkit';
import { configReducer } from './config';
import { modelsSlice } from './models';
import { calculationResultSlice } from './result';
// import { metadataReducer } from './metadata';

export * from './constants';
export * from './config';
export * from './result';
// export * from './helpers';
export * from './models';
// export * from './metadata';

// reset with createReducer
export const priceTweakerReducer = combineReducers({
    config: configReducer,
    calculationResult: calculationResultSlice.reducer,
    models: modelsSlice.reducer,
    // metadata: metadataReducer,
});
