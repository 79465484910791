import { Suspense } from 'react';
import { useNavigate } from 'react-router';
import { Modal, ModalProps, ModalOverlay, ModalContent, ModalCloseButton } from '@chakra-ui/react';
import { PreloaderBox } from '@shared/components/preloader';

// todo move to common-modals

interface Props extends Omit<ModalProps, 'isOpen' | 'onClose'> {}

export const ContextualModal = ({ children, ...props }: Props) => {
    const navigate = useNavigate();

    function onClose() {
        navigate(-1);
    }

    return (
        <Modal
            variant="alert"
            size="full"
            lockFocusAcrossFrames={false}
            {...props}
            isOpen
            onClose={onClose}
        >
            <ModalOverlay>
                <ModalContent>
                    <Suspense fallback={<PreloaderBox in />}>{children}</Suspense>
                    <ModalCloseButton position="fixed" top="15px" right="25px" />
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
