export const ROUTES = {
    // auth
    SIGN_IN: '/manage/sign_in/',
    COMPANY_LOGIN: '/:company/manage/login/',

    // machines-materials
    MACHINES_MATERIALS: '/manage/machines-materials/',
    EDIT_MATERIAL: '/manage/machines-materials/materials/material/:id',
    CREATE_MATERIAL: '/manage/machines-materials/:id',
    CREATE_PRINTER: '/manage/machines-materials/printer/',
    EDIT_PRINTER: '/manage/machines-materials/materials/printer/:id',

    ASSISTED_SETUP: '/manage/materials/assisted-setup/3d/',

    PRICE_MATCHING: '/manage/price-matching/',
    SETUP_PRICE_MATCHING: '/manage/price-matching/setup/',
    PRICE_TWEAKER_MAIN: '/manage/price-tweaker/',
    PRICE_TWEAKER_PURCHASE: '/manage/price-tweaker/purchase/:id',

    // settings
    WIDGET_SETTINGS: '/manage/settings/widget/',
    COMPANY_SETTINGS: '/manage/settings/company/',
    BILLING_SETTINGS: '/manage/settings/billing/',
    PERSONAL_INFO_SETTINGS: '/manage/settings/profile/',

    // orders
    ORDERS_MAIN: '/manage/quotes/',
    ORDERS_DRAFTS: '/manage/drafts/',
    ORDERS_CARTS: '/manage/carts/',
    ORDERS_BOARD: '/manage/quotes/board',
    ORDERS_LINE_ITEMS: '/manage/quotes/parts',
    ORDERS_DETAILS: '/manage/quotes/:id/',

    // users
    USERS_ROLES_MAIN: '/manage/users-roles/',
    USERS_ROLES_ADD_USER: '/manage/users-roles/add-user/',

    // customers
    CLIENTS_MAIN: '/manage/customers/',
    CLIENTS_DETAILS: '/manage/customers/:id/',
    ORGANIZATIONS_MAIN: '/manage/organizations/',
    ORGANIZATIONS_DETAILS: '/manage/organizations/:id/',

    // models
    MODELS_MAIN: '/manage/files/',
    MODELS_DETAILS: '/manage/files/:id/',

    // discounts
    DISCOUNTS: '/manage/discounts/',

    // other
    DASHBOARD: '/manage/dashboard/',
    INSTALL_WIDGET: '/manage/install-widget/',

    // not found
    NOT_FOUND: '/*',
} as const;
