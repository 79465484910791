import { cssVar } from '@chakra-ui/theme-tools';

export const TooltipStyles = {
    baseStyle: {
        py: '1.5',
        fontSize: 'xs',
        fontWeight: '400',
    },
    variants: {
        common: {
            color: 'white',
            [cssVar('tooltip-bg').variable]: `colors.secondary.accent`,
        },
        inverse: {
            color: 'font.primary',
            [cssVar('tooltip-bg').variable]: `colors.neutral.subtlest`,
        },
    },
    defaultProps: {
        variant: 'common',
    },
};
