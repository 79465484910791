import { AppThunk } from '@app/types';
import { fetchMachines, fetchMaterialPresets } from './reducers';
import { selectMaterialPresets } from './selectors';

export const initMaterialCreatePage = (): AppThunk => {
    return (dispatch, getState) => {
        const state = getState();
        const materialPesets = selectMaterialPresets(state);

        if (!materialPesets.length) {
            dispatch(fetchMaterialPresets());
        }

        dispatch(fetchMachines());
    };
};
