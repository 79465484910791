import omit from 'lodash/omit';
import { Grid, GridProps } from '@chakra-ui/react';
import { FileInput, FileInputProps } from './file-input';
import { FilesList } from './files-list';
import { useFilesAttachment, UseFilesAttachmentProps } from './hooks';

interface Props extends UseFilesAttachmentProps {
    id: string;
    name?: string;
    label?: string;
    disabled?: boolean;
    buttonProps?: FileInputProps['buttonProps'];
    gridProps?: GridProps;
}

// todo context useFilesAttachment
export const FilesAttachment = ({ id, name, label, buttonProps, gridProps, ...rest }: Props) => {
    const { getInputProps, attachments, disabled, handleRemove } = useFilesAttachment(rest);

    return (
        <Grid alignItems="flex-start" justifyItems="flex-start" gap={2} {...gridProps}>
            {!disabled && (
                <FileInput
                    label={label}
                    buttonProps={buttonProps}
                    {...omit(getInputProps({ id, name }), ['size', 'style', 'tabIndex'])}
                />
            )}
            {Boolean(attachments.length) && (
                <FilesList attachments={attachments} handleRemove={handleRemove} />
            )}
        </Grid>
    );
};
