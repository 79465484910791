import { ReactNode, useEffect, useState } from 'react';
import { Grid, HStack, ModalBody, Spacer } from '@chakra-ui/react';
import { usePagination } from '@ajna/pagination';
import { Organization, useOrganizationsListQuery } from '@services/df/organizations';
import { OrganizationCard, OrganizationCardActionArgs } from '@entities';
import { EmptyResult } from '@shared/components/empty-result';
import { SearchInput } from '@shared/components/forms-v2';
import {
    BasicPagination,
    PaginationLimitPerPage,
    getPaginationRequestParams,
    PAGINATION_CONFIG,
} from '@shared/components/pagination';
import { PreloaderBox } from '@shared/components/preloader';
import { SectionModal, SectionModalProps } from '@shared/components/section';
import { FCC, PartiallyOptional } from '@shared/types';

const ORGANIZATION_LIST_PER_PAGE_LIMITS = [5, 10, 15, 20] as const;

type OrganizationListProps = {
    action: (props: OrganizationCardActionArgs) => ReactNode;
    perPageLimits?: number[];
};

const defaultList: Organization[] = [];

export const OrganizationList: FCC<OrganizationListProps> = ({
    action,
    perPageLimits = ORGANIZATION_LIST_PER_PAGE_LIMITS,
    children,
}) => {
    const [total, setTotal] = useState<number | undefined>(undefined);
    const { currentPage, setCurrentPage, pageSize, setPageSize, pagesCount, pages } = usePagination(
        {
            total,
            limits: PAGINATION_CONFIG,
            initialState: { pageSize: perPageLimits[0], currentPage: 1 },
        },
    );

    const [search, setSearch] = useState('');
    const { data, isLoading, isFetching, totalOrganizations } = useOrganizationsListQuery(
        { search, ...getPaginationRequestParams(currentPage, pageSize) },
        {
            // refetchOnMountOrArgChange: true,
            selectFromResult: ({ data, isLoading, isFetching }) => ({
                data: data?.results ?? defaultList,
                totalOrganizations: data?.count ?? 0,
                isLoading,
                isFetching,
            }),
        },
    );

    useEffect(() => {
        setTotal(totalOrganizations);
    }, [totalOrganizations]);

    return (
        <Grid rowGap={6}>
            <SearchInput value={search} onFinalize={setSearch} />
            <PreloaderBox in={isLoading || isFetching}>
                <Grid minH={16} rowGap={2}>
                    {data.length ? (
                        data.map((organization, index) => (
                            <OrganizationCard key={index} {...organization}>
                                {action}
                            </OrganizationCard>
                        ))
                    ) : isLoading ? null : (
                        <EmptyResult />
                    )}
                </Grid>
            </PreloaderBox>

            {children}

            {!!total && (
                <HStack justifyContent="space-between">
                    <BasicPagination
                        pages={pages}
                        pagesCount={pagesCount}
                        pageSize={pageSize}
                        currentPage={currentPage}
                        onPageChange={setCurrentPage}
                        total={totalOrganizations}
                    />
                    <Spacer />
                    <PaginationLimitPerPage
                        limits={perPageLimits}
                        total={totalOrganizations}
                        onChange={limit => {
                            setCurrentPage(1);
                            setPageSize(limit);
                        }}
                    />
                </HStack>
            )}
        </Grid>
    );
};

export const OrganizationLibrary = ({
    action,
    perPageLimits,
    header = 'Select the company',
    children,
    ...rest
}: PartiallyOptional<SectionModalProps, 'header' | 'children'> & OrganizationListProps) => {
    return (
        <SectionModal size="3xl" isCentered={false} header={header} {...rest}>
            <ModalBody pb={8}>
                <OrganizationList action={action} perPageLimits={perPageLimits} />
                {children}
            </ModalBody>
        </SectionModal>
    );
};
