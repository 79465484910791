import {
    Radio as ChakraRadio,
    RadioProps as ChakraRadioProps,
    RadioGroup,
    RadioGroupProps,
} from '@chakra-ui/react';
import { useFieldStyles } from '../hooks';

const orientationStyles = {
    vertical: {
        flexDirection: 'column',
        alignItems: 'flex-start',
        gap: '2',
    },
    horizontal: {
        flexDirection: 'row',
        gap: '8',
    },
};

export type RadioProps = Omit<RadioGroupProps, 'children'> & {
    buttons: Array<ChakraRadioProps>;
    orientation?: 'horizontal' | 'vertical';
};

export const Radio = ({ buttons, orientation = 'horizontal', ..._props }: RadioProps) => {
    // @ts-ignore
    const props = useFieldStyles('radio', { ...orientationStyles[orientation], ..._props });
    return (
        <RadioGroup display="flex" {...props}>
            {buttons.map(radio => (
                <ChakraRadio key={radio.value} {...radio}>
                    {radio.children}
                </ChakraRadio>
            ))}
        </RadioGroup>
    );
};
