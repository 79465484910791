import { Icon, IconProps } from '@chakra-ui/react';

export const DollarIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M8 1.58337V14.4167" />
        <path d="M10.9167 3.91663H6.54167C6.00018 3.91663 5.48088 4.13173 5.09799 4.51462C4.7151 4.8975 4.5 5.41681 4.5 5.95829C4.5 6.49978 4.7151 7.01908 5.09799 7.40197C5.48088 7.78486 6.00018 7.99996 6.54167 7.99996H9.45833C9.99982 7.99996 10.5191 8.21506 10.902 8.59795C11.2849 8.98084 11.5 9.50014 11.5 10.0416C11.5 10.5831 11.2849 11.1024 10.902 11.4853C10.5191 11.8682 9.99982 12.0833 9.45833 12.0833H4.5" />
    </Icon>
);
