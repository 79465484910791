import { PAGINATION_CONFIG } from './constants';

export function getTotalNumPages(
    totalCount: number,
    pageSize: number = PAGINATION_CONFIG.pageSize,
) {
    pageSize = pageSize ?? PAGINATION_CONFIG.pageSize;
    return Math.ceil(totalCount / pageSize);
}

export function getPaginationOffset(
    pageNumber: number = 1,
    pageSize: number = PAGINATION_CONFIG.pageSize,
) {
    return Math.max(pageNumber - 1, 0) * pageSize;
}

export function getPaginationRequestParams(
    pageNumber: number = 1,
    limit: number = PAGINATION_CONFIG.pageSize,
) {
    return {
        limit,
        page: pageNumber,
        offset: getPaginationOffset(pageNumber, limit),
    };
}
