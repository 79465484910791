import { Icon, IconProps } from '@chakra-ui/react';

export const CircleArrowIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M6 7.5C6 7.65 5.95 7.75 5.85 7.85L3.85 9.85C3.65 10.05 3.35 10.05 3.15 9.85L1.15 7.85C0.95 7.65 0.95 7.35 1.15 7.15C1.35 6.95 1.65 6.95 1.85 7.15L3.5 8.8L5.15 7.15C5.35 6.95 5.65 6.95 5.85 7.15C5.95 7.25 6 7.35 6 7.5Z"
            fill="currentColor"
        />
        <path
            d="M15 8C15 11.3 12.3 14 9 14C7.5 14 6.05 13.45 4.95 12.4C4.75 12.2 4.75 11.9 4.9 11.7C5.1 11.5 5.4 11.5 5.6 11.65C6.55 12.55 7.75 13 9 13C11.75 13 14 10.75 14 8C14 5.25 11.75 3 9 3C6.25 3 4 5.25 4 8L4 8.5C4 8.8 3.8 9 3.5 9C3.2 9 3 8.8 3 8.5L3 8C3 4.7 5.7 2 9 2C12.3 2 15 4.7 15 8Z"
            fill="currentColor"
        />
    </Icon>
);
