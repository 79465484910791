import { cx } from '@chakra-ui/utils';
import { getClassNameFromStrings } from '@shared/utils/strings';
import { TextIndicator, TextIndicatorProps } from '@shared/components/typography';
import { useOrderStatusLabels } from '../hooks';

const COLORS_BY_GROUP = {
    waiting: 'warning',
    cancelled: 'error',
    ready: 'success',
} as const;

interface Props extends TextIndicatorProps {
    code: string;
}

export const OrderStatus = ({ code, ...rest }: Props) => {
    const orderStatusOptions = useOrderStatusLabels();
    const { group, label } = orderStatusOptions[code] || {};
    const type = COLORS_BY_GROUP[group as keyof typeof COLORS_BY_GROUP];
    const labelClass = getClassNameFromStrings('order_status', code);

    return (
        // Do not remove classes they are used to apply custom css
        <TextIndicator className={cx('order_status', labelClass)} type={type} {...rest}>
            {label}
        </TextIndicator>
    );
};
