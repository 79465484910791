import isString from 'lodash/isString';
import omit from 'lodash/omit';
import React, { PropsWithChildren, ReactNode } from 'react';
import { FieldValues, FormProvider, UseFormReturn, useFormState } from 'react-hook-form';
import {
    Button,
    HStack,
    Modal,
    ModalProps,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalCloseButton,
} from '@chakra-ui/react';
import { MaybeRenderProp } from '@chakra-ui/react-utils';
import { runIfFn } from '@chakra-ui/utils';
import { POPOVER_FIELD_STYLES } from '@shared/components/common-popovers';
import { getNodeIdComposer } from '@shared/utils/strings';
import { FieldStylesContext, UseUpdateFormPopupProps, useUpdateFormPopup } from '../hooks';

// todo move to common-modals

const MODAL_FORM_STYLES = {
    sm: {
        fieldStyles: {
            ...POPOVER_FIELD_STYLES,
        },
    },
    md: { fieldStyles: omit(POPOVER_FIELD_STYLES, ['label']) },
};

export interface ModalFormProps<Fields extends FieldValues = FieldValues>
    extends UseUpdateFormPopupProps<Fields> {
    // same as SectionModalFormProps
    trigger: (props: {
        onOpen: () => void;
        triggerId: (button?: string, ...args: Array<number | string>) => string;
    }) => React.ReactNode;
    formId: string;
    header: ReactNode;
    buttonText?: string;
    trapFocus?: boolean;
    shouldCheckDirty?: boolean;
    size?: ModalProps['size'];

    // ModalFormProps
    contentSize?: keyof typeof MODAL_FORM_STYLES;
    actions?: MaybeRenderProp<
        {
            isDisabled: boolean;
            isSubmitting: boolean;
        } & UseFormReturn<Fields>
    >;
}

export const ModalForm = <Fields extends FieldValues>({
    trigger,
    formId,
    buttonText = 'save',
    header,
    data,
    schema,
    submit,
    overwriteDefaultValues,
    trapFocus = true,
    stripUnknown = true,
    shouldCheckDirty = true,
    apiErrorsToastEnabled = true,
    apiErrorsFields,
    apiErrorsMap,
    onError,
    size = 'xl',
    contentSize = 'md',
    actions,
    children,
}: PropsWithChildren<ModalFormProps<Fields>>) => {
    const _id = getNodeIdComposer(formId, 'popup');
    const triggerId = (button = 'trigger', ...args: Array<number | string>) => _id(button, ...args);

    const { onOpen, onClose, isOpen, handleFormSubmit, ...formApi } = useUpdateFormPopup<Fields>({
        data,
        schema,
        onError,
        apiErrorsToastEnabled,
        apiErrorsFields,
        apiErrorsMap,
        stripUnknown,
        submit,
        overwriteDefaultValues,
    });
    const { isSubmitting, isDirty } = useFormState({ control: formApi.control });
    const isDisabled = (shouldCheckDirty && !isDirty) || isSubmitting;

    const { fieldStyles } = MODAL_FORM_STYLES[contentSize];

    return (
        <>
            {trigger({ onOpen, triggerId })}

            <Modal
                isCentered
                lockFocusAcrossFrames={false}
                onClose={onClose}
                isOpen={isOpen}
                trapFocus={trapFocus}
                size={size}
            >
                <ModalOverlay />
                <ModalContent>
                    <FormProvider {...formApi}>
                        <ModalHeader fontSize="lg">
                            <HStack justifyContent="space-between">
                                {isString(header) ? <span>{header}</span> : header}
                                <ModalCloseButton
                                    position="relative"
                                    top="0px"
                                    insetEnd="0px"
                                    color="neutral.darkest"
                                />
                            </HStack>
                        </ModalHeader>

                        <ModalBody
                            id={formId}
                            as="form"
                            className="chakra-popover-form__content"
                            onSubmit={event => {
                                // https://github.com/react-hook-form/react-hook-form/issues/2076
                                event?.stopPropagation();
                                handleFormSubmit(event);
                            }}
                        >
                            <FieldStylesContext.Provider value={fieldStyles}>
                                {children}
                            </FieldStylesContext.Provider>
                        </ModalBody>
                        <ModalFooter>
                            {actions ? (
                                runIfFn(actions, {
                                    isDisabled,
                                    isSubmitting,
                                    ...formApi,
                                })
                            ) : (
                                <Button
                                    id={_id('submit_button')}
                                    isDisabled={isDisabled}
                                    isLoading={isSubmitting}
                                    loadingText={buttonText}
                                    form={formId}
                                    type="submit"
                                >
                                    {buttonText}
                                </Button>
                            )}
                        </ModalFooter>
                    </FormProvider>
                </ModalContent>
            </Modal>
        </>
    );
};
