export const pluralize = (val: number | object, word: string, plural = word + 's') => {
    const _pluralize = (num: number, word: string, plural = word + 's') =>
        [1, -1].includes(Number(num)) ? word : plural;

    if (typeof val === 'object')
        return (num: number, word: keyof object) => _pluralize(num, word, val[word]);

    return _pluralize(val, word, plural);
};

/*

https://www.30secondsofcode.org/js/s/pluralize/

pluralize(0, 'apple'); // 'apples'
pluralize(1, 'apple'); // 'apple'
pluralize(2, 'apple'); // 'apples'
pluralize(2, 'person', 'people'); // 'people'

const PLURALS = {
  person: 'people',
  radius: 'radii'
};
const autoPluralize = pluralize(PLURALS);
autoPluralize(2, 'person'); // 'people'

https://stackoverflow.com/questions/27194359/javascript-pluralize-an-english-string

 */
