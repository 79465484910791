import React from 'react';
import { DateInput } from '@shared/components/datepicker';
import { ClassicCKEditor } from '@shared/components/ckeditor';
import { InputConfigs } from '../types';
import {
    Input,
    Textarea,
    NumberInput,
    Checkbox,
    MultipleCheckboxes,
    Radio,
    Switch,
    Select,
    EditableList,
} from '../inputs';

interface Props {
    field: InputConfigs;
}

export const InputHandler = ({ field }: Props) => {
    let Component: any = null;

    switch (field.type) {
        case 'text':
            Component = Input;
            break;

        case 'textarea':
            Component = Textarea;
            break;

        case 'number':
            Component = NumberInput;
            break;

        case 'select':
            Component = Select;
            break;

        case 'switch':
            Component = Switch;
            break;

        case 'radio':
            Component = Radio;
            break;

        case 'checkbox':
            Component = Checkbox;
            break;

        case 'multiple-checkboxes':
            Component = MultipleCheckboxes;
            break;

        case 'editable-list':
            Component = EditableList;
            break;

        case 'date':
            Component = DateInput;
            break;

        case 'wysiwyg':
            Component = ClassicCKEditor;
            break;

        default:
            break;
    }

    return <Component name={field.name} {...field.props} />;
};
