import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { composeName, defineField, FieldType } from '@shared/components/forms-v2';

type ConfigArgs = {
    path?: string;
};

export const getComplexityBracketingConfig = ({ path }: ConfigArgs = {}) => {
    const _name = (name: string) => (path ? composeName(path, name) : name);

    const first_boundary = defineField({
        type: FieldType.Number,
        name: _name('first_boundary'),
        scheme: yup.number().requiredNullEqualTo0(1),
        props: {
            label: 'First boundary',
        },
    });
    const second_boundary = defineField({
        type: FieldType.Number,
        name: _name('second_boundary'),
        scheme: yup.number().requiredNullEqualTo0(2),
        props: {
            label: 'Second boundary',
        },
    });
    const third_boundary = defineField({
        type: FieldType.Number,
        name: _name('third_boundary'),
        scheme: yup.number().requiredNullEqualTo0(3),
        props: {
            label: 'Third boundary',
        },
    });
    const fourth_boundary = defineField({
        type: FieldType.Number,
        name: _name('fourth_boundary'),
        scheme: yup.number().requiredNullEqualTo0(4),
        props: {
            label: 'Fourth boundary',
        },
    });

    const first_multiplier = defineField({
        type: FieldType.Number,
        name: _name('first_multiplier'),
        scheme: yup.number().requiredNullEqualTo0(1).min(0),
        props: {
            label: 'Very simple',
        },
    });
    const second_multiplier = defineField({
        type: FieldType.Number,
        name: _name('second_multiplier'),
        scheme: yup.number().requiredNullEqualTo0(1).min(0),
        props: {
            label: 'Simple',
        },
    });
    const third_multiplier = defineField({
        type: FieldType.Number,
        name: _name('third_multiplier'),
        scheme: yup.number().requiredNullEqualTo0(1).min(0),
        props: {
            label: 'Normal',
        },
    });
    const fourth_multiplier = defineField({
        type: FieldType.Number,
        name: _name('fourth_multiplier'),
        scheme: yup.number().requiredNullEqualTo0(1).min(0),
        props: {
            label: 'Complex',
        },
    });
    const fifth_multiplier = defineField({
        type: FieldType.Number,
        name: _name('fifth_multiplier'),
        scheme: yup.number().requiredNullEqualTo0(1).min(0),
        props: {
            label: 'High complex',
        },
    });

    const schema = yup.object(
        mapValues(
            {
                // we currently don't edit these fields and don't want to send default values when creating
                // first_boundary,
                // second_boundary,
                // third_boundary,
                // fourth_boundary,
                first_multiplier,
                second_multiplier,
                third_multiplier,
                fourth_multiplier,
                fifth_multiplier,
            },
            'scheme',
        ),
    );

    const fields = {
        first_boundary,
        second_boundary,
        third_boundary,
        fourth_boundary,
        first_multiplier,
        second_multiplier,
        third_multiplier,
        fourth_multiplier,
        fifth_multiplier,
    };

    const soloFields = {
        bracketing_first_multiplier: first_multiplier.copy({ name: 'bracketing_first_multiplier' }),
        bracketing_second_multiplier: second_multiplier.copy({
            name: 'bracketing_second_multiplier',
        }),
        bracketing_third_multiplier: third_multiplier.copy({ name: 'bracketing_third_multiplier' }),
        bracketing_fourth_multiplier: fourth_multiplier.copy({
            name: 'bracketing_fourth_multiplier',
        }),
        bracketing_fifth_multiplier: fifth_multiplier.copy({ name: 'bracketing_fifth_multiplier' }),
    };

    return {
        fields,
        soloFields,
        schema,
    };
};

export type ComplexityBracketingConfig = ReturnType<typeof getComplexityBracketingConfig>;
export type ComplexityBracketingSchema = ComplexityBracketingConfig['schema'];
