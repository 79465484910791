import { AxiosError } from 'axios';
import { combineEpics } from 'redux-observable';
import {
    of,
    from,
    merge,
    range,
    filter,
    mergeMap,
    switchMap,
    catchError,
    withLatestFrom,
    concatWith,
    concatMap,
} from 'rxjs';
import { MaterialService } from '@services/material.service';
import { MachineMaterialsService } from '@services/machine-materials.service';
import { AppEpic } from '@app/types';
import {
    createMaterialEntitySuccess,
    startEditMaterialEntities,
    finishEditMaterialEntities,
    deleteMaterialEntitySuccess,
    editMaterialEntitySuccess,
    editMaterial,
    editMaterialFailure,
    editMaterialSuccess,
    fetchMaterial,
    fetchMaterialFailure,
    fetchMaterialSuccess,
    fetchTechnologies,
    fetchTechnologiesFailure,
    fetchTechnologiesSuccess,
} from './reducers';
import { triggerToast } from '@ducks/app';
import { ResponseApiError } from '@shared/types';
import { TECHNOLOGY_SLUG_MAP } from '@shared/constants';
import { Action } from '@reduxjs/toolkit';

const getTechnologiesEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchTechnologies.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(MachineMaterialsService.init().fetchListTechnologies()).pipe(
                switchMap(({ data }) => {
                    return of(fetchTechnologiesSuccess(data));
                }),
                catchError(() => of(fetchTechnologiesFailure())),
            );
        }),
    );

const getMaterialEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchMaterial.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { materialId, technologyId } = action.payload;

            return from(
                MaterialService.init().fetchMaterial({
                    id: materialId,
                    name: TECHNOLOGY_SLUG_MAP[technologyId],
                }),
            ).pipe(
                switchMap(({ data }) => {
                    return of(fetchMaterialSuccess(data));
                }),
                catchError(() => of(fetchMaterialFailure())),
            );
        }),
    );

const editMaterialEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(editMaterial.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const { data, materialId, technologyId, hasNotification = true } = action.payload;
            const actions: Action[] = [];

            if (hasNotification) {
                actions.push(
                    triggerToast({
                        title: 'Material was updated',
                    }),
                );
            }

            return from(
                MaterialService.init().editMaterial({
                    id: materialId,
                    name: TECHNOLOGY_SLUG_MAP[technologyId],
                    data,
                }),
            ).pipe(
                mergeMap(() => {
                    return of(...actions, editMaterialSuccess({ data }));
                }),
                catchError((err: AxiosError<ResponseApiError>) => {
                    return of(
                        editMaterialFailure(),
                        triggerToast({
                            error: err.response?.data,
                        }),
                    );
                }),
            );
        }),
    );

const handleEntityQueuesMaterialEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(startEditMaterialEntities.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const {
                entity,
                materialId,
                queueEdition,
                queueDeletion,
                queueCreation,
                hasNotification = true,
            } = action.payload;

            const actions: Action[] = [];

            if (hasNotification) {
                actions.push(
                    triggerToast({
                        title: 'Material was updated',
                    }),
                );
            }

            return merge(
                range(queueDeletion.length).pipe(
                    mergeMap(index =>
                        from(
                            MaterialService.init().deleteMaterialEntity({
                                id: materialId,
                                entityId: queueDeletion[index].id,
                                entity,
                            }),
                        ).pipe(
                            mergeMap(() =>
                                of(
                                    deleteMaterialEntitySuccess({
                                        entity,
                                        id: queueDeletion[index].id,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
                range(queueEdition.length).pipe(
                    mergeMap(index =>
                        from(
                            MaterialService.init().editMaterialEntity({
                                id: materialId,
                                entity,
                                data: queueEdition[index],
                                entityId: queueEdition[index].id,
                            }),
                        ).pipe(
                            mergeMap(({ data }) =>
                                of(
                                    editMaterialEntitySuccess({
                                        entity,
                                        data,
                                        id: data.id,
                                    }),
                                ),
                            ),
                        ),
                    ),
                ),
                range(queueCreation.length).pipe(
                    mergeMap(index =>
                        from(
                            MaterialService.init().createMaterialEntity({
                                entity,
                                id: materialId,
                                data: queueCreation[index],
                            }),
                        ).pipe(
                            mergeMap(({ data }) =>
                                of(createMaterialEntitySuccess({ entity, data })),
                            ),
                        ),
                    ),
                ),
            ).pipe(
                concatWith([...actions, finishEditMaterialEntities()]),
                catchError((err: AxiosError<ResponseApiError>) => {
                    return of(
                        triggerToast({
                            error: err.response?.data,
                        }),
                    );
                }),
            );
        }),
    );

export const materialEditEpics = combineEpics(
    getMaterialEpic,
    editMaterialEpic,
    getTechnologiesEpic,
    handleEntityQueuesMaterialEpic,
);
