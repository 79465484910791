import { createPersistReducer } from '@ducks/helpers';
import { createReactTableSlice, getReactTableSelectors } from '@ducks/react-table-slice';

export const tableSlice = createReactTableSlice({
    namespace: 'orders',
    initialState: {
        rowSelection: {},
    },
    reducers: {},
});

export const { setColumnsConfig: setOrderColumnsConfig, setRowSelection: setOrdersRowSelection } =
    tableSlice.actions;

export const {
    selectColumnsConfig: selectOrderColumnsConfig,
    selectRowSelection: selectSelectedOrders,
    selectColumnsOrder: selectOrderColumnsOrder,
    selectColumnsVisibility: selectOrderColumnsVisibility,
    // @ts-ignore
} = getReactTableSelectors(tableSlice, state => state.orders.table);

export const ordersTableReducer = createPersistReducer(tableSlice, {
    whitelist: ['columnsConfig'],
});
