import { Control, useFormState } from 'react-hook-form';
import { FCC } from '@shared/types';
import { FieldConfigs, FieldControlProps, MultipleInputFieldHint } from '../types';
import { FieldControl, FieldHandler } from '../common';
import { InputGroup, WithInputGroupProps } from '../inputs';
import { getCurrentErrors } from '../helpers';
import { Flex, Text } from '@chakra-ui/react';
import set from 'lodash/set';
import { Fragment } from 'react';

const multipleInputFieldInjectedProps = { wrapped: false };

export type MultipleInputFieldProps = WithInputGroupProps &
    FieldControlProps & {
        control?: Control<any>;
        fields?: FieldConfigs[];
        hints?: MultipleInputFieldHint[];
    };

export const MultipleInputField: FCC<MultipleInputFieldProps> = ({
    control,
    fields,
    hints,
    children,

    label,
    helpText,
    tooltip,

    formControlProps,
    groupProps,
    labelProps,
    tooltipProps,
    errorProps,
    helpTextProps,
    ...rest
}) => {
    // const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    // const { errorText, isInvalid } = getFieldState(state);
    // const { field } = state;

    const names = fields?.map(field => field.name);
    const { errors } = useFormState({ control, name: names });
    const { currentErrors, errorText } = getCurrentErrors({ names, errors });

    return (
        <FieldControl
            label={label}
            labelProps={labelProps}
            tooltip={tooltip}
            tooltipProps={tooltipProps}
            helpText={helpText}
            helpTextProps={helpTextProps}
            errorText={errorText}
            isInvalid={currentErrors && currentErrors.length > 0}
            errorProps={errorProps}
            {...formControlProps}
        >
            <InputGroup {...groupProps} {...rest}>
                {fields &&
                    fields.map((field, index) => {
                        //----- DeepCloning of a multiple field with Elements as children leads to infinite rerenders -----//
                        //----- Links to objects changes => rerender => links to objects changes -----//
                        //----- const updatedField = cloneDeep(_field); -----//
                        set(field, 'props.errorProps', { display: 'none' });

                        const currentHint =
                            hints && hints.find(hint => hint.positionIndex === index);

                        return (
                            <Fragment key={field.name}>
                                {currentHint && currentHint.hint}
                                <Flex
                                    className="input-container"
                                    w="100%"
                                    flexDir="column"
                                    gap="4px"
                                >
                                    {field.label && (
                                        <Text textStyle="typography-sm" color="gray.400">
                                            {field.label}
                                        </Text>
                                    )}
                                    <FieldHandler
                                        key={field.name}
                                        control={control}
                                        field={field}
                                        injectedProps={multipleInputFieldInjectedProps}
                                    />
                                </Flex>
                            </Fragment>
                        );
                    })}
            </InputGroup>
        </FieldControl>
    );
};
