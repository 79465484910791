import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import React from 'react';
import { defineField, FieldType } from '@shared/components/forms-v2';
import { uuidv4 } from '@shared/utils/uuid';

type ConfigArgs = {};

export const getMachinePriorityConfig = ({}: ConfigArgs = {}) => {
    const days = defineField({
        type: FieldType.Number,
        name: 'days',
        scheme: yup.number().requiredNullEqualTo0(10).integer().min(0),
        props: {
            label: 'Days',
            min: 0,
            precision: 0,
        },
    });
    const factor = defineField({
        type: FieldType.Number,
        name: 'factor',
        scheme: yup.number().requiredNullEqualTo0().integer(),
        props: {
            label: 'Additional margin',
            rightAddon: '%',
        },
    });
    const name_for_user = defineField({
        type: FieldType.Text,
        name: 'name_for_user',
        scheme: yup.string().ensure().trim().max(255),
        props: {
            label: 'Name for user',
            tooltip: 'Any name input here will be displayed instead of the lead timed days above.',
        },
    });
    const note_for_user = defineField({
        type: FieldType.Text,
        name: 'note_for_user',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Note for user',
            tooltip: 'Additional info to be displayed with each lead time option.',
        },
    });
    const sort_order = defineField({
        type: FieldType.Number,
        name: 'sort_order',
        scheme: yup.number().requiredNullEqualTo0(1).min(1).max(999),
        props: {
            label: 'Order of display',
        },
    });
    const tbd_quantity_threshold = defineField({
        type: FieldType.Number,
        name: 'tbd_quantity_threshold',
        scheme: yup.number().requiredNullEqualTo0().integer().min(0),
        props: {
            label: 'TBD part quantity threshold',
            tooltip:
                'The min. amount of parts ordered with the lead time that will trigger TBD mode',
            min: 0,
            precision: 0,
        },
    });

    const schema = yup.object({
        id: yup
            .string()
            .uuid()
            .default(() => uuidv4()),
        ...mapValues(
            {
                days,
                factor,
                name_for_user,
                note_for_user,
                sort_order,
                tbd_quantity_threshold,
            },
            'scheme',
        ),
    });

    const fields = {
        days,
        factor,
        name_for_user,
        note_for_user,
        sort_order,
        tbd_quantity_threshold,
    };

    return {
        fields,
        schema,
    };
};

export type MachinePriorityConfig = ReturnType<typeof getMachinePriorityConfig>;
export type MachinePrioritySchema = MachinePriorityConfig['schema'];
