import { Box, BoxProps, Collapse, CollapseProps } from '@chakra-ui/react';
import { COLLAPSE_TRANSITION_END_STYLES } from '@shared/constants';

// we need to add padding instead of margin for a clean transition without jerking
export interface LineItemsCollapseProps extends CollapseProps {
    pt?: BoxProps['pt'];
    pl?: BoxProps['pl'];
}

export const LineItemsCollapse = ({
    pt = 2,
    pl = 0,
    in: isOpen,
    children,
    ...rest
}: LineItemsCollapseProps) => {
    return (
        <Collapse
            in={isOpen}
            animateOpacity
            transitionEnd={COLLAPSE_TRANSITION_END_STYLES}
            {...rest}
        >
            <Box pt={pt} pl={pl}>
                {children}
            </Box>
        </Collapse>
    );
};
