import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useUpdateEffect: typeof useEffect = (effect, dependencies) => {
    const mounted = useRef(false);

    useEffect(() => {
        if (!mounted.current) {
            mounted.current = true;
            return;
        }
        effect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, dependencies);
};

export const useUpdateMemoizeEffect: typeof useEffect = (effect, dependencies) => {
    const previousDependencies = useRef(dependencies);

    useUpdateEffect(() => {
        if (!isEqual(previousDependencies.current, dependencies)) {
            effect();
        }

        previousDependencies.current = dependencies;
    }, [dependencies]);
};
