export const COMPLEXITY_BRACKETS = [
    {
        level: 1,
        title: 'Very simple',
        coefficient_field_key: 'first_multiplier',
    },
    {
        level: 2,
        title: 'Simple',
        coefficient_field_key: 'second_multiplier',
    },
    {
        level: 3,
        title: 'Normal',
        coefficient_field_key: 'third_multiplier',
    },
    {
        level: 4,
        title: 'Complex',
        coefficient_field_key: 'fourth_multiplier',
    },
    {
        level: 5,
        title: 'Highly complex',
        coefficient_field_key: 'fifth_multiplier',
    },
] as const;

export type ComplexityBracketing = (typeof COMPLEXITY_BRACKETS)[number];
