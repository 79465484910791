import { createSelector } from '@reduxjs/toolkit';
import { PricingPlanFeature } from '@services/df/app';
import { enhancedApi as api } from '@services/df/machines-materials';
import { RootState } from '@app/types';
import { Nilable } from '@shared/types';
import { isBelong2D, isBelongCNC } from '@shared/utils/technology-guard';
import {
    DISALLOWED_TECHNOLOGIES,
    TECHNOLOGY_TITLE_MAP,
    TechnologyIdsUnion,
} from '@shared/constants';
import { hasPricingPlanFeature, selectCompanyPricingPlanFeatures } from '../app';

const isAllowedTechnologyPredicate = (
    features: PricingPlanFeature[] | undefined,
    techId: number,
) => {
    const planFeaturesByTech = {
        cnc: 'ms_enabled_CNC',
        '2d': 'ms_enabled_cutter',
        '3d': 'ms_enabled_3D',
    };

    const getFeatureCodeByTechId = (id: number) =>
        isBelong2D(id)
            ? planFeaturesByTech['2d']
            : isBelongCNC(id)
            ? planFeaturesByTech.cnc
            : planFeaturesByTech['3d'];

    const hasAnyCode = Object.values(planFeaturesByTech).some(code =>
        hasPricingPlanFeature(features, code),
    );
    const featureCodeName = getFeatureCodeByTechId(techId);
    const isAllowedByFeature = hasAnyCode ? hasPricingPlanFeature(features, featureCodeName) : true;
    const isAllowedByDefault = !DISALLOWED_TECHNOLOGIES.includes(techId);

    return isAllowedByDefault && isAllowedByFeature;
};

// technologies
export const adminTechnologiesSelector = api.endpoints.adminTechnologiesRetrieve.select();
export const selectAdminTechnologies = (state: RootState) => adminTechnologiesSelector(state).data;
export const selectTechnologies = (state: RootState) =>
    selectAdminTechnologies(state)?.printing_tech;
export const selectCustomTechnologies = (state: RootState) =>
    selectAdminTechnologies(state)?.custom_tech;

export const selectCustomTechnologiesByTechnology = createSelector(
    selectCustomTechnologies,
    (state: RootState, technologyId?: number) => technologyId,
    (customTechnologies, technologyId) =>
        customTechnologies?.filter(technology => technology.tech_id === technologyId),
);

export const selectAllowedTechnologies = createSelector(
    selectTechnologies,
    selectCompanyPricingPlanFeatures,
    (technologies, features) =>
        technologies?.filter(technology =>
            isAllowedTechnologyPredicate(features, technology.tech_id),
        ),
);

export const selectAllowedTechnologiesOptions = createSelector(
    selectCompanyPricingPlanFeatures,
    features =>
        Object.entries(TECHNOLOGY_TITLE_MAP)
            .filter(([_id]) => isAllowedTechnologyPredicate(features, parseInt(_id)))
            .map(([id, label]) => ({
                label,
                value: id,
            })),
);

// machines & materials
export const machinesMaterialsSelector = api.endpoints.machinesMaterialsList.select({});
export const selectMachinesMaterials = (state: RootState) => machinesMaterialsSelector(state).data;

// we do not take into account query parameters
export const selectCreatedMachinesCount = (state: RootState) =>
    selectMachinesMaterials(state)?.length ?? 0;

export const selectMaterials = createSelector(selectMachinesMaterials, machinesMaterials =>
    machinesMaterials?.flatMap(({ id, title, technology, materials }) =>
        materials.map(material => ({
            ...material,
            printerId: id,
            printerLabel: title,
            techId: technology as TechnologyIdsUnion,
        })),
    ),
);

export const selectMachines = createSelector(selectMachinesMaterials, machinesMaterials =>
    machinesMaterials?.map(({ id, title, technology }) => ({
        id,
        title,
        technology: technology as TechnologyIdsUnion,
    })),
);

export const selectMaterialsByPrinter = createSelector(
    selectMaterials,
    (state: RootState, printerId: Nilable<number>) => printerId,
    (materials, selectedPrinterId) =>
        selectedPrinterId
            ? materials?.filter(material => material.printerId === selectedPrinterId)
            : [],
);

export const selectMaterialsByTechnologies = createSelector(
    // selectAllowedTechnologiesOptions, // instead of DISALLOWED_TECHNOLOGIES
    selectMaterials,
    (state: RootState, technologyIds: number[] = []) => technologyIds,
    (materials, technologyIds) =>
        materials?.filter(
            material =>
                !DISALLOWED_TECHNOLOGIES.some(tech => tech === material.techId) &&
                (technologyIds.length
                    ? technologyIds.some(tech => tech === material.techId)
                    : true),
        ),
);

export const makeSelectAllowedMachines = () =>
    createSelector(
        (state: RootState, exclude?: TechnologyIdsUnion[]) => exclude,
        selectMachines,
        (_exclude, machines) => {
            const exclude = _exclude
                ? DISALLOWED_TECHNOLOGIES.concat(_exclude)
                : DISALLOWED_TECHNOLOGIES;
            return machines?.filter(machine => !exclude.some(tech => tech === machine.technology));
        },
    );

export const selectAllowedMachines = createSelector(selectMachines, machines =>
    machines?.filter(machine => !DISALLOWED_TECHNOLOGIES.some(tech => tech === machine.technology)),
);

// presets
export const machinesPresetsSelector = api.endpoints.machinesPresetsList.select({});
export const selectMachinesPresets = (state: RootState) => machinesPresetsSelector(state).data;
export const selectMachinesPresetsByTechnology = createSelector(
    selectMachinesPresets,
    (state: RootState, technologyId?: number) => technologyId,
    (presets, technologyId) => presets?.filter(preset => preset.technology === technologyId),
);

export const materialsPresetsSelector = api.endpoints.materialsPresetsList.select({});
export const selectMaterialsPresets = (state: RootState) => materialsPresetsSelector(state).data;
export const selectMaterialsPresetsByTechnology = createSelector(
    selectMaterialsPresets,
    (state: RootState, technologyId?: number) => technologyId,
    (presets, technologyId) => presets?.filter(preset => preset.technology === technologyId),
);
