import { parseISO } from 'date-fns';
import { formatInTimeZone, format } from 'date-fns-tz';
import { useAppSelector } from '@app/hooks';
import { selectTimezone } from '@ducks/app';
import { Nilable } from '@shared/types';

const DEFAULT_DATE_VIEW_FORMAT = 'MMM. dd, yyyy';
const DEFAULT_DATETIME_VIEW_FORMAT = 'MMM. dd, yyyy, h:mm aaa';

export const formatDate = (value: string, timezone = 'UTC', _format = DEFAULT_DATE_VIEW_FORMAT) => {
    // It doesn't offset the time (and date) according to the timezone
    if (timezone === 'none') {
        return format(parseISO(value), _format);
    }
    return formatInTimeZone(parseISO(value), timezone, _format);
};

type Props = {
    value: Nilable<string>;
    fallback?: string;
} & (
    | {
          time?: never;
          format?: string;
      }
    | {
          time?: boolean;
          format?: never;
      }
);

export const DateTime = ({
    value,
    time = true,
    format: _format = time ? DEFAULT_DATETIME_VIEW_FORMAT : DEFAULT_DATE_VIEW_FORMAT,
    fallback = 'Not set',
}: Props) => {
    const timezone = useAppSelector(selectTimezone);
    return <span>{value ? formatDate(value, timezone, _format) : fallback}</span>;
};
