import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, FieldType } from '@shared/components/forms-v2';
import { OrderPriceCorrectionType } from './types';

type ConfigArgs = { currencySign?: string; decreaseAllowed: boolean };

export const getOrderPriceCorrectionConfig = ({
    currencySign = '$',
    decreaseAllowed,
}: ConfigArgs) => {
    const type = defineField({
        type: FieldType.Radio,
        name: 'type',
        scheme: yup.string().default('0'),
        props: {
            label: 'Change',
            buttons: [
                { value: OrderPriceCorrectionType.Increase, children: 'Price Increased' },
                ...(decreaseAllowed
                    ? [{ value: OrderPriceCorrectionType.Decrease, children: 'Price Decreased' }]
                    : []),
            ],
        },
    });

    const value = defineField({
        type: FieldType.Number,
        name: 'value',
        scheme: yup.number().min(0).default(0),
        props: {
            label: 'Price adjustment',
            min: 0,
            rightAddon: currencySign,
        },
    });

    const comment = defineField({
        type: FieldType.Text,
        name: 'comment',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Description',
        },
    });

    const schema = yup.object(
        mapValues(
            {
                type,
                value,
                comment,
            },
            'scheme',
        ),
    );

    const fieldsArray = [type, value, comment];

    const fields = {
        type,
        value,
        comment,
    };

    return {
        fieldsArray,
        fields,
        schema,
    };
};

export type OrderPriceCorrectionConfig = ReturnType<typeof getOrderPriceCorrectionConfig>;
export type OrderPriceCorrectionSchema = OrderPriceCorrectionConfig['schema'];
