import currency from 'currency.js';

export function toPercent(value: number | string = 0, precision = 8) {
    return currency(value, { precision }).multiply(100);
    // TODO: Uncomment next line and remove the previous one when next time touch it.
    // Then remove all .value from cases where it's used. Do it when chances of conflicts are minimal.
    // return currency(value, { precision }).multiply(100).value;
}

export function toCoefficient(value: number | string = 0, precision = 8) {
    return currency(value, { precision }).divide(100);
    // TODO: Uncomment next line and remove the previous one when next time touch it.
    // Then remove all .value from cases where it's used. Do it when chances of conflicts are minimal.
    // return currency(value, { precision }).divide(100).value;
}
