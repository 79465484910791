import { Switch as ChakraSwitch, SwitchProps as ChakraSwitchProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { useFieldStyles } from '../hooks';

export type SwitchProps = ChakraSwitchProps;

export const Switch = forwardRef<SwitchProps, 'input'>(({ ..._props }, ref) => {
    const props = useFieldStyles('switch', _props);
    return <ChakraSwitch {...props} ref={ref} />;
});
