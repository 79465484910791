import { Icon, IconProps } from '@chakra-ui/react';

export const NestingIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M9.28889 14.8889H4.24889M4.24889 14.8889C3.07277 14.8889 2.48472 14.8889 2.03551 14.66C1.64036 14.4587 1.31911 14.1374 1.11777 13.7423C0.888886 13.293 0.888885 12.705 0.888885 11.5289M4.24889 14.8889H4.52889C5.705 14.8889 6.29305 14.8889 6.74229 14.66C7.13744 14.4587 7.45867 14.1374 7.65999 13.7423C7.88889 13.293 7.88889 12.705 7.88889 11.5289V11.2489C7.88889 10.0727 7.88889 9.48475 7.65999 9.03549C7.45867 8.64034 7.13744 8.31911 6.74229 8.11779C6.29305 7.88889 5.705 7.88889 4.52889 7.88889H4.24889C3.07277 7.88889 2.48472 7.88889 2.03551 8.11779C1.64036 8.31911 1.31911 8.64034 1.11777 9.03549C0.888886 9.48475 0.888885 10.0727 0.888885 11.2489V11.5289M0.888885 11.5289V6.48889M6.48889 0.888885H9.28889M14.8889 6.48889V9.28889M12.0889 14.8889C12.7399 14.8889 13.0654 14.8889 13.3324 14.8173C14.0571 14.6232 14.6232 14.0571 14.8173 13.3324C14.8889 13.0654 14.8889 12.7399 14.8889 12.0889M14.8889 3.68889C14.8889 3.0379 14.8889 2.71241 14.8173 2.44536C14.6232 1.72067 14.0571 1.15462 13.3324 0.96044C13.0654 0.888886 12.7399 0.888885 12.0889 0.888885M3.68889 0.888885C3.0379 0.888885 2.71241 0.888886 2.44536 0.96044C1.72067 1.15462 1.15462 1.72067 0.96044 2.44536C0.888886 2.71241 0.888885 3.0379 0.888885 3.68889" />
    </Icon>
);
