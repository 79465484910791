import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { wrapCreateBrowserRouter } from '@sentry/react';
import { storeManager } from '@app/store';
import { App } from '@app/app';
// TODO: delete when safari will be supported
// https://developer.mozilla.org/en-US/docs/Web/CSS/:focus-visible
// see .js-focus-visible
import 'focus-visible/dist/focus-visible';

import { IntercomUtils, SentryUtils } from '@shared/utils';
import * as serviceWorker from './serviceWorker';

// todo remove this line after migrating auth to LS with token
!Boolean(process.env.REACT_APP_WITH_SIDEBAR) && IntercomUtils.ignoreFocusLockOnShow();

SentryUtils.setup();

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter);

export const router = sentryCreateBrowserRouter(
    [
        // match everything with "*"
        { path: '*', element: <App /> },
    ],
    {
        // https://reactrouter.com/6.30.0/upgrading/future#v7_starttransition
        // future: { v7_startTransition: true },
    },
);

// todo move providers from index.ts and App to separate file
const container = document.getElementById('root')!;
const root = createRoot(container);
root.render(
    <React.StrictMode>
        <Provider store={storeManager.store}>
            <RouterProvider router={router} />
        </Provider>
    </React.StrictMode>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
