import { useCallback } from 'react';
import {
    DiscountSerializerV2Request,
    PatchedDiscountSerializerV2Request,
    useDiscountsCreateMutation,
    useDiscountsPartialUpdateMutation,
    useDiscountsDestroyMutation,
} from '@services/df/discounts';
import { createToast as toast } from '@shared/components/toast';
import { isFetchBaseQueryError } from '@shared/utils/http';

export const useDiscountCreate = () => {
    const [create, state] = useDiscountsCreateMutation();
    return {
        create: useCallback(
            async (form: DiscountSerializerV2Request) => {
                const created = await create({
                    discountSerializerV2Request: form,
                }).unwrap();

                toast({ position: 'top-right', title: 'Discount was created' });

                return created;
            },
            [create],
        ),
        state,
    };
};

export const useDiscountUpdate = (id: number) => {
    const [update, state] = useDiscountsPartialUpdateMutation();

    return {
        update: useCallback(
            async (patchedDiscountSerializerV2Request: PatchedDiscountSerializerV2Request) => {
                const updated = await update({
                    id,
                    patchedDiscountSerializerV2Request,
                }).unwrap();

                toast({ position: 'top-right', title: 'Discount was updated' });

                return updated;
            },
            [update, id],
        ),
        state,
    };
};

export const useDiscountDestroy = (id: number) => {
    const [destroy, state] = useDiscountsDestroyMutation();

    return {
        destroy: useCallback(async () => {
            try {
                await destroy({ id }).unwrap();
                toast({
                    position: 'top-right',
                    title: 'Discount was deleted',
                });
            } catch (error) {
                isFetchBaseQueryError(error) &&
                    toast({
                        status: 'error',
                        position: 'top-right',
                        description: error.message,
                        isClosable: true,
                    });
            }
        }, [destroy, id]),
        state,
    };
};
