import { Icon, IconProps } from '@chakra-ui/react';

export const InfoSolidIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M8.0116 16C10.1492 15.965 12.1872 15.0902 13.6849 13.5647C14.4343 12.8186 15.0256 11.929 15.4235 10.9492C15.8213 9.96935 16.0175 8.91938 16.0003 7.86201C16.0015 6.82882 15.7988 5.80554 15.4039 4.85082C15.0089 3.8961 14.4294 3.02871 13.6986 2.29835C12.9678 1.56799 12.1001 0.98902 11.1451 0.594627C10.1902 0.200235 9.16679 -0.00183016 8.1336 1.24897e-05L7.99094 1.24772e-05C5.85269 0.0220118 3.81049 0.891345 2.31245 2.41726C0.814397 3.94318 -0.017148 6.00106 0.000268164 8.13934C-0.00219189 9.18507 0.204469 10.2207 0.608098 11.1854C1.01173 12.1501 1.60417 13.0244 2.3506 13.7567C3.09702 14.4891 3.98236 15.0649 4.95454 15.4501C5.92672 15.8354 6.96611 16.0223 8.0116 16ZM9.00027 4.97201C9.00507 5.10273 8.98377 5.23309 8.9376 5.35548C8.89143 5.47786 8.82133 5.58982 8.73138 5.68479C8.64144 5.77976 8.53346 5.85585 8.41376 5.9086C8.29406 5.96135 8.16505 5.98971 8.03427 5.99201H8.01627C7.7532 5.99151 7.50053 5.88918 7.31125 5.70648C7.12196 5.52378 7.01075 5.2749 7.00094 5.01201C6.99604 4.88127 7.01728 4.75087 7.06341 4.62845C7.10955 4.50602 7.17965 4.39403 7.26962 4.29904C7.35958 4.20405 7.46759 4.12796 7.58733 4.07524C7.70707 4.02252 7.83612 3.99423 7.96693 3.99201H7.98493C8.24791 3.99285 8.50038 4.09528 8.6896 4.27791C8.87881 4.46054 8.99012 4.70923 9.00027 4.97201ZM8.66693 7.66668V11.6667C8.66693 11.8435 8.5967 12.0131 8.47167 12.1381C8.34665 12.2631 8.17708 12.3333 8.00027 12.3333C7.82346 12.3333 7.65389 12.2631 7.52886 12.1381C7.40384 12.0131 7.3336 11.8435 7.3336 11.6667L7.3336 7.66668C7.3336 7.48987 7.40384 7.3203 7.52886 7.19527C7.65389 7.07025 7.82346 7.00001 8.00027 7.00001C8.17708 7.00001 8.34665 7.07025 8.47167 7.19527C8.5967 7.3203 8.66693 7.48987 8.66693 7.66668Z"
            fill="currentColor"
        />
    </Icon>
);
