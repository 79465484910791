import { Dispatch, SetStateAction, useEffect, useMemo, useState } from 'react';
import { Box, Button, Flex, Grid, Text } from '@chakra-ui/react';
import {
    Month_Names_Short,
    OnDateSelected,
    RangeCalendarPanel,
    Weekday_Names_Short,
} from 'chakra-dayzed-datepicker';
import { format } from 'date-fns';
import { CalendarConfigs } from 'chakra-dayzed-datepicker/src/utils/commonTypes';
import {
    OneOrTwoDates,
    getFirstDayOfWeek,
    getNewSelectedDates,
    getRangeDatepickerButtons,
} from './utils';
import { DATEPICKER_DEFAULT_STYLES, DEFAULT_DATE_FORMAT } from './constants';

type OnButtonClickHandler = (value: Readonly<[Date, Date]>) => void;

interface RangeButton {
    label: string;
    value: readonly [Date, Date];
    checkActive: (selectedDates: OneOrTwoDates) => boolean;
}

interface RangeButtonsProps {
    buttons: RangeButton[];
    onClick: OnButtonClickHandler;
    selectedDates: OneOrTwoDates | undefined;
}

const RangeButtons = ({ buttons, selectedDates, onClick }: RangeButtonsProps) => {
    return (
        <Flex
            align="flex-start"
            minW="140px"
            py="0.5rem"
            maxW="320px"
            flexDir={{ base: 'row', sm: 'column' }}
            justifyContent="center"
            gap="12px"
            flexWrap="wrap"
        >
            {buttons.map(({ label, value, checkActive }, index) => {
                const isActive = Array.isArray(selectedDates) ? checkActive(selectedDates) : false;
                return (
                    <Button
                        key={index}
                        variant="link"
                        isActive={isActive}
                        onClick={() => onClick(value)}
                        _active={{ color: 'primary.default' }}
                    >
                        {label}
                    </Button>
                );
            })}
        </Flex>
    );
};

interface RangeDatepickerProps {
    selectedDates: OneOrTwoDates | undefined;
    onChangeDate: Dispatch<SetStateAction<OneOrTwoDates | undefined>>;
    onButtonClick?: OnButtonClickHandler;
    getRangeButtons?: (() => RangeButton[]) | null;
    // defaultDate is to open calendar on the right month
    defaultDate?: Date;
}

export const RangeDatepicker = ({
    getRangeButtons = getRangeDatepickerButtons,
    onChangeDate,
    selectedDates,
    defaultDate,
    onButtonClick,
}: RangeDatepickerProps) => {
    const [firstDayOfWeek, setFirstDayOfWeek] = useState<CalendarConfigs['firstDayOfWeek']>(0);
    const [date, setDate] = useState(defaultDate);
    const buttons = useMemo(() => getRangeButtons?.(), [getRangeButtons]);

    const handleOnDateSelected: OnDateSelected = ({ selectable, date }) => {
        if (selectable) {
            onChangeDate(dates => getNewSelectedDates(dates!, date));
        }
    };

    const formattedDates = selectedDates
        ? `${format(selectedDates[0], DEFAULT_DATE_FORMAT)}${
              selectedDates[1] ? ' - ' + format(selectedDates[1], DEFAULT_DATE_FORMAT) : ''
          }`
        : null;

    useEffect(() => {
        getFirstDayOfWeek().then(locale => setFirstDayOfWeek(locale));
    }, []);

    return (
        <Grid
            templateRows={{ base: 'auto', sm: '1fr auto' }}
            templateAreas={{
                base: `
                'calendar'
                'range'
                'result'
                `,
                sm: `
                'range calendar'
                '. result'
                `,
            }}
        >
            {!!buttons?.length && onButtonClick && (
                <Box gridArea="range">
                    <RangeButtons
                        buttons={buttons}
                        selectedDates={selectedDates}
                        onClick={(value: Readonly<[Date, Date]>) => {
                            onButtonClick(value);
                            setDate(value[1]);
                        }}
                    />
                </Box>
            )}
            <Box gridArea="calendar">
                <RangeCalendarPanel
                    //------ The key is passed to rerender the calendar when the action button ------//
                    //------ is pressed to show the start date of the range ------//
                    key={date?.toISOString()}
                    propsConfigs={DATEPICKER_DEFAULT_STYLES} // todo merge with propsConfigs?
                    selected={selectedDates}
                    dayzedHookProps={{
                        selected: selectedDates,
                        showOutsideDays: false,
                        onDateSelected: handleOnDateSelected,
                        monthsToDisplay: 2,
                        date,
                        firstDayOfWeek,
                    }}
                    configs={{
                        dateFormat: DEFAULT_DATE_FORMAT,
                        monthNames: Month_Names_Short,
                        dayNames: Weekday_Names_Short,
                        firstDayOfWeek,
                    }}
                />
            </Box>
            {formattedDates && (
                <Flex gridArea="result" mt="12px" justify="center">
                    <Text
                        border="1px solid"
                        borderColor="gray.200"
                        textStyle="typography-sm"
                        color="gray.400"
                        borderRadius="4px"
                        p="10px"
                    >
                        {formattedDates}
                    </Text>
                </Flex>
            )}
        </Grid>
    );
};
