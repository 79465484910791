import React from 'react';
import { Heading, Text } from '@chakra-ui/react';
import { UntitledBox } from '@shared/components/common-boxes';
import { FCC } from '@shared/types';

export const AddressList = <T extends { id: number; full_address: string }>({
    addresses,
    header,
    action,
}: {
    header?: string;
    addresses?: T[];
    action: FCC<{
        address: T;
    }>;
}) => {
    const hasAddresses = !!addresses?.length;
    const Action = action;

    return hasAddresses ? (
        <>
            <Heading as="h5" size="sm">
                {header || 'Delivery addresses'}
            </Heading>
            {addresses.map(address => (
                <UntitledBox key={address.id} action={<Action address={address} />}>
                    <Text textStyle="typography-md">{address.full_address}</Text>
                </UntitledBox>
            ))}
        </>
    ) : null;
};
