import { Icon, IconProps } from '@chakra-ui/react';

export const CheckIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 8 8" {...props}>
        <path
            stroke="currentColor"
            strokeWidth="1"
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.66683 2L3.00016 5.66667L1.3335 4"
        />
    </Icon>
);
