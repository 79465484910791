import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        authCreateSessionCreate: build.mutation<
            AuthCreateSessionCreateApiResponse,
            AuthCreateSessionCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/auth/create_session/`,
                method: 'POST',
                body: queryArg.obtainTokenRequestRequest,
            }),
        }),
        authCreateSessionWithTokenCreate: build.mutation<
            AuthCreateSessionWithTokenCreateApiResponse,
            AuthCreateSessionWithTokenCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/auth/create_session_with_token/`,
                method: 'POST',
                body: queryArg.obtainTokenRequestRequest,
            }),
        }),
        authObtainCompaniesCreate: build.mutation<
            AuthObtainCompaniesCreateApiResponse,
            AuthObtainCompaniesCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/auth/obtain_companies/`,
                method: 'POST',
                body: queryArg.obtainCompaniesRequestRequest,
            }),
        }),
        obtainAnonymousAuthTokenCreate: build.mutation<
            ObtainAnonymousAuthTokenCreateApiResponse,
            ObtainAnonymousAuthTokenCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/obtain_anonymous_auth_token/`,
                method: 'POST',
                body: queryArg.obtainAnonymousTokenRequest,
            }),
        }),
        obtainAuthTokenCreate: build.mutation<
            ObtainAuthTokenCreateApiResponse,
            ObtainAuthTokenCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/obtain_auth_token/`,
                method: 'POST',
                body: queryArg.obtainTokenRequestRequest,
            }),
        }),
        passwordRecoveryRequestCreate: build.mutation<
            PasswordRecoveryRequestCreateApiResponse,
            PasswordRecoveryRequestCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/password/recovery/request/`,
                method: 'POST',
                body: queryArg.requestResetPasswordRequest,
            }),
        }),
        passwordRecoveryResetCreate: build.mutation<
            PasswordRecoveryResetCreateApiResponse,
            PasswordRecoveryResetCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/password/recovery/reset/`,
                method: 'POST',
                body: queryArg.resetPasswordRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthCreateSessionCreateApiResponse = unknown;
export type AuthCreateSessionCreateApiArg = {
    obtainTokenRequestRequest: ObtainTokenRequestRequest;
};
export type AuthCreateSessionWithTokenCreateApiResponse =
    /** status 200  */ TokenResponseserializer;
export type AuthCreateSessionWithTokenCreateApiArg = {
    obtainTokenRequestRequest: ObtainTokenRequestRequest;
};
export type AuthObtainCompaniesCreateApiResponse = /** status 200  */ ObtainCompaniesResponse[];
export type AuthObtainCompaniesCreateApiArg = {
    obtainCompaniesRequestRequest: ObtainCompaniesRequestRequest;
};
export type ObtainAnonymousAuthTokenCreateApiResponse = /** status 200  */ TokenResponseserializer;
export type ObtainAnonymousAuthTokenCreateApiArg = {
    obtainAnonymousTokenRequest: ObtainAnonymousTokenRequest;
};
export type ObtainAuthTokenCreateApiResponse = /** status 200  */ TokenResponseserializer;
export type ObtainAuthTokenCreateApiArg = {
    obtainTokenRequestRequest: ObtainTokenRequestRequest;
};
export type PasswordRecoveryRequestCreateApiResponse = unknown;
export type PasswordRecoveryRequestCreateApiArg = {
    requestResetPasswordRequest: RequestResetPasswordRequest;
};
export type PasswordRecoveryResetCreateApiResponse = unknown;
export type PasswordRecoveryResetCreateApiArg = {
    resetPasswordRequest: ResetPasswordRequest;
};
export type ObtainTokenRequestRequest = {
    company_name: string;
    email: string;
    password?: string;
    security_hash?: string;
};
export type TokenResponseserializer = {
    token: string;
};
export type ShortRole = {
    codename: string;
};
export type ObtainCompaniesResponse = {
    company_id: number;
    company_title: string;
    company_short_name: string;
    company_logo: string;
    roles_in_company: ShortRole[];
};
export type ObtainCompaniesRequestRequest = {
    email: string;
    password: string;
};
export type ObtainAnonymousTokenRequest = {
    company_name: string;
};
export type RequestResetPasswordRequest = {
    email: string;
    company_name?: string;
    redirect_path?: string;
};
export type ResetPasswordRequest = {
    reset_token: string;
    new_password: string;
};
export const {
    useAuthCreateSessionCreateMutation,
    useAuthCreateSessionWithTokenCreateMutation,
    useAuthObtainCompaniesCreateMutation,
    useObtainAnonymousAuthTokenCreateMutation,
    useObtainAuthTokenCreateMutation,
    usePasswordRecoveryRequestCreateMutation,
    usePasswordRecoveryResetCreateMutation,
} = injectedRtkApi;
