import pick from 'lodash/pick';
import { AxiosResponse } from 'axios';
import { from, concatAll, reduce } from 'rxjs';
import { TechnologyTitlesUnion } from '@shared/constants';
import { FieldsValues } from '@modules/price-matching-tool/price-match-result';
import { MaterialExecution, MaterialExecutionUnion } from '@shared/types';
import { MachineService } from '@services/machine.service';
import { MaterialService } from '@services/material.service';
import { ComplexityBracketing, Feature } from '@services/df/machines-materials';
import { PriceMatchFields } from '@services/df/price-match';

export const getPrinterDataForCNC = (values: FieldsValues) => {
    const { per_part_fee, machine_speed, finishing_speed, price_per_hour } = values;

    return {
        price_per_hour,
        per_part_fee,
        machine_speed,
        ...(finishing_speed && { finishing_speed }),
    };
};

export const getPrinterDataForSupportless3D = (values: FieldsValues) => {
    return pick(values, [
        'nominal_growth_rate_batch',
        'price_per_hour_batch',
        'price_of_area_bounding_box_batch',
        'price_of_volume_bounding_box_batch',
        'price_of_area_surface_batch',
        'per_part_fee',
        'nominal_growth_rate',
        'price_per_hour',
        'price_of_area_bounding_box',
        'price_of_volume_bounding_box',
        'price_of_area_surface',
        'nominal_layer_thickness',
    ]);
};

export const getPrinterDataForSupport3D = (values: FieldsValues) => {
    return pick(values, [
        'nominal_growth_rate_batch',
        'price_per_hour_batch',
        'price_of_area_bounding_box_batch',
        'price_of_volume_bounding_box_batch',
        'price_of_area_surface_batch',
        'per_part_fee',
        'nominal_growth_rate',
        'price_per_hour',
        'price_of_area_bounding_box',
        'price_of_volume_bounding_box',
        'price_of_area_surface',
        'nominal_layer_thickness',
    ]);
};

export const getMaterialDataForSupportless3D = (values: FieldsValues) => {
    const { programming_cost, price_per_gram_batch, price, power_for_volume } = values;

    return {
        programming_cost,
        price_per_gram_batch,
        price,
        power_for_volume,
    };
};

export const getMaterialDataForSupport3D = (values: FieldsValues) => {
    const {
        programming_cost,
        price_per_gram_batch,
        price,
        power_for_volume,
        price_per_gram,
        filling_level_of_support,
        price_per_gram_of_support_batch,
        price_per_gram_of_support,
    } = values;

    return {
        programming_cost,
        price_per_gram_batch,
        price,
        power_for_volume,
        price_per_gram,
        filling_level_of_support,
        price_per_gram_of_support_batch,
        price_per_gram_of_support,
    };
};

export const getMaterialDataForCNC = (
    values: FieldsValues,
    defaultValues: Partial<PriceMatchFields>,
) => {
    const {
        programming_cost,
        cost_per_subtracted_material,
        cost_per_surface_area,
        cost_per_volume_bb,
        price_per_kilogram,
        price_per_sq_cm,
    } = values;

    return {
        programming_cost,
        cost_per_subtracted_material,
        cost_per_surface_area,
        cost_per_volume_bb,
        price_per_kilogram,
        price_per_sq_cm: price_per_sq_cm ?? defaultValues.price_per_sq_cm,
        density: 1,
        machinability: 100,
        pricing_method: 'per_gram',
        bb_margin_x: 0,
        bb_margin_y: 0,
        bb_margin_z: 0,
    };
};

export const getNewBracketingDataForCNC = (values: FieldsValues, defaultValues: FieldsValues) => {
    const {
        bracketing_first_boundary,
        bracketing_second_boundary,
        bracketing_third_boundary,
        bracketing_fourth_boundary,
    } = defaultValues;
    const {
        bracketing_first_multiplier,
        bracketing_second_multiplier,
        bracketing_third_multiplier,
        bracketing_fourth_multiplier,
        bracketing_fifth_multiplier,
    } = values;

    return {
        first_boundary: bracketing_first_boundary,
        second_boundary: bracketing_second_boundary,
        third_boundary: bracketing_third_boundary,
        fourth_boundary: bracketing_fourth_boundary,
        first_multiplier: bracketing_first_multiplier!,
        second_multiplier: bracketing_second_multiplier!,
        third_multiplier: bracketing_third_multiplier!,
        fourth_multiplier: bracketing_fourth_multiplier!,
        fifth_multiplier: bracketing_fifth_multiplier!,
    };
};

export const getNewFeaturesDataForCNC = (values: FieldsValues) => {
    const {
        feature_per_piece_cuboid,
        feature_per_piece_axial,
        feature_per_piece_radial,
        feature_per_piece_reduction,
        feature_setup_axial,
        feature_setup_radial,
        feature_setup_reduction,
    } = values;
    if (
        feature_setup_axial ||
        feature_setup_radial ||
        feature_per_piece_axial ||
        feature_setup_reduction ||
        feature_per_piece_radial ||
        feature_per_piece_cuboid ||
        feature_per_piece_reduction
    ) {
        return [
            {
                name: 'Simple drilling hole, radial',
                feature_type: 'radial',
                is_active: true,
                price_per_feature: feature_per_piece_radial,
                price_set_up: feature_setup_radial,
            },
            {
                name: 'Simple drilling hole, axial',
                feature_type: 'axial',
                is_active: true,
                price_per_feature: feature_per_piece_axial,
                price_set_up: feature_setup_axial,
            },
            {
                name: 'Reduction',
                feature_type: 'single_step_reduction',
                is_active: true,
                price_per_feature: feature_per_piece_reduction,
                price_set_up: feature_setup_reduction,
            },
        ];
    }
};

export const getNewLayerThicknessDataFor3DTechs = (
    executions: MaterialExecution<MaterialExecutionUnion>[],
    option?: number | null,
) => {
    return executions.map(execution => ({ ...execution, option }));
};

export const getFlowCNC$ = ({
    name,
    machineId,
    materialId,
    values,
    defaultValues,
    bracketing,
    features,
}: {
    name: TechnologyTitlesUnion;
    machineId: number;
    materialId: number;
    values: FieldsValues;
    defaultValues: Partial<PriceMatchFields>;
    bracketing: ComplexityBracketing[];
    features: Feature[];
}) => {
    const newPrinterFields = getPrinterDataForCNC(values);
    const newMaterialFields = getMaterialDataForCNC(values, defaultValues);
    const newBracketing = getNewBracketingDataForCNC(values, defaultValues);
    const newFeatures = getNewFeaturesDataForCNC(values)!;

    return from([
        MachineService.init().editMachine(machineId, name, newPrinterFields),
        MaterialService.init().editMaterial({
            id: materialId,
            name,
            data: newMaterialFields,
        }),
        ...features.map(feature =>
            MachineService.init().deleteNested(feature.id, 'features', machineId),
        ),
        ...newFeatures.map(feature =>
            MachineService.init().createNested('features', machineId, feature),
        ),
        MachineService.init().editBracketing(bracketing[0].id, machineId, newBracketing),
    ]).pipe(
        concatAll(),
        reduce((acc: AxiosResponse[], data) => acc.concat(data), []),
    );
};

export const getFlowSupport3D$ = ({
    name,
    machineId,
    materialId,
    values,
    layerThickness,
    nominal_layer_thickness,
}: {
    name: TechnologyTitlesUnion;
    machineId: number;
    materialId: number;
    values: FieldsValues;
    layerThickness: MaterialExecution<'layer_thickness'>[];
    nominal_layer_thickness?: number | null;
}) => {
    const newPrinterFields = getPrinterDataForSupport3D({ ...values, nominal_layer_thickness });
    const newMaterialFields = getMaterialDataForSupport3D(values);
    const newLayerThickness = getNewLayerThicknessDataFor3DTechs(
        layerThickness,
        nominal_layer_thickness,
    );

    return from([
        MachineService.init().editMachine(machineId, name, newPrinterFields),
        MaterialService.init().editMaterial({
            name,
            id: materialId,
            data: newMaterialFields,
        }),
        ...newLayerThickness.map(execution =>
            MaterialService.init().editMaterialEntity({
                id: materialId,
                entity: 'executions',
                entityId: execution.id,
                data: execution,
            }),
        ),
    ]).pipe(
        concatAll(),
        reduce((acc: AxiosResponse[], data) => acc.concat(data), []),
    );
};

export const getFlowSupportless3D$ = ({
    name,
    machineId,
    materialId,
    values,
    layerThickness,
    nominal_layer_thickness,
}: {
    name: TechnologyTitlesUnion;
    machineId: number;
    materialId: number;
    values: FieldsValues;
    layerThickness: MaterialExecution<'layer_thickness'>[];
    nominal_layer_thickness?: number | null;
}) => {
    const newPrinterFields = getPrinterDataForSupportless3D({ ...values, nominal_layer_thickness });
    const newMaterialFields = getMaterialDataForSupportless3D(values);
    const newLayerThickness = getNewLayerThicknessDataFor3DTechs(
        layerThickness,
        nominal_layer_thickness,
    );

    return from([
        MachineService.init().editMachine(machineId, name, newPrinterFields),
        MaterialService.init().editMaterial({
            name,
            id: materialId,
            data: newMaterialFields,
        }),
        ...newLayerThickness.map(execution =>
            MaterialService.init().editMaterialEntity({
                id: materialId,
                entity: 'executions',
                entityId: execution.id,
                data: execution,
            }),
        ),
    ]).pipe(
        concatAll(),
        reduce((acc: AxiosResponse[], data) => acc.concat(data), []),
    );
};
