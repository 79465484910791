// todo move to machines-materials 'ducks' constants

export enum Technologies {
    'SLS' = 1,
    'FDM' = 2,
    'SLM' = 3,
    '3DP' = 4,
    'Multijet' = 5,
    'Polyjet' = 6,
    'SLA' = 7,
    'HP MJF' = 8,
    'Multi-Axis Milling' = 9,
    'CNC Cutter' = 10,
    'Turning' = 11,
    '3-Axis Milling' = 12,
    'CNC Sheetmetal' = 13,
    '4-Axis Milling' = 14,
}

export const TECHNOLOGY_TITLE_MAP = {
    1: 'SLS',
    2: 'FDM',
    3: 'SLM',
    4: '3DP',
    5: 'Multijet',
    6: 'Polyjet',
    7: 'SLA',
    8: 'HP MJF',
    9: 'Multi-Axis Milling',
    10: 'CNC Cutter',
    11: 'Turning',
    12: '3-Axis Milling',
    13: 'CNC Sheetmetal',
    14: '4-Axis Milling',
} as const;

export const TECHNOLOGY_SLUG_MAP = {
    1: 'sls',
    2: 'fdm',
    3: 'slm',
    4: '3dp',
    5: 'multijet',
    6: 'polyjet',
    7: 'sla',
    8: 'hp-mjf',
    9: 'multi-axis-milling',
    10: 'cnc-cutter',
    11: 'turning',
    12: '3-axis-milling',
    13: 'cnc-sheetmetal',
    14: '4-axis-milling',
} as const;

export type TechnologiesDict = typeof TECHNOLOGY_SLUG_MAP;

// creation of machines for this techs is prohibited
export const DISALLOWED_TECHNOLOGIES = [Technologies['Multijet'], Technologies['Polyjet']];

// TODO: `${Technologies}` extends `${infer T extends number}` ? T : never
// right way but babel has error with this variant
export type TechnologyIdsUnion = keyof TechnologiesDict;
export type TechnologyTitlesUnion = TechnologiesDict[keyof TechnologiesDict];
