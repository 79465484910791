import React, { PropsWithChildren, ReactNode } from 'react';
import { FieldValues, FormProvider, useFormState } from 'react-hook-form';
import { Button, ModalFooter, ModalBody, ModalProps } from '@chakra-ui/react';
import { UseUpdateFormPopupProps, useUpdateFormPopup } from '@shared/components/forms-v2';
import { getNodeIdComposer } from '@shared/utils/strings';
import { SectionModal } from './section-modal';

// todo move to common-modals

export interface SectionModalFormProps<Fields extends FieldValues = FieldValues>
    extends UseUpdateFormPopupProps<Fields> {
    trigger: (props: {
        onOpen: () => void;
        triggerId: (button?: string, ...args: Array<number | string>) => string;
    }) => React.ReactNode;
    formId: string;
    header: ReactNode;
    buttonText?: string;
    trapFocus?: boolean;
    shouldCheckDirty?: boolean;
    size?: ModalProps['size'];
    isCentered?: boolean;
    hasHeaderDivider?: boolean;
}

export const SectionModalForm = <Fields extends FieldValues>({
    trigger,
    formId,
    buttonText = 'save',
    header,
    data,
    schema,
    submit,
    trapFocus = true,
    stripUnknown = true,
    shouldCheckDirty = true,
    apiErrorsToastEnabled = true,
    apiErrorsFields,
    apiErrorsMap,
    onError,
    size = '3xl',
    hasHeaderDivider = true,
    children,
    ...props
}: PropsWithChildren<SectionModalFormProps<Fields>>) => {
    const _id = getNodeIdComposer(formId, 'popup');
    const triggerId = (button = 'trigger', ...args: Array<number | string>) => _id(button, ...args);

    const { onOpen, onClose, isOpen, handleFormSubmit, ...formApi } = useUpdateFormPopup<Fields>({
        data,
        schema,
        apiErrorsToastEnabled,
        apiErrorsFields,
        apiErrorsMap,
        onError,
        stripUnknown,
        submit,
    });
    const { isSubmitting, isDirty } = useFormState({ control: formApi.control });
    return (
        <>
            {trigger({ onOpen, triggerId })}
            <SectionModal
                header={header}
                onClose={onClose}
                isOpen={isOpen}
                trapFocus={trapFocus}
                size={size}
                hasHeaderDivider={hasHeaderDivider}
                {...props}
            >
                <ModalBody
                    id={formId}
                    as="form"
                    onSubmit={event => {
                        // https://github.com/react-hook-form/react-hook-form/issues/2076
                        event?.stopPropagation();
                        handleFormSubmit(event);
                    }}
                >
                    <FormProvider {...formApi}>{children}</FormProvider>
                </ModalBody>
                <ModalFooter pt={3.5} pb={3.5} bg="primary.default">
                    <Button
                        id={_id('submit_button')}
                        variant="onPrimary"
                        isDisabled={shouldCheckDirty && !isDirty}
                        isLoading={isSubmitting}
                        loadingText={buttonText}
                        form={formId}
                        type="submit"
                    >
                        {buttonText}
                    </Button>
                </ModalFooter>
            </SectionModal>
        </>
    );
};
