import { ReactNode, useRef } from 'react';
import {
    AlertDialog as ChakraAlertDialog,
    AlertDialogProps as ChakraAlertDialogProps,
    AlertDialogHeader,
    AlertDialogBody,
    AlertDialogContent,
    AlertDialogFooter,
    AlertDialogOverlay,
    Button,
} from '@chakra-ui/react';
import { getNodeIdComposer } from '@shared/utils/strings';

export interface AlertDialogProps
    extends Omit<ChakraAlertDialogProps, 'children' | 'leastDestructiveRef' | 'onClose'> {
    onCancel?: () => void;
    onConfirm: () => void;
    header: ReactNode;
    body: ReactNode;
    alertId: string;
    cancelButtonText?: string;
    confirmButtonText?: string;
    isLoading?: boolean;
}

export const AlertDialog = ({
    isOpen,
    onCancel,
    onConfirm,
    header,
    body,
    alertId,
    cancelButtonText = 'No',
    confirmButtonText = 'Yes',
    isLoading = false,
    isCentered = true,
    ...rest
}: AlertDialogProps) => {
    const _id = getNodeIdComposer(alertId);
    const cancelRef = useRef<HTMLButtonElement>(null);

    return (
        <ChakraAlertDialog
            variant="alert"
            size="sm"
            isOpen={isOpen}
            onClose={onCancel ?? (() => ({}))}
            isCentered={isCentered}
            leastDestructiveRef={cancelRef}
            lockFocusAcrossFrames={false}
            {...rest}
        >
            <AlertDialogOverlay>
                <AlertDialogContent>
                    <AlertDialogHeader textStyle="typography-lg">{header}</AlertDialogHeader>
                    <AlertDialogBody textStyle="typography-md">{body}</AlertDialogBody>
                    <AlertDialogFooter gap="2">
                        {onCancel && (
                            <Button
                                id={_id('cancel')}
                                size="sm"
                                variant="outline"
                                onClick={onCancel}
                                ref={cancelRef}
                            >
                                {cancelButtonText}
                            </Button>
                        )}
                        <Button
                            id={_id('confirm')}
                            size="sm"
                            onClick={onConfirm}
                            isLoading={isLoading}
                        >
                            {confirmButtonText}
                        </Button>
                    </AlertDialogFooter>
                </AlertDialogContent>
            </AlertDialogOverlay>
        </ChakraAlertDialog>
    );
};
