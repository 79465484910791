import { Suspense, lazy, memo, useEffect } from 'react';
import { importScript } from 'assets-dynamic-import';
import type { ModelViewerComponent as ModelViewerComponentDeclaration } from 'digifabster-model-viewer';
import { Image } from '@chakra-ui/react';
import { ModelSerializerV2 } from '@services/df/models';
import { getModelProps } from '@ducks/models';
import { PreloaderBox } from '@shared/components/preloader';

// todo remove this garbage after properly building and installing the 'digifabster-model-viewer' package
export const ModelViewerComponent = lazy(() =>
    importScript(process.env.PUBLIC_URL + '/static/externals/model-viewer.umd.js?v=1', () => ({
        default: (
            global as unknown as {
                ModelViewer: {
                    ModelViewerComponent: typeof ModelViewerComponentDeclaration;
                };
            }
        ).ModelViewer.ModelViewerComponent,
    })),
);

// // todo this way we can't avoid processing 'digifabster-model-viewer' by webpack, cause it's broken source codes (dist)
// export const ModelViewerComponent = lazy(() =>
//     import('digifabster-model-viewer').then(({ ModelViewerComponent }) => ({
//         default: ModelViewerComponent,
//     })),
// );

interface Props {
    model: ModelSerializerV2;
}

export const ModelViewer = memo(
    ({ model }: Props) => {
        const { file_model_viewer_url, thumb } = model;
        const { hasViewer } = getModelProps({ model });

        // useEffect(() => {
        //     console.log('ModelViewer mount');
        //
        //     return () => {
        //         console.log('ModelViewer unmount');
        //     };
        // }, []);

        return file_model_viewer_url ? (
            <Suspense fallback={<PreloaderBox in />}>
                {hasViewer ? (
                    <ModelViewerComponent fileUrl={file_model_viewer_url} />
                ) : (
                    <Image src={thumb} objectFit="contain" position="absolute" w="full" h="full" />
                )}
            </Suspense>
        ) : null;
    },
    (next, prev) => next.model.file_model_viewer_url === prev.model.file_model_viewer_url,
);
