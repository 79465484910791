import { createSlice } from '@reduxjs/toolkit';
import { PriceTweakerResponse, enhancedApi as ptApi } from '@services/df/price-tweaker';
import { RootState } from '@app/types';
import { priceTweakerUpdateConfig, priceTweakerResetConfig } from './config';

const initialState = {
    data: {} as PriceTweakerResponse['data'],
    errors: [] as PriceTweakerResponse['errors'],
    isError: false,
    isSuccess: false,
    isLoading: false,
};

export const calculationResultSlice = createSlice({
    name: 'priceTweaker/calculationResult',
    initialState,
    reducers: {
        resetError(state) {
            state.isError = false;
            state.errors = [];
        },
    },
    extraReducers: builder => {
        builder.addCase(priceTweakerResetConfig, () => {
            return initialState;
        });
        builder.addCase(priceTweakerUpdateConfig, (state, { payload }) => {
            // reset
            if (!payload.materialId) {
                return initialState;
            }
        });
        builder.addMatcher(ptApi.endpoints.priceTweakerCreate.matchPending, state => {
            state.isLoading = true;
        });
        builder.addMatcher(
            ptApi.endpoints.priceTweakerCreate.matchFulfilled,
            (state, { payload }) => {
                state.data = payload.data;
                state.errors = payload.errors;
                state.isSuccess = payload.success;
                state.isLoading = false;
            },
        );
    },
});

export const { resetError: priceTweakerResetError } = calculationResultSlice.actions;

export const selectPriceTweakerPrices = (state: RootState) =>
    state.priceTweaker.calculationResult.data?.prices;
export const selectPriceTweakerPostProductionPrices = (state: RootState) =>
    state.priceTweaker.calculationResult.data?.post_production_prices;
export const selectPriceTweakerModelValues = (state: RootState) =>
    state.priceTweaker.calculationResult.data?.model_values;
export const selectPriceTweakerErrors = (state: RootState) =>
    state.priceTweaker.calculationResult.errors;
export const selectPriceTweakerHasError = (state: RootState) =>
    state.priceTweaker.calculationResult.isError ||
    !!state.priceTweaker.calculationResult.errors?.length;
export const selectPriceTweakerHasSuccessResult = (state: RootState) =>
    state.priceTweaker.calculationResult.isSuccess;
export const selectPriceTweakerIsCalculating = (state: RootState) =>
    state.priceTweaker.calculationResult.isLoading;
