import { PropsWithChildren } from 'react';
import { Button, FlexProps } from '@chakra-ui/react';
import { Control, useFormState } from 'react-hook-form';
import { FullPageBanner } from '@shared/components/full-page-banner';

interface Props extends Omit<FlexProps, 'onSubmit'> {
    formId: string;
    control?: Control;
    buttonText?: string;
    resetButtonText?: string;
    showResetButton?: boolean;
}

export const FullPageBannerSubmit = ({
    formId,
    control,
    buttonText = 'save',
    resetButtonText = 'cancel',
    showResetButton = false,
    children,
    ...props
}: PropsWithChildren<Props>) => {
    const { isSubmitting, isDirty } = useFormState({ control });
    return (
        <FullPageBanner in={isDirty} {...props}>
            {children}
            {showResetButton && (
                <Button
                    mr={3}
                    variant="onPrimary"
                    isDisabled={isSubmitting}
                    form={formId}
                    type="reset"
                >
                    {resetButtonText}
                </Button>
            )}
            <Button
                mr={'60px'}
                variant="onPrimary"
                isLoading={isSubmitting}
                loadingText={buttonText}
                form={formId}
                type="submit"
            >
                {buttonText}
            </Button>
        </FullPageBanner>
    );
};
