import { TextIndicator, TextIndicatorProps } from '@shared/components/typography';

interface Props extends TextIndicatorProps {
    isPaid: boolean;
}

export const OrderPaymentStatus = ({ isPaid, ...rest }: Props) => {
    return (
        <TextIndicator type={isPaid ? 'success' : undefined} {...rest}>
            {isPaid ? 'Paid' : 'Unpaid'}
        </TextIndicator>
    );
};
