import { Button, IconButton, IconButtonProps } from '@chakra-ui/react';
import { BellIcon } from '@shared/components/icons';
import { SystemMessagesPopover } from './system-messages-popover';

interface Props extends Omit<IconButtonProps, 'aria-label'> {
    asIcon?: boolean;
    children?: string;
}

export const SystemMessagesButton = ({
    asIcon = false,
    children = 'See new notifications here',
    ...props
}: Props) => {
    const icon = <BellIcon />;
    const baseProps = {
        variant: 'link',
        colorScheme: 'neutral',
    };
    return (
        <SystemMessagesPopover>
            {asIcon ? (
                <IconButton {...baseProps} icon={icon} aria-label={children} {...props} />
            ) : (
                <Button {...baseProps} rightIcon={icon} {...props}>
                    <span>{children}</span>
                </Button>
            )}
        </SystemMessagesPopover>
    );
};
