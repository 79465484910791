import { ComponentProps } from 'react';
import { Portal, ScaleFade } from '@chakra-ui/react';
import { Alert } from '@shared/components/alert';

export const StaticToast = (props: ComponentProps<typeof Alert>) => (
    <ScaleFade>
        <Portal>
            <Alert position="fixed" top={8} right={8} zIndex="toast" {...props} />
        </Portal>
    </ScaleFade>
);
