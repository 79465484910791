import { Checkbox as ChakraCheckbox, CheckboxProps as ChakraCheckboxProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { CheckboxIcon } from '@shared/components/icons';
import { useFieldStyles } from '../hooks';

export type CheckboxProps = ChakraCheckboxProps;

export const Checkbox = forwardRef<CheckboxProps, 'input'>((_props, ref) => {
    const props = useFieldStyles('checkbox', _props);
    return <ChakraCheckbox icon={<CheckboxIcon />} ref={ref} {...props} />;
});
