import { HStack, Link, Text, VStack } from '@chakra-ui/react';
import { AppLogo } from '@shared/components/app-logo';

export const SignInHead = () => {
    return (
        <VStack justifyContent="center">
            <AppLogo h="34px" />
            <HStack>
                <Text textStyle="typography-md" color="secondary.default">
                    Don’t have an account?{' '}
                    <Link
                        variant="highlighted"
                        href="https://digifabster.com/getstarted/"
                        isExternal
                    >
                        Get started free
                    </Link>
                </Text>
            </HStack>
        </VStack>
    );
};
