import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, FieldType } from '@shared/components/forms-v2';

export const getClientConfig = () => {
    const name = defineField({
        type: FieldType.Text,
        name: 'name',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Name',
        },
    });

    const surname = defineField({
        type: FieldType.Text,
        name: 'surname',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Surname',
        },
    });

    const email = defineField({
        type: FieldType.Text,
        name: 'email',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Email',
            clipboard: true,
            disabled: true,
            formControlProps: { isReadOnly: true },
        },
    });

    const phone_number = defineField({
        type: FieldType.Text,
        name: 'phone_number',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Phone number',
        },
    });

    const company_name = defineField({
        type: FieldType.Text,
        name: 'company_name',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Name of company',
        },
    });

    const position = defineField({
        type: FieldType.Text,
        name: 'position',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Position in company',
        },
    });

    const schema = yup.object(
        mapValues(
            {
                name,
                surname,
                email,
                phone_number,
                company_name,
                position,
            },
            'scheme',
        ),
    );

    const fieldsArray = [name, surname, email, phone_number, company_name, position];
    const fields = {
        name,
        surname,
        email,
        phone_number,
        company_name,
        position,
    };

    return {
        fieldsArray,
        fields,
        schema,
    };
};

export type ClientConfig = ReturnType<typeof getClientConfig>;
export type ClientSchema = ClientConfig['schema'];
export type ClientFields = ClientConfig['fields'];
export type ClientFieldsUnion = keyof ClientConfig['fields'];
export type ClientFieldsArray = Array<ClientFields[ClientFieldsUnion]>;

// todo static method of FormHelper.fromFields
export const getClientConfigFromFields = (fields: ReadonlyArray<ClientFieldsUnion>) => {
    const { fields: _fields, schema } = getClientConfig();

    return {
        fields: fields.map(field => _fields[field]),
        fieldsMap: _fields,
        // @ts-ignore
        schema: schema.pick(fields),
    };
};
