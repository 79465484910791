import { Currency, KanbanDashboardConfig } from '@services/df/app';

export const DEFAULT_KANBAN_DASHBOARD_CONFIG = {
    groups: [
        {
            title: 'To-do',
            statuses: ['waiting_for_review', 'placed', 'firm_offer_sent', 'waiting_for_payment'],
        },
        {
            title: 'Manufacturing Planning',
            statuses: [
                'planning_confirmed',
                'po_provided',
                'free_for_manufacturing',
                'po_payment_due',
            ],
        },
        {
            title: 'Manufacturing',
            statuses: [
                'manufacturing',
                'post_production',
                're_manufacturing',
                'ready_for_shipping',
                'ready_for_pickup',
            ],
        },
        {
            title: 'Done',
            statuses: ['delivered', 'cancelled_by_user', 'cancelled_by_admin', 'shipped'],
        },
    ],
    card: { order_fields: ['placed_at', 'buyers_full_name', 'email'] },
} as Required<KanbanDashboardConfig>;

export const DEFAULT_CURRENCY: Currency = {
    title: 'usd',
    symbol: '$',
    position: 'left',
    separator: ',',
    decimal: '.',
    decimal_digits: 2,
};
