import { ResponsiveValue, SystemProps } from '@chakra-ui/system';

// todo move to semanticTokens
export const FIELD_MAX_W: SystemProps['maxW'] = '330px';

export const SECTION_GRID_STYLES: {
    columns: ResponsiveValue<number>;
    rowGap: SystemProps['margin'];
    columnGap: SystemProps['margin'];
    groupRowGap: SystemProps['margin'];
} = {
    columns: 2,
    columnGap: 14,
    rowGap: 10,
    groupRowGap: 6,
};

export const SECTION_ENTITY_RECORD_STYLES = { flexGrow: 1, maxW: FIELD_MAX_W };
