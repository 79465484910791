/*
    Based on codegen.ts
*/

import {
    BlankEnum,
    Color,
    ColorRequest,
    Execution,
    ExecutionRequest,
    PatchedExecutionRequest,
} from '../codegen';
import { enhancedApi as api } from '../machines/manual';
import { getMachineSlugByTechnology } from '../helpers';
import type {
    MaterialMilling3,
    Material3Dp,
    MaterialCutter,
    MaterialSheetMetal,
    MaterialFdm,
    MaterialMjf,
    MaterialMilling5,
    MaterialMultijet,
    MaterialPolyjet,
    MaterialSla,
    MaterialSlm,
    MaterialSls,
    MaterialTurning,
    MaterialMilling3Request,
    Material3DpRequest,
    MaterialCutterRequest,
    MaterialSheetMetalRequest,
    MaterialFdmRequest,
    MaterialMjfRequest,
    MaterialMilling5Request,
    MaterialMultijetRequest,
    MaterialPolyjetRequest,
    MaterialSlaRequest,
    MaterialSlmRequest,
    MaterialSlsRequest,
    MaterialTurningRequest,
    PriceUnitsEnum,
    FormTypeEnum,
    NullEnum,
    // Execution,
    PostProduction,
    Extrafieldset,
    Barstock,
    Bend,
    // ExecutionRequest,
    PostProductionRequest,
    ExtrafieldOptionRequest,
    ExtrafieldsetRequest,
    BarstockRequest,
    BendRequest,
    // PatchedExecutionRequest,
    // PatchedPostProductionRequest,
    // PatchedExtrafieldsetRequest,
    // PatchedBarstockRequest,
    // PatchedBendRequest,
} from './codegen';

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        materialsList: build.query<MaterialsListApiResponse, MaterialsListApiArg>({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/`,
            }),
        }),
        materialsUpdate: build.mutation<MaterialsUpdateApiResponse, MaterialsUpdateApiArg>({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/`,
                method: 'PUT',
                body: queryArg.materialRequest,
            }),
        }),
        materialsRetrieve: build.query<MaterialsRetrieveApiResponse, MaterialsRetrieveApiArg>({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
            }),
        }),
        materialsPartialUpdate: build.mutation<
            MaterialsPartialUpdateApiResponse,
            MaterialsPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
                method: 'PATCH',
                body: queryArg.patchedMaterialRequest,
            }),
        }),
        materialsDestroy: build.mutation<MaterialsDestroyApiResponse, MaterialsDestroyApiArg>({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/`,
                method: 'DELETE',
            }),
        }),
        materialsDuplicateCreate: build.mutation<
            MaterialsDuplicateCreateApiResponse,
            MaterialsDuplicateCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${getMachineSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.id
                }/duplicate/`,
                method: 'POST',
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
// export { injectedRtkApi as enhancedMaterialsApi };

export type MaterialsListApiResponse = /** status 200  */ Material[];
export type MaterialsListApiArg = {
    technologyId: number;
};
export type MaterialsUpdateApiResponse = /** status 200  */ Material;
export type MaterialsUpdateApiArg = {
    technologyId: number;
    materialRequest: MaterialRequest;
};
export type MaterialsRetrieveApiResponse = /** status 200  */ Material;
export type MaterialsRetrieveApiArg = {
    id: number;
    technologyId: number;
};
export type MaterialsPartialUpdateApiResponse = /** status 200  */ Material;
export type MaterialsPartialUpdateApiArg = {
    id: number;
    technologyId: number;
    patchedMaterialRequest: PatchedMaterialRequest;
};
export type MaterialsDestroyApiResponse = unknown;
export type MaterialsDestroyApiArg = {
    id: number;
    technologyId: number;
};
export type MaterialsDuplicateCreateApiResponse = /** status 200  */ Material;
export type MaterialsDuplicateCreateApiArg = {
    id: number;
    technologyId: number;
};

export type PatchedPostProductionRequest = {
    title?: string;
    active?: boolean;
    form_type?: FormTypeEnum;
    is_custom?: boolean | null;
    price?: number | null;
    price_units?: (PriceUnitsEnum | BlankEnum | NullEnum) | null;
    minimal_price?: number | null;
    setup_cost?: number | null;
    production_days?: number;
    name_for_user?: string;
    note_for_user?: string;
    group_title?: string;
    countable?: boolean;
    is_exclusive?: boolean;
    manual_review?: boolean;
    colors?: ColorRequest[];
    sort_order?: number;
};

export type PatchedExtrafieldsetRequest = {
    title?: string;
    options?: ExtrafieldOptionRequest[];
    is_active?: boolean;
    sort_order?: number;
};

export type PatchedBarstockRequest = {
    x?: number;
    y?: number;
    z?: number;
    id?: string;
    feed_rate?: number | null;
    price_per_cm?: number;
    price_per_sq_cm?: number;
    is_machined?: boolean;
    price_of_squaring?: number;
    price_per_punch?: number;
    price_per_bend?: number;
    bends_min_radius?: number;
};

export type PatchedBendRequest = {
    id?: string;
    price_per_bend?: number;
    min_angle?: number;
    max_angle?: number;
    min_length?: number;
    max_length?: number;
};

type MaterialCombined_ = MaterialMilling3 &
    Material3Dp &
    MaterialCutter &
    MaterialSheetMetal &
    MaterialFdm &
    MaterialMjf &
    MaterialMilling5 &
    MaterialMultijet &
    MaterialPolyjet &
    MaterialSla &
    MaterialSlm &
    MaterialSls &
    MaterialTurning;

type MaterialRequestCombined_ = MaterialMilling3Request &
    Material3DpRequest &
    MaterialCutterRequest &
    MaterialSheetMetalRequest &
    MaterialFdmRequest &
    MaterialMjfRequest &
    MaterialMilling5Request &
    MaterialMultijetRequest &
    MaterialPolyjetRequest &
    MaterialSlaRequest &
    MaterialSlmRequest &
    MaterialSlsRequest &
    MaterialTurningRequest;

export type Material = Omit<
    MaterialCombined_,
    'execution' | 'postproduction' | 'extra_fieldsets' | 'bar_stocks' | 'bends'
> & {
    execution?: Execution[];
    postproduction?: PostProduction[];
    extra_fieldsets?: Extrafieldset[];
    bar_stocks?: Barstock[];
    bends?: Bend[];
};

export type MaterialRequest = Omit<
    MaterialRequestCombined_,
    'execution' | 'postproduction' | 'extra_fieldsets' | 'bar_stocks' | 'bends'
> & {
    execution?: ExecutionRequest[];
    postproduction?: PostProductionRequest[];
    extra_fieldsets?: ExtrafieldsetRequest[];
    bar_stocks?: BarstockRequest[];
    bends?: BendRequest[];
};

export type PatchedMaterialRequest = Partial<
    Omit<
        MaterialRequestCombined_,
        'execution' | 'postproduction' | 'extra_fieldsets' | 'bar_stocks' | 'bends'
    >
> & {
    execution?: PatchedExecutionRequest[];
    postproduction?: PatchedPostProductionRequest[];
    extra_fieldsets?: PatchedExtrafieldsetRequest[];
    bar_stocks?: PatchedBarstockRequest[];
    bends?: PatchedBendRequest[];
};

export const {
    useMaterialsListQuery,
    useMaterialsUpdateMutation,
    useMaterialsRetrieveQuery,
    useMaterialsPartialUpdateMutation,
    useMaterialsDestroyMutation,
    useMaterialsDuplicateCreateMutation,
} = injectedRtkApi;
