import uniqBy from 'lodash/uniqBy';
import React, { forwardRef, MutableRefObject, useCallback } from 'react';
import {
    CreatableSelect,
    CreatableProps as ChakraCreatableProps,
    GroupBase,
    SelectInstance,
    OnChangeValue,
    ActionMeta,
} from 'chakra-react-select';
import { useFieldStyles } from '../hooks';

const components = {
    DropdownIndicator: null,
};

export interface EditableListOption {
    readonly label: string;
    readonly value: string;
}

type CreateOption<Option extends EditableListOption = EditableListOption> = (
    value: string,
) => Option;

const defaultCreateOption: CreateOption = value => ({
    value,
    label: value,
});

type CreatableProps<Option extends EditableListOption = EditableListOption> = {
    filter?: (option: Option) => boolean;
    format?: (option: Option) => Option;
    // createOption?: CreateOption<Option>;
};

export type EditableListProps<
    Option extends EditableListOption = EditableListOption,
    Group extends GroupBase<Option> = GroupBase<Option>,
> = ChakraCreatableProps<Option, true, Group> &
    CreatableProps<Option> &
    (EditableListOption extends Option
        ? {
              createOption?: CreateOption<Option>;
          }
        : {
              createOption: CreateOption<Option>;
          });

export const EditableList = forwardRef(
    <Option extends EditableListOption, Group extends GroupBase<Option> = GroupBase<Option>>(
        {
            size = 'md',
            colorScheme,
            tagVariant,
            onChange: propsOnChange,
            format,
            filter,
            createOption: _createOption,
            chakraStyles = {},
            value = [],
            // defaultValue = [],
            ...props
        }: EditableListProps<Option, Group>,
        ref:
            | ((instance: SelectInstance<Option, true, Group> | null) => void)
            | MutableRefObject<SelectInstance<Option, true, Group> | null>
            | null,
    ) => {
        // https://stackoverflow.com/questions/70391664/why-typescript-complains-that-xxx-is-assignable-to-the-constraint-of-type-t-b/70392066#70392066
        // https://dev.to/wes/b-is-assignable-to-the-constraint-of-type-t-but-t-could-be-instantiated-with-a-different-subtype-of-constraint-a-2l38
        const createOption = (_createOption ?? defaultCreateOption) as CreateOption<Option>;
        const theme = useFieldStyles('editableList', { size, colorScheme, tagVariant });
        const { control, clearIndicator, inputContainer, multiValueLabel } = chakraStyles;

        // save it locally for recovery
        // const [options, setOptions] = React.useState<readonly Option[]>(
        //     () => value as MultiValue<Option>,
        // );

        const onChange = useCallback(
            (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
                const filteredValue = filter ? newValue.filter(filter) : newValue;
                const formattedValue = format ? filteredValue.map(format) : filteredValue;
                const cleanValue = uniqBy(formattedValue, 'value');

                propsOnChange?.(cleanValue, actionMeta);
            },
            [propsOnChange, format, filter],
        );

        return (
            <CreatableSelect
                {...theme}
                ref={ref}
                isMulti
                components={components}
                onChange={onChange}
                placeholder="Type something and press enter or tab..."
                value={(value as unknown as string[]).map(createOption)}
                // defaultValue={(defaultValue as unknown as string[]).map(createOption)}
                chakraStyles={{
                    ...chakraStyles,
                    control: provided => ({
                        ...provided,
                        minH: 9,
                        ...control,
                    }),
                    clearIndicator: (provided, { selectProps }) => ({
                        ...provided,
                        fontSize: '8px',
                        color: selectProps.menuIsOpen ? undefined : 'gray.400',
                        ...clearIndicator,
                    }),
                    inputContainer: (provided, props) => ({
                        ...provided,
                        overflow: 'hidden',
                        ...inputContainer?.(provided, props),
                    }),
                    // yes it is a hack but this solution is better than the last example from https://react-select.com/creatable
                    menu: () => ({
                        display: 'none',
                    }),
                    multiValueLabel: (provided, props) => ({
                        ...provided,
                        fontWeight: '600',
                        wordBreak: 'break-all',
                        ...multiValueLabel?.(provided, props),
                    }),
                }}
                {...props}
            />
        );
    },
);
