import isNaN from 'lodash/isNaN';
import isNull from 'lodash/isNull';
import isNumber from 'lodash/isNumber';
import React, { useCallback, useState } from 'react';
import { useCallbackRef } from '@chakra-ui/hooks';
import { callAllHandlers } from '@chakra-ui/utils';
import { useController } from 'react-hook-form';
import { ControllerFieldProps } from '../types';
import { FieldControl } from '../common';
import { NumberInput, NumberInputProps } from '../inputs';
import { getFieldState } from '../helpers';

const transformInput = (value: string | number) => (isNull(value) || isNaN(value) ? '' : value);
const transformOutput = (valueAsString: string, valueAsNumber: number) =>
    isNaN(valueAsNumber) ? null : valueAsNumber;

export type NumberFieldProps = NumberInputProps & ControllerFieldProps;

export const NumberField = ({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    tooltip,
    helpText,
    helpTextAsTooltip,
    isHelpTextAboveInput = false,
    errorText: _,

    formControlProps,
    labelProps,
    errorProps,
    helpTextProps,
    tooltipProps,

    ...inputProps
}: NumberFieldProps) => {
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const {
        field: { value, onChange: _onChange, onBlur: _onBlur, ...field },
    } = state;

    // the main purpose of this is to separate the types of values we want to pass to the component and rhf
    const [viewValue, setViewValue] = useState(value);

    const inputOnChange = inputProps?.onChange;

    const onChange = useCallback(
        (valueAsString: string, valueAsNumber: number) => {
            inputOnChange?.(valueAsString, valueAsNumber);
            _onChange(transformOutput(valueAsString, valueAsNumber));
            setViewValue(valueAsString);
        },
        [inputOnChange, _onChange],
    );

    const onBlur = useCallbackRef(inputProps?.onBlur);

    // sync viewValue with value on rhf.setValue()
    // todo NaN case value is not processed
    if (isNumber(value) && value !== parseFloat(viewValue)) {
        setViewValue(value);
    }

    return (
        <FieldControl
            label={label}
            labelProps={labelProps}
            tooltip={tooltip}
            tooltipProps={tooltipProps}
            helpText={helpText}
            helpTextAsTooltip={helpTextAsTooltip}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            isHelpTextAboveInput={isHelpTextAboveInput}
            {...formControlProps}
        >
            <NumberInput
                {...field}
                {...inputProps}
                value={transformInput(viewValue)}
                onBlur={callAllHandlers(_onBlur, onBlur)}
                onChange={onChange}
                isInvalid={isInvalid}
            />
        </FieldControl>
    );
};
