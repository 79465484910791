import has from 'lodash/has';
import { MaterialGroup } from './codegen';
import { enhancedApi as api } from './materials';

api.enhanceEndpoints({
    endpoints: {
        adminTechnologiesRetrieve: {
            providesTags: ['AdminTechnologies'],
        },
        machinesMaterialsList: {
            providesTags: ['Machines'],
        },
        machinesSamplesCreate: {
            invalidatesTags: (result, error, arg) => [{ type: 'Machines' }],
        },
        machinesPartialUpdate: {
            async onQueryStarted(
                { technologyId, id, patchedMachineRequest },
                { dispatch, queryFulfilled },
            ) {
                // optimistic updates
                if (has(patchedMachineRequest, 'active')) {
                    const patchResult = dispatch(
                        api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                            const index = draft.findIndex(machine => machine.id === id);
                            if (index !== -1) draft[index].active = patchedMachineRequest.active;
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                } else {
                    try {
                        const { data } = await queryFulfilled;

                        dispatch(
                            api.util.updateQueryData(
                                'machinesRetrieve',
                                { id, technologyId },
                                draft => data,
                            ),
                        );
                    } catch {
                        dispatch(api.util.invalidateTags(['Machines']));
                    }
                }
            },
        },
        machinesDestroy: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                        const index = draft.findIndex(machine => machine.id === id);
                        if (index !== -1) draft.splice(index, 1);
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        },
        machinesDuplicateCreate: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                            draft.unshift(data);
                        }),
                    );
                } catch {}
            },
        },
        materialsPartialUpdate: {
            async onQueryStarted(
                { technologyId, id, patchedMaterialRequest },
                { dispatch, queryFulfilled },
            ) {
                // optimistic updates
                if (has(patchedMaterialRequest, 'active')) {
                    const patchResult = dispatch(
                        api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                            const machineIndex = draft.findIndex(machine =>
                                machine.materials.find(material => material.id === id),
                            );

                            if (machineIndex !== -1) {
                                const materials = draft[machineIndex].materials;
                                const materialIndex = materials.findIndex(
                                    material => material.id === id,
                                );
                                if (materialIndex !== -1)
                                    draft[machineIndex].materials[materialIndex].active =
                                        !!patchedMaterialRequest.active;
                            }
                        }),
                    );

                    try {
                        await queryFulfilled;
                    } catch {
                        patchResult.undo();
                    }
                } else {
                    try {
                        const { data } = await queryFulfilled;

                        dispatch(
                            api.util.updateQueryData(
                                'materialsRetrieve',
                                { id, technologyId },
                                draft => data,
                            ),
                        );
                    } catch {
                        // dispatch(api.util.invalidateTags(['Machines']));
                    }
                }
            },
        },
        materialsDestroy: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                const patchResult = dispatch(
                    api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                        const machineIndex = draft.findIndex(machine =>
                            machine.materials.find(material => material.id === id),
                        );

                        if (machineIndex !== -1) {
                            const materials = draft[machineIndex].materials;
                            const materialIndex = materials.findIndex(
                                material => material.id === id,
                            );
                            if (materialIndex !== -1) materials.splice(materialIndex, 1);
                        }
                    }),
                );

                try {
                    await queryFulfilled;
                } catch {
                    patchResult.undo();
                }
            },
        },
        materialsDuplicateCreate: {
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('machinesMaterialsList', {}, draft => {
                            const machineIndex = draft.findIndex(
                                machine => machine.id === data.printer,
                            );

                            if (machineIndex !== -1) {
                                const materials = draft[machineIndex].materials;
                                materials.push(data as MaterialGroup);
                            }
                        }),
                    );
                } catch {}
            },
        },
    },
});
