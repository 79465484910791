import { Icon, IconProps } from '@chakra-ui/react';

// This icon is looks like a Prohibition Road Sign

export const CircleSlashed2Icon = (props: IconProps) => (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
        <circle
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="4.93"
            y1="4.93"
            x2="19.07"
            y2="19.07"
            stroke="currentColor"
            strokeWidth="2.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
