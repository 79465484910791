import debounce from 'lodash/debounce';
import { useCallback, useEffect, useRef } from 'react';
import { ClientExtendedRepresentation, enhancedApi as api } from '@services/df/clients';
import { OptionType } from '@shared/components/forms-v2';
import { getPaginationRequestParams } from '@shared/components/pagination';

export type ClientOption = OptionType & {
    client: ClientExtendedRepresentation;
};

const createOptions = (clients: ClientExtendedRepresentation[] = []) =>
    clients?.map((client: ClientExtendedRepresentation) => ({
        client,
        value: client.id.toString(),
        label: `${client.full_name} (${client.email})`,
    }));

interface Props {
    clientsIds?: number[];
}

// https://stackoverflow.com/questions/55073545/react-select-debounced-async-call-not-displaying-suggestions
export function useClientsAsyncOptions({ clientsIds }: Props = {}) {
    const [trigger] = api.useLazyClientsListQuery();
    const optionsMap = useRef<Record<string, ClientOption>>({});

    const mergeOptionsMap = useCallback((options: ClientOption[]) => {
        optionsMap.current = Object.assign(
            optionsMap.current,
            options.reduce(
                (acc, item) => ({
                    ...acc,
                    [item.value]: item,
                }),
                {} as Record<string, ClientOption>,
            ),
        );
    }, []);

    const filter = useRef(
        debounce((search: string, callback: (options: ClientOption[]) => void) => {
            trigger(
                {
                    search,
                    withOrganization: false,
                    ...getPaginationRequestParams(1, 100),
                },
                /* preferCacheValue */ false,
            ).then(({ data }) => {
                const result = createOptions(data?.results);
                mergeOptionsMap(result);
                callback(result);
            });
        }, 800),
    );

    // load initial clients
    useEffect(() => {
        if (!clientsIds?.length) return;

        trigger(
            {
                idIn: clientsIds,
            },
            /* preferCacheValue */ false,
        ).then(({ data }) => {
            const result = createOptions(data?.results);
            mergeOptionsMap(result);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [trigger]);

    return { filter: filter.current, optionsMap: optionsMap.current };
}
