import { createSelector } from '@reduxjs/toolkit';
import { enhancedApi as api, SampleModelType } from '@services/df/models';
import { RootState } from '@app/types';
import {
    isBelong3D,
    isMillingGroup,
    isTurning,
    isCncCutter,
    isCncSheetmetal,
} from '@shared/utils/technology-guard';

export const sampleModelsListSelector = api.endpoints.sampleModelsList.select({});
export const selectSampleModels = (state: RootState) => sampleModelsListSelector(state).data?.items;
export const selectSampleModelById = (state: RootState, modelId: number) =>
    sampleModelsListSelector(state).data?.items.find(model => model.id === modelId);
export const selectSampleModelsByTechnology = createSelector(
    selectSampleModels,
    (state: RootState, technologyId?: number | null) => technologyId,
    (models, technologyId) => {
        const sampleType = isBelong3D(technologyId)
            ? SampleModelType.PriceTweaker3d
            : isMillingGroup(technologyId) || isTurning(technologyId)
            ? SampleModelType.PriceTweakerCnc
            : isCncCutter(technologyId)
            ? SampleModelType.ComplexityBracketingCutter
            : // : isCncSheetmetal(technologyId)
              // ? SampleModelType.ComplexityBracketingSheetmetal
              null;

        return models?.filter(model => model.sample_model_type === sampleType);
    },
);
