import React from 'react';
import { Link } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { Box, BoxProps, IconButton, Image, Flex, HStack, Text, Tooltip } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { useLogoutMutation } from '@services/df/auth';
import { useClientsMeRetrieveQuery } from '@services/df/clients';
import { getMediaUrl } from '@services/instance';
import { useAppSelector } from '@app/hooks';
import { selectSettings } from '@ducks/app';
import { AppLogo } from '@shared/components/app-logo';
import { LogoutIcon } from '@shared/components/icons';
import HwBadge from '@shared/components/hw-badge';
import { isAbsoluteUrl } from '@shared/utils/routes';
import { ROUTES } from '@shared/constants';
import { SideNavigationItems } from './side-navigation-items';
import { OnItemSelect } from './types';

const UserProfile = () => {
    // const { userIsManager } = useAppSelector(selectUserPermissions);
    const { logo_url, company_title } = useAppSelector(selectSettings)!;
    const { data: currentUser } = useClientsMeRetrieveQuery();

    return (
        // <HStack {...(userIsManager && { as: Link, to: ROUTES.PERSONAL_INFO_SETTINGS })}>
        <HStack as={Link} to={ROUTES.PERSONAL_INFO_SETTINGS} wordBreak="break-word">
            <Flex
                overflow="hidden"
                position="relative"
                borderRadius="8"
                boxSize="42px"
                bgColor="white"
                flexShrink={0}
            >
                {/*<Image*/}
                {/*    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6ab0v0R8OK9lD-o0WsSxwNYKiOJz2-oxBvA&s"*/}
                {/*    alt={company_title}*/}
                {/*/>*/}
                {/* todo Thumb? with fallback='' */}
                {logo_url && (
                    <Image
                        src={isAbsoluteUrl(logo_url) ? logo_url : getMediaUrl(logo_url)}
                        alt={company_title}
                        objectFit="cover"
                    />
                )}
                {/*{*/}
                {/*    <Image*/}
                {/*        src="https://digifabster.com/media/logos/p1by3k.png"*/}
                {/*        alt={company_title}*/}
                {/*        objectFit="cover"*/}
                {/*    />*/}
                {/*}*/}
            </Flex>
            {/*<Image borderRadius="8" boxSize="42px" src={logo_url} alt={company_title} />*/}
            {/*<Image*/}
            {/*    borderRadius="8"*/}
            {/*    boxSize="42px"*/}
            {/*    src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR6ab0v0R8OK9lD-o0WsSxwNYKiOJz2-oxBvA&s"*/}
            {/*    alt={company_title}*/}
            {/*/>*/}

            <Box>
                <Text textStyle="button-md" color="secondary.default">
                    {company_title}
                </Text>
                <Text textStyle="typography-sm" color="font.secondary" mt="0.5">
                    {currentUser?.full_name}
                </Text>
            </Box>
        </HStack>
    );
};

const NavigationHeader = () => (
    <HStack justifyContent="space-between" p={4}>
        <AppLogo h="22px" />
        <HwBadge />
    </HStack>
);

const NavigationContent = forwardRef<BoxProps, 'div'>(({ children, ...rest }, ref) => (
    <Box ref={ref} overflowY="auto" flex="1 1 auto" {...rest}>
        <Box
            as="nav"
            h="full"
            // h="3000px"
            p={4}
        >
            {children}
        </Box>
    </Box>
));

const NavigationFooter = () => {
    const [logout] = useLogoutMutation();
    const { shutdown } = useIntercom();
    const handleLogoutClick = async () => {
        try {
            await logout().unwrap();
            shutdown();
            window.location.assign(ROUTES.SIGN_IN);
        } catch (error) {}
    };

    return (
        <HStack justifyContent="space-between" p={4}>
            <UserProfile />
            <Tooltip label="Logout">
                <IconButton
                    variant="link"
                    icon={<LogoutIcon color="neutral.dark" />}
                    aria-label="logout"
                    onClick={handleLogoutClick}
                />
            </Tooltip>
        </HStack>
    );
};

interface SideNavigationProps extends BoxProps {
    onItemSelect?: OnItemSelect;
}

export const SideNavigation = forwardRef<SideNavigationProps, 'div'>(
    ({ children, w = 'sideNavigation', onItemSelect, ...rest }, ref) => (
        <Box ref={ref} position="relative" zIndex={2} w={w} {...rest}>
            <Flex
                flexDirection="column"
                position="fixed"
                w={w}
                h="100vh"
                bgColor="neutral.subtlest"
                // boxShadow="navigation"
            >
                <NavigationHeader />
                <NavigationContent>
                    <SideNavigationItems
                        display="flex"
                        flexDirection="column"
                        onItemSelect={onItemSelect}
                    />
                    {children}
                </NavigationContent>
                <NavigationFooter />
            </Flex>
        </Box>
    ),
);
