import { Box, Flex, FlexProps, Text } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { ModelSerializerV2 } from '@services/df/models';
import { Thumb } from '@shared/components/thumb';
import { ModelPerimeter } from './model-perimeter';
import { ModelSizes } from './model-sizes';
import { ModelVolume } from './model-volume';

// todo move to entities/models/ui

type Props = Omit<FlexProps, 'id'> & {
    isActive: boolean;
    model: ModelSerializerV2;
};

export const ModelCard = forwardRef<Props, 'div'>(({ isActive, model, children, ...rest }, ref) => (
    <Flex
        as="button"
        overflow="hidden"
        layerStyle="defaultBorder"
        borderRadius="8"
        align="stretch"
        textAlign="left"
        ref={ref}
        disabled={isActive}
        {...rest}
    >
        <Thumb
            src={model.thumb_120x120}
            layerStyle=""
            size="100%"
            h="auto"
            maxW="56px"
            borderRight="var(--chakra-colors-neutral-subtle) var(--chakra-sizes-border) solid"
        />
        <Box
            px={4}
            py={2}
            bg={isActive ? 'neutral.subtle' : undefined}
            color={isActive ? 'secondary.subtle' : undefined}
        >
            <Text textStyle="button-pad-sm" wordBreak="break-all">
                {model.title}
            </Text>
            <ModelVolume textStyle="typography-xs" model={model} mt={1} />
            <ModelPerimeter
                textStyle="typography-xs"
                perimeter={model.perimeter}
                units={model.units}
            />
            <ModelSizes textStyle="typography-xs" size={model.size} units={model.units} />
            {children}
        </Box>
    </Flex>
));
