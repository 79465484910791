import { ReactNode } from 'react';
import { useDisclosure } from '@chakra-ui/react';
import { AlertDialog } from '@shared/components/alert-dialog';
import { FieldControl, Select } from '@shared/components/forms-v2';
import { getNodeIdComposer } from '@shared/utils/strings';
import { useMachineSampleCreate } from './hooks';

interface Props {
    rootId: string;
    trigger: (props: { id: string; onOpen: () => void; isDisabled: boolean }) => ReactNode;
}

export const MachineSampleCreateDialog = ({ rootId, trigger }: Props) => {
    const _id = getNodeIdComposer(rootId, 'create_machine_sample_dialog');

    const { update, isLoading, technology, setTechnology, technologies } = useMachineSampleCreate();
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            {trigger({ onOpen, id: _id('trigger'), isDisabled: !technologies.length })}
            <AlertDialog
                alertId={_id()}
                isOpen={isOpen}
                onCancel={onClose}
                onConfirm={() => {
                    if (!technology) return;
                    update(parseInt(technology.value)).then(onClose);
                }}
                isLoading={isLoading}
                header="New machine"
                body={
                    <FieldControl label="Manufacturing technology">
                        <Select
                            value={technology}
                            options={technologies}
                            // @ts-ignore
                            onChange={setTechnology}
                        />
                    </FieldControl>
                }
                cancelButtonText="Cancel"
                confirmButtonText="Create"
            />
        </>
    );
};
