import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        systemMessagesList: build.query<SystemMessagesListApiResponse, SystemMessagesListApiArg>({
            query: queryArg => ({
                url: `/v2/system_messages/`,
                params: {
                    error_code: queryArg.errorCode,
                    limit: queryArg.limit,
                    logging_level: queryArg.loggingLevel,
                    offset: queryArg.offset,
                    resolved: queryArg.resolved,
                    search: queryArg.search,
                    service: queryArg.service,
                },
            }),
        }),
        systemMessagesCreate: build.mutation<
            SystemMessagesCreateApiResponse,
            SystemMessagesCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/system_messages/`,
                method: 'POST',
                body: queryArg.systemMessageCreateRequest,
            }),
        }),
        systemMessagesRetrieve: build.query<
            SystemMessagesRetrieveApiResponse,
            SystemMessagesRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/system_messages/${queryArg.id}/` }),
        }),
        systemMessagesPartialUpdate: build.mutation<
            SystemMessagesPartialUpdateApiResponse,
            SystemMessagesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/system_messages/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedSystemMessageUpdateRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type SystemMessagesListApiResponse = /** status 200  */ PaginatedSystemMessageList;
export type SystemMessagesListApiArg = {
    errorCode?: string;
    /** Number of results to return per page. */
    limit?: number;
    /** * `info` - INFO
     * `warning` - WARNING
     * `error` - ERROR */
    loggingLevel?: 'error' | 'info' | 'warning';
    /** The initial index from which to return the results. */
    offset?: number;
    resolved?: boolean;
    /** A search term. */
    search?: string;
    /** * `stripe` - Stripe
     * `paypal` - PayPal
     * `easypost` - EasyPost
     * `hubspot` - HubSpot
     * `quickbooks_online` - QuickBooks Online
     * `salesforce` - SalesForce
     * `sharepoint` - SharePoint
     * `trello` - Trello
     * `xero` - Xero
     * `zoho` - Zoho
     * `webhooks` - Webhooks */
    service?:
        | 'easypost'
        | 'hubspot'
        | 'paypal'
        | 'quickbooks_online'
        | 'salesforce'
        | 'sharepoint'
        | 'stripe'
        | 'trello'
        | 'webhooks'
        | 'xero'
        | 'zoho';
};
export type SystemMessagesCreateApiResponse = /** status 201  */ SystemMessage;
export type SystemMessagesCreateApiArg = {
    systemMessageCreateRequest: SystemMessageCreateRequest;
};
export type SystemMessagesRetrieveApiResponse = /** status 200  */ SystemMessage;
export type SystemMessagesRetrieveApiArg = {
    id: number;
};
export type SystemMessagesPartialUpdateApiResponse = /** status 200  */ SystemMessage;
export type SystemMessagesPartialUpdateApiArg = {
    id: number;
    patchedSystemMessageUpdateRequest: PatchedSystemMessageUpdateRequest;
};
export type ServiceEnum =
    | 'stripe'
    | 'paypal'
    | 'easypost'
    | 'hubspot'
    | 'quickbooks_online'
    | 'salesforce'
    | 'sharepoint'
    | 'trello'
    | 'xero'
    | 'zoho'
    | 'webhooks';
export type LoggingLevelEnum = 'info' | 'warning' | 'error';
export type SystemMessage = {
    id: number;
    service: ServiceEnum;
    logging_level?: LoggingLevelEnum;
    error_code?: string | null;
    message?: string | null;
    resolved?: boolean;
    created_at: string;
    updated_at: string;
};
export type PaginatedSystemMessageList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: SystemMessage[];
};
export type SystemMessageCreateRequest = {
    service: ServiceEnum;
    logging_level?: LoggingLevelEnum;
    error_code?: string;
    message?: string;
};
export type PatchedSystemMessageUpdateRequest = {
    resolved?: boolean;
};
export const {
    useSystemMessagesListQuery,
    useSystemMessagesCreateMutation,
    useSystemMessagesRetrieveQuery,
    useSystemMessagesPartialUpdateMutation,
} = injectedRtkApi;
