import { Icon, IconProps } from '@chakra-ui/react';

export const SearchIcon = (props: IconProps) => (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path
            d="M5.5 9.5C7.70914 9.5 9.5 7.70914 9.5 5.5C9.5 3.29086 7.70914 1.5 5.5 1.5C3.29086 1.5 1.5 3.29086 1.5 5.5C1.5 7.70914 3.29086 9.5 5.5 9.5Z"
            stroke="#7F91A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />

        <path
            d="M10.5 10.5L8.32495 8.325"
            stroke="#7F91A2"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
