import map from 'lodash/map';
import { UseControllerReturn, FieldValues } from 'react-hook-form';

export const getFieldState = <TFieldValues extends FieldValues = FieldValues>({
    fieldState: { invalid, isTouched, isDirty, error },
    formState: { isSubmitted },
}: UseControllerReturn<TFieldValues>) => {
    const isInvalid = (isTouched || isSubmitted) && (invalid || Boolean(error));
    return {
        errorText: Array.isArray(error)
            ? map(error.filter(Boolean), 'message').join(', ')
            : error?.message,
        isInvalid,
    };
};
