import { isValidElement } from 'react';
import type { FunctionComponentElement, ReactElement, ReactNode } from 'react';

export const hasChildren = (
    element: ReactNode,
): element is ReactElement<{ children: ReactNode | ReactNode[] }> =>
    isValidElement<{ children?: ReactNode[] }>(element) && Boolean(element.props.children);

export function isClassComponent(component: any) {
    return typeof component === 'function' && !!component.prototype.isReactComponent;
}

export function isFunctionComponent(component: any) {
    return (
        typeof component === 'function' && String(component).includes('return React.createElement')
    );
}

export function isReactComponent(component: any) {
    return isClassComponent(component) || isFunctionComponent(component);
}

export function isElement(element: any) {
    return isValidElement(element);
}

export function isDOMTypeElement(element: any): element is ReactElement {
    return isElement(element) && typeof element.type === 'string';
}

export function isCompositeTypeElement(element: any): element is FunctionComponentElement<any> {
    return isElement(element) && typeof element.type === 'function';
}

export function isIconElement(element: any): element is FunctionComponentElement<any> {
    return isCompositeTypeElement(element) && element.type.name.toLowerCase().includes('icon');
}
