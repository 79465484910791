import {
    Drawer,
    DrawerProps,
    // DrawerBody,
    // DrawerFooter,
    // DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
} from '@chakra-ui/react';
import { SideNavigation } from './side-navigation';
import { OnItemSelect } from './types';

interface Props extends Omit<DrawerProps, 'children'> {
    onItemSelect?: OnItemSelect;
}

export const SideNavigationDrawer = ({ onItemSelect, ...rest }: Props) => {
    return (
        <Drawer placement="left" {...rest}>
            <DrawerOverlay />
            <DrawerContent>
                <SideNavigation w="full" onItemSelect={onItemSelect} />
                <DrawerCloseButton color="secondary.default" top={3} />
                {/*<DrawerHeader>Create your account</DrawerHeader>*/}
                {/*<DrawerBody></DrawerBody>*/}
                {/*<DrawerFooter></DrawerFooter>*/}
            </DrawerContent>
        </Drawer>
    );
};
