import isNil from 'lodash/isNil';
import pick from 'lodash/pick';
import {
    FEATURE_TYPE_LABELS,
    SHAPE_TYPE_LABELS,
    TOPOLOGY_TYPE_LABELS,
} from '@services/df/codegen-enums-labels';
import { TopologyType } from '@services/df/codegen-enums';
import { FeatureTypeRequest } from '@services/df/price-tweaker';
import { isHoleModelDfmFeature, ModelDfmFeature } from './types';

export const getFeatureBlindDisplay = (through: string | boolean) => {
    switch (through) {
        case '': {
            return 'allow both';
        }
        case 'through':
        case true: {
            return 'not blind';
        }
        case 'not_through':
        case false: {
            return 'blind';
        }
    }
};

export const getModelFeatureLabel = (props: ModelDfmFeature) => {
    const { through, ratio, radius, angle, topology_type } = props;

    const parts = [
        TOPOLOGY_TYPE_LABELS[topology_type],
        // isHoleModelDfmFeature(props) ? FEATURE_TYPE_LABELS[props.feature_type].toLowerCase() : '',
        isHoleModelDfmFeature(props) ? SHAPE_TYPE_LABELS[props.shape_type].toLowerCase() : '',
        getFeatureBlindDisplay(through),
        isNil(angle) ? '' : `Angle:\xa0${angle}°`,
        isNil(radius) ? '' : `Radius:\xa0${radius}`,
        isNil(ratio) ? '' : `Ratio:\xa0${ratio}:1`,
    ].filter(Boolean);

    return parts.join(', ');
};

export const getMachineFeatureLabel = ({
    name: _name,
    topology_type = '',
    feature_type,
    shape_type,
    through,
}: FeatureTypeRequest) => {
    const isHole = topology_type === TopologyType.Hole;
    const tt =
        topology_type && TOPOLOGY_TYPE_LABELS[topology_type as keyof typeof TOPOLOGY_TYPE_LABELS];
    const name = isHole ? _name : '';
    const parts = [
        name,
        name ? tt.toLowerCase() : tt,
        // isHole
        //     ? FEATURE_TYPE_LABELS[feature_type as keyof typeof FEATURE_TYPE_LABELS].toLowerCase()
        //     : '',
        isHole &&
            shape_type &&
            SHAPE_TYPE_LABELS[shape_type as keyof typeof SHAPE_TYPE_LABELS].toLowerCase(),
        through ? getFeatureBlindDisplay(through) : '',
    ].filter(Boolean);

    return parts.join(', ');
};

export const getMachineFeatureDetails = ({ max_ratio, radius, angle }: FeatureTypeRequest) => {
    const parts = [`<=\xa0${angle}°`, `R\xa0>=\xa0${radius}`, `<=\xa0${max_ratio}:1`].filter(
        Boolean,
    );

    return parts.join(', ');
};

export const getMachineFeatureFullLabel = (feature: FeatureTypeRequest) => {
    const parts = [getMachineFeatureLabel(feature), getMachineFeatureDetails(feature)].filter(
        Boolean,
    );

    return parts.join(', ');
};

interface FilterShapeTypeLabelsProps {
    shapeTypeLabels: typeof SHAPE_TYPE_LABELS;
    is2DTechnology?: boolean;
}

export const filterShapeTypeLabels = ({
    shapeTypeLabels,
    is2DTechnology,
}: FilterShapeTypeLabelsProps) => {
    if (!is2DTechnology) return shapeTypeLabels;
    return pick(shapeTypeLabels, ['', 'simple']);
};
