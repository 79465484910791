import { ReactNode } from 'react';
import FocusLock from 'react-focus-lock';
import {
    HStack,
    IconButton,
    IconButtonProps,
    Popover,
    PopoverProps,
    PopoverTrigger,
    PopoverContent,
    PopoverContentProps,
    PopoverBody,
    PopoverCloseButton,
} from '@chakra-ui/react';
import { CheckIcon } from '@shared/components/icons';
import { FCC } from '@shared/types';

const checkBg = {
    content: '" "',
    position: 'absolute',
    display: 'block',
    height: 'full',
    width: 'full',
    backgroundColor: 'white',
    borderRadius: '100%',
    zIndex: 'hide',
};

interface Props extends PopoverProps {
    triggerNode: ReactNode;
    // triggerNode: PopoverProps['children'];
    checkmark: Partial<IconButtonProps>;
    popoverContentProps?: PopoverContentProps;
}

export const CheckmarkPopover: FCC<Props> = ({
    triggerNode,
    checkmark,
    children,
    popoverContentProps,
    ...props
}) => {
    return (
        <Popover placement="bottom-start" isLazy {...props}>
            <PopoverTrigger>{triggerNode}</PopoverTrigger>
            <PopoverContent
                bg="neutral.subtlest"
                width="auto"
                maxW="190px"
                {...popoverContentProps}
            >
                <FocusLock returnFocus persistentFocus={false}>
                    <PopoverBody px={2} pt={1} pb={1}>
                        <HStack spacing="1" alignItems="flex-start">
                            {children}
                            <HStack spacing="0.5" h={6}>
                                <IconButton
                                    size="sm"
                                    variant="link"
                                    aria-label="Confirm"
                                    icon={<CheckIcon />}
                                    _before={checkBg}
                                    {...checkmark}
                                />
                                <PopoverCloseButton
                                    position="relative"
                                    p={0}
                                    w={3}
                                    h={3}
                                    top={0}
                                    right={0}
                                    fontSize="9px"
                                />
                            </HStack>
                        </HStack>
                    </PopoverBody>
                </FocusLock>
            </PopoverContent>
        </Popover>
    );
};
