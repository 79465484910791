import React from 'react';
import { useController } from 'react-hook-form';
import { ClassicCKEditor, ClassicCKEditorProps } from '@shared/components/ckeditor';
import { FieldControl } from '../common';
import { ControllerFieldProps } from '../types';
import { getFieldState } from '../helpers';

export type CKEditorFieldProps = Pick<ClassicCKEditorProps, 'config'> & ControllerFieldProps;

export const CKEditorField = ({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    tooltip,
    helpText,
    helpTextAsTooltip,
    errorText: _,

    formControlProps,
    labelProps,
    errorProps,
    helpTextProps,
    tooltipProps,
    ...input
}: CKEditorFieldProps) => {
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const { field } = state;
    return (
        <FieldControl
            label={label}
            labelProps={labelProps}
            tooltip={tooltip}
            tooltipProps={tooltipProps}
            helpText={helpText}
            helpTextAsTooltip={helpTextAsTooltip}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            {...formControlProps}
        >
            <ClassicCKEditor
                value={field.value}
                onChange={(event, editor) => {
                    const data = editor.getData();
                    field.onChange(data);
                    // console.log({ event, editor, data });
                }}
                {...input}
            />
        </FieldControl>
    );
};
