import { Feature } from '@services/df/machines-materials';
import { FeatureType, ShapeType } from '@services/df/codegen-enums';

interface ModelDfmFeatureBase {
    hash_id: string;
    through: boolean;
    angle?: number;
    radius?: number;
    ratio?: number;
    diameter?: number;
}

export interface SlotModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'slot';
    height: number;
    width: number;
    depth: number;
    rounded: boolean;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export interface PocketModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'pocket';
    depth: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
    vertical_convex_fillets: number[];
    vertical_concave_fillets: number[];
}

export interface NotchModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'notch';
    height: number;
    width: number;
    depth: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export interface HoleModelDfmFeature extends ModelDfmFeatureBase {
    topology_type: 'hole';
    feature_type: FeatureType;
    shape_type: ShapeType;
    diam01: number;
    diam02: number;
    overall_height: number;
    straight_height: number;
    angle_end_vs_center_line: number;
    direction: {
        x: number;
        y: number;
        z: number;
    };
}

export type ModelDfmFeature =
    | SlotModelDfmFeature
    | PocketModelDfmFeature
    | NotchModelDfmFeature
    | HoleModelDfmFeature;

// https://digifabster.slack.com/archives/C1ENJ3LCW/p1720190696336459?thread_ts=1720178761.378319&cid=C1ENJ3LCW
export interface DfmFeature {
    type: string;
    properties: ModelDfmFeature;
    suitable_dfm_features_ids: string[];
    scoped_feature: null | Feature; // suitable machine feature
    errors: { code: string; message: string }[];
}

export function isHoleModelDfmFeature(obj: ModelDfmFeature): obj is HoleModelDfmFeature {
    return obj.topology_type === 'hole';
}
