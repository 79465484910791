import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        adminTechnologiesRetrieve: build.query<
            AdminTechnologiesRetrieveApiResponse,
            AdminTechnologiesRetrieveApiArg
        >({
            query: () => ({ url: `/v2/admin/technologies/` }),
        }),
        machinesComplexityBracketingList: build.query<
            MachinesComplexityBracketingListApiResponse,
            MachinesComplexityBracketingListApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/complexity_bracketing/`,
            }),
        }),
        machinesComplexityBracketingPartialUpdate: build.mutation<
            MachinesComplexityBracketingPartialUpdateApiResponse,
            MachinesComplexityBracketingPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/complexity_bracketing/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedComplexityBracketingRequest,
            }),
        }),
        machinesFeaturesList: build.query<
            MachinesFeaturesListApiResponse,
            MachinesFeaturesListApiArg
        >({
            query: queryArg => ({ url: `/v2/machines/${queryArg.machineId}/features/` }),
        }),
        machinesFeaturesUpdate: build.mutation<
            MachinesFeaturesUpdateApiResponse,
            MachinesFeaturesUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/features/`,
                method: 'PUT',
                body: queryArg.featureRequest,
            }),
        }),
        machinesFeaturesRetrieve: build.query<
            MachinesFeaturesRetrieveApiResponse,
            MachinesFeaturesRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/features/${queryArg.id}/`,
            }),
        }),
        machinesFeaturesPartialUpdate: build.mutation<
            MachinesFeaturesPartialUpdateApiResponse,
            MachinesFeaturesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/features/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedFeatureRequest,
            }),
        }),
        machinesFeaturesDestroy: build.mutation<
            MachinesFeaturesDestroyApiResponse,
            MachinesFeaturesDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/features/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        machinesPrioritiesList: build.query<
            MachinesPrioritiesListApiResponse,
            MachinesPrioritiesListApiArg
        >({
            query: queryArg => ({ url: `/v2/machines/${queryArg.machineId}/priorities/` }),
        }),
        machinesPrioritiesUpdate: build.mutation<
            MachinesPrioritiesUpdateApiResponse,
            MachinesPrioritiesUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/priorities/`,
                method: 'PUT',
                body: queryArg.priorityRequest,
            }),
        }),
        machinesPrioritiesRetrieve: build.query<
            MachinesPrioritiesRetrieveApiResponse,
            MachinesPrioritiesRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/priorities/${queryArg.id}/`,
            }),
        }),
        machinesPrioritiesPartialUpdate: build.mutation<
            MachinesPrioritiesPartialUpdateApiResponse,
            MachinesPrioritiesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/priorities/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedPriorityRequest,
            }),
        }),
        machinesPrioritiesDestroy: build.mutation<
            MachinesPrioritiesDestroyApiResponse,
            MachinesPrioritiesDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/priorities/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        machinesTolerancesList: build.query<
            MachinesTolerancesListApiResponse,
            MachinesTolerancesListApiArg
        >({
            query: queryArg => ({ url: `/v2/machines/${queryArg.machineId}/tolerances/` }),
        }),
        machinesTolerancesUpdate: build.mutation<
            MachinesTolerancesUpdateApiResponse,
            MachinesTolerancesUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/tolerances/`,
                method: 'PUT',
                body: queryArg.toleranceRequest,
            }),
        }),
        machinesTolerancesRetrieve: build.query<
            MachinesTolerancesRetrieveApiResponse,
            MachinesTolerancesRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/tolerances/${queryArg.id}/`,
            }),
        }),
        machinesTolerancesPartialUpdate: build.mutation<
            MachinesTolerancesPartialUpdateApiResponse,
            MachinesTolerancesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/tolerances/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedToleranceRequest,
            }),
        }),
        machinesTolerancesDestroy: build.mutation<
            MachinesTolerancesDestroyApiResponse,
            MachinesTolerancesDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/${queryArg.machineId}/tolerances/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        machinesPresetsList: build.query<MachinesPresetsListApiResponse, MachinesPresetsListApiArg>(
            {
                query: queryArg => ({
                    url: `/v2/machines/presets/`,
                    params: { technology_id: queryArg.technologyId },
                }),
            },
        ),
        machinesPresetsRetrieve: build.query<
            MachinesPresetsRetrieveApiResponse,
            MachinesPresetsRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/machines/presets/${queryArg.id}/` }),
        }),
        machinesSamplesCreate: build.mutation<
            MachinesSamplesCreateApiResponse,
            MachinesSamplesCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines/samples/`,
                method: 'POST',
                body: queryArg.copySampleMachineRequestRequest,
            }),
        }),
        machinesMaterialsList: build.query<
            MachinesMaterialsListApiResponse,
            MachinesMaterialsListApiArg
        >({
            query: queryArg => ({
                url: `/v2/machines_materials/`,
                params: { active: queryArg.active },
            }),
        }),
        materialsExecutionsList: build.query<
            MaterialsExecutionsListApiResponse,
            MaterialsExecutionsListApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${queryArg.materialId}/executions/`,
                params: { execution_type: queryArg.executionType },
            }),
        }),
        materialsExecutionsUpdate: build.mutation<
            MaterialsExecutionsUpdateApiResponse,
            MaterialsExecutionsUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${queryArg.materialId}/executions/`,
                method: 'PUT',
                body: queryArg.executionRequest,
            }),
        }),
        materialsExecutionsRetrieve: build.query<
            MaterialsExecutionsRetrieveApiResponse,
            MaterialsExecutionsRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${queryArg.materialId}/executions/${queryArg.id}/`,
            }),
        }),
        materialsExecutionsPartialUpdate: build.mutation<
            MaterialsExecutionsPartialUpdateApiResponse,
            MaterialsExecutionsPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${queryArg.materialId}/executions/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedExecutionRequest,
            }),
        }),
        materialsExecutionsDestroy: build.mutation<
            MaterialsExecutionsDestroyApiResponse,
            MaterialsExecutionsDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/${queryArg.materialId}/executions/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        materialsPresetsList: build.query<
            MaterialsPresetsListApiResponse,
            MaterialsPresetsListApiArg
        >({
            query: queryArg => ({
                url: `/v2/materials/presets/`,
                params: { technology_id: queryArg.technologyId },
            }),
        }),
        materialsPresetsRetrieve: build.query<
            MaterialsPresetsRetrieveApiResponse,
            MaterialsPresetsRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/materials/presets/${queryArg.id}/` }),
        }),
        priceDfmFeaturesCreate: build.mutation<
            PriceDfmFeaturesCreateApiResponse,
            PriceDfmFeaturesCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/price/dfm_features/`,
                method: 'POST',
                body: queryArg.dfmFeaturesPriceRequestRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AdminTechnologiesRetrieveApiResponse = /** status 200  */ AdminTechnologies;
export type AdminTechnologiesRetrieveApiArg = void;
export type MachinesComplexityBracketingListApiResponse = /** status 200  */ ComplexityBracketing[];
export type MachinesComplexityBracketingListApiArg = {
    machineId: number;
};
export type MachinesComplexityBracketingPartialUpdateApiResponse =
    /** status 200  */ ComplexityBracketing;
export type MachinesComplexityBracketingPartialUpdateApiArg = {
    id: number;
    machineId: number;
    patchedComplexityBracketingRequest: PatchedComplexityBracketingRequest;
};
export type MachinesFeaturesListApiResponse = /** status 200  */ Feature[];
export type MachinesFeaturesListApiArg = {
    machineId: number;
};
export type MachinesFeaturesUpdateApiResponse = /** status 200  */ Feature;
export type MachinesFeaturesUpdateApiArg = {
    machineId: number;
    featureRequest: FeatureRequest;
};
export type MachinesFeaturesRetrieveApiResponse = /** status 200  */ Feature;
export type MachinesFeaturesRetrieveApiArg = {
    id: string;
    machineId: number;
};
export type MachinesFeaturesPartialUpdateApiResponse = /** status 200  */ Feature;
export type MachinesFeaturesPartialUpdateApiArg = {
    id: string;
    machineId: number;
    patchedFeatureRequest: PatchedFeatureRequest;
};
export type MachinesFeaturesDestroyApiResponse = unknown;
export type MachinesFeaturesDestroyApiArg = {
    id: string;
    machineId: number;
};
export type MachinesPrioritiesListApiResponse = /** status 200  */ Priority[];
export type MachinesPrioritiesListApiArg = {
    machineId: number;
};
export type MachinesPrioritiesUpdateApiResponse = /** status 200  */ Priority;
export type MachinesPrioritiesUpdateApiArg = {
    machineId: number;
    priorityRequest: PriorityRequest;
};
export type MachinesPrioritiesRetrieveApiResponse = /** status 200  */ Priority;
export type MachinesPrioritiesRetrieveApiArg = {
    id: string;
    machineId: number;
};
export type MachinesPrioritiesPartialUpdateApiResponse = /** status 200  */ Priority;
export type MachinesPrioritiesPartialUpdateApiArg = {
    id: string;
    machineId: number;
    patchedPriorityRequest: PatchedPriorityRequest;
};
export type MachinesPrioritiesDestroyApiResponse = unknown;
export type MachinesPrioritiesDestroyApiArg = {
    id: string;
    machineId: number;
};
export type MachinesTolerancesListApiResponse = /** status 200  */ Tolerance[];
export type MachinesTolerancesListApiArg = {
    machineId: number;
};
export type MachinesTolerancesUpdateApiResponse = /** status 200  */ Tolerance;
export type MachinesTolerancesUpdateApiArg = {
    machineId: number;
    toleranceRequest: ToleranceRequest;
};
export type MachinesTolerancesRetrieveApiResponse = /** status 200  */ Tolerance;
export type MachinesTolerancesRetrieveApiArg = {
    id: string;
    machineId: number;
};
export type MachinesTolerancesPartialUpdateApiResponse = /** status 200  */ Tolerance;
export type MachinesTolerancesPartialUpdateApiArg = {
    id: string;
    machineId: number;
    patchedToleranceRequest: PatchedToleranceRequest;
};
export type MachinesTolerancesDestroyApiResponse = unknown;
export type MachinesTolerancesDestroyApiArg = {
    id: string;
    machineId: number;
};
export type MachinesPresetsListApiResponse = /** status 200  */ PrinterPreset[];
export type MachinesPresetsListApiArg = {
    technologyId?: number;
};
export type MachinesPresetsRetrieveApiResponse = /** status 200  */ PrinterPreset;
export type MachinesPresetsRetrieveApiArg = {
    id: number;
};
export type MachinesSamplesCreateApiResponse = /** status 201  */ MachineGroup;
export type MachinesSamplesCreateApiArg = {
    copySampleMachineRequestRequest: CopySampleMachineRequestRequest;
};
export type MachinesMaterialsListApiResponse = /** status 200  */ MachineGroup[];
export type MachinesMaterialsListApiArg = {
    active?: boolean;
};
export type MaterialsExecutionsListApiResponse = /** status 200  */ Execution[];
export type MaterialsExecutionsListApiArg = {
    /** * `layer_thickness` - Layer thickness
     * `filling` - Filling
     * `color` - Color */
    executionType?: 'color' | 'filling' | 'layer_thickness';
    materialId: number;
};
export type MaterialsExecutionsUpdateApiResponse = /** status 200  */ Execution;
export type MaterialsExecutionsUpdateApiArg = {
    materialId: number;
    executionRequest: ExecutionRequest;
};
export type MaterialsExecutionsRetrieveApiResponse = /** status 200  */ Execution;
export type MaterialsExecutionsRetrieveApiArg = {
    id: string;
    materialId: number;
};
export type MaterialsExecutionsPartialUpdateApiResponse = /** status 200  */ Execution;
export type MaterialsExecutionsPartialUpdateApiArg = {
    id: string;
    materialId: number;
    patchedExecutionRequest: PatchedExecutionRequest;
};
export type MaterialsExecutionsDestroyApiResponse = unknown;
export type MaterialsExecutionsDestroyApiArg = {
    id: string;
    materialId: number;
};
export type MaterialsPresetsListApiResponse = /** status 200  */ MaterialPreset[];
export type MaterialsPresetsListApiArg = {
    technologyId?: number | null;
};
export type MaterialsPresetsRetrieveApiResponse = /** status 200  */ MaterialPreset;
export type MaterialsPresetsRetrieveApiArg = {
    id: number;
};
export type PriceDfmFeaturesCreateApiResponse = /** status 200  */ DfmFeaturesPriceResponse;
export type PriceDfmFeaturesCreateApiArg = {
    dfmFeaturesPriceRequestRequest: DfmFeaturesPriceRequestRequest;
};
export type PrintingTechnologyAdmin = {
    id: number;
    tech_id: number;
    title: string;
    slug: string;
    image: string;
    note: string;
    sorting_order_number: number;
    is_cnc: boolean;
};
export type CustomTechnologyAdmin = {
    id: number;
    tech_id: number;
    title?: string | null;
    image: string;
    note?: string | null;
    sorting_order_number?: number | null;
};
export type AdminTechnologies = {
    printing_tech: PrintingTechnologyAdmin[];
    custom_tech: CustomTechnologyAdmin[];
};
export type ComplexityBracketing = {
    id: number;
    first_multiplier?: number;
    second_multiplier?: number;
    third_multiplier?: number;
    fourth_multiplier?: number;
    fifth_multiplier?: number;
    first_boundary?: number | null;
    second_boundary?: number | null;
    third_boundary?: number | null;
    fourth_boundary?: number | null;
};
export type PatchedComplexityBracketingRequest = {
    first_multiplier?: number;
    second_multiplier?: number;
    third_multiplier?: number;
    fourth_multiplier?: number;
    fifth_multiplier?: number;
    first_boundary?: number | null;
    second_boundary?: number | null;
    third_boundary?: number | null;
    fourth_boundary?: number | null;
};
export type FeatureTypeEnum = 'cuboids' | 'axial' | 'radial' | 'single_step_reduction';
export type BlankEnum = '';
export type ShapeTypeEnum = 'simple' | 'countersunk' | 'counterdrilled' | 'counterbored';
export type ThroughEnum = 'through' | 'not_through';
export type TopologyTypeEnum = 'hole' | 'slot' | 'pocket' | 'notch' | 'reduction';
export type Feature = {
    id: string;
    name: string;
    price_set_up?: number;
    price_per_feature?: number;
    feature_type?: FeatureTypeEnum | BlankEnum;
    shape_type?: ShapeTypeEnum | BlankEnum;
    through?: ThroughEnum | BlankEnum;
    min_ratio?: number;
    max_ratio?: number;
    is_active?: boolean;
    time_per_feature?: number;
    time_of_programming_recurring?: number;
    time_of_programming_non_recurring?: number;
    radius?: number;
    angle?: number;
    topology_type?: TopologyTypeEnum | BlankEnum;
};
export type FeatureRequest = {
    name: string;
    price_set_up?: number;
    price_per_feature?: number;
    feature_type?: FeatureTypeEnum | BlankEnum;
    shape_type?: ShapeTypeEnum | BlankEnum;
    through?: ThroughEnum | BlankEnum;
    min_ratio?: number;
    max_ratio?: number;
    is_active?: boolean;
    time_per_feature?: number;
    time_of_programming_recurring?: number;
    time_of_programming_non_recurring?: number;
    radius?: number;
    angle?: number;
    topology_type?: TopologyTypeEnum | BlankEnum;
};
export type PatchedFeatureRequest = {
    name?: string;
    price_set_up?: number;
    price_per_feature?: number;
    feature_type?: FeatureTypeEnum | BlankEnum;
    shape_type?: ShapeTypeEnum | BlankEnum;
    through?: ThroughEnum | BlankEnum;
    min_ratio?: number;
    max_ratio?: number;
    is_active?: boolean;
    time_per_feature?: number;
    time_of_programming_recurring?: number;
    time_of_programming_non_recurring?: number;
    radius?: number;
    angle?: number;
    topology_type?: TopologyTypeEnum | BlankEnum;
};
export type Priority = {
    id: string;
    days?: number;
    factor?: number;
    name_for_user?: string;
    note_for_user?: string;
    sort_order?: number;
    tbd_quantity_threshold?: number;
    display_name: string;
};
export type PriorityRequest = {
    days?: number;
    factor?: number;
    name_for_user?: string;
    note_for_user?: string;
    sort_order?: number;
    tbd_quantity_threshold?: number;
};
export type PatchedPriorityRequest = {
    days?: number;
    factor?: number;
    name_for_user?: string;
    note_for_user?: string;
    sort_order?: number;
    tbd_quantity_threshold?: number;
};
export type Tolerance = {
    id: string;
    deviation_from?: number;
    deviation_to?: number;
    margin?: number;
    name_for_user?: string;
    sort_order?: number;
    display_name?: string;
};
export type ToleranceRequest = {
    deviation_from?: number;
    deviation_to?: number;
    margin?: number;
    name_for_user?: string;
    sort_order?: number;
    display_name?: string;
};
export type PatchedToleranceRequest = {
    deviation_from?: number;
    deviation_to?: number;
    margin?: number;
    name_for_user?: string;
    sort_order?: number;
    display_name?: string;
};
export type PrinterPreset = {
    id: number;
    title: string;
    technology: number;
    active?: boolean;
    size_x?: number;
    size_y?: number;
    size_z?: number;
    min_size_x?: number;
    min_size_y?: number;
    min_size_z?: number;
    min_size_x_threshold?: number | null;
    min_size_y_threshold?: number | null;
    min_size_z_threshold?: number | null;
    suitable_materials?: number[];
    nominal_layer_thickness?: number | null;
    nominal_growth_rate?: number | null;
    horizontal_printing_speed?: number | null;
    price_per_hour: string;
    price_of_area_surface: string;
    price_of_volume_bounding_box: string;
    price_of_model_height: string;
    price_of_area_bounding_box: string;
    machine_speed?: number | null;
    finishing_speed?: number | null;
    nominal_sintering_rate?: number | null;
    nominal_melting_rate?: number | null;
};
export type MaterialGroup = {
    id: number;
    title: string;
    active: boolean;
    sorting_priority: number;
};
export type MachineGroup = {
    id: number;
    title: string;
    is_iqt_only?: boolean;
    technology: number;
    custom_tech?: number | null;
    active?: boolean;
    materials: MaterialGroup[];
    suitable_materials: any[];
};
export type CopySampleMachineRequestRequest = {
    technology_id: number;
};
export type ExecutionTypeEnum = 'layer_thickness' | 'filling' | 'color';
export type Color = {
    id: string;
    color: string;
    label: string;
};
export type Execution = {
    id: string;
    option?: number | null;
    execution_type: ExecutionTypeEnum;
    name_for_user?: string;
    note_for_user?: string;
    colors?: Color[];
    sort_order?: number;
};
export type ColorRequest = {
    color: string;
    label: string;
};
export type ExecutionRequest = {
    option?: number | null;
    execution_type: ExecutionTypeEnum;
    name_for_user?: string;
    note_for_user?: string;
    colors?: ColorRequest[];
    sort_order?: number;
};
export type PatchedExecutionRequest = {
    option?: number | null;
    execution_type?: ExecutionTypeEnum;
    name_for_user?: string;
    note_for_user?: string;
    colors?: ColorRequest[];
    sort_order?: number;
};
export type MaterialPreset = {
    id: number;
    title: string;
    technology?: number | null;
    specific_type: string;
    startup_cost?: number;
    spec_sheet_url?: string;
    price: string;
    density?: number | null;
    price_per_gram: string;
    shell_closeness?: number | null;
    minimal_hole?: number | null;
    power_for_volume?: number | null;
    power_for_bb_volume?: number | null;
    machinability?: number | null;
    work_hardening_exponent?: number | null;
    minimal_wall_thickness?: number;
    price_per_hole: string;
};
export type DfmFeatureResponse = {
    type: string;
    properties: {
        [key: string]: any;
    };
    suitable_dfm_features_ids: string[];
    errors: string[];
};
export type DfmFeaturesPriceResponse = {
    model_id: number;
    model_dfm_features: DfmFeatureResponse[];
};
export type DfmFeaturesPriceRequestRequest = {
    model_id: number;
    material_id: number;
    dfm_features?: {
        [key: string]: any;
    }[];
};
export const {
    useAdminTechnologiesRetrieveQuery,
    useMachinesComplexityBracketingListQuery,
    useMachinesComplexityBracketingPartialUpdateMutation,
    useMachinesFeaturesListQuery,
    useMachinesFeaturesUpdateMutation,
    useMachinesFeaturesRetrieveQuery,
    useMachinesFeaturesPartialUpdateMutation,
    useMachinesFeaturesDestroyMutation,
    useMachinesPrioritiesListQuery,
    useMachinesPrioritiesUpdateMutation,
    useMachinesPrioritiesRetrieveQuery,
    useMachinesPrioritiesPartialUpdateMutation,
    useMachinesPrioritiesDestroyMutation,
    useMachinesTolerancesListQuery,
    useMachinesTolerancesUpdateMutation,
    useMachinesTolerancesRetrieveQuery,
    useMachinesTolerancesPartialUpdateMutation,
    useMachinesTolerancesDestroyMutation,
    useMachinesPresetsListQuery,
    useMachinesPresetsRetrieveQuery,
    useMachinesSamplesCreateMutation,
    useMachinesMaterialsListQuery,
    useMaterialsExecutionsListQuery,
    useMaterialsExecutionsUpdateMutation,
    useMaterialsExecutionsRetrieveQuery,
    useMaterialsExecutionsPartialUpdateMutation,
    useMaterialsExecutionsDestroyMutation,
    useMaterialsPresetsListQuery,
    useMaterialsPresetsRetrieveQuery,
    usePriceDfmFeaturesCreateMutation,
} = injectedRtkApi;
