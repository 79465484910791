import { Box, Stack, StackProps } from '@chakra-ui/react';
import { FCC } from '@shared/types';
import {
    FieldConfigs,
    InputConfigs,
    FieldHandler,
    InputHandler,
    isInputConfigs,
} from '@shared/components/forms-v2';
import { SECTION_GRID_STYLES } from './constants';

const fieldPopoverOverridingProps = {
    label: '',
    helpText: '',
};

export type SectionLineItemFieldsProps =
    | ((FieldConfigs | InputConfigs) & {
          group?: never;
          groupProps?: never;
      })
    | {
          group: (FieldConfigs | InputConfigs)[];
          groupProps?: StackProps;
      };

export const SectionLineItemFields: FCC<SectionLineItemFieldsProps> = props => {
    const { group, groupProps, children } = props;

    return group ? (
        <Stack spacing={SECTION_GRID_STYLES.groupRowGap} {...groupProps}>
            {group.map(field =>
                isInputConfigs(field) ? (
                    <InputHandler key={field.name} field={field} />
                ) : (
                    <FieldHandler key={field.name} field={field} />
                ),
            )}

            {children}
        </Stack>
    ) : (
        <Box>
            {isInputConfigs(props) ? (
                <InputHandler field={props} />
            ) : (
                <FieldHandler field={props} overridingProps={fieldPopoverOverridingProps} />
            )}
            {children}
        </Box>
    );
};
