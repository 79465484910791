import isString from 'lodash/isString';
import { PropsWithChildren, ReactNode } from 'react';
import { FieldValues, FormProvider, useFormState } from 'react-hook-form';
import {
    Box,
    BoxProps,
    Button,
    Popover,
    PopoverProps,
    PopoverHeader,
    PopoverBody,
    PopoverFooter,
    PopoverTrigger,
    Portal,
    Text,
} from '@chakra-ui/react';
import { SystemProps } from '@chakra-ui/system';
import {
    FieldStyles,
    useUpdateFormPopup,
    UseUpdateFormPopupProps,
} from '@shared/components/forms-v2';
import { getNodeIdComposer } from '@shared/utils/strings';
import { FormPopoverContent } from './form-popover-content';
import { POPOVER_FIELD_STYLES } from './constants';

export interface FormPopoverProps<TFieldValues extends FieldValues = FieldValues>
    extends UseUpdateFormPopupProps<TFieldValues>,
        PopoverProps {
    triggerNode: ReactNode;
    // triggerNode: PopoverProps['children'];
    formId: string;
    header?: ReactNode;
    buttonText?: string;
    showCloseButton?: boolean;
    shouldCheckDirty?: boolean;
    styles?: FieldStyles;
    isFetching?: boolean;
    onCloseFunction?: () => void;
}

export const FormPopover = <TFieldValues extends FieldValues = FieldValues>({
    triggerNode,
    formId,
    data,
    schema,
    submit,
    children,
    header,
    buttonText = 'save',
    showCloseButton = false,
    shouldCheckDirty = true,
    styles = POPOVER_FIELD_STYLES,
    apiErrorsToastEnabled = true,
    apiErrorsFields,
    apiErrorsMap,
    onError,
    stripUnknown,
    isFetching,
    onCloseFunction,
    ...rest
}: PropsWithChildren<FormPopoverProps<TFieldValues>>) => {
    const _id = getNodeIdComposer(formId, 'popover');
    const { onOpen, onClose, isOpen, handleFormSubmit, ...formApi } =
        useUpdateFormPopup<TFieldValues>({
            data,
            schema,
            submit,
            onError,
            apiErrorsToastEnabled,
            apiErrorsFields,
            apiErrorsMap,
            stripUnknown,
        });
    const { isSubmitting, isDirty } = useFormState({ control: formApi.control });

    const handleClose = () => {
        onCloseFunction?.();
        onClose();
    };

    return (
        <Popover
            placement="bottom-start"
            isLazy
            onOpen={onOpen}
            onClose={handleClose}
            isOpen={isOpen}
            {...rest}
        >
            <PopoverTrigger>{triggerNode}</PopoverTrigger>
            <Portal>
                <FormPopoverContent showCloseButton={showCloseButton} styles={styles}>
                    <FormProvider {...formApi}>
                        {header && (
                            <PopoverHeader>
                                {isString(header) ? (
                                    <Text textStyle="typography-xs" fontWeight={700}>
                                        {header}
                                    </Text>
                                ) : (
                                    header
                                )}
                            </PopoverHeader>
                        )}

                        <PopoverBody>
                            <Box as="form" id={formId} onSubmit={handleFormSubmit}>
                                {children}
                            </Box>
                        </PopoverBody>
                        <PopoverFooter>
                            <Button
                                id={_id('submit_button')}
                                // forced styles to prevent overwriting with ButtonGroup
                                variant="solid"
                                colorScheme="primary"
                                size="sm"
                                isDisabled={shouldCheckDirty && !isDirty}
                                isLoading={isSubmitting || isFetching}
                                loadingText={buttonText}
                                form={formId}
                                type="submit"
                            >
                                {buttonText}
                            </Button>
                        </PopoverFooter>
                    </FormProvider>
                </FormPopoverContent>
            </Portal>
        </Popover>
    );
};

interface FormPopoverLineItemProps extends BoxProps {
    rowGap?: SystemProps['margin'];
}

export const FormPopoverLineItem = ({
    rowGap = 2,
    children,
    ...rest
}: FormPopoverLineItemProps) => {
    return (
        <Box
            // _notFirst={{
            //     pt: rowGap,
            // }}
            sx={{
                '& ~ &': {
                    pt: rowGap,
                },
            }}
            {...rest}
        >
            {children}
        </Box>
    );
};
