import { Button, ButtonProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { EditIcon, PlusCircleIcon, TrashIcon } from '@shared/components/icons';

interface Props extends ButtonProps {}

export const ADD_BUTTON_PROPS = { size: 'sm', variant: 'outline', leftIcon: <PlusCircleIcon /> };

export const AddButton = forwardRef<Props, 'button'>(({ children = 'add more', ...props }, ref) => {
    return (
        <Button ref={ref} {...ADD_BUTTON_PROPS} {...props}>
            <span>{children}</span>
        </Button>
    );
});

export const DELETE_BUTTON_PROPS = {
    size: 'sm',
    variant: 'outline',
    leftIcon: <TrashIcon />,
    colorScheme: 'error',
};

export const DeleteButton = forwardRef<Props, 'button'>(
    ({ children = 'delete', ...props }, ref) => {
        return (
            <Button ref={ref} {...DELETE_BUTTON_PROPS} {...props}>
                <span>{children}</span>
            </Button>
        );
    },
);

export const EDIT_BUTTON_PROPS = {
    size: 'sm',
    variant: 'link',
    colorScheme: 'gray',
    leftIcon: <EditIcon />,
};

export const EditButton = forwardRef<Props, 'button'>(({ children = 'Edit', ...props }, ref) => {
    return (
        <Button ref={ref} {...EDIT_BUTTON_PROPS} {...props}>
            <span>{children}</span>
        </Button>
    );
});

// const editRecordButtonHoverStyles = { textDecoration: 'underline' };
//
// export const EditRecordButton = forwardRef<Props, 'button'>(({ children, ...props }, ref) => {
//     return (
//         <Button
//             ref={ref}
//             variant="link"
//             letterSpacing="button"
//             // textDecoration="underline"
//             _hover={editRecordButtonHoverStyles}
//             {...props}
//         >
//             {children}
//         </Button>
//     );
// });
