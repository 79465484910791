import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import {
    defineField,
    definePercentField,
    FieldType,
    OptionType,
} from '@shared/components/forms-v2';

type Options = {
    orderStatusOptions?: OptionType[];
};

export const getOrganizationConfig = ({ orderStatusOptions }: Options = {}) => {
    const title = defineField({
        type: FieldType.Text,
        name: 'title',
        scheme: yup.string().ensure().trim().required().max(255),
        props: {
            label: 'Name',
            helpText: 'This domain is going to be used to add users to this organization',
        },
    });

    const domain = defineField({
        type: FieldType.Text,
        name: 'domain',
        scheme: yup.string().ensure().trim().required().max(255),
        props: {
            label: 'Company domain',
        },
    });

    const tax_number = defineField({
        type: FieldType.Text,
        name: 'tax_number',
        scheme: yup.string().ensure().trim().max(30),
        props: {
            label: 'Tax number',
        },
    });

    const description = defineField({
        type: FieldType.Text,
        name: 'description',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Description',
        },
    });

    const grace_period_trigger_status = defineField({
        type: FieldType.Select,
        name: 'grace_period_trigger_status',
        scheme: yup.string().ensure().default('placed'),

        props: {
            label: 'Invoice trigger status',
            tooltip:
                'Select order status that will trigger invoice creation and calculate payment due date based on payment terms.',
            allowedEmpty: true,
            emptyValue: '',
            options: orderStatusOptions,
        },
    });

    const grace_period_days = defineField({
        type: FieldType.Number,
        name: 'grace_period_days',
        scheme: yup.number().integer().min(0).default(30),
        props: {
            label: 'Payment terms, days',
            tooltip:
                'Due date will be calculated by adding a number of days to a date when trigger status was reached.',
            min: 0,
            precision: 0,
            rightAddon: 'Days',
        },
    });

    // todo definePercentField
    const custom_tax_rate = defineField({
        type: FieldType.Number,
        name: 'custom_tax_rate',
        scheme: yup.number().min(0).max(100).nullable().default(0),
        props: {
            label: 'Custom tax rate',
            tooltip:
                'Use this field to create a tax exempt or other special tax conditions for this company.',
            rightAddon: '%',
        },
    });

    const vban_bank_transfer_enabled = defineField({
        type: FieldType.Switch,
        name: 'vban_bank_transfer_enabled',
        scheme: yup.boolean().default(true),
        props: {
            label: 'Accept bank transfers',
            size: 'lg',
            formControlProps: {
                w: 'auto',
            },
        },
    });

    const fieldsArray = [
        vban_bank_transfer_enabled,
        title,
        description,
        domain,
        tax_number,
        grace_period_trigger_status,
        grace_period_days,
        custom_tax_rate,
    ];
    const fields = {
        title,
        description,
        domain,
        tax_number,
        grace_period_trigger_status,
        grace_period_days,
        custom_tax_rate,
        vban_bank_transfer_enabled,
    };

    const schema = yup.object(
        mapValues(
            {
                title,
                description,
                domain,
                tax_number,
                grace_period_trigger_status,
                grace_period_days,
                custom_tax_rate,
                vban_bank_transfer_enabled,
            },
            'scheme',
        ),
    );

    return {
        fields,
        fieldsArray,
        schema,
    };
};

export type OrganizationConfig = ReturnType<typeof getOrganizationConfig>;
export type OrganizationSchema = OrganizationConfig['schema'];
export type OrganizationFields = OrganizationConfig['fields'];
export type OrganizationFieldsUnion = keyof OrganizationConfig['fields'];
export type OrganizationFieldsArray = Array<OrganizationFields[OrganizationFieldsUnion]>;

// todo static method of FormHelper.fromFields
export const getOrganizationConfigFromFields = (
    fields: ReadonlyArray<OrganizationFieldsUnion>,
    opts: Options = {},
) => {
    const { fields: _fields, schema } = getOrganizationConfig(opts);

    return {
        fields: fields.map(field => _fields[field]),
        fieldsMap: _fields,
        // @ts-ignore
        schema: schema.pick(fields),
    };
};
