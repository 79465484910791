import type { ReactNode } from 'react';
import { Outlet, ScrollRestoration } from 'react-router-dom';
import { Box, Container, ContainerProps, Flex } from '@chakra-ui/react';
import { useAppSize } from '@shared/hooks';

type Props = {
    headerSlot?: ReactNode;
    footerSlot?: ReactNode;
    sidebarSlot?: ReactNode;
} & ContainerProps;

export function Layout({ headerSlot, sidebarSlot, footerSlot, ...rest }: Props) {
    const { isDesktop } = useAppSize();

    return (
        <Container paddingInlineStart="unset" paddingInlineEnd="unset" maxW="100%" {...rest}>
            {!isDesktop && headerSlot}

            <Flex>
                {sidebarSlot && isDesktop && <Box as="aside">{sidebarSlot}</Box>}

                {/* https://frontendmasters.com/blog/the-css-contain-property/#inline-size-and-style-containments */}
                {/* The contain: inline-size CSS declaration prevents the element's inline dimension (width/h)
                    from being set by the element's contents. */}

                {/*<Box as="main" flex="1 1 0%" overflowX="auto" zIndex={1}>*/}
                <Box as="main" flex="1 1 0%" zIndex={1} sx={{ contain: 'inline-size' }}>
                    <Outlet />
                </Box>
            </Flex>

            {footerSlot}
            <ScrollRestoration />
        </Container>
    );
}
