import { Icon, IconProps } from '@chakra-ui/react';

export const CodeIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M9.30813 3.01672C9.62068 3.09113 9.81073 3.39275 9.73257 3.69041L7.39923 12.5791C7.32112 12.8767 7.00443 13.0577 6.69186 12.9833C6.37931 12.9089 6.18929 12.6073 6.26742 12.3096L8.60078 3.42094C8.67889 3.12328 8.99558 2.9423 9.30813 3.01672ZM4.91248 4.82947C5.14029 5.04642 5.14029 5.39818 4.91248 5.61513L2.40829 8.00001L4.91248 10.3849C5.14029 10.6018 5.14029 10.9536 4.91248 11.1705C4.68468 11.3875 4.31533 11.3875 4.08752 11.1705L1.17085 8.39283C0.943049 8.17589 0.943049 7.82412 1.17085 7.60719L4.08752 4.82947C4.31533 4.61252 4.68468 4.61252 4.91248 4.82947ZM11.0875 4.82947C11.3153 4.61252 11.6847 4.61252 11.9125 4.82947L14.8292 7.60719C15.0569 7.82412 15.0569 8.17589 14.8292 8.39283L11.9125 11.1705C11.6847 11.3875 11.3153 11.3875 11.0875 11.1705C10.8597 10.9536 10.8597 10.6018 11.0875 10.3849L13.5917 8.00001L11.0875 5.61513C10.8597 5.39818 10.8597 5.04642 11.0875 4.82947Z"
            fill="currentColor"
        />
    </Icon>
);
