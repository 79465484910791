import isInteger from 'lodash/isInteger';
import currency, { Options } from 'currency.js';
import configureMeasurements, {
    length,
    LengthSystems,
    LengthUnits,
    area,
    AreaUnits,
    AreaSystems,
    volume,
    VolumeSystems,
    VolumeUnits,
} from 'convert-units';

// Measures: The names of the measures being used
type Measures = 'length' | 'area' | 'volume';
// Systems: The systems being used across all measures
type Systems = LengthSystems | AreaSystems | VolumeSystems;
// Units: All the units across all measures and their systems
type Units = LengthUnits | AreaUnits | VolumeUnits;

/*
  `configureMeasurements` is a closure that accepts a directory
  of measures and returns a factory function (`convert`) that uses
  only those measures.
*/
export const unitsConverter = configureMeasurements<Measures, Systems, Units>({
    length,
    area,
    volume,
});

export type ConvertOptions<U extends Units = Units> = {
    from: U;
    to: U;
};

type ConvertUnits = {
    value: number;
} & Required<ConvertOptions>;

export function convertUnits({ value, from, to }: Required<ConvertUnits>) {
    return unitsConverter(value).from(from).to(to);
}

export function formatUnits(value: string | number = 0, { symbol = '', ...opts }: Options = {}) {
    return currency(value, { symbol, ...opts }).format();
}

export function formatMultiplier(
    value?: string | number | null,
    { precision = 3, ...opts }: Options = {},
) {
    return formatUnits(value || 0, { precision, ...opts });
}

export function formatMinutes(
    value?: string | number | null,
    { separator = ' ', symbol = 'min', pattern = '# !', ...opts }: Options = {},
) {
    const precision = isInteger(value) ? 0 : 2;
    return formatUnits(value || 0, { separator, symbol, pattern, precision, ...opts });
}
