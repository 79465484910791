import { useEffect, useRef, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectNnwUrlComposer } from '@ducks/app';
import { useClientsPasswordRecoveryTokenCreateMutation } from '@services/df/clients';
import { ClipboardInput, FieldControl } from '@shared/components/forms-v2';
import { PreloaderBox } from '@shared/components/preloader';
import { createToast as toast } from '@shared/components/toast';

interface Props {
    clientId?: number;
}

export const useClientResetPasswordLink = ({ clientId }: Props) => {
    const mnwUrlComposer = useAppSelector(selectNnwUrlComposer);

    //***** FetchingResetPasswordToken is needed to avoid sending the mutation request twice *****
    //***** This is happening only in dev mode due to double components loading, but still *****
    const fetchingResetPasswordToken = useRef(false);

    const [link, setLink] = useState('');
    const [getToken, { isLoading }] = useClientsPasswordRecoveryTokenCreateMutation();

    useEffect(() => {
        if (!clientId || fetchingResetPasswordToken.current) return;

        fetchingResetPasswordToken.current = true;

        (async () => {
            try {
                const { token } = await getToken({ id: clientId }).unwrap();
                token && setLink(mnwUrlComposer('client_password_recovery_url', token).toString());
            } catch (error) {
                toast({
                    status: 'error',
                    description: "The reset password link wasn't generate",
                    position: 'top-right',
                });
                console.error('Error: ', error);
            }
        })();
    }, [clientId, mnwUrlComposer, getToken]);

    return {
        isLoading,
        link: link ? link : isLoading ? '' : "Wasn't generated",
    };
};

export function ClientResetPasswordLink(props: Props) {
    const { isLoading, link } = useClientResetPasswordLink(props);

    return (
        <PreloaderBox in={isLoading}>
            <FieldControl label="Reset password link:">
                <ClipboardInput value={link} disabled />
            </FieldControl>
        </PreloaderBox>
    );
}
