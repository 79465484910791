import { ComponentType, useEffect } from 'react';
import { useQueryParams } from 'use-query-params';
import { ButtonProps, StackProps } from '@chakra-ui/react';
import { callAllHandlers } from '@chakra-ui/utils';
import {
    Pagination,
    PaginationPage,
    PaginationPageGroup,
    PaginationContainer,
    PaginationSeparator,
    usePagination,
} from '@ajna/pagination';
import { IconType, SeparatorPosition } from '@ajna/pagination/dist/lib/types';
import { PageProps } from '@ajna/pagination/dist/components/PaginationPage';
import { PageGroupProps } from '@ajna/pagination/dist/components/PaginationPageGroup';
import {
    CurrentPageParam,
    PaginationQueryParams,
    PerPageLimitParam,
    PAGINATION_CONFIG,
} from './constants';

interface PaginationProps {
    pages: number[];
    total: number;
    pageSize: number;
    pagesCount: number;
    currentPage: number;
    pageStyles?: PageProps & ButtonProps;
    pageGroupStyles?: PageGroupProps & StackProps;
    separatorStyles?: {
        hoverIcon: IconType;
        jumpSize: number;
        isDisabled: boolean;
        separatorPosition: SeparatorPosition;
    } & ButtonProps;
    onPageChange: (page: number) => void;
}

interface WithQueryPageProps
    extends Pick<PaginationProps, 'total' | 'pageStyles' | 'pageGroupStyles' | 'separatorStyles'> {
    onPageChange?: (page: number) => void;
    limits?: {
        inner: number;
        outer: number;
    };
}

const withQueryPage = (Component: ComponentType<any>) => {
    const WithQueryPage = ({
        total,
        pageGroupStyles = {
            spacing: 2,
        },
        limits = PAGINATION_CONFIG,
        onPageChange,
        ...paginationProps
    }: WithQueryPageProps) => {
        const [query, setQuery] = useQueryParams({
            [PaginationQueryParams.Limit]: PerPageLimitParam,
            [PaginationQueryParams.PageNumber]: CurrentPageParam,
        });

        const { pages, pagesCount, setCurrentPage, setPageSize, pageSize, currentPage } =
            usePagination({
                total,
                limits,
                initialState: { pageSize: query.limit, currentPage: query.page },
            });

        useEffect(() => {
            setCurrentPage(query.page);
        }, [query.page, setCurrentPage]);

        useEffect(() => {
            setPageSize(query.limit);
        }, [query.limit, setPageSize]);

        const props = {
            pageGroupStyles,
            ...paginationProps,
            total,
            pages,
            pagesCount,
            pageSize,
            currentPage,
            onPageChange: callAllHandlers(onPageChange, (page: number) => {
                setQuery({
                    page,
                });
            }),
        };

        return <Component {...props} />;
    };

    return WithQueryPage;
};

export const BasicPagination = ({
    pages,
    total,
    pageSize,
    pagesCount,
    onPageChange,
    currentPage,
    pageStyles,
    separatorStyles,
    pageGroupStyles,
}: PaginationProps) => {
    if (pageSize >= total) {
        return null;
    }

    return (
        <Pagination pagesCount={pagesCount} currentPage={currentPage} onPageChange={onPageChange}>
            <PaginationContainer>
                <PaginationPageGroup
                    separator={
                        <PaginationSeparator
                            jumpSize={4}
                            minW="8"
                            minH="8"
                            textStyle="typography-sm"
                            fontWeight="400"
                            colorScheme="gray"
                            color="font.100"
                            {...separatorStyles}
                        />
                    }
                    {...pageGroupStyles}
                >
                    {pages.map(page => (
                        <PaginationPage
                            key={page}
                            page={page}
                            minW="8"
                            minH="8"
                            textStyle="typography-sm"
                            fontWeight="400"
                            colorScheme="gray"
                            color="font.100"
                            _current={{
                                variant: 'outline',
                                _hover: {
                                    color: 'font.100',
                                },
                                _active: {
                                    color: 'font.100',
                                },
                            }}
                            {...pageStyles}
                        />
                    ))}
                </PaginationPageGroup>
            </PaginationContainer>
        </Pagination>
    );
};

export const PaginationWithQuery = withQueryPage(BasicPagination);
