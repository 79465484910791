import xor from 'lodash/xor';
import { useCallback, useState } from 'react';
import { useQueryParams, StringParam, withDefault } from 'use-query-params';
import {
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Heading,
    Text,
    HStack,
    ModalCloseButton,
} from '@chakra-ui/react';
import { useUsersModelsListQuery } from '@services/df/models';
import {
    PerPageLimitParam,
    CurrentPageParam,
    PaginationQueryParams,
    getPaginationRequestParams,
} from '@shared/components/pagination';
import { CheckCircleIcon, InfoIcon } from '@shared/components/icons';
import { SearchFilter } from '@shared/components/common-filters';
import { pluralize } from '@shared/utils/strings';
import { ModelsLibraryTable } from './models-library-table';
import { ModelsLibraryFooter } from './models-library-footer';
import { ModelsLibraryQueryParams } from './constants';

const SelectedModelsStatus = ({
    amount,
    isSufficient,
}: {
    amount: number;
    isSufficient: boolean;
}) => {
    const [Icon, status] = isSufficient
        ? [CheckCircleIcon, 'success.100']
        : [InfoIcon, 'warning.100'];

    return (
        <HStack spacing="9px">
            <Icon w="13px" h="13px" color={status} />
            <Text textStyle="typography-sm" fontWeight="600" color={status}>
                {`${amount} ${pluralize(amount, 'model')} selected`}
            </Text>
        </HStack>
    );
};

interface ModelsLibraryContentProps {
    sectionId: string;
    onClose: () => void;
    onConfirm: (ids: number[]) => void;
    preselectedIds?: number[];
    requiredCount?: number;
    maxCount?: number;
}

const ModelsLibraryContent = ({
    sectionId,
    onClose,
    onConfirm,
    preselectedIds = [],
    requiredCount = 10,
    maxCount = 1000,
}: ModelsLibraryContentProps) => {
    const [query] = useQueryParams({
        [PaginationQueryParams.Limit]: PerPageLimitParam,
        [PaginationQueryParams.PageNumber]: CurrentPageParam,
        [ModelsLibraryQueryParams.Search]: withDefault(StringParam, ''),
    });

    const { search } = query;
    const params = {
        ...getPaginationRequestParams(query.page, query.limit),
        search,
        ordering: '-date_created',
        parentIsNull: true,
    };

    const { data, isLoading, isFetching } = useUsersModelsListQuery(params);

    const [selectedModels, setSelectedModels] = useState(() => preselectedIds?.slice(0, maxCount));

    const handleConfirmClick = useCallback(() => {
        onConfirm(selectedModels);
        onClose();
    }, [onClose, onConfirm, selectedModels]);

    const selectedCount = selectedModels.length;
    const hasChanged = Boolean(xor(preselectedIds, selectedModels).length);

    return (
        <ModalContent maxWidth={1000}>
            <ModalHeader>
                <HStack justifyContent="space-between">
                    <Heading>Models library</Heading>
                    <HStack>
                        <SearchFilter placeholder={'Search model'} />
                        <ModalCloseButton position="static" />
                    </HStack>
                </HStack>
            </ModalHeader>
            <ModalBody>
                <ModelsLibraryTable
                    isLoading={isLoading || isFetching}
                    models={data?.results}
                    selectedModels={selectedModels}
                    setSelectedModels={setSelectedModels}
                    allToggleAllowed={maxCount >= params.limit}
                    maxCount={maxCount}
                />
            </ModalBody>
            <ModelsLibraryFooter
                sectionId={sectionId}
                isDisabled={!hasChanged}
                total={data?.count}
                onConfirm={handleConfirmClick}
            >
                {requiredCount > 0 && (
                    <SelectedModelsStatus
                        amount={selectedCount}
                        isSufficient={selectedCount >= requiredCount}
                    />
                )}
            </ModelsLibraryFooter>
        </ModalContent>
    );
};

// todo move to widgets/models-library

interface Props extends ModelsLibraryContentProps {
    isOpen: boolean;
}

export const ModelsLibrary = ({ isOpen, onClose, ...rest }: Props) => {
    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            lockFocusAcrossFrames={false}
            blockScrollOnMount={true}
            // variant="models"
            size="xxxl"
            motionPreset="slideInBottom"
        >
            <ModalOverlay />
            <ModelsLibraryContent onClose={onClose} {...rest} />
        </Modal>
    );
};
