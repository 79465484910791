import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/types';
import { enhancedApi as api } from '@services/df/clients';
import { selectSettings } from '@ducks/app';
import { UserRoles } from '@shared/constants';
import { selectIsAuthenticated } from '../auth';
import { defineUserPermissions } from './helpers';

export const clientsMeSelector = api.endpoints.clientsMeRetrieve.select();
export const selectUser = (state: RootState) => clientsMeSelector(state).data;
export const selectUserRoles = (state: RootState) => selectSettings(state)?.available_roles;
export const selectUserRolesOptions = createSelector(selectUserRoles, roles =>
    roles?.map(({ title, id }) => ({ label: title, value: id })),
);

export const selectUserPermissions = createSelector(
    selectSettings,
    selectIsAuthenticated,
    (settings, isAuthenticated) => {
        return defineUserPermissions({
            isAuthenticated: isAuthenticated || Boolean(settings?.roles.length),
            userRoles: (settings?.roles as UserRoles) ?? [],
            settings,
        });
    },
);
