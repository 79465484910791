import { generatePath, useSearchParams } from 'react-router-dom';
import { ButtonLink } from '@shared/components/button-link';
import { ROUTES } from '@shared/constants';

interface ModelLinkProps {
    modelId: number;
}

export function ModelLink({ modelId }: ModelLinkProps) {
    const [searchParams] = useSearchParams();

    return (
        <ButtonLink
            variant="link"
            to={`${generatePath(ROUTES.MODELS_DETAILS, {
                id: modelId.toString(),
            })}?${searchParams}`}
            justifyContent="flex-start"
        >
            {modelId}
        </ButtonLink>
    );
}
