import React, { Children } from 'react';
import { FieldArrayPath, FieldValues } from 'react-hook-form';
import { Box, BoxProps, ButtonProps, Heading, IconButton, VStack, HStack } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { AddButton } from '@shared/components/common-buttons';
import { CopyIcon, TrashIcon } from '@shared/components/icons';
import { FieldArrayApiActions } from './field-array-api';

interface NestedFormsLayoutProps extends BoxProps {
    append: () => void;
}

export const NestedFormsLayout = ({ append, children, ...rest }: NestedFormsLayoutProps) => {
    const hasChild = Boolean(Children.toArray(children).filter(Boolean).length);
    return (
        <Box {...rest}>
            {hasChild && (
                <VStack spacing="6" mb="6">
                    {children}
                </VStack>
            )}
            <AddButton onClick={append} />
        </Box>
    );
};

export const NestedFormBox = (props: BoxProps) => (
    <Box position="relative" layerStyle="lightGrayBox" {...props} />
);

export const CopyNestedFormButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
    <IconButton
        ref={ref}
        isRound
        _hover={{
            bg: 'gray.200',
        }}
        colorScheme="gray"
        aria-label="copy execution"
        {...props}
    >
        <CopyIcon />
    </IconButton>
));

export const RemoveNestedFormButton = forwardRef<ButtonProps, 'button'>((props, ref) => (
    <IconButton
        ref={ref}
        isRound
        _hover={{
            bg: 'gray.200',
        }}
        colorScheme="gray"
        color="error.400"
        aria-label="remove execution"
        {...props}
    >
        <TrashIcon />
    </IconButton>
));

interface GetNestedFormPartsProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
> extends FieldArrayApiActions<TFieldValues, TFieldArrayName, TKeyName> {
    index: number;
    count: number;
    min?: number;
}

export const getNestedFormParts = <
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
>({
    index,
    count,
    min = 1,
    copy,
    remove,
}: GetNestedFormPartsProps<TFieldValues, TFieldArrayName, TKeyName>) => {
    const copyButton = (
        <CopyNestedFormButton
            onClick={() => {
                copy(index);
            }}
        />
    );
    const removeButton =
        count > min ? (
            <RemoveNestedFormButton
                onClick={() => {
                    remove(index);
                }}
            />
        ) : null;

    const header = (
        <HStack justify="space-between" mb={4}>
            <Heading as="div" size="md">
                Option {index + 1}
            </Heading>
            <Box>
                {copyButton}
                {removeButton}
            </Box>
        </HStack>
    );

    return {
        copyButton,
        removeButton,
        header,
    };
};
