import { Join } from '@shared/types';

type Delimiter = '.' | ':' | '-' | '_';
type PathSegment = string | number;
type Path = Array<PathSegment>;

export function createPathComposer<D extends Delimiter>(delimiter: D) {
    const composePath = <P extends Path>(...path: P) => path.join(delimiter) as Join<P, '', D>;

    const getPathComposer =
        <Prefix extends Path>(...prefix: Prefix) =>
        <T extends Path>(...path: T) =>
            composePath(...prefix, ...path);

    return [composePath, getPathComposer] as const;
}

export type ComposePath<D extends Delimiter> = ReturnType<typeof createPathComposer<D>>[0];
export type PathComposer<D extends Delimiter> = ReturnType<typeof createPathComposer<D>>[1];
