import { extendTheme } from '@chakra-ui/react';
import { createBreakpoints } from '@chakra-ui/theme-tools';
import { CloseButtonStyles as CloseButton } from './components/close-button-styles';
import { FormControlStyles as Form } from './components/form-control-styles';
import { FormErrorStyles as FormError } from './components/form-error-styles';
import { FormLabelStyles as FormLabel } from './components/form-label-styles';
import { ModalStyles as Modal } from './components/modal-styles';
import { MenuStyles as Menu } from './components/menu-styles';
import { TableStyles as Table } from './components/table-styles';
import { BadgeStyles as Badge } from './components/badge-styles';
import { ButtonStyles as Button } from './components/button-styles';
import { HeadingStyles as Heading } from './components/heading-styles';
import { CheckboxStyles as Checkbox } from './components/checkbox-styles';
import { CollapsibleSectionStyles as CollapsibleSection } from './components/collapsible-section-styles';
import { LinkStyles as Link } from './components/link-styles';
import { InputStyles as Input } from './components/input-styles';
import { AlertStyles as Alert } from './components/alert-styles';
import { PopoverStyles as Popover } from './components/popover-styles';
import { TabsStyles as Tabs } from './components/tabs-styles';
import { TagStyles as Tag } from './components/tag-styles';
import { TextStyles as Text } from './components/text-styles';
import { TextareaStyles as Textarea } from './components/textarea-styles';
import { TooltipStyles as Tooltip } from './components/tooltip-styles';
import { SectionStyles as Section } from './components/section-styles';
import { SwitchStyles as Switch } from './components/switch-styles';
import { RadioStyles as Radio } from './components/radio-styles';
import { containerStyles as Container } from './components/container-styles';

// https://medium.com/@alexhris/inplayers-design-system-stream-design-tokens-pt-2-3-11cc541e1d57

// const tokenToColorMap = {
//   primary: 'blue',
//   accent: 'teal',
//   success: 'green',
//   warning: 'orange',
//   error: 'red',
//   neutral: 'gray',
// };

// https://codesandbox.io/s/semantic-tokens-41xdw?file=/src/index.tsx
const semanticTokens = {
    // Typography
    // fonts: {},
    // fontSizes: {},
    // fontWeights: {},
    // letterSpacings: {},
    // lineHeights: {},

    // Foundations
    // borders: {},
    // breakpoints: {},
    colors: {
        primary: {
            default: 'primary.default',
            _dark: 'blue.500',
        },
        secondary: {
            default: 'red.800',
            _dark: 'red.700',
        },
        error: { default: 'red.500', _dark: 'red.200' },
        success: { default: 'green.500', _dark: 'red.200' },

        // in figma Surface/Primary
        'bg.primary': {
            default: 'white',
            // _dark: 'blue.500',
        },
        // in figma Surface/Secondary
        'bg.secondary': {
            default: 'neutral.subtlest',
            // _dark: 'blue.500',
        },
        // in figma Surface/Secondary
        'bg.disabled': {
            default: 'neutral.subtlest',
            // _dark: 'blue.500',
        },
        // in figma Surface/Inverse
        'bg.inverse': {
            default: 'neutral.subtle',
            // _dark: 'blue.500',
        },

        'border.primary': {
            default: 'neutral.subtle',
            // _dark: 'blue.500',
        },
        'border.secondary': {
            default: 'neutral.dark',
            // _dark: 'neutral.subtle',
        },
    },
    // radii: {},
    // shadows: {},
    sizes: {
        border: 'px',
    },
    // space: { pageHGap: '65px', pageVGap: '9' },
    // transition: {},
    // zIndices: {},
};

const colors = {
    black: '#000000',
    white: '#FFFFFF',
    dark: '#181938',
    darkblue: '#0C2953',
    clear: 'transparent',

    // blue: {
    //     default: '#0057d7',
    //     subtle: '#b7cef0',
    //     subtlest: '#f3f8ff',
    //     accent: '#003d96',
    // },
    // green: {
    //     default: '#4daf00',
    //     subtle: '#d6ecc5',
    //     subtlest: '#D6ECC5FF',
    //     accent: '#4DAF00FF',
    // },

    // text and icons
    font: {
        // todo delete after replacing with names
        100: '#181938',
        200: '#161733',
        300: '#14152E',
        400: '#121329',
        500: '#101124',
        600: '#0E0F20',
        700: '#0C0D1B',
        800: '#0A0B17',
        900: '#080912',

        // todo valid values
        primary: '#181938', // in figma Font/Primary, todo try to set 'dark'
        secondary: '#7f91a2', // 'neutral.darkest' Font/Secondary
        disabled: '#7f91a2',
    },
    // todo delete after replacing with neutral names
    gray: {
        100: '#F4F6F9', // neutral.subtlest
        200: '#E4E9F0', // neutral.subtle
        300: '#C0C9D2', // neutral.dark
        400: '#7F91A2', // neutral.darkest
        500: '#616f7d',
        600: '#8798A8',
    },
    // todo valid values, neutral is primitive tokens category, check NNW
    neutral: {
        subtlest: '#F4F6F9', // in figma Surface/Secondary
        subtle: '#E4E9F0', // Surface/Inverse
        dark: '#C0C9D2',
        darkest: '#7F91A2', // Font/Secondary

        // don't use it, just for compatibility
        100: '#F4F6F9', // neutral.subtlest
        200: '#E4E9F0', // neutral.subtle
        300: '#C0C9D2', // neutral.dark
        400: '#7F91A2', // neutral.darkest
        500: '#616f7d',
        600: '#8798A8',
    },

    //// brand and services UI colors
    primary: {
        // todo delete after replacing with names
        light: '#B7CEF0', // primary.subtle
        100: '#0057D7', // primary.default
        200: '#0050c5',
        300: '#0049b3',
        400: '#0042a1',
        500: '#003d96', // primary.accent
        600: '#00347d',
        700: '#002d6b',
        800: '#002659',
        900: '#001e47',

        // todo valid values
        default: '#0057d7',
        subtle: '#b7cef0',
        subtlest: '#f3f8ff',
        accent: '#003d96',
    },
    secondary: {
        default: '#224170',
        subtle: '#224170',
        accent: '#0c2953',
    },
    success: {
        light: '#D4E2C9',
        50: '#BEE3A1',
        100: '#4DAF00',
        200: '#47A000',
        300: '#419100',
        400: '#3B8300',
        500: '#347400',
        600: '#2E6600',
        700: '#275700',
        800: '#214900',
        900: '#1A3A00',

        default: '#4DAF00',
        subtle: '#D4E2C9',
    },
    warning: {
        light: '#FEEBC8',
        100: '#FECC2D',
        200: '#E9BB29',
        300: '#D4AA25',
        400: '#BF9921',
        500: '#AA881D',
        600: '#957719',
        700: '#806615',
        800: '#6B5512',
        900: '#56440E',

        default: '#FECC2D',
        subtle: '#FEEBC8',
    },
    error: {
        light: '#FCE6E6',
        100: '#DB0000',
        200: '#C90000',
        300: '#B70000',
        400: '#A50000',
        500: '#930000',
        600: '#810000',
        700: '#6F0000',
        800: '#5D0000',
        900: '#4A0000',

        default: '#DB0000',
        subtle: '#FCE6E6',
    },

    bluegray: {
        100: '#F3F8FF',
        200: '#F2F6FD',
    },

    pink: {
        subtlest: '#fdecff', // Background of auto messages
        darkest: '#a74b93', // Accents of auto messages
    },

    successBg: '#EEF7E6',
    errorBg: '#FCE6E6',
};

const fonts = {
    body: 'ProximaNova,Arial,sans-serif',
    heading: 'ProximaNova,Arial,sans-serif',
};

const fontSizes = {
    xss: '0.64rem', // 10,24px
    xs: '0.75rem', // 12px
    sm: '0.8125rem', // 13px
    md: '0.938rem', // 15,008px
    lg: '1.172rem', // 18.75px
    xl: '1.5rem', // 24px
    xxl: '1.875rem', // 30px
    xxxl: '2.875rem', // 48.832px
    xxxxl: '4.5rem', // 72px
};

const letterSpacings = {
    normal: '0.3px',
    button: '1px',
};

const lineHeights = {
    18: '1.125rem',
};

// remember about useAppSize
const breakpoints = createBreakpoints({
    sm: '30em', // 480px
    md: '48em', // 768px
    lg: '64em', // 1024px
    xl: '80em', // 1280px
    xxl: '96em', // 1536px
});
// const breakpoints = createBreakpoints({
//     sm: '480px',
//     md: '768px',
//     lg: '1024px',
//     xl: '1280px',
//     xxl: '1536px',
// });

const getLayerStyles = () => {
    const defaultBorder = {
        border: 'var(--chakra-sizes-border) solid',
        borderColor: 'neutral.subtle',
        borderRadius: 'base',
    };

    const defaultBox = {
        ...defaultBorder,
        position: 'relative',
        borderRadius: '12',
        p: '8',
    };

    // todo only 2 usages, discuss with designer about deprecation,
    //  in tweaker we have SectionAccentTile bgColor="primary.subtlest"
    const blueGrayBox = {
        borderColor: 'transparent',
        bgColor: 'primary.subtlest',
        borderRadius: 'base',
        px: '6',
        py: '4',
    };

    const lightGrayBox = {
        bgColor: 'neutral.subtlest',
        borderRadius: '8',
        px: '4',
        py: '4',
    };

    const pageHGapNegate = {
        ml: { base: -4, md: -10, lg: -16 },
        mr: { base: -4, md: -10, lg: -16 },
    };
    const pageHGap = { px: { base: 4, md: 10, lg: 16 } };
    const pageVGap = { py: { base: 6, md: 8 } };

    return {
        defaultBorder,
        defaultBox,
        blueGrayBox,
        lightGrayBox,
        borderedLightGrayBox: {
            ...defaultBorder,
            ...lightGrayBox,
        },
        pageVGap,
        pageHGap,
        pageHGapNegate,
        pageGap: { ...pageVGap, ...pageHGap },
    };
};

// todo rename same as https://chakra-ui.com/docs/components/text#sizes
// todo https://panda-css.com/docs/theming/text-styles#naming-conventions
const textStyles = {
    // todo maybe remove?
    'typography-xss': {
        fontSize: 'xss',
        lineHeight: '0.625rem',
        letterSpacing: 'normal',
    },
    'typography-xs': {
        fontSize: 'xs',
        lineHeight: '0.75rem',
        letterSpacing: 'normal',
    },
    'typography-sm': {
        fontSize: 'sm',
        lineHeight: '0.938rem',
        letterSpacing: 'normal',
    },
    'typography-md': {
        fontSize: 'md',
        lineHeight: '18',
        letterSpacing: 'normal',
    },
    'typography-lg': {
        fontSize: 'lg',
        lineHeight: '1.438rem',
        letterSpacing: 'normal',
    },
    // todo maybe remove? used only in ckeditor
    'typography-xl': {
        fontSize: 'xl',
        lineHeight: '1.813rem',
        letterSpacing: 'normal',
    },
    // todo maybe remove? used only in ckeditor
    'typography-xxl': {
        fontSize: 'xxl',
        lineHeight: '2.313rem',
        letterSpacing: 'normal',
    },
    // todo maybe remove? used only in ckeditor
    'typography-xxxl': {
        fontSize: 'xxxl',
        lineHeight: '3.5rem',
        letterSpacing: 'normal',
    },
    // button without background and padding
    'button-sm': {
        fontSize: 'xs',
        fontWeight: '600',
        letterSpacing: 'normal',
    },
    'button-md': {
        fontSize: 'sm',
        fontWeight: '600',
        letterSpacing: 'normal',
    },
    'button-lg': {
        fontSize: 'md',
        fontWeight: '600',
        letterSpacing: 'normal',
    },
    // button with background and padding
    'button-pad-sm': {
        fontSize: 'xss',
        fontWeight: '700',
        letterSpacing: 'button',
        textTransform: 'uppercase',
    },
    'button-pad-md': {
        fontSize: 'xs',
        // fontWeight: '700',
        fontWeight: '800',
        letterSpacing: 'button',
        textTransform: 'uppercase',
    },
    'button-pad-lg': {
        fontSize: 'sm',
        // fontWeight: '700',
        fontWeight: '800',
        letterSpacing: 'button',
        textTransform: 'uppercase',
    },
};

const radii = {
    base: '0.25rem', // 4px small components like buttons, inputs, alerts, toasts, etc...
    6: '0.375rem', // 6px small popovers
    8: '0.5rem', // 8px tags, cards, some boxes
    10: '0.625rem', // 10px badges (files)
    12: '0.75rem', // 12px cards, some boxes
    60: '3.75rem', // 60px modal, page

    // we're setting them to a 'base' so we don't overwrite the default chakra component styles
    xs: '0.25rem',
    sm: '0.25rem',
    md: '0.25rem',
};

const shadows = {
    outline: '0 0 0 1px var(--chakra-colors-primary-100)',
    outlineWhite: '0 0 0 3px rgba(255, 255, 255, 0.6)',
    menu: '0px 20px 20px -15px rgba(0, 25, 65, 0.2)',
    navigation: '0 0 12px 0 #0000000D',
    section: '0px 8px 16px 1px #0000000D',
};

const space = {
    border: '1px',
    header: '60px', // todo calc
};

const sizes = {
    middlePage: '1280px',
    narrowPage: '1160px',
    sideNavigation: 'clamp(200px, 230px, 50vw)',
    withScrollbar: 'calc(100% + 17px)',
    input: '9',
    border: '1px',
    // border: 'px',
};

const components = {
    // ATTENTION: Styles names have to match real components names
    Alert,
    Badge,
    Button,
    CloseButton,
    Checkbox,
    CollapsibleSection,
    Container,
    Form,
    FormError,
    FormLabel,
    Heading,
    Table,
    Modal,
    Menu,
    Input,
    Link,
    Radio,
    Popover,
    Tabs,
    Tag,
    Text,
    Textarea,
    Tooltip,
    Section,
    Switch,
};

const styles = {
    global: {
        body: {
            color: 'font.primary',
            fontWeight: 400,
            letterSpacing: 'normal',
            lineHeight: 'none',
        },
        'input, textarea, select, button, ::file-selector-button': {
            letterSpacing: 'normal',
        },
    },
};

const overrides = {
    // ChakraTheme
    components,
    layerStyles: getLayerStyles(),
    textStyles,
    semanticTokens,
    styles,

    // Foundations
    breakpoints,
    colors,
    radii,
    space,
    sizes,
    shadows,

    // Typography
    fonts,
    fontSizes,
    letterSpacings,
    lineHeights,
};

// todo https://chakra-ui.com/docs/styled-system/customize-theme#scaling-out-your-project
export default extendTheme(overrides);
