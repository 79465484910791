import {
    Organization,
    OrganizationCreateRequest,
    PatchedOrganizationUpdateRequest,
} from '@services/df/organizations';
import { toCoefficient, toPercent } from '@shared/utils/strings';

export const overwriteOrganizationDefaultValues = (data: Organization) => {
    if (!data?.custom_tax_rate) return data;
    return { ...data, custom_tax_rate: toPercent(data?.custom_tax_rate).value };
};

export const formatOrganizationPayload = (
    data: OrganizationCreateRequest | PatchedOrganizationUpdateRequest,
) => {
    if (!data.custom_tax_rate) return data;
    return { ...data, custom_tax_rate: toCoefficient(data.custom_tax_rate).value };
};
