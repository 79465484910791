import { Icon, IconProps } from '@chakra-ui/react';

export const BookIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M12.7 2.8V1H3.25C2.01025 1 1 2.01025 1 3.25V13.15C1 14.3897 2.01025 15.4 3.25 15.4H14.5V2.8H12.7ZM1.9 3.25C1.9 2.50525 2.50525 1.9 3.25 1.9H11.8V10.9H3.25C2.74375 10.9 2.27575 11.0687 1.9 11.35V3.25ZM3.25 14.5C2.50525 14.5 1.9 13.8947 1.9 13.15C1.9 12.4052 2.50525 11.8 3.25 11.8H12.7V3.7H13.6V12.7H5.05V13.6H13.6V14.5H3.25Z"
            fill="currentColor"
        />
    </Icon>
);
