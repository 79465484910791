import React, { useState } from 'react';
import { createContext } from '@chakra-ui/react-utils';
import { FCC } from '@shared/types';
import { FormPrompt } from './form-prompt';

type DirtyFormsProviderContext = {
    hasDirtyForm: boolean;
    formChanged: (formId: string, value: boolean) => void;
};

const [DirtyFormsProvider, useDirtyForms] = createContext<DirtyFormsProviderContext>({
    name: 'DirtyFormsContext',
});

export { useDirtyForms };

interface Props {
    withPrompt?: boolean;
    promptMessage?: string;
}

export const DirtyForms: FCC<Props> = ({ children, promptMessage, withPrompt = true }) => {
    const [dirtyForms, setDirtyForms] = useState<{ [formId: string]: boolean }>({});
    const value = React.useMemo(
        () => ({
            hasDirtyForm: Object.values(dirtyForms).some(Boolean),
            formChanged: (formId: string, value: boolean) =>
                setDirtyForms(state => ({ ...state, [formId]: value })),
        }),
        [dirtyForms],
    );

    return (
        <DirtyFormsProvider value={value}>
            {children}
            {withPrompt && (
                <FormPrompt hasUnsavedChanges={value.hasDirtyForm} message={promptMessage} />
            )}
        </DirtyFormsProvider>
    );
};
