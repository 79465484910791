import { Tooltip, forwardRef } from '@chakra-ui/react';
import { Thumb, ThumbProps } from '@shared/components/thumb';
import { FullscreenOn } from '@shared/components/tooltipped-icon-button';

// todo move to entities/models/ui

interface ModelThumbProps extends ThumbProps {}

export const ModelThumb = forwardRef<ModelThumbProps, 'div'>((props, ref) => (
    <Thumb ref={ref} borderRadius="8" size="full" {...props} />
));

const EXPAND_ICON_STYLES = {
    position: 'absolute' as const,
    right: '5px',
    bottom: '5px',
    w: '32px',
    h: '32px',
    backgroundColor: 'transparent',
};

const EXPAND_TOOLTIP_STYLES = {
    variant: 'inverse',
    w: '100px',
    top: '-48px',
    right: '20px',
    textAlign: 'center' as const,
    borderRadius: 'base',
};

const THUMBNAIL_ANIMATION_STYLES = {
    _hover: {
        div: {
            // color: 'neutral.dark',
            color: 'neutral.darkest',
            transform: 'scale(1.05)',
        },
    },
};

interface ModelThumbButtonProps extends ModelThumbProps {
    onClick: () => void;
    tooltip?: string;
    showIcon?: boolean;
}

export const ModelThumbButton = forwardRef<ModelThumbButtonProps, 'button'>(
    ({ showIcon = true, tooltip = 'Click for more details', src, ...rest }, ref) => (
        <Tooltip label={tooltip} {...EXPAND_TOOLTIP_STYLES}>
            <ModelThumb
                ref={ref}
                as="button"
                src={src}
                position="relative"
                {...THUMBNAIL_ANIMATION_STYLES}
                {...rest}
            >
                {src && showIcon && (
                    <FullscreenOn as="div" withTooltip={false} {...EXPAND_ICON_STYLES} />
                )}
            </ModelThumb>
        </Tooltip>
    ),
);
