import { Control } from 'react-hook-form';
import { FieldConfigsUnion, FieldType, CompoundFieldType } from '../types';
import {
    TextField,
    TextareaField,
    NumberField,
    CKEditorField,
    CheckboxField,
    DateField,
    MultipleCheckboxesField,
    RadioField,
    SwitchField,
    SelectField,
    EditableListField,
    MultipleInputField,
} from '../fields';

function getComponent(type: FieldType) {
    switch (type) {
        case FieldType.Text:
            return TextField;

        case FieldType.Textarea:
            return TextareaField;

        case FieldType.WYSIWYG:
            return CKEditorField;

        case FieldType.Number:
            return NumberField;

        case FieldType.Select:
            return SelectField;

        case FieldType.Switch:
            return SwitchField;

        case FieldType.Radio:
            return RadioField;

        case FieldType.Checkbox:
            return CheckboxField;

        case FieldType.MultipleCheckboxes:
            return MultipleCheckboxesField;

        case FieldType.EditableList:
            return EditableListField;

        case FieldType.Date:
            return DateField;

        default:
            const _exhaustiveCheck: never = type;
            return _exhaustiveCheck;
    }
}

interface Props {
    field: FieldConfigsUnion;
    control?: Control<any>;
    composeName?: (name: string) => string; // for nested entities
    injectedProps?: any;
    overridingProps?: any;
}

export const FieldHandler = ({
    field,
    composeName,
    control,
    injectedProps,
    overridingProps,
}: Props) => {
    const { type } = field;

    if (type === CompoundFieldType.MultipleInput) {
        const { fields, props } = field;

        return <MultipleInputField control={control} fields={fields} {...props} />;
    }

    const Component = getComponent(type);

    return (
        <Component
            control={control}
            name={composeName ? composeName(field.name) : field.name}
            {...injectedProps}
            {...field.props}
            {...overridingProps}
        />
    );
};
