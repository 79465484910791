import { useCallback, useEffect, useMemo, useState } from 'react';
import { SingleValue } from 'chakra-react-select';
import { useAppSelector } from '@app/hooks';
import { RootState } from '@app/types';
import { OptionType } from '@shared/components/forms-v2';
import { MachinesMaterials, MachinesMaterialsMaterial } from '@shared/types';
import { TechnologyIdsUnion, EventOrigin } from '@shared/constants';
import { useMachinesMaterialsListQuery } from '@services/df/machines-materials';
import { selectMaterials, selectMaterialsByPrinter, makeSelectAllowedMachines } from '../selectors';

export type MachineOption = OptionType & {
    technology: TechnologyIdsUnion;
    id: number;
};

export type MaterialOption = OptionType & {
    id: number;
};

export const getMachineOption = (
    machine: Pick<MachinesMaterials, 'id' | 'title' | 'technology'>,
): MachineOption => ({
    label: machine.title,
    value: machine.id.toString(),
    technology: machine.technology,
    id: machine.id,
});

export const getMaterialOption = (material: MachinesMaterialsMaterial): MaterialOption => ({
    label: material.title,
    value: material.id.toString(),
    id: material.id,
});

type OnUpdate = (params: {
    machine?: MachineOption | null;
    material?: MaterialOption | null;
    eventOrigin: EventOrigin;
}) => void;

export interface MachinesMaterialsSelectOptions {
    preselectedMaterialId?: number | null;
    onUpdate?: OnUpdate;
    exclude?: TechnologyIdsUnion[];
}

export const useMachinesMaterialsSelectOptions = ({
    preselectedMaterialId,
    onUpdate,
    exclude,
}: MachinesMaterialsSelectOptions = {}) => {
    const { isLoading, isFetching } = useMachinesMaterialsListQuery({});

    const selectAllowedMachines = useMemo(makeSelectAllowedMachines, []);
    const materials = useAppSelector(selectMaterials);
    const machines = useAppSelector(state => selectAllowedMachines(state, exclude));

    const [machine, setMachine] = useState<MachineOption | null>(null);
    const [material, setMaterial] = useState<MaterialOption | null>(null);

    const materialsBySelectedPrinter = useAppSelector((state: RootState) =>
        selectMaterialsByPrinter(state, machine?.id),
    );

    // set up local state on loading and synchronize it with pre-selected (forced or previously selected) material, if present
    useEffect(() => {
        if (
            // set only once at loading
            machine ||
            !preselectedMaterialId ||
            !materials?.length ||
            !machines?.length
        ) {
            return;
        }

        const preselectedMaterial = materials?.find(
            ({ id }) => id === Number(preselectedMaterialId),
        )!;
        const preselectedMachine = machines?.find(
            ({ id }) => id === preselectedMaterial?.printerId,
        )!;

        if (!preselectedMaterial || !preselectedMachine) {
            return;
        }

        const _machine = getMachineOption(preselectedMachine);
        const _material = getMaterialOption(preselectedMaterial);
        setMachine(_machine);
        setMaterial(_material);
        onUpdate?.({ machine: _machine, material: _material, eventOrigin: EventOrigin.Effect });
    }, [materials, machines, preselectedMaterialId, machine, onUpdate]);

    const handleMachineChange = useCallback(
        (machine: SingleValue<MachineOption>) => {
            setMachine(machine);
            setMaterial(null);
            onUpdate?.({ machine, material: null, eventOrigin: EventOrigin.Ui });
        },
        [onUpdate],
    );

    const handleMaterialChange = useCallback(
        (material: SingleValue<MaterialOption>) => {
            setMaterial(material);
            onUpdate?.({ material, eventOrigin: EventOrigin.Ui });
        },
        [onUpdate],
    );

    return {
        isLoading: isLoading || isFetching,
        machines,
        machine,
        setMachine,
        machinesOptions: machines?.map(getMachineOption),
        handleMachineChange,
        materials,
        material,
        setMaterial,
        materialOptions: materialsBySelectedPrinter?.map(getMaterialOption),
        handleMaterialChange,
    };
};
