import React, { useMemo } from 'react';
import { BoxProps } from '@chakra-ui/react';
import {
    AddressFields,
    AddressFieldsUnion,
    getAddressConfigFromFields,
    useAddressStateField,
} from '@entities';
import { AddButton } from '@shared/components/common-buttons';
import {
    SectionLineItem,
    SectionLineItemsCollapse,
    SectionModalForm,
    SectionModalFormProps,
} from '@shared/components/section';
import { CompanyUserAddress } from '@services/df/clients';

const AddressModalFormFields = ({
    fieldsMap,
    fieldsOrder,
}: {
    fieldsMap: AddressFields;
    fieldsOrder: ReadonlyArray<AddressFieldsUnion>;
}) => {
    const countryField = fieldsMap.country;
    const stateField = fieldsMap.state;

    const { state, countryProps, stateProps } = useAddressStateField(
        countryField.props,
        stateField.props,
    );

    return (
        <>
            {fieldsOrder.map(field => {
                switch (field) {
                    case 'country': {
                        return (
                            <SectionLineItem
                                key={field}
                                {...fieldsMap[field]}
                                props={countryProps}
                            />
                        );
                    }

                    case 'state': {
                        return (
                            <SectionLineItemsCollapse key={field} in={Boolean(state)}>
                                <SectionLineItem {...fieldsMap[field]} props={stateProps} />
                            </SectionLineItemsCollapse>
                        );
                    }

                    default: {
                        return <SectionLineItem key={field} {...fieldsMap[field]} />;
                    }
                }
            })}
        </>
    );
};

interface BaseAddressModalFormProps
    extends Omit<SectionModalFormProps, 'data' | 'trigger' | 'schema'>,
        Omit<BoxProps, 'onError'> {
    address?: Partial<CompanyUserAddress>;
    fields: ReadonlyArray<AddressFieldsUnion>;
    trigger?: SectionModalFormProps['trigger'];
}

export const BaseAddressModalForm = ({ address, fields, ...rest }: BaseAddressModalFormProps) => {
    const { fieldsMap, schema } = useMemo(() => getAddressConfigFromFields(fields), [fields]);

    return (
        <SectionModalForm
            apiErrorsToastEnabled={false}
            trigger={({ onOpen, triggerId }) => (
                <AddButton id={triggerId()} onClick={onOpen}>
                    add address
                </AddButton>
            )}
            trapFocus={false}
            {...rest}
            data={address}
            schema={schema}
        >
            <AddressModalFormFields fieldsMap={fieldsMap} fieldsOrder={fields} />
        </SectionModalForm>
    );
};
