import { get } from 'lodash';
import { FieldErrors } from 'react-hook-form';

interface GetCurrentErrors {
    names: string[] | undefined;
    errors: FieldErrors<any>;
}

export const getCurrentErrors = ({ names, errors }: GetCurrentErrors) => {
    const currentErrors = names?.reduce((accum: string[], path) => {
        const error = get(errors, path);
        if (error && typeof error.message === 'string') {
            return [...accum, error.message];
        }
        return accum;
    }, []);
    const errorText = currentErrors && currentErrors.join('; ');

    return { currentErrors, errorText };
};
