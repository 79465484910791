import { axiosInstance } from './instance';
import {
    getPriceMatchSlugByTechnology,
    PriceMatchRecalculatedFields,
    PriceMatchPriceSet,
} from '@services/df/price-match';
import { TechnologyIdsUnion } from '@shared/constants';

export class PriceMatchingService {
    static init() {
        return new PriceMatchingService();
    }

    public recalculatePriceMatch(data: PriceMatchRecalculatedFields, techId?: TechnologyIdsUnion) {
        return axiosInstance.post<PriceMatchPriceSet>(
            `/price_match/${getPriceMatchSlugByTechnology(techId)}/recalculate_results/`,
            data,
        );
    }
}
