import { FCC } from '@shared/types';
import { Section, SectionHead } from '@shared/components/section';

const size = 'md';

interface AuthSectionHeadProps {
    header: string;
}

export const AuthSectionHead = ({ header }: AuthSectionHeadProps) => (
    <SectionHead size={size} textAlign="center" color="secondary.default" mb={6} header={header} />
);

interface AuthSectionProps extends AuthSectionHeadProps {}

export const AuthSection: FCC<AuthSectionProps> = ({ header, children }) => (
    <Section
        size={size}
        head={<AuthSectionHead header={header} />}
        border="none"
        boxShadow="section"
        divider={false}
    >
        {children}
    </Section>
);
