import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '@app/types';
import { NullableRecord } from '@shared/types';
import { EventOrigin, TechnologyIdsUnion } from '@shared/constants';
import { createPersistReducer } from '../helpers';

export type PriceMatchConfig = NullableRecord<{
    technologyId?: TechnologyIdsUnion;
    materialId?: number;
    layerThickness?: number;
}>;

const initialState: PriceMatchConfig = {};

const configSlice = createSlice({
    name: 'priceMatch/config',
    initialState,
    reducers: create => ({
        update: create.preparedReducer(
            (payload: PriceMatchConfig, eventOrigin: EventOrigin) => ({
                payload,
                meta: { eventOrigin },
            }),
            (state, action) => {
                Object.assign(state, action.payload);
            },
        ),
    }),
});

export const configReducer = createPersistReducer(configSlice, {
    dependsOnCompany: true,
});

export const { update: priceMatchUpdateConfig } = configSlice.actions;

export const selectPriceMatchConfig = (state: RootState) => state.priceMatch.config;
export const selectPriceMatchTechnologyId = (state: RootState) =>
    state.priceMatch.config.technologyId;
export const selectPriceMatchMaterialId = (state: RootState) => state.priceMatch.config.materialId;
export const selectPriceMatchLayerThickness = (state: RootState) =>
    state.priceMatch.config.layerThickness;
