import { ComponentProps, useMemo } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectOrderStatusOptions } from '@ducks/app';
import {
    OrganizationCreateRequest,
    useOrganizationsCreateMutation,
} from '@services/df/organizations';
import {
    OrganizationFieldsArray,
    getOrganizationConfig,
    formatOrganizationPayload,
} from '@entities';
import { UpdateFormSubmit } from '@shared/components/forms-v2';
import { VbanDescription } from '@shared/components/vban';
import { SectionLineItem, SectionModalForm } from '@shared/components/section';
import { createToast as toast } from '@shared/components/toast';

const formId = 'organization_create_form';

const renderFields = (fields: OrganizationFieldsArray) =>
    fields.map((field, index) => <SectionLineItem key={index} {...field} />);

interface Props
    extends Pick<
        ComponentProps<typeof SectionModalForm<OrganizationCreateRequest>>,
        'trigger' | 'shouldCheckDirty'
    > {
    data?: OrganizationCreateRequest;
}

export const OrganizationCreateForm = ({ data, shouldCheckDirty, trigger }: Props) => {
    const orderStatusOptions = useAppSelector(selectOrderStatusOptions)!;
    const { fieldsArray, schema } = useMemo(
        () => getOrganizationConfig({ orderStatusOptions }),
        [orderStatusOptions],
    );
    const [vban_bank_transfer_enabled, ...fields] = fieldsArray;

    const [create] = useOrganizationsCreateMutation();
    const handleCreate: UpdateFormSubmit<OrganizationCreateRequest> = async data => {
        const preparedData = formatOrganizationPayload(data);
        await create({
            organizationCreateRequest: preparedData as OrganizationCreateRequest,
        })
            .unwrap()
            .then(() => {
                toast({
                    position: 'top-right',
                    title: 'The company was created',
                });
            });
    };

    return (
        <SectionModalForm
            formId={formId}
            header="Create company"
            trigger={trigger}
            data={data}
            schema={schema}
            submit={handleCreate}
            shouldCheckDirty={shouldCheckDirty}
            isCentered={false}
        >
            {renderFields(fields)}
            <SectionLineItem
                helpText={<VbanDescription />}
                // todo check sx
                gridProps={{ paddingTop: '40px', gridTemplateColumns: 'auto 1fr' }}
                {...vban_bank_transfer_enabled}
            />
        </SectionModalForm>
    );
};
