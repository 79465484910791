import { anatomy } from '@chakra-ui/theme-tools';
import type { ComponentMultiStyleConfig } from '@chakra-ui/theme';
import type { PartsStyleObject } from '@chakra-ui/theme-tools';

export const sectionAnatomy = anatomy('section').parts('container').extend('divider');

const baseStyle: PartsStyleObject<typeof sectionAnatomy> = {
    container: {
        // '& > .chakra-divider': { color: 'neutral.subtle' },
        '& .chakra-section__image': {
            boxSize: 12,
        },
        '& .chakra-section__description': {
            color: 'neutral.darkest',
            textStyle: 'typography-sm',
        },
        '.chakra-section__image + .chakra-section__heading-box > .chakra-section__description': {
            mt: 2,
        },
        '& .chakra-section__heading-box': {
            flexGrow: 1,
        },
    },
    divider: { color: 'neutral.subtle' },
};

export const SectionStyles: ComponentMultiStyleConfig = {
    parts: sectionAnatomy.keys,
    baseStyle,
    sizes: {
        sm: {
            container: {
                p: 4,
                '& .chakra-section__image': {
                    boxSize: 8,
                },
                '& .chakra-section__head': {
                    mb: 2,
                },
                '& .chakra-section__heading-box': {
                    'h2,h3': { scrollMarginTop: 4 },
                },
                '& .chakra-section__description': {
                    textStyle: 'typography-md',
                },
            },
            divider: { my: 2 },
        },
        md: {
            container: {
                p: { base: 4, md: 6 },
                '& .chakra-section__image': {
                    boxSize: 10,
                },
                '& .chakra-section__heading-box': {
                    'h2,h3': { scrollMarginTop: 6 },
                },
            },
            divider: { my: { base: 4 } },
        },
        lg: {
            container: {
                // borderRadius: '12',
                '& .chakra-section__heading-box': {
                    'h2,h3': { scrollMarginTop: 8 },
                },
            },
            divider: { my: { base: 4, md: 6 } },
        },
    },
    variants: {
        neutral: {
            container: { layerStyle: 'defaultBox', bgColor: 'white' },
        },
        accent: { container: { layerStyle: 'lightGrayBox' } },
    },
    defaultProps: {
        // colorScheme: 'gray',
        size: 'lg',
        variant: 'neutral',
    },
};
