import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import React from 'react';
import { defineField, FieldType } from '@shared/components/forms-v2';
import { uuidv4 } from '@shared/utils/uuid';

type ConfigArgs = {};

export const getMachineToleranceConfig = ({}: ConfigArgs = {}) => {
    const deviation_from = defineField({
        type: FieldType.Number,
        name: 'deviation_from',
        scheme: yup.number().requiredNullEqualTo0(10),
        props: {
            label: 'Minus',
            rightAddon: 'mm',
        },
    });
    const deviation_to = defineField({
        type: FieldType.Number,
        name: 'deviation_to',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Plus',
            rightAddon: 'mm',
        },
    });
    const margin = defineField({
        type: FieldType.Number,
        name: 'margin',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Margin',
            tooltip: 'Gets added to the total cost of the piece',
            rightAddon: '%',
        },
    });
    const name_for_user = defineField({
        type: FieldType.Text,
        name: 'name_for_user',
        scheme: yup.string().ensure().trim().max(255),
        props: {
            label: 'Name for user',
            tooltip: `Any name input here will be displayed instead
            of the tolerance values above.`,
        },
    });
    const sort_order = defineField({
        type: FieldType.Number,
        name: 'sort_order',
        scheme: yup.number().requiredNullEqualTo0(1).min(0).max(4294967295),
        props: {
            label: 'Order of display',
            min: 1,
            max: 999,
        },
    });

    const schema = yup.object({
        id: yup
            .string()
            .uuid()
            .default(() => uuidv4()),
        ...mapValues(
            {
                deviation_from,
                deviation_to,
                margin,
                name_for_user,
                sort_order,
            },
            'scheme',
        ),
    });

    const fields = {
        deviation_from,
        deviation_to,
        margin,
        name_for_user,
        sort_order,
    };

    return {
        fields,
        schema,
    };
};

export type MachineToleranceConfig = ReturnType<typeof getMachineToleranceConfig>;
export type MachineToleranceSchema = MachineToleranceConfig['schema'];
