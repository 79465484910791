export enum OrderPriceCorrectionType {
    Increase = '0',
    Decrease = '1',
}

export interface OrderPriceCorrectionForm {
    type: OrderPriceCorrectionType;
    value: string;
    comment?: string;
}
