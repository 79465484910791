import filter from 'lodash/filter';
import find from 'lodash/find';
import isEqual from 'lodash/isEqual';
import differenceBy from 'lodash/differenceBy';
import intersectionBy from 'lodash/intersectionBy';

export const getCrudQueues = () => {
    const getQueueCreation = (nextState: any[], baseState: any[] = []) =>
        differenceBy(nextState, baseState, 'id');

    const getQueueEdition = (nextState: any[], baseState: any[] = []) => {
        const intersections = intersectionBy(nextState, baseState, 'id');
        const edited = filter(intersections, nextForm => {
            const baseForm = find(baseState, baseForm => nextForm.id === baseForm.id);
            return !isEqual(nextForm, baseForm);
        });
        return edited;
    };

    const getQueueDeletion = (nextState: any[], baseState: any[] = []) =>
        differenceBy(baseState, nextState, 'id');

    return { getQueueDeletion, getQueueCreation, getQueueEdition };
};
