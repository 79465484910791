import { DiscountSerializerV2 } from '@services/df/discounts';
import { DiscountType } from '@services/df/codegen-enums';

// todo move to DiscountFormHelper as static

export const hasMultipleConditions = (type: DiscountSerializerV2['type']) =>
    !(type === DiscountType.Promo || type === DiscountType.Client);

export const hasMaterialsInDiscount = (type: DiscountSerializerV2['type']) =>
    type === DiscountType.Batch || type === DiscountType.SqCmOfMaterial;

export const hasClientsInDiscount = (type: DiscountSerializerV2['type']) =>
    type === DiscountType.Client;
