import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';
import { getFilledButtonStylesBySize } from './button-styles';

const getSolidTabStylesBySize = (size: string) =>
    size === 'sm'
        ? {
              py: 2,
              px: 4,
              minH: '8',
          }
        : size === 'md'
        ? {
              py: 3,
              px: 8,
          }
        : undefined;

const variantSolid: SystemStyleFunction = props => {
    const { colorScheme: c, size } = props;

    return {
        tablist: {
            borderBottom: 'unset',
            border: '1px solid',
            borderColor: 'gray.200',
            borderRadius: 'base',
            width: 'fit-content',
        },
        tab: {
            fontWeight: '600',
            color: 'gray.600',
            textStyle: 'typography-md',
            borderRadius: 'base 0px 0px base',
            _selected: {
                color: 'font.100',
                background: 'gray.200',
            },

            ...getSolidTabStylesBySize(size),
        },
    };
};

const variantDivided: SystemStyleFunction = props => {
    const {
        colorScheme: c,
        size,
        theme: { textStyles },
    } = props;

    return {
        tablist: {
            display: 'grid',
            gridAutoFlow: 'column',
            alignItems: 'center',
            columnGap: '3',
            '& > .chakra-divider': { h: '70%' },
        },
        tab: {
            ...getFilledButtonStylesBySize(size, textStyles),
            px: '0',
            color: 'font.secondary',
            _selected: {
                color: 'font.primary',
                borderBottom: 'solid 1px',
            },
        },
    };
};

export const TabsStyles = {
    baseStyle: {
        tabpanel: {
            padding: '0',
        },
    },
    sizes: {
        md: {
            tab: { py: 4 },
        },
    },
    variants: {
        line: {
            tab: {
                fontWeight: '600',
                color: 'gray.600',
                _selected: {
                    color: 'primary.default',
                },
                _active: {
                    bg: 'rgba(0, 87, 215, 0.05)',
                },
            },
        },
        // section: {
        //
        // }
        divided: variantDivided,
        solid: variantSolid,
    },
    defaultProps: {
        colorScheme: 'primary',
    },
};
