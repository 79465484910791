// export const MenuStyles = {
//     baseStyle: {},
//     sizes: {
//         md: {
//             // groupTitle: {},
//             item: {
//                 py: 2,
//                 px: 4,
//                 // fontSize: 'sm',
//             },
//         },
//     },
//     variants: {
//         dropdown: {
//             // groupTitle: {},
//             item: {
//                 // _focus: {
//                 //     bg: mode('gray.100', 'whiteAlpha.100')(props),
//                 // },
//                 // _active: {
//                 //     bg: mode('gray.200', 'whiteAlpha.200')(props),
//                 // },
//                 // _expanded: {
//                 //     bg: mode('gray.100', 'whiteAlpha.100')(props),
//                 // },
//                 // _disabled: {
//                 //     opacity: 0.4,
//                 //     cursor: 'not-allowed',
//                 // },
//             },
//         },
//     },
//     defaultProps: { size: 'md', variant: 'dropdown' },
// };

export const MenuStyles = {
    baseStyle: {
        // groupTitle: {},
        item: {
            py: 2,
            px: 4,
            fontSize: 'sm',
            '& .chakra-menu__icon-wrapper': {
                fontSize: '1em',
                marginEnd: '1',
            },
        },
    },
};
