import { CheckboxGroup, CheckboxGroupProps, Stack, StackProps } from '@chakra-ui/react';
import { Checkbox, CheckboxProps } from './checkbox';

export type MultipleCheckboxesProps = Omit<CheckboxGroupProps, 'children'> & {
    buttons: Array<CheckboxProps>;
    stack?: StackProps;
};

export const MultipleCheckboxes = ({ buttons, stack, ...props }: MultipleCheckboxesProps) => {
    return (
        <CheckboxGroup {...props}>
            <Stack alignItems="flex-start" spacing={3} {...stack}>
                {buttons.map(checkbox => (
                    <Checkbox key={checkbox.value} {...checkbox}>
                        {checkbox.children}
                    </Checkbox>
                ))}
            </Stack>
        </CheckboxGroup>
    );
};

export const objectToCheckboxesButtons = (obj: Record<string, string>) =>
    Object.keys(obj).map(value => ({
        children: obj[value],
        value,
    }));
