import { mode, SystemStyleFunction, transparentize } from '@chakra-ui/theme-tools';

const variantSubtle: SystemStyleFunction = props => {
    const { colorScheme: c, theme } = props;
    const darkBg = transparentize(`${c}.200`, 0.16)(theme);

    if (c === 'neutral') {
        return {
            bg: mode(`neutral.subtle`, darkBg)(props),
            color: mode('neutral.darkest', `${c}.200`)(props),
        };
    }

    if (c === 'primary') {
        return {
            bg: mode(`${c}.subtle`, darkBg)(props),
        };
    }

    if (c === 'success') {
        return {
            bg: mode(`${c}.light`, darkBg)(props),
            color: mode(`${c}.100`, `${c}.200`)(props),
        };
    }

    if (c === 'error') {
        return {
            bg: mode(`${c}.light`, darkBg)(props),
            color: mode(`${c}.100`, `${c}.200`)(props),
        };
    }

    if (c === 'warning') {
        return {
            bg: mode(`${c}.light`, darkBg)(props),
            color: mode(`${c}.100`, `${c}.200`)(props),
        };
    }

    return {
        bg: mode(`${c}.100`, darkBg)(props),
        color: mode('font.100', `${c}.200`)(props),
    };
};

export const BadgeStyles = {
    variants: {
        subtle: variantSubtle,
    },
};
