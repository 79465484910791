import React, { PropsWithChildren, ReactNode, useMemo } from 'react';
import { FieldValues, useFormState } from 'react-hook-form';
import { Box, PopoverContentProps } from '@chakra-ui/react';
import {
    FieldConfigsUnion,
    FieldHandler,
    UseFormPopupProps,
    useFormPopup,
    // NumberField,
    // EditableListField,
} from '@shared/components/forms-v2';
import { CheckmarkPopover } from './checkmark-popover';

export const defaultCheckmarkProps = {
    'aria-label': 'Submit',
    type: 'submit',
} as const;

export const fieldPopoverInjectedProps = {
    size: 'xs',
    minW: '50px',
    formControlProps: { color: 'font.100' },
    groupProps: { size: 'xs' },
    errorProps: { size: 'xs', mt: 1 },
};

export const fieldPopoverOverridingProps = {
    label: '',
};

interface Props<TFieldValues extends FieldValues = FieldValues>
    extends UseFormPopupProps<TFieldValues> {
    triggerNode: ReactNode;
    // triggerNode: PopoverProps['children'];
    formId: string;
    field: FieldConfigsUnion;
    popoverContentProps?: PopoverContentProps;
}

export const EditFieldPopover = <TFieldValues extends FieldValues = FieldValues>({
    triggerNode,
    formId,
    data,
    field,
    schema,
    submit,
    children,
    popoverContentProps,
    ...rest
}: PropsWithChildren<Props<TFieldValues>>) => {
    const { onOpen, onClose, isOpen, control, handleFormSubmit } = useFormPopup<TFieldValues>({
        data,
        schema,
        submit,
        ...rest,
    });
    const { isSubmitting, isDirty } = useFormState({ control });
    const checkmarkProps = useMemo(
        () => ({ ...defaultCheckmarkProps, form: formId, disabled: isSubmitting || !isDirty }),
        [formId, isDirty, isSubmitting],
    );
    return (
        <CheckmarkPopover
            checkmark={checkmarkProps}
            triggerNode={triggerNode}
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            popoverContentProps={popoverContentProps}
        >
            <Box as="form" id={formId} onSubmit={handleFormSubmit}>
                <FieldHandler
                    control={control}
                    injectedProps={fieldPopoverInjectedProps}
                    field={field}
                    overridingProps={fieldPopoverOverridingProps}
                />
                {/*<NumberField control={formApi.control} name="xs" size="xs" />*/}
                {/*<EditableListField control={formApi.control} name="xs" size="sm" />*/}
                {children}
            </Box>
        </CheckmarkPopover>
    );
};
