import { Icon, IconProps } from '@chakra-ui/react';

// This icon is looks like a Paper Plane or Telegram Icon

export const SendIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M14.8346 1.33984L7.46484 8.70955"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M14.835 1.33984L10.1452 14.7393L7.46531 8.70955L1.43555 6.02966L14.835 1.33984Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
