import { axiosInstance } from './instance';

type BracketingType = {
    id: number | string;
    first_multiplier: number | string;
    second_multiplier: number | string;
    third_multiplier: number | string;
    fourth_multiplier: number | string;
    fifth_multiplier: number | string;
};

export class MachineService {
    static init() {
        return new MachineService();
    }

    // This used in price-mutch-result

    public editMachine(id: number | string, name: string, data: Record<string, any>) {
        return axiosInstance.patch(`/v2/machines/${name}/${id}/`, data);
    }

    // public fetchListMachinePresets(technology_id?: number) {
    //     return axiosInstance.get('/v2/machines/presets/', {
    //         params: {
    //             technology_id,
    //         },
    //     });
    // }

    // public editNested(
    //     id: number | string,
    //     name: string,
    //     machineId: number | string,
    //     data: Record<string, string | number>,
    // ) {
    //     return axiosInstance.patch(`/v2/machines/${machineId}/${name}/${id}/`, data);
    // }

    // This used in price-mutch-result

    public createNested(name: string, machineId: number | string, data: Record<string, any>) {
        return axiosInstance.put(`/v2/machines/${machineId}/${name}/`, data);
    }

    // This used in price-mutch-result

    public deleteNested(id: number | string, name: string, machineId: number | string) {
        return axiosInstance.delete(`/v2/machines/${machineId}/${name}/${id}/`);
    }

    // This used in price-mutch-result

    public editBracketing(
        id: number | string,
        machineId: number | string,
        data: Omit<BracketingType, 'id'>,
    ) {
        return axiosInstance.patch(`/v2/machines/${machineId}/complexity_bracketing/${id}/`, data);
    }

    // public fetchBracketing(machineId: number | string) {
    //     return axiosInstance.get(`/v2/machines/${machineId}/complexity_bracketing/`);
    // }

    // public fetchListFeature(id: number | string) {
    //     return axiosInstance.get(`/v2/machines/${id}/features/`);
    // }
}
