import filter from 'lodash/filter';
import isString from 'lodash/isString';
import { ReactNode } from 'react';
import { Box, BoxProps, Heading, HStack, VStack, StackProps, Text } from '@chakra-ui/react';
import { FCC } from '@shared/types';
import { FieldConfigs } from '../types';

type Entity = Array<{ property: ReactNode; value: ReactNode }>;

export const getRecordFromFieldset = <
    Field extends { label?: ReactNode } & FieldConfigs,
    Fieldset extends Array<Field>,
>(
    fieldset: Fieldset,
    values: Record<string, ReactNode>,
): Entity =>
    fieldset.map(({ label, name, props }) => ({
        //@ts-ignore
        property: props?.label || label || 'unknown',
        value: values[name],
    }));

export interface EntityRecordProps extends BoxProps {
    entity: Entity;
    listProps?: StackProps;
    label?: ReactNode;
    blankText?: ReactNode;
    actions?: ReactNode;
}

export const EntityRecord: FCC<EntityRecordProps> = ({
    entity,
    label,
    blankText,
    children,
    actions,
    listProps = {},
    ...props
}) => {
    const _label = isString(label) ? (
        <Heading as="div" size="sm" mb={2}>
            {label}
        </Heading>
    ) : (
        label
    );

    const list = filter(entity, 'value');
    const records = (
        <VStack layerStyle="blueGrayBox" spacing={1} align="stretch" {...listProps}>
            {_label}
            {list.length ? (
                list.map(({ property, value }, index) => (
                    <Text key={index} textStyle="typography-sm" fontStyle="italic" color="gray.400">
                        <Text as="b">{property}: </Text>
                        {isString(value) ? value.substring(0, 100) : value}
                    </Text>
                ))
            ) : blankText ? (
                blankText
            ) : (
                <Text textStyle="typography-md" fontStyle="italic" color="gray.400">
                    Record is empty
                </Text>
            )}
            {children}
        </VStack>
    );
    return (
        <Box {...props}>
            {actions ? (
                <HStack spacing={4}>
                    {records}
                    <HStack spacing={2}>{actions}</HStack>
                </HStack>
            ) : (
                records
            )}
        </Box>
    );
};
