import { PricingPlanFeature } from '@services/df/app';

export const findPricingPlanFeature = (
    features: PricingPlanFeature[] | undefined,
    codeName: string,
) => features?.find(feature => feature.code_name === codeName);

export const hasPricingPlanFeature = (
    features: PricingPlanFeature[] | undefined,
    codeName: string,
) => Boolean(findPricingPlanFeature(features, codeName));
