import { ReactNode } from 'react';
import { BoxProps, Center, VStack } from '@chakra-ui/react';
import { PageContainer } from '@shared/components/page-container';

interface Props extends BoxProps {
    head?: ReactNode;
}

export const AuthPageLayout = ({ head, children, ...rest }: Props) => (
    <PageContainer
        showAppHeader={false}
        as={Center}
        bgColor="neutral.subtlest"
        minH="100vh"
        {...rest}
    >
        <VStack alignItems="stretch" spacing={10} w="full" maxW="360px">
            {head}
            {children}
        </VStack>
    </PageContainer>
);
