import { Icon, IconProps } from '@chakra-ui/react';

export const ClientsIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M10.8173 13.0445V11.8173C10.8173 11.1664 10.5587 10.5421 10.0985 10.0818C9.63818 9.62156 9.01391 9.36298 8.36298 9.36298H3.45433C2.8034 9.36298 2.17913 9.62156 1.71886 10.0818C1.25858 10.5421 1 11.1664 1 11.8173V13.0445M14.5 13.0447V11.8175C14.4996 11.2737 14.3186 10.7455 13.9854 10.3157C13.6523 9.88589 13.1858 9.57893 12.6593 9.44298M10.2037 2.07999C10.7317 2.21517 11.1996 2.5222 11.5338 2.9527C11.8679 3.38319 12.0493 3.91266 12.0493 4.45762C12.0493 5.00259 11.8679 5.53205 11.5338 5.96255C11.1996 6.39304 10.7317 6.70008 10.2037 6.83525M8.36418 4.45433C8.36418 5.80982 7.26534 6.90866 5.90985 6.90866C4.55437 6.90866 3.45553 5.80982 3.45553 4.45433C3.45553 3.09884 4.55437 2 5.90985 2C7.26534 2 8.36418 3.09884 8.36418 4.45433Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
