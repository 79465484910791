import { LineItem, LineItemProps } from '@shared/components/line-items';
import { GetModelVolumeProps, getModelVolume } from './helpers';

// todo move to entities/models/ui

type Props = Partial<LineItemProps> & GetModelVolumeProps;

export const ModelVolume = ({ model, convertTo, label = 'Volume', ...props }: Props) => {
    const { formattedVolume, volumeWithSymbol } = getModelVolume({
        model,
        convertTo,
    });

    return formattedVolume ? (
        <LineItem label={label} {...props}>
            <span>{volumeWithSymbol}</span>
        </LineItem>
    ) : null;
};
