import { ReactNode } from 'react';
import { generatePath } from 'react-router-dom';
import { HStack, VStack, StackProps, Text } from '@chakra-ui/react';
import { MaybeRenderProp } from '@chakra-ui/react-utils';
import { runIfFn } from '@chakra-ui/utils';
import { forwardRef } from '@chakra-ui/system';
import { ClientExtendedRepresentation } from '@services/df/clients';
import { SectionTile, SectionTileProps } from '@shared/components/section';
import { RouterLink } from '@shared/components/common-links';
import { ROUTES } from '@shared/constants';

type Props = Pick<ClientExtendedRepresentation, 'id' | 'full_name' | 'email'> &
    Omit<StackProps, 'id'> & {
        action?: ReactNode;
    };

const ClientCardLayout = ({ id, full_name, email, action, children, ...rest }: Props) => (
    <HStack justifyContent="space-between" {...rest}>
        <VStack alignItems="flex-start">
            <Text textStyle="typography-md">
                {full_name}{' '}
                <RouterLink
                    variant="highlighted"
                    to={generatePath(ROUTES.CLIENTS_DETAILS, {
                        id: id.toString(),
                    })}
                >
                    ({email})
                </RouterLink>
            </Text>
            {children}
        </VStack>
        {action}
    </HStack>
);

type ClientCardProps = ClientExtendedRepresentation &
    Omit<SectionTileProps, 'id' | 'children'> & {
        children?: MaybeRenderProp<{
            id: number;
        }>;
    };

export const ClientCard = forwardRef<ClientCardProps, 'div'>(
    ({ id, full_name, email, phone_number, description, children, ...rest }, ref) => (
        <SectionTile ref={ref} {...rest}>
            <ClientCardLayout
                id={id}
                full_name={full_name}
                email={email}
                action={runIfFn(children, {
                    id,
                })}
            >
                {/*{phone_number && <Text textStyle="typography-sm">Phone: {phone_number}</Text>}*/}
                {/*{description && <Text textStyle="typography-sm">Description: {description}</Text>}*/}
            </ClientCardLayout>
        </SectionTile>
    ),
);
