import currency from 'currency.js';
import { ChangeEventHandler } from 'react';
import { Checkbox, Flex, Text, Tr, Td } from '@chakra-ui/react';
import { DateTime } from '@shared/components/datetime';
import { Thumb } from '@shared/components/thumb';
import { ObjectModelUnits } from '@shared/types';

interface Props {
    id: number;
    size: {
        x: number | null;
        y: number | null;
        z: number | null;
    };
    title: string | undefined;
    checked: boolean;
    isLoading: boolean;
    date: string | undefined;
    thumb: string | undefined;
    units: ObjectModelUnits | undefined;
    onChangeCheckbox: ChangeEventHandler<HTMLInputElement>;
}

export const ModelsItem = ({
    id,
    title,
    size,
    units,
    date,
    thumb,
    checked,
    isLoading,
    onChangeCheckbox,
}: Props) => {
    const correctSize = [size.x!, size.y!, size.z!]
        .map(size => currency(size).format({ symbol: '' }))
        .join(' x ');
    const sizeUnits = `Size: ${correctSize} ${units}`;

    return (
        <Tr>
            <Td width="16px" verticalAlign="middle">
                <Checkbox
                    value={id}
                    isChecked={checked}
                    isDisabled={isLoading}
                    onChange={onChangeCheckbox}
                    verticalAlign="middle"
                    mr="16px"
                />
            </Td>
            <Td>
                <Flex width="208px" gap="12px" align="center" justify="space-between">
                    <Thumb src={thumb} flexShrink={0} />
                    <Flex width="200px" direction="column">
                        <Text as="h1" textStyle="typography-sm" color="font.100" fontWeight="600">
                            {title}
                        </Text>
                        <Text mt="4px" textStyle="typography-sm" color="gray.400">
                            {sizeUnits}
                        </Text>
                    </Flex>
                </Flex>
            </Td>
            <Td textAlign="right">
                <Text textStyle="typography-sm" color="font.100">
                    <DateTime value={date} />
                </Text>
            </Td>
        </Tr>
    );
};
