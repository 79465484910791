import { analyticsUrl } from './helpers';
import { GTM_ID } from './constants';
import { useScriptInjection } from '@shared/hooks/use-script';

export default function GTM() {
    const {} = useScriptInjection({
        url: analyticsUrl(window, 'dataLayer', GTM_ID),
        removeOnUnmount: false,
        placeInjectionCallback: script => {
            const f = document.getElementsByTagName('script')[0];
            f?.parentNode?.insertBefore(script, f);
        },
    });

    return null;
}
