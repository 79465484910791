import React from 'react';
import {
    Button,
    ButtonProps,
    IconButton,
    IconButtonProps,
    Input,
    InputProps,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { UploadCloudIcon } from '@shared/components/icons';

const baseButtonProps = {
    as: 'label' as const,
    className: 'chakra-file-button',
    cursor: 'pointer',
    sx: { 'input:focus-visible + &': { boxShadow: 'outline' } },
};

export type FileInputProps<AsIcon extends boolean = false> = InputProps & {
    label?: string;
    asIcon?: AsIcon;
    buttonProps?: AsIcon extends true ? Partial<IconButtonProps> : ButtonProps;
};
// & (
//         | {
//               asIcon?: true;
//               buttonProps?: Partial<IconButtonProps>;
//           }
//         | {
//               asIcon?: false;
//               buttonProps?: ButtonProps;
//           }
//     );

const FileInputInner = <AsIcon extends boolean = false>(
    { id, label = 'Attach file', asIcon, buttonProps, ...props }: FileInputProps<AsIcon>,
    ref: React.ForwardedRef<HTMLInputElement>,
) => {
    const button =
        asIcon === true ? (
            <IconButton
                {...baseButtonProps}
                htmlFor={id}
                icon={<UploadCloudIcon />}
                {...buttonProps}
                aria-label={label}
            />
        ) : (
            <Button {...baseButtonProps} htmlFor={id} {...buttonProps}>
                <span>{label}</span>
            </Button>
        );

    return (
        <>
            <Input
                type="file"
                id={id}
                ref={ref}
                width=".1px"
                height=".1px"
                opacity="0"
                overflow="hidden"
                position="absolute"
                zIndex="-1"
                {...props}
            />
            {button}
        </>
    );
};

export const FileInput = forwardRef(FileInputInner) as <AsIcon extends boolean = false>(
    props: FileInputProps<AsIcon> & { ref?: React.ForwardedRef<HTMLInputElement> },
) => ReturnType<typeof FileInputInner>;
