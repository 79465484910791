import { Icon, IconProps } from '@chakra-ui/react';

export const LifeRingIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M3.05078 3.05104L6.01878 6.01904M9.98037 9.98101L12.9484 12.949M9.98037 6.01903L12.4514 3.54803M3.05078 12.949L6.01878 9.98101M15 8C15 11.866 11.866 15 8 15C4.13401 15 1 11.866 1 8C1 4.13401 4.13401 1 8 1C11.866 1 15 4.13401 15 8ZM10.8 8C10.8 9.5464 9.5464 10.8 8 10.8C6.4536 10.8 5.2 9.5464 5.2 8C5.2 6.4536 6.4536 5.2 8 5.2C9.5464 5.2 10.8 6.4536 10.8 8Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
