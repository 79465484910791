export const CollapsibleSectionStyles = {
    baseStyle: {
        headerStyle: { p: 4, justifyContent: 'space-between', cursor: 'pointer' },
        containerStyle: { layerStyle: 'defaultBox', p: 0, overflow: 'hidden' },
    },
    sizes: {
        sm: {
            headerSpacing: {
                py: 2,
            },
        },
        md: {
            headerSpacing: {
                py: 4,
            },
        },
        lg: {
            headerSpacing: {
                p: 6,
            },
        },
    },
    variants: {
        neutral: {
            headerStyle: {
                bg: 'neutral.subtlest',
                color: 'font.100',
            },
        },
        white: {
            headerStyle: {
                bg: 'white',
                color: 'font.100',
            },
        },
        primary: {
            headerStyle: {
                bg: 'primary.default',
                color: 'white',
            },
        },
        secondary: {
            headerStyle: {
                bg: 'secondary.default',
                color: 'white',
            },
        },
    },
    defaultProps: {
        size: 'md',
        variant: 'neutral',
    },
};
