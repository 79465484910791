import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, definePercentField, FieldType } from '@shared/components/forms-v2';

type ConfigArgs = {
    currencySign?: string;
};

export const getPurchaseConfig = ({ currencySign = '$' }: ConfigArgs = {}) => {
    const scheme = yup.number().min(0).default(0);
    const currencyProps = {
        min: 0,
        rightAddon: currencySign,
    };

    const naked_price = defineField({
        type: FieldType.Number,
        name: 'naked_price',
        scheme,
        props: {
            ...currencyProps,
        },
    });

    const post_production_cost = defineField({
        type: FieldType.Number,
        name: 'post_production_cost',
        scheme,
        props: {
            ...currencyProps,
        },
    });

    const priority_cost = defineField({
        type: FieldType.Number,
        name: 'priority_cost',
        scheme: yup.number().default(0),
        props: { rightAddon: currencySign },
    });

    const material_title = defineField({
        type: FieldType.Text,
        name: 'material_title',
        scheme: yup.string().ensure().trim().required(),
    });

    const count = defineField({
        type: FieldType.Number,
        name: 'count',
        scheme: yup.number().integer().min(1).default(1),
        props: {
            min: 1,
            precision: 0,
        },
    });

    const discount_rate = definePercentField({
        name: 'discount_rate',
    });

    const schema = yup.object(
        // @ts-ignore
        mapValues(
            {
                material_title,
                naked_price,
                post_production_cost,
                priority_cost,
                count,
                discount_rate,
            },
            'scheme',
        ),
    );

    const fieldsArray = [
        material_title,
        naked_price,
        post_production_cost,
        priority_cost,
        count,
        discount_rate,
    ];

    const fields = {
        material_title,
        naked_price,
        post_production_cost,
        priority_cost,
        count,
        discount_rate,
    };

    return {
        fieldsArray,
        fields,
        schema,
    };
};

export type PurchaseConfig = ReturnType<typeof getPurchaseConfig>;
export type PurchaseSchema = PurchaseConfig['schema'];
export type PurchaseFields = PurchaseConfig['fields'];
export type PurchaseFieldsUnion = keyof PurchaseConfig['fields'];
export type PurchaseFieldsArray = Array<PurchaseFields[PurchaseFieldsUnion]>;

// todo static method of FormHelper.fromFields
export const getPurchaseConfigFromFields = (fields: ReadonlyArray<PurchaseFieldsUnion>) => {
    const { fields: _fields, schema } = getPurchaseConfig();

    return {
        fields: fields.map(field => _fields[field]),
        fieldsMap: _fields,
        // @ts-ignore
        schema: schema.pick(fields),
    };
};
