// for visibility of dropdown options when collapsed box is open
export const COLLAPSE_TRANSITION_END_STYLES = { enter: { overflow: 'visible' } };

// to distinguish between the origin of an event, its side effect, or explicit manual UI changes
export enum EventOrigin {
    Effect = 'effect',
    Ui = 'ui',
}

// https://bitbucket.org/atlassian/atlassian-frontend-mirror/src/master/design-system/motion/src/utils/

export type AnimationCurve =
    | 'cubic-bezier(0.15,1,0.3,1)'
    | 'cubic-bezier(0.2,0,0,1)'
    | 'cubic-bezier(0.8,0,0,0.8)';

export const easeInOut: AnimationCurve = 'cubic-bezier(0.15,1,0.3,1)';

export const easeOut: AnimationCurve = 'cubic-bezier(0.2,0,0,1)';

export const easeIn: AnimationCurve = 'cubic-bezier(0.8,0,0,0.8)';

/**
 * Think of this as the motion equivalent of the @atlaskit/theme `grid()`.
 */
export const durationStep = 25;

export const smallDurationMs = durationStep * 4;

export const mediumDurationMs = durationStep * 14;

export const largeDurationMs = durationStep * 28;
