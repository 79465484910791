import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, definePercentField, FieldType } from '@shared/components/forms-v2';
import { Override } from '@shared/types';

type ConfigArgs = {
    currencySign?: string;
};

export const getOrderConfig = ({ currencySign = '$' }: ConfigArgs = {}) => {
    const scheme = yup.number().min(0).default(0);
    const currencyProps = {
        min: 0,
        rightAddon: currencySign,
    };

    const startup_cost = defineField({
        type: FieldType.Number,
        name: 'startup_cost',
        scheme,
        props: {
            ...currencyProps,
        },
    });

    const delivery_title = defineField({
        type: FieldType.Text,
        name: 'delivery_title',
        scheme: yup.string().ensure().trim(),
    });

    const delivery_price = defineField({
        type: FieldType.Number,
        name: 'delivery_price',
        scheme,
        props: {
            ...currencyProps,
        },
    });

    const tax = definePercentField({
        name: 'tax',
    });

    const discount_rate = definePercentField({
        name: 'discount_rate',
    });

    const remaining_amount = defineField({
        type: FieldType.Number,
        name: 'remaining_amount',
        scheme,
        props: {
            ...currencyProps,
        },
    });

    const down_payment_rate = definePercentField({
        name: 'down_payment_rate',
    });

    const name = defineField({
        type: FieldType.Text,
        name: 'name',
        scheme: yup.string().ensure().trim().required(),
        props: { label: 'First name' },
    });

    const surname = defineField({
        type: FieldType.Text,
        name: 'surname',
        scheme: yup.string().ensure().trim().required(),
        props: { label: 'Last name' },
    });

    const phone = defineField({
        type: FieldType.Text,
        name: 'phone',
        scheme: yup.string().ensure().trim().required(),
        props: { label: 'Phone' },
    });

    const email = defineField({
        type: FieldType.Text,
        name: 'email',
        scheme: yup.string().ensure().trim().email().required(),
    });

    const tracking_number = defineField({
        type: FieldType.Text,
        name: 'tracking_number',
        scheme: yup.string().ensure().trim().max(255),
    });

    const po_number = defineField({
        type: FieldType.Text,
        name: 'po_number',
        scheme: yup.string().ensure().trim().max(255),
    });

    const vat_number = defineField({
        type: FieldType.Text,
        name: 'vat_number',
        scheme: yup.string().ensure().trim().max(30),
    });

    const billing_name = defineField({
        type: FieldType.Text,
        name: 'billing_name',
        scheme: yup.string().ensure().trim().required(),
        props: { label: 'First name' },
    });

    const billing_surname = defineField({
        type: FieldType.Text,
        name: 'billing_surname',
        scheme: yup.string().ensure().trim().required(),
        props: { label: 'Last name' },
    });

    const billing_phone = defineField({
        type: FieldType.Text,
        name: 'billing_phone',
        scheme: yup.string().ensure().trim().required(),
    });

    const billing_email = defineField({
        type: FieldType.Text,
        name: 'billing_email',
        scheme: yup.string().ensure().trim().email().required(),
    });

    const schema = yup.object(
        // @ts-ignore
        mapValues(
            {
                startup_cost,
                tax,
                discount_rate,
                delivery_title,
                delivery_price,
                remaining_amount,
                down_payment_rate,
                name,
                surname,
                phone,
                email,
                billing_name,
                billing_surname,
                billing_phone,
                billing_email,
                vat_number,
                tracking_number,
                po_number,
            },
            'scheme',
        ),
    );

    const fieldsArray = [
        startup_cost,
        tax,
        discount_rate,
        delivery_title,
        delivery_price,
        remaining_amount,
        down_payment_rate,
        name,
        surname,
        phone,
        email,
        billing_name,
        billing_surname,
        billing_phone,
        billing_email,
        vat_number,
        tracking_number,
        po_number,
    ];

    const fields = {
        startup_cost,
        tax,
        discount_rate,
        delivery_title,
        delivery_price,
        remaining_amount,
        down_payment_rate,
        name,
        surname,
        phone,
        email,
        billing_name,
        billing_surname,
        billing_phone,
        billing_email,
        vat_number,
        tracking_number,
        po_number,
    };

    type _Fields = typeof fields;

    return {
        fieldsArray,
        fields: fields as {
            [Property in keyof _Fields]: Override<_Fields[Property], { name: Property }>;
        },
        schema,
    };
};

export type OrderConfig = ReturnType<typeof getOrderConfig>;
export type OrderSchema = OrderConfig['schema'];
export type OrderFields = OrderConfig['fields'];
export type OrderFieldsUnion = keyof OrderConfig['fields'];
export type OrderFieldsArray = Array<OrderFields[OrderFieldsUnion]>;

// todo static method of FormHelper.fromFields
export const getOrderConfigFromFields = (fields: ReadonlyArray<OrderFieldsUnion>) => {
    const { fields: _fields, schema } = getOrderConfig();

    return {
        fields: fields.map(field => _fields[field]),
        fieldsMap: _fields,
        // @ts-ignore
        schema: schema.pick(fields),
    };
};
