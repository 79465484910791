import { LineItem, LineItemProps } from '@shared/components/line-items';
import { GetModelPerimeterProps, getModelPerimeter } from './helpers';

// todo move to entities/models/ui

type Props = Partial<LineItemProps> & GetModelPerimeterProps;

export const ModelPerimeter = ({
    perimeter,
    units,
    convertTo,
    label = 'Perimeter',
    ...props
}: Props) => {
    const { formattedPerimeter, perimeterWithSymbol } = getModelPerimeter({
        perimeter,
        units,
        convertTo,
    });

    return formattedPerimeter ? (
        <LineItem label={label} {...props}>
            <span>{perimeterWithSymbol}</span>
        </LineItem>
    ) : null;
};
