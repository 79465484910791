import React, { createContext, useContext, useState } from 'react';

export type TransportApis = {
    pull: () => void;
};

type ContextState = {
    groups: Map<string, HTMLElement>;
    register: (id: string, container: HTMLElement) => void;
    unregister: (id: string) => void;
    swapNode: (from: string, to: string) => void;
    actives: Set<string>;
};

export const context = createContext({} as ContextState);

export const useTransports = () => useContext(context);

type Props = {
    children: React.ReactNode;
};

// group name => container
// container => parentNode

export function TransportProvider({ children }: Props) {
    const [groups, setGroups] = useState<Map<string, HTMLElement>>(new Map());
    const [actives, setActives] = useState(new Set<string>());

    const register = (id: string, container: HTMLElement) => {
        setGroups(groups => {
            if (groups.has(id)) return groups;
            else {
                groups.set(id, container);
            }
            return new Map(groups);
        });
    };

    const unregister = (id: string) => {
        setGroups(groups => {
            if (!groups.has(id)) return groups;
            else {
                groups.delete(id);
            }
            return new Map(groups);
        });
    };

    // from node contains the real content
    // to node is anchored
    const swapNode = (from: string, to: string) => {
        if (!groups.has(from) || !groups.has(to)) return;

        const toNode = groups.get(to)!;
        const fromNode = groups.get(from)!;

        if (!toNode.parentNode || !fromNode.parentNode) return;

        /**
         * replaceChild(newNode, oldNode) -> oldNode, newNode cannot be a rendered Node, you need to remove it first
         * therefore, you can create a swapNode to replace them one by one to avoid constantly updating the saved container.
         * */
        const swapNode = document.createElement('div');
        const availableFromNode = fromNode.parentNode.replaceChild(swapNode, fromNode);
        const availableToNode = toNode.parentNode.replaceChild(availableFromNode, toNode);

        swapNode.parentNode!.replaceChild(availableToNode, swapNode);

        setActives(actives => {
            actives.delete(from);
            actives.add(to);
            return new Set(actives);
        });
    };

    return (
        <context.Provider value={{ actives, groups, register, unregister, swapNode }}>
            {children}
        </context.Provider>
    );
}
