import { AxiosError } from 'axios';
import { combineEpics } from 'redux-observable';
import { filter, switchMap, withLatestFrom, of, from, catchError } from 'rxjs';
import { MaterialService } from '@services/material.service';
import { MachineMaterialsService } from '@services/machine-materials.service';
import {
    createMaterial,
    createMaterialFailure,
    createMaterialSuccess,
    fetchMachines,
    fetchMachinesFailure,
    fetchMachinesSuccess,
    fetchMaterialPresets,
    fetchMaterialPresetsFailure,
    fetchMaterialPresetsSuccess,
} from './reducers';
import { redirectRouter, triggerToast } from '@ducks/app';
import { addFocusedMaterialId } from '@ducks/machines-materials/list';
import { AppEpic } from '@app/types';
import { ResponseApiError } from '@shared/types';

const getMachines: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchMachines.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(MachineMaterialsService.init().fetchMachinesMaterials()).pipe(
                switchMap(({ data }) => {
                    return of(fetchMachinesSuccess({ data }));
                }),
                catchError(() => of(fetchMachinesFailure())),
            );
        }),
    );

const getMaterialPresets: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(fetchMaterialPresets.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(MaterialService.init().fetchMaterialPresets()).pipe(
                switchMap(({ data }) => {
                    return of(fetchMaterialPresetsSuccess({ data }));
                }),
                catchError(() => of(fetchMaterialPresetsFailure())),
            );
        }),
    );

const createMaterialEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(createMaterial.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const { name, data, redirectUrl } = action.payload;
            return from(MaterialService.init().createMaterial({ name, data })).pipe(
                switchMap(({ data }) => {
                    return of(
                        createMaterialSuccess(),
                        addFocusedMaterialId(data.id),
                        redirectRouter({
                            to: redirectUrl,
                            opts: { state: { materialId: data.id } },
                        }),
                    );
                }),
                catchError((err: AxiosError<ResponseApiError>) =>
                    of(
                        createMaterialFailure(),
                        triggerToast({
                            error: err.response?.data,
                        }),
                    ),
                ),
            );
        }),
    );

export const materialCreateEpics = combineEpics(
    getMachines,
    getMaterialPresets,
    createMaterialEpic,
);
