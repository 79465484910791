import isNil from 'lodash/isNil';
import { Bend } from '@services/df/machines-materials';
import { ModelDfmBend } from './types';

export const getModelBendLabel = (props: ModelDfmBend) => {
    const { minRadius, maxRadius, thickness, angle, length } = props;

    const parts = [
        'bend',
        isNil(minRadius) ? '' : `min radius: ${minRadius}`,
        isNil(angle) ? '' : `angle: ${angle}°`,
        isNil(length) ? '' : `length: ${length}mm`,
    ].filter(Boolean);

    return parts.join(', ');
};

export const getMaterialBendLabel = ({ min_angle, max_angle, min_length, max_length }: Bend) => {
    const parts = [
        'bend',
        `>=${min_angle}, <${max_angle} degrees`,
        `>=${min_length}, <${max_length} mm length`,
    ].filter(Boolean);

    return parts.join(', ');
};
