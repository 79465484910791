import negate from 'lodash/negate';
import orderBy from 'lodash/orderBy';
import unzip from 'lodash/unzip';
import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enhancedApi as api } from '@services/df/machines-materials';
import type { MachineGroup } from '@services/df/machines-materials/codegen';
import { RootState } from '@app/types';
import { isBelongCNC } from '@shared/utils/technology-guard';
import { TechnologyIdsUnion } from '@shared/constants';
import { createPersistReducer } from '../helpers';
import { selectTechnologies, selectCustomTechnologies, selectMachinesMaterials } from './selectors';

// todo move to modules/machines-materials/main

type MachineGroupEnhanced = MachineGroup & { technologyTitle: string; isCnc: boolean };

const isCncPredicate = (machine: MachineGroupEnhanced) => machine.isCnc;

// filters
export enum GroupFilters {
    All = 'all',
    Cnc = 'cnc',
    Am = 'am',
}

interface FiltersState {
    activeOnly: boolean;
    group: GroupFilters;
}

// sorting
type SortingKeys = 'availability' | 'process';
type SortingOrder = '' | 'desc' | 'asc';
type SortingState = Record<SortingKeys, SortingOrder>;

const SortingKeyFieldMap: Record<SortingKeys, keyof MachineGroupEnhanced> = {
    availability: 'is_iqt_only',
    process: 'technologyTitle',
};

const getNextOrderValue = (current: SortingOrder) => {
    switch (current) {
        case '':
            return 'asc';
        case 'asc':
            return 'desc';
        default:
            return '';
    }
};

// interactions
interface InteractionsState {
    focusedMachineId?: number;
    focusedMaterialId?: number;
}

const initialState = {
    filters: { activeOnly: false, group: GroupFilters.All } as FiltersState,
    sorting: {
        availability: '',
        process: '',
    } as SortingState,
    interactions: {} as InteractionsState,
};

const machinesMaterialsListSlice = createSlice({
    name: 'machinesMaterials/list',
    initialState,
    reducers: {
        // filters
        activeOnlyFilterToggled(state) {
            state.filters.activeOnly = !state.filters.activeOnly;
        },
        groupFilterChanged(state, action: PayloadAction<GroupFilters>) {
            state.filters.group = action.payload;
        },

        // sorting
        sortingKeySwitched(state, action: PayloadAction<SortingKeys>) {
            state.sorting[action.payload] = getNextOrderValue(state.sorting[action.payload]);
        },

        // interactions
        resetFocusedMachineId(state) {
            state.interactions.focusedMachineId = undefined;
        },
        resetFocusedMaterialId(state) {
            state.interactions.focusedMaterialId = undefined;
        },
        // todo wait for materials to be refactored, then delete
        addFocusedMaterialId(state, action: PayloadAction<number>) {
            state.interactions.focusedMaterialId = action.payload;
        },
    },
    extraReducers: builder => {
        builder
            .addMatcher(api.endpoints.machinesUpdate.matchFulfilled, (state, action) => {
                state.interactions.focusedMachineId = action.payload.id;
            })
            .addMatcher(api.endpoints.machinesDuplicateCreate.matchFulfilled, (state, action) => {
                state.interactions.focusedMachineId = action.payload.id;
            })
            .addMatcher(api.endpoints.materialsUpdate.matchFulfilled, (state, action) => {
                state.interactions.focusedMaterialId = action.payload.id;
            })
            .addMatcher(api.endpoints.materialsDuplicateCreate.matchFulfilled, (state, action) => {
                state.interactions.focusedMaterialId = action.payload.id;
            });
    },
});

export const {
    activeOnlyFilterToggled,
    groupFilterChanged,
    sortingKeySwitched,
    resetFocusedMachineId,
    addFocusedMaterialId,
    resetFocusedMaterialId,
} = machinesMaterialsListSlice.actions;

// selectors
export const selectMachinesMaterialsFilters = (state: RootState) =>
    state.machinesMaterials.list.filters;

export const selectMachinesMaterialsSorting = (state: RootState) =>
    state.machinesMaterials.list.sorting;

export const selectMachinesMaterialsInteractions = (state: RootState) =>
    state.machinesMaterials.list.interactions;

const defaultMachinesList: MachineGroupEnhanced[] = [];

const selectMachinesList = createSelector(
    selectMachinesMaterials,
    selectTechnologies,
    selectCustomTechnologies,
    (machines, technologies, customTechnologies) =>
        machines?.map(machine => {
            const isCnc = isBelongCNC(machine.technology as TechnologyIdsUnion);
            const technologyTitle =
                (
                    customTechnologies?.find(item => item.id === machine.custom_tech) ||
                    technologies?.find(item => item.id === machine.technology)
                )?.title ?? '';

            return {
                ...machine,
                isCnc,
                technologyTitle,
            };
        }) ?? defaultMachinesList,
);

const selectFilteredByActivityMachines = createSelector(
    selectMachinesList,
    (state: RootState) => selectMachinesMaterialsFilters(state).activeOnly,
    (machines, activeOnly) =>
        activeOnly ? machines.filter(machine => !!machine.active) : machines.slice(),
);

export const selectMachinesCounts = createSelector(selectFilteredByActivityMachines, machines => ({
    [GroupFilters.All]: machines.length,
    [GroupFilters.Cnc]: machines.filter(isCncPredicate).length,
    [GroupFilters.Am]: machines.filter(negate(isCncPredicate)).length,
}));

const selectFilteredMachinesList = createSelector(
    selectFilteredByActivityMachines,
    (state: RootState) => selectMachinesMaterialsFilters(state).group,
    (machines, group) => {
        switch (group) {
            case GroupFilters.Cnc: {
                return machines.filter(isCncPredicate);
            }
            case GroupFilters.Am: {
                return machines.filter(negate(isCncPredicate));
            }
            default: {
                return machines.slice();
            }
        }
    },
);

export const selectSortedMachinesList = createSelector(
    selectFilteredMachinesList,
    selectMachinesMaterialsSorting,
    (filteredMachines, _sorting) => {
        const sorting = Object.entries(_sorting)
            .filter(([_, order]) => order)
            .map(([key, order]) => [SortingKeyFieldMap[key as SortingKeys], order]);

        //------ This sorting is needed to give is_iqt_only more weight as a column than Process ------//
        //------ Reconsider this when adding new sorting columns ------//
        sorting.sort((a, b) => {
            if (a[0] < b[0]) return -1;
            return 1;
        });

        return sorting.length ? orderBy(filteredMachines, ...unzip(sorting)) : filteredMachines;
    },
);

// reducer
export const machinesMaterialsListReducer = createPersistReducer(machinesMaterialsListSlice, {
    whitelist: ['filters', 'sorting'],
    dependsOnCompany: true,
});
