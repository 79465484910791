import FocusLock from 'react-focus-lock';
import { PopoverContent, PopoverContentProps, PopoverCloseButton } from '@chakra-ui/react';
import { FieldStyles, FieldStylesContext } from '@shared/components/forms-v2';
import { POPOVER_FIELD_STYLES } from './constants';

export interface FormPopoverContentProps extends PopoverContentProps {
    showCloseButton?: boolean;
    styles?: FieldStyles;
}

export const FormPopoverContent = ({
    showCloseButton = false,
    styles = POPOVER_FIELD_STYLES,
    children,
    ...rest
}: FormPopoverContentProps) => {
    return (
        <PopoverContent className="chakra-popover-form__content" w="auto" {...rest}>
            <FocusLock returnFocus persistentFocus={false}>
                <FieldStylesContext.Provider value={styles}>{children}</FieldStylesContext.Provider>
                {showCloseButton && <PopoverCloseButton top={2} />}
            </FocusLock>
        </PopoverContent>
    );
};
