import { TimeoutId } from '@reduxjs/toolkit/dist/query/core/buildMiddleware/types';

/**
 * Intercom util class
 * Note: we have an intercom installed via the django app.
 * @internal
 */
export class IntercomUtils {
    /**
     * Task https://digifabster.atlassian.net/jira/software/projects/DFDEV/boards/1?assignee=70121%3Ac6a571c4-e3cb-418b-b5a5-737ab115c170&selectedIssue=DFDEV-2707
     * we can't solve the task this way https://github.com/theKashey/react-focus-lock?tab=readme-ov-file#resolving-focus-fighting
     * because we don't control the Intercom setup, so we use a declarative approach https://github.com/theKashey/focus-lock#declarative-control
     */
    static ignoreFocusLock() {
        let intervalId: TimeoutId;
        let waitForContainer = function () {
            const container = IntercomUtils.getContainer();
            if (container) {
                container.dataset.noFocusLock = 'true';
                clearInterval(intervalId);
            }
        };

        intervalId = setInterval(waitForContainer, 500);
    }

    static ignoreFocusLockOnShow() {
        const onLoad = function () {
            window.Intercom?.('onShow', function () {
                IntercomUtils.ignoreFocusLock();
            });
        };

        window.addEventListener('load', onLoad, false);
    }

    static getContainer() {
        return document.getElementById('intercom-container');
    }

    static open() {
        window.Intercom?.('show');
    }
}
