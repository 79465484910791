import { SystemStyleFunction } from '@chakra-ui/theme-tools';

const getCommonVariant: SystemStyleFunction = props => {
    const {
        theme: { textStyles },
        colorScheme,
    } = props;

    return {
        table: {
            width: '100%',
            tableLayout: 'fixed',
            borderCollapse: 'collapse',
        },
        thead: {
            tr: {
                th: {
                    pt: '0px',
                    pb: '10px',
                    pe: '0px',
                    color: `${colorScheme}.400`,
                    fontWeight: '400',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    ...textStyles['typography-md'],
                },
                'th:not(:first-of-type)': {
                    ps: '8px',
                },
                'th:first-of-type': {
                    ps: '0px',
                },
                'th:last-of-type > div > :first-child': {
                    pe: '8px',
                },
                //------ It's for more convenient change of a column size ------//
                'th > div > :last-child:hover': {
                    transform: 'scale(20, 2)',
                },
            },
        },
        tbody: {
            borderTop: '1px solid',
            borderColor: `${colorScheme}.200`,
            tr: {
                _hover: {
                    td: {
                        background: `${colorScheme}.100`,
                    },
                },
                td: {
                    pt: '20px',
                    pb: '20px',
                    pe: '0px',
                    color: 'font.100',
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    wordBreak: 'break-word',
                    ...textStyles['typography-sm'],
                },
                'td:not(:first-of-type)': {
                    ps: '8px',
                },
                'td:first-of-type': {
                    ps: '0px',
                },
            },
        },
    };
};

const getItemsVariant: SystemStyleFunction = props => {
    const {
        theme: { textStyles },
        colorScheme,
    } = props;

    return {
        table: {
            width: '100%',
            tableLayout: 'fixed',
            borderCollapse: 'separate',
            borderSpacing: '0 8px',
        },
        thead: {
            tr: {
                th: {
                    p: '0 0 10px 8px',
                    color: `${colorScheme}.400`,
                    fontWeight: '400',
                    textTransform: 'none',
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                    ...textStyles['typography-md'],
                },
            },
        },
        tbody: {
            borderTop: '1px solid',
            borderColor: `${colorScheme}.200`,
            tr: {
                _hover: {
                    td: {
                        background: `${colorScheme}.100`,
                    },
                },
                'td:first-child': {
                    borderTopLeftRadius: '12px',
                    borderBottomLeftRadius: '12px',
                    borderLeft: '1px solid',
                    borderColor: `${colorScheme}.200`,
                },
                'td:last-child': {
                    borderTopRightRadius: '12px',
                    borderBottomRightRadius: '12px',
                    borderRight: '2px solid',
                    borderColor: `${colorScheme}.200`,
                },
                td: {
                    p: '20px 0 20px 8px',
                    color: 'font.100',
                    whiteSpace: 'wrap',
                    overflow: 'hidden',
                    wordBreak: 'break-word',
                    ...textStyles['typography-sm'],
                    borderTop: '1px solid',
                    borderBottom: '1px solid',
                    borderColor: `${colorScheme}.200`,
                },
            },
        },
    };
};

const getSpreadsheetVariant: SystemStyleFunction = props => {
    const {
        theme: { textStyles },
        colorScheme,
    } = props;

    return {
        table: {
            border: 'var(--chakra-sizes-border) solid',
            borderColor: 'gray.200',
        },
        th: {
            ...textStyles['typography-xs'],
            lineHeight: '1.2',
            fontWeight: '400',
            textTransform: 'none',
        },
        td: {
            ...textStyles['typography-sm'],
        },
        tr: {
            'th, td': {
                border: 'var(--chakra-sizes-border) solid',
                borderColor: 'gray.200',
                px: '3',
                py: '2.5',
                '&:first-of-type': {
                    pl: 4,
                },
            },
            '&.spreadsheet__tr_head, &.spreadsheet__tr_foot': {
                bgColor: 'gray.100',
                'th, td': {
                    fontWeight: '600',
                },
            },
            '&.spreadsheet__tr_divider': {
                'th, td': {
                    fontWeight: '600',
                    fontSize: 'md',
                },
            },
        },
    };
};

export const TableStyles = {
    baseStyle: {},
    sizes: {},
    variants: {
        // todo refactor
        common: getCommonVariant,
        // todo remove, use common
        models: {
            table: {
                borderCollapse: 'collapse',
                whiteSpace: 'nowrap',
                width: '100%',
            },
            tbody: {
                tr: {
                    td: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                        border: 'none',
                        padding: '0px 0px 13px 0px',
                    },
                },
            },
            thead: {
                tr: {
                    th: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                        paddingTop: '8px',
                        paddingBottom: '4px',
                        color: 'gray.400',
                        fontSize: 'xs',
                        lineHeight: '12px',
                        letterSpacing: 'normal',
                        textTransform: 'none',
                        fontWeight: '400',
                    },
                },
            },
        },
        // todo remove, use common
        upload: {
            table: {
                borderCollapse: 'collapse',
                whiteSpace: 'nowrap',
                width: '100%',
            },
            tbody: {
                tr: {
                    td: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                        border: 'none',
                        padding: '0px 0px 13px 0px',
                        paddingLeft: '32px',
                    },
                },
            },
            thead: {
                tr: {
                    th: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        color: 'gray.400',
                        fontSize: 'xs',
                        lineHeight: '12px',
                        letterSpacing: 'normal',
                        textTransform: 'none',
                        fontWeight: '400',
                        paddingLeft: '32px',
                    },
                },
            },
        },
        // todo refactor, price-match prices table
        comparative: {
            table: {
                borderCollapse: 'collapse',
                whiteSpace: 'nowrap',
                width: '100%',
            },
            tbody: {
                tr: {
                    _hover: {
                        td: {
                            background: 'gray.100',
                        },
                    },
                    td: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                    },
                },
            },
            thead: {
                tr: {
                    th: {
                        paddingInlineEnd: 'unset',
                        paddingInlineStart: 'unset',
                        paddingTop: '8px',
                        paddingBottom: '8px',
                        color: 'gray.400',
                        fontSize: 'xs',
                        lineHeight: '12px',
                        letterSpacing: 'normal',
                        textTransform: 'none',
                        fontWeight: '400',
                    },
                },
            },
        },

        spreadsheet: getSpreadsheetVariant,

        items: getItemsVariant,
    },
    defaultProps: {
        colorScheme: 'gray',
    },
};
