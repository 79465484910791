import { Icon, IconProps } from '@chakra-ui/react';

export const FileIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M13 2H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2V9l-7-7z" />
        <path d="M13 3v6h6" />
    </Icon>
);
