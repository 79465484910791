import { ProductFruits } from 'react-product-fruits';
import { useAppSelector } from '@app/hooks';
import { selectUser } from '@entities';
import { ClientExtendedRepresentationDetailed } from '@services/df/clients';

//***** Product Fruits documentation: https://help.productfruits.com/en/article/installation-overview *****//

const getUserInfo = ({
    id,
    name,
    surname,
    email,
    roles,
    created_at,
}: ClientExtendedRepresentationDetailed) => {
    const role = roles?.join(',');

    return {
        username: String(id) || '', // REQUIRED - any unique user identifier
        email,
        firstname: name,
        lastname: surname,
        signUpAt: created_at,
        role,
    };
};

export function Guide() {
    const currentUser = useAppSelector(selectUser)!;
    const code = process.env.REACT_APP_PRODUCT_FRUITS_WORKSPACE_CODE;

    return code && currentUser ? (
        <ProductFruits workspaceCode={code} language="en" user={getUserInfo(currentUser)} />
    ) : null;
}
