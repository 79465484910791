import map from 'lodash/map';
import uniq from 'lodash/uniq';
import React, { ChangeEventHandler } from 'react';
import { Tbody, Thead, Th, Tr, Text, Flex, Checkbox } from '@chakra-ui/react';
import { ModelSerializerV2 } from '@services/df/models';
import { TableContainer } from '@shared/components/chakra-containers';
import { PreloaderBox } from '@shared/components/preloader';
import { ModelsItem } from './models-item';

interface Props {
    isLoading: boolean;
    allToggleAllowed: boolean;
    models?: ModelSerializerV2[];
    selectedModels: number[];
    setSelectedModels: React.Dispatch<React.SetStateAction<number[]>>;
    maxCount?: number;
}

export const ModelsLibraryTable = ({
    isLoading,
    models,
    selectedModels,
    setSelectedModels,
    allToggleAllowed,
    maxCount = 50,
}: Props) => {
    const allToggleChecked =
        Boolean(models?.length) &&
        models!.every(model => selectedModels.some(id => id === model.id));

    const handleToggleOneChange: ChangeEventHandler<HTMLInputElement> = ({
        target: { value: id, checked },
    }) => {
        if (checked) {
            setSelectedModels([...selectedModels, parseInt(id)].slice(-maxCount));
        } else {
            setSelectedModels(selectedModels.filter(_id => _id !== parseInt(id)));
        }
    };

    const handleToggleAllChange: ChangeEventHandler<HTMLInputElement> = ({
        target: { checked },
    }) => {
        if (!models) return;

        if (checked) {
            setSelectedModels(uniq([...selectedModels, ...map(models, 'id')]).slice(-maxCount));
        } else {
            setSelectedModels(selectedModels.filter(id => !models.some(model => model.id === id)));
        }
    };

    return (
        <PreloaderBox in={isLoading}>
            {Boolean(models?.length) && (
                <TableContainer variant="models" tbodyTop="20px" tbodyBottom="20px">
                    <Thead>
                        <Tr borderBottom="1px solid" borderColor="gray.100">
                            <Th verticalAlign="middle">
                                <Checkbox
                                    isChecked={allToggleChecked}
                                    isDisabled={isLoading || !allToggleAllowed}
                                    onChange={handleToggleAllChange}
                                    verticalAlign="middle"
                                />
                            </Th>
                            <Th>
                                <Text textStyle="typography-sm" color="gray.400">
                                    Model name
                                </Text>
                            </Th>
                            <Th textAlign="right">
                                <Flex align="center" justify="flex-end" h="24px">
                                    <Text textStyle="typography-sm" color="gray.400">
                                        Uploaded
                                    </Text>
                                </Flex>
                            </Th>
                        </Tr>
                    </Thead>
                    <Tbody borderBottom="1px solid" borderColor="gray.100">
                        {models!.map(model => (
                            <ModelsItem
                                key={model.id}
                                id={model.id}
                                size={model.size}
                                units={model.units}
                                title={model.title}
                                isLoading={isLoading}
                                date={model.date_created}
                                thumb={model.thumb_120x120}
                                checked={selectedModels.some(id => id === model.id)}
                                onChangeCheckbox={handleToggleOneChange}
                            />
                        ))}
                    </Tbody>
                </TableContainer>
            )}
        </PreloaderBox>
    );
};
