import { ReactNode } from 'react';
import { GridItemProps, SimpleGrid, SimpleGridProps } from '@chakra-ui/react';
import { FCC } from '@shared/types';
import { LineItemsCollapse, LineItemsCollapseProps } from '@shared/components/line-items';
import { SectionLineItemDetails } from './section-line-item-details';
import { SectionLineItemFields, SectionLineItemFieldsProps } from './section-line-item-fields';
import { SECTION_GRID_STYLES } from './constants';

type SectionLineItemDetailsProps = {
    label?: ReactNode;
    helpText?: ReactNode;
};

export type SectionLineItemProps = SectionLineItemDetailsProps &
    SectionLineItemFieldsProps & {
        leftChildren?: ReactNode | undefined;
        rightChildren?: ReactNode | undefined;
        gridProps?: GridItemProps;
    };

export const SectionLineItemGrid = ({
    columns = SECTION_GRID_STYLES.columns,
    columnGap = SECTION_GRID_STYLES.columnGap,
    rowGap = SECTION_GRID_STYLES.rowGap,
    children,
    ...grid
}: SimpleGridProps) => {
    return (
        // todo media queries + toggle visibility in search results
        <SimpleGrid
            columns={columns}
            columnGap={columnGap}
            rowGap={rowGap}
            sx={{
                '& ~ &': {
                    pt: rowGap,
                },
            }}
            {...grid}
        >
            {children}
        </SimpleGrid>
    );
};

export const SectionLineItem: FCC<SectionLineItemProps> = ({
    label: _label,
    helpText: _helpText,
    children,
    leftChildren,
    rightChildren,
    gridProps,
    ...fields
}) => {
    // @ts-ignore
    const labelFromProps = !fields?.group && fields?.props?.label;
    const label = _label || labelFromProps;

    // @ts-ignore
    const helpTextFromProps = !fields?.group && fields?.props?.helpText;
    const helpText = _helpText || helpTextFromProps;

    return (
        // grid because sometimes a line item is a group of collapsed fields, e.g.
        // one field:
        //     <SectionLineItem {...field_config} />
        // group of fields:
        //     <SectionLineItemsCollapse in={condition}>
        //         <SectionLineItem {...field_config_1} />
        //         <SectionLineItem {...field_config_2} />
        //     </SectionLineItemsCollapse>

        <SectionLineItemGrid {...gridProps}>
            <SectionLineItemDetails header={label} description={helpText}>
                {leftChildren}
            </SectionLineItemDetails>
            <SectionLineItemFields {...fields}>{rightChildren}</SectionLineItemFields>
            {children}
        </SectionLineItemGrid>
    );
};

export const SectionLineItemsCollapse = ({
    pt = SECTION_GRID_STYLES.rowGap,
    ...rest
}: LineItemsCollapseProps) => <LineItemsCollapse pt={pt} {...rest} />;
