import { Icon, IconProps } from '@chakra-ui/react';

export const BoardIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M5.5 2.5H2.7C2.03726 2.5 1.5 3.03726 1.5 3.7V12.3C1.5 12.9627 2.03726 13.5 2.7 13.5H5.5M5.5 2.5V13.5M5.5 2.5H10.5M5.5 13.5H10.5M10.5 2.5H13.3C13.9627 2.5 14.5 3.03726 14.5 3.7V12.3C14.5 12.9627 13.9627 13.5 13.3 13.5H10.5M10.5 2.5V13.5"
            stroke="currentColor"
            strokeWidth="1.2"
        />
    </Icon>
);
