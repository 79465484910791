import type { ColorProps } from '@chakra-ui/styled-system';
import {
    HStack,
    Button,
    IconButton,
    IconButtonProps,
    Text,
    Tooltip,
    TooltipProps,
    useClipboard,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import {
    CheckIcon,
    CopyIcon,
    EditIcon,
    MaximizeIcon,
    MinimizeIcon,
    TrashNewIcon,
    XSquareIcon,
} from '@shared/components/icons';

interface TooltippedIconButtonProps extends Omit<IconButtonProps, 'aria-label'> {
    tooltip: string;
    withTooltip?: boolean;
    tooltipPlacement?: TooltipProps['placement'];
    tooltipVariant?: TooltipProps['variant'];
}

export const TooltippedIconButton = forwardRef<TooltippedIconButtonProps, 'button'>(
    (
        {
            id,
            withTooltip = true,
            onClick,
            tooltip,
            tooltipPlacement = 'top',
            tooltipVariant,
            ...props
        },
        ref,
    ) => {
        const button = (
            <IconButton
                ref={ref}
                size="lg"
                variant="link"
                {...props}
                id={id}
                data-testid={id}
                aria-label={tooltip}
                onClick={onClick}
            />
        );

        return withTooltip ? (
            <Tooltip label={tooltip} placement={tooltipPlacement} variant={tooltipVariant}>
                {button}
            </Tooltip>
        ) : (
            button
        );
    },
);

interface EntityButtonProps extends Omit<TooltippedIconButtonProps, 'tooltip'> {
    tooltip?: string;
}

export const EditEntityButton = forwardRef<EntityButtonProps, 'button'>(
    ({ tooltip = 'Edit', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                colorScheme="neutral"
                icon={<EditIcon />}
                tooltip={tooltip}
                {...props}
            />
        );
    },
);

export const DuplicateEntityButton = forwardRef<EntityButtonProps, 'button'>(
    ({ tooltip = 'Duplicate', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                colorScheme="neutral"
                icon={<CopyIcon />}
                tooltip={tooltip}
                {...props}
            />
        );
    },
);

export const DeleteEntityButton = forwardRef<EntityButtonProps, 'button'>(
    ({ tooltip = 'Delete', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                colorScheme="error"
                icon={<TrashNewIcon />}
                tooltip={tooltip}
                {...props}
            />
        );
    },
);

export const DeleteNestedEntityButton = forwardRef<EntityButtonProps, 'button'>(
    ({ tooltip = 'Delete', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                colorScheme="neutral"
                icon={<XSquareIcon />}
                tooltip={tooltip}
                {...props}
            />
        );
    },
);

interface FullscreenButtonProps extends Omit<TooltippedIconButtonProps, 'tooltip'> {
    tooltip?: string;
}

const FULLSCREEN_BUTTON_PROPS = {
    size: 'sm',
    variant: 'outline',
    w: '38px',
    h: '38px',
    zIndex: '2',
    color: 'black',
    backgroundColor: 'white',
    borderColor: 'gray.200',
    _hover: {
        color: 'gray.600',
        transform: 'scale(1.05)',
    },
    _active: {
        backgroundColor: 'white',
    },
};

export const FullscreenOn = forwardRef<FullscreenButtonProps, 'button'>(
    ({ tooltip = 'Click to open in fullscreen mode', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                icon={<MaximizeIcon w="18px" h="18px" />}
                tooltip={tooltip}
                tooltipVariant="inverse"
                {...FULLSCREEN_BUTTON_PROPS}
                {...props}
            />
        );
    },
);

export const FullscreenOff = forwardRef<FullscreenButtonProps, 'button'>(
    ({ tooltip = 'Click to exit fullscreen mode', ...props }, ref) => {
        return (
            <TooltippedIconButton
                ref={ref}
                icon={<MinimizeIcon w="18px" h="18px" />}
                tooltip={tooltip}
                tooltipVariant="inverse"
                {...FULLSCREEN_BUTTON_PROPS}
                {...props}
            />
        );
    },
);

type ClipboardButtonProps = Partial<ReturnType<typeof useClipboard>> &
    Omit<IconButtonProps, 'aria-label'> & {
        asIcon?: boolean;
        label?: string;
        iconColor?: ColorProps['color'];
    };

export const ClipboardButton = forwardRef<ClipboardButtonProps, 'button'>(
    (
        { asIcon = true, iconColor, onCopy, value, hasCopied, label: _label, children, ...props },
        ref,
    ) => {
        const label = _label ?? 'Copy to clipboard';
        const resultLabel = 'Copied';
        const icon = <CopyIcon color={iconColor} />;

        const baseProps = {
            ref,
            onClick: onCopy,
            variant: 'link',
            colorScheme: 'neutral',
        };

        const button = asIcon ? (
            <IconButton
                {...baseProps}
                icon={icon}
                aria-label={hasCopied ? resultLabel : label}
                {...props}
            />
        ) : (
            <Button {...baseProps} rightIcon={icon} {...props}>
                <Text as="span" overflow="hidden" textOverflow="ellipsis">
                    {children}
                </Text>
            </Button>
        );

        return (
            <Tooltip
                maxW="400px"
                label={
                    hasCopied ? (
                        <HStack spacing={1}>
                            <CheckIcon />
                            <Text>{resultLabel}</Text>
                        </HStack>
                    ) : (
                        label
                    )
                }
                isOpen={hasCopied ? true : undefined}
                placement="top"
                hasArrow
            >
                {button}
            </Tooltip>
        );
    },
);
