export const DEFAULT_MIN_FILE_SIZE = 1;
export const DEFAULT_MAX_FILE_SIZE = 1024 * 1024 * 50;
export const DEFAULT_MAX_FILES_QUANTITY = 10;

export const MODEL_EXTENSIONS_BY_FORMAT = {
    stl: ['.stl'],
    obj: ['.obj'],
    wrl: ['.wrl'],
    step: ['.step', '.stp'],
    iges: ['.iges', '.igs'],
    '3mf': ['.3mf'],
    dxf: ['.dxf'],
    zip: ['.zip'],
};

//----- Added temporarily because we don't have the capability to use dxf-files in price matching ------//
export const EXCLUDED_MODEL_EXTENSIONS = ['.dxf'];

export const ACCEPTED_MODEL_EXTENSIONS = Object.values(MODEL_EXTENSIONS_BY_FORMAT)
    .flat()
    .filter(ext => !EXCLUDED_MODEL_EXTENSIONS.includes(ext));

export const IMAGE_EXTENSION_BY_FORMAT = {
    jpeg: ['.jpg', '.jpeg'],
    png: ['.png'],
    svg: ['.svg'],
    gif: ['.gif'],
};

export const ACCEPTED_IMAGE_EXTENSIONS = Object.values(IMAGE_EXTENSION_BY_FORMAT).flat();
