import { Table } from '@chakra-ui/react';
import styled from '@emotion/styled';

export const TableContainer = styled(({ tbodyTop, tbodyBottom, ...props }: any) => (
    <Table {...props} />
))`
    tbody tr:first-of-type td {
        padding-top: ${props => props.tbodyTop};
    }
    tbody tr:last-of-type td {
        padding-bottom: ${props => props.tbodyBottom};
    }
`;
