import { SystemStyleInterpolation } from '@chakra-ui/theme-tools';
import { InputStyles } from './input-styles';

const variants: Record<string, SystemStyleInterpolation> = {
    outline: props => InputStyles.variants.outline(props).field ?? {},
};

export const TextareaStyles = {
    baseStyle: {},
    sizes: {
        md: InputStyles.sizes.md.field ?? {},
    },
    variants,
    defaultProps: {},
};
