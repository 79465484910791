import { ComponentPropsWithoutRef } from 'react';
import { Heading } from '@chakra-ui/react';

interface Props extends ComponentPropsWithoutRef<typeof Heading> {
    title: string;
}

export function SectionHeading({ title, ...props }: Props) {
    return (
        <Heading as="h4" size="md" className="chakra-section__heading" {...props}>
            {title}
        </Heading>
    );
}
