import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        backofficeSettingsRetrieve: build.query<
            BackofficeSettingsRetrieveApiResponse,
            BackofficeSettingsRetrieveApiArg
        >({
            query: () => ({ url: `/v2/backoffice/settings/` }),
        }),
        billingCompanyStripeCustomerPortalRetrieve: build.query<
            BillingCompanyStripeCustomerPortalRetrieveApiResponse,
            BillingCompanyStripeCustomerPortalRetrieveApiArg
        >({
            query: () => ({ url: `/v2/billing/company/stripe_customer_portal/` }),
        }),
        billingCompanySubscriptionsList: build.query<
            BillingCompanySubscriptionsListApiResponse,
            BillingCompanySubscriptionsListApiArg
        >({
            query: () => ({ url: `/v2/billing/company/subscriptions/` }),
        }),
        companyRetrieve: build.query<CompanyRetrieveApiResponse, CompanyRetrieveApiArg>({
            query: () => ({ url: `/v2/company/` }),
        }),
        companyPartialUpdate: build.mutation<
            CompanyPartialUpdateApiResponse,
            CompanyPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/`,
                method: 'PATCH',
                body: queryArg.patchedEditCompanyCrmRequest,
            }),
        }),
        companyAddressRetrieve: build.query<
            CompanyAddressRetrieveApiResponse,
            CompanyAddressRetrieveApiArg
        >({
            query: () => ({ url: `/v2/company/address/` }),
        }),
        companyAddressPartialUpdate: build.mutation<
            CompanyAddressPartialUpdateApiResponse,
            CompanyAddressPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/address/`,
                method: 'PATCH',
                body: queryArg.patchedCompanyAddressCrmRequest,
            }),
        }),
        companyCustomEmailsRetrieve: build.query<
            CompanyCustomEmailsRetrieveApiResponse,
            CompanyCustomEmailsRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/custom_emails/`,
                params: { email_type: queryArg.emailType, lang_code: queryArg.langCode },
            }),
        }),
        companyCustomEmailsCreate: build.mutation<
            CompanyCustomEmailsCreateApiResponse,
            CompanyCustomEmailsCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/custom_emails/`,
                method: 'POST',
                body: queryArg.customEmailCreateRequest,
            }),
        }),
        companyCustomEmailsDestroy: build.mutation<
            CompanyCustomEmailsDestroyApiResponse,
            CompanyCustomEmailsDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/custom_emails/`,
                method: 'DELETE',
                params: { email_type: queryArg.emailType, lang_code: queryArg.langCode },
            }),
        }),
        companyCustomInvoiceHtmlRetrieve: build.query<
            CompanyCustomInvoiceHtmlRetrieveApiResponse,
            CompanyCustomInvoiceHtmlRetrieveApiArg
        >({
            query: () => ({ url: `/v2/company/custom_invoice_html/` }),
        }),
        companyCustomInvoiceHtmlCreate: build.mutation<
            CompanyCustomInvoiceHtmlCreateApiResponse,
            CompanyCustomInvoiceHtmlCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/custom_invoice_html/`,
                method: 'POST',
                body: queryArg.customInvoiceCreateRequest,
            }),
        }),
        companyCustomPackageListHtmlRetrieve: build.query<
            CompanyCustomPackageListHtmlRetrieveApiResponse,
            CompanyCustomPackageListHtmlRetrieveApiArg
        >({
            query: () => ({ url: `/v2/company/custom_package_list_html/` }),
        }),
        companyCustomPackageListHtmlCreate: build.mutation<
            CompanyCustomPackageListHtmlCreateApiResponse,
            CompanyCustomPackageListHtmlCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/custom_package_list_html/`,
                method: 'POST',
                body: queryArg.customPackageListCreateRequest,
            }),
        }),
        companyDeliveryCarriersList: build.query<
            CompanyDeliveryCarriersListApiResponse,
            CompanyDeliveryCarriersListApiArg
        >({
            query: () => ({ url: `/v2/company/delivery/carriers/` }),
        }),
        companyDeliveryCustomShippingList: build.query<
            CompanyDeliveryCustomShippingListApiResponse,
            CompanyDeliveryCustomShippingListApiArg
        >({
            query: () => ({ url: `/v2/company/delivery/custom_shipping/` }),
        }),
        companyDeliveryCustomShippingCreate: build.mutation<
            CompanyDeliveryCustomShippingCreateApiResponse,
            CompanyDeliveryCustomShippingCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/delivery/custom_shipping/`,
                method: 'POST',
                body: queryArg.companyCustomShippingRequest,
            }),
        }),
        companyDeliveryCustomShippingRetrieve: build.query<
            CompanyDeliveryCustomShippingRetrieveApiResponse,
            CompanyDeliveryCustomShippingRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/company/delivery/custom_shipping/${queryArg.id}/` }),
        }),
        companyDeliveryCustomShippingPartialUpdate: build.mutation<
            CompanyDeliveryCustomShippingPartialUpdateApiResponse,
            CompanyDeliveryCustomShippingPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/delivery/custom_shipping/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedCompanyCustomShippingRequest,
            }),
        }),
        companyDeliveryCustomShippingDestroy: build.mutation<
            CompanyDeliveryCustomShippingDestroyApiResponse,
            CompanyDeliveryCustomShippingDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/delivery/custom_shipping/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        companyExtendTrialPartialUpdate: build.mutation<
            CompanyExtendTrialPartialUpdateApiResponse,
            CompanyExtendTrialPartialUpdateApiArg
        >({
            query: () => ({ url: `/v2/company/extend_trial/`, method: 'PATCH' }),
        }),
        companyIntegrationsList: build.query<
            CompanyIntegrationsListApiResponse,
            CompanyIntegrationsListApiArg
        >({
            query: () => ({ url: `/v2/company/integrations/` }),
        }),
        companyIntegrationsDestroy: build.mutation<
            CompanyIntegrationsDestroyApiResponse,
            CompanyIntegrationsDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/integrations/${queryArg.slug}/`,
                method: 'DELETE',
            }),
        }),
        companyVerifiedIdentityList: build.query<
            CompanyVerifiedIdentityListApiResponse,
            CompanyVerifiedIdentityListApiArg
        >({
            query: () => ({ url: `/v2/company/verified_identity/` }),
        }),
        companyVerifiedIdentityCreate: build.mutation<
            CompanyVerifiedIdentityCreateApiResponse,
            CompanyVerifiedIdentityCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/company/verified_identity/`,
                method: 'POST',
                body: queryArg.companySendersVerifiedIdentityRequestRequest,
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type BackofficeSettingsRetrieveApiResponse = /** status 200  */ BackofficeAppSettings;
export type BackofficeSettingsRetrieveApiArg = void;
export type BillingCompanyStripeCustomerPortalRetrieveApiResponse =
    /** status 200  */ StripeCustomerPortal;
export type BillingCompanyStripeCustomerPortalRetrieveApiArg = void;
export type BillingCompanySubscriptionsListApiResponse = /** status 200  */ Subscription[];
export type BillingCompanySubscriptionsListApiArg = void;
export type CompanyRetrieveApiResponse = /** status 200  */ CompanyCrm;
export type CompanyRetrieveApiArg = void;
export type CompanyPartialUpdateApiResponse = /** status 200  */ CompanyCrm;
export type CompanyPartialUpdateApiArg = {
    patchedEditCompanyCrmRequest: PatchedEditCompanyCrmRequest;
};
export type CompanyAddressRetrieveApiResponse = /** status 200  */ CompanyAddressCrm;
export type CompanyAddressRetrieveApiArg = void;
export type CompanyAddressPartialUpdateApiResponse = /** status 200  */ CompanyAddressCrm;
export type CompanyAddressPartialUpdateApiArg = {
    patchedCompanyAddressCrmRequest: PatchedCompanyAddressCrmRequest;
};
export type CompanyCustomEmailsRetrieveApiResponse = /** status 200  */ CustomEmailRetrieveResponse;
export type CompanyCustomEmailsRetrieveApiArg = {
    emailType:
        | 'invoice_notification'
        | 'magic_link_login_signup'
        | 'new_order'
        | 'new_order_inhouse'
        | 'new_submittal'
        | 'order_details_changed'
        | 'order_notification'
        | 'order_payment_due_date_changed'
        | 'order_payment_due_in_2_days'
        | 'order_poll'
        | 'password_recover'
        | 'payment_received'
        | 'price_correction'
        | 'registration_complete'
        | 'user_order_status';
    langCode:
        | 'ar'
        | 'cs'
        | 'da'
        | 'de'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'es'
        | 'fr'
        | 'he'
        | 'hr'
        | 'hu'
        | 'it'
        | 'ko'
        | 'nl'
        | 'no'
        | 'pl'
        | 'ru'
        | 'sl'
        | 'sr'
        | 'sv'
        | 'th'
        | 'tr'
        | 'uk'
        | 'zh-hans'
        | 'zh-hk'
        | 'zh-tw';
};
export type CompanyCustomEmailsCreateApiResponse = /** status 200  */ CustomEmailCreate;
export type CompanyCustomEmailsCreateApiArg = {
    customEmailCreateRequest: CustomEmailCreateRequest;
};
export type CompanyCustomEmailsDestroyApiResponse = /** status 200  */ CustomEmailRetrieveResponse;
export type CompanyCustomEmailsDestroyApiArg = {
    emailType:
        | 'invoice_notification'
        | 'magic_link_login_signup'
        | 'new_order'
        | 'new_order_inhouse'
        | 'new_submittal'
        | 'order_details_changed'
        | 'order_notification'
        | 'order_payment_due_date_changed'
        | 'order_payment_due_in_2_days'
        | 'order_poll'
        | 'password_recover'
        | 'payment_received'
        | 'price_correction'
        | 'registration_complete'
        | 'user_order_status';
    langCode:
        | 'ar'
        | 'cs'
        | 'da'
        | 'de'
        | 'en'
        | 'en-au'
        | 'en-gb'
        | 'es'
        | 'fr'
        | 'he'
        | 'hr'
        | 'hu'
        | 'it'
        | 'ko'
        | 'nl'
        | 'no'
        | 'pl'
        | 'ru'
        | 'sl'
        | 'sr'
        | 'sv'
        | 'th'
        | 'tr'
        | 'uk'
        | 'zh-hans'
        | 'zh-hk'
        | 'zh-tw';
};
export type CompanyCustomInvoiceHtmlRetrieveApiResponse =
    /** status 200  */ CustomInvoiceRetrieveResponse;
export type CompanyCustomInvoiceHtmlRetrieveApiArg = void;
export type CompanyCustomInvoiceHtmlCreateApiResponse = /** status 200  */ CustomInvoiceCreate;
export type CompanyCustomInvoiceHtmlCreateApiArg = {
    customInvoiceCreateRequest: CustomInvoiceCreateRequest;
};
export type CompanyCustomPackageListHtmlRetrieveApiResponse =
    /** status 200  */ CustomPackageListRetrieveResponse;
export type CompanyCustomPackageListHtmlRetrieveApiArg = void;
export type CompanyCustomPackageListHtmlCreateApiResponse =
    /** status 200  */ CustomPackageListCreate;
export type CompanyCustomPackageListHtmlCreateApiArg = {
    customPackageListCreateRequest: CustomPackageListCreateRequest;
};
export type CompanyDeliveryCarriersListApiResponse = /** status 200  */ DeliveryCarrier[];
export type CompanyDeliveryCarriersListApiArg = void;
export type CompanyDeliveryCustomShippingListApiResponse =
    /** status 200  */ CompanyCustomShipping[];
export type CompanyDeliveryCustomShippingListApiArg = void;
export type CompanyDeliveryCustomShippingCreateApiResponse =
    /** status 201  */ CompanyCustomShipping;
export type CompanyDeliveryCustomShippingCreateApiArg = {
    companyCustomShippingRequest: CompanyCustomShippingRequest;
};
export type CompanyDeliveryCustomShippingRetrieveApiResponse =
    /** status 200  */ CompanyCustomShipping;
export type CompanyDeliveryCustomShippingRetrieveApiArg = {
    id: number;
};
export type CompanyDeliveryCustomShippingPartialUpdateApiResponse =
    /** status 200  */ CompanyCustomShipping;
export type CompanyDeliveryCustomShippingPartialUpdateApiArg = {
    id: number;
    patchedCompanyCustomShippingRequest: PatchedCompanyCustomShippingRequest;
};
export type CompanyDeliveryCustomShippingDestroyApiResponse = unknown;
export type CompanyDeliveryCustomShippingDestroyApiArg = {
    id: number;
};
export type CompanyExtendTrialPartialUpdateApiResponse = /** status 200  */ CompanyExtendTrial;
export type CompanyExtendTrialPartialUpdateApiArg = void;
export type CompanyIntegrationsListApiResponse = /** status 200  */ Integration[];
export type CompanyIntegrationsListApiArg = void;
export type CompanyIntegrationsDestroyApiResponse = unknown;
export type CompanyIntegrationsDestroyApiArg = {
    slug: string;
};
export type CompanyVerifiedIdentityListApiResponse =
    /** status 200  */ CompanySendersVerifiedIdentity[];
export type CompanyVerifiedIdentityListApiArg = void;
export type CompanyVerifiedIdentityCreateApiResponse =
    /** status 201  */ CompanySendersVerifiedIdentity;
export type CompanyVerifiedIdentityCreateApiArg = {
    companySendersVerifiedIdentityRequestRequest: CompanySendersVerifiedIdentityRequestRequest;
};
export type TimezoneEnum =
    | 'Africa/Abidjan'
    | 'Africa/Accra'
    | 'Africa/Addis_Ababa'
    | 'Africa/Algiers'
    | 'Africa/Asmara'
    | 'Africa/Bamako'
    | 'Africa/Bangui'
    | 'Africa/Banjul'
    | 'Africa/Bissau'
    | 'Africa/Blantyre'
    | 'Africa/Brazzaville'
    | 'Africa/Bujumbura'
    | 'Africa/Cairo'
    | 'Africa/Casablanca'
    | 'Africa/Ceuta'
    | 'Africa/Conakry'
    | 'Africa/Dakar'
    | 'Africa/Dar_es_Salaam'
    | 'Africa/Djibouti'
    | 'Africa/Douala'
    | 'Africa/El_Aaiun'
    | 'Africa/Freetown'
    | 'Africa/Gaborone'
    | 'Africa/Harare'
    | 'Africa/Johannesburg'
    | 'Africa/Juba'
    | 'Africa/Kampala'
    | 'Africa/Khartoum'
    | 'Africa/Kigali'
    | 'Africa/Kinshasa'
    | 'Africa/Lagos'
    | 'Africa/Libreville'
    | 'Africa/Lome'
    | 'Africa/Luanda'
    | 'Africa/Lubumbashi'
    | 'Africa/Lusaka'
    | 'Africa/Malabo'
    | 'Africa/Maputo'
    | 'Africa/Maseru'
    | 'Africa/Mbabane'
    | 'Africa/Mogadishu'
    | 'Africa/Monrovia'
    | 'Africa/Nairobi'
    | 'Africa/Ndjamena'
    | 'Africa/Niamey'
    | 'Africa/Nouakchott'
    | 'Africa/Ouagadougou'
    | 'Africa/Porto-Novo'
    | 'Africa/Sao_Tome'
    | 'Africa/Tripoli'
    | 'Africa/Tunis'
    | 'Africa/Windhoek'
    | 'America/Adak'
    | 'America/Anchorage'
    | 'America/Anguilla'
    | 'America/Antigua'
    | 'America/Araguaina'
    | 'America/Argentina/Buenos_Aires'
    | 'America/Argentina/Catamarca'
    | 'America/Argentina/Cordoba'
    | 'America/Argentina/Jujuy'
    | 'America/Argentina/La_Rioja'
    | 'America/Argentina/Mendoza'
    | 'America/Argentina/Rio_Gallegos'
    | 'America/Argentina/Salta'
    | 'America/Argentina/San_Juan'
    | 'America/Argentina/San_Luis'
    | 'America/Argentina/Tucuman'
    | 'America/Argentina/Ushuaia'
    | 'America/Aruba'
    | 'America/Asuncion'
    | 'America/Atikokan'
    | 'America/Bahia'
    | 'America/Bahia_Banderas'
    | 'America/Barbados'
    | 'America/Belem'
    | 'America/Belize'
    | 'America/Blanc-Sablon'
    | 'America/Boa_Vista'
    | 'America/Bogota'
    | 'America/Boise'
    | 'America/Cambridge_Bay'
    | 'America/Campo_Grande'
    | 'America/Cancun'
    | 'America/Caracas'
    | 'America/Cayenne'
    | 'America/Cayman'
    | 'America/Chicago'
    | 'America/Chihuahua'
    | 'America/Ciudad_Juarez'
    | 'America/Costa_Rica'
    | 'America/Creston'
    | 'America/Cuiaba'
    | 'America/Curacao'
    | 'America/Danmarkshavn'
    | 'America/Dawson'
    | 'America/Dawson_Creek'
    | 'America/Denver'
    | 'America/Detroit'
    | 'America/Dominica'
    | 'America/Edmonton'
    | 'America/Eirunepe'
    | 'America/El_Salvador'
    | 'America/Fort_Nelson'
    | 'America/Fortaleza'
    | 'America/Glace_Bay'
    | 'America/Goose_Bay'
    | 'America/Grand_Turk'
    | 'America/Grenada'
    | 'America/Guadeloupe'
    | 'America/Guatemala'
    | 'America/Guayaquil'
    | 'America/Guyana'
    | 'America/Halifax'
    | 'America/Havana'
    | 'America/Hermosillo'
    | 'America/Indiana/Indianapolis'
    | 'America/Indiana/Knox'
    | 'America/Indiana/Marengo'
    | 'America/Indiana/Petersburg'
    | 'America/Indiana/Tell_City'
    | 'America/Indiana/Vevay'
    | 'America/Indiana/Vincennes'
    | 'America/Indiana/Winamac'
    | 'America/Inuvik'
    | 'America/Iqaluit'
    | 'America/Jamaica'
    | 'America/Juneau'
    | 'America/Kentucky/Louisville'
    | 'America/Kentucky/Monticello'
    | 'America/Kralendijk'
    | 'America/La_Paz'
    | 'America/Lima'
    | 'America/Los_Angeles'
    | 'America/Lower_Princes'
    | 'America/Maceio'
    | 'America/Managua'
    | 'America/Manaus'
    | 'America/Marigot'
    | 'America/Martinique'
    | 'America/Matamoros'
    | 'America/Mazatlan'
    | 'America/Menominee'
    | 'America/Merida'
    | 'America/Metlakatla'
    | 'America/Mexico_City'
    | 'America/Miquelon'
    | 'America/Moncton'
    | 'America/Monterrey'
    | 'America/Montevideo'
    | 'America/Montserrat'
    | 'America/Nassau'
    | 'America/New_York'
    | 'America/Nome'
    | 'America/Noronha'
    | 'America/North_Dakota/Beulah'
    | 'America/North_Dakota/Center'
    | 'America/North_Dakota/New_Salem'
    | 'America/Nuuk'
    | 'America/Ojinaga'
    | 'America/Panama'
    | 'America/Paramaribo'
    | 'America/Phoenix'
    | 'America/Port-au-Prince'
    | 'America/Port_of_Spain'
    | 'America/Porto_Velho'
    | 'America/Puerto_Rico'
    | 'America/Punta_Arenas'
    | 'America/Rankin_Inlet'
    | 'America/Recife'
    | 'America/Regina'
    | 'America/Resolute'
    | 'America/Rio_Branco'
    | 'America/Santarem'
    | 'America/Santiago'
    | 'America/Santo_Domingo'
    | 'America/Sao_Paulo'
    | 'America/Scoresbysund'
    | 'America/Sitka'
    | 'America/St_Barthelemy'
    | 'America/St_Johns'
    | 'America/St_Kitts'
    | 'America/St_Lucia'
    | 'America/St_Thomas'
    | 'America/St_Vincent'
    | 'America/Swift_Current'
    | 'America/Tegucigalpa'
    | 'America/Thule'
    | 'America/Tijuana'
    | 'America/Toronto'
    | 'America/Tortola'
    | 'America/Vancouver'
    | 'America/Whitehorse'
    | 'America/Winnipeg'
    | 'America/Yakutat'
    | 'Antarctica/Casey'
    | 'Antarctica/Davis'
    | 'Antarctica/DumontDUrville'
    | 'Antarctica/Macquarie'
    | 'Antarctica/Mawson'
    | 'Antarctica/McMurdo'
    | 'Antarctica/Palmer'
    | 'Antarctica/Rothera'
    | 'Antarctica/Syowa'
    | 'Antarctica/Troll'
    | 'Antarctica/Vostok'
    | 'Arctic/Longyearbyen'
    | 'Asia/Aden'
    | 'Asia/Almaty'
    | 'Asia/Amman'
    | 'Asia/Anadyr'
    | 'Asia/Aqtau'
    | 'Asia/Aqtobe'
    | 'Asia/Ashgabat'
    | 'Asia/Atyrau'
    | 'Asia/Baghdad'
    | 'Asia/Bahrain'
    | 'Asia/Baku'
    | 'Asia/Bangkok'
    | 'Asia/Barnaul'
    | 'Asia/Beirut'
    | 'Asia/Bishkek'
    | 'Asia/Brunei'
    | 'Asia/Chita'
    | 'Asia/Choibalsan'
    | 'Asia/Colombo'
    | 'Asia/Damascus'
    | 'Asia/Dhaka'
    | 'Asia/Dili'
    | 'Asia/Dubai'
    | 'Asia/Dushanbe'
    | 'Asia/Famagusta'
    | 'Asia/Gaza'
    | 'Asia/Hebron'
    | 'Asia/Ho_Chi_Minh'
    | 'Asia/Hong_Kong'
    | 'Asia/Hovd'
    | 'Asia/Irkutsk'
    | 'Asia/Jakarta'
    | 'Asia/Jayapura'
    | 'Asia/Jerusalem'
    | 'Asia/Kabul'
    | 'Asia/Kamchatka'
    | 'Asia/Karachi'
    | 'Asia/Kathmandu'
    | 'Asia/Khandyga'
    | 'Asia/Kolkata'
    | 'Asia/Krasnoyarsk'
    | 'Asia/Kuala_Lumpur'
    | 'Asia/Kuching'
    | 'Asia/Kuwait'
    | 'Asia/Macau'
    | 'Asia/Magadan'
    | 'Asia/Makassar'
    | 'Asia/Manila'
    | 'Asia/Muscat'
    | 'Asia/Nicosia'
    | 'Asia/Novokuznetsk'
    | 'Asia/Novosibirsk'
    | 'Asia/Omsk'
    | 'Asia/Oral'
    | 'Asia/Phnom_Penh'
    | 'Asia/Pontianak'
    | 'Asia/Pyongyang'
    | 'Asia/Qatar'
    | 'Asia/Qostanay'
    | 'Asia/Qyzylorda'
    | 'Asia/Riyadh'
    | 'Asia/Sakhalin'
    | 'Asia/Samarkand'
    | 'Asia/Seoul'
    | 'Asia/Shanghai'
    | 'Asia/Singapore'
    | 'Asia/Srednekolymsk'
    | 'Asia/Taipei'
    | 'Asia/Tashkent'
    | 'Asia/Tbilisi'
    | 'Asia/Tehran'
    | 'Asia/Thimphu'
    | 'Asia/Tokyo'
    | 'Asia/Tomsk'
    | 'Asia/Ulaanbaatar'
    | 'Asia/Urumqi'
    | 'Asia/Ust-Nera'
    | 'Asia/Vientiane'
    | 'Asia/Vladivostok'
    | 'Asia/Yakutsk'
    | 'Asia/Yangon'
    | 'Asia/Yekaterinburg'
    | 'Asia/Yerevan'
    | 'Atlantic/Azores'
    | 'Atlantic/Bermuda'
    | 'Atlantic/Canary'
    | 'Atlantic/Cape_Verde'
    | 'Atlantic/Faroe'
    | 'Atlantic/Madeira'
    | 'Atlantic/Reykjavik'
    | 'Atlantic/South_Georgia'
    | 'Atlantic/St_Helena'
    | 'Atlantic/Stanley'
    | 'Australia/Adelaide'
    | 'Australia/Brisbane'
    | 'Australia/Broken_Hill'
    | 'Australia/Darwin'
    | 'Australia/Eucla'
    | 'Australia/Hobart'
    | 'Australia/Lindeman'
    | 'Australia/Lord_Howe'
    | 'Australia/Melbourne'
    | 'Australia/Perth'
    | 'Australia/Sydney'
    | 'Canada/Atlantic'
    | 'Canada/Central'
    | 'Canada/Eastern'
    | 'Canada/Mountain'
    | 'Canada/Newfoundland'
    | 'Canada/Pacific'
    | 'Europe/Amsterdam'
    | 'Europe/Andorra'
    | 'Europe/Astrakhan'
    | 'Europe/Athens'
    | 'Europe/Belgrade'
    | 'Europe/Berlin'
    | 'Europe/Bratislava'
    | 'Europe/Brussels'
    | 'Europe/Bucharest'
    | 'Europe/Budapest'
    | 'Europe/Busingen'
    | 'Europe/Chisinau'
    | 'Europe/Copenhagen'
    | 'Europe/Dublin'
    | 'Europe/Gibraltar'
    | 'Europe/Guernsey'
    | 'Europe/Helsinki'
    | 'Europe/Isle_of_Man'
    | 'Europe/Istanbul'
    | 'Europe/Jersey'
    | 'Europe/Kaliningrad'
    | 'Europe/Kirov'
    | 'Europe/Kyiv'
    | 'Europe/Lisbon'
    | 'Europe/Ljubljana'
    | 'Europe/London'
    | 'Europe/Luxembourg'
    | 'Europe/Madrid'
    | 'Europe/Malta'
    | 'Europe/Mariehamn'
    | 'Europe/Minsk'
    | 'Europe/Monaco'
    | 'Europe/Moscow'
    | 'Europe/Oslo'
    | 'Europe/Paris'
    | 'Europe/Podgorica'
    | 'Europe/Prague'
    | 'Europe/Riga'
    | 'Europe/Rome'
    | 'Europe/Samara'
    | 'Europe/San_Marino'
    | 'Europe/Sarajevo'
    | 'Europe/Saratov'
    | 'Europe/Simferopol'
    | 'Europe/Skopje'
    | 'Europe/Sofia'
    | 'Europe/Stockholm'
    | 'Europe/Tallinn'
    | 'Europe/Tirane'
    | 'Europe/Ulyanovsk'
    | 'Europe/Vaduz'
    | 'Europe/Vatican'
    | 'Europe/Vienna'
    | 'Europe/Vilnius'
    | 'Europe/Volgograd'
    | 'Europe/Warsaw'
    | 'Europe/Zagreb'
    | 'Europe/Zurich'
    | 'GMT'
    | 'Indian/Antananarivo'
    | 'Indian/Chagos'
    | 'Indian/Christmas'
    | 'Indian/Cocos'
    | 'Indian/Comoro'
    | 'Indian/Kerguelen'
    | 'Indian/Mahe'
    | 'Indian/Maldives'
    | 'Indian/Mauritius'
    | 'Indian/Mayotte'
    | 'Indian/Reunion'
    | 'Pacific/Apia'
    | 'Pacific/Auckland'
    | 'Pacific/Bougainville'
    | 'Pacific/Chatham'
    | 'Pacific/Chuuk'
    | 'Pacific/Easter'
    | 'Pacific/Efate'
    | 'Pacific/Fakaofo'
    | 'Pacific/Fiji'
    | 'Pacific/Funafuti'
    | 'Pacific/Galapagos'
    | 'Pacific/Gambier'
    | 'Pacific/Guadalcanal'
    | 'Pacific/Guam'
    | 'Pacific/Honolulu'
    | 'Pacific/Kanton'
    | 'Pacific/Kiritimati'
    | 'Pacific/Kosrae'
    | 'Pacific/Kwajalein'
    | 'Pacific/Majuro'
    | 'Pacific/Marquesas'
    | 'Pacific/Midway'
    | 'Pacific/Nauru'
    | 'Pacific/Niue'
    | 'Pacific/Norfolk'
    | 'Pacific/Noumea'
    | 'Pacific/Pago_Pago'
    | 'Pacific/Palau'
    | 'Pacific/Pitcairn'
    | 'Pacific/Pohnpei'
    | 'Pacific/Port_Moresby'
    | 'Pacific/Rarotonga'
    | 'Pacific/Saipan'
    | 'Pacific/Tahiti'
    | 'Pacific/Tarawa'
    | 'Pacific/Tongatapu'
    | 'Pacific/Wake'
    | 'Pacific/Wallis'
    | 'US/Alaska'
    | 'US/Arizona'
    | 'US/Central'
    | 'US/Eastern'
    | 'US/Hawaii'
    | 'US/Mountain'
    | 'US/Pacific'
    | 'UTC';
export type BlankEnum = '';
export type CurrencyCodeEnum =
    | 'aed'
    | 'aud'
    | 'brl'
    | 'cad'
    | 'chf'
    | 'clp'
    | 'czk'
    | 'dkk'
    | 'eur'
    | 'gbp'
    | 'hkd'
    | 'huf'
    | 'idr'
    | 'ils'
    | 'inr'
    | 'krw'
    | 'mxn'
    | 'myr'
    | 'ngn'
    | 'nok'
    | 'nzd'
    | 'pln'
    | 'rsd'
    | 'rub'
    | 'sar'
    | 'sek'
    | 'sgd'
    | 'thb'
    | 'try'
    | 'twd'
    | 'uah'
    | 'usd'
    | 'zar';
export type NullEnum = null;
export type CurrencyFormatSignPositionEnum = 'left' | 'right';
export type CurrencyDecimalDigitsEnum = 0 | 2;
export type Currency = {
    title: CurrencyCodeEnum | NullEnum;
    symbol: string;
    position: CurrencyFormatSignPositionEnum;
    separator: string;
    decimal: string;
    decimal_digits: CurrencyDecimalDigitsEnum;
};
export type OrderStatusOption = {
    code: string;
    label: string;
    custom_label: string;
    description: string;
    group: string;
};
export type AvailableRole = {
    id: string;
    title: string;
};
export type EmailType = {
    id: string;
    title: string;
};
export type WidgetUrlTemplates = {
    iqt_upload_url: string;
    iqt_cart_url: string;
    short_iqt_order_url: string;
    widget_upload_url: string;
    widget_cart_url: string;
    widget_order_url: string;
    payment_page_url: string;
    widget_order_poll_success_url: string;
    client_password_recovery_url: string;
};
export type PricingPlanFeature = {
    id: number;
    name: string;
    code_name: string;
    tooltip?: string;
    additional_info?: string | null;
    css_class?: string | null;
    is_public?: boolean | null;
    sorting_order_number?: number;
};
export type OrderStatusEnum =
    | 'created'
    | 'initial'
    | 'waiting_for_review'
    | 'firm_offer_sent'
    | 'placed'
    | 'po_provided'
    | 'waiting_for_payment'
    | 'on_hold'
    | 'free_for_manufacturing'
    | 'planning_confirmed'
    | 'manufacturing'
    | 'post_production'
    | 're_manufacturing'
    | 'ready_for_shipping'
    | 'shipped'
    | 'ready_for_pickup'
    | 'delivered'
    | 'po_payment_due'
    | 'cancelled_by_user'
    | 'cancelled_by_admin';
export type KanbanDashboardGroup = {
    title: string;
    statuses: OrderStatusEnum[];
};
export type KanbanDashboardCard = {
    order_fields: string[];
};
export type KanbanDashboardConfig = {
    groups?: KanbanDashboardGroup[];
    card?: KanbanDashboardCard;
};
export type BackofficeAppSettings = {
    allowed_extensions: string[];
    allowed_extensions_beauty: string[];
    model_size_limit_in_bytes: number;
    company_title: string;
    company_full_title: string;
    locale: string;
    timezone?: TimezoneEnum | BlankEnum;
    currency: Currency;
    tags?: string[];
    order_status_options: OrderStatusOption[];
    roles: string[];
    available_roles: AvailableRole[];
    logo_url: string | null;
    stripe_public_key: string;
    stripe_account_id: string;
    stripe_customer_id?: string;
    tbd_label: string;
    tax_name?: string;
    tax_number_name?: string;
    email_types: EmailType[];
    custom_pdf_css?: string | null;
    widget_site_url: string;
    widget_url_templates: WidgetUrlTemplates;
    lang_codes: {
        [key: string]: any;
    }[];
    features: PricingPlanFeature[];
    default_boundaries: number[];
    is_widget_blocked: boolean;
    is_blocked: boolean;
    is_deactivated: boolean;
    trials_left: number;
    auto_quote_email: EmailType;
    kanban_dashboard_config: KanbanDashboardConfig;
    analytics_dashboard_url: string;
};
export type StripeCustomerPortal = {
    portal_url: string;
};
export type SubscriptionProduct = {
    title: string;
    recurring_interval: string;
    currency: string;
    amount: number;
};
export type Subscription = {
    id: number;
    external_id: string;
    status: string;
    current_period_start?: string | null;
    current_period_end?: string | null;
    products: SubscriptionProduct[];
    created_at: string;
    updated_at: string;
};
export type CustomInvoiceField = {
    id: string;
    name: string;
    value: string;
};
export type CompanyData = {
    custom_fields?: CustomInvoiceField[];
    trade_name?: string;
    legal_name?: string;
    legal_form?: string;
    legal_address?: string;
    registration_number?: string;
    id_number?: string;
};
export type PaymentDetails = {
    custom_fields?: CustomInvoiceField[];
    payment_instructions?: string;
    html?: string;
};
export type CustomTermsField = {
    id: string;
    clients: string;
    text: string;
};
export type CompanyCustomTerms = {
    custom_terms?: CustomTermsField[];
};
export type CompanyMainBankData = {
    custom_fields?: CustomInvoiceField[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type CompanySecondBankData = {
    custom_fields?: CustomInvoiceField[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type CompanyCorrespondentBankData = {
    custom_fields?: CustomInvoiceField[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type InvoiceFooterSection = {
    html?: string;
};
export type InvoiceData = {
    company_data?: CompanyData;
    payment_details?: PaymentDetails;
    company_custom_terms?: CompanyCustomTerms;
    main_bank_data?: CompanyMainBankData;
    second_bank_data?: CompanySecondBankData;
    correspondent_bank_data?: CompanyCorrespondentBankData;
    footer_section1?: InvoiceFooterSection;
    footer_section2?: InvoiceFooterSection;
    footer_section3?: InvoiceFooterSection;
    footer_section4?: InvoiceFooterSection;
    footer_section5?: InvoiceFooterSection;
    footer_section6?: InvoiceFooterSection;
    footer_section7?: InvoiceFooterSection;
    footer_section8?: InvoiceFooterSection;
};
export type CompanyLanguageCodeEnum =
    | 'en'
    | 'en-au'
    | 'en-gb'
    | 'ar'
    | 'de'
    | 'fr'
    | 'nl'
    | 'ru'
    | 'cs'
    | 'it'
    | 'es'
    | 'pl'
    | 'th'
    | 'zh-hans'
    | 'zh-hk'
    | 'zh-tw'
    | 'ko'
    | 'hu'
    | 'hr'
    | 'sl'
    | 'sv'
    | 'uk'
    | 'da'
    | 'tr'
    | 'no'
    | 'sr'
    | 'he';
export type WidgetSignInModeEnum = 'upload' | 'add_to_cart';
export type CurrencyFormatDecimalSeparatorEnum = '.' | ',';
export type CurrencyFormatThousandSeparatorEnum = '.' | ',' | '\u00A0';
export type ModelUnitsEnum = 'mm' | 'cm' | 'in';
export type MaterialSelectionModeEnum = 'preselection' | 'repeat_specs' | 'full_preselection';
export type PaymentMethodEnum = 'paypal' | 'stripe' | 'payfast' | 'converge' | 'wires-only';
export type DocumentTypeEnum = 'invoices' | 'proforma';
export type OrdersReviewTypeEnum = 'instant_payment' | 'manual_review' | 'payment_or_manual_review';
export type ZipGroupingMethodEnum = 'group_by_object_model' | 'group_by_purchase';
export type SpecStringTemplateEnum =
    | 'layer_thickness'
    | 'filling'
    | 'color'
    | 'post_production'
    | 'lead_time'
    | 'tolerance'
    | 'thickness'
    | 'features'
    | 'dfm_features'
    | 'extra_fieldsets'
    | 'technology';
export type CompanyCrm = {
    title: string;
    short_name: string;
    features: PricingPlanFeature[];
    website?: string;
    api_key: string;
    logo?: string;
    phone?: string;
    support_email?: string;
    order_emails?: string[];
    senders_email?: string;
    timezone?: TimezoneEnum | BlankEnum;
    widget_url: string;
    invoices_data?: InvoiceData;
    terms_and_conditions?: string;
    lead_notification_daily?: boolean;
    lead_notification_weekly?: boolean;
    send_cart_notifications?: boolean;
    send_cart_poll_emails?: boolean;
    remove_models_period?: number;
    purchase_numbers_enabled?: boolean;
    is_landscape_packing_list?: boolean;
    company_language_code?: (CompanyLanguageCodeEnum | BlankEnum | NullEnum) | null;
    widget_sign_in_mode?: (WidgetSignInModeEnum | NullEnum) | null;
    enabled_magic_links?: boolean;
    currency?: CurrencyCodeEnum | NullEnum;
    currency_decimal_digits?: CurrencyDecimalDigitsEnum;
    currency_format_sign_position?: CurrencyFormatSignPositionEnum;
    currency_format_decimal_separator?: CurrencyFormatDecimalSeparatorEnum;
    currency_format_thousand_separator?: CurrencyFormatThousandSeparatorEnum;
    currency_format_swap_thousand_separator?: boolean;
    default_widget_units?: ModelUnitsEnum;
    display_widget_units?: (ModelUnitsEnum | BlankEnum | NullEnum) | null;
    minimum_order_amount?: number;
    due_days?: number;
    taxjar_enabled?: boolean;
    tax?: number;
    tax_name?: string;
    tax_number_name?: string;
    vat_number_required?: boolean;
    tax_on_shipping?: boolean;
    pre_offer_title?: string;
    offer_title?: string;
    packing_list_common_checks?: string[];
    packing_list_line_checks?: string[];
    material_selection_mode?: MaterialSelectionModeEnum;
    is_drawings_optional_for_non_processable_file_formats?: boolean;
    current_payment_method?: PaymentMethodEnum;
    document_type?: DocumentTypeEnum;
    pro_forma_tax_included?: boolean;
    pro_forma_title?: string;
    po_enabled?: boolean;
    vban_bank_transfer_min_order_threshold?: string;
    vban_payment_enabled: boolean;
    grace_period_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    grace_period_days?: number;
    send_payment_due?: boolean | null;
    orders_review_type?: OrdersReviewTypeEnum;
    manual_review_threshold?: number;
    tbd_line_item_total_threshold?: number;
    tbd_line_item_custom_message?: string;
    tbd_order_total_threshold?: number;
    tbd_order_custom_message?: string;
    tbd_machine_qnt_custom_message?: string;
    tbd_lead_time_qnt_custom_message?: string;
    tbd_complexity_custom_message?: string;
    tbd_no_suitable_feature_custom_message?: string;
    tbd_non_cad_custom_message?: string;
    tbd_custom_label?: string;
    force_order_shipping?: boolean;
    use_local_pickup?: boolean;
    require_address_on_pickup?: boolean;
    use_shipping_companies?: boolean;
    round_way_shipment?: boolean;
    use_fixed_shipping_cost?: boolean;
    available_carriers: string[];
    custom_widget_head_html?: string;
    custom_widget_body_html?: string;
    widget_style_customization?: any;
    widget_content_customization?: any;
    non_processable_file_formats?: any[];
    custom_pdf_css?: string | null;
    zip_grouping_method?: ZipGroupingMethodEnum;
    filename_template?: string;
    foldername_template?: string;
    spec_string_template?: SpecStringTemplateEnum[];
    geometry_type_detection_accuracy_coefficient?: number | null;
    wall_thickness_allowed_failed_polygons_coefficient?: number | null;
    sanity_check_coefficient?: number | null;
    cnc_sanity_check_coefficient?: number | null;
    complexity_check_coefficient?: number | null;
    tags: string[];
    paypal_account_email?: string;
    custom_order_status_titles?: any;
    ep_min_rate_threshold?: string | null;
    trustpilot_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    trustpilot_email?: string;
    kanban_dashboard_config?: KanbanDashboardConfig | null;
    after_payment_order_target_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
};
export type CustomInvoiceFieldRequest = {
    id: string;
    name: string;
    value: string;
};
export type CompanyDataRequest = {
    custom_fields?: CustomInvoiceFieldRequest[];
    trade_name?: string;
    legal_name?: string;
    legal_form?: string;
    legal_address?: string;
    registration_number?: string;
    id_number?: string;
};
export type PaymentDetailsRequest = {
    custom_fields?: CustomInvoiceFieldRequest[];
    payment_instructions?: string;
    html?: string;
};
export type CustomTermsFieldRequest = {
    id: string;
    clients: string;
    text: string;
};
export type CompanyCustomTermsRequest = {
    custom_terms?: CustomTermsFieldRequest[];
};
export type CompanyMainBankDataRequest = {
    custom_fields?: CustomInvoiceFieldRequest[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type CompanySecondBankDataRequest = {
    custom_fields?: CustomInvoiceFieldRequest[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type CompanyCorrespondentBankDataRequest = {
    custom_fields?: CustomInvoiceFieldRequest[];
    bank_name?: string;
    bank_address?: string;
    account_number?: string;
    swift_or_bic?: string;
};
export type InvoiceFooterSectionRequest = {
    html?: string;
};
export type InvoiceDataRequest = {
    company_data?: CompanyDataRequest;
    payment_details?: PaymentDetailsRequest;
    company_custom_terms?: CompanyCustomTermsRequest;
    main_bank_data?: CompanyMainBankDataRequest;
    second_bank_data?: CompanySecondBankDataRequest;
    correspondent_bank_data?: CompanyCorrespondentBankDataRequest;
    footer_section1?: InvoiceFooterSectionRequest;
    footer_section2?: InvoiceFooterSectionRequest;
    footer_section3?: InvoiceFooterSectionRequest;
    footer_section4?: InvoiceFooterSectionRequest;
    footer_section5?: InvoiceFooterSectionRequest;
    footer_section6?: InvoiceFooterSectionRequest;
    footer_section7?: InvoiceFooterSectionRequest;
    footer_section8?: InvoiceFooterSectionRequest;
};
export type KanbanDashboardGroupRequest = {
    title: string;
    statuses: OrderStatusEnum[];
};
export type KanbanDashboardCardRequest = {
    order_fields: string[];
};
export type KanbanDashboardConfigRequest = {
    groups?: KanbanDashboardGroupRequest[];
    card?: KanbanDashboardCardRequest;
};
export type PatchedEditCompanyCrmRequest = {
    title?: string;
    short_name?: string;
    website?: string;
    logo?: Blob;
    phone?: string;
    support_email?: string;
    order_emails?: string[];
    senders_email?: string;
    timezone?: TimezoneEnum | BlankEnum;
    invoices_data?: InvoiceDataRequest;
    terms_and_conditions?: string;
    lead_notification_daily?: boolean;
    lead_notification_weekly?: boolean;
    send_cart_notifications?: boolean;
    send_cart_poll_emails?: boolean;
    remove_models_period?: number;
    purchase_numbers_enabled?: boolean;
    is_landscape_packing_list?: boolean;
    company_language_code?: (CompanyLanguageCodeEnum | BlankEnum | NullEnum) | null;
    widget_sign_in_mode?: (WidgetSignInModeEnum | NullEnum) | null;
    enabled_magic_links?: boolean;
    currency?: CurrencyCodeEnum | NullEnum;
    currency_decimal_digits?: CurrencyDecimalDigitsEnum;
    currency_format_sign_position?: CurrencyFormatSignPositionEnum;
    currency_format_decimal_separator?: CurrencyFormatDecimalSeparatorEnum;
    currency_format_thousand_separator?: CurrencyFormatThousandSeparatorEnum;
    currency_format_swap_thousand_separator?: boolean;
    default_widget_units?: ModelUnitsEnum;
    display_widget_units?: (ModelUnitsEnum | BlankEnum | NullEnum) | null;
    minimum_order_amount?: number;
    due_days?: number;
    taxjar_enabled?: boolean;
    tax?: number;
    tax_name?: string;
    tax_number_name?: string;
    vat_number_required?: boolean;
    tax_on_shipping?: boolean;
    pre_offer_title?: string;
    offer_title?: string;
    packing_list_common_checks?: string[];
    packing_list_line_checks?: string[];
    material_selection_mode?: MaterialSelectionModeEnum;
    is_drawings_optional_for_non_processable_file_formats?: boolean;
    current_payment_method?: PaymentMethodEnum;
    document_type?: DocumentTypeEnum;
    pro_forma_tax_included?: boolean;
    pro_forma_title?: string;
    po_enabled?: boolean;
    vban_bank_transfer_min_order_threshold?: string;
    vban_payment_enabled?: boolean;
    grace_period_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    grace_period_days?: number;
    send_payment_due?: boolean | null;
    orders_review_type?: OrdersReviewTypeEnum;
    manual_review_threshold?: number;
    tbd_line_item_total_threshold?: number;
    tbd_line_item_custom_message?: string;
    tbd_order_total_threshold?: number;
    tbd_order_custom_message?: string;
    tbd_machine_qnt_custom_message?: string;
    tbd_lead_time_qnt_custom_message?: string;
    tbd_non_cad_custom_message?: string;
    tbd_custom_label?: string;
    force_order_shipping?: boolean;
    use_local_pickup?: boolean;
    require_address_on_pickup?: boolean;
    use_shipping_companies?: boolean;
    round_way_shipment?: boolean;
    use_fixed_shipping_cost?: boolean;
    available_carriers?: string[];
    custom_widget_head_html?: string;
    custom_widget_body_html?: string;
    widget_style_customization?: any;
    widget_content_customization?: any;
    non_processable_file_formats?: any[];
    custom_pdf_css?: string | null;
    zip_grouping_method?: ZipGroupingMethodEnum;
    filename_template?: string;
    foldername_template?: string;
    spec_string_template?: SpecStringTemplateEnum[];
    geometry_type_detection_accuracy_coefficient?: number | null;
    wall_thickness_allowed_failed_polygons_coefficient?: number | null;
    sanity_check_coefficient?: number | null;
    cnc_sanity_check_coefficient?: number | null;
    complexity_check_coefficient?: number | null;
    tags?: string[];
    paypal_account_email?: string;
    custom_order_status_titles?: any;
    ep_min_rate_threshold?: string | null;
    trustpilot_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    trustpilot_email?: string;
    kanban_dashboard_config?: KanbanDashboardConfigRequest | null;
    after_payment_order_target_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
};
export type CountryEnum =
    | 'AF'
    | 'AL'
    | 'DZ'
    | 'AD'
    | 'AR'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BA'
    | 'BW'
    | 'BR'
    | 'BG'
    | 'BL'
    | 'BH'
    | 'CV'
    | 'KH'
    | 'KW'
    | 'CA'
    | 'CL'
    | 'CN'
    | 'CO'
    | 'CD'
    | 'CR'
    | 'HR'
    | 'CU'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'EE'
    | 'ET'
    | 'FI'
    | 'FR'
    | 'GE'
    | 'DE'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GP'
    | 'GT'
    | 'HT'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JO'
    | 'JP'
    | 'KZ'
    | 'KE'
    | 'KR'
    | 'KG'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MY'
    | 'MV'
    | 'MT'
    | 'MX'
    | 'MD'
    | 'MC'
    | 'ME'
    | 'MA'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NG'
    | 'NF'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PA'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RO'
    | 'RU'
    | 'WS'
    | 'SM'
    | 'SA'
    | 'RS'
    | 'SG'
    | 'SK'
    | 'SI'
    | 'SO'
    | 'ZA'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SE'
    | 'CH'
    | 'TW'
    | 'TJ'
    | 'TH'
    | 'TO'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'YE'
    | 'ZW';
export type StateEnum =
    | 'AL'
    | 'AK'
    | 'AZ'
    | 'AR'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DE'
    | 'DC'
    | 'FL'
    | 'GA'
    | 'GU'
    | 'HI'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'IA'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'ME'
    | 'MD'
    | 'MA'
    | 'MI'
    | 'MN'
    | 'MS'
    | 'MO'
    | 'MT'
    | 'NE'
    | 'NV'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NY'
    | 'NC'
    | 'ND'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PW'
    | 'PA'
    | 'PR'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VT'
    | 'VI'
    | 'VA'
    | 'WA'
    | 'WV'
    | 'WI'
    | 'WY'
    | 'AB'
    | 'BC'
    | 'MB'
    | 'NB'
    | 'NL'
    | 'NS'
    | 'NT'
    | 'NU'
    | 'ON'
    | 'PE'
    | 'QC'
    | 'SK'
    | 'YT';
export type CompanyAddressCrm = {
    country: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city: string;
    street_address: string;
    apartment?: string;
    postcode?: string;
    vat_number?: string;
};
export type PatchedCompanyAddressCrmRequest = {
    country?: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city?: string;
    street_address?: string;
    apartment?: string;
    postcode?: string;
    vat_number?: string;
};
export type CustomEmailRetrieveResponse = {
    title: string;
    description: string;
    subject: string;
    default_subject: string;
    body: string;
    default_body: string;
};
export type CustomEmailCreate = {
    email_type: string;
    lang_code: string;
    subject?: string;
    body?: string;
};
export type CustomEmailCreateRequest = {
    email_type: string;
    lang_code: string;
    subject?: string;
    body?: string;
};
export type CustomInvoiceRetrieveResponse = {
    body: string;
    default_body: string;
};
export type CustomInvoiceCreate = {
    body: string;
};
export type CustomInvoiceCreateRequest = {
    body: string;
};
export type CustomPackageListRetrieveResponse = {
    body: string;
    default_body: string;
};
export type CustomPackageListCreate = {
    body: string;
};
export type CustomPackageListCreateRequest = {
    body: string;
};
export type DeliveryCarrier = {
    code: string;
    title: string;
};
export type ShippingTypeEnum = 'international' | 'domestic' | 'city' | 'pick-up';
export type CompanyCustomShipping = {
    id: number;
    shipping_type: ShippingTypeEnum;
    name: string;
    note?: string;
    cost?: number;
};
export type CompanyCustomShippingRequest = {
    shipping_type: ShippingTypeEnum;
    name: string;
    note?: string;
    cost?: number;
};
export type PatchedCompanyCustomShippingRequest = {
    shipping_type?: ShippingTypeEnum;
    name?: string;
    note?: string;
    cost?: number;
};
export type CompanyExtendTrial = {
    is_blocked?: boolean;
    trials_left?: number;
};
export type Integration = {
    slug: string;
    title: string;
    enabled?: boolean;
    connect_link?: string;
};
export type CompanySendersVerifiedIdentityTypeEnum = 'email' | 'domain';
export type CompanySendersVerifiedIdentityStatusEnum =
    | 'pending'
    | 'success'
    | 'failed'
    | 'temporaryfailure';
export type CompanySendersVerifiedIdentity = {
    type: CompanySendersVerifiedIdentityTypeEnum;
    value: string;
    status?: CompanySendersVerifiedIdentityStatusEnum;
    verification_data: any[];
    requested_at: string;
};
export type CompanySendersVerifiedIdentityRequestRequest = {
    type: string;
    value: string;
};
export const {
    useBackofficeSettingsRetrieveQuery,
    useBillingCompanyStripeCustomerPortalRetrieveQuery,
    useBillingCompanySubscriptionsListQuery,
    useCompanyRetrieveQuery,
    useCompanyPartialUpdateMutation,
    useCompanyAddressRetrieveQuery,
    useCompanyAddressPartialUpdateMutation,
    useCompanyCustomEmailsRetrieveQuery,
    useCompanyCustomEmailsCreateMutation,
    useCompanyCustomEmailsDestroyMutation,
    useCompanyCustomInvoiceHtmlRetrieveQuery,
    useCompanyCustomInvoiceHtmlCreateMutation,
    useCompanyCustomPackageListHtmlRetrieveQuery,
    useCompanyCustomPackageListHtmlCreateMutation,
    useCompanyDeliveryCarriersListQuery,
    useCompanyDeliveryCustomShippingListQuery,
    useCompanyDeliveryCustomShippingCreateMutation,
    useCompanyDeliveryCustomShippingRetrieveQuery,
    useCompanyDeliveryCustomShippingPartialUpdateMutation,
    useCompanyDeliveryCustomShippingDestroyMutation,
    useCompanyExtendTrialPartialUpdateMutation,
    useCompanyIntegrationsListQuery,
    useCompanyIntegrationsDestroyMutation,
    useCompanyVerifiedIdentityListQuery,
    useCompanyVerifiedIdentityCreateMutation,
} = injectedRtkApi;
