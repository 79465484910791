import React from 'react';
import { Box, BoxProps } from '@chakra-ui/react';
import { PreloaderBox } from '@shared/components/preloader';
import { AppHeader } from '@shared/components/app-header';
import { useAppSize } from '@shared/hooks';
import { FCC } from '@shared/types';

export interface Props extends BoxProps {
    center?: boolean;
    preloading?: boolean;
    showAppHeader?: boolean;
}

export const PageContainer: FCC<Props> = ({
    children,
    center = false,
    preloading = false,
    showAppHeader = true,
    ...rest
}) => {
    const { isDesktop } = useAppSize();

    if (preloading) {
        return <PreloaderBox in />;
    }

    return (
        <Box
            layerStyle="pageGap"
            // mt={{ base: 'header', lg: '0' }}
            {...(center ? { m: '0 auto' } : {})}
            {...rest}
        >
            {showAppHeader && isDesktop && <AppHeader />}
            {children}
        </Box>
    );
};
