import { createPersistReducer } from '@ducks/helpers';
import { createReactTableSlice, getReactTableSelectors } from '@ducks/react-table-slice';

export const modelsTableSlice = createReactTableSlice({
    namespace: 'models',
    initialState: {
        rowSelection: {},
    },
    reducers: {},
});

export const { setColumnsConfig: setModelsColumnsConfig, setRowSelection: setSelectedModels } =
    modelsTableSlice.actions;

export const {
    selectColumnsConfig: selectModelColumnsConfig,
    selectRowSelection: selectSelectedModels,
    selectColumnsOrder: selectModelColumnsOrder,
    selectColumnsVisibility: selectModelColumnsVisibility,
} = getReactTableSelectors(modelsTableSlice, state => state.models.table);

export const modelsTableReducer = createPersistReducer(modelsTableSlice, {
    whitelist: ['columnsConfig'],
});
