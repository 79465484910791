import { UserRoleTypes, UserRoles } from '@shared/constants';
import { BackofficeAppSettings } from '@services/df/app';
import { hasPricingPlanFeature } from '@ducks/app';

export const checkPermissionsByRoles = (userRoles: UserRoles, allowedRoles: UserRoles) =>
    userRoles.some(role => allowedRoles.includes(role));

export const defineUserPermissions = ({
    isAuthenticated,
    userRoles,
    settings,
}: {
    isAuthenticated: boolean;
    userRoles: UserRoles;
    settings?: BackofficeAppSettings;
}) => {
    const _features = settings?.features;

    const features = {
        isAdditionalMachinesEnabled: hasPricingPlanFeature(
            _features,
            'ms_additional_machines_enabled',
        ),
        isUsersRolesSettingsEnabled: hasPricingPlanFeature(_features, 'users_roles_enabled'),
    };

    const userIsAdmin = checkPermissionsByRoles(userRoles, [UserRoleTypes.CompanyOwner]);
    const userIsBlocked = checkPermissionsByRoles(userRoles, [UserRoleTypes.BlockedClient]);
    const userIsSandboxOwner = checkPermissionsByRoles(userRoles, [UserRoleTypes.SandboxOwner]);
    const userIsSalesman = checkPermissionsByRoles(userRoles, [UserRoleTypes.Salesman]);
    const userIsVendor = checkPermissionsByRoles(userRoles, [UserRoleTypes.Vendor]);

    const userIsAdminOrSalesman = checkPermissionsByRoles(userRoles, [
        UserRoleTypes.CompanyOwner,
        UserRoleTypes.Salesman,
    ]);
    const userIsAdminOrVendor = checkPermissionsByRoles(userRoles, [
        UserRoleTypes.CompanyOwner,
        UserRoleTypes.Vendor,
    ]);
    const userIsManager = checkPermissionsByRoles(userRoles, [
        UserRoleTypes.CompanyOwner,
        UserRoleTypes.Salesman,
        UserRoleTypes.Vendor,
    ]);

    const roles = {
        userIsAdmin, // I named the variable the same as in the backend
        userIsVendor,
        userIsBlocked,
        userIsSandboxOwner,
        userIsSalesman,

        userIsAdminOrSalesman,
        userIsAdminOrVendor,
        userIsManager,
    };

    // concrete app actions
    // const actions = {
    //     isConfirmProductAllowed: isAuthenticated,
    //     isOrderPageAllowed: isAuthenticated,
    //     isUploadingModelsAllowed: isAuthenticated,
    // };

    // combined variables (narrow app scope)
    // const access = {
    //     iqtAllowed:
    //         isAuthenticated &&
    //         userIsCompanyOwner &&
    //         (!AppSession.iFrame || AppSession.shortIqtModeOn),
    // };

    return {
        ...features,
        ...roles,
        // ...actions,
        // ...access,
    };
};

export type Permissions = ReturnType<typeof defineUserPermissions>;
