import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { enhancedApi as api, ModelSerializerV2 } from '@services/df/models';
import { RootState } from '@app/types';

// todo remove
export const modelsSlice = createSlice({
    name: 'models/list',
    initialState: {
        total: 0,
        modelsList: [],
        isLoading: false,
    } as {
        total: number;
        modelsList: ModelSerializerV2[] | undefined;
        isLoading: boolean;
    },
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(api.endpoints.usersModelsList.matchPending, state => {
            state.isLoading = true;
        });
        builder.addMatcher(api.endpoints.usersModelsList.matchRejected, state => {
            state.isLoading = false;
        });
        builder.addMatcher(api.endpoints.usersModelsList.matchFulfilled, (state, { payload }) => {
            state.modelsList = payload.results;
            state.total = payload.count ?? 0;
            state.isLoading = false;
        });
    },
});

export const selectModels = (state: RootState) => state.models.models.modelsList;
export const selectModelsIds = () =>
    createSelector(selectModels, modelsList => modelsList?.map(model => model.id));
export const selectModelById = (id: number | string) =>
    createSelector(selectModels, modelsList => modelsList?.find(model => model.id === Number(id)));
export const selectModelsTotal = (state: RootState) => state.models.models.total;

export const selectModelsIsLoading = (state: RootState) => state.models.models.isLoading;

export const currentModelSlice = createSlice({
    name: 'currentModel',
    initialState: {
        currentModel: undefined,
        isFirstTimeLoading: true,
    } as {
        currentModel: ModelSerializerV2 | undefined;
        isFirstTimeLoading: boolean;
    },
    reducers: {
        saveCurrentModel(state, { payload }: PayloadAction<ModelSerializerV2 | undefined>) {
            state.currentModel = payload;
        },
        resetCurrentModel(state) {
            state.currentModel = undefined;
        },
        setIsFirstTimeLoading(state, { payload }: PayloadAction<boolean>) {
            state.isFirstTimeLoading = payload;
        },
    },
});

export const { saveCurrentModel, resetCurrentModel, setIsFirstTimeLoading } =
    currentModelSlice.actions;

export const selectCurrentModel = (state: RootState) => state.currentModel.currentModel;
export const selectIsFirstTimeLoading = (state: RootState) => state.currentModel.isFirstTimeLoading;
