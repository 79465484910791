import { Icon, IconProps } from '@chakra-ui/react';

export const ColumnsIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M13.6612 2.80032C13.4351 2.57438 13.1636 2.46143 12.8462 2.46143H3.15387C2.83657 2.46143 2.56491 2.57436 2.33892 2.80032C2.11298 3.02631 2 3.29787 2 3.6152V12.3844C2 12.7018 2.11298 12.9734 2.33892 13.1993C2.56491 13.4253 2.83655 13.5383 3.15387 13.5383H12.8462C13.1636 13.5383 13.4351 13.4253 13.6612 13.1993C13.8871 12.9734 14 12.7018 14 12.3844V3.6152C14 3.29787 13.8871 3.02631 13.6612 2.80032ZM7.53852 12.6153H3.15387C3.09138 12.6153 3.0373 12.5924 2.99164 12.5469C2.94594 12.5011 2.92313 12.4471 2.92313 12.3845V4.30767H7.53855L7.53852 12.6153ZM13.0769 12.3844C13.0769 12.447 13.0541 12.5011 13.0084 12.5467C12.9626 12.5924 12.9087 12.6152 12.8461 12.6152H8.46153V4.30749H13.077L13.0769 12.3844Z"
            fill="currentColor"
        />
    </Icon>
);
