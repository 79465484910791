import * as yup from 'yup';
import Lazy from 'yup/lib/Lazy';
import isNaN from 'lodash/isNaN';
import { LocaleObject } from 'yup/lib/locale';
import { castNumber } from '@shared/utils/strings';

// Source: https://github.com/jquense/yup/blob/master/src/locale.ts
// @ts-nocheck (to ignore typechecking on validation function parameters)
/* eslint-disable no-template-curly-in-string */
export const yupLocale: LocaleObject = {
    mixed: {
        default: 'Field is invalid',
        required: 'Required',
        notType: 'Invalid ${type}',
    },
    string: {
        email: 'Please enter a valid email address',
        url: 'Must be a valid URL',
        max: 'No more than ${max} characters long',
        min: 'At least ${min} characters long',
    },
    array: {
        max: 'Must have less than or equal to ${max} items',
        min: 'Must have at least ${min} items',
    },
    number: {
        min: 'Must be greater than or equal to ${min}',
        max: 'Must be less than or equal to ${max}',
        lessThan: 'Must be less than ${less}',
        moreThan: 'Must be greater than ${more}',
        integer: 'Must be an integer',
    },
    boolean: { isValue: 'Field must be ${value}' },
};
/* eslint-enable no-template-curly-in-string */

yup.setLocale(yupLocale);

yup.addMethod<yup.StringSchema>(yup.string, 'emptyAsUndefined', function () {
    return this.transform(value => (value ? value : undefined));
});

yup.addMethod<yup.StringSchema>(yup.string, 'asNumber', function (precision?: number, step = 1) {
    return this.nullable()
        .ensure()
        .transform(value => castNumber(value, precision, step));
});

yup.addMethod<yup.NumberSchema>(yup.number, 'emptyAsUndefined', function () {
    return this.transform((value, originalValue) =>
        String(originalValue)?.trim() ? value : undefined,
    );
});

yup.addMethod<yup.NumberSchema>(yup.number, 'stripZero', function () {
    return this.transform((value, originalValue) => (value === 0 ? undefined : value));
});

yup.addMethod<yup.ArraySchema<yup.AnySchema | Lazy<any, any>>>(yup.array, 'sort', function () {
    return this.transform((value, originalValue) => {
        return value.sort();
    });
});

yup.addMethod<yup.NumberSchema<number | undefined | null>>(
    yup.number,
    'requiredNullEqualTo0',
    function (defaultValue: number = 0) {
        return this.nullable()
            .transform(value => {
                return value === '' || value === null || isNaN(value) ? 0 : value;
            })
            .required()
            .default(defaultValue);
    },
);
