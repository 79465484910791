import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import {
    defineField,
    definePercentField,
    objectToSelectOptions,
    FieldType,
    SelectComponent,
} from '@shared/components/forms-v2';
import { Currency } from '@services/df/app';
import { DiscountType } from '@services/df/codegen-enums';
import { DISCOUNT_TYPE_LABELS } from '@services/df/codegen-enums-labels';
import { hasClientsInDiscount, hasMultipleConditions } from './helpers';

interface DiscountTypeData {
    namePlaceholder: string;
    conditionValue?: { hint: string; units: string };
}

const getDiscountTypeData = ({
    currencySign,
}: {
    currencySign: Currency['symbol'];
}): Record<`${DiscountType}`, DiscountTypeData> => ({
    promo: { namePlaceholder: 'Black friday promo' },
    client: { namePlaceholder: 'Client discount' },
    order: {
        namePlaceholder: 'Order total discount',
        conditionValue: { hint: 'If total more than', units: currencySign },
    },
    batch: {
        namePlaceholder: 'Batch discount',
        conditionValue: { hint: 'If identical copies more than', units: 'pcs' },
    },
    hourly: {
        namePlaceholder: 'Hourly discount',
        conditionValue: { hint: 'If manufacturing time is more than', units: 'hours' },
    },
    sq_cm_of_material: {
        namePlaceholder: '2d cutting material discount',
        conditionValue: { hint: 'If the material is greater than', units: 'cm²' },
    },
});

type ConfigArgs = {
    currencySign?: string;
    discountType?: `${DiscountType}`;
    isCreation?: boolean;
};

export const getOrderStatusSchema = () => {
    return yup.object({
        code: yup.string().ensure().required(),
        title: yup.string().ensure().trim().required(),
    });
};

export const getDiscountConfig = ({
    currencySign = '$',
    discountType = DiscountType.Promo,
    isCreation = false,
}: ConfigArgs = {}) => {
    const { namePlaceholder, conditionValue } = getDiscountTypeData({ currencySign })[discountType];

    const active = defineField({
        type: FieldType.Switch,
        name: 'active',
        scheme: yup.boolean().default(true),
        props: {
            label: 'Active',
        },
    });

    const type = defineField({
        type: FieldType.Select,
        name: 'type',
        scheme: yup.string().default(discountType),
        props: {
            label: 'Discount type',
            options: objectToSelectOptions(DISCOUNT_TYPE_LABELS),
            isDisabled: !isCreation,
        },
    });

    const name = defineField({
        type: FieldType.Text,
        name: 'name',
        scheme: yup.string().ensure().trim().required().max(255),
        props: {
            label: 'Name',
            placeholder: namePlaceholder,
        },
    });

    const materials = defineField({
        type: FieldType.Select,
        name: 'materials',
        scheme: yup.array().of(yup.string()).ensure().compact(),
        props: {
            label: 'Materials',
            placeholder: 'Select material',
            isMulti: true,
        },
    });

    const clients = defineField({
        type: FieldType.Select,
        name: 'clients',
        scheme: yup
            .array()
            .of(yup.string())
            .ensure()
            .compact()
            .when('type', {
                is: hasClientsInDiscount,
                then: schema => schema.min(1),
            }),
        props: {
            label: 'Clients',
            placeholder: 'Select client',
            component: SelectComponent.Async,
            cacheOptions: true,
            defaultOptions: true,
            isMulti: true,
        },
    });

    // conditions

    const discount_value = definePercentField({
        name: 'discount_value',
        props: {
            label: !hasMultipleConditions(discountType) && 'Discount',
        },
    });

    const condition_value = defineField({
        type: FieldType.Number,
        name: 'condition_value',
        scheme: yup.number().integer().min(0).default(0),
        props: {
            min: 0,
            precision: 0,
            rightAddon: conditionValue?.units,
        },
        hint: conditionValue?.hint,
    });

    const code = defineField({
        type: FieldType.Text,
        name: 'code',
        scheme: yup.string().ensure().trim().required().max(255),
        props: {
            label: 'Code',
            placeholder: 'BLACK FRIDAY',
        },
    });

    // todo reason for warning
    // Warning: A component is changing an uncontrolled input to be controlled. This is likely caused by the value changing
    // from undefined to a defined value, which should not happen. Decide between using a controlled or uncontrolled
    // input element for the lifetime of the component. More info: https://reactjs.org/link/controlled-components input
    const getConditionFields = () => {
        switch (discountType) {
            case 'client':
                return { discount_value };
            case 'promo':
                return { discount_value, code };
            default:
                return { discount_value, condition_value };
        }
    };

    const conditions = {
        fields: { discount_value, condition_value, code },
        schema: yup.object({
            id: yup.number(),
            // @ts-ignore
            ...mapValues(getConditionFields(), 'scheme'),
        }),
    };

    const fields = {
        active,
        type,
        name,
        materials,
        clients,
    };

    const schema = yup.object(mapValues(fields, 'scheme')).shape({
        conditions: yup
            .array(conditions.schema)
            .ensure()
            .compact()
            .default(isCreation ? [conditions.schema.getDefault()] : undefined),
    });

    return {
        conditions,
        fields,
        schema,
    };
};

export type DiscountConfig = ReturnType<typeof getDiscountConfig>;
export type DiscountSchema = DiscountConfig['schema'];
export type DiscountFields = DiscountConfig['fields'];
export type DiscountConfigConditions = DiscountConfig['conditions'];
export type DiscountFieldsUnion = keyof DiscountConfig['fields'];
export type DiscountFieldsArray = Array<DiscountFields[DiscountFieldsUnion]>;
