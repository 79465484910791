import { createSlice, createSelector, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/types';
import { selectAllowedTechnologies } from './selectors';

// todo move to modules/machines-materials/machine

const initialState: { technologyId: number | null } = { technologyId: null };

export const createMachineSlice = createSlice({
    name: 'machine/create',
    initialState,
    reducers: {
        technologyChanged(state, action: PayloadAction<number | null>) {
            state.technologyId = action.payload;
        },
    },
    // selectors: {
    //     selectCreateMachineTechnologyId: state => state.technologyId,
    // },
});

export const { technologyChanged: createMachineTechnologyChanged } = createMachineSlice.actions;

export const selectCreateMachineTechnologyId = (state: RootState) =>
    state.machine.create.technologyId;
// export const { selectCreateMachineTechnologyId } = createMachineSlice.selectors;
export const selectCreateMachineTechnology = createSelector(
    selectAllowedTechnologies,
    selectCreateMachineTechnologyId,
    (technologies, currentTechnologyId) =>
        technologies?.find(technology => technology.id === currentTechnologyId),
);
