import * as yup from 'yup';
import type { CastOptions } from 'yup/lib/schema';
import mapValues from 'lodash/mapValues';
import { Machine } from '@services/df/machines-materials';
import {
    CompoundFieldConfigs,
    CompoundFieldType,
    FieldType,
    defineField,
    definePercentField,
} from '@shared/components/forms-v2';
import { isBelongCNC } from '@shared/utils/technology-guard';
import { Technologies } from '@shared/constants';
import { getComplexityBracketingConfig } from './complexity-bracketing';
import { getMachineFeatureConfig } from './features';
import { getMachinePriorityConfig } from './priorities';
import { getMachineToleranceConfig } from './tolerances';
import { overwriteMachineDefaultValues } from './helpers';

const getOrganizationsEnabledSchema = () =>
    yup.object({
        id: yup.number(),
        title: yup.string().ensure().trim(),
        domain: yup.string().ensure().trim(),
    });

type MachineFieldsConfigArgs = {
    currencySign?: string;
};

const MAX_INT_32 = 2147483647;
const MAX_UINT_32 = 4294967295;
const getBasicNumberScheme = (defaultValue: number = 0) =>
    yup.number().requiredNullEqualTo0(defaultValue).min(0).max(Number.MAX_SAFE_INTEGER);

export const getMachineFieldsConfig = ({ currencySign = '$' }: MachineFieldsConfigArgs = {}) => {
    const title = defineField({
        type: FieldType.Text,
        name: 'title',
        scheme: yup.string().ensure().trim().required().nullable().default(null),
        props: {
            label: 'Machine',
            placeholder: 'Type machine title',
        },
    });

    const custom_tech = defineField({
        type: FieldType.Select,
        name: 'custom_tech',
        scheme: yup.number().nullable().default(null),
        props: {
            id: 'custom_technology',
            label: 'Custom technology',
            placeholder: 'Select...',
            allowedEmpty: true,
        },
    });

    const minimum_price_of_line_items = defineField({
        type: FieldType.Number,
        name: 'minimum_price_of_line_items',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Minimum order value',
            tooltip:
                'Set minimum order value for all items produced on this machine. Orders must meet this threshold to be placed.',
            rightAddon: currencySign,
        },
    });

    const accept_drawing = defineField({
        type: FieldType.Select,
        name: 'accept_drawing',
        scheme: yup.string().default('not_accept'),
        props: {
            label: 'Accept Drawings',
            options: [
                { label: 'No drawings', value: 'not_accept' },
                { label: 'Drawings are optional', value: 'accept_not_require' },
                { label: 'Drawings are required', value: 'accept_require' },
            ],
        },
    });

    const is_iqt_only = defineField({
        type: FieldType.Switch,
        name: 'is_iqt_only',
        scheme: yup.boolean().default(false),
        props: {
            formControlProps: { width: 'auto' },
        },
    });

    const size_x = defineField({
        type: FieldType.Number,
        name: 'size_x',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Length',
        },
    });

    const size_y = defineField({
        type: FieldType.Number,
        name: 'size_y',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Width',
        },
    });

    const size_z = defineField({
        type: FieldType.Number,
        name: 'size_z',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Height',
        },
    });

    const min_size_x = defineField({
        type: FieldType.Number,
        name: 'min_size_x',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'X-Travel',
        },
    });

    const min_size_y = defineField({
        type: FieldType.Number,
        name: 'min_size_y',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Y-Travel',
        },
    });

    const min_size_z = defineField({
        type: FieldType.Number,
        name: 'min_size_z',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Z-Travel',
        },
    });

    const min_size_x_threshold = defineField({
        type: FieldType.Number,
        name: 'min_size_x_threshold',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'X-Travel',
        },
    });

    const min_size_y_threshold = defineField({
        type: FieldType.Number,
        name: 'min_size_y_threshold',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Y-Travel',
        },
    });

    const min_size_z_threshold = defineField({
        type: FieldType.Number,
        name: 'min_size_z_threshold',
        scheme: getBasicNumberScheme(),
        props: {
            placeholder: 'Z-Travel',
        },
    });

    const tbd_complexity_threshold = defineField({
        type: FieldType.Number,
        name: 'tbd_complexity_threshold',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: 'Complexity threshold',
            tooltip: `Trigger TBD Mode (manual review) if the complexity of a part
                        is higher than the specified threshold. Disabled if set to 0.`,
        },
    });

    const tbd_no_suitable_feature_enabled = defineField({
        type: FieldType.Switch,
        name: 'tbd_no_suitable_feature_enabled',
        scheme: yup.boolean().default(false),
        label: 'TBD MODE',
        helpText: `Trigger TBD mode (manual review) if a part requires features that are not
                    listed in the settings of this machine`,
    });

    const max_heat_absorption = definePercentField({
        name: 'max_heat_absorption',
        defaultValue: 50,
        props: {
            label: 'Heat absorption limit, percentage of printer volume',
        },
    });

    const batch_recurring_mode = defineField({
        type: FieldType.Radio,
        name: 'batch_recurring_mode',
        scheme: yup.string().oneOf(['chamber_volume', 'plate_area']).default('chamber_volume'),
        props: {
            buttons: [
                {
                    value: 'chamber_volume',
                    children: 'Volume-based batches',
                },
                {
                    value: 'plate_area',
                    children: 'Area-based batches',
                },
            ],
            variant: 'borderedContainer',
            mb: '16px',
            gap: '16px',
        },
    });

    const machine_speed = defineField({
        type: FieldType.Number,
        name: 'machine_speed',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Roughing speed',
            tooltip:
                'How much material the machine can subtract, without unacceptable ' +
                'wear on the tool, when a material with 100% machinability.',
            rightAddon: 'cm³ per hour',
        },
    });

    const finishing_speed = defineField({
        type: FieldType.Number,
        name: 'finishing_speed',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Finishing speed',
            tooltip: `The (slow) work done very close to the model boundaries. This often
            causes quite a high correlation between part cost and model surface
            area. If you see such a correlation, use this parameter.`,
            rightAddon: 'cm² per hour',
        },
    });

    const horizontal_printing_speed = defineField({
        type: FieldType.Number,
        name: 'horizontal_printing_speed',
        scheme: getBasicNumberScheme(30),
        props: {
            label: 'Horizontal printing speed',
            rightAddon: 'mm per second',
        },
    });

    const nominal_growth_rate = defineField({
        type: FieldType.Number,
        name: 'nominal_growth_rate',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Nominal growth rate, per piece printing',
            tooltip: `If you use Nominal growth rate, per piece printing,
        the print time will be counted for every separate copy ordered.`,
            rightAddon: 'mm per hour per z-axis',
        },
    });

    const nominal_growth_rate_batch = defineField({
        type: FieldType.Number,
        name: 'nominal_growth_rate_batch',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Nominal growth rate, batch printing',
            tooltip: `If you use Nominal growth rate, batch printing,
        the print time will be counted only once, however many copies
        of the model are ordered.`,
            rightAddon: 'mm per hour per z-axis',
        },
    });

    const nominal_sintering_rate = defineField({
        type: FieldType.Number,
        name: 'nominal_sintering_rate',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Nominal fusing rate',
            tooltip: `You can either use the value given by the manufacturer, or, better, use
        empirical data for a 60, 70, or 80% filled printer, printing with the
        layer thickness which you will have to check or edit here below.`,
            rightAddon: 'cm³ per hour',
        },
    });

    const nominal_melting_rate = defineField({
        type: FieldType.Number,
        name: 'nominal_melting_rate',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Nominal melting rate',
            tooltip: `You can either use the value given by the manufacturer, or,
            better, use empirical data for a 60, 70, or 80% filled printer, printing
            with the layer thickness which you will have to check or edit here below.`,
            rightAddon: 'cm³ per hour',
        },
    });

    const nominal_layer_thickness = defineField({
        type: FieldType.Number,
        name: 'nominal_layer_thickness',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Nominal layer thickness',
            tooltip:
                'This is the layer thickness mentioned above, the one you would usually print at.',
            rightAddon: 'µm',
        },
    });

    const price_per_hour = defineField({
        type: FieldType.Number,
        name: 'price_per_hour',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Machine rate per hour',
            tooltip: `This hourly rate will be multiplied by
        the estimated machining time, which depends on the machine speed,
        machinability, work hardening exponent, and complexity bracket.`,
            rightAddon: `${currencySign} per hour`,
        },
    });

    const price_per_hour_batch = defineField({
        type: FieldType.Number,
        name: 'price_per_hour_batch',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price per hour, batch printing',
            tooltip:
                'If you use price per hour batch printing, the price per hour ' +
                'will be counted only once, however many copies of the model are ordered.',
            rightAddon: currencySign,
        },
    });

    const price_per_hour_batch_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'price_per_hour_batch_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Price per hour, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const per_part_fee = defineField({
        type: FieldType.Number,
        name: 'per_part_fee',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Per part fee',
            tooltip:
                'This fee is applied for every part. For example, ' +
                'ten repeat models will have this cost applied ten times.',
            rightAddon: currencySign,
        },
    });

    const manual_review_threshold = defineField({
        type: FieldType.Number,
        name: 'manual_review_threshold',
        scheme: yup.number().requiredNullEqualTo0().integer().min(0).max(MAX_UINT_32),
        props: {
            label: 'Manual review part quantity threshold',
            tooltip: 'Minimum amount per line item triggering manual review.',
            rightAddon: 'pcs',
            precision: 0,
        },
    });

    const tbd_quantity_threshold = defineField({
        type: FieldType.Number,
        name: 'tbd_quantity_threshold',
        scheme: yup.number().requiredNullEqualTo0().integer().min(0).max(MAX_INT_32),
        props: {
            label: 'TBD part quantity threshold',
            helpText:
                'All the orders with quantity equal or greater than this value will be hold under the manual review',
            rightAddon: 'pcs',
            precision: 0,
        },
    });

    const time_per_clamp = defineField({
        type: FieldType.Number,
        name: 'time_per_clamp',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Time per clamp/reclamp',
            tooltip: `We will count the number of times the blank has to be turned to create
            all the features. This we will multiply by the minutes you input. The result
            will be added to the total time of production.`,
            rightAddon: 'minutes',
        },
    });

    const price_of_model_height = defineField({
        type: FieldType.Number,
        name: 'price_of_model_height',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of model height',
            tooltip:
                'This can be an alternative or an addition to the nominal ' +
                'growth rate/price per hour combination above.',
            rightAddon: `${currencySign} per mm`,
        },
    });

    const price_of_area_surface = defineField({
        type: FieldType.Number,
        name: 'price_of_area_surface',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of surface area, per piece printing',
            tooltip: `Meant is the surface area of the model. This is where
        the extruder does most of his work and where most of the material
        goes, so is in fact more interesting than the volume of the model.`,
            helpText: 'Of the model itself',
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const price_of_area_surface_batch = defineField({
        type: FieldType.Number,
        name: 'price_of_area_surface_batch',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of surface area, batch printing',
            tooltip: `If you use price per cm2 model surface area batch printing,
        the price per cm2 model surface area will be counted only once, however
        many copies of the model are ordered.`,
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const price_of_area_surface_batch_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'price_of_area_surface_batch_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Price of surface area, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const price_of_area_bounding_box = defineField({
        type: FieldType.Number,
        name: 'price_of_area_bounding_box',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of surface area bounding box',
            tooltip: 'Meant are the max dimensions of the model, measured along x and y.',
            helpText: 'Meant are the max dimensions of the model, measured along x and y',
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const price_of_area_bounding_box_batch = defineField({
        type: FieldType.Number,
        name: 'price_of_area_bounding_box_batch',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of Surface area bounding box, batch printing',
            tooltip:
                'If you use Surface area bounding box batch printing, the Surface area will be counted ' +
                'only once, however many copies of the model are ordered.',
            rightAddon: `${currencySign} per cm²`,
        },
    });

    const price_of_area_bounding_box_batch_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'price_of_area_bounding_box_batch_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Price of surface area bounding box, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const price_of_volume_bounding_box = defineField({
        type: FieldType.Number,
        name: 'price_of_volume_bounding_box',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of volume bounding box, per piece printing',
            tooltip: 'This should help in case you want to print multiple models at once.',
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const price_of_volume_bounding_box_batch = defineField({
        type: FieldType.Number,
        name: 'price_of_volume_bounding_box_batch',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price of volume bounding box, batch printing',
            tooltip: `If you use price per cm3 bounding box volume batch printing,
            the price per cm3 bounding box volume will be counted only once,
            however many copies of the model are ordered.`,
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const price_of_volume_bounding_box_batch_recurring = defineField({
        type: FieldType.Checkbox,
        name: 'price_of_volume_bounding_box_batch_recurring',
        scheme: yup.boolean().default(false),
        props: {
            label: 'Price of volume bounding box, batch, recurring',
            tooltip: `When multiple copies of one part are ordered, they may not all fit one printer envelope.
            We check how many time a new print batch needs to be started given the quantity of
            copies ordered, and multiply that number with the cost you input here. Then we add the
            product of the multiplication to the total cost of the line item. That total cost is
            then divided by the number of copies and shown as price per part.`,
        },
    });

    const price_per_volume_mvbb = defineField({
        type: FieldType.Number,
        name: 'price_per_volume_mvbb',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price per volume of minimal bounding box',
            rightAddon: `${currencySign} per cm³`,
        },
    });

    const price_per_shell = defineField({
        type: FieldType.Number,
        name: 'price_per_shell',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Price per shell',
            rightAddon: currencySign,
        },
    });

    const features_operator_involvement = definePercentField({
        name: 'features_operator_involvement',
        props: {
            label: 'Operator involvement (features)',
        },
    });

    const machining_operator_involvement = definePercentField({
        name: 'machining_operator_involvement',
        props: {
            label: 'Operator involvement (machining)',
        },
    });

    const rate_of_operator = defineField({
        type: FieldType.Number,
        name: 'rate_of_operator',
        scheme: getBasicNumberScheme(),
        props: { label: 'Operator rate per hour', rightAddon: `${currencySign} per hour` },
    });

    const rate_of_programmer = defineField({
        type: FieldType.Number,
        name: 'rate_of_programmer',
        scheme: getBasicNumberScheme(),
        props: {
            label: 'Programmer rate per hour',
            rightAddon: `${currencySign} per hour`,
        },
    });

    const mvbb_enabled = defineField({
        type: FieldType.Switch,
        name: 'mvbb_enabled',
        scheme: yup.boolean().default(false),
        props: {
            formControlProps: { width: 'auto' },
        },
    });

    return {
        title,
        custom_tech,
        minimum_price_of_line_items,
        accept_drawing,
        is_iqt_only,
        size_x,
        size_y,
        size_z,
        min_size_x,
        min_size_y,
        min_size_z,
        min_size_x_threshold,
        min_size_y_threshold,
        min_size_z_threshold,
        tbd_complexity_threshold,
        tbd_no_suitable_feature_enabled,
        max_heat_absorption,
        batch_recurring_mode,
        machine_speed,
        finishing_speed,
        horizontal_printing_speed,
        nominal_growth_rate,
        nominal_growth_rate_batch,
        nominal_sintering_rate,
        nominal_melting_rate,
        nominal_layer_thickness,
        price_per_hour,
        price_per_hour_batch,
        price_per_hour_batch_recurring,
        per_part_fee,
        manual_review_threshold,
        tbd_quantity_threshold,
        time_per_clamp,
        price_of_model_height,
        price_of_area_surface,
        price_of_area_surface_batch,
        price_of_area_surface_batch_recurring,
        price_of_area_bounding_box,
        price_of_area_bounding_box_batch,
        price_of_area_bounding_box_batch_recurring,
        price_of_volume_bounding_box,
        price_of_volume_bounding_box_batch,
        price_of_volume_bounding_box_batch_recurring,
        price_per_volume_mvbb,
        price_per_shell,
        features_operator_involvement,
        machining_operator_involvement,
        rate_of_operator,
        rate_of_programmer,
        mvbb_enabled,
    } as const;
};

export type MachineFieldsConfig = ReturnType<typeof getMachineFieldsConfig>;
export type MachineCommonFields = keyof MachineFieldsConfig;

export const getMachineCompoundFieldsConfig = ({
    size_x,
    size_y,
    size_z,
    min_size_x,
    min_size_y,
    min_size_z,
    min_size_x_threshold,
    min_size_y_threshold,
    min_size_z_threshold,
}: MachineFieldsConfig) => {
    const travel: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Maximum allowed part size',
            tooltip:
                "These are your machine's dimensions, or the max size part you want to accept for quoting.",
            rightAddon: 'mm',
        },
        fields: [size_x, size_y, size_z],
    };

    const minPartSizeAnd: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Minimum allowed part size (AND logic)',
            tooltip: 'This setting allows parts that are larger in all 3 dimensions.',
            rightAddon: 'mm',
        },
        fields: [min_size_x, min_size_y, min_size_z],
    };

    const minPartSizeOr: CompoundFieldConfigs = {
        type: CompoundFieldType.MultipleInput,
        props: {
            label: 'Minimum allowed part size (OR logic)',
            tooltip: 'This setting allows parts that are larger in at least one of the dimensions.',
            rightAddon: 'mm',
        },
        fields: [min_size_x_threshold, min_size_y_threshold, min_size_z_threshold],
    };

    const dimensions3 = { travel, minPartSizeAnd, minPartSizeOr };
    const dimensions2 = {
        travel: {
            ...travel,
            fields: [size_x, { ...size_y, props: { ...size_y.props, placeholder: 'Diameter' } }],
        },
        minPartSizeAnd: {
            ...minPartSizeAnd,
            fields: [min_size_x, min_size_y],
        },
        minPartSizeOr: {
            ...minPartSizeOr,
            fields: [min_size_x_threshold, min_size_y_threshold],
        },
    };

    return { dimensions3, dimensions2 };
};

export const getMachineFieldsConfigByTechnology = (
    technologyId: number | undefined,
    fields: MachineFieldsConfig,
): Partial<MachineFieldsConfig> | undefined => {
    const {
        title,
        custom_tech,
        minimum_price_of_line_items,
        accept_drawing,
        is_iqt_only,
        size_x,
        size_y,
        size_z,
        min_size_x,
        min_size_y,
        min_size_z,
        min_size_x_threshold,
        min_size_y_threshold,
        min_size_z_threshold,
        tbd_complexity_threshold,
        tbd_no_suitable_feature_enabled,
        max_heat_absorption,
        batch_recurring_mode,
        machine_speed,
        finishing_speed,
        horizontal_printing_speed,
        nominal_growth_rate,
        nominal_growth_rate_batch,
        nominal_sintering_rate,
        nominal_melting_rate,
        nominal_layer_thickness,
        price_per_hour,
        price_per_hour_batch,
        price_per_hour_batch_recurring,
        per_part_fee,
        manual_review_threshold,
        tbd_quantity_threshold,
        time_per_clamp,
        price_of_model_height,
        price_of_area_surface,
        price_of_area_surface_batch,
        price_of_area_surface_batch_recurring,
        price_of_area_bounding_box,
        price_of_area_bounding_box_batch,
        price_of_area_bounding_box_batch_recurring,
        price_of_volume_bounding_box,
        price_of_volume_bounding_box_batch,
        price_of_volume_bounding_box_batch_recurring,
        price_per_volume_mvbb,
        price_per_shell,
        // features_operator_involvement,
        // machining_operator_involvement,
        rate_of_operator,
        rate_of_programmer,
        mvbb_enabled,
    } = fields;

    const baseFields = {
        title,
        custom_tech,
        minimum_price_of_line_items,
        accept_drawing,
        is_iqt_only,
        size_x,
        size_y,
        size_z,
        min_size_x,
        min_size_y,
        min_size_z,
        min_size_x_threshold,
        min_size_y_threshold,
        min_size_z_threshold,
        rate_of_operator,
        rate_of_programmer,
    };
    const specs3DOneOffFields = {
        nominal_growth_rate_batch,
        price_per_hour_batch,
        price_per_hour_batch_recurring,
        batch_recurring_mode,
        max_heat_absorption,
    };
    const geometryOneOffFields = {
        price_of_area_surface_batch,
        price_of_area_surface_batch_recurring,
        price_of_area_bounding_box_batch,
        price_of_area_bounding_box_batch_recurring,
        price_of_volume_bounding_box_batch,
        price_of_volume_bounding_box_batch_recurring,
    };

    const nominalLayerThicknessTooltip =
        'You can either use the value given by the manufacturer, or, better, use\nempirical data ' +
        'for a 60, 70, or 80% filled printer, printing with the\nlayer thickness which ' +
        'you will have to check or edit here below.';

    const nominalGrowthRateTooltip =
        'You can either use the value given by the manufacturer, or, better, use\nempirical data ' +
        'for a model that fills 50% of the printer, printing with\nthe layer thickness which ' +
        'you will have to check or edit here below.';

    const priceOfAreaSurfaceUpdated = price_of_area_surface.updateProps({
        tooltip: 'Meant is the surface area of the model.',
    });

    const priceOfAreaBoundingBoxUpdated = price_of_area_bounding_box.updateProps({
        label: 'Price of surface area bounding box, per piece printing',
        tooltip:
            'If you use Surface area bounding box per piece printing, ' +
            'the Surface\narea will be counted for every separate copy ordered.',
    });

    switch (technologyId) {
        case Technologies['SLS']:
        case Technologies['HP MJF']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                nominal_growth_rate,
                nominal_sintering_rate,
                nominal_layer_thickness,
                per_part_fee,
                price_per_hour,
                manual_review_threshold,
                tbd_quantity_threshold,

                price_of_model_height,
                price_of_area_surface: priceOfAreaSurfaceUpdated,
                price_of_area_bounding_box: priceOfAreaBoundingBoxUpdated,
                price_of_volume_bounding_box,

                price_per_volume_mvbb,
                price_per_shell,
            });
        }

        case Technologies['Polyjet']:
        case Technologies['Multijet']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                horizontal_printing_speed,
                nominal_growth_rate: nominal_growth_rate.default(30).updateProps({
                    label: 'Nominal growth rate',
                    tooltip: nominalGrowthRateTooltip,
                }),
                nominal_layer_thickness: nominal_layer_thickness.default(76).updateProps({
                    tooltip: nominalLayerThicknessTooltip,
                }),
                per_part_fee,
                price_per_hour,
                manual_review_threshold,
                tbd_quantity_threshold,

                price_of_model_height,
                price_of_area_surface: priceOfAreaSurfaceUpdated,
                price_of_area_bounding_box,
                price_of_volume_bounding_box,
            });
        }

        case Technologies['3DP']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                nominal_growth_rate: nominal_growth_rate.default(20).updateProps({
                    tooltip: nominalLayerThicknessTooltip,
                }),
                nominal_layer_thickness: nominal_layer_thickness.default(100).updateProps({
                    tooltip: nominalLayerThicknessTooltip,
                }),
                per_part_fee,
                price_per_hour,
                manual_review_threshold,
                tbd_quantity_threshold,

                price_of_model_height,
                price_of_area_surface: priceOfAreaSurfaceUpdated,
                price_of_area_bounding_box,
                price_of_volume_bounding_box,
            });
        }

        case Technologies['SLA']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                nominal_growth_rate,
                nominal_layer_thickness,
                per_part_fee,
                price_per_hour,
                manual_review_threshold,
                tbd_quantity_threshold,

                price_of_model_height,
                price_of_area_surface: priceOfAreaSurfaceUpdated,
                price_of_area_bounding_box: priceOfAreaBoundingBoxUpdated,
                price_of_volume_bounding_box,
            });
        }

        case Technologies['SLM']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                nominal_growth_rate: nominal_growth_rate.default(30),
                nominal_melting_rate,
                nominal_layer_thickness: nominal_layer_thickness.default(200),
                per_part_fee: per_part_fee.default(20),
                price_per_hour,
                manual_review_threshold,
                tbd_quantity_threshold,

                price_of_model_height,
                price_of_area_surface: priceOfAreaSurfaceUpdated,
                price_of_area_bounding_box: priceOfAreaBoundingBoxUpdated,
                price_of_volume_bounding_box,
            });
        }

        case Technologies['FDM']: {
            return Object.assign(baseFields, specs3DOneOffFields, geometryOneOffFields, {
                nominal_growth_rate_batch: nominal_growth_rate_batch.default(10),
                horizontal_printing_speed,
                nominal_growth_rate,
                nominal_layer_thickness,
                per_part_fee,
                price_per_hour: price_per_hour.default(75),
                manual_review_threshold: manual_review_threshold.default(100),
                tbd_quantity_threshold,
                price_of_area_surface,
                price_of_area_bounding_box,
                price_of_volume_bounding_box,
            });
        }

        case Technologies['CNC Sheetmetal']:
        case Technologies['CNC Cutter']: {
            return Object.assign(baseFields, {
                per_part_fee: per_part_fee.default(1),
                price_per_hour: price_per_hour.default(10).updateProps({
                    tooltip:
                        'This hourly rate will be multiplied by the estimated machining\n' +
                        'time, which depends on the chosen material thickness feed rate.',
                }),
                manual_review_threshold: manual_review_threshold.default(100),
                tbd_complexity_threshold,
                tbd_quantity_threshold,
                tbd_no_suitable_feature_enabled,
            });
        }

        case Technologies['3-Axis Milling']:
        case Technologies['4-Axis Milling']:
        case Technologies['Multi-Axis Milling']: {
            return Object.assign(baseFields, {
                machine_speed: machine_speed.default(1000),
                finishing_speed: finishing_speed.default(500),
                time_per_clamp,
                per_part_fee: per_part_fee.default(1),
                price_per_hour: price_per_hour.default(125),
                manual_review_threshold: manual_review_threshold.default(100),
                tbd_complexity_threshold,
                tbd_quantity_threshold,
                tbd_no_suitable_feature_enabled,
                mvbb_enabled,
            });
        }

        case Technologies['Turning']: {
            return Object.assign(baseFields, {
                machine_speed: machine_speed.default(2500),
                finishing_speed: finishing_speed.default(1500),
                per_part_fee: per_part_fee.default(25),
                price_per_hour: price_per_hour.default(50),
                manual_review_threshold: manual_review_threshold.default(100),
                tbd_complexity_threshold,
                tbd_quantity_threshold,
                tbd_no_suitable_feature_enabled,
            });
        }

        default: {
            return baseFields;
        }
    }
};

type MachineConfigByTechnologyArgs = MachineFieldsConfigArgs & {
    technologyId?: number;
    isCreation?: boolean;
};

export const getMachineFormConfigByTechnology = ({
    currencySign = '$',
    technologyId,
    isCreation = false,
}: MachineConfigByTechnologyArgs = {}) => {
    const machineFields = getMachineFieldsConfig({ currencySign });
    const compoundFields = getMachineCompoundFieldsConfig(machineFields);
    const fields = getMachineFieldsConfigByTechnology(technologyId, machineFields);

    // nested entities
    const bracketing = isBelongCNC(technologyId)
        ? getComplexityBracketingConfig({ path: 'bracketing' })
        : undefined;
    const features = isBelongCNC(technologyId)
        ? getMachineFeatureConfig({ currencySign, technologyId })
        : undefined;
    const tolerances = isBelongCNC(technologyId) ? getMachineToleranceConfig() : undefined;
    const priorities = getMachinePriorityConfig();

    // @ts-ignore
    let schema = yup.object(mapValues(fields, 'scheme')).shape({
        technology: yup.number().nullable().default(null),
        organizations_enabled: yup.array(getOrganizationsEnabledSchema()).ensure().compact(),
        priorities: yup
            .array(priorities.schema)
            .ensure()
            .compact()
            .default(isCreation ? [priorities.schema.getDefault()] : undefined),
    });

    if (bracketing) {
        schema = schema.shape({
            bracketing: isCreation ? bracketing.schema : bracketing.schema.default(undefined),
        });
    }

    if (features) {
        schema = schema.shape({
            features: yup.array(features.schema).ensure().compact(),
            tbd_no_suitable_feature_enabled: machineFields.tbd_no_suitable_feature_enabled.scheme,
        });
    }

    if (tolerances) {
        schema = schema.shape({
            tolerances: yup
                .array(tolerances.schema)
                .ensure()
                .compact()
                .default(isCreation ? [tolerances.schema.getDefault()] : undefined),
        });
    }

    const cast = (data: Machine, opts: CastOptions = {}) => {
        const cast = schema.cast(data, opts);

        return { ...cast, ...overwriteMachineDefaultValues(data) };
    };

    return { compoundFields, fields, features, tolerances, priorities, bracketing, schema, cast };
};

export type MachineFormConfigType = ReturnType<typeof getMachineFormConfigByTechnology>;

// console.log(
//     'cast:',
//     getMachineConfigByTechnology({
//         technologyId: 10,
//         currency: {
//             title: null,
//             position: 'left',
//             separator: '',
//             decimal: '',
//             decimal_digits: 0,
//             symbol: '$',
//         },
//     }).schema.cast({}),
// );
// console.log(
//     'getDefault:',
//     getMachineConfigByTechnology({
//         technologyId: 10,
//         currency: {
//             title: null,
//             position: 'left',
//             separator: '',
//             decimal: '',
//             decimal_digits: 0,
//             symbol: '$',
//         },
//     }).schema.getDefault(),
// );
