import { dfApi as api } from '@services/df/baseApi';
import { Nilable } from '@shared/types';
import { Technologies, TechnologyIdsUnion } from '@shared/constants';

export const getPriceMatchSlugByTechnology = (techologyId: Nilable<TechnologyIdsUnion>) => {
    switch (techologyId) {
        case Technologies['SLM']:
        case Technologies['SLA']:
            return 'sla_slm';

        case Technologies['Multi-Axis Milling']:
        case Technologies['3-Axis Milling']:
        case Technologies['4-Axis Milling']:
            return 'milling';

        case Technologies['Turning']:
            return 'turning';

        case Technologies['SLS']:
        case Technologies['3DP']:
        case Technologies['HP MJF']:
            return 'supportless_3d';

        case Technologies['FDM']:
            return 'fdm';

        default: {
            return '';
        }
    }
};

export interface PriceMatchFields {
    nominal_layer_thickness?: number | null; // absent in backend serializers
    machine_speed: number;
    programming_cost: number;
    price_per_sq_cm: number;
    bracketing_first_boundary: number;
    bracketing_second_boundary: number;
    bracketing_third_boundary: number;
    bracketing_fourth_boundary: number;
    finishing_speed: number;
    price_per_gram_batch: number;
    price: number;
    power_for_volume: number;
    price_per_gram: number;
    filling_level_of_support: number;
    cost_per_subtracted_material: number;
    cost_per_surface_area: number;
    cost_per_volume_bb: number;
    price_per_kilogram: number;
    nominal_growth_rate_batch: number;
    price_per_hour_batch: number;
    price_of_area_bounding_box_batch: number;
    price_of_volume_bounding_box_batch: number;
    price_of_area_surface_batch: number;
    per_part_fee: number;
    nominal_growth_rate: number;
    price_per_hour: number;
    price_of_area_bounding_box: number;
    price_of_volume_bounding_box: number;
    price_of_area_surface: number;
    feature_per_piece_cuboid: number;
    feature_per_piece_axial: number;
    feature_per_piece_radial: number;
    feature_per_piece_reduction: number;
    bracketing_first_multiplier: number;
    bracketing_second_multiplier: number;
    bracketing_third_multiplier: number;
    bracketing_fourth_multiplier: number;
    bracketing_fifth_multiplier: number;
    feature_setup_axial: number;
    feature_setup_radial: number;
    feature_setup_reduction: number;
    price_per_gram_of_support_batch: number;
    price_per_gram_of_support: number;

    // todo create interface
    // // base milling turning
    // programming_cost: number;
    // cost_per_subtracted_material: number;
    // cost_per_surface_area: number;
    // cost_per_volume_bb: number;
    // feature_setup_cuboid: number;
    // feature_setup_axial: number;
    // feature_setup_radial: number;
    // feature_setup_reduction: number;
    // per_part_fee: number;
    // machine_speed: number;
    // price_per_sq_cm: number;
    // feature_per_piece_cuboid: number;
    // feature_per_piece_axial: number;
    // feature_per_piece_radial: number;
    // feature_per_piece_reduction: number;
    // price_per_kilogram: number;
    // bracketing_first_multiplier: number;
    // bracketing_second_multiplier: number;
    // bracketing_third_multiplier: number;
    // bracketing_fourth_multiplier: number;
    // bracketing_fifth_multiplier: number;
    // // milling
    // feature_setup_cuboid // todo already in base milling turning
    // feature_per_piece_cuboid // todo already in base milling turning
    // // turning
    // finishing_speed: number;
    // bracketing_first_boundary: number;
    // bracketing_second_boundary: number;
    // bracketing_third_boundary: number;
    // bracketing_fourth_boundary: number;
    // // base supportless_3d sla_slm fdm
    // programming_cost: number;
    // price_per_hour_batch: number;
    // price_of_volume_bounding_box_batch: number;
    // price_per_gram_batch: number;
    // price_of_area_surface_batch: number;
    // per_part_fee: number;
    // price_per_hour: number;
    // price: number;
    // power_for_volume: number;
    // price_of_area_surface: number;
    // // supportless_3d
    // nominal_growth_rate_batch: number;
    // nominal_growth_rate: number;
    // price_of_area_bounding_box_batch: number;
    // price_of_area_bounding_box: number;
    // price_of_volume_bounding_box: number;
    // // sla_slm
    // nominal_growth_rate_batch: number;
    // nominal_growth_rate: number;
    // price_of_area_bounding_box_batch: number;
    // price_of_area_bounding_box: number;
    // price_of_volume_bounding_box: number;
    // price_per_gram: number;
    // filling_level_of_support: number;
    // // fdm
    // price_per_gram: number;
    // filling_level_of_support: number;
    // price_per_gram_of_support: number;
    // price_per_gram_of_support_batch: number;
}

export interface PriceMatchRecalculatedFields {
    material_id: number;
    model_ids: number[];
    fields: Partial<PriceMatchFields>;
}

export interface PriceMatchTaskId {
    task_id: string;
}

export interface PriceMatchTask {
    status: 'success' | 'in_process' | 'failed';
    is_ready: boolean;
    result?: PriceMatchResult;
}

export interface PriceMatchResult {
    fields: PriceMatchFields;
    user_prices: PriceMatchPriceSet;
    automatic_prices: PriceMatchPriceSet;
}

export interface PriceMatchPriceSet {
    prices_1: number[];
    prices_10: number[];
}

export interface PriceMatchEnteredPrices {
    material_id: number;
    layer_thickness?: number | null;
    infill?: number;
    model_ids: number[];
    prices_1: number[];
    prices_10: number[];
}

interface PriceMatchRetrieveArg {
    taskId: string;
    technologyId: TechnologyIdsUnion;
}

interface PriceMatchCreateArg {
    payload: PriceMatchEnteredPrices;
    technologyId: TechnologyIdsUnion;
}

interface PriceMatchRecalculateArg {
    payload: PriceMatchRecalculatedFields;
    technologyId: TechnologyIdsUnion;
}

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        priceMatchRetrieve: build.query<PriceMatchTask, PriceMatchRetrieveArg>({
            query: queryArg => ({
                url: `/price_match/${getPriceMatchSlugByTechnology(queryArg.technologyId)}/${
                    queryArg.taskId
                }`,
            }),
        }),
        priceMatchCreate: build.mutation<PriceMatchTaskId, PriceMatchCreateArg>({
            query: queryArg => ({
                url: `/price_match/${getPriceMatchSlugByTechnology(queryArg.technologyId)}/`,
                method: 'POST',
                body: queryArg.payload,
            }),
        }),
        priceMatchRecalculate: build.mutation<PriceMatchPriceSet, PriceMatchRecalculateArg>({
            query: queryArg => ({
                url: `/price_match/${getPriceMatchSlugByTechnology(
                    queryArg.technologyId,
                )}/recalculate_results/`,
                method: 'POST',
                body: queryArg.payload,
            }),
        }),
    }),
    overrideExisting: false,
});

export { injectedRtkApi as enhancedApi };

export const {
    usePriceMatchRetrieveQuery,
    usePriceMatchCreateMutation,
    usePriceMatchRecalculateMutation,
} = injectedRtkApi;
