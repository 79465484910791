import { createPathComposer } from '@shared/utils/strings';

export const [composeName, getNameComposer] = createPathComposer('.');

/*
    Usage outside component:
        const _name = getNameComposer('deep', 'nested', 'form'); // just define at the beginning of the file
        ...
        <Input name={_name('field1')} />
        <Input name={_name('field2')} />
        ...

    Usage inside component (array form):
        const _name = useCallback(getNameComposer('pet', index), [index]); // with memo
        const [_name] = useState(() => getNameComposer('pet', index)); // or if component is unmounted on index change
        ...
        <Input name={_name('gender')} />
        <Input name={_name('age')} />
        ...
*/
