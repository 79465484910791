import { useMemo } from 'react';
import { useMatch } from 'react-router';
import capitalize from 'lodash/capitalize';
import { useAppSelector } from '@app/hooks';
import { selectCurrency, selectOrderStatuses } from '@ducks/app';
import { getSingleFieldConfig } from '@shared/components/forms-v2';
import { View } from '@ducks/filters';
import { ROUTES } from '@shared/constants';
import { OrderFieldsUnion, getOrderConfig } from './config';

export interface UseOrderFieldProps {
    name: OrderFieldsUnion;
    value?: string | number | null;
}

export const useOrderField = ({ name, value }: UseOrderFieldProps) => {
    const currency = useAppSelector(selectCurrency)!;

    return useMemo(
        () =>
            getSingleFieldConfig(
                getOrderConfig({ currencySign: currency.symbol }).fields[name],
                value,
            ),
        [currency, name, value],
    );
};

export const useOrdersView = () => {
    const isAccomplished = Boolean(useMatch(ROUTES.ORDERS_MAIN));
    const isDraft = Boolean(useMatch(ROUTES.ORDERS_DRAFTS));
    const isCart = Boolean(useMatch(ROUTES.ORDERS_CARTS));
    const isLineItems = Boolean(useMatch(ROUTES.ORDERS_LINE_ITEMS));

    let view = View.Orders;
    if (isDraft) view = View.Drafts;
    if (isCart) view = View.Carts;
    if (isLineItems) view = View.LineItems;

    return { isAccomplished, isDraft, isCart, isLineItems, view };
};

export const useOrderStatusLabels = () => {
    const orderStatuses = useAppSelector(selectOrderStatuses)!;
    const orderUserStatuses = useMemo<{ [key: string]: { label: string; group: string } }>(
        () =>
            orderStatuses.reduce((accum, { code, custom_label, label, group }) => {
                return { ...accum, [code]: { label: capitalize(custom_label || label), group } };
            }, {}),
        [orderStatuses],
    );
    return orderUserStatuses;
};
