import { useCallback } from 'react';
import { useAppDispatch } from '@app/hooks';
import {
    ToleranceRequest,
    PatchedToleranceRequest,
    useMachinesTolerancesUpdateMutation,
    useMachinesTolerancesPartialUpdateMutation,
    useMachinesTolerancesDestroyMutation,
    enhancedApi as api,
} from '@services/df/machines-materials';

interface UseMachinePriorityCrud {
    technologyId: number;
    machineId: number;
}

export const useMachineToleranceCreate = ({ technologyId, machineId }: UseMachinePriorityCrud) => {
    const dispatch = useAppDispatch();
    const [create, state] = useMachinesTolerancesUpdateMutation();
    return {
        create: useCallback(
            async (form: ToleranceRequest & { id: string }) => {
                const created = await create({
                    machineId,
                    toleranceRequest: form,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            if (draft.tolerances) {
                                draft.tolerances.push(created);
                            } else {
                                draft.tolerances = [created];
                            }
                        },
                    ),
                );

                return created;
            },
            [dispatch, create, technologyId, machineId],
        ),
        state,
    };
};

export const useMachineToleranceUpdate = ({ technologyId, machineId }: UseMachinePriorityCrud) => {
    const dispatch = useAppDispatch();
    const [update, state] = useMachinesTolerancesPartialUpdateMutation();

    return {
        update: useCallback(
            async (id: string, form: PatchedToleranceRequest) => {
                const updated = await update({
                    id,
                    machineId,
                    patchedToleranceRequest: form,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            const index = draft.tolerances?.findIndex(feature => feature.id === id);
                            if (index !== -1) draft.tolerances![index!] = updated;
                        },
                    ),
                );

                return updated;
            },
            [dispatch, update, technologyId, machineId],
        ),
        state,
    };
};

export const useMachineToleranceDestroy = ({ technologyId, machineId }: UseMachinePriorityCrud) => {
    const dispatch = useAppDispatch();
    const [destroy, state] = useMachinesTolerancesDestroyMutation();

    return {
        destroy: useCallback(
            async (id: string) => {
                await destroy({
                    id,
                    machineId,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            const index = draft.tolerances!.findIndex(feature => feature.id === id);
                            if (index !== -1) draft.tolerances!.splice(index, 1);
                        },
                    ),
                );
            },
            [dispatch, destroy, technologyId, machineId],
        ),
        state,
    };
};
