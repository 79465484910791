import React from 'react';
import { Grid, Text } from '@chakra-ui/react';
import { useAppSelector } from '@app/hooks';
import {
    SystemMessage as SystemMessageProps,
    useSystemMessagesListQuery,
    useSystemMessagesPartialUpdateMutation,
} from '@services/df/system-messages';
import { SERVICE_LABELS } from '@services/df/codegen-enums-labels';
import { selectSystemMessages, selectIsSystemMessagesLoaded } from '@ducks/system-messages';
import { Alert } from '@shared/components/alert';
import { DateTime } from '@shared/components/datetime';
import { getPaginationRequestParams } from '@shared/components/pagination';
import { PreloaderBox } from '@shared/components/preloader';

export const SystemMessage = ({
    id,
    service,
    logging_level,
    error_code,
    message,
    resolved,
    updated_at,
}: SystemMessageProps) => {
    const _title = SERVICE_LABELS[service];
    const title = (
        <Text fontWeight="600" mb={1}>
            {error_code ? `${_title}: ${error_code}` : _title}
        </Text>
    );
    const description = message ? (
        <>
            {title}
            <Text mb={2}>{message}</Text>
        </>
    ) : (
        title
    );

    const [update] = useSystemMessagesPartialUpdateMutation();
    return (
        <Alert
            key={id}
            variant="neutral"
            status={logging_level}
            // title={title}
            description={description}
            onClose={() => update({ id, patchedSystemMessageUpdateRequest: { resolved: true } })}
            isClosable
        >
            <Text textStyle="typography-xs" color="font.secondary">
                <DateTime value={updated_at} />
            </Text>
        </Alert>
    );
};

const params = {
    ...getPaginationRequestParams(),
    resolved: false,
};

export const SystemMessagesList = () => {
    const messages = useAppSelector(selectSystemMessages);
    const isMessagesLoaded = useAppSelector(selectIsSystemMessagesLoaded);
    const { isLoading } = useSystemMessagesListQuery(params, {
        skip: isMessagesLoaded,
    });
    const hasMessages = Boolean(messages.length);

    return (
        <PreloaderBox in={isLoading}>
            <Grid rowGap={4} minH={isLoading ? 20 : 0}>
                {hasMessages
                    ? messages.map(message => <SystemMessage key={message.id} {...message} />)
                    : isLoading
                    ? null
                    : 'No messages'}
            </Grid>
        </PreloaderBox>
    );
};
