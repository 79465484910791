import { Icon, IconProps } from '@chakra-ui/react';

export const PriceTweakerIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M3 5.5C3.82843 5.5 4.5 4.82843 4.5 4C4.5 3.17157 3.82843 2.5 3 2.5C2.17157 2.5 1.5 3.17157 1.5 4C1.5 4.82843 2.17157 5.5 3 5.5Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M3 5.5V13.5"
            stroke="#7F91A2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 11.5C8.82843 11.5 9.5 10.8284 9.5 10C9.5 9.17157 8.82843 8.5 8 8.5C7.17157 8.5 6.5 9.17157 6.5 10C6.5 10.8284 7.17157 11.5 8 11.5Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 2.5V8.5"
            stroke="#7F91A2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M8 11.5V13.5"
            stroke="#7F91A2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 8.5C13.8284 8.5 14.5 7.82843 14.5 7C14.5 6.17157 13.8284 5.5 13 5.5C12.1716 5.5 11.5 6.17157 11.5 7C11.5 7.82843 12.1716 8.5 13 8.5Z"
            stroke="currentColor"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 8.5V13.5"
            stroke="#7F91A2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M13 2.5V5.5"
            stroke="#7F91A2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
