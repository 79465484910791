import isNil from 'lodash/isNil';
import isString from 'lodash/isString';
import isUndefined from 'lodash/isUndefined';
import { Children, ReactNode } from 'react';
import {
    Button,
    ButtonProps,
    Grid,
    GridProps,
    Link,
    LinkProps,
    Text,
    TextProps,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

interface LineItemValueProps {
    zeroAsEmpty?: boolean;
}

export const LINE_ITEM_EMPTY_VALUE = '—';

export const isEmpty = (node: ReactNode, zeroAsEmpty = false) =>
    node === '' || node === false || isNil(node) || (zeroAsEmpty && node === 0);

export interface LineItemProps extends TextProps {
    label: ReactNode;
    labelProps?: TextProps;
    value?: ReactNode;
    valueProps?: LineItemValueProps & TextProps;
}

export const LineItem = ({
    label,
    labelProps,
    value,
    valueProps,
    children,
    ...props
}: LineItemProps) => (
    <Text as="div" display="flex" alignItems="baseline" textStyle="typography-sm" {...props}>
        {isString(label) ? (
            <Text as="span" whiteSpace="nowrap" mr={1} {...labelProps}>
                {label}:{' '}
            </Text>
        ) : (
            label
        )}

        {isUndefined(value) ? children : <LineItemValue {...valueProps}>{value}</LineItemValue>}
    </Text>
);

export const LineItemValue = ({
    children,
    zeroAsEmpty,
    ...props
}: LineItemValueProps & TextProps) => (
    <Text as="span" fontWeight="600" {...props}>
        {isEmpty(children, zeroAsEmpty) ? LINE_ITEM_EMPTY_VALUE : children}
    </Text>
);

const hoverStyles = { textDecoration: 'underline' };

export const LineItemEditValue = forwardRef<LineItemValueProps & ButtonProps, 'button'>(
    ({ children, zeroAsEmpty, ...props }, ref) => {
        const _isEmpty = isEmpty(children, zeroAsEmpty);
        return (
            <Button
                ref={ref}
                variant="link"
                letterSpacing="button"
                userSelect="text"
                whiteSpace="normal"
                wordBreak="break-all"
                _hover={_isEmpty ? undefined : hoverStyles}
                {...props}
            >
                <Text as="span" textAlign="left">
                    {_isEmpty ? LINE_ITEM_EMPTY_VALUE : children}
                </Text>
            </Button>
        );
    },
);

export const LineItemEmailValue = forwardRef<LineItemValueProps & LinkProps, 'a'>(
    ({ children, zeroAsEmpty, ...props }, ref) => {
        const _isEmpty = isEmpty(children, zeroAsEmpty);
        return (
            <Link
                ref={ref}
                href={_isEmpty ? undefined : `mailto:${children}`}
                variant="highlighted"
                fontWeight={600}
                {...props}
            >
                {_isEmpty ? LINE_ITEM_EMPTY_VALUE : children}
            </Link>
        );
    },
);

export const LineItems = ({ children, ...props }: GridProps) => {
    const hasChild = Boolean(Children.toArray(children).filter(Boolean).length);
    if (!hasChild) return null;

    return (
        <Grid rowGap={2} alignContent="flex-start" {...props}>
            {children}
        </Grid>
    );
};
