import { combineEpics } from 'redux-observable';
import { filter, tap, ignoreElements } from 'rxjs';
import { AppEpic } from '@app/types';
import { createToast } from '@shared/components/toast';
import { triggerToast, redirectWindow, redirectRouter } from './reducers';
import { router } from '../../index';

const redirectRouterEpic: AppEpic = action$ =>
    action$.pipe(
        filter(redirectRouter.match),
        tap(({ payload: { to, opts } }) => router.navigate(to, opts)),
        ignoreElements(),
    );

const redirectWindowEpic: AppEpic = action$ =>
    action$.pipe(
        filter(redirectWindow.match),
        tap(({ payload: { url } }) => window.location.assign(url)),
        ignoreElements(),
    );

const generateToast: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(triggerToast.match),
        tap(({ payload }) => {
            createToast(payload);
        }),
        ignoreElements(),
    );

export const appEpics = combineEpics(generateToast, redirectRouterEpic, redirectWindowEpic);
