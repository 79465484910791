import { useMemo } from 'react';
import { useAppSelector } from '@app/hooks';
import { selectNnwUrlComposer } from './selectors';

type Args = Parameters<ReturnType<typeof selectNnwUrlComposer>>;

export const useNnwUrl = (...args: Args) => {
    const mnwUrlComposer = useAppSelector(selectNnwUrlComposer);
    return useMemo(() => mnwUrlComposer(...args).toString(), [mnwUrlComposer, ...args]);
};
