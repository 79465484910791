import { createSlice } from '@reduxjs/toolkit';
import {
    InitialState,
    FetchMaterialPresetsSuccessAction,
    FetchMachinesMaterialsSuccessAction,
    CreateMaterialAction,
} from './types';

const initialState: InitialState = {
    presets: [],
    suitableMaterials: {},
    machinesMaterials: [],
    isLoadingPresets: false,
    isLoadingMachines: false,
    isCreatingMaterial: false,
};

export const createMaterialSlice = createSlice({
    name: 'createMaterial',
    initialState,
    reducers: {
        fetchMachines(state) {
            state.isLoadingMachines = true;
        },

        fetchMachinesSuccess(state, action: FetchMachinesMaterialsSuccessAction) {
            const { data } = action.payload;
            state.machinesMaterials = data;
            state.suitableMaterials = data.reduce(
                (acc, { suitable_materials, id }) => ({
                    ...acc,
                    [id]: suitable_materials,
                }),
                {},
            );
            state.isLoadingMachines = false;
        },

        fetchMachinesFailure(state) {},

        fetchMaterialPresets(state) {
            state.isLoadingPresets = true;
        },

        fetchMaterialPresetsSuccess(state, action: FetchMaterialPresetsSuccessAction) {
            state.presets = action.payload.data;
            state.isLoadingPresets = false;
        },

        fetchMaterialPresetsFailure(state) {},

        createMaterial(state, action: CreateMaterialAction) {
            state.isCreatingMaterial = true;
        },

        createMaterialSuccess(state) {
            state.isCreatingMaterial = false;
        },

        createMaterialFailure(state) {
            state.isCreatingMaterial = false;
        },
    },
});

export const {
    fetchMachines,
    fetchMachinesSuccess,
    fetchMachinesFailure,
    fetchMaterialPresets,
    fetchMaterialPresetsSuccess,
    fetchMaterialPresetsFailure,
    createMaterial,
    createMaterialSuccess,
    createMaterialFailure,
} = createMaterialSlice.actions;
