import { ResponseApiError } from '@shared/types';

export function formatResponseError(error: ResponseApiError | undefined) {
    if (!error) {
        return '';
    }

    return Object.entries(error)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');
}

// export function formatResponseErrors<T>(data: T) {
//     return Object.entries(data).reduce((acc, [key, value]) => {
//         return { ...acc, [key]: Array.isArray(value) ? value.join(', ') : value };
//     }, {} as Record<keyof T, string>);
// }
