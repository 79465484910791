import { Icon, IconProps } from '@chakra-ui/react';

export const WrenchIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 16 16"
        fill="none"
        stroke="currentColor"
        strokeWidth="1.2"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M8.85066 5.40036C8.75905 5.49382 8.70773 5.61948 8.70773 5.75036C8.70773 5.88123 8.75905 6.00689 8.85066 6.10036L9.65066 6.90036C9.74412 6.99197 9.86978 7.04328 10.0007 7.04328C10.1315 7.04328 10.2572 6.99197 10.3507 6.90036L12.2357 5.01536C12.4871 5.57095 12.5632 6.18997 12.4539 6.78993C12.3446 7.38988 12.055 7.94228 11.6238 8.37349C11.1926 8.80471 10.6402 9.09427 10.0402 9.20358C9.44028 9.3129 8.82125 9.23677 8.26566 8.98536L4.81066 12.4404C4.61175 12.6393 4.34196 12.751 4.06066 12.751C3.77936 12.751 3.50957 12.6393 3.31066 12.4404C3.11175 12.2414 3 11.9717 3 11.6903C3 11.409 3.11175 11.1393 3.31066 10.9404L6.76566 7.48536C6.51424 6.92976 6.43812 6.31074 6.54743 5.71078C6.65674 5.11083 6.9463 4.55843 7.37752 4.12722C7.80874 3.696 8.36113 3.40644 8.96109 3.29713C9.56104 3.18781 10.1801 3.26394 10.7357 3.51536L8.85566 5.39536L8.85066 5.40036Z" />
    </Icon>
);
