import { Icon, IconProps } from '@chakra-ui/react';

export const ThumbDefaultIcon = (props: IconProps) => (
    <Icon
        viewBox="0 0 80 80"
        fill="none"
        stroke="#C0C9D2"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M71.4387 53.3507V26.1624C71.4375 24.9704 71.1228 23.7998 70.5263 22.7678C69.9298 21.7358 69.0724 20.8789 68.0401 20.2829L44.2503 6.68874C43.217 6.09217 42.0449 5.77811 40.8518 5.77811C39.6587 5.77811 38.4865 6.09217 37.4532 6.68874L13.6634 20.2829C12.6312 20.8789 11.7738 21.7358 11.1773 22.7678C10.5808 23.7998 10.2661 24.9704 10.2649 26.1624V53.3507C10.2661 54.5427 10.5808 55.7134 11.1773 56.7453C11.7738 57.7773 12.6312 58.6342 13.6634 59.2302L37.4532 72.8244C38.4865 73.421 39.6587 73.735 40.8518 73.735C42.0449 73.735 43.217 73.421 44.2503 72.8244L68.0401 59.2302C69.0724 58.6342 69.9298 57.7773 70.5263 56.7453C71.1228 55.7134 71.4375 54.5427 71.4387 53.3507Z" />
        <path d="M11.1794 22.6279L40.8487 39.7905L70.518 22.6279" />
        <path d="M40.8503 74.0142V39.7569" />
    </Icon>
);
