import type { PayloadAction, Slice } from '@reduxjs/toolkit';
import { persistReducer, PersistConfig } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import type { StoreManager } from '@app/types';
import { createPathComposer } from '@shared/utils/strings';
import { EventOrigin } from '@shared/constants';

// todo move to shared/utils/redux

let storeManager: StoreManager;

export const injectStoreManager = (_storeManager: StoreManager) => {
    storeManager = _storeManager;
};

// persist utils
export const [composePersistReducerKey, getPersistReducerKeyComposer] = createPathComposer(':');

type KeyComposer = ReturnType<
    typeof getPersistReducerKeyComposer<['backoffice', string] | ['backoffice']>
>;

type PersistReducerOptions<S, N extends string> = Partial<PersistConfig<S>> & {
    dependsOnCompany?: boolean;
    omitCondition?: () => boolean;
} & (
        | { key?: never; composeKey?: (keyComposer: KeyComposer) => string }
        | { key?: string; composeKey?: never }
    );

// key pattern 'backoffice:company?:reducer' => 'backoffice:4taps:auth'
// key pattern 'backoffice:company?:namespace?:reducer' => 'backoffice:4taps:priceMatch:config'
// pass key argument for custom key
export function createPersistReducer<S extends Slice, N extends string>(
    slice: S,
    {
        omitCondition,
        dependsOnCompany,
        composeKey: _composeKey,
        ...config
    }: PersistReducerOptions<ReturnType<S['getInitialState']>, N>,
): S['reducer'] {
    const baseReducer = slice.reducer;
    const omitPersistence = omitCondition?.();

    if (omitPersistence) {
        return baseReducer;
    }

    return persistReducer(
        {
            get key() {
                // we want to call key composer in persistReducer with existing company name,
                // but not at the time of calling createPersistReducer
                // note that we can't call store.getState() in reducer body
                const companyName =
                    dependsOnCompany && storeManager ? storeManager.companyName : '';
                const keyComposer = getPersistReducerKeyComposer(
                    'backoffice',
                    ...(companyName ? [companyName] : []),
                );

                return _composeKey
                    ? _composeKey(keyComposer)
                    : keyComposer(...slice.name.split('/'));
            },
            storage,
            version: 1,
            ...config,
        },
        baseReducer,
    );
}

// matchers
interface EventOriginMeta {
    eventOrigin: EventOrigin;
}

interface UiEventMeta extends EventOriginMeta {
    eventOrigin: EventOrigin.Ui;
}

interface EffectEventMeta extends EventOriginMeta {
    eventOrigin: EventOrigin.Effect;
}

export function hasUiEventMeta<P>(action: any): action is PayloadAction<P, string, UiEventMeta> {
    return (
        (action as PayloadAction<any, string, EventOriginMeta>).meta?.eventOrigin === EventOrigin.Ui
    );
}

export function hasEffectEventMeta<P>(
    action: any,
): action is PayloadAction<P, string, EffectEventMeta> {
    return (
        (action as PayloadAction<any, string, EventOriginMeta>).meta?.eventOrigin ===
        EventOrigin.Effect
    );
}
