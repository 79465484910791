import { mode, SystemStyleFunction } from '@chakra-ui/theme-tools';

// Filled button styles, button with background and padding

// https://github.com/chakra-ui/chakra-ui/discussions/4492
export const getFilledButtonStylesBySize = (size: string, textStyles: any) =>
    size === 'sm'
        ? {
              h: 'auto',
              minH: '28px',
              px: '3',
              py: '1.5',
              ...textStyles['button-pad-sm'],
          }
        : size === 'md'
        ? {
              h: 'auto',
              minH: '36px',
              py: '2',
              // py: '3',
              ...textStyles['button-pad-md'],
          }
        : size === 'lg'
        ? {
              ...textStyles['button-pad-lg'],
          }
        : undefined;

const variantGhost: SystemStyleFunction = props => {
    const {
        colorScheme: c,
        size,
        theme: { textStyles },
    } = props;
    const defaultActive = {
        color: 'white',
    };

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        ...(c === 'neutral' || c === 'gray'
            ? {
                  color: mode(`neutral.darkest`, `whiteAlpha.900`)(props),
                  _hover: {
                      bg: mode(`neutral.subtlest`, `whiteAlpha.200`)(props),
                  },
                  _active: { bg: mode(`neutral.subtle`, `whiteAlpha.300`)(props) },
              }
            : c === 'secondary'
            ? {
                  color: mode(`secondary.default`, `whiteAlpha.900`)(props),
                  _hover: {
                      bg: mode(`neutral.subtle`, `whiteAlpha.200`)(props),
                  },
                  _active: { bg: mode(`neutral.dark`, `whiteAlpha.300`)(props) },
              }
            : {
                  color: `${c}.100`,
                  _hover: {
                      bg: mode(`${c}.subtlest`, `whiteAlpha.200`)(props),
                  },
                  _active: defaultActive,
                  'input:focus-visible + &': defaultActive,
              }),
    };
};

const variantSolid: SystemStyleFunction = props => {
    const {
        colorScheme: c,
        size,
        theme: { textStyles },
    } = props;

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        ...(c === 'neutral' || c === 'gray'
            ? {
                  bgColor: mode(`neutral.subtlest`, `whiteAlpha.900`)(props),
                  color: mode(`neutral.darkest`, `whiteAlpha.900`)(props),

                  _hover: {
                      bgColor: mode(`neutral.subtle`, `whiteAlpha.300`)(props),
                      _disabled: {
                          bgColor: mode(`neutral.subtlest`, `whiteAlpha.900`)(props),
                      },
                  },
                  _active: { bgColor: mode(`neutral.dark`, `whiteAlpha.400`)(props) },
              }
            : c === 'secondary'
            ? {
                  bgColor: mode(`neutral.subtle`, `whiteAlpha.900`)(props),
                  color: mode(`secondary.default`, `whiteAlpha.900`)(props),

                  _hover: {
                      bgColor: mode(`neutral.subtle`, `whiteAlpha.300`)(props),
                      _disabled: {
                          bgColor: mode(`neutral.subtlest`, `whiteAlpha.900`)(props),
                      },
                  },
                  _active: { bgColor: mode(`neutral.dark`, `whiteAlpha.400`)(props) },
              }
            : {
                  bgColor: `${c}.100`,
                  _hover: {
                      bgColor: `${c}.500`,
                  },
              }),
    };
};

const variantOutline: SystemStyleFunction = props => {
    const {
        colorScheme,
        size,
        theme: { textStyles },
    } = props;

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        color: `${colorScheme}.100`,
        borderColor: `${colorScheme}.100`,
        _hover: {
            color: 'white',
            bgColor: `${colorScheme}.100`,
        },
        _active: {
            color: 'white',
            bgColor: `${colorScheme}.500`,
        },
    };
};

const variantOnPrimary: SystemStyleFunction = props => {
    const {
        size,
        theme: { textStyles },
    } = props;

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        minW: 20,
        bgColor: 'white',
        color: 'primary.default',
        _focus: {
            boxShadow: 'outlineWhite',
        },
        _hover: {
            opacity: 0.7,
        },
    };
};

const variantOnSecondary: SystemStyleFunction = props => {
    const {
        size,
        theme: { textStyles },
    } = props;

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        minW: 20,
        bgColor: '#FFFFFF33',
        color: 'white',
        _focus: {
            boxShadow: 'outlineWhite',
        },
        _hover: {
            opacity: 0.7,
        },
    };
};

const variantTag: SystemStyleFunction = props => {
    const { size } = props;

    const getStylesBySize = (size: string) =>
        size === 'sm'
            ? {
                  h: 'auto',
                  minH: '20px',
                  px: '2',
                  py: '1',
                  fontSize: 'xs',
              }
            : // : size === 'md'
              // ? {
              //       h: 'auto',
              //       minH: '36px',
              //       px: '2',
              //       py: '1',
              //       fontSize: 'sm',
              //   }
              undefined;

    return {
        ...getStylesBySize(size),
        fontWeight: '400',
        borderRadius: '12',
        minW: 20,
        bgColor: 'gray.200',
        color: 'inherit',
        _hover: {
            opacity: 0.7,
        },
        _active: {
            opacity: 0.5,
        },
    };
};

// Link (text) button styles, button without background and padding

const getLinkButtonStyles = () => ({
    px: '0px',
    py: '0px',
    minW: 'auto',
});

const getLinkButtonStylesBySize = (size: string, textStyles: any) =>
    size === 'sm'
        ? {
              ...textStyles['button-sm'],
          }
        : size === 'md'
        ? {
              // todo iconSpacing problem
              '& .chakra-button__icon:first-of-type': {
                  marginEnd: '1.5',
              },
              '& .chakra-button__icon:last-of-type': {
                  marginStart: '1.5',
              },
              ...textStyles['button-md'],
          }
        : size === 'lg'
        ? {
              ...textStyles['button-lg'],
          }
        : undefined;

const variantLink: SystemStyleFunction = props => {
    const {
        colorScheme: c,
        size,
        theme: { textStyles },
    } = props;
    const defaultActive = {
        color: mode(`${c}.400`, `${c}.500`)(props),
    };

    return {
        ...getLinkButtonStyles(),
        ...getLinkButtonStylesBySize(size, textStyles),
        ...(c === 'font'
            ? {
                  color: mode(`${c}.100`, `${c}.200`)(props),
                  _hover: {
                      opacity: 0.6,
                      textDecoration: 'none',
                  },
                  _active: {
                      opacity: 1,
                  },
              }
            : c === 'secondary'
            ? {
                  color: mode(`secondary.default`, `secondary.default`)(props),
                  _hover: {
                      opacity: 0.6,
                      textDecoration: 'none',
                  },
              }
            : c === 'neutral' || c === 'gray'
            ? {
                  color: mode(`neutral.darkest`, `neutral.subtle`)(props),
                  _hover: {
                      color: mode(`${c}.500`, `${c}.500`)(props),
                      textDecoration: 'none',
                  },
              }
            : {
                  color: mode(`${c}.100`, `${c}.200`)(props),
                  _hover: {
                      color: mode(`${c}.200`, `${c}.500`)(props),
                      textDecoration: 'none',
                  },
                  _active: defaultActive,
                  'input:focus-visible + &': defaultActive,
              }),
    };
};

const variantUnderlined: SystemStyleFunction = props => {
    const {
        colorScheme: c,
        size,
        theme: { textStyles },
    } = props;
    const defaultActive = {
        color: mode(`${c}.400`, `${c}.500`)(props),
        opacity: 1,
        borderBottom: 'solid 1px',
    };

    return {
        ...getFilledButtonStylesBySize(size, textStyles),
        px: '0',
        py: '1.5',
        borderRadius: 'none',
        _hover: {
            opacity: 0.6,
        },
        ...(c === 'font' || c === 'neutral'
            ? {
                  color: mode(`font.secondary`, `neutral.subtle`)(props),
                  _active: {
                      ...defaultActive,
                      color: mode(`font.primary`, `neutral.subtle`)(props),
                  },
              }
            : c === 'secondary'
            ? {
                  color: mode(`secondary.default`, `secondary.default`)(props),
              }
            : {
                  color: mode(`${c}.100`, `${c}.200`)(props),
                  _hover: {
                      color: mode(`${c}.200`, `${c}.500`)(props),
                  },
                  _active: defaultActive,
                  'input:focus-visible + &': defaultActive,
              }),
    };
};

export const ButtonStyles = {
    baseStyle: {
        lineHeight: 'none',
        _disabled: {
            pointerEvents: 'none',
        },
    },
    sizes: {
        sm: {
            '& .chakra-icon': {
                minW: '3.5',
                minH: '3.5',
            },
            // todo iconSpacing problem
            '& .chakra-button__icon:first-of-type': {
                marginEnd: '1',
            },
            '& .chakra-button__icon:last-of-type': {
                marginStart: '1',
            },
        },
        md: {
            '& .chakra-icon': {
                minW: '4',
                minH: '4',
            },
        },
        lg: {
            '& .chakra-icon': {
                minW: '1.125rem',
                minH: '1.125rem',
            },
        },
        xl: {
            '& .chakra-icon': {
                minW: '5',
                minH: '5',
            },
        },
    },
    variants: {
        ghost: variantGhost,
        solid: variantSolid,
        outline: variantOutline,
        onPrimary: variantOnPrimary,
        onSecondary: variantOnSecondary,
        tag: variantTag,
        link: variantLink,
        underlined: variantUnderlined,
    },
    defaultProps: {
        colorScheme: 'primary',
    },
};
