import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/types';
import { createPersistReducer } from '../../../ducks/helpers';
import { OrderDocumentParams } from '../constants';

const initialState: {
    orderNumberCorrections: Record<number, string | number>; // orderId: order_number
} = {
    orderNumberCorrections: {},
};

const getDocumentSlice = createSlice({
    name: 'order/getDocument',
    initialState,
    reducers: {
        formSubmitted(state, action: PayloadAction<{ id: number; form: OrderDocumentParams }>) {
            state.orderNumberCorrections[action.payload.id] = action.payload.form.order_number!;
        },
    },
});

export const { formSubmitted: orderGetDocumentFormSubmitted } = getDocumentSlice.actions;
export const selectOrderGetDocumentForms = (state: RootState) =>
    state.order.getDocument.orderNumberCorrections;

export const getDocumentReducer = createPersistReducer(getDocumentSlice, {
    whitelist: ['orderNumberCorrections'],
    dependsOnCompany: true,
});
