import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

type Props = ConstructorParameters<typeof CKEditor<ClassicEditor>>[0];

export interface ClassicCKEditorProps {
    value: Props['data'];
    onReady?: Props['onReady'];
    onChange?: Props['onChange'];
    config?: Props['config'];
}

export const ClassicCKEditor = ({ config, value, onReady, onChange }: ClassicCKEditorProps) => {
    return (
        <CKEditor
            editor={ClassicEditor}
            config={config}
            data={value}
            onReady={onReady}
            onChange={onChange}
        />
    );
};
