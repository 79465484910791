import { createSlice } from '@reduxjs/toolkit';
import { MaterialWithBarStockUnion, MaterialWithBendUnion } from '@shared/types';
import {
    EditMaterialAction,
    FetchMaterialAction,
    EditMaterialSuccessAction,
    FetchMaterialSuccessAction,
    FetchTechnologiesSuccessAction,
    StartEditMaterialEntitiesAction,
    EditMaterialEntitySuccessAction,
    DeleteMaterialEntitySuccessAction,
    CreateMaterialEntitySuccessAction,
    InitialState,
} from './types';

const initialState: InitialState = {
    technologies: [],
    currentMaterial: null,
    isLoadingMaterial: false,
    isLoadingTechnologies: false,
    isFinishedEditMaterial: false,
    isFinishedEditMaterialEntities: false,
    isLoadingEditMaterialEntities: false,
};

export const editMaterialSlice = createSlice({
    name: 'editMaterial',
    initialState,
    reducers: {
        fetchTechnologies(state) {
            state.isLoadingTechnologies = true;
        },
        fetchTechnologiesSuccess(state, action: FetchTechnologiesSuccessAction) {
            state.technologies = action.payload;
            state.isLoadingTechnologies = false;
        },
        fetchTechnologiesFailure() {},

        fetchMaterial(state, action: FetchMaterialAction) {
            state.isLoadingMaterial = true;
        },
        fetchMaterialSuccess(state, action: FetchMaterialSuccessAction) {
            state.currentMaterial = action.payload;
            state.isLoadingMaterial = false;
        },
        fetchMaterialFailure() {},

        editMaterial(state, action: EditMaterialAction) {
            state.isFinishedEditMaterial = false;
        },

        editMaterialSuccess(state, action: EditMaterialSuccessAction) {
            Object.assign(state.currentMaterial!, action.payload.data);
            state.isFinishedEditMaterial = true;
        },
        editMaterialFailure(state) {
            state.isFinishedEditMaterial = false;
        },

        startEditMaterialEntities(state, action: StartEditMaterialEntitiesAction) {
            state.isFinishedEditMaterialEntities = false;
            state.isLoadingEditMaterialEntities = true;
        },

        finishEditMaterialEntities(state) {
            state.isFinishedEditMaterialEntities = true;
            state.isLoadingEditMaterialEntities = false;
        },

        createMaterialEntitySuccess(state, action: CreateMaterialEntitySuccessAction) {
            const { currentMaterial } = state;
            const { entity, data } = action.payload;

            if (entity === 'post_productions' && currentMaterial) {
                currentMaterial.postproduction.push(data);
            }

            if (entity === 'executions' && currentMaterial) {
                currentMaterial.execution.push(data);
            }

            if (entity === 'bar_stocks' && currentMaterial) {
                (currentMaterial as MaterialWithBarStockUnion).bar_stocks.push(data);
            }

            if (entity === 'bends' && currentMaterial) {
                (currentMaterial as MaterialWithBendUnion).bends.push(data);
            }

            if (entity === 'extra_fieldsets' && currentMaterial) {
                currentMaterial.extra_fieldsets.push(data);
            }
        },

        editMaterialEntitySuccess(state, action: EditMaterialEntitySuccessAction) {
            const { currentMaterial } = state;
            const { entity, data, id } = action.payload;

            if (entity === 'post_productions' && currentMaterial) {
                const index = currentMaterial.postproduction.findIndex(
                    element => element.id === id,
                );
                currentMaterial.postproduction[index] = data;
            }
            if (entity === 'executions' && currentMaterial) {
                const index = currentMaterial.execution.findIndex(element => element.id === id);
                currentMaterial.execution[index] = data;
            }

            if (entity === 'bar_stocks' && currentMaterial) {
                const index = (currentMaterial as MaterialWithBarStockUnion).bar_stocks.findIndex(
                    element => element.id === id,
                );
                (currentMaterial as MaterialWithBarStockUnion).bar_stocks[index] = data;
            }

            if (entity === 'bends' && currentMaterial) {
                const index = (currentMaterial as MaterialWithBendUnion).bends.findIndex(
                    element => element.id === id,
                );
                (currentMaterial as MaterialWithBendUnion).bends[index] = data;
            }

            if (entity === 'extra_fieldsets' && currentMaterial) {
                const index = currentMaterial.extra_fieldsets.findIndex(
                    element => element.id === id,
                );
                currentMaterial.extra_fieldsets[index] = data;
            }
        },

        deleteMaterialEntitySuccess(state, action: DeleteMaterialEntitySuccessAction) {
            const { currentMaterial } = state;
            const { id, entity } = action.payload;

            if (entity === 'post_productions' && currentMaterial) {
                currentMaterial.postproduction = currentMaterial.postproduction.filter(
                    element => element.id !== id,
                );
            }
            if (entity === 'executions' && currentMaterial) {
                currentMaterial.execution = currentMaterial.execution.filter(
                    element => element.id !== id,
                );
            }

            if (entity === 'bar_stocks' && currentMaterial) {
                (currentMaterial as MaterialWithBarStockUnion).bar_stocks = (
                    currentMaterial as MaterialWithBarStockUnion
                ).bar_stocks.filter(element => element.id !== id);
            }

            if (entity === 'bends' && currentMaterial) {
                (currentMaterial as MaterialWithBendUnion).bends = (
                    currentMaterial as MaterialWithBendUnion
                ).bends.filter(element => element.id !== id);
            }

            if (entity === 'extra_fieldsets' && currentMaterial) {
                currentMaterial.extra_fieldsets = currentMaterial.extra_fieldsets.filter(
                    element => element.id !== id,
                );
            }
        },
    },
});

export const {
    fetchTechnologies,
    fetchTechnologiesSuccess,
    fetchTechnologiesFailure,
    fetchMaterial,
    fetchMaterialSuccess,
    fetchMaterialFailure,
    editMaterial,
    editMaterialSuccess,
    editMaterialFailure,
    startEditMaterialEntities,
    editMaterialEntitySuccess,
    createMaterialEntitySuccess,
    deleteMaterialEntitySuccess,
    finishEditMaterialEntities,
} = editMaterialSlice.actions;
