export const dot = (color = 'transparent', gap: string | number = 1, size = '1.5') => ({
    display: 'inline-flex',
    alignItems: 'center',
    _before: {
        height: size,
        width: size,
        minWidth: size,
        backgroundColor: color,
        borderRadius: '100%',
        content: '" "',
        display: 'block',
        marginRight: gap,
    },
});

export const dottedSpacer = (color = 'neutral.subtle', dotGap = 3, size = '1px') => {
    const dotGapPercent = Math.floor(100 - 100 / dotGap);
    return {
        height: size,
        bgGradient: `linear(to-r, transparent 0%, transparent ${dotGapPercent}%, ${color} ${dotGapPercent}%, ${color} 100%)`,
        backgroundPosition: `${dotGap}px bottom`,
        backgroundRepeat: 'repeat-x',
        backgroundSize: `${dotGap}px ${size}`,
    };
};

// export const dashedSpacer = (color = 'neutral.subtle', dotGap = 3, size = '1px') => {
//     const dotGapPercent = Math.floor(100 - 100 / dotGap);
//     return {
//         height: size,
//         bgGradient: `linear(to-r, transparent 0%, transparent ${dotGapPercent}%, ${color} ${dotGapPercent}%, ${color} 100%)`,
//         backgroundPosition: `${dotGap}px bottom`,
//         backgroundRepeat: 'repeat-x',
//         backgroundSize: `${dotGap}px ${size}`,
//     };
// };
