import { ClientRepresentation } from '@services/df/orders';

export function composeClientLabel(
    client: Pick<ClientRepresentation, 'name' | 'surname' | 'email'>,
) {
    const fullName = [client.name, client.surname].filter(Boolean);

    if (fullName.length) {
        return fullName.join(' ');
    }

    return client.email;
}
