import isEmpty from 'lodash/isEmpty';
import React, { useEffect } from 'react';
import { UseFormReturn, FieldValues, FieldPath } from 'react-hook-form';
import { createToast as toast } from '@shared/components/toast';
import { getFieldApiErrors } from '../helpers';

interface UseFormApiErrorsProps<Fields extends FieldValues> {
    formApi: UseFormReturn<Fields>;
    enabled?: boolean;
    fields?: Array<FieldPath<Fields>>; // filter specific fields
}

// hook for rendering form fields errors in toast (useful for fields that don't have a UI)
export function useFormApiErrors<Fields extends FieldValues>({
    formApi,
    enabled = true,
    fields,
}: UseFormApiErrorsProps<Fields>) {
    const { formState, clearErrors } = formApi;

    useEffect(() => {
        const errors = formState.errors;

        if (!enabled || isEmpty(errors)) return;

        const hasFields = Boolean(fields?.length);
        const apiErrorsNames: Array<FieldPath<Fields>> = [];

        const messages: (string | React.ReactElement)[] = Object.keys(errors)
            .map(field => {
                if (!hasFields || (hasFields && fields?.includes(field as FieldPath<Fields>))) {
                    const errorMessage = getFieldApiErrors(errors, field as FieldPath<Fields>);

                    if (errorMessage) {
                        apiErrorsNames.push(field as FieldPath<Fields>);
                        return errorMessage;
                    }
                }

                return '';
            })
            .filter(Boolean);

        if (!messages.length) return;

        toast({
            status: 'error',
            position: 'bottom',
            duration: 15000,
            // isClosable: true,
            description: (
                <React.Fragment
                    children={
                        messages.length > 1
                            ? messages.map((message, i) => <div key={i}>{message}</div>)
                            : messages[0]
                    }
                />
            ),
            // onCloseComplete: () => {
            //     // remove API errors from state
            //     clearErrors(hasFields ? fields : apiErrorsNames);
            // },
        });

        // remove API errors from state
        clearErrors(hasFields ? fields : apiErrorsNames);
    }, [formState, clearErrors, fields]);
}
