import { v4 as uuidv4 } from 'uuid';
import isString from 'lodash/isString';
import { FileRejection } from 'react-dropzone';
import { getFileNameByUrl } from '@shared/utils/strings';
import {
    AttachedFile,
    AttachedLink,
    InitialFile,
    UploadFileAcceptType,
    UploadFileRejectType,
} from './types';

export function isAttachedFile(obj?: unknown): obj is AttachedFile {
    return (obj as AttachedFile)?.type === 'file';
}

export function isAttachedLink(obj?: unknown): obj is AttachedLink {
    return (obj as AttachedLink)?.url !== undefined;
}

export function isImage(file: File) {
    if (file.type.split('/')[0] === 'image') {
        return true;
    }
}

export function createAttachment(_file: InitialFile) {
    const objectOrFile = isString(_file) ? { url: _file, name: getFileNameByUrl(_file) } : _file;

    return isAttachedLink(objectOrFile)
        ? ({ ...objectOrFile, type: 'link' } as const)
        : ({
              type: 'file',
              uuid: uuidv4(),
              file: objectOrFile as File,
          } as const);
}

export const formatDropzoneAccepts = (fileAccepts: File[]): UploadFileAcceptType[] => {
    return fileAccepts.map(file => ({
        file: file,
        uuid: uuidv4(),
        progress: 0,
        status: 'uploading',
    }));
};

export const formatDropzoneRejections = (
    fileRejections: FileRejection[],
): UploadFileRejectType[] => {
    return fileRejections.map(file => {
        const errorCodes = file.errors.map(error => error.code);
        return {
            uuid: uuidv4(),
            fileName: file.file.name,
            errorCodes,
            codeValues: errorCodes.reduce((acc, code) => {
                const error = file.errors.find(error => error.code === code)!;

                if (error.values) {
                    return {
                        ...acc,
                        [code]: error.values,
                    };
                }

                return acc;
            }, {}),
        };
    });
};
