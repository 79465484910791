import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        fullModelStatusList: build.query<FullModelStatusListApiResponse, FullModelStatusListApiArg>(
            {
                query: queryArg => ({
                    url: `/v2/full_model_status/`,
                    params: { models_ids: queryArg.modelsIds },
                }),
            },
        ),
        modelStatusRetrieve: build.query<ModelStatusRetrieveApiResponse, ModelStatusRetrieveApiArg>(
            {
                query: queryArg => ({
                    url: `/v2/model_status/`,
                    params: { models_ids: queryArg.modelsIds },
                }),
            },
        ),
        modelsList: build.query<ModelsListApiResponse, ModelsListApiArg>({
            query: queryArg => ({
                url: `/v2/models/`,
                params: {
                    date_created: queryArg.dateCreated,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    geometry_type: queryArg.geometryType,
                    id: queryArg.id,
                    initial_status: queryArg.initialStatus,
                    is_deleted: queryArg.isDeleted,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    ordering: queryArg.ordering,
                    parent_is_null: queryArg.parentIsNull,
                    parent_model_id: queryArg.parentModelId,
                    quote_succeed: queryArg.quoteSucceed,
                    search: queryArg.search,
                    surface: queryArg.surface,
                    title: queryArg.title,
                    units: queryArg.units,
                    upload_job_id: queryArg.uploadJobId,
                    user_id__in: queryArg.userIdIn,
                },
            }),
        }),
        modelsDestroy: build.mutation<ModelsDestroyApiResponse, ModelsDestroyApiArg>({
            query: queryArg => ({ url: `/v2/models/${queryArg.id}/`, method: 'DELETE' }),
        }),
        modelsCsvRetrieve: build.query<ModelsCsvRetrieveApiResponse, ModelsCsvRetrieveApiArg>({
            query: () => ({ url: `/v2/models/csv/` }),
        }),
        modelsReportsCheckUpdate: build.mutation<
            ModelsReportsCheckUpdateApiResponse,
            ModelsReportsCheckUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/models/reports/${queryArg.reportType}/check/`,
                method: 'PUT',
                params: {
                    date_created: queryArg.dateCreated,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    geometry_type: queryArg.geometryType,
                    id: queryArg.id,
                    initial_status: queryArg.initialStatus,
                    is_deleted: queryArg.isDeleted,
                    ordering: queryArg.ordering,
                    parent_is_null: queryArg.parentIsNull,
                    parent_model_id: queryArg.parentModelId,
                    quote_succeed: queryArg.quoteSucceed,
                    search: queryArg.search,
                    surface: queryArg.surface,
                    title: queryArg.title,
                    units: queryArg.units,
                    upload_job_id: queryArg.uploadJobId,
                    user_id__in: queryArg.userIdIn,
                },
            }),
        }),
        modelsReportsDownloadRetrieve: build.query<
            ModelsReportsDownloadRetrieveApiResponse,
            ModelsReportsDownloadRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/models/reports/${queryArg.reportType}/download/`,
                params: {
                    date_created: queryArg.dateCreated,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    geometry_type: queryArg.geometryType,
                    id: queryArg.id,
                    initial_status: queryArg.initialStatus,
                    is_deleted: queryArg.isDeleted,
                    ordering: queryArg.ordering,
                    parent_is_null: queryArg.parentIsNull,
                    parent_model_id: queryArg.parentModelId,
                    quote_succeed: queryArg.quoteSucceed,
                    search: queryArg.search,
                    surface: queryArg.surface,
                    title: queryArg.title,
                    units: queryArg.units,
                    upload_job_id: queryArg.uploadJobId,
                    user_id__in: queryArg.userIdIn,
                },
            }),
        }),
        uploadJobCreate: build.mutation<UploadJobCreateApiResponse, UploadJobCreateApiArg>({
            query: () => ({ url: `/v2/upload_job/`, method: 'POST' }),
        }),
        uploadJobReBindCreate: build.mutation<
            UploadJobReBindCreateApiResponse,
            UploadJobReBindCreateApiArg
        >({
            query: () => ({ url: `/v2/upload_job/re_bind/`, method: 'POST' }),
        }),
        uploadModelsCreate: build.mutation<UploadModelsCreateApiResponse, UploadModelsCreateApiArg>(
            {
                query: queryArg => ({
                    url: `/v2/upload_models/`,
                    method: 'POST',
                    body: queryArg.uploadModelRequest,
                }),
            },
        ),
        usersUploadJobCreate: build.mutation<
            UsersUploadJobCreateApiResponse,
            UsersUploadJobCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/users/${queryArg.userId}/upload_job/`,
                method: 'POST',
            }),
        }),
        usersModelsList: build.query<UsersModelsListApiResponse, UsersModelsListApiArg>({
            query: queryArg => ({
                url: `/v2/users/models/`,
                params: {
                    date_created: queryArg.dateCreated,
                    date_from: queryArg.dateFrom,
                    date_to: queryArg.dateTo,
                    geometry_type: queryArg.geometryType,
                    id: queryArg.id,
                    initial_status: queryArg.initialStatus,
                    is_deleted: queryArg.isDeleted,
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    ordering: queryArg.ordering,
                    parent_is_null: queryArg.parentIsNull,
                    parent_model_id: queryArg.parentModelId,
                    quote_succeed: queryArg.quoteSucceed,
                    search: queryArg.search,
                    surface: queryArg.surface,
                    title: queryArg.title,
                    units: queryArg.units,
                    upload_job_id: queryArg.uploadJobId,
                    user_id__in: queryArg.userIdIn,
                },
            }),
        }),
        usersModelsRetrieve: build.query<UsersModelsRetrieveApiResponse, UsersModelsRetrieveApiArg>(
            {
                query: queryArg => ({ url: `/v2/users/models/${queryArg.id}/` }),
            },
        ),
        usersModelsDestroy: build.mutation<UsersModelsDestroyApiResponse, UsersModelsDestroyApiArg>(
            {
                query: queryArg => ({ url: `/v2/users/models/${queryArg.id}/`, method: 'DELETE' }),
            },
        ),
        usersModelsCsvRetrieve: build.query<
            UsersModelsCsvRetrieveApiResponse,
            UsersModelsCsvRetrieveApiArg
        >({
            query: () => ({ url: `/v2/users/models/csv/` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type FullModelStatusListApiResponse = /** status 200  */ FullModelStatus[];
export type FullModelStatusListApiArg = {
    modelsIds: number[];
};
export type ModelStatusRetrieveApiResponse = /** status 200  */ {
    [key: string]: string;
};
export type ModelStatusRetrieveApiArg = {
    modelsIds: number[];
};
export type ModelsListApiResponse = /** status 200  */ PaginatedModelSerializerV2List;
export type ModelsListApiArg = {
    dateCreated?: string;
    dateFrom?: string;
    dateTo?: string;
    /** Multiple values may be separated by commas.
    
    * `shaft` - Shaft
    * `disk` - Disk
    * `sheet` - Sheet
    * `sheetmetal` - Sheetmetal
    * `flat_sheet` - Flat sheet
    * `other` - Other */
    geometryType?: ('disk' | 'flat_sheet' | 'other' | 'shaft' | 'sheet' | 'sheetmetal')[];
    id?: number;
    /** Multiple values may be separated by commas.
    
    * `in_progress` - in_progress
    * `ready` - ready
    * `failed` - failed */
    initialStatus?: ('failed' | 'in_progress' | 'ready')[];
    isDeleted?: boolean;
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    /** Which field to use when ordering the results. */
    ordering?: string;
    parentIsNull?: boolean;
    parentModelId?: number | null;
    quoteSucceed?: boolean;
    /** A search term. */
    search?: string;
    surface?: number;
    title?: string;
    /** * `mm` - Millimeters
     * `cm` - Centimeters
     * `in` - Inches */
    units?: 'cm' | 'in' | 'mm';
    uploadJobId?: string | null;
    /** Multiple values may be separated by commas. */
    userIdIn?: number[];
};
export type ModelsDestroyApiResponse = unknown;
export type ModelsDestroyApiArg = {
    id: string;
};
export type ModelsCsvRetrieveApiResponse = unknown;
export type ModelsCsvRetrieveApiArg = void;
export type ModelsReportsCheckUpdateApiResponse = /** status 200  */ ModelReportCheck;
export type ModelsReportsCheckUpdateApiArg = {
    dateCreated?: string;
    dateFrom?: string;
    dateTo?: string;
    /** Multiple values may be separated by commas.
    
    * `shaft` - Shaft
    * `disk` - Disk
    * `sheet` - Sheet
    * `sheetmetal` - Sheetmetal
    * `flat_sheet` - Flat sheet
    * `other` - Other */
    geometryType?: ('disk' | 'flat_sheet' | 'other' | 'shaft' | 'sheet' | 'sheetmetal')[];
    id?: number;
    /** Multiple values may be separated by commas.
    
    * `in_progress` - in_progress
    * `ready` - ready
    * `failed` - failed */
    initialStatus?: ('failed' | 'in_progress' | 'ready')[];
    isDeleted?: boolean;
    /** Which field to use when ordering the results. */
    ordering?: string;
    parentIsNull?: boolean;
    parentModelId?: number | null;
    quoteSucceed?: boolean;
    reportType: string;
    /** A search term. */
    search?: string;
    surface?: number;
    title?: string;
    /** * `mm` - Millimeters
     * `cm` - Centimeters
     * `in` - Inches */
    units?: 'cm' | 'in' | 'mm';
    uploadJobId?: string | null;
    /** Multiple values may be separated by commas. */
    userIdIn?: number[];
};
export type ModelsReportsDownloadRetrieveApiResponse = unknown;
export type ModelsReportsDownloadRetrieveApiArg = {
    dateCreated?: string;
    dateFrom?: string;
    dateTo?: string;
    /** Multiple values may be separated by commas.
    
    * `shaft` - Shaft
    * `disk` - Disk
    * `sheet` - Sheet
    * `sheetmetal` - Sheetmetal
    * `flat_sheet` - Flat sheet
    * `other` - Other */
    geometryType?: ('disk' | 'flat_sheet' | 'other' | 'shaft' | 'sheet' | 'sheetmetal')[];
    id?: number;
    /** Multiple values may be separated by commas.
    
    * `in_progress` - in_progress
    * `ready` - ready
    * `failed` - failed */
    initialStatus?: ('failed' | 'in_progress' | 'ready')[];
    isDeleted?: boolean;
    /** Which field to use when ordering the results. */
    ordering?: string;
    parentIsNull?: boolean;
    parentModelId?: number | null;
    quoteSucceed?: boolean;
    reportType: string;
    /** A search term. */
    search?: string;
    surface?: number;
    title?: string;
    /** * `mm` - Millimeters
     * `cm` - Centimeters
     * `in` - Inches */
    units?: 'cm' | 'in' | 'mm';
    uploadJobId?: string | null;
    /** Multiple values may be separated by commas. */
    userIdIn?: number[];
};
export type UploadJobCreateApiResponse = /** status 200  */ CreateUploadJobResponse;
export type UploadJobCreateApiArg = void;
export type UploadJobReBindCreateApiResponse = /** status 200  */ UploadModelsJob;
export type UploadJobReBindCreateApiArg = void;
export type UploadModelsCreateApiResponse = /** status 200  */ UploadModelResponse;
export type UploadModelsCreateApiArg = {
    uploadModelRequest: UploadModelRequest;
};
export type UsersUploadJobCreateApiResponse = /** status 200  */ CreateUploadJobResponse;
export type UsersUploadJobCreateApiArg = {
    userId: string;
};
export type UsersModelsListApiResponse = /** status 200  */ PaginatedModelSerializerV2List;
export type UsersModelsListApiArg = {
    dateCreated?: string;
    dateFrom?: string;
    dateTo?: string;
    /** Multiple values may be separated by commas.
    
    * `shaft` - Shaft
    * `disk` - Disk
    * `sheet` - Sheet
    * `sheetmetal` - Sheetmetal
    * `flat_sheet` - Flat sheet
    * `other` - Other */
    geometryType?: ('disk' | 'flat_sheet' | 'other' | 'shaft' | 'sheet' | 'sheetmetal')[];
    id?: number;
    /** Multiple values may be separated by commas.
    
    * `in_progress` - in_progress
    * `ready` - ready
    * `failed` - failed */
    initialStatus?: ('failed' | 'in_progress' | 'ready')[];
    isDeleted?: boolean;
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    /** Which field to use when ordering the results. */
    ordering?: string;
    parentIsNull?: boolean;
    parentModelId?: number | null;
    quoteSucceed?: boolean;
    /** A search term. */
    search?: string;
    surface?: number;
    title?: string;
    /** * `mm` - Millimeters
     * `cm` - Centimeters
     * `in` - Inches */
    units?: 'cm' | 'in' | 'mm';
    uploadJobId?: string | null;
    /** Multiple values may be separated by commas. */
    userIdIn?: number[];
};
export type UsersModelsRetrieveApiResponse = /** status 200  */ ModelSerializerV2;
export type UsersModelsRetrieveApiArg = {
    id: string;
};
export type UsersModelsDestroyApiResponse = unknown;
export type UsersModelsDestroyApiArg = {
    id: string;
};
export type UsersModelsCsvRetrieveApiResponse = unknown;
export type UsersModelsCsvRetrieveApiArg = void;
export type InitialStatusEnum = 'in_progress' | 'ready' | 'failed';
export type ProcessingStatusEnum = 'in_progress' | 'success' | 'failed';
export type FullModelStatus = {
    id: number;
    initial_status?: InitialStatusEnum;
    processing_status?: ProcessingStatusEnum;
};
export type ClientRepresentation = {
    id: number;
    email: string;
    created_at: string;
    phone_number?: string;
    name?: string;
    surname?: string;
    full_name: string;
    company_name?: string;
    position?: string;
    roles?: string[];
    down_payment_rate?: number;
};
export type ModelUnitsEnum = 'mm' | 'cm' | 'in';
export type CncFeatureList = {
    type: string;
    type_display: string;
    shape_type: string;
    shape_type_display: string;
    through: string;
    ratio: number;
    diameter: number;
    nominal: string;
    quantity: number;
};
export type GeometryTypeEnum = 'shaft' | 'disk' | 'sheet' | 'sheetmetal' | 'flat_sheet' | 'other';
export type ModelSize = {
    x: number | null;
    y: number | null;
    z: number | null;
};
export type ModelSerializerV2 = {
    id: number;
    upload_job_id: string | null;
    client?: ClientRepresentation;
    title?: string;
    initial_status?: InitialStatusEnum;
    repaired_res?: any;
    units?: ModelUnitsEnum;
    volume: number | null;
    surface?: number | null;
    perimeter?: number | null;
    punches_count?: number | null;
    is_rotated?: boolean;
    cnc_features: CncFeatureList[];
    dfm_features: {
        [key: string]: any;
    };
    thumb: string;
    thumb_120x120: string;
    thumb_300x300: string | null;
    thumb_status: string;
    file_model_original?: string;
    file_model_repaired?: string;
    file_model_original_url: string | null;
    file_model_viewer_url: string;
    file_model_stl_original_url: string | null;
    file_model_stl_repaired_url: string | null;
    object_model_original_url: string | null;
    date_created: string;
    parent_model?: number | null;
    technologies?: number[];
    geometry_type?: GeometryTypeEnum;
    cnc_complexity_level: number;
    filesize?: string;
    size: ModelSize;
    child_models: number[];
    cnc_complexity: number | null;
    is_processable: boolean;
    processing_status?: ProcessingStatusEnum;
    size_z_for_sheet: string;
    sheet_top_surface_area: number;
    bends: any[];
};
export type PaginatedModelSerializerV2List = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: ModelSerializerV2[];
};
export type ModelReportCheck = {
    is_downloadable: boolean;
    email: string;
};
export type CreateUploadJobResponse = {
    uj: number;
};
export type ObjectModelStatus = {
    id: number;
    title: string;
    units?: ModelUnitsEnum;
    initial_status?: InitialStatusEnum;
    auto_oriented: boolean;
    suitable_technologies: number[];
    parent_model?: number | null;
};
export type UploadModelsJob = {
    id: string;
    href: string;
    models?: ObjectModelStatus[];
    is_ready: boolean;
};
export type UploadedObjectModel = {
    status: string;
    title: string;
    id?: number;
    detail?: string;
    extension?: string;
    filesize?: string;
};
export type UploadModelResponse = {
    file_name: string;
    detail?: string;
    object_models: UploadedObjectModel[];
};
export type UploadModelRequest = {
    models: Blob[];
    models_units?: any;
    upload_job_id: string;
};
export const {
    useFullModelStatusListQuery,
    useModelStatusRetrieveQuery,
    useModelsListQuery,
    useModelsDestroyMutation,
    useModelsCsvRetrieveQuery,
    useModelsReportsCheckUpdateMutation,
    useModelsReportsDownloadRetrieveQuery,
    useUploadJobCreateMutation,
    useUploadJobReBindCreateMutation,
    useUploadModelsCreateMutation,
    useUsersUploadJobCreateMutation,
    useUsersModelsListQuery,
    useUsersModelsRetrieveQuery,
    useUsersModelsDestroyMutation,
    useUsersModelsCsvRetrieveQuery,
} = injectedRtkApi;
