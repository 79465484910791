import isString from 'lodash/isString';
import { ReactNode } from 'react';
import {
    Divider,
    HStack,
    Modal,
    ModalProps,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalCloseButton,
} from '@chakra-ui/react';

export interface SectionModalProps extends ModalProps {
    header: ReactNode;
    hasHeaderDivider?: boolean;
}

export const SectionModal = ({
    header,
    hasHeaderDivider,
    children,
    ...props
}: SectionModalProps) => {
    return (
        <Modal size="5xl" isCentered lockFocusAcrossFrames={false} {...props}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    <HStack justifyContent="space-between">
                        {isString(header) ? <span>{header}</span> : header}
                        <ModalCloseButton
                            position="relative"
                            top="0px"
                            insetEnd="0px"
                            color="neutral.darkest"
                        />
                    </HStack>
                    {hasHeaderDivider && <Divider mt={6} color="neutral.subtle" />}
                </ModalHeader>

                {children}
            </ModalContent>
        </Modal>
    );
};
