import { Icon, IconProps } from '@chakra-ui/react';

// This icon is looks like a writing Pencil

export const EditIcon = (props: IconProps) => (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path d="M6 10H10.5" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M8.25 1.74999C8.44891 1.55108 8.7187 1.43933 9 1.43933C9.13929 1.43933 9.27721 1.46677 9.4059 1.52007C9.53458 1.57337 9.65151 1.6515 9.75 1.74999C9.84849 1.84848 9.92662 1.96541 9.97992 2.09409C10.0332 2.22278 10.0607 2.3607 10.0607 2.49999C10.0607 2.63928 10.0332 2.7772 9.97992 2.90589C9.92662 3.03457 9.84849 3.1515 9.75 3.24999L3.5 9.49999L1.5 9.99999L2 7.99999L8.25 1.74999Z"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
