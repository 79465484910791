import { importScript } from 'assets-dynamic-import';
import { ReactElement, useEffect, useState } from 'react';
import { Box, IconButton } from '@chakra-ui/react';
import { BellIcon } from '@shared/components/icons';
import { HEADWAY_ACCOUNT_ID, HEADWAY_CONTAINER_ID, HEADWAY_TRIGGER_ID } from './constants';

const HEADWAY_INDICATOR_STYLES = {
    '.HW_badge.HW_softHidden': { transform: 'scale(.4)' },
    '.HW_badge:not(.HW_softHidden)': { bgColor: 'success.default' },
};

// https://docs.headwayapp.co/widget

const createWidget = async () => {
    const { init } = await importScript(
        'https://cdn.headwayapp.co/widget.js',
        () => global.Headway,
    );

    const widget = init({
        account: HEADWAY_ACCOUNT_ID,
        selector: `#${HEADWAY_CONTAINER_ID}`, // ID of the container element
        trigger: `#${HEADWAY_TRIGGER_ID}`,
    });

    return () => {
        // console.log('destroy');
        widget.destroy();
    };
};

const HwBadgeComponent = () => {
    const [isLoaded, setIsLoaded] = useState(false);
    //     useEffect(() => {
    //         console.log('mount');
    //         const promise = createWidget();
    //         return () => {
    //             console.log('unmount');
    //             promise.then(destroy => destroy());
    //         };
    //     }, []);

    useEffect(() => {
        let destroy: () => void;

        createWidget().then(_destroy => {
            destroy = _destroy;
            setIsLoaded(true);
        });

        return () => destroy?.();
    }, []);

    return (
        <Box position="relative" alignSelf="flex-end" pr={2}>
            <IconButton
                id={HEADWAY_TRIGGER_ID}
                aria-label="Headwayapp new notifications here"
                icon={<BellIcon />}
                variant="link"
                colorScheme="neutral"
                isDisabled={!isLoaded}
            />
            <Box
                id={HEADWAY_CONTAINER_ID}
                position="absolute"
                left="20%"
                bottom="30%"
                // transform="scale(.65)"
                sx={HEADWAY_INDICATOR_STYLES}
            />
        </Box>
    );
};

export default function HwBadge(): ReactElement | null {
    return HEADWAY_ACCOUNT_ID ? <HwBadgeComponent /> : null;
}
