import { useCallback, useEffect, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useAppDispatch } from '@app/hooks';
import {
    FeatureRequest,
    PatchedFeatureRequest,
    useMachinesFeaturesUpdateMutation,
    useMachinesFeaturesPartialUpdateMutation,
    useMachinesFeaturesDestroyMutation,
    enhancedApi as api,
} from '@services/df/machines-materials';
import { OptionType, SelectFieldProps } from '@shared/components/forms-v2';
import { FeatureType, TopologyType } from '@services/df/codegen-enums';
import {
    isMillingGroup,
    isTurning,
    isCncCutter,
    isCncSheetmetal,
} from '@shared/utils/technology-guard';

type FeatureTypeOption = Record<TopologyType, FeatureType[] | undefined>;

// based on: https://digifabster.atlassian.net/jira/software/projects/DFDEV/boards/1?assignee=63e4ea02614cb4ba5303bf93&selectedIssue=DFDEV-2310
const FEATURE_CONDITIONS_CONFIG = {
    milling: {
        topology: Object.values(TopologyType).filter(t => t !== TopologyType.Reduction),
        featureType: {
            [TopologyType.Hole]: Object.values(FeatureType).filter(
                t => t !== FeatureType.SingleStepReduction,
            ),
        } as FeatureTypeOption,
    },
    turning: {
        topology: [TopologyType.Hole, TopologyType.Reduction],
        featureType: {
            [TopologyType.Hole]: [FeatureType.Axial, FeatureType.Radial],
        } as FeatureTypeOption,
    },
    cutter: {
        topology: [TopologyType.Hole, TopologyType.Notch, TopologyType.Slot],
    },
    sheetmetal: {
        topology: [TopologyType.Hole, TopologyType.Notch, TopologyType.Slot],
    },
};

type Props = {
    technologyId: number;
    topologyTypeName?: string;
    nameFieldName?: string;
    featureTypeName?: string;
    shapeTypeName?: string;
    topologyTypeFieldProps?: Omit<SelectFieldProps, 'name'>;
    featureTypeFieldProps?: Omit<SelectFieldProps, 'name'>;
};

export const useMachineFeatureConditions = ({
    technologyId,
    topologyTypeName = 'topology_type',
    nameFieldName = 'name',
    featureTypeName = 'feature_type',
    shapeTypeName = 'shape_type',
    topologyTypeFieldProps,
    featureTypeFieldProps,
}: Props) => {
    const { resetField, setValue } = useFormContext();
    const [topologyType, featureType] = useWatch({ name: [topologyTypeName, featureTypeName] });
    const isHole = topologyType === TopologyType.Hole;

    const topologyTypeProps = useMemo(() => {
        const allowed = isMillingGroup(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.milling.topology
            : isTurning(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.turning.topology
            : isCncCutter(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.cutter.topology
            : isCncSheetmetal(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.sheetmetal.topology
            : [];

        const options = topologyTypeFieldProps?.options?.filter(option =>
            // @ts-ignore
            allowed.includes(option.value),
        );

        return {
            ...topologyTypeFieldProps,
            options,
        };
    }, [topologyTypeFieldProps, technologyId]);

    const featureTypeProps = useMemo(() => {
        const allowed = isMillingGroup(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.milling.featureType[topologyType as TopologyType]
            : isTurning(technologyId)
            ? FEATURE_CONDITIONS_CONFIG.turning.featureType[topologyType as TopologyType]
            : [];

        const options = featureTypeFieldProps?.options?.filter(option =>
            // @ts-ignore
            allowed?.includes(option.value),
        );

        return {
            ...featureTypeFieldProps,
            options,
        };
    }, [featureTypeFieldProps, topologyType, technologyId]);

    const isFeatureTypeVisible = isHole && !!featureTypeProps.options?.length;
    const isNameVisible = isHole;
    const isShapeTypeVisible = isHole && featureType === FeatureType.Cuboids;

    // reset values
    useEffect(() => {
        if (isFeatureTypeVisible) {
            // set allowed default
            const hasOptions = !!featureTypeProps.options?.length;
            const allowedOption =
                hasOptions &&
                featureTypeProps.options!.find(
                    option => (option as OptionType).value === featureType,
                );

            if (hasOptions && !allowedOption) {
                setValue(featureTypeName, (featureTypeProps.options![0] as OptionType).value);
            }
        } else {
            // force set
            if (isTurning(technologyId)) {
                switch (topologyType) {
                    case TopologyType.Reduction: {
                        setValue(featureTypeName, FeatureType.SingleStepReduction);
                        return;
                    }
                }
            }

            setValue(featureTypeName, FeatureType.Cuboids);
        }
    }, [
        featureTypeName,
        technologyId,
        topologyType,
        featureType,
        featureTypeProps.options,
        isFeatureTypeVisible,
        setValue,
    ]);

    useEffect(() => {
        if (!isNameVisible) {
            resetField(nameFieldName, {
                defaultValue: 'Drilled hole',
            });
        }
    }, [nameFieldName, isNameVisible, resetField]);

    useEffect(() => {
        if (!isShapeTypeVisible) {
            resetField(shapeTypeName, { defaultValue: '' });
        }
    }, [shapeTypeName, isShapeTypeVisible, resetField]);

    return {
        isHole,
        isFeatureTypeVisible,
        isNameVisible,
        isShapeTypeVisible,
        topologyTypeProps,
        featureTypeProps,
    };
};

interface UseMachineFeatureCrud {
    technologyId: number;
    machineId: number;
}

export const useMachineFeatureCreate = ({ technologyId, machineId }: UseMachineFeatureCrud) => {
    const dispatch = useAppDispatch();
    const [create, state] = useMachinesFeaturesUpdateMutation();
    return {
        create: useCallback(
            async (form: FeatureRequest & { id: string }) => {
                const created = await create({
                    machineId,
                    featureRequest: form,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            if (draft.features) {
                                draft.features.push(created);
                            } else {
                                draft.features = [created];
                            }
                        },
                    ),
                );

                return created;
            },
            [dispatch, create, technologyId, machineId],
        ),
        state,
    };
};

export const useMachineFeatureUpdate = ({ technologyId, machineId }: UseMachineFeatureCrud) => {
    const dispatch = useAppDispatch();
    const [update, state] = useMachinesFeaturesPartialUpdateMutation();

    return {
        update: useCallback(
            async (id: string, form: PatchedFeatureRequest) => {
                const updated = await update({
                    id,
                    machineId,
                    patchedFeatureRequest: form,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            const index = draft.features?.findIndex(feature => feature.id === id);
                            if (index !== -1) draft.features![index!] = updated;
                        },
                    ),
                );

                return updated;
            },
            [dispatch, update, technologyId, machineId],
        ),
        state,
    };
};

export const useMachineFeatureDestroy = ({ technologyId, machineId }: UseMachineFeatureCrud) => {
    const dispatch = useAppDispatch();
    const [destroy, state] = useMachinesFeaturesDestroyMutation();

    return {
        destroy: useCallback(
            async (id: string) => {
                await destroy({
                    id,
                    machineId,
                }).unwrap();

                dispatch(
                    api.util.updateQueryData(
                        'machinesRetrieve',
                        { id: machineId, technologyId },
                        draft => {
                            const index = draft.features!.findIndex(feature => feature.id === id);
                            if (index !== -1) draft.features!.splice(index, 1);
                        },
                    ),
                );
            },
            [dispatch, destroy, technologyId, machineId],
        ),
        state,
    };
};
