export const MODAL_CONTENT_STYLES = {
    w: '1048px',
    minH: '448px',
    padding: '24px',
    backgroundColor: 'gray.100',
} as const;

export const MODAL_BODY_STYLES = {
    h: '100%',
    minH: '448px',
    textStyle: 'typography-md',
    padding: '0',
    display: 'flex',
    alignItems: 'center',
} as const;

export const VIEWER_CONTAINER_STYLES = {
    w: '100%',
    h: '100%',
    minH: '448px',
    overflow: 'hidden',
    backgroundColor: 'white',
    border: '1px solid',
    borderColor: 'gray.200',
    borderRadius: '8',
} as const;

export const DETAILS_CONTAINER_STYLES = {
    w: { base: '100%', sm: '350px' },
    justifyContent: 'flex-start',
    gap: '28px',
    flexShrink: 0,
} as const;

export const MODAL_CONTAINER_STYLES = {
    width: '100%',
    height: '100%',
    minH: '448px',
    flexDir: { base: 'column', md: 'row' },
    alignItems: { base: 'center', md: 'flex-start' },
    gap: '38px',
} as const;

export const COLUMN_WIDTH = '130px';

export const ROW_STYLES = {
    columnGap: '18px',
    justify: { base: 'space-between', md: 'flex-start' },
} as const;
