import { Icon, IconProps } from '@chakra-ui/react';

export const ArrowRight = (props: IconProps) => (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path
            d="M9.55667 6.35365C9.73692 6.15839 9.73692 5.84181 9.55667 5.64655L6.61946 2.46456C6.43922 2.2693 6.14699 2.2693 5.96675 2.46456C5.7865 2.65982 5.7865 2.97641 5.96675 3.17167L8.5776 6.0001L5.96675 8.82853C5.7865 9.02379 5.7865 9.34037 5.96675 9.53563C6.14699 9.7309 6.43922 9.7309 6.61946 9.53563L9.55667 6.35365ZM1.8457 6.5001H9.23032V5.5001H1.8457L1.8457 6.5001Z"
            fill="currentColor"
        />
    </Icon>
);
