import { useLocation } from 'react-router';

interface Props {
    hash?: string;
}

export function useCurrentUrl({ hash }: Props = {}) {
    const { pathname, search } = useLocation();
    let url = pathname + search;
    if (hash) {
        url = `${url}#${hash}`;
    }

    return url;
}
