import get from 'lodash/get';
import { useCallback } from 'react';
import { FieldPath, FieldValues, SubmitHandler } from 'react-hook-form';
import { FetchBaseQueryError } from '@services/df/baseApi';
import { setApiErrors } from '@shared/components/forms-v2';
import { UseFormPopupInitProps, useFormPopupInit } from './use-form-popup-init';
import { useFormApiErrors } from './use-form-api-errors';

export interface UseFormPopupProps<TFieldValues extends FieldValues = FieldValues>
    extends UseFormPopupInitProps<TFieldValues> {
    submit: SubmitHandler<TFieldValues>;
    apiErrorsToastEnabled?: boolean;
    apiErrorsFields?: Array<FieldPath<TFieldValues>>;
    apiErrorsMap?: Record<string, FieldPath<TFieldValues>>;
}

export const useFormPopup = <TFieldValues extends FieldValues>({
    data,
    schema,
    apiErrorsToastEnabled = true,
    apiErrorsFields,
    apiErrorsMap,
    submit: _submit,
    ...rest
}: UseFormPopupProps<TFieldValues>) => {
    const formApi = useFormPopupInit<TFieldValues>({
        data,
        schema,
        ...rest,
    });
    const { setError, handleSubmit, onClose } = formApi;

    useFormApiErrors({ formApi, enabled: apiErrorsToastEnabled, fields: apiErrorsFields });

    const submit: SubmitHandler<TFieldValues> = useCallback(
        async data => {
            try {
                await _submit(data);
                onClose();
            } catch (error) {
                const _errors = (error as FetchBaseQueryError)?.data;
                const errors = apiErrorsMap
                    ? Object.entries(apiErrorsMap).reduce(
                          (acc, [name, path]) => ({ ...acc, [name]: get(_errors, path) }),
                          {},
                      )
                    : _errors;

                setApiErrors(setError, errors);
            }
        },
        [_submit, setError, onClose, apiErrorsMap],
    );

    return {
        handleFormSubmit: handleSubmit(submit),
        ...formApi,
    };
};
