import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import { SingleDatepicker, SingleDatepickerProps } from 'chakra-dayzed-datepicker';
import { useFieldStyles } from '@shared/components/forms-v2';
import { DEFAULT_DATE_FORMAT, DATEPICKER_DEFAULT_STYLES } from './constants';
import { getFirstDayOfWeek, parseDate } from './utils';
import { CalendarConfigs } from 'chakra-dayzed-datepicker/src/utils/commonTypes';

dayjs.extend(utc);

export interface DateInputProps extends Omit<SingleDatepickerProps, 'date'> {
    value: string;
}

export const DateInput = ({ value, propsConfigs: _propsConfigs, ...props }: DateInputProps) => {
    const inputProps = useFieldStyles('input', _propsConfigs?.inputProps);
    const propsConfigs = { ...DATEPICKER_DEFAULT_STYLES, ..._propsConfigs, inputProps }; // todo deep merge?
    const date = dayjs.utc(value).format('YYYY-MM-DD');
    const [firstDayOfWeek, setFirstDayOfWeek] = useState<CalendarConfigs['firstDayOfWeek']>(0);

    useEffect(() => {
        getFirstDayOfWeek().then(locale => setFirstDayOfWeek(locale));
    }, []);

    return (
        <SingleDatepicker
            configs={{
                dateFormat: DEFAULT_DATE_FORMAT,
                firstDayOfWeek: firstDayOfWeek,
            }}
            {...props}
            propsConfigs={propsConfigs}
            date={value ? parseDate(date) : undefined}
        />
    );
};
