// todo move to machines-materials 'ducks' helpers

import negate from 'lodash/negate';
import isNumber from 'lodash/isNumber';
import lowerCase from 'lodash/lowerCase';
import { TECHNOLOGY_TITLE_MAP, TechnologyIdsUnion } from '@shared/constants';
import { Nilable } from '@shared/types';

type TechnologyId = Nilable<number | string>;

const getChecker = (techId: TechnologyIdsUnion) => (currentId: TechnologyId) => {
    const techLabel = TECHNOLOGY_TITLE_MAP[techId];

    return (
        Boolean(currentId) &&
        (isNumber(currentId)
            ? techId === currentId
            : lowerCase(techLabel) === lowerCase(currentId!))
    );
};

// single
export const isSLS = getChecker(1);
export const isSLM = getChecker(3);
export const isSLA = getChecker(7);
export const is3DP = getChecker(4);
export const isFDM = getChecker(2);
export const isMJF = getChecker(8);
export const isPolyjet = getChecker(6);
export const isMultijet = getChecker(5);
export const isCncCutter = getChecker(10);
export const isCncSheetmetal = getChecker(13);
export const is3AxisMilling = getChecker(12);
export const is4AxisMilling = getChecker(14);
export const isMultiAxisMilling = getChecker(9);
export const isTurning = getChecker(11);

// groups
export const isMultiAxisMillingGroup = (currentId: TechnologyId) =>
    isMultiAxisMilling(currentId) || is4AxisMilling(currentId);

export const isMillingGroup = (currentId: TechnologyId) =>
    isMultiAxisMilling(currentId) || is4AxisMilling(currentId) || is3AxisMilling(currentId);

export const isBelongCNC = (currentId: TechnologyId) =>
    isMillingGroup(currentId) ||
    isCncCutter(currentId) ||
    isCncSheetmetal(currentId) ||
    isTurning(currentId);

export const isBelong2D = (currentId: TechnologyId) =>
    isCncCutter(currentId) || isCncSheetmetal(currentId);
export const isBelong3D = negate(isBelong2D);
export const isBelongCNCWithout2D = (currentId: TechnologyId) =>
    isBelongCNC(currentId) && isBelong3D(currentId);
