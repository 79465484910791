export const RadioStyles = {
    baseStyle: {
        control: {
            border: '1px solid',
            borderColor: 'gray.200',

            _hover: {
                borderColor: 'gray.300',
            },
            _checked: {
                background: 'rgba(0, 87, 215, 0.05)',
                borderColor: 'primary.default',
                color: 'primary.default',

                _before: {
                    w: '1.5',
                    h: '1.5',
                },

                _hover: {
                    background: 'rgba(0, 87, 215, 0.05)',
                    borderColor: 'primary.default',
                },
            },
            _invalid: {
                borderColor: 'error.100',
            },
        },
    },
    sizes: {
        lg: {
            label: {
                fontSize: 'md',
                marginStart: '3',
            },
            control: {
                _checked: {
                    _before: {
                        w: '2',
                        h: '2',
                    },
                },
            },
        },
        md: {
            label: {
                fontSize: 'sm',
                '.chakra-popover-form__content &': { fontSize: 'xs', marginStart: '1' },
            },
        },
    },
    variants: {
        white: {
            control: {
                bg: 'white',
            },
        },
        borderedContainer: {
            container: {
                w: '100%',
                p: '12px',
                border: '1px solid',
                borderColor: 'gray.200',
                borderRadius: '8px',
                _checked: {
                    backgroundColor: 'gray.100',
                },
            },
        },
    },
    defaultProps: {},
};
