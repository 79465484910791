import { ReactElement } from 'react';
import { BaseSchema } from 'yup';
import { StyleProps, ThemingProps } from '@chakra-ui/system';
import { UseControllerProps, FieldValues } from 'react-hook-form';
import { ClassicCKEditorProps } from '@shared/components/ckeditor';
import { DateInputProps } from '@shared/components/datepicker';
import { FieldElementsProps, FormControlProps } from './common';
import {
    InputProps,
    TextareaProps,
    NumberInputProps,
    CheckboxProps,
    MultipleCheckboxesProps,
    RadioProps,
    SwitchProps,
    SelectProps,
    EditableListProps,
} from './inputs';
import {
    TextFieldProps,
    TextareaFieldProps,
    NumberFieldProps,
    CheckboxFieldProps,
    MultipleCheckboxesFieldProps,
    RadioFieldProps,
    SwitchFieldProps,
    SelectFieldProps,
    EditableListFieldProps,
    MultipleInputFieldProps,
    CKEditorFieldProps,
    DateFieldProps,
} from './fields';

export interface SelectOption {
    label: string;
    value: string;
}

export type FieldControlProps = FieldElementsProps & {
    formControlProps?: FormControlProps;
};

export type ControllerFieldProps<TFieldValues extends FieldValues = FieldValues> =
    // todo types
    UseControllerProps<any> & FieldControlProps;

export enum FieldType {
    Text = 'text',
    Textarea = 'textarea',
    Number = 'number',
    Checkbox = 'checkbox',
    MultipleCheckboxes = 'multiple-checkboxes',
    Radio = 'radio',
    Switch = 'switch',
    Select = 'select',
    EditableList = 'editable-list',
    Date = 'date',
    WYSIWYG = 'wysiwyg',
}

export enum CompoundFieldType {
    MultipleInput = 'multiple-input',
}

export type FieldTypeUnion = `${FieldType}` | `${CompoundFieldType}`;

export interface InputTypePropsMap {
    [FieldType.Text]: InputProps;
    [FieldType.Textarea]: TextareaProps;
    [FieldType.Number]: NumberInputProps;
    [FieldType.Checkbox]: CheckboxProps;
    [FieldType.MultipleCheckboxes]: MultipleCheckboxesProps;
    [FieldType.Radio]: RadioProps;
    [FieldType.Switch]: SwitchProps;
    [FieldType.Select]: SelectProps;
    [FieldType.EditableList]: EditableListProps;
    [FieldType.Date]: DateInputProps;
    [FieldType.WYSIWYG]: ClassicCKEditorProps;
}

export interface FieldTypePropsMap {
    [FieldType.Text]: TextFieldProps;
    [FieldType.Textarea]: TextareaFieldProps;
    [FieldType.Number]: NumberFieldProps;
    [FieldType.Checkbox]: CheckboxFieldProps;
    [FieldType.MultipleCheckboxes]: MultipleCheckboxesFieldProps;
    [FieldType.Radio]: RadioFieldProps;
    [FieldType.Switch]: SwitchFieldProps;
    [FieldType.Select]: SelectFieldProps;
    [FieldType.EditableList]: EditableListFieldProps;
    [FieldType.Date]: DateFieldProps;
    [FieldType.WYSIWYG]: CKEditorFieldProps;
}

export type FieldConfig<
    Property extends FieldType,
    Plain extends boolean = boolean,
    Scheme extends BaseSchema = BaseSchema,
> = {
    type: Property;
    name: string;
    label?: string; // todo remove
    plain?: Plain; // simple input component outside form control
    scheme?: Scheme;
    props?: Plain extends true
        ? Omit<InputTypePropsMap[Property], 'name'>
        : Omit<FieldTypePropsMap[Property], 'name'>;
};

export type MultipleInputFieldConfig = {
    type: CompoundFieldType.MultipleInput;
    fields: FieldConfigs[];
    subforms?: any[];
    props?: MultipleInputFieldProps;
};

export type InputConfigs = {
    [Property in keyof InputTypePropsMap]: FieldConfig<Property, true>;
}[keyof InputTypePropsMap];

export type FieldConfigs = {
    [Property in keyof FieldTypePropsMap]: FieldConfig<Property, false>;
}[keyof FieldTypePropsMap];

export type CompoundFieldConfigs = MultipleInputFieldConfig;
export type FieldConfigsUnion = FieldConfigs | CompoundFieldConfigs;

export function isInputConfigs(config: FieldConfigs | InputConfigs): config is InputConfigs {
    return (config as InputConfigs).plain === true;
}

// allow overwriting field styles and theme for each form
// add SystemProps if you need pseudo props
type FieldStyle<ThemeComponent extends string = string> = ThemingProps<ThemeComponent> & StyleProps; // & SystemProps

export interface FieldStyles {
    control?: FieldStyle<'FormControl'>;
    label?: FieldStyle<'FormLabel'>;
    error?: FieldStyle<'FormErrorMessage'>;
    helpText?: FieldStyle<'FormControl'>;
    // tooltip?: FieldStyle<'Tooltip'>;

    inputGroup?: FieldStyle<'Input'>;
    input?: FieldStyle<'Input'>;
    textarea?: FieldStyle<'Textarea'>;
    checkbox?: FieldStyle<'Checkbox'> &
        Pick<CheckboxFieldProps, 'spacing' | 'iconColor' | 'iconSize' | 'icon'>;
    switch?: FieldStyle<'Switch'> & Pick<SwitchFieldProps, 'spacing'>;
    radio?: Omit<FieldStyle<'Radio'>, 'orientation'>;
    editableList?: Pick<EditableListFieldProps, 'size' | 'colorScheme' | 'tagVariant'>;
    select?: Pick<SelectFieldProps, 'size' | 'colorScheme' | 'variant' | 'tagVariant'>;
    // & SelectFieldProps['chakraStyles'];
}

export interface MultipleInputFieldHint {
    hint: ReactElement;
    positionIndex: number;
}
