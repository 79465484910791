import { useAppSelector } from '@app/hooks';
import {
    selectAllowedTechnologies,
    selectCustomTechnologiesByTechnology,
    selectMachinesPresets,
    selectMachinesPresetsByTechnology,
    selectMaterialsByTechnologies,
} from '../../selectors';
import { parseTechnologiesOptions, parseMachinesOptions } from './helpers';

export const useTechnologyOptions = () => {
    const technologies = useAppSelector(selectAllowedTechnologies);
    const technologiesOptions = parseTechnologiesOptions(technologies);
    return {
        technologies,
        technologiesOptions,
    };
};

type Props = {
    technologyId?: number;
};

export const useCustomTechnologyOptions = ({ technologyId }: Props) => {
    const customTechnologies = useAppSelector(state =>
        selectCustomTechnologiesByTechnology(state, technologyId),
    );
    const customTechnologiesOptions = parseTechnologiesOptions(customTechnologies);

    return {
        customTechnologies,
        customTechnologiesOptions,
    };
};

export const useMachinePresetsOptions = ({ technologyId }: Props) => {
    const machinePresets = useAppSelector(selectMachinesPresets);
    const currentTechnologyPresets = useAppSelector(state =>
        selectMachinesPresetsByTechnology(state, technologyId),
    );
    const machinesOptions = parseMachinesOptions(
        technologyId ? currentTechnologyPresets : machinePresets,
    );
    return {
        machinePresets,
        currentTechnologyPresets,
        machinesOptions,
    };
};

export const useMaterialOptions = ({ technologyIds }: { technologyIds?: number[] } = {}) => {
    const materials = useAppSelector(state => selectMaterialsByTechnologies(state, technologyIds));
    const materialsOptions = materials?.map(material => ({
        value: String(material.id),
        label: `${material.title} (${material.printerLabel})`,
    }));
    return {
        materials,
        materialsOptions,
    };
};
