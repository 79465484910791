import { Box, Tooltip, TooltipProps } from '@chakra-ui/react';
import { HelpCircleIcon } from '@shared/components/icons';

export interface HintProps extends Omit<TooltipProps, 'children'> {}

export const Hint = ({ ...rest }: HintProps) => {
    return (
        <Tooltip {...rest}>
            {/* TODO: Bug with tooltip ref in chakra */}
            <Box display="inline-flex" ml="1">
                <HelpCircleIcon color="gray.400" w="3.5" h="3.5" />
            </Box>
        </Tooltip>
    );
};
