import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, FieldType, objectToSelectOptions } from '@shared/components/forms-v2';
import { TextParticle as Particle } from '@shared/components/typography';
import {
    FEATURE_TYPE_LABELS,
    SHAPE_TYPE_LABELS,
    TOPOLOGY_TYPE_LABELS,
} from '@services/df/codegen-enums-labels';
import { FeatureType } from '@services/df/codegen-enums';
import { uuidv4 } from '@shared/utils/uuid';
import { isBelong2D } from '@shared/utils/technology-guard';
import { filterShapeTypeLabels } from './helpers';

type ConfigArgs = {
    currencySign?: string;
    topologyTypeDefault?: string;
    technologyId?: number;
};

export const getMachineFeatureConfig = ({
    currencySign = '$',
    topologyTypeDefault = 'hole',
    technologyId,
}: ConfigArgs = {}) => {
    const is2DTechnology = isBelong2D(technologyId);

    const name = defineField({
        type: FieldType.Text,
        name: 'name',
        scheme: yup.string().ensure().trim().required().default('Drilled hole'),
        props: {
            label: 'Feature name (thread type)',
        },
    });

    const is_active = defineField({
        type: FieldType.Switch,
        name: 'is_active',
        scheme: yup.boolean().default(true),
        props: {
            label: 'Active',
        },
    });

    const price_per_feature = defineField({
        type: FieldType.Number,
        name: 'price_per_feature',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: 'Price per feature',
            rightAddon: currencySign,
            min: 0,
        },
    });

    const price_set_up = defineField({
        type: FieldType.Number,
        name: 'price_set_up',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: 'Price set up',
            rightAddon: currencySign,
            min: 0,
        },
    });

    const feature_type = defineField({
        type: FieldType.Select,
        name: 'feature_type',
        scheme: yup.string().default(FeatureType.Cuboids),
        props: {
            label: 'Feature type',
            options: objectToSelectOptions(FEATURE_TYPE_LABELS),
        },
    });

    const through = defineField({
        type: FieldType.Select,
        name: 'through',
        scheme: yup.string().ensure(),
        props: {
            label: 'Blind/Not blind',
            helpText: 'Is this feature with ratio below allowed to be through?',
            options: [
                {
                    label: 'Any',
                    value: '',
                },
                {
                    label: 'Not blind',
                    value: 'through',
                },
                {
                    label: 'Blind',
                    value: 'not_through',
                },
            ],
        },
    });

    const shape_type = defineField({
        type: FieldType.Select,
        name: 'shape_type',
        scheme: yup.string().ensure(),
        props: {
            label: 'Hole form (shape type)',
            options: objectToSelectOptions(
                filterShapeTypeLabels({ shapeTypeLabels: SHAPE_TYPE_LABELS, is2DTechnology }),
            ),
        },
    });

    const topology_type = defineField({
        type: FieldType.Select,
        name: 'topology_type',
        scheme: yup.string().ensure().default(topologyTypeDefault),
        props: {
            label: 'Feature topology',
            options: objectToSelectOptions(TOPOLOGY_TYPE_LABELS),
        },
    });

    const radius = defineField({
        type: FieldType.Number,
        name: 'radius',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: 'Min radius',
            helpText: 'Min endwall radius (need to be >=)',
            min: 0,
        },
    });

    const angle = defineField({
        type: FieldType.Number,
        name: 'angle',
        scheme: yup.number().requiredNullEqualTo0(),
        props: {
            label: 'Max angle',
            helpText: 'Max vertical angle, (need to be <=)',
            rightAddon: '°',
        },
    });

    const min_ratio = defineField({
        type: FieldType.Number,
        name: 'min_ratio',
        scheme: yup.number().requiredNullEqualTo0().min(0).max(999),
        props: {
            label: 'Min Ratio',
            helpText: 'Min ratio (need to be >=) of height to diameter',
            min: 0,
            max: 999,
        },
    });

    const max_ratio = defineField({
        type: FieldType.Number,
        name: 'max_ratio',
        scheme: yup.number().requiredNullEqualTo0(10).min(0).max(999),
        props: {
            label: 'Max Ratio',
            helpText: 'Max ratio (need to be <) of height to diameter',
            min: 0,
            max: 999,
        },
    });

    const time_per_feature = defineField({
        type: FieldType.Number,
        name: 'time_per_feature',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: (
                <span>
                    Time of running per unit&nbsp;of measurement,
                    <br />
                    <Particle>per-piece</Particle>
                </span>
            ),
            rightAddon: 'min',
            min: 0,
        },
    });

    const time_of_programming_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_recurring',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: (
                <span>
                    Time of operator per unit of measurement, one&#8209;off,{' '}
                    <Particle>recurring</Particle>
                </span>
            ),
            rightAddon: 'min',
            min: 0,
        },
    });

    const time_of_programming_non_recurring = defineField({
        type: FieldType.Number,
        name: 'time_of_programming_non_recurring',
        scheme: yup.number().requiredNullEqualTo0().min(0),
        props: {
            label: (
                <span>
                    Time of programmer per unit of measurement, one&#8209;off,{' '}
                    <Particle>non-recurring</Particle>
                </span>
            ),
            rightAddon: 'min',
            min: 0,
        },
    });

    const fields = {
        topology_type,
        name,
        ...(!is2DTechnology && {
            price_per_feature,
            price_set_up,
            time_per_feature,
            time_of_programming_recurring,
            time_of_programming_non_recurring,
        }),
        is_active,
        feature_type,
        through,
        shape_type,
        radius,
        angle,
        min_ratio,
        max_ratio,
    };

    const schema = yup.object({
        // if machine feature is not created, we only have the virtual uuid that we used when submitting for example in PT
        id: yup
            .string()
            .uuid()
            .default(() => uuidv4()),
        ...mapValues(fields, 'scheme'),
    });

    return {
        fields,
        schema,
    };
};

export type MachineFeatureConfig = ReturnType<typeof getMachineFeatureConfig>;
export type MachineFeatureSchema = MachineFeatureConfig['schema'];
