import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ModelsUploadResponse, UploadStatusResponse } from '@shared/types';
import { UploadFileAcceptType, UploadFileRejectType } from '@shared/components/upload';
import { ModelsUploadState } from './types';

// todo move to widgets/models-uploader

const initialState: ModelsUploadState = {
    uploadJob: '',
    models: [],
    acceptedFiles: [],
    rejectedFiles: [],
    readyModelsIds: [],
    isActivePolling: false,
    isUploadJobCreating: false,
};

export const modelsUploadSlice = createSlice({
    name: 'models/upload',
    initialState,
    reducers: {
        createUploadJob(state) {
            state.isUploadJobCreating = true;
        },
        createUploadJobSuccess(state, action: PayloadAction<string>) {
            state.uploadJob = action.payload;
            state.isUploadJobCreating = false;
        },
        createUploadJobFailure(state) {
            state.isUploadJobCreating = false;
        },
        setUploadModels(state, action: PayloadAction<File[]>) {},
        uploadModels(state, action: PayloadAction<UploadFileAcceptType[]>) {},

        uploadModelsSuccess(
            state,
            action: PayloadAction<{
                models: ModelsUploadResponse[];
                files: UploadFileAcceptType[];
            }>,
        ) {
            const { models, files } = action.payload;

            const newModels = [] as ModelsUploadState['models'];

            models.forEach((model, i) => {
                if ('detail' in model) {
                    state.rejectedFiles = state.rejectedFiles.concat({
                        fileName: model.file_name,
                        errorCodes: [model.detail!],
                        uuid: files[i].uuid,
                        codeValues: {},
                    });
                    state.acceptedFiles = state.acceptedFiles.filter(
                        accepted =>
                            !state.rejectedFiles.find(rejected => rejected.uuid === accepted.uuid),
                    );

                    return;
                }

                model?.object_models?.forEach(objectModel => {
                    newModels.unshift({
                        ...objectModel,
                        uuid: files[i].uuid,
                    });
                });
            });

            state.acceptedFiles = state.acceptedFiles.filter(
                file => !newModels.find(model => model.uuid === file.uuid),
            );

            state.models = newModels.concat(state.models);
        },
        uploadModelsFailure(state) {},

        addAcceptedFiles(state, action: PayloadAction<UploadFileAcceptType[]>) {
            state.acceptedFiles = state.acceptedFiles.concat(action.payload);
        },
        addRejectedFiles(state, action: PayloadAction<UploadFileRejectType[]>) {
            state.rejectedFiles = state.rejectedFiles.concat(action.payload);
        },
        deleteRejectedFile(state, action: PayloadAction<string>) {
            state.rejectedFiles = state.rejectedFiles.filter(file => file.uuid !== action.payload);
        },
        deleteModel(state, action: PayloadAction<number>) {
            state.models = state.models.filter(model => model.id !== action.payload);
        },
        updateProgress(state, action: PayloadAction<{ UUIDs: string[]; progress: number }>) {
            state.acceptedFiles = state.acceptedFiles.map(file => {
                if (action.payload.UUIDs.includes(file.uuid)) {
                    return {
                        ...file,
                        progress: action.payload.progress,
                        ...(action.payload.progress === 100 && { status: 'uploaded' }),
                    };
                }
                return file;
            });
        },
        startStatusPolling(state) {
            state.isActivePolling = true;
        },
        stopStatusPolling(state) {
            state.isActivePolling = false;
        },
        checkStatus(state) {},
        checkStatusSuccess(state, action: PayloadAction<UploadStatusResponse>) {
            const modelsStatuses = action.payload;
            const ids = Object.keys(modelsStatuses).map(Number);
            state.models = state.models
                .map(model => {
                    if (ids.includes(model.id)) {
                        switch (modelsStatuses[model.id]) {
                            case 'ready':
                                return { ...model, status: 'ready' };
                            case 'failed':
                                return { ...model, status: 'failed' };
                        }
                    }
                    return model;
                })
                .filter(model => {
                    if (modelsStatuses[model.id] === 'failed') {
                        state.rejectedFiles = state.rejectedFiles.concat({
                            fileName: model.title,
                            errorCodes: ["Model couldn't be analyzed, please try to fix it."],
                            uuid: model.uuid,
                            codeValues: {},
                        });
                        return false;
                    }
                    return true;
                });
        },
        checkStatusFailure(state) {},

        setReadyModel(state, action: PayloadAction<number>) {
            state.readyModelsIds.push(action.payload);
        },
        deleteReadyModelId(state, action) {
            state.readyModelsIds = state.readyModelsIds.filter(id => id !== action.payload);
        },
        readyModelsPolled(state, action: PayloadAction<number[]>) {},
    },
});

export const {
    createUploadJob,
    createUploadJobSuccess,
    uploadModelsSuccess,
    createUploadJobFailure,
    startStatusPolling,
    stopStatusPolling,
    addAcceptedFiles,
    updateProgress,
    uploadModelsFailure,
    addRejectedFiles,
    deleteRejectedFile,
    deleteModel,
    checkStatus,
    checkStatusSuccess,
    checkStatusFailure,
    deleteReadyModelId,
    setUploadModels,
    uploadModels,
    setReadyModel,
    readyModelsPolled,
} = modelsUploadSlice.actions;
