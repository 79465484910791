import qs from 'qs';
import { Suspense, lazy } from 'react';
import { PersistGate } from 'redux-persist/integration/react';
import { Navigate, Route, Routes, useNavigate, useLocation } from 'react-router-dom';
import { ErrorBoundary } from '@sentry/react';
import { QueryParamProvider, EncodedQuery, transformSearchStringJsonSafe } from 'use-query-params';
import { ReactRouter6Adapter } from 'use-query-params/adapters/react-router-6';
import { IntercomProvider } from 'react-use-intercom';
import { ChakraProvider } from '@chakra-ui/react';
import { storeManager } from '@app/store';
import { AppLocationState } from '@app/types';
import Analytics from '@shared/components/analytics';
import DevRoutes from '@modules/dev-routes';
import { CompanyLoginPage, SignInPage } from '@modules/auth';
import { AnalyticsDashboardPage } from '@modules/dashboard';
import { AppContainer } from '@shared/components/app-container';
import { getBaseErrorBoundaryProps } from '@shared/components/error-boundaries';
import { PreloaderBox } from '@shared/components/preloader';
import { ContextualModal } from '@shared/components/contextual-modal';
import { GuestRoute, PrivateRoute } from '@shared/components/routes';
import { TransportProvider } from '@shared/components/transport';
import { ROUTES } from '@shared/constants/routes';
import { IntercomUtils } from '@shared/utils';
import { FCC } from '@shared/types';
import Layout, { baseLayout } from './layouts';
import { Globally } from './styles/global';
import theme from './styles/theme';

const queryParamOptions = {
    // enableBatching: true,
    // updateType: 'replaceIn' as const,
    searchStringToObject: (searchString: string) =>
        qs.parse(searchString, {
            ignoreQueryPrefix: true,
        }) as EncodedQuery,
    objectToSearchString: (encodedParams: EncodedQuery) => {
        const search = qs.stringify(encodedParams);
        return transformSearchStringJsonSafe(search);
    },
};

const MachinesMaterialsMainPageLazy = lazy(
    () => import('@modules/machine-materials/main/machines-materials-main-page'),
);
const MaterialCreatePageLazy = lazy(
    () => import('@modules/machine-materials/material/material-create/material-create.page'),
);
const MaterialEditPageLazy = lazy(
    () => import('@modules/machine-materials/material/material-edit/material-edit.page'),
);
const MachineCreatePageLazy = lazy(
    () => import('@modules/machine-materials/machine/machine-create/machine-create.page'),
);
const MachineEditPageLazy = lazy(
    () => import('@modules/machine-materials/machine/machine-edit/machine-edit.page'),
);
const PriceMatchSetupPageLazy = lazy(
    () => import('@modules/price-matching-tool/setup/price-match-setup-page'),
);
const PriceMatchResultPageLazy = lazy(
    () => import('@modules/price-matching-tool/price-match-result/price-match-result.page'),
);
const PriceTweakerMainPageLazy = lazy(
    () => import('@modules/price-tweaker/main/price-tweaker-main-page'),
);
const PriceTweakerPurchasePageLazy = lazy(
    () => import('@modules/price-tweaker/purchase/price-tweaker-purchase-page'),
);
const CompanySettingsPageLazy = lazy(
    () => import('@modules/setttings/company/company-settings-page'),
);
const WidgetSettingsPageLazy = lazy(() => import('@modules/setttings/widget/widget-settings-page'));
const BillingSettingsPageLazy = lazy(
    () => import('@modules/setttings/billing/billing-settings-page'),
);
const OrdersMainPageLazy = lazy(() => import('@modules/orders/main/orders-main-page'));
const OrdersCartsPageLazy = lazy(() => import('@modules/orders/carts/orders-carts-page'));
const OrdersDraftsPageLazy = lazy(() => import('@modules/orders/drafts/orders-drafts-page'));
const OrdersLineItemsPageLazy = lazy(
    () => import('@modules/orders/line-items/orders-line-items-page'),
);
const OrderDetailsPageLazy = lazy(() => import('@modules/orders/details/order-details-page'));
const UsersRolesMainPageLazy = lazy(
    () => import('@modules/users-roles/main/users-roles-main-page'),
);
const UsersRolesNewUserPageLazy = lazy(() => import('@modules/users-roles/user/new-user-page'));
const ClientsMainPageLazy = lazy(() => import('@modules/clients/main/clients-main-page'));
const ClientDetailsPageLazy = lazy(() => import('@modules/clients/details/client-details-page'));
const OrganizationDetailsPageLazy = lazy(
    () => import('@modules/organizations/details/organization-details-page'),
);
const ModelsMainPageLazy = lazy(() => import('@modules/models/main/models-main-page'));
const ModelsDetailsPageLazy = lazy(() => import('@modules/models/details/models-details-page'));
const InstallWidgetPageLazy = lazy(() => import('@modules/install-widget/install-widget-page'));
const DiscountsPageLazy = lazy(() => import('@modules/discounts/discounts-page'));
const PersonalInfoPageLazy = lazy(
    () => import('@modules/setttings/personal-info/personal-info-page'),
);
const NotFoundPageLazy = lazy(() => import('@modules/not-found/not-found-page'));

/*
    Contextual modal navigation with React Router:
        1) https://stackforgeeks.com/blog/react-router-modalonly-routes#implementing-modals-with-react-router-6-and-remix-a-comparative-guide-to-nested-routing-and-search-params-approaches
        2) https://www.infoxicator.com/modals-with-react-router-6-and-remix

    We'll stick to the basic approach (with background location https://stackblitz.com/edit/github-9lelz2-tdcovz?file=src%2FApp.tsx)
    since we're not using React-Router loaders. If you need loaders, choose the implementation from the first link.
 */

const CompanyRoutesHandler: FCC = ({ children }) => {
    let location = useLocation();
    let { backgroundLocation } = (location.state as AppLocationState) || {};

    return (
        // PersistGate depends on the storeManager.companyName, so it is inside the AppContainer
        <PersistGate loading={<PreloaderBox in />} persistor={storeManager.persistor}>
            {/* https://reactrouter.com/6.30.0/components/routes */}
            <Routes location={backgroundLocation || location}>{children}</Routes>
            {/* Show the modal when a `backgroundLocation` is set */}
            {backgroundLocation && (
                <Routes>
                    <Route
                        path={ROUTES.PRICE_TWEAKER_PURCHASE}
                        element={
                            <ContextualModal>
                                <PriceTweakerPurchasePageLazy showAppHeader={false} />
                            </ContextualModal>
                        }
                    />
                </Routes>
            )}
        </PersistGate>
    );
};

export function App() {
    let location = useLocation();
    let { backgroundLocation, lineItemPosition, backUrl } =
        (location.state as AppLocationState) || {};

    return (
        <ChakraProvider theme={theme} resetCSS={true}>
            <ErrorBoundary {...getBaseErrorBoundaryProps({ label: 'Root level error' })}>
                <Globally />
                <Analytics />
                <DevRoutes />

                <Suspense fallback={<PreloaderBox in />}>
                    <IntercomProvider
                        appId={process.env.REACT_APP_INTERCOM_WORKSPACE_ID}
                        autoBoot={Boolean(process.env.REACT_APP_WITH_SIDEBAR)}
                        shouldInitialize={
                            Boolean(process.env.REACT_APP_WITH_SIDEBAR) &&
                            Boolean(process.env.REACT_APP_INTERCOM_WORKSPACE_ID)
                        }
                        onShow={IntercomUtils.ignoreFocusLock}
                    >
                        <QueryParamProvider
                            adapter={ReactRouter6Adapter}
                            options={queryParamOptions}
                        >
                            <TransportProvider>
                                {/* anonymous only */}
                                <Routes>
                                    <Route element={<GuestRoute />}>
                                        <Route element={baseLayout}>
                                            <Route
                                                path={ROUTES.COMPANY_LOGIN}
                                                element={<CompanyLoginPage />}
                                            />
                                            <Route path={ROUTES.SIGN_IN} element={<SignInPage />} />
                                        </Route>
                                    </Route>
                                </Routes>

                                {/* company auth only */}
                                <AppContainer>
                                    {/* CompanyRoutesHandler */}
                                    <PersistGate
                                        loading={<PreloaderBox in />}
                                        persistor={storeManager.persistor}
                                    >
                                        <Routes location={backgroundLocation || location}>
                                            <Route element={Layout}>
                                                {/* Machines & Materials */}
                                                <Route
                                                    path={ROUTES.MACHINES_MATERIALS}
                                                    element={<MachinesMaterialsMainPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.CREATE_MATERIAL}
                                                    element={<MaterialCreatePageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.EDIT_MATERIAL}
                                                    element={<MaterialEditPageLazy />}
                                                />
                                                <Route
                                                    element={
                                                        <PrivateRoute
                                                            to={ROUTES.BILLING_SETTINGS}
                                                            isAllowed={({ permissions }) =>
                                                                permissions.isAdditionalMachinesEnabled
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Route
                                                        path={ROUTES.CREATE_PRINTER}
                                                        element={<MachineCreatePageLazy />}
                                                    />
                                                </Route>
                                                <Route
                                                    path={ROUTES.EDIT_PRINTER}
                                                    element={<MachineEditPageLazy />}
                                                />

                                                {/* MM tools */}
                                                <Route
                                                    path={ROUTES.SETUP_PRICE_MATCHING}
                                                    element={<PriceMatchSetupPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.PRICE_MATCHING}
                                                    element={<PriceMatchResultPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.PRICE_TWEAKER_MAIN}
                                                    element={<PriceTweakerMainPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.PRICE_TWEAKER_PURCHASE}
                                                    element={
                                                        <PriceTweakerPurchasePageLazy
                                                            lineItemPosition={lineItemPosition}
                                                        />
                                                    }
                                                />

                                                {/* Settings */}
                                                <Route
                                                    path={ROUTES.COMPANY_SETTINGS}
                                                    element={<CompanySettingsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.WIDGET_SETTINGS}
                                                    element={<WidgetSettingsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.BILLING_SETTINGS}
                                                    element={<BillingSettingsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.PERSONAL_INFO_SETTINGS}
                                                    element={<PersonalInfoPageLazy />}
                                                />

                                                {/* Orders */}
                                                <Route
                                                    path={ROUTES.ORDERS_MAIN}
                                                    element={<OrdersMainPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.ORDERS_BOARD}
                                                    element={
                                                        <Navigate
                                                            to={ROUTES.ORDERS_MAIN}
                                                            replace
                                                            state={{ ordersTab: 'board' }}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path={ROUTES.ORDERS_LINE_ITEMS}
                                                    element={<OrdersLineItemsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.ORDERS_DRAFTS}
                                                    element={<OrdersDraftsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.ORDERS_CARTS}
                                                    element={<OrdersCartsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.ORDERS_DETAILS}
                                                    element={
                                                        // todo remove backUrl, check NNW back button impl
                                                        <OrderDetailsPageLazy backUrl={backUrl} />
                                                    }
                                                />

                                                {/* Users & Roles */}
                                                <Route
                                                    path={ROUTES.USERS_ROLES_MAIN}
                                                    element={<UsersRolesMainPageLazy />}
                                                />
                                                <Route
                                                    element={
                                                        <PrivateRoute
                                                            to={ROUTES.BILLING_SETTINGS}
                                                            isAllowed={({ permissions }) =>
                                                                permissions.isUsersRolesSettingsEnabled
                                                            }
                                                        />
                                                    }
                                                >
                                                    <Route
                                                        path={ROUTES.USERS_ROLES_ADD_USER}
                                                        element={<UsersRolesNewUserPageLazy />}
                                                    />
                                                </Route>

                                                {/* Customers & Organizations */}
                                                <Route
                                                    path={ROUTES.CLIENTS_MAIN}
                                                    element={<ClientsMainPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.CLIENTS_DETAILS}
                                                    element={<ClientDetailsPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.ORGANIZATIONS_MAIN}
                                                    element={
                                                        <Navigate
                                                            to={ROUTES.CLIENTS_MAIN}
                                                            replace
                                                            state={{ clientsTab: 'companies' }}
                                                        />
                                                    }
                                                />
                                                <Route
                                                    path={ROUTES.ORGANIZATIONS_DETAILS}
                                                    element={<OrganizationDetailsPageLazy />}
                                                />

                                                {/* Models */}
                                                <Route
                                                    path={ROUTES.MODELS_MAIN}
                                                    element={<ModelsMainPageLazy />}
                                                >
                                                    <Route
                                                        path={ROUTES.MODELS_DETAILS}
                                                        element={<ModelsDetailsPageLazy />}
                                                    />
                                                </Route>

                                                {/* Other */}
                                                <Route
                                                    path={ROUTES.DASHBOARD}
                                                    element={<AnalyticsDashboardPage />}
                                                />
                                                <Route
                                                    path={ROUTES.INSTALL_WIDGET}
                                                    element={<InstallWidgetPageLazy />}
                                                />
                                                <Route
                                                    path={ROUTES.DISCOUNTS}
                                                    element={<DiscountsPageLazy />}
                                                />

                                                {/* Not found */}
                                                <Route
                                                    path={ROUTES.NOT_FOUND}
                                                    element={<NotFoundPageLazy />}
                                                />
                                            </Route>
                                        </Routes>
                                        {/* Show the modal when a `backgroundLocation` is set */}
                                        {backgroundLocation && (
                                            <Routes>
                                                <Route
                                                    path={ROUTES.PRICE_TWEAKER_PURCHASE}
                                                    element={
                                                        <ContextualModal>
                                                            <PriceTweakerPurchasePageLazy
                                                                showAppHeader={false}
                                                                // todo remove
                                                                lineItemPosition={lineItemPosition}
                                                            />
                                                        </ContextualModal>
                                                    }
                                                />
                                            </Routes>
                                        )}
                                    </PersistGate>
                                </AppContainer>
                            </TransportProvider>
                        </QueryParamProvider>
                    </IntercomProvider>
                </Suspense>
            </ErrorBoundary>
        </ChakraProvider>
    );
}

export function useNavigateToNotFoundPage(targetUrl: string) {
    const navigate = useNavigate();
    return () => navigate(targetUrl, { replace: true });
}
