import trim from 'lodash/trim';
import { ChangeEventHandler, KeyboardEventHandler, useCallback } from 'react';
import { InputLeftElement } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { Input, CommonInputProps } from '@shared/components/forms-v2';
import { SearchIcon } from '@shared/components/icons';

export interface SearchInputProps extends CommonInputProps {
    onFinalize: (value: string) => void;
}

export const SearchInput = forwardRef<SearchInputProps, 'input'>(
    (
        {
            value,
            onFinalize: _onFinalize,
            placeholder = 'Search',
            leftElement = (
                <InputLeftElement pointerEvents="none">
                    <SearchIcon />
                </InputLeftElement>
            ),
            ...rest
        },
        ref,
    ) => {
        const onFinalize = useCallback(
            (nextValue: string) => {
                nextValue = trim(nextValue);
                if (nextValue !== value) {
                    _onFinalize(nextValue);
                }
            },
            [value, _onFinalize],
        );
        const handleBlur: ChangeEventHandler<HTMLInputElement> = useCallback(
            ({ target }) => {
                onFinalize(target.value);
            },
            [onFinalize],
        );
        const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
            ({ code, currentTarget }) => {
                if (code === 'Enter' || code === 'NumpadEnter') {
                    onFinalize(currentTarget.value);
                }
            },
            [onFinalize],
        );

        return (
            <Input
                ref={ref}
                leftElement={leftElement}
                onBlur={handleBlur}
                onKeyDown={handleKeyDown}
                defaultValue={value} // make it uncontrolled
                placeholder={placeholder}
                {...rest}
            />
        );
    },
);
