import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, objectToSelectOptions, FieldType } from '@shared/components/forms-v2';
import { AddressType } from '@services/df/codegen-enums';
import { ADDRESS_TYPE_LABELS, COUNTRY_LABELS } from '@services/df/codegen-enums-labels';

export const getAddressConfig = () => {
    const address_type = defineField({
        type: FieldType.Select,
        name: 'address_type',
        scheme: yup.string().default(AddressType.CustomerCompany),
        props: {
            label: 'Address type',
            options: objectToSelectOptions(ADDRESS_TYPE_LABELS),
        },
    });

    const company_name = defineField({
        type: FieldType.Text,
        name: 'company_name',
        scheme: yup.string().ensure().trim().max(100),
        props: {
            label: 'Company name',
        },
    });

    const country = defineField({
        type: FieldType.Select,
        name: 'country',
        scheme: yup.string().required(),
        props: {
            label: 'Country',
            options: objectToSelectOptions(COUNTRY_LABELS),
        },
    });

    const state = defineField({
        type: FieldType.Select,
        name: 'state',
        scheme: yup.string().ensure().default(''),
        props: {
            label: 'State',
        },
    });

    const city = defineField({
        type: FieldType.Text,
        name: 'city',
        scheme: yup.string().ensure().trim().required(),
        props: {
            label: 'City',
        },
    });

    const street_address = defineField({
        type: FieldType.Text,
        name: 'street_address',
        scheme: yup.string().ensure().trim().required(),
        props: {
            label: 'Street address',
        },
    });

    const apartment = defineField({
        type: FieldType.Text,
        name: 'apartment',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Apartment',
        },
    });

    const postcode = defineField({
        type: FieldType.Text,
        name: 'postcode',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Postal code',
        },
    });

    const vat_number = defineField({
        type: FieldType.Text,
        name: 'vat_number',
        scheme: yup.string().ensure().trim(),
        props: {
            label: 'Sales tax number',
        },
    });

    const schema = yup.object(
        mapValues(
            {
                address_type,
                company_name,
                country,
                state,
                city,
                street_address,
                apartment,
                postcode,
                vat_number,
            },
            'scheme',
        ),
    );

    return {
        fields: {
            address_type,
            company_name,
            country,
            state,
            city,
            street_address,
            apartment,
            postcode,
            vat_number,
        },
        schema,
    };
};

export type AddressConfig = ReturnType<typeof getAddressConfig>;
export type AddressSchema = AddressConfig['schema'];
export type AddressFields = AddressConfig['fields'];
export type AddressFieldsUnion = keyof AddressConfig['fields'];
export type AddressFieldsArray = Array<AddressFields[AddressFieldsUnion]>;

// todo static method of FormHelper.fromFields
export const getAddressConfigFromFields = (fields: ReadonlyArray<AddressFieldsUnion>) => {
    const { fields: _fields, schema } = getAddressConfig();

    return {
        fields: fields.map(field => _fields[field]),
        fieldsMap: _fields,
        // @ts-ignore
        schema: schema.pick(fields),
    };
};
