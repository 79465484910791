import { RouterNavigateOptions, To } from '@remix-run/router';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Toast } from '@shared/components/toast';

const initialState = {};

export const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        redirectRouter(state, action: PayloadAction<{ to: To; opts?: RouterNavigateOptions }>) {},
        redirectWindow(state, action: PayloadAction<{ url: string }>) {},
        triggerToast(state, action: PayloadAction<Toast>) {},
    },
});

export const { redirectRouter, redirectWindow, triggerToast } = appSlice.actions;
