export const PopoverStyles = {
    baseStyle: {
        content: {
            borderRadius: 6,
            boxShadow: '0px 4px 4px 0px #0000001A, 2px 4px 8px 5px #0000000D',
        },
        header: { px: 4, pt: 4, pb: 0, border: 0 },
        body: { px: 4, pt: 4, pb: 2 },
        footer: { px: 4, pt: 2, pb: 4, border: 0 },
        closeButton: { color: 'gray.400' },
    },
};
