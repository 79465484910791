/*
    Based on codegen.ts
*/

import { getBackendUrl } from '../../instance';
import { enhancedApi as api } from './codegen';

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        auth: build.query<AuthApiResponse, AuthApiArg>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                // Hard coded for development
                if (
                    process.env.NODE_ENV === 'development' &&
                    process.env.REACT_APP_EXPIRING_AUTH_TOKEN
                ) {
                    return Promise.resolve({
                        data: { token: process.env.REACT_APP_EXPIRING_AUTH_TOKEN },
                    });
                }

                return (await baseQuery({
                    url: `/v2/obtain_session_token/`,
                    method: 'POST',
                    withCredentials: true,
                })) as { data: Auth };
            },
        }),
        logout: build.mutation<unknown, void>({
            async queryFn(_arg, _queryApi, _extraOptions, baseQuery) {
                return await baseQuery({
                    url: getBackendUrl('/manage/logout/'),
                    withCredentials: true,
                });
            },
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type AuthApiResponse = /** status 200  */ Auth;
export type AuthApiArg = void;
export type Auth = { token: string };
export const { useAuthQuery, useLogoutMutation } = injectedRtkApi;
