import type { ReactElement } from 'react';
import { DividerProps, ListItemProps } from '@chakra-ui/react';

export interface ParentRouteNavItem extends ListItemProps {
    items: readonly Exclude<NavItem, ParentRouteNavItem>[];
    children: string;
    leftAddon?: ReactElement;
}

export interface RouteNavItem extends ListItemProps {
    href: string;
    children: string;
    leftAddon?: ReactElement;
}

export interface ButtonNavItem extends ListItemProps {
    onClick: () => void;
    children: string;
    leftAddon?: ReactElement;
}

export interface DividerNavItem extends DividerProps {
    divider: true;
}

export interface SubheaderNavItem extends ListItemProps {
    subheader: true;
    children: string;
    leftAddon?: ReactElement;
}

export type NavItem = ParentRouteNavItem | RouteNavItem | ButtonNavItem | DividerNavItem;
// | SubheaderNavItem;

export const isRoute = (navItem: NavItem): navItem is RouteNavItem =>
    !!(navItem as RouteNavItem).href;

export const isParent = (navItem: NavItem): navItem is ParentRouteNavItem =>
    !!(navItem as ParentRouteNavItem).items?.length;

export const isButton = (navItem: NavItem): navItem is ButtonNavItem =>
    !!(navItem as ButtonNavItem).onClick;

export const isDivider = (navItem: NavItem): navItem is DividerNavItem =>
    (navItem as DividerNavItem).divider === true;

// export const isSubheader = (navItem: NavItem): navItem is SubheaderNavItem =>
//     (navItem as SubheaderNavItem).subheader === true;

export type OnItemSelect = (item: NavItem) => void;
