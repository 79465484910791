export * from './roles';
export * from './routes';
export * from './requests';
export * from './technologies';
export * from './schema';
export * from './storage';
export * from './ui';

// https://docs.celeryq.dev/en/stable/reference/celery.result.html#celery.result.AsyncResult.status
export enum TaskStatus {
    Pending = 'PENDING',
    Started = 'STARTED',
    Retry = 'RETRY',
    Failure = 'FAILURE',
    Success = 'SUCCESS',
}
