type Join<Str extends any[], Cache extends string = ''> = Str extends []
    ? Cache
    : Str extends [infer Head, ...infer Tail]
    ? Head extends string | number
        ? Join<Tail, Cache extends '' ? Head : `${Cache}-${Head}`>
        : Cache
    : Cache;

type NodePath = string | number | undefined | null;

export function composeNodeId<T extends Array<NodePath>>(...path: T) {
    return path.filter(Boolean).join('-') as Join<T>;
}

export function getNodeIdComposer<Prefix extends Array<string>>(...prefix: Prefix) {
    return <T extends Array<NodePath>>(...path: T) => composeNodeId(...prefix, ...path);
}
