import { RootState } from '@app/types';
import { createSelector } from '@reduxjs/toolkit';
import { MaterialPreset } from '@shared/types';

export const selectMachinesMaterials = (state: RootState) => state.createMaterial.machinesMaterials;
export const selectMaterialPresets = (state: RootState) => state.createMaterial.presets;

export const selectSuitableMaterials = (state: RootState) => state.createMaterial.suitableMaterials;

export const selectIsLoadingPresets = (state: RootState) => state.createMaterial.isLoadingPresets;

export const selectIsLoadingMachinesMaterials = (state: RootState) =>
    state.createMaterial.isLoadingMachines;

export const selectIsCreatingMaterial = (state: RootState) =>
    state.createMaterial.isCreatingMaterial;

export const selectCurrentTechnology = createSelector(
    selectMachinesMaterials,
    (state: RootState, machineId: string | number) => machineId,
    (machinesMaterials, machineId) => {
        if (!machineId) {
            return undefined;
        }
        const currentMachine = machinesMaterials.find(machine => machine.id === Number(machineId));

        return currentMachine?.technology;
    },
);

export const selectMaterialPresetsBySuitable = (currentMachineId: string) =>
    createSelector(
        selectSuitableMaterials,
        selectMaterialPresets,
        (suitableMaterials, materialPresets) => {
            if (!currentMachineId) {
                return undefined;
            }
            const suitableMaterialPresets = [] as MaterialPreset[];
            const ids = suitableMaterials[currentMachineId] || [];
            for (const id of ids) {
                const foundPreset = materialPresets.find(material => material.id === id);
                if (foundPreset) {
                    suitableMaterialPresets.push(foundPreset);
                }
            }
            return suitableMaterialPresets;
        },
    );
