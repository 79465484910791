import { SystemProps } from '@chakra-ui/system';
import { FieldStyles } from '@shared/components/forms-v2';

export const POPOVER_FIELD_STYLES: FieldStyles = {
    // TODO: move to semanticTokens
    label: {
        fontSize: 'xs',
        mb: '1',
    },
    helpText: {
        fontSize: 'xs',
        mt: '1',
    },
    inputGroup: { size: 'sm' },
    input: { size: 'sm' },
    select: { size: 'sm' },
    radio: { flexDirection: 'column', alignItems: 'flex-start', gap: 2 },
    error: { size: 'xs' },
};

// TODO remove? discuss with designer
export const UNTITLED_POPOVER_FIELD_STYLES = {
    ...POPOVER_FIELD_STYLES,
    label: {
        fontSize: 'xs',
        fontWeight: '700',
        mb: '2',
    },
};

// TODO remove? discuss with designer
export const TITLED_POPOVER_FORM_STYLES: {
    rowGap: SystemProps['margin'];
} = {
    rowGap: 2,
};
