import { createPersistReducer } from '@ducks/helpers';
import { createReactTableSlice, getReactTableSelectors } from '@ducks/react-table-slice';

export const organizationsTableSlice = createReactTableSlice({
    namespace: 'organizations',
    initialState: {
        rowSelection: {},
    },
    reducers: {},
});

export const {
    setColumnsConfig: setOrganizationsColumnsConfig,
    setRowSelection: setSelectedOrganizations,
} = organizationsTableSlice.actions;

export const {
    selectColumnsConfig: selectOrganizationsColumnsConfig,
    selectRowSelection: selectSelectedOrganizations,
    selectColumnsOrder: selectOrganizationsColumnsOrder,
    selectColumnsVisibility: selectOrganizationsColumnsVisibility,
} = getReactTableSelectors(organizationsTableSlice, state => state.organizations.table);

export const tableOrganizationsReducer = createPersistReducer(organizationsTableSlice, {
    whitelist: ['columnsConfig'],
});
