import isString from 'lodash/isString';
import { ReactNode } from 'react';
import { Box, BoxProps, Heading, Text } from '@chakra-ui/react';

interface Props extends BoxProps {
    header: string | ReactNode;
    description?: string | ReactNode;
    children?: ReactNode;
}

export const SectionLineItemDetails = ({ header, description, children, ...rest }: Props) => {
    const _header = isString(header) ? (
        <Heading as="h5" size="sm">
            {header}
        </Heading>
    ) : (
        header
    );
    const _description = isString(description) ? (
        // maxW="475px"
        <Text textStyle="typography-sm" color="gray.600" mt={2}>
            {description}
        </Text>
    ) : (
        description
    );

    return (
        <Box {...rest}>
            {_header}
            {_description}
            {children}
        </Box>
    );
};
