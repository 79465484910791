import * as yup from 'yup';
import { useMemo } from 'react';
import { BoxProps, GridProps } from '@chakra-ui/react';
import { SectionModalForm, SectionModalFormProps } from '@shared/components/section';
import { UntitledBox } from '@shared/components/common-boxes';
import { EditButton } from '@shared/components/common-buttons';
import {
    FieldHandler,
    defineField,
    FieldType,
    getSingleFieldConfig,
} from '@shared/components/forms-v2';
import { NOTES_CKEDITOR_CONFIG } from '@shared/constants/ckeditor';
import { CKEditorView } from './ckeditor-view';

const defineNotesField = (name: string) =>
    defineField({
        type: FieldType.WYSIWYG,
        name,
        scheme: yup.string().ensure().trim(),
        props: { config: NOTES_CKEDITOR_CONFIG },
        // props: { config: NOTES_CKEDITOR_CONFIG, formControlProps: { maxW: '100%' } },
    });

interface CKEditorNotesViewProps extends BoxProps {
    notes?: string;
}

export const CKEditorNotesView = ({ notes, ...rest }: CKEditorNotesViewProps) => (
    <CKEditorView
        config="notes"
        data={notes || '<p>No notes yet...</p>'}
        color="font.secondary"
        {...rest}
    />
);

interface CKEditorNotesTileProps extends GridProps {
    notes: string;
    short?: boolean;
}

export const CKEditorNotesTile = ({
    notes,
    short = false,
    children,
    ...rest
}: CKEditorNotesTileProps) => (
    <UntitledBox action={children} p="3" {...rest}>
        <CKEditorView config="notes" data={notes} overflow="clip" maxH={short ? '44' : undefined} />
    </UntitledBox>
);

interface CKEditorNotesModalFormProps
    extends Omit<SectionModalFormProps, 'data' | 'trigger' | 'schema'>,
        Omit<BoxProps, 'onError'> {
    name: string;
    notes?: string;
    trigger?: SectionModalFormProps['trigger'];
}

export const CKEditorNotesModalForm = ({
    name,
    notes = '',
    ...rest
}: CKEditorNotesModalFormProps) => {
    const { data, field, schema } = useMemo(
        () => getSingleFieldConfig(defineNotesField(name), notes),
        [name, notes],
    );

    return (
        <SectionModalForm
            trigger={({ onOpen, triggerId }) => <EditButton id={triggerId()} onClick={onOpen} />}
            trapFocus={false}
            {...rest}
            data={data}
            schema={schema}
            hasHeaderDivider={false}
        >
            <FieldHandler field={field} />
        </SectionModalForm>
    );
};
