import { Link, LinkProps } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/react';
import { useActiveRoute } from '@shared/utils/routes';

export interface ButtonLinkProps extends Pick<LinkProps, 'to' | 'state'>, ButtonProps {}

export const ButtonLink = ({ to, children, ...rest }: ButtonLinkProps) => {
    const { isActive } = useActiveRoute({ to });

    return (
        <Button as={Link} to={to} isActive={isActive} width="fit-content" {...rest}>
            {children}
        </Button>
    );
};
