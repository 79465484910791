import { combineEpics } from 'redux-observable';
import { appEpics } from '@ducks/app';
import { priceMatchResultEpics } from '@ducks/price-match-result';
import { uploadModelsEpics } from '@ducks/models-upload/epics';
import { materialCreateEpics } from '@ducks/material-create';
import { materialEditEpics } from '@ducks/material-edit';

export const rootEpic = combineEpics(
    appEpics,
    priceMatchResultEpics,
    uploadModelsEpics,
    materialEditEpics,
    materialCreateEpics,
);
