// https://github.com/FionnCasey/react-hook-form-generator/blob/master/src/hooks/useStyles.ts
import { useMemo, createContext, useContext } from 'react';
import { FieldStyles } from '../types';

export const FieldStylesContext = createContext<FieldStyles>({});

export const useFieldStyles = <T extends keyof FieldStyles, P extends FieldStyles[T]>(
    type: T,
    props?: P,
) => {
    const configStyles = useContext(FieldStylesContext);

    // node_modules/@chakra-ui/system/src/use-style-config.ts
    // omit 'value', 'onChange' and other props that we pass in 'Field' component, remove render props in 'Field'
    // return useMemo(() => {
    //     // @ts-ignore
    //     console.log('props:', props?.value);
    //     return props ? { ...configStyles[type], ...props } : configStyles[type];
    // }, [type, configStyles, props]);

    return props ? { ...configStyles[type], ...props } : configStyles[type];
};
