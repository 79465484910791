import { Icon, IconProps } from '@chakra-ui/react';

export const PlusIcon = (props: IconProps) => (
    <Icon
        fill="none"
        viewBox="0 0 10 10"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        {...props}
    >
        <path d="M5 1V9M1 5H9" />
    </Icon>
);
