import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import {
    Flex,
    useDisclosure,
    Modal,
    ModalProps,
    ModalOverlay,
    ModalBody,
    ModalContent,
    ModalCloseButton,
    Button,
    ButtonGroup,
} from '@chakra-ui/react';
import { useAppDispatch } from '@app/hooks';
import { triggerToast } from '@ducks/app';
import { resetCurrentModel } from '@ducks/models';
import { FetchBaseQueryError } from '@services/df/baseApi';
import { useUsersModelsDestroyMutation } from '@services/df/models';
import { Purchase } from '@services/df/orders';
import { AlertDialog } from '@shared/components/alert-dialog';
import { TrashNewIcon } from '@shared/components/icons';
import { PreloaderBox } from '@shared/components/preloader';
import { Transport, useTransports } from '@shared/components/transport';
import { FullscreenOn } from '@shared/components/tooltipped-icon-button';
import { ROUTES } from '@shared/constants';
import { getNodeIdComposer } from '@shared/utils/strings';
import { ModelViewer, ModelViewerFullScreenModal } from '../model-viewer';
import { getOrderPurchaseModelData } from './helpers';
import { ModelDetails } from './model-details';
import {
    VIEWER_CONTAINER_STYLES,
    MODAL_CONTENT_STYLES,
    MODAL_BODY_STYLES,
    DETAILS_CONTAINER_STYLES,
    MODAL_CONTAINER_STYLES,
} from './constants';

interface ModelDetailsPopupProps extends Omit<ModalProps, 'children'> {
    model: Purchase['product'];
    materialId?: number | null;
    rootId: string;
    isAbleToDeleteModel?: boolean;
    isAbleToShowRelatedModels?: boolean;
    isFetching?: boolean;
    refetch?: () => void;
}

export const ModelDetailsPopup = ({
    isOpen,
    onClose,
    model,
    materialId,
    rootId,
    isAbleToDeleteModel = false,
    isAbleToShowRelatedModels = false,
    isFetching = false,
    refetch,
}: ModelDetailsPopupProps) => {
    const _dialogId = getNodeIdComposer(rootId, 'delete_model_dialog');
    const _viewerId = getNodeIdComposer(rootId, 'viewer');
    const plugId = _viewerId('plug');
    const windowId = _viewerId('window');

    const dispatch = useAppDispatch();
    const { swapNode: _swapNode } = useTransports();

    const {
        isOpen: isOpenFullScreen,
        onOpen: onOpenFullScreen,
        onClose: onCloseFullScreen,
    } = useDisclosure();

    const {
        isOpen: isOpenDeleteDialog,
        onOpen: onOpenDeleteDialog,
        onClose: onCloseDeleteDialog,
    } = useDisclosure();

    const swapNode = () => {
        _swapNode(windowId, plugId);
        window.dispatchEvent(new Event('resize'));
    };

    const handleOpenFullScreen = () => {
        onOpenFullScreen();

        setTimeout(() => {
            swapNode();
        }, 0);
    };

    const handleCloseFullScreen = () => {
        swapNode();
        onCloseFullScreen();
    };

    const { id } = model;
    const { title } = getOrderPurchaseModelData(model);
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const backPath = `${ROUTES.MODELS_MAIN}?${searchParams}`;

    const [deleteRemoteModel, { isLoading }] = useUsersModelsDestroyMutation();

    const handleDelete = async () => {
        const response = await deleteRemoteModel({ id: id.toString() });
        const { data } = response as {
            data: unknown;
        };
        if (data || data === '') {
            dispatch(triggerToast({ title: `Model ${title} was successfully deleted` }));
            if (refetch) {
                refetch();
            }
            navigate(backPath);
        } else {
            const { error } = response as {
                error: FetchBaseQueryError;
            };
            const errorDetails = error.data || 'Unknown error';
            dispatch(triggerToast({ error: errorDetails }));
        }
        dispatch(resetCurrentModel());
        onCloseDeleteDialog();
    };

    const deleteButton = isAbleToDeleteModel ? (
        <Button
            id={_dialogId('trigger')}
            leftIcon={<TrashNewIcon color="error.100" />}
            variant="link"
            aria-label="delete-model"
            colorScheme="error"
            textColor="text.100"
            onClick={onOpenDeleteDialog}
            isLoading={isLoading}
            loadingText="Deleting..."
        >
            Delete model
        </Button>
    ) : null;

    return (
        <Modal
            variant="alert"
            isOpen={isOpen}
            onClose={onClose}
            isCentered
            size="xxl"
            lockFocusAcrossFrames={false}
            closeOnEsc={!isOpenFullScreen}
        >
            <AlertDialog
                alertId={_dialogId()}
                header={`Delete ${title}?`}
                body={`Are you sure you want to irretrievably delete this model?`}
                cancelButtonText="Cancel"
                confirmButtonText="Delete"
                onCancel={onCloseDeleteDialog}
                onConfirm={handleDelete}
                isOpen={isOpenDeleteDialog}
            />
            <ModalOverlay>
                <ModalContent {...MODAL_CONTENT_STYLES}>
                    <ModelViewerFullScreenModal
                        isOpen={isOpenFullScreen}
                        onClose={handleCloseFullScreen}
                    >
                        <Transport id={plugId} />
                    </ModelViewerFullScreenModal>
                    <ModalBody {...MODAL_BODY_STYLES}>
                        <Flex {...MODAL_CONTAINER_STYLES}>
                            <Flex position="relative" {...VIEWER_CONTAINER_STYLES}>
                                <FullscreenOn
                                    position="absolute"
                                    bottom="10px"
                                    right="12px"
                                    tooltipPlacement="left"
                                    onClick={handleOpenFullScreen}
                                />
                                <Transport id={windowId}>
                                    <ModelViewer model={model} />
                                </Transport>
                            </Flex>
                            <PreloaderBox in={isFetching}>
                                <Flex flexDir="column" {...DETAILS_CONTAINER_STYLES}>
                                    <ModelDetails
                                        model={model}
                                        isAbleToShowRelatedModels={isAbleToShowRelatedModels}
                                    />
                                    <ButtonGroup flexDir={{ base: 'column', sm: 'row' }} rowGap={4}>
                                        <Button
                                            as="a"
                                            variant="outline"
                                            w={{ base: '100%', md: '200px' }}
                                            href={`/manage/materials/price-tweaker;modelId=${id}${
                                                materialId ? ';materialId=' + materialId : ''
                                            }`}
                                        >
                                            Get price breakdown
                                        </Button>
                                        {deleteButton}
                                    </ButtonGroup>
                                </Flex>
                            </PreloaderBox>
                        </Flex>
                    </ModalBody>
                    <ModalCloseButton
                        top={{
                            base: `calc(${VIEWER_CONTAINER_STYLES.minH} + ${MODAL_CONTAINER_STYLES.gap} + 15px)`,
                            md: '15px',
                        }}
                        right="15px"
                    />
                </ModalContent>
            </ModalOverlay>
        </Modal>
    );
};
