import { Icon, IconProps } from '@chakra-ui/react';

export const PercentIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M13.0013 3.66675L3.66797 13.0001M6.33333 4.66667C6.33333 5.58714 5.58714 6.33333 4.66667 6.33333C3.74619 6.33333 3 5.58714 3 4.66667C3 3.74619 3.74619 3 4.66667 3C5.58714 3 6.33333 3.74619 6.33333 4.66667ZM13.6673 12C13.6673 12.9205 12.9211 13.6667 12.0007 13.6667C11.0802 13.6667 10.334 12.9205 10.334 12C10.334 11.0796 11.0802 10.3334 12.0007 10.3334C12.9211 10.3334 13.6673 11.0796 13.6673 12Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
