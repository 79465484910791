import { tagAnatomy as parts } from '@chakra-ui/anatomy';
import { PartsStyleObject, StyleFunctionProps, SystemStyleObject } from '@chakra-ui/theme-tools';
import { BadgeStyles } from './badge-styles';

const sizes: Record<string, PartsStyleObject<typeof parts>> = {
    sm: {
        container: {
            borderRadius: '10',
            // minH: 6,
        },
    },
    md: {
        container: {
            borderRadius: '12',
            minH: 6,
        },
    },
    lg: {
        container: {
            borderRadius: '12',
        },
    },
};

const baseStyleCloseButton: SystemStyleObject = {
    fontSize: 'md',
    w: 3,
    h: 3,
    opacity: 1,
    marginEnd: 0,
};

export const TagStyles = {
    baseStyle: {
        container: {
            fontWeight: 'regular',
        },
        label: {
            lineHeight: 'none',
        },
        closeButton: baseStyleCloseButton,
    },
    sizes,
    variants: {
        subtle: (props: StyleFunctionProps) => ({
            container: BadgeStyles.variants.subtle(props),
        }),
        solid: ({ colorScheme }: any) => {
            return {
                container: {
                    color: 'font.100',
                    bgColor: `${colorScheme}.100`,
                    borderColor: `${colorScheme}.200`,
                    borderWidth: '2px',
                },
            };
        },
    },
};
