import { BaseSyntheticEvent } from 'react';
import { FieldValues, SubmitHandler, FormState, UseFormSetError, FieldPath } from 'react-hook-form';
import { FetchBaseQueryError } from '@services/df/baseApi';
import { setApiErrors } from '@shared/components/forms-v2';
import { UseFormPopupInitProps, useFormPopupInit } from './use-form-popup-init';
import { useFormApiErrors } from './use-form-api-errors';
import get from 'lodash/get';

export type UpdateFormSubmit<TFieldValues extends FieldValues = FieldValues> = (
    data: TFieldValues,
    dirtyFields: FormState<TFieldValues>['dirtyFields'],
    event?: BaseSyntheticEvent,
) => unknown | Promise<unknown>;

export type UpdateFormError<TFieldValues extends FieldValues = FieldValues> = (
    setError: UseFormSetError<TFieldValues>,
    errors: FetchBaseQueryError['data'],
) => void;

export interface UseUpdateFormPopupProps<TFieldValues extends FieldValues = FieldValues>
    extends UseFormPopupInitProps<TFieldValues> {
    submit: UpdateFormSubmit<TFieldValues>;
    onError?: UpdateFormError<TFieldValues>;
    apiErrorsToastEnabled?: boolean;
    apiErrorsFields?: Array<FieldPath<TFieldValues>>;
    apiErrorsMap?: Record<string, FieldPath<TFieldValues>>;
}

export const useUpdateFormPopup = <TFieldValues extends FieldValues>({
    data,
    schema,
    apiErrorsToastEnabled = true,
    apiErrorsFields,
    apiErrorsMap,
    submit: _submit,
    onError,
    ...rest
}: UseUpdateFormPopupProps<TFieldValues>) => {
    const formApi = useFormPopupInit<TFieldValues>({
        data,
        schema,
        ...rest,
    });
    const {
        setError,
        handleSubmit,
        onClose,
        formState: { dirtyFields },
    } = formApi;

    useFormApiErrors({ formApi, enabled: apiErrorsToastEnabled, fields: apiErrorsFields });

    const submit: SubmitHandler<TFieldValues> = async (data, event) => {
        try {
            await _submit(data, dirtyFields, event);
            onClose();
        } catch (error) {
            const _errors = (error as FetchBaseQueryError)?.data;
            const errors = apiErrorsMap
                ? Object.entries(apiErrorsMap).reduce(
                      (acc, [name, path]) => ({ ...acc, [name]: get(_errors, path) }),
                      {},
                  )
                : _errors;
            if (onError) {
                onError(setError, errors);
            } else {
                setApiErrors(setError, errors);
            }
        }
    };

    return {
        handleFormSubmit: handleSubmit(submit),
        ...formApi,
    };
};
