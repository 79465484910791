import React from 'react';
import { useMatches } from 'react-router-dom';
import {
    Button,
    ButtonProps,
    Collapse,
    Divider,
    List,
    ListProps,
    ListItem,
    ListItemProps,
    useDisclosure,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { useAppSelector } from '@app/hooks';
import { selectSettings, useNnwUrl } from '@ducks/app';
import { selectUserPermissions } from '@entities';
import { getBackendUrl } from '@services/instance';
import { ButtonLink } from '@shared/components/button-link';
import {
    BarMatchingIcon,
    BoxIcon,
    BookIcon,
    CartIcon,
    ClientsIcon,
    CreditCardIcon,
    CodeIcon,
    DropletIcon,
    LifeRingIcon,
    PercentIcon,
    SettingsIcon,
    TableListIcon,
    UsersIcon,
} from '@shared/components/icons';
import { IntercomUtils } from '@shared/utils';
import { isAbsoluteUrl } from '@shared/utils/routes';
import { ROUTES } from '@shared/constants';
import { NavItem, isButton, isDivider, isParent, isRoute, OnItemSelect } from './types';

const useNavItems = () => {
    const { userIsAdmin, userIsAdminOrSalesman, userIsAdminOrVendor, userIsManager } =
        useAppSelector(selectUserPermissions);
    const { is_blocked } = useAppSelector(selectSettings) || {};
    const { analytics_dashboard_url } = useAppSelector(selectSettings)!;
    // const { stripe_customer_id } = useAppSelector(selectSettings)!;
    const iqtUrl = useNnwUrl('iqt_upload_url');

    const navItems = [
        userIsManager && {
            children: 'Orders',
            leftAddon: <TableListIcon />,
            items: [
                {
                    href: ROUTES.ORDERS_MAIN,
                    children: 'Orders',
                },
                {
                    href: ROUTES.ORDERS_LINE_ITEMS,
                    children: 'Parts',
                },
                {
                    href: ROUTES.ORDERS_DRAFTS,
                    children: 'Drafts',
                },
                {
                    href: ROUTES.ORDERS_CARTS,
                    children: 'Carts',
                },
            ],
        },
        userIsAdminOrSalesman && {
            href: ROUTES.MODELS_MAIN,
            children: 'Models',
            leftAddon: <BoxIcon />,
        },
        userIsAdminOrSalesman && {
            href: ROUTES.CLIENTS_MAIN,
            children: 'Clients',
            leftAddon: <ClientsIcon />,
        },
        userIsAdminOrVendor && {
            href: ROUTES.MACHINES_MATERIALS,
            children: 'Machines & Materials',
            leftAddon: <DropletIcon />,
        },
        userIsAdminOrSalesman && {
            href: ROUTES.DISCOUNTS,
            children: 'Discounts',
            leftAddon: <PercentIcon />,
        },
        userIsAdminOrSalesman && {
            href: ROUTES.INSTALL_WIDGET,
            children: 'Install Widget',
            leftAddon: <CodeIcon />,
        },
        userIsAdminOrSalesman && {
            href: is_blocked ? ROUTES.BILLING_SETTINGS : iqtUrl,
            children: 'Internal Quotation Tool',
            leftAddon: <CartIcon />,
        },
        userIsAdmin && {
            href: ROUTES.USERS_ROLES_MAIN,
            children: 'Users & Roles',
            leftAddon: <UsersIcon />,
        },
        userIsAdmin && {
            href: ROUTES.BILLING_SETTINGS,
            children: 'Billing',
            leftAddon: <CreditCardIcon />,
        },
        userIsManager &&
            analytics_dashboard_url && {
                href: ROUTES.DASHBOARD,
                children: 'Sales dashboard',
                leftAddon: <BarMatchingIcon />,
            },
        // {
        //     // TODO check stripe_customer_id
        //     href: getBackendUrl('/manage/stripe_customer_portal/'),
        //     children: 'Stripe customer portal',
        //     leftAddon: <CreditCardIcon />,
        // },
        {
            href: 'https://help.digifabster.com/',
            children: 'Knowledge base',
            leftAddon: <BookIcon />,
            mb: 'auto',
        },
        { divider: true },
        // { subheader: true, children: 'References' },
        userIsAdmin && {
            children: 'Settings',
            leftAddon: <SettingsIcon />,
            items: [
                {
                    href: ROUTES.COMPANY_SETTINGS,
                    children: 'Company Settings',
                },
                {
                    href: ROUTES.WIDGET_SETTINGS,
                    children: 'Widget Settings',
                },
            ],
        },
        {
            onClick: () => IntercomUtils.open(),
            children: 'Help',
            leftAddon: <LifeRingIcon />,
            mb: 'auto',
        },
    ].filter(Boolean);

    return navItems as readonly NavItem[];
};

const BUTTON_PROPS = {
    variant: 'link',
    colorScheme: 'secondary',
    p: 2,
    py: 2,
    width: 'full',
    justifyContent: 'flex-start',
    // todo iconSpacing problem
    // iconSpacing: 2,
    css: {
        '.chakra-button__icon:first-of-type': {
            marginInlineEnd: '8px',
        },
    },
    _hover: { bgGradient: 'linear(to-r, white, transparent)' },
    _active: { bgGradient: 'linear(to-r, white, transparent)', color: 'primary.default' },
};

const CHILD_BUTTON_PROPS = {
    '& .chakra-button': {
        pl: '30px',
        // pl: 8,
        py: 1,
    },
};

const SideNavigationButton = forwardRef<ButtonProps, 'button'>(({ children, ...props }, ref) => {
    return (
        <Button ref={ref} {...BUTTON_PROPS} {...props}>
            <span>{children}</span>
        </Button>
    );
});

interface SideNavigationItemProps extends ListItemProps {
    item: NavItem;
    onItemSelect?: OnItemSelect;
}

const SideNavigationItem = forwardRef<SideNavigationItemProps, 'li'>(
    ({ item, onItemSelect, children: _children, ...rest }, ref) => {
        const matches = useMatches();
        const { isOpen, onToggle } = useDisclosure({
            defaultIsOpen: isParent(item)
                ? !!matches.find(
                      match =>
                          !!item.items.find(item =>
                              isRoute(item) ? item.href === match.pathname : undefined,
                          ),
                  )
                : false,
        });

        if (isDivider(item)) {
            return <Divider borderColor="neutral.subtle" my={4} />;
        }

        if (isParent(item)) {
            const { children, leftAddon, items, ...styles } = item;
            return (
                <ListItem ref={ref} {...styles} {...rest}>
                    <SideNavigationButton
                        leftIcon={leftAddon}
                        onClick={() => {
                            onToggle();
                            onItemSelect?.(item);
                        }}
                    >
                        {children}
                    </SideNavigationButton>
                    <Collapse in={isOpen} animateOpacity>
                        <List spacing={1} sx={CHILD_BUTTON_PROPS}>
                            {items.map((item, index) => (
                                <SideNavigationItem
                                    key={index}
                                    item={item}
                                    onItemSelect={onItemSelect}
                                />
                            ))}
                        </List>
                    </Collapse>
                </ListItem>
            );
        }

        const { children, leftAddon, ...styles } = item;

        const value = isButton(item) ? (
            <SideNavigationButton
                leftIcon={leftAddon}
                onClick={() => {
                    item?.onClick();
                    onItemSelect?.(item);
                }}
            >
                {children}
            </SideNavigationButton>
        ) : isAbsoluteUrl(item.href) ? (
            <SideNavigationButton
                as="a"
                target="_blank"
                rel="noopener"
                leftIcon={leftAddon}
                href={item.href}
                onClick={() => {
                    onItemSelect?.(item);
                }}
            >
                {children}
            </SideNavigationButton>
        ) : (
            <ButtonLink
                {...BUTTON_PROPS}
                leftIcon={leftAddon}
                to={item.href}
                onClick={() => {
                    onItemSelect?.(item);
                }}
            >
                <span>{children}</span>
            </ButtonLink>
        );

        return (
            <ListItem ref={ref} {...styles} {...rest}>
                {value}
                {_children}
            </ListItem>
        );
    },
);

interface SideNavigationItemsProps extends ListProps {
    onItemSelect?: OnItemSelect;
}

export const SideNavigationItems = forwardRef<SideNavigationItemsProps, 'ul'>(
    ({ children, onItemSelect, ...rest }, ref) => {
        const navItems = useNavItems();
        return (
            <List ref={ref} spacing={2} h="full" {...rest}>
                {navItems.map((item, index) => (
                    <SideNavigationItem key={index} item={item} onItemSelect={onItemSelect} />
                ))}
                {children}
            </List>
        );
    },
);
