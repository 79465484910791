import { countDecimalPlaces, StringOrNumber, toPrecision } from '@chakra-ui/utils';

function parse(value: StringOrNumber) {
    return parseFloat(value.toString().replace(/[^\w.-]+/g, ''));
}

export function getDecimalPlaces(value: number, step = 1) {
    return Math.max(countDecimalPlaces(step), countDecimalPlaces(value));
}

export function castNumber(value: StringOrNumber, precision?: number, step = 1) {
    const parsedValue = parse(value);
    if (Number.isNaN(parsedValue)) return null;
    const decimalPlaces = getDecimalPlaces(parsedValue, step);
    return toPrecision(parsedValue, precision ?? decimalPlaces);
}

// thanks to indian geniuses who can't export main function from shitty module
// https://github.com/chakra-ui/chakra-ui/blob/main/packages/components/counter/src/use-counter.ts#L204
