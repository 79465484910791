import * as Sentry from '@sentry/react';
import type { Breadcrumb, ErrorEvent, EventHint } from '@sentry/types';
// import type { BrowserOptions } from '@sentry/browser';
import React from 'react';
import {
    createRoutesFromChildren,
    matchRoutes,
    useLocation,
    useNavigationType,
} from 'react-router-dom';

// const client = new BrowserClient({
//     beforeSend: (error, hint) => {
//         return shouldIgnoreError(error, hint) ? null : error;
//     },
// });

/**
 * Sentry util class
 * Based on:
 * 1) https://github.com/getsentry/sentry-javascript/issues/7275
 * 2) https://gist.github.com/jeengbe/4bc86f05a41a1831e6abf2369579cc7a
 * @internal
 */
export class SentryUtils {
    static setup() {
        // Init the sentry conditionally.
        // https://docs.sentry.io/platforms/javascript/configuration/options/#enabled
        const isSentryRunnable = [
            process.env.NODE_ENV === 'production',
            process.env.REACT_APP_SENTRY_DSN,
            process.env.REACT_APP_SENTRY_ENVIRONMENT,
            process.env.REACT_APP_RELEASE_VERSION,
        ].every(Boolean);

        if (isSentryRunnable) {
            Sentry.init({
                // dsn: 'https://139606bfd51540d7a2b947ce4361431e@o350186.ingest.sentry.io/4504163515891712',
                dsn: process.env.REACT_APP_SENTRY_DSN,
                environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
                release: process.env.REACT_APP_RELEASE_VERSION,
                integrations: [
                    new Sentry.BrowserTracing({
                        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
                            React.useEffect,
                            useLocation,
                            useNavigationType,
                            createRoutesFromChildren,
                            matchRoutes,
                        ),
                    }),
                ],

                // debug: true,
                // enabled: false,
                // initialScope: {
                // contexts: {
                //     app: {
                //         mode: AppSession.mode,
                //     },
                // },
                // tags: { 'my-tag': 'my value' },
                // user: {
                //     id: 42, // persist and get from redux?
                // },
                // },
                normalizeDepth: 7,

                // // Set tracesSampleRate to 1.0 to capture 100%
                // // of transactions for performance monitoring.
                // // We recommend adjusting this value in production
                // tracesSampleRate: 1.0,

                beforeSend: (error, hint) => {
                    // https://docs.sentry.io/platforms/javascript/data-management/sensitive-data/
                    if (error.user) {
                        // Don't send user's email address
                        delete error.user.email;
                    }

                    return SentryUtils.shouldIgnoreError(error, hint) ? null : error;
                },

                // https://docs.sentry.io/platforms/javascript/configuration/filtering/#decluttering-sentry
                // https://gist.github.com/Chocksy/e9b2cdd4afc2aadc7989762c4b8b495a
                ignoreErrors: [
                    // Random plugins/extensions
                    'top.GLOBALS',
                    // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
                    'originalCreateNotification',
                    'canvas.contentDocument',
                    'MyApp_RemoveAllHighlights',
                    'http://tt.epicplay.com',
                    "Can't find variable: ZiteReader",
                    'jigsaw is not defined',
                    'ComboSearch is not defined',
                    'http://loading.retry.widdit.com/',
                    'atomicFindClose',
                    // Facebook borked
                    'fb_xd_fragment',
                    // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to
                    // reduce this. (thanks @acdha)
                    // See http://stackoverflow.com/questions/4113268
                    'bmi_SafeAddOnload',
                    'EBCallBackMessageReceived',
                    // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
                    'conduitPage',
                    // Generic error code from errors outside the security sandbox
                    // You can delete this if using raven.js > 1.0, which ignores these automatically.
                    'Script error.',
                    // Avast extension error
                    '_avast_submit',
                    // safari webkit
                    /.*@webkit-masked-url.*/,
                ],
                denyUrls: [
                    // Google Adsense
                    /pagead\/js/i,
                    // Facebook flakiness
                    /graph\.facebook\.com/i,
                    // Facebook blocked
                    /connect\.facebook\.net\/en_US\/all\.js/i,
                    // Woopra flakiness
                    /eatdifferent\.com\.woopra-ns\.com/i,
                    /static\.woopra\.com\/js\/woopra\.js/i,
                    // Chrome extensions
                    /extensions\//i,
                    /^chrome:\/\//i,
                    /^chrome-extension:\/\//i,
                    // Other plugins
                    /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
                    /webappstoolbarba\.texthelp\.com\//i,
                    /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
                ],
            });
        }
    }

    static shouldIgnoreError(error: ErrorEvent, hint: EventHint): boolean {
        return (
            SentryUtils.isRecaptchaBadTimeoutRejection(error, hint) ||
            SentryUtils.isNetworkError(error)
        );
    }

    // https://github.com/getsentry/sentry-javascript/issues/2514
    static isRecaptchaBadTimeoutRejection(_: ErrorEvent, hint: EventHint): boolean {
        return hint.originalException === 'Timeout';
    }

    // https://stackoverflow.com/questions/55738408/javascript-typeerror-cancelled-error-when-calling-fetch-on-ios
    // https://stackoverflow.com/questions/71280168/javascript-typeerror-load-failed-error-when-calling-fetch-on-ios
    // ignore iOS errors
    // https://digifabster.sentry.io/issues/3876412062/events/?project=4504163515891712&referrer=project-issue-stream
    // ignore disconnection errors
    // https://digifabster.sentry.io/issues/3870303356/events/?project=4504163515891712&referrer=release-issue-stream
    static typeErrorFetchFailedValues = new Set([
        'Failed to fetch',
        'NetworkError when attempting to fetch resource.',
        'Load failed',
        'network error',
        'cancelled',
    ]);

    static isNetworkError(error: ErrorEvent): boolean {
        const exception = error.exception?.values?.[0];
        const now = Date.now();

        if (
            exception?.type !== 'TypeError' ||
            !SentryUtils.typeErrorFetchFailedValues.has(exception.value as string)
        ) {
            return false;
        }

        return true;
        // if you want to check a specific URL, uncomment the code below and fill in isErroneousBreadcrumb with urls
        // if (!error.breadcrumbs) {
        //     return false;
        // }
        //
        // // We go from the back since the last breadcrumb is most likely the erroneous one
        // for (let i = error.breadcrumbs.length - 1; i >= 0; i--) {
        //     const breadcrumb = error.breadcrumbs[i];
        //     if (!breadcrumb) continue;
        //
        //     // We only need to check the last 5s of breadcrumbs as any earlier breadcrumbs are definitely unrelated
        //     if (breadcrumb.timestamp && now - breadcrumb.timestamp * 1000 > 5000) {
        //         break;
        //     }
        //
        //     if (isErroneousBreadcrumb(breadcrumb)) {
        //         return true;
        //     }
        // }
        //
        // return false;
    }

    // static isErroneousBreadcrumb(breadcrumb: Breadcrumb): boolean {
    //     if (breadcrumb.level !== 'error' || (breadcrumb.category !== 'xhr' && breadcrumb.category !== 'fetch')) {
    //         return false;
    //     }
    //
    //     const url = breadcrumb.data?.url as string | undefined;
    //     if (!url) return false;
    //
    //     return url === 'urlThatIsOftenBlocked' || url.startsWith('startOfUrlThatIsOftenBlocked');
    // }
}
