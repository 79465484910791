import AutosizeTextarea from 'react-autosize-textarea';
import { Textarea as ChakraTextarea, TextareaProps as ChakraTextareaProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { useFieldStyles } from '../hooks';

export type TextareaProps = ChakraTextareaProps;

export const Textarea = forwardRef<TextareaProps, 'textarea'>((_props, ref) => {
    const props = useFieldStyles('textarea', _props);
    return (
        <ChakraTextarea
            ref={ref}
            minH="unset"
            overflow="hidden"
            w="100%"
            resize="none"
            // transition="height none" // https://github.com/chakra-ui/chakra-ui/issues/670#issuecomment-711469284
            rows={5}
            maxRows={20}
            as={AutosizeTextarea}
            {...props}
        />
    );
});
