import React from 'react';
import { QueryParams } from 'use-query-params';
import {
    Popover,
    PopoverBody,
    PopoverTrigger,
    RadioGroup,
    Radio,
    Button,
    HStack,
    VStack,
    StackProps,
    Text,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { callAllHandlers } from '@chakra-ui/utils';
import { ChevronDownIcon } from '@shared/components/icons';
import { FormPopoverContent } from '@shared/components/common-popovers';
import {
    CurrentPageParam,
    PAGINATION_CONFIG,
    PaginationQueryParams,
    PerPageLimitParam,
} from './constants';

interface ReactTableLimitPopoverProps {
    trigger: (props: { limit: number }) => React.ReactNode;
    value?: number;
    defaultValue?: number;
    limits?: readonly number[];
    onChange?: (limit: number) => void;
}

const PaginationLimitPerPagePopover = ({
    trigger,
    defaultValue,
    value,
    onChange,
    limits = PAGINATION_CONFIG.perPageLimits,
}: ReactTableLimitPopoverProps) => {
    const [_limit, setLimit] = React.useState(defaultValue ?? limits[0]);
    const limit = value ?? _limit;

    const handleLimitChange = (_limit: string) => {
        const limit = Number(_limit);
        setLimit(limit);
        onChange?.(limit);
    };

    return (
        <Popover placement="bottom-start" isLazy>
            <PopoverTrigger>{trigger({ limit })}</PopoverTrigger>
            <FormPopoverContent>
                <PopoverBody>
                    <RadioGroup value={limit} onChange={handleLimitChange}>
                        <VStack align="flex-start">
                            {limits.map(value => (
                                <Radio key={value} value={value}>
                                    {value}
                                </Radio>
                            ))}
                        </VStack>
                    </RadioGroup>
                </PopoverBody>
            </FormPopoverContent>
        </Popover>
    );
};

interface PaginationLimitPerPageProps extends Omit<ReactTableLimitPopoverProps, 'trigger'> {
    total: number;
}

export const PaginationLimitPerPage = forwardRef<
    PaginationLimitPerPageProps & Omit<StackProps, 'onChange'>,
    'div'
>(({ total, defaultValue, value, limits, onChange, ...rest }, ref) => {
    return (
        <HStack ref={ref} {...rest}>
            <Text textStyle="typography-sm">Showing</Text>
            <PaginationLimitPerPagePopover
                trigger={({ limit }) => (
                    <Button
                        display="inline-flex"
                        bgColor="gray.200"
                        variant="unstyled"
                        p="5px"
                        h="19px"
                        minW="35px"
                        borderRadius="4px"
                        iconSpacing={0.5}
                        rightIcon={<ChevronDownIcon w="3px" h="6px" />}
                    >
                        <Text textStyle="typography-sm" fontWeight="500">
                            {limit}
                        </Text>
                    </Button>
                )}
                defaultValue={defaultValue}
                value={value}
                limits={limits}
                onChange={onChange}
            />
            <Text textStyle="typography-sm">of {total} results</Text>
        </HStack>
    );
});

export const PaginationLimitPerPageWithQuery = ({
    onChange,
    ...rest
}: PaginationLimitPerPageProps) => (
    <QueryParams
        config={{
            [PaginationQueryParams.Limit]: PerPageLimitParam,
            [PaginationQueryParams.PageNumber]: CurrentPageParam,
        }}
    >
        {({ setQuery, query }) => (
            <PaginationLimitPerPage
                value={query.limit}
                onChange={callAllHandlers(onChange, (limit: number) => {
                    setQuery({
                        page: 1,
                        limit,
                    });
                })}
                {...rest}
            />
        )}
    </QueryParams>
);
