import isUndefined from 'lodash/isUndefined';
import { AddressTypeEnum, enhancedApi as api } from './codegen';

const getAddressesField = (type: AddressTypeEnum) =>
    type === 'delivery' ? 'delivery_addresses' : 'billing_addresses';

api.enhanceEndpoints({
    endpoints: {
        clientsList: {
            providesTags: (result, error) => [{ type: 'Client', id: 'LIST' }],
        },
        clientsRetrieve: {
            providesTags: (result, error, arg) => [{ type: 'Client', id: arg.id }],
        },
        clientsCreate: {
            invalidatesTags: (result, error) => [{ type: 'Client', id: 'LIST' }],
        },
        clientsPartialUpdate: {
            // don't refetch clients if we simply add/remove an organization,
            // this breaks src/modules/organizations/details/organization-users/organization-users.tsx
            invalidatesTags: (result, error, query) =>
                isUndefined(query.patchedUpdateUserRequest.organization_id)
                    ? [{ type: 'Client', id: 'LIST' }]
                    : [],
            async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('clientsRetrieve', { id }, draft => {
                            Object.assign(draft, data);
                        }),
                    );
                } catch {}
            },
        },
        clientsMePartialUpdate: {
            async onQueryStarted(_, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('clientsMeRetrieve', undefined, draft => {
                            Object.assign(draft, data);
                        }),
                    );
                } catch {}
            },
        },
        clientsAddressesCreate: {
            async onQueryStarted({ customUserId }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('clientsRetrieve', { id: customUserId }, draft => {
                            const addressType = getAddressesField(data.address_type);

                            if (draft[addressType]) {
                                draft[addressType]!.unshift(data);
                            } else {
                                draft[addressType] = [data];
                            }
                        }),
                    );
                } catch {}
            },
        },
        clientsAddressesPartialUpdate: {
            async onQueryStarted({ customUserId, id }, { dispatch, queryFulfilled }) {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(
                        api.util.updateQueryData('clientsRetrieve', { id: customUserId }, draft => {
                            const addressType = getAddressesField(data.address_type);

                            const index = draft[addressType]!.findIndex(adr => adr.id === id);
                            if (index !== -1) {
                                draft[addressType]![index] = data;
                            } else {
                                // address_type was updated
                                dispatch(
                                    api.util.invalidateTags([{ type: 'Client', id: customUserId }]),
                                );
                            }
                        }),
                    );
                } catch {}
            },
        },
    },
});
