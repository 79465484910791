import { createPersistReducer } from '@ducks/helpers';
import { createReactTableSlice, getReactTableSelectors } from '@ducks/react-table-slice';

export const lineItemsTableSlice = createReactTableSlice({
    namespace: 'lineItems',
    initialState: {
        rowSelection: {},
    },
    reducers: {},
});

export const {
    setColumnsConfig: setLineItemsColumnsConfig,
    setRowSelection: setSelectedLineItems,
} = lineItemsTableSlice.actions;

export const {
    selectColumnsConfig: selectLineItemsColumnsConfig,
    selectRowSelection: selectSelectedLineItems,
    selectColumnsOrder: selectLineItemsColumnsOrder,
    selectColumnsVisibility: selectLineItemsColumnsVisibility,
} = getReactTableSelectors(lineItemsTableSlice, state => state.lineItems.table);

export const lineItemsTableReducer = createPersistReducer(lineItemsTableSlice, {
    whitelist: ['columnsConfig'],
});
