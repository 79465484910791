import { Grid, GridProps, Box } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { PaginationWithQuery } from './pagination';
import { PaginationLimitPerPageWithQuery } from './pagination-limit-per-page';

interface Props extends GridProps {
    total: number;
    onPageChange?: (page: number) => void;
    onLimitChange?: (limit: number) => void;
}

export const PaginationPanel = forwardRef<Props, 'div'>(
    ({ total, onPageChange, onLimitChange, children, ...rest }, ref) => {
        return (
            <Grid
                templateColumns={{ base: 'auto', md: '1fr auto 1fr' }}
                display={{ base: 'flex', md: 'grid' }}
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                gap={3}
                mt="20px"
                ref={ref}
                {...rest}
            >
                <Box justifySelf={{ base: 'center', md: 'start' }} order={{ base: 2, md: 1 }}>
                    <PaginationWithQuery total={total} onPageChange={onPageChange} />
                </Box>
                <Box justifySelf="center" order={{ base: 1, md: 2 }}>
                    {children}
                </Box>
                <Box justifySelf={{ base: 'center', md: 'end' }} order={3}>
                    <PaginationLimitPerPageWithQuery total={total} onChange={onLimitChange} />
                </Box>
            </Grid>
        );
    },
);
