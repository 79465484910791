export const FormControlStyles = {
    baseStyle: {
        container: {
            '--input-bg-color': 'colors.white',
            _invalid: {
                '--input-bg-color': 'colors.errorBg',
            },
            _readOnly: {
                '--input-bg-color': 'colors.neutral.subtlest',
            },
            _disabled: {
                '--input-bg-color': 'colors.neutral.subtlest',
            },
        },
        // requiredIndicator: {},
        helperText: {
            mt: '1.5',
            color: 'font.secondary',
            fontSize: 'sm',
            lineHeight: '18',
        },
    },
};
