import { Icon, IconProps } from '@chakra-ui/react';

export const CreditCardIcon = (props: IconProps) => (
    <Icon fill="none" viewBox="0 0 16 16" {...props}>
        <path
            d="M1 6.81818H15M2.27273 3H13.7273C14.4302 3 15 3.56982 15 4.27273V11.9091C15 12.612 14.4302 13.1818 13.7273 13.1818H2.27273C1.56982 13.1818 1 12.612 1 11.9091V4.27273C1 3.56982 1.56982 3 2.27273 3Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
