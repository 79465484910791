import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { getColor, PartsStyleFunction } from '@chakra-ui/theme-tools';

const variantOutline: PartsStyleFunction<typeof parts> = props => {
    const { theme } = props;
    const { focusBorderColor: fc, errorBorderColor: ec } = props;

    return {
        field: {
            // background: 'white',
            bg: 'var(--input-bg-color, var(--chakra-colors-white))',
            borderColor: 'neutral.subtle',

            _hover: {
                borderColor: 'primary.default',
            },
            _focus: {
                // background: 'white',
                borderColor: getColor(theme, fc ?? 'primary.default'),
                boxShadow: 'none',
                // boxShadow: `0 0 0 1px ${getColor(theme, fc ?? 'primary.default')}`,
            },
            _invalid: {
                // background: 'rgba(219, 0, 0, 0.1)',
                // background: 'errorBg',
                borderColor: 'error.100',
                boxShadow: 'none',
                // boxShadow: `0 0 0 1px ${getColor(theme, ec ?? 'error.100')}`,
            },
            _readOnly: {
                '--input-bg-color': 'colors.neutral.subtlest',
            },
            _disabled: {
                '--input-bg-color': 'colors.neutral.subtlest', // todo, we have same shit in FormControlStyles, we place in here cause sometimes input used without FormControl
                borderColor: 'neutral.subtle',
                opacity: '1',
            },
        },
        addon: {
            background: 'neutral.subtle',
        },
    };
};

// https://github.com/chakra-ui/chakra-ui/issues/2347#issuecomment-997215492

export const InputStyles = {
    baseStyle: {
        field: {
            _disabled: {
                color: 'font.disabled',
                backgroundColor: 'neutral.subtlest',
                opacity: '1',
            },
        },
    },
    sizes: {
        md: {
            field: { borderRadius: 'base', px: 4, h: 9 },
            addon: {
                borderRadius: 'base',
                fontSize: 'sm',
                px: 2,
                h: 9,
                minW: 9,
                justifyContent: 'center',
            },
        },
        sm: {
            field: { px: 2, h: 7, fontSize: 'xs' },
            addon: {
                fontSize: 'xs',
                px: 2,
                h: 7,
                minW: 7,
            },
        },
    },
    variants: { outline: variantOutline },
    defaultProps: {},
};
