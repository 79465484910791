import storage from 'redux-persist/lib/storage/session';
import { createSlice, createEntityAdapter } from '@reduxjs/toolkit';
import { enhancedApi as api, SystemMessage } from '@services/df/system-messages';
import { RootState } from '@app/types';
import { createPersistReducer } from '../helpers';

// todo move to widgets/system-messages-list

// cache and metadata for systemMessages
// https://github.com/reduxjs/redux-essentials-example-app/blob/tutorial-steps/src/features/notifications/notificationsSlice.js

const systemMessagesAdapter = createEntityAdapter<SystemMessage>({
    sortComparer: (a, b) => b.updated_at.localeCompare(a.updated_at),
});

export const systemMessagesListSlice = createSlice({
    name: 'systemMessages/list',
    initialState: systemMessagesAdapter.getInitialState({ isLoaded: false }), // we must define a flag in case of an empty response
    reducers: {},
    extraReducers: builder => {
        builder.addMatcher(
            api.endpoints.systemMessagesList.matchFulfilled,
            (state, { payload }) => {
                systemMessagesAdapter.setAll(state, payload?.results ?? []);
                state.isLoaded = true;
            },
        );
        builder.addMatcher(
            // https://github.com/reduxjs/redux-toolkit/issues/1509#issuecomment-984061830
            api.endpoints.systemMessagesPartialUpdate.matchPending,
            (
                state,
                {
                    meta: {
                        arg: {
                            originalArgs: {
                                id,
                                patchedSystemMessageUpdateRequest: { resolved },
                            },
                        },
                    },
                },
            ) => {
                resolved && systemMessagesAdapter.removeOne(state, id);
            },
        );
    },
});

export const systemMessagesListReducer = createPersistReducer(systemMessagesListSlice, {
    dependsOnCompany: true,
    storage,
});

export const systemMessagesActions = systemMessagesListSlice.actions;

export const selectIsSystemMessagesLoaded = (state: RootState) =>
    state.systemMessages.list.isLoaded;

export const { selectAll: selectSystemMessages, selectEntities: selectSystemMessagesEntities } =
    systemMessagesAdapter.getSelectors((state: RootState) => state.systemMessages.list);
