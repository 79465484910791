import { useCallback, useRef } from 'react';
import { forwardRef } from '@chakra-ui/system';
import { Box, BoxProps, Grid, IconButton, useDisclosure } from '@chakra-ui/react';
import { useAppSelector } from '@app/hooks';
import { selectUserPermissions } from '@entities';
import { SystemMessagesButton } from '@ducks/system-messages';
import { AppLogo } from '@shared/components/app-logo';
import { TableListIcon } from '@shared/components/icons';
import { useAppSize } from '@shared/hooks';
import { OnItemSelect, isRoute, SideNavigationDrawer } from '../side-navigation';

interface HeaderProps extends BoxProps {}

export const Header = forwardRef<HeaderProps, 'div'>(({ children, ...rest }, ref) => {
    const { userIsAdminOrSalesman } = useAppSelector(selectUserPermissions);
    const { isLargeTablet } = useAppSize();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const menuBtnRef = useRef(null);
    const handleItemSelect: OnItemSelect = useCallback(
        item => {
            if (isRoute(item)) {
                onClose();
            }
        },
        [onClose],
    );

    return (
        <Box
            ref={ref}
            as="header"
            position="sticky"
            zIndex={2}
            top={0}
            w="full"
            p={4}
            bgColor="neutral.subtlest"
            {...rest}
        >
            <Grid alignItems="center" templateColumns="repeat(3, 1fr)">
                <Box justifySelf="start">
                    <IconButton
                        aria-label="Menu"
                        size="sm"
                        colorScheme="secondary"
                        icon={<TableListIcon />}
                        ref={menuBtnRef}
                        onClick={onOpen}
                    />

                    <SideNavigationDrawer
                        isOpen={isOpen}
                        onClose={onClose}
                        finalFocusRef={menuBtnRef}
                        onItemSelect={handleItemSelect}
                    />
                </Box>

                <Box justifySelf="center">
                    <AppLogo h="32px" />
                </Box>

                {userIsAdminOrSalesman ? (
                    <Box justifySelf="end">
                        <SystemMessagesButton
                            asIcon={!isLargeTablet}
                            {...(isLargeTablet
                                ? undefined
                                : { colorScheme: 'secondary', variant: 'ghost', size: 'sm' })}
                        />
                    </Box>
                ) : null}
            </Grid>
        </Box>
    );
});
