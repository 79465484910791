import { Box, BoxProps, Tooltip } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { HelpCircleIcon } from '@shared/components/icons';
import { DiscountSerializerV2 } from '@services/df/discounts';
import { DISCOUNT_TYPE_LABELS } from '@services/df/codegen-enums-labels';

interface Props extends BoxProps {
    discount: DiscountSerializerV2 | undefined;
}

export const DiscountTooltip = forwardRef<Props, 'div'>(({ discount, ...rest }, ref) => {
    const label = discount?.type ? DISCOUNT_TYPE_LABELS[discount?.type] : 'Unknown discount type';
    const name = discount?.name ? discount.name : 'Unknown discount name';

    return discount ? (
        <Tooltip label={`${label}: ${name}`}>
            <Box display="inline-flex" ml="1" ref={ref} {...rest}>
                <HelpCircleIcon color="neutral.darkest" w="13px" h="13px" minW="auto" minH="auto" />
            </Box>
        </Tooltip>
    ) : null;
});
