import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import { FieldErrors } from 'react-hook-form';
import { Text, TextProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';

export const NonFieldErrorsKey = 'detail';

interface NonFieldErrorsProps extends TextProps {
    errors?: FieldErrors;
}

export const NonFieldErrors = forwardRef<NonFieldErrorsProps, 'p'>(
    ({ errors = '', ...rest }, ref) => {
        if (!errors || isEmpty(errors) || (!isString(errors) && !errors[NonFieldErrorsKey]))
            return null;

        return (
            <Text
                textStyle="typography-xs"
                color="error.default"
                // color="red.500"
                ref={ref}
                {...rest}
                mt={-2}
            >
                {isString(errors)
                    ? errors
                    : isString(errors[NonFieldErrorsKey])
                    ? errors[NonFieldErrorsKey]
                    : (errors[NonFieldErrorsKey]?.message as string)}
            </Text>
        );
    },
);
