import isString from 'lodash/isString';
import isObject from 'lodash/isObject';
import { Children, ReactNode, useMemo } from 'react';
import {
    Input as ChakraInput,
    InputProps as ChakraInputProps,
    InputGroup as ChakraInputGroup,
    InputGroupProps as ChakraInputGroupProps,
    InputLeftAddon,
    InputRightAddon,
    InputRightElement,
    InputLeftElement,
    InputAddonProps,
    InputElementProps,
    FlexProps,
    useClipboard,
} from '@chakra-ui/react';
import { ComponentWithAs, forwardRef } from '@chakra-ui/system';
import { FCC, ValuesType } from '@shared/types';
import { ClipboardButton } from '@shared/components/tooltipped-icon-button';
import { isIconElement } from '@shared/utils/react';
import { useFieldStyles } from '../hooks';

interface AddonsProps {
    rightAddon?: ReactNode | Omit<InputAddonProps, 'placement'>;
    rightElement?: ReactNode | Omit<InputElementProps, 'placement'>;
    leftAddon?: ReactNode | Omit<InputAddonProps, 'placement'>;
    leftElement?: ReactNode | Omit<InputElementProps, 'placement'>;
}

// type AddonsProps = (
//     | {
//           rightAddon?: ReactNode | Omit<InputAddonProps, 'placement'>;
//           rightElement?: never;
//       }
//     | {
//           rightAddon?: never;
//           rightElement?: ReactNode | Omit<InputElementProps, 'placement'>;
//       }
// ) &
//     (
//         | {
//               leftAddon?: ReactNode | Omit<InputAddonProps, 'placement'>;
//               leftElement?: never;
//           }
//         | {
//               leftAddon?: never;
//               leftElement?: ReactNode | Omit<InputElementProps, 'placement'>;
//           }
//     );

function renderChild(
    component: ComponentWithAs<'div', InputElementProps> | ComponentWithAs<'div', InputAddonProps>,
    node: ValuesType<AddonsProps>,
) {
    const Component = component;

    if (isString(node) || isIconElement(node)) {
        return <Component children={node} />;
    }

    if (isObject(node) && Boolean((node as { children?: ReactNode }).children)) {
        return <Component {...node} />;
    }

    return node as ReactNode;
}

// const compoundStyles = {
//     '.react-select-container': { width: 'full' },
//     '.react-select-container > .react-select__control': { '--input-bg-color': 'colors.gray.200' },
//     '.react-select-container:not(:first-of-type) > .react-select__control': { borderStartRadius: 0 },
//     '.react-select-container:not(:last-of-type) > .react-select__control': { borderEndRadius: 0 },
//     '.chakra-input:not(:first-of-type)': { borderStartRadius: 0 },
//     '.chakra-input:not(:last-of-type)': { borderEndRadius: 0 },
// };

const compoundStyles = {
    '.react-select-container': { width: 'full' },
    '.react-select-container > .react-select__control': { '--input-bg-color': 'colors.gray.200' },
    '.react-select-container:not(:first-child) > .react-select__control': { borderStartRadius: 0 },
    '.react-select-container:not(:last-child) > .react-select__control': { borderEndRadius: 0 },
    //***** The sign of a direct descendant ">" was deleted from following styles because there is additional *****/
    //***** wrapper in numeric inputs, so with it numeric multiple inputs layout breaks *****/
    '.input-container:not(:first-child) .chakra-input': { borderStartRadius: 0 },
    '.input-container:not(:last-child) .chakra-input': { borderEndRadius: 0 },
};
// todo cases with InputLeftElement/InputRightElement are not supported

// one can move it to global class names e.g. "chakra-input__group--shadowed"
// https://github.com/chakra-ui/chakra-ui/issues/2085#issuecomment-699964454
const shadowedStyles = {
    _after: {
        content: '""',
        w: '32',
        h: 'full',
        bgGradient: `linear(to-l, white, transparent)`,
        // bgGradient: `linear(to-l, var(--input-bg-color), transparent)`,
        // bgGradient: `linear(to-l, var(--input-bg-color) 30%, transparent)`,
        position: 'absolute',
        bgClip: 'content-box',
        padding: 'calc(var(--chakra-sizes-border) * 2)',
        top: '0',
        right: '0',
        zIndex: '1',
        pointerEvents: 'none',
    },
    // with "sx" props
    // '[role=group][data-disabled] &:after': {
    //     bgGradient: `linear(to-l, gray.100, transparent)`,
    // },
    // '[role=group][data-readonly] &:after': {
    //     bgGradient: `linear(to-l, gray.100, transparent)`,
    // },
};

type InputGroupProps = ChakraInputGroupProps & {
    shadowed?: boolean;
};

export const InputGroup: FCC<InputGroupProps & AddonsProps> = ({
    rightAddon,
    leftAddon,
    rightElement,
    leftElement,
    shadowed,
    children,
    sx,
    ...rest
}) => {
    const props = useFieldStyles('inputGroup', rest);

    const left = leftAddon
        ? renderChild(InputLeftAddon, leftAddon)
        : leftElement
        ? renderChild(InputLeftElement, leftElement)
        : null;

    const right = rightAddon
        ? renderChild(InputRightAddon, rightAddon)
        : rightElement
        ? renderChild(InputRightElement, rightElement)
        : null;

    const isCompound = Children.count(children) > 1;
    const isGroup = Boolean(left || right) || isCompound;
    const styles = useMemo(
        () => (isCompound ? (sx ? { ...compoundStyles, ...sx } : compoundStyles) : undefined),
        [sx, isCompound],
    );

    if (isGroup) {
        return (
            // <ChakraInputGroup sx={shadowed ? shadowedStyles : undefined} {...props}>
            <ChakraInputGroup
                sx={styles}
                alignItems="flex-end"
                {...(shadowed ? shadowedStyles : {})}
                {...props}
            >
                {children}
                {left}
                {right}
                {/*<InputRightAddon>Try</InputRightAddon>*/}
                {/*<InputRightAddon>Render</InputRightAddon>*/}
                {/*<InputRightAddon>Array</InputRightAddon>*/}
            </ChakraInputGroup>
        );
    }

    return <>{children}</>;
};

export type WithInputGroupProps = AddonsProps & {
    groupProps?: InputGroupProps;
    isWhite?: boolean;
};

export type CommonInputProps = ChakraInputProps & WithInputGroupProps;

export const ClipboardInput = forwardRef<CommonInputProps, 'input'>(
    ({ groupProps, leftAddon, leftElement, isWhite, size, ...input }, ref) => {
        const value = input.value || input.defaultValue;
        const clipboard = useClipboard(value ? value.toString() : '');

        return (
            <InputGroup
                leftAddon={leftAddon}
                leftElement={leftElement}
                rightElement={
                    <InputRightElement {...(size === 'sm' && { h: '28px' })}>
                        <ClipboardButton {...clipboard} size={size} />
                    </InputRightElement>
                }
                shadowed
                {...groupProps}
            >
                <ChakraInput
                    pr="9"
                    ref={ref}
                    isReadOnly
                    size={size}
                    {...(isWhite && { _readOnly: { bg: 'white' } })}
                    {...input}
                />
            </InputGroup>
        );
    },
);

export type InputProps = CommonInputProps & {
    clipboard?: boolean;
    // add here another variants that require custom logic in addons
};

export const Input = forwardRef<InputProps, 'input'>(
    (
        { groupProps, rightAddon, leftAddon, rightElement, leftElement, clipboard, ...input },
        ref,
    ) => {
        const props = useFieldStyles('input', input);

        if (clipboard) {
            return (
                <ClipboardInput
                    ref={ref}
                    leftAddon={leftAddon}
                    leftElement={leftElement}
                    groupProps={groupProps}
                    {...props}
                />
            );
        }

        return (
            <InputGroup
                leftAddon={leftAddon}
                rightAddon={rightAddon}
                leftElement={leftElement}
                rightElement={rightElement}
                {...groupProps}
            >
                <ChakraInput ref={ref} {...props} />
            </InputGroup>
        );
    },
);

// todo <Input as={ClipboardInput}...
