import { ReactNode } from 'react';
import {
    FormControl,
    FormControlProps as ChakraFormControlProps,
    FormHelperText,
    FormErrorMessage,
    FormLabelProps,
    FormErrorMessageProps,
    HelpTextProps,
} from '@chakra-ui/react';
import { dataAttr } from '@chakra-ui/utils';
import { FCC } from '@shared/types';
import { LabelControl } from './label-control';
import { HintProps } from './hint';
import { useFieldStyles } from '../hooks';

export type FormControlProps = Omit<ChakraFormControlProps, 'label'>;

export type FieldElementsProps = {
    label?: ReactNode;
    labelProps?: FormLabelProps;
    helpText?: ReactNode;
    helpTextProps?: Omit<HelpTextProps, 'children'>;
    helpTextAsTooltip?: boolean;
    // errorType?: 'plain' | 'tooltip'; // todo
    errorText?: ReactNode;
    errorProps?: Omit<FormErrorMessageProps, 'children'>;
    tooltip?: ReactNode;
    tooltipProps?: HintProps;
    isHelpTextAboveInput?: boolean;
};

export const FieldControl: FCC<FieldElementsProps & FormControlProps> = ({
    label,
    helpText,
    errorText,
    tooltip,

    labelProps,
    helpTextProps: _helpTextProps,
    helpTextAsTooltip,
    errorProps: _errorProps,
    tooltipProps,
    isHelpTextAboveInput,

    children,
    ...rest
}) => {
    const props = useFieldStyles('control', rest);
    const errorProps = useFieldStyles('error', _errorProps);
    const helpTextProps = useFieldStyles('helpText', _helpTextProps);

    const renderHelpText = () =>
        helpText &&
        !helpTextAsTooltip && <FormHelperText {...helpTextProps}>{helpText}</FormHelperText>;

    return (
        <FormControl
            data-disabled={dataAttr(rest.isDisabled)}
            data-invalid={dataAttr(rest.isInvalid)}
            data-readonly={dataAttr(rest.isReadOnly)}
            {...props}
        >
            {label && (
                <LabelControl
                    label={label}
                    tooltip={helpTextAsTooltip ? helpText : tooltip}
                    hintProps={tooltipProps}
                    {...labelProps}
                />
            )}

            {isHelpTextAboveInput && renderHelpText()}

            {children}

            {errorText ? (
                <FormErrorMessage {...errorProps}>{errorText}</FormErrorMessage>
            ) : (
                !isHelpTextAboveInput && renderHelpText()
            )}
        </FormControl>
    );
};
