import { useCallback, useState } from 'react';
import { useAppSelector } from '@app/hooks';
import { useMachinesSamplesCreateMutation } from '@services/df/machines-materials';
import { createToast as toast } from '@shared/components/toast';
import { isFetchBaseQueryError } from '@shared/utils/http';
import { selectAllowedTechnologiesOptions } from '../selectors';

export const useMachineSampleCreate = () => {
    const technologies = useAppSelector(selectAllowedTechnologiesOptions);
    const [update, { isLoading }] = useMachinesSamplesCreateMutation();
    const [technology, setTechnology] = useState(technologies[0]);

    return {
        technology,
        setTechnology,
        technologies,
        update: useCallback(
            async (technologyId: number) => {
                try {
                    await update({
                        copySampleMachineRequestRequest: {
                            technology_id: technologyId,
                        },
                    }).unwrap();
                } catch (error) {
                    isFetchBaseQueryError(error) &&
                        toast({
                            status: 'error',
                            description: error.message,
                            isClosable: true,
                        });
                }
            },
            [update],
        ),
        isLoading,
    };
};
