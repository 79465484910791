import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, FieldType, OptionType } from '@shared/components/forms-v2';

type Options = {
    availableRolesOptions?: OptionType[];
};

export const getUserConfig = ({ availableRolesOptions }: Options = {}) => {
    const email = defineField({
        type: FieldType.Text,
        name: 'email',
        scheme: yup.string().ensure().trim().email().required().default(''),
        props: {
            label: 'Email',
        },
    });

    const name = defineField({
        type: FieldType.Text,
        name: 'name',
        scheme: yup.string().ensure().trim().required().default(''),
        props: {
            label: 'Name',
        },
    });

    const surname = defineField({
        type: FieldType.Text,
        name: 'surname',
        scheme: yup.string().ensure().trim().required().default(''),
        props: {
            label: 'Last name',
        },
    });

    const roles = defineField({
        type: FieldType.Select,
        name: 'roles',
        scheme: yup.array().of(yup.string()).min(1).defined(),
        props: {
            label: 'Roles',
            isSearchable: false,
            isMulti: true,
            options: availableRolesOptions,
        },
    });

    const fieldsArray = [email, name, surname, roles];
    const fields = { email, name, surname, roles };

    const schema = yup.object(
        mapValues(
            {
                email,
                name,
                surname,
                roles,
            },
            'scheme',
        ),
    );

    return { schema, fields, fieldsArray };
};

export type UserConfig = ReturnType<typeof getUserConfig>;
export type UserSchema = UserConfig['schema'];
export type UserFields = UserConfig['fields'];
export type UserFieldsUnion = keyof UserConfig['fields'];
export type UserFieldsArray = Array<UserFields[UserFieldsUnion]>;
