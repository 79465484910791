import { isSameDay, startOfDay, subDays, subMonths } from 'date-fns';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import { CalendarConfigs } from 'chakra-dayzed-datepicker/src/utils/commonTypes';

dayjs.extend(localeData);

export type OneOrTwoDates = [Date, Date] | [Date];

export const getNewSelectedDates = (
    dates: OneOrTwoDates | undefined,
    newDate: Date,
): OneOrTwoDates => {
    if (!dates || dates.length === 2) {
        return [newDate];
    }

    if (dates.length === 1) {
        const firstTime = dates[0];
        return firstTime < newDate ? [...dates, newDate] : [newDate, ...dates];
    }

    return [newDate];
};

const convertLocale = (browserLocale: string) => {
    switch (browserLocale) {
        case 'en-us' || !browserLocale:
            return 'en';
        case 'es-es':
            return 'es';
        case 'fr-fr':
            return 'fr';
        case 'it-it':
            return 'it';
        case 'ru-ru':
            return 'ru';
        default:
            return browserLocale;
    }
};

export const getFirstDayOfWeek = async () => {
    const browserLocale = navigator.language.toLowerCase();
    try {
        const dayjsLocale = await import(
            /* @vite-ignore */ `dayjs/locale/${convertLocale(browserLocale)}.js`
        );
        dayjs.locale(dayjsLocale);
        const firstDayOfWeek = dayjs()
            .localeData()
            .firstDayOfWeek() as CalendarConfigs['firstDayOfWeek'];

        return firstDayOfWeek;
    } catch (error) {
        console.error(error);
        return 0;
    }
};

export const isToday = (selectedDates: OneOrTwoDates) => {
    const today = startOfDay(new Date());
    return selectedDates.every(date => isSameDay(today, startOfDay(date)));
};

export const isYesterday = (selectedDates: OneOrTwoDates) => {
    const yesterday = subDays(new Date(), 1);
    return selectedDates.every(date => isSameDay(date, yesterday));
};

export const isLastMonth = (selectedDates: OneOrTwoDates) => {
    if (selectedDates.length === 2) {
        const [startDate, endDate] = selectedDates;
        const today = startOfDay(new Date());
        const monthAgo = subMonths(endDate, 1);
        return isSameDay(today, endDate) && isSameDay(startDate, monthAgo);
    }
    return false;
};

export const isLast3Months = (selectedDates: OneOrTwoDates) => {
    if (selectedDates.length === 2) {
        const [startDate, endDate] = selectedDates;
        const today = startOfDay(new Date());
        const threeMonthAgo = subMonths(endDate, 3);
        return isSameDay(today, endDate) && isSameDay(startDate, threeMonthAgo);
    }
    return false;
};

export const isLast12Months = (selectedDates: OneOrTwoDates) => {
    if (selectedDates.length === 2) {
        const [startDate, endDate] = selectedDates;
        const today = startOfDay(new Date());
        const twelveMonthAgo = subMonths(endDate, 12);
        return isSameDay(today, endDate) && isSameDay(startDate, twelveMonthAgo);
    }
    return false;
};

export const parseDate = (dateString: string | undefined) => {
    return dateString ? dayjs(dateString).toDate() : undefined;
};

interface ConvertDateRangeProps {
    dateFrom?: string;
    dateTo?: string;
}

export function convertDateRange({ dateFrom, dateTo }: ConvertDateRangeProps) {
    return dateFrom || dateTo
        ? ([parseDate(dateFrom) ?? undefined, dateTo ? parseDate(dateTo)! : undefined].filter(
              Boolean,
          ) as OneOrTwoDates)
        : undefined;
}

export const getRangeDatepickerButtons = () => [
    {
        label: 'Today',
        value: [new Date(), new Date()] as const,
        checkActive: (range: OneOrTwoDates) => isToday(range),
    },
    {
        label: 'Yesterday',
        value: [subDays(new Date(), 1), subDays(new Date(), 1)] as const,
        checkActive: (range: OneOrTwoDates) => isYesterday(range),
    },
    {
        label: 'Last month',
        value: [new Date(), subMonths(new Date(), 1)] as const,
        checkActive: (range: OneOrTwoDates) => isLastMonth(range),
    },
    {
        label: 'Last 3 months',
        value: [new Date(), subMonths(new Date(), 3)] as const,
        checkActive: (range: OneOrTwoDates) => isLast3Months(range),
    },
    {
        label: 'Last 12 months',
        value: [new Date(), subMonths(new Date(), 12)] as const,
        checkActive: (range: OneOrTwoDates) => isLast12Months(range),
    },
];
