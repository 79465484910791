import * as yup from 'yup';
import mapValues from 'lodash/mapValues';
import { defineField, FieldType } from '@shared/components/forms-v2';

export const getAuthConfig = () => {
    const email = defineField({
        type: FieldType.Text,
        name: 'email',
        scheme: yup.string().ensure().trim().email().required(),
        props: {
            label: 'Login',
            // label: 'Email',
            labelProps: { color: 'font.secondary' },
        },
    });

    const password = defineField({
        type: FieldType.Text,
        name: 'password',
        scheme: yup.string().ensure().trim().required(),
        props: {
            label: 'Password',
            type: 'password',
            labelProps: { color: 'font.secondary' },
        },
    });

    const schema = yup.object(
        mapValues(
            {
                email,
                password,
            },
            'scheme',
        ),
    );

    const fields = {
        email,
        password,
    };

    return {
        fields,
        schema,
    };
};
