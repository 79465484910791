import { Modal, ModalProps, ModalContent, ModalBody } from '@chakra-ui/react';
import { FullscreenOff } from '@shared/components/tooltipped-icon-button';

interface Props extends ModalProps {}

// todo remove, fullscreen should be included in viewer
export const ModelViewerFullScreenModal = ({ isOpen, onClose, children, ...rest }: Props) => {
    return (
        <Modal
            variant="alert"
            isOpen={isOpen}
            onClose={onClose}
            isCentered={true}
            lockFocusAcrossFrames={false}
            size="xxxl"
            {...rest}
        >
            <ModalContent position="absolute" w="100vw" h="100vh">
                <FullscreenOff
                    position="absolute"
                    bottom="20px"
                    right="100px"
                    tooltipPlacement="left"
                    onClick={onClose}
                />
                <ModalBody>{children}</ModalBody>
            </ModalContent>
        </Modal>
    );
};
