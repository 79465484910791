import { combineEpics } from 'redux-observable';
import {
    filter,
    withLatestFrom,
    switchMap,
    from,
    catchError,
    of,
    concat,
    timer,
    map,
    takeWhile,
    mergeMap,
} from 'rxjs';
import { AppEpic } from '@app/types';
import { ModelsService } from '@services/models.service';
import { selectObjectModelsIds, selectModels, selectUploadJob } from './selectors';
import { formatDropzoneAccepts } from '@shared/components/upload';
import {
    addAcceptedFiles,
    checkStatus,
    checkStatusFailure,
    checkStatusSuccess,
    createUploadJobFailure,
    createUploadJobSuccess,
    setUploadModels,
    startStatusPolling,
    stopStatusPolling,
    uploadModels,
    uploadModelsFailure,
    uploadModelsSuccess,
} from './slice';

const uploadModelsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(uploadModels.match),
        withLatestFrom(state$),
        mergeMap(([action, state]) => {
            const uj = selectUploadJob(state);
            return from(ModelsService.init().uploadModels(uj, action.payload)).pipe(
                mergeMap(({ data }) => {
                    return of(
                        uploadModelsSuccess({ models: data, files: action.payload }),
                        startStatusPolling(),
                    );
                }),
                catchError(() => of(uploadModelsFailure())),
            );
        }),
    );

const setUploadModelsEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(setUploadModels.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            return from(ModelsService.init().createUploadJob()).pipe(
                switchMap(({ data }) => {
                    const acceptedFiles = formatDropzoneAccepts(action.payload);
                    return of(
                        createUploadJobSuccess(data.uj),
                        addAcceptedFiles(acceptedFiles),
                        uploadModels(acceptedFiles),
                    );
                }),
                catchError(() => of(createUploadJobFailure())),
            );
        }),
    );

const checkStatusEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(checkStatus.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const ids = selectObjectModelsIds(state);
            return from(ModelsService.init().checkStatus(ids)).pipe(
                switchMap(({ data }) => {
                    return of(checkStatusSuccess(data));
                }),
                catchError(() => of(checkStatusFailure())),
            );
        }),
    );

const startCheckStatusPollingEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(startStatusPolling.match),
        switchMap(() =>
            concat(
                timer(2000, 4000).pipe(
                    withLatestFrom(state$),
                    map(([_, state]) => selectModels(state)),
                    takeWhile(models =>
                        Boolean(
                            models.filter(
                                model => model.status !== 'ready' && model.status !== 'failed',
                            ).length,
                        ),
                    ),
                    switchMap(() => of(checkStatus())),
                ),
                of(stopStatusPolling()),
            ),
        ),
    );

export const uploadModelsEpics = combineEpics(
    uploadModelsEpic,
    setUploadModelsEpic,
    checkStatusEpic,
    startCheckStatusPollingEpic,
);
