import { Box, BoxProps, Center, Fade, Spinner, SpinnerProps } from '@chakra-ui/react';
import { FCC } from '@shared/types';

interface Props extends SpinnerProps {}

export const Preloader = (props: Props) => {
    return <Spinner color="primary.default" emptyColor="gray.200" speed="0.76s" {...props} />;
};

interface PreloaderBoxProps extends BoxProps {
    in: boolean;
    size?: SpinnerProps['size'];
    thickness?: SpinnerProps['thickness'];
}

export const PreloaderBox: FCC<PreloaderBoxProps> = ({
    in: show,
    size = 'xl',
    thickness = '5px',
    children,
    ...rest
}) => {
    const spinner = (
        <Fade in={show} unmountOnExit={true}>
            <Center
                position="absolute"
                top={0}
                left={0}
                zIndex={1}
                w="full"
                h="full"
                background="rgba(255, 255, 255, 0.5)"
            >
                <Preloader size={size} thickness={thickness} />
            </Center>
        </Fade>
    );

    if (!children) {
        return spinner;
    }

    return (
        <Box position="relative" {...rest}>
            {children}
            {spinner}
        </Box>
    );
};
