import { buildCreateSlice, asyncThunkCreator, createAsyncThunk } from '@reduxjs/toolkit';
import { ModelsService } from '@services/models.service';
// import type { FetchBaseQueryError } from '@services/df/baseApi';
import type { RootState, AppDispatch, AppExtraApi } from './types';

export const appExtraApi = { ModelsService };

// Use throughout your app instead of plain 'createSlice', 'createAsyncThunk'
export const createAppSlice = buildCreateSlice({
    creators: { asyncThunk: asyncThunkCreator },
});

export const createAppAsyncThunk = createAsyncThunk.withTypes<{
    state: RootState;
    dispatch: AppDispatch;
    // rejectValue: FetchBaseQueryError;
    extra: AppExtraApi;
}>();
