import React, { ElementType, ComponentProps, useCallback } from 'react';
import { useController } from 'react-hook-form';
import { PickOptional, PolymorphicComponentProps } from '@shared/types';
import { ControllerFieldProps } from '../types';
import { FieldControl } from '../common';
import { Input } from '../inputs';
import { getFieldState } from '../helpers';

type Props = ControllerFieldProps & {
    wrapped?: boolean;
    isBooleanGroup?: boolean;
    transformInput?: (value: any) => any; // get from react-hook-form and provide to input component
    transformOutput?: (...args: any[]) => any; // before saving in react-hook-form
};

type ControllableFieldProps<C extends ElementType> = PolymorphicComponentProps<C, Props>;

export const ControllableField = <C extends ElementType = typeof Input>({
    as,
    isBooleanGroup,
    transformInput,
    transformOutput,
    wrapped = true,

    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    tooltip,
    helpText,
    helpTextAsTooltip,
    errorText: _,

    formControlProps,
    labelProps,
    errorProps,
    helpTextProps,
    tooltipProps,

    ...inputProps
}: ControllableFieldProps<C>) => {
    const Component = as || Input;
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const {
        field: { ref, value, onChange: _onChange, onBlur: _onBlur, ...field },
    } = state;

    const onChange = useCallback(
        (...arg: any[]) => {
            const output = transformOutput ? transformOutput(...arg) : arg[0];
            _onChange(output, ...arg?.slice(1));
            inputProps?.onChange?.(output, ...arg?.slice(1));
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputProps?.onChange, _onChange, transformOutput],
    );

    const onBlur = useCallback(
        (...arg: any[]) => {
            // @ts-ignore
            _onBlur(...arg);
            inputProps?.onBlur?.(...arg);
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [inputProps?.onBlur, _onBlur],
    );

    // can't use ref and isInvalid with groups like Radio and MultipleCheckboxes
    const conditionalProps = isBooleanGroup ? {} : { isInvalid, ref };

    const input = (
        <Component
            {...field}
            {...inputProps}
            value={transformInput ? transformInput(value) : value}
            onBlur={onBlur}
            onChange={onChange}
            {...conditionalProps}
        />
    );

    return wrapped ? (
        <FieldControl
            label={label}
            labelProps={labelProps}
            tooltip={tooltip}
            tooltipProps={tooltipProps}
            helpText={helpText}
            helpTextAsTooltip={helpTextAsTooltip}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            {...formControlProps}
        >
            {input}
        </FieldControl>
    ) : (
        input
    );
};

// based on https://react-typescript-cheatsheet.netlify.app/docs/hoc/excluding_props/

type InjectedControllerFieldProps<C extends ElementType> = Pick<
    ControllableFieldProps<C>,
    'isBooleanGroup' | 'transformInput' | 'transformOutput'
>;

export function withControllableField<
    C extends ElementType,
    U extends PickOptional<ComponentProps<C>> & InjectedControllerFieldProps<C>,
>(component: C, injectedProps: U) {
    const Wrapped = (props: Omit<ControllableFieldProps<C>, keyof U>) => {
        const newProps = { ...injectedProps, ...props } as unknown as ControllableFieldProps<C>;
        return <ControllableField {...newProps} as={component} />;
    };

    // Format for display in DevTools
    // @ts-ignore
    const name = component.displayName || component.name || 'Unknown';
    Wrapped.displayName = `withControllableField(${name})`;

    return Wrapped;
}
