import { mode } from '@chakra-ui/theme-tools';

export const AlertStyles = {
    baseStyle: {
        container: {
            w: 'auto',
            // maxW: '480ox',
            borderRadius: 'base',
            px: 3,
        },
        title: {
            fontSize: 'md',
            fontWeight: 'semibold',
            lineHeight: 'none',
        },
        description: {
            display: 'inline-block',
            fontSize: 'sm',
            lineHeight: 1.25,
            '.chakra-alert__title + &': { mt: 1 },
        },
        icon: {
            w: 4,
            h: 4,
        },
    },
    variants: {
        solid: ({ colorScheme }: any) => {
            return {
                container: {
                    bgColor: `${colorScheme}.default`,
                },
            };
        },
        subtle: (props: any) => {
            const { colorScheme: c } = props;
            return {
                container: {
                    bgColor: `${c}.subtle`,
                    '.chakra-icon': { color: mode(`${c}.default`, `${c}.200`)(props) },
                },
                title: {
                    color: `${c}.default`,
                },
            };
        },
        neutral: (props: any) => {
            const { colorScheme: c } = props;
            return {
                container: {
                    bgColor: 'neutral.subtlest',
                    '.chakra-alert__close-icon': { color: 'neutral.darkest' },
                },
                icon: {
                    color: mode(`${c}.default`, `${c}.200`)(props),
                },
            };
        },
    },
    defaultProps: { colorScheme: 'error' },
};
