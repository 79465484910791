import { useController } from 'react-hook-form';
import { ControllerFieldProps } from '../types';
import { FieldControl, Hint } from '../common';
import { Checkbox, CheckboxProps } from '../inputs';
import { getFieldState } from '../helpers';

export type CheckboxFieldProps = CheckboxProps & Omit<ControllerFieldProps, 'labelProps'>;

export const CheckboxField = ({
    name,
    rules,
    shouldUnregister,
    defaultValue,
    control,

    label,
    helpText,
    helpTextAsTooltip,
    tooltip: _tooltip,

    formControlProps,
    tooltipProps,
    errorProps,
    helpTextProps,
    children,
    ...input
}: CheckboxFieldProps) => {
    const state = useController({ name, rules, shouldUnregister, defaultValue, control });
    const { errorText, isInvalid } = getFieldState(state);
    const { field } = state;
    const tooltip = helpTextAsTooltip ? helpText : _tooltip;

    return (
        <FieldControl
            helpText={helpText}
            helpTextProps={helpTextProps}
            isInvalid={isInvalid}
            errorText={errorText}
            errorProps={errorProps}
            display="flex"
            alignItems="center"
            {...formControlProps}
        >
            <Checkbox
                {...input}
                {...field}
                isChecked={field.value}
                onChange={e => {
                    field.onChange(e.target.checked);
                    input?.onChange?.(e);
                }}
            >
                {label}
            </Checkbox>

            {!!tooltip && <Hint label={tooltip} {...tooltipProps} />}
            {children}
        </FieldControl>
    );
};
