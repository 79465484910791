import { MouseEventHandler, ReactElement } from 'react';
import {
    Button,
    ButtonProps,
    IconButton,
    HStack,
    StackProps,
    IconButtonProps,
} from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { CloseIcon } from '@shared/components/icons';

interface FilterTriggerButtonProps extends ButtonProps {
    icon: ReactElement;
}

export const FilterTriggerButton = forwardRef<FilterTriggerButtonProps, 'button'>(
    ({ icon, children, ...rest }, ref) => {
        return (
            <Button
                ref={ref}
                variant="unstyled"
                display="inline-flex"
                alignItems="center"
                iconSpacing={1}
                minW="69px"
                minH="32px"
                p="8px 12px 8px 12px"
                borderRadius="base"
                border="1px solid"
                borderColor="neutral.subtlest"
                bg="neutral.subtlest"
                color="font.primary"
                _active={{
                    borderColor: 'primary.default',
                    color: 'primary.default',
                }}
                _hover={{
                    bg: 'neutral.subtle',
                }}
                leftIcon={icon}
                {...rest}
            >
                {children}
            </Button>
        );
    },
);

export const FilterResetButton = forwardRef<Omit<IconButtonProps, 'aria-label'>, 'button'>(
    (props, ref) => {
        return (
            <IconButton
                ref={ref}
                aria-label="reset-filter"
                variant="link"
                colorScheme="primary"
                icon={<CloseIcon />}
                {...props}
            />
        );
    },
);

interface FilterProps extends Omit<StackProps, 'onReset'> {
    isActive: boolean;
    onReset: MouseEventHandler;
}

export const Filter = forwardRef<FilterProps, 'div'>(
    ({ isActive, children, onReset, ...props }, ref) => {
        return (
            <HStack ref={ref} align="center" {...props}>
                {children}
                {isActive && <FilterResetButton onClick={onReset} />}
            </HStack>
        );
    },
);
