import { Text, TextProps } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { dot } from '@app/styles/helpers';

const COLORS_BY_TYPE = {
    neutral: 'neutral.dark',
    warning: 'warning.default',
    error: 'error.default',
    success: 'success.default',
} as const;

export type IndicatorType = keyof typeof COLORS_BY_TYPE;

export interface TextIndicatorProps extends TextProps {
    type?: IndicatorType;
    gap?: string | number;
}

export const TextIndicator = forwardRef<TextIndicatorProps, 'span'>(
    ({ children, type = 'neutral', gap = '1', ...rest }, ref) => {
        const color = COLORS_BY_TYPE[type];
        return (
            <Text ref={ref} as="span" {...dot(color, gap)} {...rest}>
                {children}
            </Text>
        );
    },
);
