import {
    CustomTechnologyAdmin,
    PrintingTechnologyAdmin,
    PrinterPreset,
} from '@services/df/machines-materials';

export function parseTechnologiesOptions(
    technologies: PrintingTechnologyAdmin[] | CustomTechnologyAdmin[] = [],
) {
    return technologies.map(technology => ({
        label: technology.title,
        value: technology.id.toString(),
    }));
}

export function parseMachinesOptions(machines: PrinterPreset[] = []) {
    return machines.map(machine => ({
        label: machine.title,
        value: machine.title,
    }));
}
