import { Center, BoxProps, IconProps, Image, ImageProps, forwardRef } from '@chakra-ui/react';
import { ThumbDefaultIcon } from '@shared/components/icons';

export interface ThumbProps extends BoxProps {
    src?: string;
    alt?: string;
    size?: BoxProps['boxSize'];
    fallback?: ImageProps['fallback'];
    fallbackProps?: IconProps;
}

export const Thumb = forwardRef<ThumbProps, 'div'>(
    ({ src, size = 10, fallback, fallbackProps, children, alt = 'thumb', ...rest }, ref) => (
        <Center
            ref={ref}
            overflow="hidden"
            layerStyle="defaultBorder"
            bg="white"
            boxSize={size}
            {...rest}
        >
            <Image
                src={src}
                alt={alt}
                fallback={fallback ?? <ThumbDefaultIcon w="65%" h="65%" {...fallbackProps} />}
            />
            {children}
        </Center>
    ),
);
