import { useCallback } from 'react';
import {
    FieldArrayPath,
    FieldValues,
    UseFieldArrayProps,
    UseFieldArrayReturn,
    UseFormGetValues,
    useFieldArray,
} from 'react-hook-form';
import { uuidv4 } from '@shared/utils/uuid';

// todo usage with 'memo' https://www.kripod.dev/blog/fixing-generics-in-react/

export interface FieldArrayApiProps<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
> extends UseFieldArrayProps<TFieldValues, TFieldArrayName, TKeyName> {
    getValues: UseFormGetValues<TFieldValues>;
    children: (
        api: { copy: (index: number) => void } & UseFieldArrayReturn<
            TFieldValues,
            TFieldArrayName,
            TKeyName
        >,
    ) => JSX.Element;
}

export const FieldArrayApi = <
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
>({
    name,
    getValues,
    children,
    ...rest
}: FieldArrayApiProps<TFieldValues, TFieldArrayName, TKeyName>) => {
    const { append, ...api } = useFieldArray({ keyName: 'uuid', name, ...rest });

    const copy = useCallback(
        (index: number) => {
            const values = getValues();
            const source = values[name][index];
            append({
                ...source,
                id: uuidv4(),
            });
        },
        [getValues, append, name],
    );

    return children({ append, copy, ...api });
};

export type FieldArrayApiActions<
    TFieldValues extends FieldValues = FieldValues,
    TFieldArrayName extends FieldArrayPath<TFieldValues> = FieldArrayPath<TFieldValues>,
    TKeyName extends string = 'id',
> = Omit<
    Parameters<FieldArrayApiProps<TFieldValues, TFieldArrayName, TKeyName>['children']>[0],
    'fields'
>;
