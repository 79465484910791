/*
    Based on codegen.ts
*/

import { dfApi as api } from '@services/df/baseApi';
import type {
    // material
    ThreeAxisMaterialRequest,
    ThreeDpMaterialRequest,
    CutterMaterialRequest,
    FdmMaterialRequest,
    HpmjfMaterialRequest,
    MultiAxisMaterialRequest,
    MultijetMaterialRequest,
    PolyjetMaterialRequest,
    SheetmetalMaterialRequest,
    SlaMaterialRequest,
    SlmMaterialRequest,
    SlsMaterialRequest,
    TurningMaterialRequest,

    // machine
    BaseCncMachineRequest,
    ThreeDPrinterRequest,
    FdmRequest,
    SlshpmjfPrinterRequest,
    PolyjetMultijetSlaPrinterRequest,
    SlmPrinterRequest,

    // response data
    CncPriceTweakerData,
    CutterPriceTweakerData,
    SheetmetalPriceTweakerData,
    TurningPriceTweakerData,
    AdditivePriceTweakerData,

    // nested entities
    FeatureTypeRequest,
    TweakerBendRequest,
} from './codegen';
import { getMachineSlugByTechnology } from '../machines-materials/helpers';

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        priceTweakerCreate: build.mutation<PriceTweakerCreateApiResponse, PriceTweakerCreateApiArg>(
            {
                query: queryArg => ({
                    url: `/v2/price_tweaker/${getMachineSlugByTechnology(queryArg.technologyId, {
                        interchangeable4Milling: true,
                    })}/`,
                    method: 'POST',
                    body: queryArg.request,
                }),
            },
        ),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type PriceTweakerCreateApiResponse = /** status 200  */ PriceTweakerResponse;
export type PriceTweakerCreateApiArg = {
    request: PriceTweakerRequest;
    technologyId: number;
};

type PriceTweakerDataCombined = CncPriceTweakerData & // Milling
    CutterPriceTweakerData &
    SheetmetalPriceTweakerData &
    TurningPriceTweakerData &
    AdditivePriceTweakerData;

export type PriceTweakerRequest = {
    object_model_id: number;
    price_config: {
        [key: string]: any;
    };
    material: PriceTweakerMaterial;
    printer: PriceTweakerPrinter;
};

type _PriceTweakerMaterial = ThreeAxisMaterialRequest &
    ThreeDpMaterialRequest &
    CutterMaterialRequest &
    FdmMaterialRequest &
    HpmjfMaterialRequest &
    MultiAxisMaterialRequest &
    MultijetMaterialRequest &
    PolyjetMaterialRequest &
    SheetmetalMaterialRequest &
    SlaMaterialRequest &
    SlmMaterialRequest &
    SlsMaterialRequest &
    TurningMaterialRequest;

export type PriceTweakerMaterial = _PriceTweakerMaterial & {
    // work_hardening_exponent?: number;
    price_per_kilogram?: number;
    // price_per_sq_cm?: number;
    cost_of_jigs_and_fixtures?: number;
    time_of_programming_recurring?: number;
    time_of_programming_non_recurring?: number;
    time_of_programming_per_surface_area_recurring?: number;
    time_of_programming_per_surface_area_non_recurring?: number;
    time_of_programming_per_surface_area_exponent?: number;

    // milling
    time_of_programming_per_face_non_recurring?: number;
    time_of_programming_per_face_recurring?: number;
    time_of_programming_per_volume_bb_non_recurring?: number;
    time_of_programming_per_volume_bb_recurring?: number;
    time_of_programming_per_volume_bb_exponent?: number;
    time_of_programming_per_subtracted_material_non_recurring?: number;
    time_of_programming_per_subtracted_material_recurring?: number;
    time_of_programming_per_subtracted_material_exponent?: number;

    // cutter & sheetmetal
    // time_of_programming_per_cm_of_perimeter_non_recurring?: number;
    // time_of_programming_per_cm_of_perimeter_recurring?: number;
    // time_of_programming_per_cm_of_perimeter_exponent?: number;
    // time_of_programming_per_area_bb_rectangle_non_recurring?: number;
    // time_of_programming_per_area_bb_rectangle_recurring?: number;
    // time_of_programming_per_area_bb_rectangle_exponent?: number;
};

type _PriceTweakerPrinter = BaseCncMachineRequest &
    ThreeDPrinterRequest &
    FdmRequest &
    SlshpmjfPrinterRequest &
    PolyjetMultijetSlaPrinterRequest &
    SlmPrinterRequest;

export type PriceTweakerPrinter = _PriceTweakerPrinter & {
    rate_of_operator?: number;
    rate_of_programmer?: number;
    time_per_clamp?: number;
    features_operator_involvement?: number;
    machining_operator_involvement?: number;
    // features?: FeatureTypeRequest[];
};

export type PriceTweakerResponse = {
    success: boolean;
    errors?: any[];
    data?: PriceTweakerDataCombined;
};

export const { usePriceTweakerCreateMutation } = injectedRtkApi;
