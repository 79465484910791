import { FetchBaseQueryMeta } from '@services/df/baseApi';
import { isValidUrl } from '../routes';

// for redirect cases in backend view response
export function responseWithRedirect(meta: any) {
    const response = (meta.baseQueryMeta as FetchBaseQueryMeta)?.response;

    return (
        response &&
        isValidUrl(response.request.responseURL) &&
        new URL(response.request.responseURL).pathname !== response.config.url
    );
}
