import uniqueId from 'lodash/uniqueId';
import { createStandaloneToast } from '@chakra-ui/react';
import theme from '@app/styles/theme';
import { formatResponseError } from '@shared/utils/http';
import { Alert } from '@shared/components/alert';
import { Toast } from './types';

// todo add close icon
// todo https://github.com/chakra-ui/chakra-ui/pull/5951/files
export const createToast = ({
    title,
    description,
    id = uniqueId(),
    variant = 'subtle',
    status = 'success',
    styles,
    error,
    isClosable,
    onCloseComplete,
    ...payload
}: Toast) => {
    const hasError = Boolean(error);
    status = hasError ? 'error' : status;
    title = hasError && !title ? 'Errors occurred in:' : title;
    description = hasError && !description ? formatResponseError(error) : description;
    const isErrorAlert = status === 'error';

    const createToast = createStandaloneToast({
        theme,
        defaultOptions: {
            position: 'bottom-right',
            duration: isErrorAlert ? 5000 : 2500,
            isClosable: isErrorAlert,
        },
    });

    createToast({
        ...payload,
        render: ({ id, onClose }) => (
            <Alert
                status={status}
                variant={variant}
                title={title}
                description={description}
                isClosable={isClosable}
                onClose={() => {
                    onClose();
                    !!onCloseComplete && onCloseComplete();
                }}
                {...styles}
            />
        ),
    });
};
