import { withDefault, useQueryParam, StringParam } from 'use-query-params';
import { Box } from '@chakra-ui/react';
import { useAppSelector } from '@app/hooks';
import { selectSettings } from '@ducks/app';
import { PageContainer } from '@shared/components/page-container';
import { withBaseErrorBoundary } from '@shared/components/error-boundaries';
import { isAbsoluteUrl } from '@shared/utils/routes';

const dateFilterParam = 'date_filter';

export const AnalyticsDashboardPage = withBaseErrorBoundary(() => {
    const { analytics_dashboard_url } = useAppSelector(selectSettings) || {};
    const [dateFilter] = useQueryParam(dateFilterParam, withDefault(StringParam, undefined));

    if (!analytics_dashboard_url || !isAbsoluteUrl(analytics_dashboard_url)) {
        return null;
    }

    const dashboardUrl = new URL(analytics_dashboard_url);

    if (dateFilter) {
        dashboardUrl.searchParams.append(dateFilterParam, dateFilter);
    }

    return (
        <PageContainer id="analytics_dashboard_page" showAppHeader={false} py={0}>
            <Box as="iframe" src={dashboardUrl.href} w="full" minH="100vh" border="none" />
        </PageContainer>
    );
});
