import { useMediaQuery } from '@chakra-ui/react';

export const useAppSize = () => {
    const [isPhone] = useMediaQuery('(max-width: 479px)');
    const [isTablet] = useMediaQuery('(min-width: 480px)');
    const [isLargeTablet] = useMediaQuery('(min-width: 768px)');
    const [isDesktop] = useMediaQuery('(min-width: 1024px)');
    const [isMediumDesktop] = useMediaQuery('(min-width: 1280px)');
    const [isLargeDesktop] = useMediaQuery('(min-width: 1536px)');

    return {
        isPhone,
        isTablet,
        isLargeTablet,
        isDesktop,
        isMediumDesktop,
        isLargeDesktop,
    };
};
