import React from 'react';
import { HStack, StackProps, Link, useClipboard, WrapItem, Wrap } from '@chakra-ui/react';
import { forwardRef } from '@chakra-ui/system';
import { ClipboardButton } from '@shared/components/tooltipped-icon-button';

interface Props extends StackProps {
    link: string;
}

export const LinkWithClipboard = forwardRef<Props, 'div'>(({ link, children, ...rest }, ref) => {
    const clipboard = useClipboard(link);

    return (
        <HStack ref={ref} spacing={3} {...rest}>
            <Link isExternal href={link} variant="highlighted" fontSize="xs">
                {children}
            </Link>
            <ClipboardButton {...clipboard} />
        </HStack>
    );
});

export const renderLinksWithClipboard = (
    links: Array<{ label: string; value: string | null | undefined }>,
) => {
    const list = links
        .map(({ value, label }, index) =>
            value ? (
                <WrapItem key={index}>
                    <LinkWithClipboard link={value}>{label}</LinkWithClipboard>
                </WrapItem>
            ) : null,
        )
        .filter(Boolean);

    return list.length ? (
        <Wrap mt={4} spacing={2}>
            {list}
        </Wrap>
    ) : null;
};
