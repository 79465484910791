import { useEffect, useCallback, useRef } from 'react';
import { useBeforeUnload, unstable_useBlocker as useBlocker } from 'react-router-dom';
import type { BlockerFunction } from '@remix-run/router/router';
// import { useBeforeUnload } from '@shared/hooks';

interface Props {
    hasUnsavedChanges: boolean;
    message?: string;
}

// only one component on the page works

export const FormPrompt = ({
    hasUnsavedChanges,
    message = 'You have unsaved changes, are you sure you want to leave?',
}: Props) => {
    hasUnsavedChanges = hasUnsavedChanges && process.env.NODE_ENV === 'production';

    const onLocationChange: BlockerFunction = useCallback(
        ({ nextLocation }) => {
            if (hasUnsavedChanges) {
                return !window.confirm(message);
            }
            return false;
        },
        [hasUnsavedChanges, message],
    );

    usePrompt({ onLocationChange, hasUnsavedChanges });
    // useBeforeUnload({ message, when: hasUnsavedChanges });
    useBeforeUnload(
        useCallback(
            event => {
                if (hasUnsavedChanges) {
                    event.preventDefault();
                    event.returnValue = message;
                    return message;
                }
            },
            [hasUnsavedChanges, message],
        ),
        { capture: true },
    );

    // todo custom dialog https://www.reddit.com/r/reactjs/comments/17rggfo/comment/k8m9ime/
    //             <AlertDialog
    //                 alertId={composeNodeId(rootId, 'discard_changes_dialog')}
    //                 onCancel={() => blocker.reset()}
    //                 onConfirm={() => blocker.proceed()}
    //                 header="Your changes won’t be saved"
    //                 body="We won’t be able to save your data if you move away from this page."
    //                 cancelButtonText="Cancel"
    //                 confirmButtonText="Discard Changes"
    //                 isOpen={blocker.state === "blocked"}
    //             />

    return null;
};

interface UsePromptProps {
    hasUnsavedChanges: boolean;
    onLocationChange: BlockerFunction;
}

function usePrompt({ onLocationChange, hasUnsavedChanges }: UsePromptProps) {
    const blocker = useBlocker(hasUnsavedChanges ? onLocationChange : false);
    const prevState = useRef(blocker.state);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            blocker.reset();
        }
        prevState.current = blocker.state;
    }, [blocker]);
}
