import { UnknownAction, configureStore } from '@reduxjs/toolkit';
// import { persistStore, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from 'redux-persist';
import { persistStore } from 'redux-persist';
import { createEpicMiddleware } from 'redux-observable';
import { dfApi } from '@services/df/baseApi';
import { injectStoreManager } from '../ducks/helpers';
import { rootEpic } from './epics';
import { appExtraApi } from './helpers';
import { rootReducer } from './reducers';
import { RootState } from './types';
import { listenerMiddleware } from './listenerMiddleware';
import './listeners';

import { ModelsService } from '@services/models.service';

// Based on:
// https://redux.js.org/usage/code-splitting
// https://redux.js.org/faq/code-structure#how-can-i-use-the-redux-store-in-non-component-files

export const storeManager = (({ manualPersist }: { manualPersist: boolean }) => {
    const epicMiddleware = createEpicMiddleware<UnknownAction, UnknownAction, RootState>();

    const store = configureStore({
        reducer: rootReducer,
        // @ts-ignore todo update ts
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
                // serializableCheck: {
                //     ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
                // },
                thunk: {
                    extraArgument: appExtraApi,
                },
            })
                .prepend(listenerMiddleware.middleware)
                .concat(epicMiddleware)
                .concat(dfApi.middleware),
    });

    const persistor = persistStore(store, { manualPersist });

    epicMiddleware.run(rootEpic);
    ModelsService.store = store;
    // setupInterceptors(store);

    let _companyName = '';

    return {
        store,
        persistor,
        persist() {
            if (this.bootstrapped || !manualPersist) return;

            persistor.persist(); // sync browser storage with redux store
        },
        get bootstrapped() {
            const pState = persistor.getState();
            return pState.bootstrapped;
        },
        get companyName() {
            return _companyName;
        },
        set companyName(company: string) {
            _companyName = company;
        },
    };
})({ manualPersist: true });

injectStoreManager(storeManager);
