import FocusLock from 'react-focus-lock';
import {
    Divider,
    Text,
    Popover,
    PopoverHeader,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    PopoverProps,
    useDisclosure,
} from '@chakra-ui/react';
import { FCC } from '@shared/types';
import { SystemMessagesList } from './system-messages-list';

interface Props extends PopoverProps {
    code?: string;
    isPaid?: boolean;
    isTbd?: boolean;
    label?: string;
}

export const SystemMessagesPopover: FCC<Props> = ({
    children,
    code,
    isPaid,
    isTbd,
    label = 'Your Notifications',
    ...rest
}) => {
    const { onOpen, onClose, isOpen } = useDisclosure();

    return (
        <Popover
            placement="bottom-end"
            isLazy
            onOpen={onOpen}
            onClose={onClose}
            isOpen={isOpen}
            {...rest}
        >
            <PopoverTrigger>{children}</PopoverTrigger>
            <Portal>
                <PopoverContent maxW="340px" w="auto" borderRadius="12">
                    <FocusLock returnFocus persistentFocus={false}>
                        <PopoverHeader px={6} pt={6} textStyle="typography-lg" fontWeight="600">
                            <span>Your notifications</span>
                            <Divider mt={4} borderColor="neutral.subtle" />
                        </PopoverHeader>
                        <PopoverBody px={6} pt={4} pb={6} maxH="550px" overflowY="auto">
                            <SystemMessagesList />
                        </PopoverBody>
                    </FocusLock>
                </PopoverContent>
            </Portal>
        </Popover>
    );
};
