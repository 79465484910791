import { Icon, IconProps } from '@chakra-ui/react';

export const MaximizeIcon = (props: IconProps) => (
    <Icon viewBox="0 0 24 24" fill="none" {...props}>
        <path
            stroke="currentColor"
            strokeWidth="2"
            d="M15 3h6v6M14 10l6.1-6.1M9 21H3v-6M10 14l-6.1 6.1"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
