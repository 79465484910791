import { Icon, IconProps } from '@chakra-ui/react';

export const BarMatchingIcon = (props: IconProps) => (
    <Icon viewBox="0 0 16 16" fill="none" {...props}>
        <path
            d="M12 12.6667V6M8 12.6667V2M4 12.6667V8.66669"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </Icon>
);
