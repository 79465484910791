import * as Sentry from '@sentry/react';
import { ReactNode, useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useIntercom } from 'react-use-intercom';
import { storeManager } from '@app/store';
import { useAppSelector } from '@app/hooks';
import {
    selectAppInitialSuccess,
    selectIsAuthenticated,
    selectShouldLogin,
    selectUserPermissions,
} from '@entities';
import { useBackofficeSettingsRetrieveQuery } from '@services/df/app';
import { useAuthQuery } from '@services/df/auth';
import { useClientsMeRetrieveQuery } from '@services/df/clients';
import { PreloaderBox } from '@shared/components/preloader';
import { ROUTES } from '@shared/constants/routes';
import { Guide } from '../guide';

interface Props {
    children: ReactNode;
}

// todo AppContainer => CompanyContainer
// todo replace it with AuthRoute after moving auth to LS with token
// todo createBrowserRouter loader prop
export const AppContainer = ({ children }: Props) => {
    const { update } = useIntercom();
    const shouldLogin = useAppSelector(selectShouldLogin);
    const isAuthenticated = useAppSelector(selectIsAuthenticated);
    const appInitialSuccess = useAppSelector(selectAppInitialSuccess);
    const { userIsAdminOrSalesman } = useAppSelector(selectUserPermissions);

    const { isLoading: isTokenObtaining } = useAuthQuery();
    const { isLoading: isAppLoading, data: settings } = useBackofficeSettingsRetrieveQuery(
        undefined,
        {
            skip: !isAuthenticated,
        },
    );
    const { isLoading: isUserLoading, data: user } = useClientsMeRetrieveQuery(undefined, {
        skip: !isAuthenticated,
    });

    useEffect(() => {
        const shouldUpdateIntercom = [
            appInitialSuccess,
            process.env.REACT_APP_WITH_SIDEBAR,
            user,
            userIsAdminOrSalesman,
            settings,
        ].every(Boolean);

        if (!shouldUpdateIntercom) return;

        update({
            name: user!.full_name,
            email: user!.email,
            company: { companyId: settings!.company_title, name: settings!.company_full_title },
        });
    }, [appInitialSuccess, settings, user, userIsAdminOrSalesman, update]);

    const companyName = settings?.company_title;

    useEffect(() => {
        if (companyName) {
            Sentry.setTag('company', companyName);
            storeManager.companyName = companyName;
            storeManager.persist();
        }
    }, [companyName]);

    if (isAppLoading || isUserLoading || isTokenObtaining) {
        return <PreloaderBox in />;
    }

    if (shouldLogin) {
        return <Navigate to={ROUTES.SIGN_IN} replace />;
    }

    if (!isAuthenticated || !settings || !user) {
        return null;
    }

    return (
        <>
            {process.env.NODE_ENV === 'production' && <Guide />}
            {children}
        </>
    );
};
