import { OrderStatus } from '@services/df/codegen-enums';

export enum OrderCommercialDocumentType {
    Invoice = 'invoice',
    FirmOffer = 'firm_offer',
    BudgetOffer = 'budget_offer',
    OrderConfirmation = 'order_confirmation',
    Proforma = 'proforma',
    PoPurchase = 'po_purchase',
    TaxCompliantInvoice = 'tax_compliant_invoice',
}

export enum OrderProductionDocumentType {
    PackingList = 'packing_list',
    ProductionTraveler = 'production_traveler',
}

export enum OrderPaymentStatusType {
    Paid = 'paid',
    Unpaid = 'unpaid',
}

export enum OrderDeliveryType {
    EasyPost = 'ep',
    Custom = 'custom',
    NoDelivery = 'no_delivery',
}

// degree of completion
export enum OrderStage {
    Accomplished = 'accomplished',
    Drafts = 'drafts',
    Carts = 'carts',
}

export const ORDER_STAGE_STATUSES_MAP = {
    [OrderStage.Accomplished]: null, // all other statuses
    [OrderStage.Drafts]: [OrderStatus.Created],
    [OrderStage.Carts]: [OrderStatus.Initial],
};

export type OrderDocumentParams = Record<string, number | string | undefined>;
