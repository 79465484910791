import map from 'lodash/map';
import { createSelector, createSlice } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '@app/types';
import { NullableRecord } from '@shared/types';
import { MachineGroup } from '@services/df/machines-materials';
import { EventOrigin, TechnologyIdsUnion } from '@shared/constants';
import { selectMachinesMaterials } from '@ducks/machines-materials';
// import { createPersistReducer } from '../helpers';
import { priceTweakerFetchModels } from './models';

export type PriceTweakerConfig = NullableRecord<{
    modelId?: number;
    technologyId?: TechnologyIdsUnion;
    materialId?: number;
}>;

const initialState: PriceTweakerConfig & { modelsIds: number[] } = { modelsIds: [] };

const configSlice = createSlice({
    name: 'priceTweaker/config',
    initialState,
    reducers: create => ({
        update: create.preparedReducer(
            (payload: PriceTweakerConfig, eventOrigin: EventOrigin) => ({
                payload,
                meta: { eventOrigin },
            }),
            (state, action) => {
                Object.assign(state, action.payload);
            },
        ),
        reset() {
            return initialState;
        },
    }),
    extraReducers: builder => {
        builder.addCase(priceTweakerFetchModels.fulfilled, (state, { meta, payload }) => {
            switch (meta.arg.case) {
                case 'refetch': {
                    return;
                }
                case 'set': {
                    state.modelsIds = map(payload, 'id');
                    break;
                }
                case 'add': {
                    state.modelsIds?.push(...map(payload, 'id'));
                }
            }

            // select model on confirm models from library
            if (!payload.find(model => model.id === state.modelId)) {
                state.modelId = payload[0].id;
            }
        });
    },
});

// we remove the configuration from LS because PT is used for different places,
// and we want to avoid conflicts when opening PT on different pages, better to skip persistence

// export const configReducer = createPersistReducer(configSlice, {
//     dependsOnCompany: true,
// });
export const configReducer = configSlice.reducer;

export const { update: priceTweakerUpdateConfig, reset: priceTweakerResetConfig } =
    configSlice.actions;

export const selectPriceTweakerConfig = (state: RootState) => state.priceTweaker.config;
export const selectPriceTweakerModelsIds = (state: RootState) =>
    state.priceTweaker.config.modelsIds;
export const selectPriceTweakerModelId = (state: RootState) => state.priceTweaker.config.modelId;
export const selectPriceTweakerTechnologyId = (state: RootState) =>
    state.priceTweaker.config.technologyId;
export const selectPriceTweakerMaterialId = (state: RootState) =>
    state.priceTweaker.config.materialId;

export const selectPriceTweakerMachine = createSelector(
    selectPriceTweakerMaterialId,
    selectMachinesMaterials,
    (preselectedMaterialId, machines) => {
        if (!machines) return;

        for (const machine of machines) {
            const { materials, ...rest } = machine as MachineGroup & {
                technology: TechnologyIdsUnion;
            };
            const material = materials.find(material => {
                return material.id === preselectedMaterialId;
            });

            if (material) {
                return {
                    ...rest,
                    material,
                };
            }
        }
    },
);

export const initPriceTweakerModels = (forcedModelId?: number): AppThunk => {
    return (dispatch, getState) => {
        if (forcedModelId) {
            dispatch(priceTweakerFetchModels({ ids: [forcedModelId], case: 'set' }));
        } else {
            // fetch stored
            const state = getState();
            const storedIds = selectPriceTweakerModelsIds(state);
            storedIds?.length &&
                dispatch(priceTweakerFetchModels({ ids: storedIds, case: 'refetch' }));
        }
    };
};
