import { Layout } from '@shared/components/layout';

/**
 * ✅ FSD Best practice
 *
 * (1) Divide layout in two modules: dumb layout grid (shared)
 * and smart layout with widgets (this file)
 *
 * (2) Avoid cross-import using slot (render prop) pattern
 * Pass widgets as props to layout
 */
export const baseLayout = <Layout />;
