import { Icon, IconProps } from '@chakra-ui/react';

// This icon is looks like a diagonal Cross

export const CloseIcon = (props: IconProps) => (
    <Icon viewBox="0 0 12 12" fill="none" {...props}>
        <path d="M9 3L3 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
        <path d="M3 3L9 9" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" />
    </Icon>
);
