import { ReactNode } from 'react';
import {
    Alert as AlertChakra,
    AlertDescription,
    AlertIcon,
    AlertProps,
    AlertTitle,
    Box,
    CloseButton,
    UseToastOptions,
} from '@chakra-ui/react';
import { FCC } from '@shared/types';
import { AlertTriangleIcon, InfoIcon } from '@shared/components/icons';

const STATUSES = {
    info: { icon: InfoIcon, colorScheme: 'primary' },
    // success: { icon: CheckCircleIcon, colorScheme: 'success' },
    success: { icon: undefined, colorScheme: 'success' },
    error: { icon: AlertTriangleIcon, colorScheme: 'error' },
    warning: { icon: AlertTriangleIcon, colorScheme: 'warning' },
};

interface Props extends Omit<AlertProps, 'status' | 'title'> {
    status?: UseToastOptions['status'];
    variant?: UseToastOptions['variant'];
    title?: UseToastOptions['title'];
    isClosable?: UseToastOptions['isClosable'];
    onClose?: UseToastOptions['onCloseComplete'];
    description?: ReactNode;
}

export const Alert: FCC<Props> = ({
    variant = 'subtle',
    status = 'error',
    title: _title,
    description: _description,
    isClosable,
    onClose,
    children,
    ...rest
}) => {
    const colorScheme = STATUSES[status].colorScheme;
    const icon = STATUSES[status].icon;
    const title = _title && <AlertTitle>{_title}</AlertTitle>;
    const description = _description && <AlertDescription>{_description}</AlertDescription>;
    const close = isClosable && (
        <CloseButton
            className="chakra-alert__close-icon"
            size="sm"
            alignSelf="flex-start"
            position="relative"
            right={-2}
            // top={-2}
            onClick={onClose}
        />
    );

    return (
        <AlertChakra variant={variant} status={status} colorScheme={colorScheme} {...rest}>
            <AlertIcon as={icon} />
            <Box flexGrow={1}>
                {title}
                {description}
                {children}
            </Box>
            {close}
        </AlertChakra>
    );
};
