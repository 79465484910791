import { filter, from, switchMap, withLatestFrom, of, catchError, Observable, EMPTY } from 'rxjs';
import { combineEpics } from 'redux-observable';
import { Action } from '@reduxjs/toolkit';
import { AppEpic } from '@app/types';
import { PriceMatchingService } from '@services/price-matching.service';
import { redirectRouter } from '@ducks/app';
import { selectPriceMatchLayerThickness, selectPriceMatchModelsIds } from '@ducks/price-match';
import {
    recalculatePrices,
    recalculatePricesSuccess,
    recalculatePricesFailure,
    acceptValuesFailure,
    acceptValuesSuccess,
    acceptValues,
} from './reducers';
import {
    selectFields,
    selectFeatures,
    selectLayerThickness,
    selectComplexityBracketing,
    selectPriceMatchMachine,
} from './selectors';
import { isBelong3D } from '@shared/utils/technology-guard';
import { getFlowCNC$, getFlowSupport3D$, getFlowSupportless3D$ } from './models';
import { Technologies, TECHNOLOGY_SLUG_MAP } from '@shared/constants';
import { AxiosResponse } from 'axios';
import { MaterialExecution } from '@shared/types';
import { enhancedApi as api } from '@services/df/machines-materials';
import { ROUTES } from '@shared/constants/routes';

const priceMatchRecalculateEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(recalculatePrices.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const model_ids = selectPriceMatchModelsIds(state);
            const layer_thickness = selectPriceMatchLayerThickness(state)!;
            const { technology, material } = selectPriceMatchMachine(state)!;

            const data = isBelong3D(technology)
                ? {
                      material_id: material.id,
                      model_ids,
                      fields: {
                          ...action.payload,
                      },
                      layer_thickness,
                  }
                : {
                      material_id: material.id,
                      model_ids,
                      fields: {
                          ...action.payload,
                      },
                  };

            return from(PriceMatchingService.init().recalculatePriceMatch(data, technology)).pipe(
                switchMap(({ data }) => of(recalculatePricesSuccess(data))),
                catchError(() => of(recalculatePricesFailure())),
            );
        }),
    );

const priceMatchAcceptValuesByTechnologyEpic: AppEpic = (action$, state$) =>
    action$.pipe(
        filter(acceptValues.match),
        withLatestFrom(state$),
        switchMap(([action, state]) => {
            const defaultValues = selectFields(state);
            const { technology, id, material } = selectPriceMatchMachine(state)!;
            const name = TECHNOLOGY_SLUG_MAP[technology];

            let flow$: Observable<AxiosResponse[]> = EMPTY;

            if (
                technology === Technologies['Multi-Axis Milling'] ||
                technology === Technologies['4-Axis Milling'] ||
                technology === Technologies['3-Axis Milling'] ||
                technology === Technologies['Turning']
            ) {
                flow$ = getFlowCNC$({
                    name,
                    machineId: id,
                    materialId: material.id,
                    values: action.payload,
                    features: selectFeatures(state)!,
                    bracketing: selectComplexityBracketing(state)!,
                    defaultValues,
                });
            }

            if (
                technology === Technologies['SLM'] ||
                technology === Technologies['SLA'] ||
                technology === Technologies['FDM']
            ) {
                flow$ = getFlowSupport3D$({
                    name,
                    machineId: id,
                    materialId: material.id,
                    values: action.payload,
                    layerThickness: selectLayerThickness(
                        state,
                    )! as MaterialExecution<'layer_thickness'>[],
                    nominal_layer_thickness: selectPriceMatchLayerThickness(state),
                });
            }

            if (
                technology === Technologies['HP MJF'] ||
                technology === Technologies['SLS'] ||
                technology === Technologies['3DP']
            ) {
                flow$ = getFlowSupportless3D$({
                    name,
                    machineId: id,
                    materialId: material.id,
                    values: action.payload,
                    layerThickness: selectLayerThickness(
                        state,
                    )! as MaterialExecution<'layer_thickness'>[],
                    nominal_layer_thickness: selectPriceMatchLayerThickness(state),
                });
            }

            return flow$.pipe(
                switchMap(() =>
                    of(
                        acceptValuesSuccess(),
                        redirectRouter({
                            to: ROUTES.MACHINES_MATERIALS,
                        }),
                    ),
                ),
                catchError(() =>
                    of(
                        acceptValuesFailure(),
                        api.endpoints.materialsExecutionsList.initiate(
                            {
                                materialId: material.id,
                                executionType: 'layer_thickness',
                            },
                            { forceRefetch: true },
                        ) as unknown as Action,
                        api.endpoints.machinesFeaturesList.initiate(
                            {
                                machineId: id,
                            },
                            { forceRefetch: true },
                        ) as unknown as Action,
                        api.endpoints.machinesComplexityBracketingList.initiate(
                            {
                                machineId: id,
                            },
                            { forceRefetch: true },
                        ) as unknown as Action,
                    ),
                ),
            );
        }),
    );

export const priceMatchResultEpics = combineEpics(
    priceMatchRecalculateEpic,
    priceMatchAcceptValuesByTechnologyEpic,
);
