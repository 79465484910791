import { useCallback } from 'react';
import { StringParam, useQueryParams, withDefault } from 'use-query-params';
import { SearchInput, SearchInputProps } from '@shared/components/forms-v2';
import { CurrentPageParam, PaginationQueryParams } from '@shared/components/pagination';

const groupProps = { maxW: '320px' };

export type SearchQueryParamKeys = 'search';
export const SearchQueryParam = withDefault(StringParam, undefined);

interface SearchFilterParams extends Omit<SearchInputProps, 'value' | 'onFinalize'> {
    queryParamKey?: SearchQueryParamKeys;
}

export function SearchFilter({ queryParamKey = 'search', ...rest }: SearchFilterParams) {
    const [query, setQuery] = useQueryParams({
        [queryParamKey]: SearchQueryParam,
        [PaginationQueryParams.PageNumber]: CurrentPageParam,
    });

    const handleFinalizeSearch = useCallback(
        (search: string) => {
            setQuery({
                [queryParamKey]: search || undefined,
                page: 1,
            });
        },
        [setQuery, queryParamKey],
    );

    return (
        <SearchInput
            value={(query[queryParamKey] ?? '') as string}
            onFinalize={handleFinalizeSearch}
            groupProps={groupProps}
            {...rest}
        />
    );
}
