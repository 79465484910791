import { cloneDeep, set } from 'lodash';
import { ReactElement } from 'react';
import { useFormContext } from 'react-hook-form';
import { Link as RouterLink } from 'react-router-dom';
import { FlexProps, HStack, Link, Text, TextProps, VStack } from '@chakra-ui/react';
import { FieldConfigsUnion, FieldHandler } from '@shared/components/forms-v2';
import { ROUTES } from '@shared/constants';

// todo place near address (entities folder)

interface Props {
    title?: string;
    description?: string | ReactElement;
    vbanSwitch: FieldConfigsUnion;
    vbanThreshold?: FieldConfigsUnion;
    props?: FlexProps;
}

export const VbanDescription = (props: TextProps) => (
    <Text textStyle="typography-sm" color="font.secondary" mt="2" {...props}>
        <Text>{`Enable your customers to pay via bank transfers just like they used to.
        We will automatically reconcile your payments and mark orders as paid
        once the funds are credited. If a customer underpays or overpays, their
        balance will be adjusted accordingly. Bank details are displayed as
        Virtual Bank Numbers, powered by Stripe.`}</Text>

        <Text mt={1}>
            {`Works only if Virtual Bank Number transfers are enabled in the `}
            <Link
                as={RouterLink}
                variant="highlighted"
                // todo pfff, hardcoded url, 'payments and taxes' 2 times in app
                //  need separate global constant for settings tabs
                to={`${ROUTES.COMPANY_SETTINGS}#${encodeURIComponent('payments and taxes')}`}
            >
                settings.
            </Link>
        </Text>
    </Text>
);

// todo VbanBox, check ToggleBox
export function VbanField({ title, description, vbanSwitch, vbanThreshold, ...props }: Props) {
    // todo useVbanField, move to parent, check useAddressStateField
    const { getValues } = useFormContext();
    const isDisabled = !getValues('vban_payment_enabled');

    const vbanThresholdEnriched = cloneDeep(vbanThreshold);
    if (vbanThresholdEnriched) {
        set(vbanThresholdEnriched, 'props.disabled', isDisabled);
        set(vbanThresholdEnriched, 'props.labelProps', {
            color: isDisabled ? 'gray.600' : '',
        });
        set(vbanThresholdEnriched, 'props.groupProps', {
            opacity: isDisabled ? '0.5' : 1,
        });
    }

    return (
        <VStack
            // todo check getLayerStyles
            w="full"
            p="16px"
            alignItems="flex-start"
            gap="4"
            borderRadius="12px"
            backgroundColor="primary.subtlest"
            {...props}
        >
            <HStack w="full" align="flex-start" gap="4">
                <HStack>
                    <VStack alignItems="flex-start">
                        {title && (
                            <Text as="h5" color="font.100">
                                {title}
                            </Text>
                        )}
                        {description &&
                            (typeof description === 'string' ? (
                                <Text
                                    textStyle="typography-sm"
                                    color="gray.600"
                                    textAlign="justify"
                                >
                                    {description}
                                </Text>
                            ) : (
                                description
                            ))}
                    </VStack>
                </HStack>
                {/* todo check ToggleBox */}
                <FieldHandler field={vbanSwitch} />
            </HStack>
            {/* todo pass as children */}
            {vbanThresholdEnriched && <FieldHandler field={vbanThresholdEnriched} />}
        </VStack>
    );
}
