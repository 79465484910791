import isString from 'lodash/isString';
import { ReactNode } from 'react';
import { Box, BoxProps, HStack, Text } from '@chakra-ui/react';
import { SectionAccentTile } from '@shared/components/section';
import { Hint } from '@shared/components/forms-v2';
import { FCC } from '@shared/types';

interface Props extends BoxProps {
    toggle: ReactNode;
    label: ReactNode;
    tooltip?: string;
}

export const ToggleBox: FCC<Props> = ({ toggle, label, tooltip, children, ...props }) => {
    const _label = isString(label) ? (
        <Text textStyle="typography-md" color="secondary.subtle">
            {label}
        </Text>
    ) : (
        label
    );

    return (
        <SectionAccentTile w="full" bgColor="primary.subtlest" {...props}>
            <HStack justifyContent="space-between">
                <HStack>
                    {_label}
                    {!!tooltip && <Hint label={tooltip} />}
                </HStack>

                {toggle}
            </HStack>

            {children}
        </SectionAccentTile>
    );
};
