import React from 'react';
import { HStack } from '@chakra-ui/react';
import { useAppSelector } from '@app/hooks';
import { selectUserPermissions } from '@entities';
import { SystemMessagesButton } from '@ducks/system-messages';

// todo refactor
export const AppHeader = () => {
    const { userIsAdminOrSalesman } = useAppSelector(selectUserPermissions);
    return userIsAdminOrSalesman ? (
        <HStack mb={5}>
            <SystemMessagesButton ml="auto" />
        </HStack>
    ) : null;
};
