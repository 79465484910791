import { createEntityAdapter } from '@reduxjs/toolkit';
import { ModelSerializerV2 } from '@services/df/models';
import { AppExtraApi, RootState } from '@app/types';
import { createAppSlice } from '@app/helpers';

const modelsAdapter = createEntityAdapter<ModelSerializerV2>({});

export const modelsSlice = createAppSlice({
    name: 'priceMatch/models',
    initialState: modelsAdapter.getInitialState({ isLoading: false }),
    reducers: create => {
        const createAThunk = create.asyncThunk.withTypes<{
            extra: AppExtraApi;
        }>();

        return {
            removeOne: create.reducer(modelsAdapter.removeOne),
            removeAll: create.reducer(modelsAdapter.removeAll),
            fetch: createAThunk(
                async (
                    { ids }: { ids: number[]; case: 'refetch' | 'set' | 'add' },
                    { signal, extra: { ModelsService } },
                ) => {
                    const { data } = await ModelsService.init().loadUsersModelsList(
                        { id: ids },
                        { signal },
                    );
                    return data.results;
                },
                {
                    pending: state => {
                        state.isLoading = true;
                    },
                    fulfilled: (state, action) => {
                        action.meta.arg.case === 'set'
                            ? modelsAdapter.setAll(state, action.payload)
                            : modelsAdapter.upsertMany(state, action.payload);
                    },
                    settled: (state, action) => {
                        state.isLoading = false;
                    },
                },
            ),
        };
    },
});

export const {
    removeOne: priceMatchRemoveModel,
    removeAll: priceMatchRemoveModels,
    fetch: priceMatchFetchModels,
} = modelsSlice.actions;

export const selectPriceMatchModelsIsLoading = (state: RootState) =>
    state.priceMatch.models.isLoading;

export const {
    selectTotal: selectPriceMatchModelsTotal,
    selectAll: selectPriceMatchModels,
    selectEntities: selectPriceMatchModelsEntities,
    selectById: selectPriceMatchModelById,
} = modelsAdapter.getSelectors((state: RootState) => state.priceMatch.models);
