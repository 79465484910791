import isUndefined from 'lodash/isUndefined';
import { PatchedPurchaseUpdateBackOfficeSerializerV2Request } from '@services/df/orders';
import { toCoefficient } from '@shared/utils/strings';

export const formatPurchasePayload = (
    _data: PatchedPurchaseUpdateBackOfficeSerializerV2Request,
) => {
    const data = { ..._data };

    // the backend is waiting for the coefficients, and in the form we have percentages
    if (!isUndefined(data.discount_rate)) {
        data.discount_rate = toCoefficient(data.discount_rate).value;
    }

    return data;
};
