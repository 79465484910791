import React, { useRef } from 'react';
import { withBaseErrorBoundary } from '@shared/components/error-boundaries';
import { PreloaderBox } from '@shared/components/preloader';
import { createToast as toast } from '@shared/components/toast';
import {
    useAuthObtainCompaniesCreateMutation,
    useAuthCreateSessionWithTokenCreateMutation,
} from '@services/df/auth';
import { AuthPageLayout, AuthSection, AuthSignInForm, SignInForm } from '../ui';
import { SignInHead } from './sign-in-head';
import { SignInCompanies } from './sign-in-companies';

const useCompanySignIn = () => {
    const form = useRef<SignInForm>();

    const [_getCompanies, { data: companies, isLoading: isCompaniesLoading }] =
        useAuthObtainCompaniesCreateMutation();
    const [_signInCompany, { isLoading: isTokenLoading }] =
        useAuthCreateSessionWithTokenCreateMutation();

    const signInCompany = async (company_name: string, _form?: SignInForm) => {
        const credentials = _form || form.current;

        if (!credentials) return;

        await _signInCompany({
            obtainTokenRequestRequest: {
                company_name,
                ...credentials,
            },
        }).unwrap();
    };

    const getCompanies = async (_form: SignInForm) => {
        const companies = await _getCompanies({ obtainCompaniesRequestRequest: _form }).unwrap();

        if (!companies.length) {
            toast({
                status: 'error',
                position: 'top-right',
                description: 'Sorry, seems you are not a manager of any company',
                isClosable: true,
            });
        } else if (companies.length === 1) {
            await signInCompany(companies?.[0].company_short_name, _form);
        } else {
            form.current = _form;
        }

        return companies;
    };

    const isCompaniesStep = companies && companies.length > 1;

    return {
        isCompaniesStep,
        isCompaniesLoading,
        isTokenLoading,
        getCompanies,
        signInCompany,
        companies,
    };
};

export const SignInPage = withBaseErrorBoundary(() => {
    const {
        isCompaniesStep,
        isCompaniesLoading,
        isTokenLoading,
        getCompanies,
        signInCompany,
        companies,
    } = useCompanySignIn();

    return (
        <AuthPageLayout id="sign_in_page" head={<SignInHead />}>
            <AuthSection header="Sign in to back-office">
                {isCompaniesStep ? (
                    <PreloaderBox in={isTokenLoading}>
                        <SignInCompanies
                            companies={companies!}
                            onCompanyClick={signInCompany}
                            isLoading={isTokenLoading}
                        />
                    </PreloaderBox>
                ) : (
                    <AuthSignInForm
                        formId="auth_sign_in_form"
                        request={getCompanies}
                        isSubmitting={isCompaniesLoading}
                    />
                )}
            </AuthSection>
        </AuthPageLayout>
    );
});
