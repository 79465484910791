import { Fragment } from 'react';
import { Divider, Flex, Link, Text, VStack } from '@chakra-ui/react';
import { Model } from '@services/df/orders';
import { DownloadCloudIcon } from '@shared/components/icons';
import { getOrderPurchaseModelData } from './helpers';
import { ModelLink } from './model-details-model-link';
import { COLUMN_WIDTH, ROW_STYLES } from './constants';

interface ModelDetailsProps {
    model: Model;
    isAbleToShowRelatedModels: boolean;
}

export function ModelDetails({ model, isAbleToShowRelatedModels }: ModelDetailsProps) {
    const { title, values, labels } = getOrderPurchaseModelData(model);

    const LabelComponent = ({ label }: { label: string }) => {
        return (
            <Text as="h5" maxW={COLUMN_WIDTH} flexGrow={1} fontSize="sm" color="neutral.darkest">
                {label}
            </Text>
        );
    };

    return (
        <>
            <Text as="h4" maxW={{ base: '200px', md: '330px' }} fontWeight="600" fontSize="lg">
                {title}
            </Text>
            <Flex flexDir="column" rowGap="15px">
                {Object.entries(values).map(([key, value]) => {
                    if (!value) return null;
                    switch (key) {
                        case 'status':
                            return (
                                <Flex key={key} {...ROW_STYLES}>
                                    <LabelComponent
                                        label={labels[key as keyof typeof labels]}
                                    ></LabelComponent>
                                    <Flex flexDir="column">
                                        {Object.entries(value).map(([statusKey, statusValue]) => {
                                            if (!statusValue) return null;
                                            return (
                                                <Text key={statusKey} fontSize="sm">
                                                    {statusValue}
                                                </Text>
                                            );
                                        })}
                                    </Flex>
                                </Flex>
                            );
                        case 'author':
                            return (
                                <Fragment key={key}>
                                    <Flex {...ROW_STYLES}>
                                        <LabelComponent
                                            label={labels[key as keyof typeof labels]}
                                        ></LabelComponent>
                                        <Text fontSize="sm">{value as string}</Text>
                                    </Flex>
                                    <Divider />
                                </Fragment>
                            );
                        case 'originalFile':
                        case 'processedFile':
                            return (
                                <Flex key={key} {...ROW_STYLES}>
                                    <LabelComponent
                                        label={labels[key as keyof typeof labels]}
                                    ></LabelComponent>
                                    <Link href={value as string} fontSize="sm">
                                        <DownloadCloudIcon /> Download
                                    </Link>
                                </Flex>
                            );
                        case 'parentModel':
                            return isAbleToShowRelatedModels ? (
                                <Flex key={key} {...ROW_STYLES}>
                                    <LabelComponent
                                        label={labels[key as keyof typeof labels]}
                                    ></LabelComponent>
                                    <ModelLink modelId={value as number} />
                                </Flex>
                            ) : null;
                        case 'childrenModels':
                            return isAbleToShowRelatedModels ? (
                                <Flex key={key} {...ROW_STYLES}>
                                    <LabelComponent
                                        label={labels[key as keyof typeof labels]}
                                    ></LabelComponent>
                                    <VStack alignItems="flex-start">
                                        {(value as number[]).map(id => {
                                            return (
                                                <ModelLink
                                                    key={id as number}
                                                    modelId={id as number}
                                                />
                                            );
                                        })}
                                    </VStack>
                                </Flex>
                            ) : null;
                        default:
                            return (
                                <Flex key={key} {...ROW_STYLES}>
                                    <LabelComponent
                                        label={labels[key as keyof typeof labels]}
                                    ></LabelComponent>
                                    <Text fontSize="sm">{value as string}</Text>
                                </Flex>
                            );
                    }
                })}
            </Flex>
        </>
    );
}
