import isNil from 'lodash/isNil';
import mapValues from 'lodash/mapValues';
import pickBy from 'lodash/pickBy';
import { shallowEqual } from 'react-redux';
import { OrderStatus } from '@services/df/codegen-enums';
import { PatchedOrderUpdateBackOfficeSerializerV2Request } from '@services/df/orders';
import { toCoefficient } from '@shared/utils/strings';
import { objectToFormData } from '@shared/components/forms-v2';
import { OrderStage, ORDER_STAGE_STATUSES_MAP, OrderDocumentParams } from './constants';

const coefficients = ['tax', 'discount_rate', 'down_payment_rate'] as const;

export const formatOrderPayload = (_data: PatchedOrderUpdateBackOfficeSerializerV2Request) => {
    const data = { ..._data };

    // the backend is waiting for the coefficients, and in the form we have percentages
    coefficients.forEach(name => {
        const value = data[name];
        if (!isNil(value)) {
            data[name] = toCoefficient(value).value;
        }
    });

    return data.attached_po ? objectToFormData(data) : data;
};

export const getOrderStage = (...statuses: Array<`${OrderStatus}` | undefined>) => {
    if (shallowEqual(statuses, ORDER_STAGE_STATUSES_MAP.drafts)) {
        return OrderStage.Drafts;
    } else if (shallowEqual(statuses, ORDER_STAGE_STATUSES_MAP.carts)) {
        return OrderStage.Carts;
    } else {
        return OrderStage.Accomplished;
    }
};

export const getOrderStageDetails = (...statuses: Array<`${OrderStatus}` | undefined>) => {
    const orderStage = getOrderStage(...statuses);
    const isDraft = orderStage === OrderStage.Drafts;
    const isAbandoned = orderStage === OrderStage.Carts;
    const isAccomplished = orderStage === OrderStage.Accomplished;

    return { orderStage, isDraft, isAbandoned, isAccomplished };
};

export const formatOrderDocumentUrl = (pdfUrl: string, params: OrderDocumentParams) => {
    const { origin, pathname, search } = new URL(pdfUrl);
    params = pickBy(params, value => !isNil(value));
    const searchParams = new URLSearchParams({
        ...Object.fromEntries(new URLSearchParams(search)),
        ...mapValues(params, String),
    }).toString();

    return searchParams ? origin + pathname + '?' + searchParams : pdfUrl;
};
