/*
    Based on codegen.ts
*/

import { enhancedApi as api, ModelSerializerV2 } from './codegen';

const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        sampleModelsList: build.query<SampleModelsListApiResponse, SampleModelsListApiArg>({
            query: queryArg => ({
                url: `/v2/sample_models/`,
                params: { sample_model_type: queryArg.sampleModelType },
            }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };

export enum SampleModelType {
    PriceTweaker3d = 'price_tweaker_3d',
    PriceTweakerCnc = 'price_tweaker_cnc',
    PreTweaker = 'pre_tweaker',
    ComplexityBracketing = 'complexity_bracketing',
    ComplexityBracketingCutter = 'complexity_bracketing_cutter',
    ComplexityBracketingSheetmetal = 'complexity_bracketing_sheetmetal',
}

export type SampleModelSerializerV2 = ModelSerializerV2 & { sample_model_type: SampleModelType };

export type SampleModelsListApiResponse = /** status 200  */ { items: SampleModelSerializerV2[] };
export type SampleModelsListApiArg = {
    sampleModelType?: SampleModelType;
};

export const { useSampleModelsListQuery } = injectedRtkApi;
