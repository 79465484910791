import { dfApi as api } from '@services/df/baseApi';
const injectedRtkApi = api.injectEndpoints({
    endpoints: build => ({
        organizationsList: build.query<OrganizationsListApiResponse, OrganizationsListApiArg>({
            query: queryArg => ({
                url: `/v2/organizations/`,
                params: {
                    limit: queryArg.limit,
                    offset: queryArg.offset,
                    ordering: queryArg.ordering,
                    search: queryArg.search,
                },
            }),
        }),
        organizationsCreate: build.mutation<
            OrganizationsCreateApiResponse,
            OrganizationsCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/`,
                method: 'POST',
                body: queryArg.organizationCreateRequest,
            }),
        }),
        organizationsAddressesList: build.query<
            OrganizationsAddressesListApiResponse,
            OrganizationsAddressesListApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.organizationId}/addresses/`,
                params: { limit: queryArg.limit, offset: queryArg.offset },
            }),
        }),
        organizationsAddressesCreate: build.mutation<
            OrganizationsAddressesCreateApiResponse,
            OrganizationsAddressesCreateApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.organizationId}/addresses/`,
                method: 'POST',
                body: queryArg.createOrganizationAddressRequest,
            }),
        }),
        organizationsAddressesRetrieve: build.query<
            OrganizationsAddressesRetrieveApiResponse,
            OrganizationsAddressesRetrieveApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.organizationId}/addresses/${queryArg.id}/`,
            }),
        }),
        organizationsAddressesPartialUpdate: build.mutation<
            OrganizationsAddressesPartialUpdateApiResponse,
            OrganizationsAddressesPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.organizationId}/addresses/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedOrganizationAddressRequest,
            }),
        }),
        organizationsAddressesDestroy: build.mutation<
            OrganizationsAddressesDestroyApiResponse,
            OrganizationsAddressesDestroyApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.organizationId}/addresses/${queryArg.id}/`,
                method: 'DELETE',
            }),
        }),
        organizationsRetrieve: build.query<
            OrganizationsRetrieveApiResponse,
            OrganizationsRetrieveApiArg
        >({
            query: queryArg => ({ url: `/v2/organizations/${queryArg.id}/` }),
        }),
        organizationsPartialUpdate: build.mutation<
            OrganizationsPartialUpdateApiResponse,
            OrganizationsPartialUpdateApiArg
        >({
            query: queryArg => ({
                url: `/v2/organizations/${queryArg.id}/`,
                method: 'PATCH',
                body: queryArg.patchedOrganizationUpdateRequest,
            }),
        }),
        organizationsDestroy: build.mutation<
            OrganizationsDestroyApiResponse,
            OrganizationsDestroyApiArg
        >({
            query: queryArg => ({ url: `/v2/organizations/${queryArg.id}/`, method: 'DELETE' }),
        }),
        organizationsSuggestList: build.query<
            OrganizationsSuggestListApiResponse,
            OrganizationsSuggestListApiArg
        >({
            query: () => ({ url: `/v2/organizations/suggest/` }),
        }),
    }),
    overrideExisting: false,
});
export { injectedRtkApi as enhancedApi };
export type OrganizationsListApiResponse = /** status 200  */ PaginatedOrganizationList;
export type OrganizationsListApiArg = {
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    /** Which field to use when ordering the results. */
    ordering?: string;
    /** A search term. */
    search?: string;
};
export type OrganizationsCreateApiResponse = /** status 201  */ Organization;
export type OrganizationsCreateApiArg = {
    organizationCreateRequest: OrganizationCreateRequest;
};
export type OrganizationsAddressesListApiResponse =
    /** status 200  */ PaginatedOrganizationAddressList;
export type OrganizationsAddressesListApiArg = {
    /** Number of results to return per page. */
    limit?: number;
    /** The initial index from which to return the results. */
    offset?: number;
    organizationId: number;
};
export type OrganizationsAddressesCreateApiResponse = /** status 201  */ OrganizationAddress;
export type OrganizationsAddressesCreateApiArg = {
    organizationId: number;
    createOrganizationAddressRequest: CreateOrganizationAddressRequest;
};
export type OrganizationsAddressesRetrieveApiResponse = /** status 200  */ OrganizationAddress;
export type OrganizationsAddressesRetrieveApiArg = {
    id: number;
    organizationId: number;
};
export type OrganizationsAddressesPartialUpdateApiResponse = /** status 200  */ OrganizationAddress;
export type OrganizationsAddressesPartialUpdateApiArg = {
    id: number;
    organizationId: number;
    patchedOrganizationAddressRequest: PatchedOrganizationAddressRequest;
};
export type OrganizationsAddressesDestroyApiResponse = unknown;
export type OrganizationsAddressesDestroyApiArg = {
    id: number;
    organizationId: number;
};
export type OrganizationsRetrieveApiResponse = /** status 200  */ Organization;
export type OrganizationsRetrieveApiArg = {
    id: number;
};
export type OrganizationsPartialUpdateApiResponse = /** status 200  */ Organization;
export type OrganizationsPartialUpdateApiArg = {
    id: number;
    patchedOrganizationUpdateRequest: PatchedOrganizationUpdateRequest;
};
export type OrganizationsDestroyApiResponse = unknown;
export type OrganizationsDestroyApiArg = {
    id: number;
};
export type OrganizationsSuggestListApiResponse = /** status 200  */ SuggestOrganization[];
export type OrganizationsSuggestListApiArg = void;
export type CountryEnum =
    | 'AF'
    | 'AL'
    | 'DZ'
    | 'AD'
    | 'AR'
    | 'AO'
    | 'AI'
    | 'AQ'
    | 'AM'
    | 'AW'
    | 'AU'
    | 'AT'
    | 'AZ'
    | 'BD'
    | 'BB'
    | 'BY'
    | 'BE'
    | 'BZ'
    | 'BJ'
    | 'BM'
    | 'BT'
    | 'BO'
    | 'BA'
    | 'BW'
    | 'BR'
    | 'BG'
    | 'BL'
    | 'BH'
    | 'CV'
    | 'KH'
    | 'KW'
    | 'CA'
    | 'CL'
    | 'CN'
    | 'CO'
    | 'CD'
    | 'CR'
    | 'HR'
    | 'CU'
    | 'CY'
    | 'CZ'
    | 'DK'
    | 'DO'
    | 'EC'
    | 'EG'
    | 'SV'
    | 'EE'
    | 'ET'
    | 'FI'
    | 'FR'
    | 'GE'
    | 'DE'
    | 'GI'
    | 'GR'
    | 'GL'
    | 'GP'
    | 'GT'
    | 'HT'
    | 'HN'
    | 'HK'
    | 'HU'
    | 'IS'
    | 'IN'
    | 'ID'
    | 'IR'
    | 'IQ'
    | 'IE'
    | 'IL'
    | 'IT'
    | 'JM'
    | 'JO'
    | 'JP'
    | 'KZ'
    | 'KE'
    | 'KR'
    | 'KG'
    | 'LV'
    | 'LB'
    | 'LS'
    | 'LR'
    | 'LY'
    | 'LI'
    | 'LT'
    | 'LU'
    | 'MO'
    | 'MK'
    | 'MY'
    | 'MV'
    | 'MT'
    | 'MX'
    | 'MD'
    | 'MC'
    | 'ME'
    | 'MA'
    | 'NP'
    | 'NL'
    | 'NC'
    | 'NZ'
    | 'NI'
    | 'NG'
    | 'NF'
    | 'NO'
    | 'OM'
    | 'PK'
    | 'PA'
    | 'PY'
    | 'PE'
    | 'PH'
    | 'PL'
    | 'PT'
    | 'PR'
    | 'QA'
    | 'RO'
    | 'RU'
    | 'WS'
    | 'SM'
    | 'SA'
    | 'RS'
    | 'SG'
    | 'SK'
    | 'SI'
    | 'SO'
    | 'ZA'
    | 'ES'
    | 'LK'
    | 'SD'
    | 'SE'
    | 'CH'
    | 'TW'
    | 'TJ'
    | 'TH'
    | 'TO'
    | 'TN'
    | 'TR'
    | 'TM'
    | 'UG'
    | 'UA'
    | 'AE'
    | 'GB'
    | 'US'
    | 'UY'
    | 'UZ'
    | 'VU'
    | 'VE'
    | 'VN'
    | 'YE'
    | 'ZW';
export type NullEnum = null;
export type StateEnum =
    | 'AL'
    | 'AK'
    | 'AZ'
    | 'AR'
    | 'CA'
    | 'CO'
    | 'CT'
    | 'DE'
    | 'DC'
    | 'FL'
    | 'GA'
    | 'GU'
    | 'HI'
    | 'ID'
    | 'IL'
    | 'IN'
    | 'IA'
    | 'KS'
    | 'KY'
    | 'LA'
    | 'ME'
    | 'MD'
    | 'MA'
    | 'MI'
    | 'MN'
    | 'MS'
    | 'MO'
    | 'MT'
    | 'NE'
    | 'NV'
    | 'NH'
    | 'NJ'
    | 'NM'
    | 'NY'
    | 'NC'
    | 'ND'
    | 'OH'
    | 'OK'
    | 'OR'
    | 'PW'
    | 'PA'
    | 'PR'
    | 'RI'
    | 'SC'
    | 'SD'
    | 'TN'
    | 'TX'
    | 'UT'
    | 'VT'
    | 'VI'
    | 'VA'
    | 'WA'
    | 'WV'
    | 'WI'
    | 'WY'
    | 'AB'
    | 'BC'
    | 'MB'
    | 'NB'
    | 'NL'
    | 'NS'
    | 'NT'
    | 'NU'
    | 'ON'
    | 'PE'
    | 'QC'
    | 'SK'
    | 'YT';
export type BlankEnum = '';
export type AddressTypeEnum = 'delivery' | 'customer_company';
export type OrganizationAddress = {
    id: number;
    full_address: string;
    country: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city: string;
    street_address: string;
    apartment?: string;
    postcode?: string;
    company_name?: string;
    vat_number?: string;
    address_type: AddressTypeEnum;
    created_at: string;
    updated_at: string;
};
export type OrderStatusEnum =
    | 'created'
    | 'initial'
    | 'waiting_for_review'
    | 'firm_offer_sent'
    | 'placed'
    | 'po_provided'
    | 'waiting_for_payment'
    | 'on_hold'
    | 'free_for_manufacturing'
    | 'planning_confirmed'
    | 'manufacturing'
    | 'post_production'
    | 're_manufacturing'
    | 'ready_for_shipping'
    | 'shipped'
    | 'ready_for_pickup'
    | 'delivered'
    | 'po_payment_due'
    | 'cancelled_by_user'
    | 'cancelled_by_admin';
export type Organization = {
    id: number;
    billing_addresses?: OrganizationAddress[];
    delivery_addresses?: OrganizationAddress[];
    title: string;
    domain: string;
    tax_number?: string;
    description?: string;
    self_notes?: string;
    grace_period_days?: number;
    grace_period_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    vban_bank_transfer_enabled?: boolean;
    custom_tax_rate?: number | null;
    created_at: string;
    updated_at: string;
};
export type PaginatedOrganizationList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: Organization[];
};
export type OrganizationCreateRequest = {
    title: string;
    domain: string;
    tax_number?: string;
    description?: string;
    self_notes?: string;
    grace_period_days?: number;
    grace_period_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    vban_bank_transfer_enabled?: boolean;
    custom_tax_rate?: number | null;
};
export type PaginatedOrganizationAddressList = {
    count: number;
    next?: string | null;
    previous?: string | null;
    results: OrganizationAddress[];
};
export type CreateOrganizationAddressRequest = {
    country: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city: string;
    street_address: string;
    apartment?: string;
    postcode?: string;
    company_name?: string;
    vat_number?: string;
    address_type: AddressTypeEnum;
};
export type PatchedOrganizationAddressRequest = {
    country?: CountryEnum | NullEnum;
    state?: (StateEnum | BlankEnum | NullEnum) | null;
    city?: string;
    street_address?: string;
    apartment?: string;
    postcode?: string;
    company_name?: string;
    vat_number?: string;
    address_type?: AddressTypeEnum;
};
export type PatchedOrganizationUpdateRequest = {
    title?: string;
    domain?: string;
    tax_number?: string;
    description?: string;
    self_notes?: string;
    grace_period_days?: number;
    grace_period_trigger_status?: (OrderStatusEnum | BlankEnum | NullEnum) | null;
    vban_bank_transfer_enabled?: boolean;
    custom_tax_rate?: number | null;
};
export type SuggestOrganization = {
    title: string;
    domain: string;
    users_count: number;
};
export const {
    useOrganizationsListQuery,
    useOrganizationsCreateMutation,
    useOrganizationsAddressesListQuery,
    useOrganizationsAddressesCreateMutation,
    useOrganizationsAddressesRetrieveQuery,
    useOrganizationsAddressesPartialUpdateMutation,
    useOrganizationsAddressesDestroyMutation,
    useOrganizationsRetrieveQuery,
    useOrganizationsPartialUpdateMutation,
    useOrganizationsDestroyMutation,
    useOrganizationsSuggestListQuery,
} = injectedRtkApi;
