import { combineSlices, combineReducers } from '@reduxjs/toolkit';

import { appSlice } from '@ducks/app';
import { filtersReducer } from '@ducks/filters';
import { priceMatchReducer } from '@ducks/price-match';
import { priceTweakerReducer } from '@ducks/price-tweaker';
import { modelsUploadSlice } from '@ducks/models-upload';
import { calculationResultSlice } from '@ducks/price-match-result';
import { createMaterialSlice } from '@ducks/material-create';
import { editMaterialSlice } from '@ducks/material-edit';
import { machinesMaterialsListReducer, createMachineSlice } from '@ducks/machines-materials';
import { currentModelSlice, modelsSlice } from '@ducks/models';
import { systemMessagesListReducer } from '@ducks/system-messages';

import { authSlice, orderReducer } from '@entities';

import { modelsTableReducer } from '@modules/models/main/models-table/slice';
import { clientsTableReducer } from '@modules/clients/main/clients-tab/slice';
import { tableOrganizationsReducer } from '@modules/clients/main/organizations-tab/slice';
import { ordersTableReducer } from '@modules/orders/components/orders-table/slice';
import { lineItemsTableReducer } from '@modules/orders/line-items/line-items-table/slice';

import { dfApi } from '@services/df/baseApi';

/*
    Most often, queries are enough to solve a problem, but if there is logic relating to different components,
    complex async flow or you need data persistence at the browser level, then you can create a slice.

    Root reducer structure:

        export const rootReducer = combineSlices({
            domain/namespace: combineReducers({
                namespaceFeature1: reducer,
                namespaceFeature2: reducer,
                ...
            }),
            bigFeature: combineReducers({
                bigFeaturePart1: reducer,
                bigFeaturePart2: reducer,
                ...
            }),
            generic/uniqueFeature: reducer,
            ...
        });

    Maximum nesting depth is 2, if you need more, try to modularize reducer:
        1) https://www.reddit.com/r/reactjs/comments/166dl4x/comment/jyjfa8e/

    Slice naming convention:
        1) for single reducer 'uniqueFeature'
        2) for nested 'namespace/reducer'
 */

export const rootReducer = combineSlices({
    app: appSlice.reducer,
    auth: authSlice.reducer,
    filters: filtersReducer,
    priceMatch: priceMatchReducer,
    priceMatchCalculation: combineReducers({
        calculationResult: calculationResultSlice.reducer, // todo refactor + append to priceMatch
    }),
    priceTweaker: priceTweakerReducer,

    createMaterial: createMaterialSlice.reducer, // todo remove/ref + append to material as create
    editMaterial: editMaterialSlice.reducer, // todo remove/ref + append to material as edit

    machinesMaterials: combineReducers({
        list: machinesMaterialsListReducer,
    }),
    machine: combineReducers({
        create: createMachineSlice.reducer,
    }),
    orders: combineReducers({
        table: ordersTableReducer,
    }),
    lineItems: combineReducers({
        table: lineItemsTableReducer,
    }),
    order: orderReducer,
    organizations: combineReducers({
        table: tableOrganizationsReducer,
    }),
    clients: combineReducers({
        table: clientsTableReducer,
    }),
    models: combineReducers({
        models: modelsSlice.reducer, // todo remove
        table: modelsTableReducer,
        upload: modelsUploadSlice.reducer, // todo refactor
    }),
    currentModel: currentModelSlice.reducer, // todo refactor + append to models or rename key to 'model'
    systemMessages: combineReducers({
        list: systemMessagesListReducer,
    }),
    [dfApi.reducerPath]: dfApi.reducer,
});
