import { useMemo } from 'react';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, Grid } from '@chakra-ui/react';
import { FieldHandler, NonFieldErrors, setApiErrors } from '@shared/components/forms-v2';
import { FetchBaseQueryError } from '@services/df/baseApi';
import { ObtainCompaniesRequestRequest } from '@services/df/auth';
import { getAuthConfig } from './config';
import { AuthResetPasswordButton } from './auth-reset-password-button';

export type SignInForm = ObtainCompaniesRequestRequest;
type Request<Response> = (data: SignInForm) => Promise<Response>;

interface Props<Response> {
    formId: string;
    isSubmitting: boolean;
    request: Request<Response>;
}

export const AuthSignInForm = <Response,>({ formId, request, isSubmitting }: Props<Response>) => {
    const { fields, schema } = useMemo(() => getAuthConfig(), []);
    const formApi = useForm<SignInForm>({
        defaultValues: schema.getDefault(),
        resolver: yupResolver(schema),
    });

    const {
        control,
        handleSubmit,
        watch,
        trigger,
        setError,
        formState: { errors },
    } = formApi;

    const submit: SubmitHandler<SignInForm> = async (form, event) => {
        try {
            const response = await request(form);
        } catch (error) {
            const errors = (error as FetchBaseQueryError).data;
            setApiErrors(setError, errors);
        }
    };

    return (
        <FormProvider {...formApi}>
            <form id={formId} onSubmit={handleSubmit(submit)}>
                <Grid rowGap={[3, 4]}>
                    <FieldHandler field={fields.email} />
                    <FieldHandler
                        field={fields.password}
                        overridingProps={{
                            rightElement: (
                                <AuthResetPasswordButton email={watch('email')} trigger={trigger} />
                            ),
                        }}
                    />
                    <NonFieldErrors errors={errors} />
                    <Button size="sm" justifySelf="flex-end" type="submit" isLoading={isSubmitting}>
                        Sign in
                    </Button>
                </Grid>
            </form>
        </FormProvider>
    );
};
