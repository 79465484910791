import { is4AxisMilling } from '@shared/utils/technology-guard';
import { Technologies, TECHNOLOGY_SLUG_MAP, TechnologyIdsUnion } from '@shared/constants';

export const getMachineSlugByTechnology = (
    id: number,
    { interchangeable4Milling = false } = {},
) => {
    const correctId =
        interchangeable4Milling && is4AxisMilling(id) ? Technologies['3-Axis Milling'] : id;
    return TECHNOLOGY_SLUG_MAP[correctId as TechnologyIdsUnion];
};
