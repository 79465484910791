import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@app/types';

// todo move to entities/order/model(state/slice)

type Data = number[];

const initialState: {
    selected: Data;
} = {
    selected: [],
};

export const purchasesSlice = createSlice({
    name: 'order/purchases',
    initialState,
    reducers: {
        setSelected(state, action: PayloadAction<Data>) {
            state.selected = action.payload;
        },
        resetSelected(state) {
            state.selected = [];
        },
    },
});

export const { setSelected: setSelectedPurchases, resetSelected: resetSelectedPurchases } =
    purchasesSlice.actions;
export const selectSelectedPurchases = (state: RootState) => state.order.purchases.selected;
