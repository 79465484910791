import { MouseEventHandler } from 'react';
import { Wrap, WrapItem, Tag, TagProps, TagLabel, TagCloseButton } from '@chakra-ui/react';
import { isAttachedLink } from './helpers';
import { AttachedObject } from './types';

const styles: TagProps = {
    colorScheme: 'primary',
    size: 'sm',
    maxW: '28',
    textTransform: 'uppercase',
};

interface Props {
    attachments: Array<AttachedObject>;
    handleRemove?: (file: AttachedObject, index: number) => MouseEventHandler;
}

export const FilesList = ({ attachments, handleRemove }: Props) => {
    return (
        <Wrap>
            {attachments.map((attachment, i) => {
                const remove = (
                    <TagCloseButton onClick={handleRemove && handleRemove(attachment, i)} />
                );

                return isAttachedLink(attachment) ? (
                    <WrapItem key={attachment.url}>
                        <Tag {...styles} title={attachment.name}>
                            <TagLabel
                                // textDecoration="underline" // does not work, because of 'isTruncated' prop into TagLabel
                                as="a"
                                href={attachment.url}
                                target="_blank"
                            >
                                {attachment.name}
                            </TagLabel>
                            {remove}
                        </Tag>
                    </WrapItem>
                ) : (
                    <WrapItem key={attachment.uuid}>
                        <Tag {...styles} title={attachment.file.name}>
                            <TagLabel>{attachment.file.name}</TagLabel>
                            {!attachment.isSubmitting && remove}
                        </Tag>
                    </WrapItem>
                );
            })}
        </Wrap>
    );
};
