import React from 'react';
import { createContext } from '@chakra-ui/react-utils';
import { useAppSelector } from '@app/hooks';
import { selectCurrency } from '@ducks/app';
import { FieldHandler } from '@shared/components/forms-v2';
import { FCC } from '@shared/types';
import {
    MachineFormConfigType,
    MachineCommonFields,
    getMachineFormConfigByTechnology,
} from '../config';

const [MachineFormConfigProvider, useMachineFormConfig] = createContext<MachineFormConfigType>({
    name: 'MachineFormConfigContext',
});

export { useMachineFormConfig };

interface Props {
    technologyId?: number;
    isCreation?: boolean;
}

export const MachineFormConfig: FCC<Props> = ({ children, technologyId, isCreation = false }) => {
    const currency = useAppSelector(selectCurrency)!;
    const value = React.useMemo(
        () =>
            getMachineFormConfigByTechnology({
                currencySign: currency.symbol,
                technologyId,
                isCreation,
            }),
        [currency, technologyId, isCreation],
    );
    return <MachineFormConfigProvider value={value}>{children}</MachineFormConfigProvider>;
};

interface MachineFieldProps {
    name: MachineCommonFields;
    injectedProps?: any;
    overridingProps?: any;
}

export const MachineField = ({ name, injectedProps, overridingProps }: MachineFieldProps) => {
    const { fields } = useMachineFormConfig();
    const field = fields?.[name];
    return field ? (
        <FieldHandler
            field={field}
            injectedProps={injectedProps}
            overridingProps={overridingProps}
        />
    ) : null;
};
