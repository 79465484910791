import { PropsConfigs } from 'chakra-dayzed-datepicker/src/utils/commonTypes';

export const DATEPICKER_DEFAULT_STYLES: PropsConfigs = {
    dayOfMonthBtnProps: {
        defaultBtnProps: {
            colorScheme: 'primary',
            _hover: { color: 'white', background: 'primary.default' },
        },
        isInRangeBtnProps: { background: 'primary.subtlest' },
        selectedBtnProps: { background: 'primary.subtle' },
        todayBtnProps: {
            outline: '2px solid !important',
            outlineColor: 'primary.default !important',
        },
    },
};

export const DEFAULT_DATE_FORMAT = 'MMM. dd, yyyy'; // todo DEFAULT_DATE_VIEW_FORMAT
