import { ModalFooter, Button, HStack } from '@chakra-ui/react';
import { PaginationWithQuery } from '@shared/components/pagination';
import { getNodeIdComposer } from '@shared/utils/strings';
import { FCC } from '@shared/types';

interface Props {
    sectionId: string;
    isDisabled?: boolean;
    total?: number;
    onConfirm: () => void;
}

export const ModelsLibraryFooter: FCC<Props> = ({
    sectionId,
    isDisabled = false,
    total = 0,
    onConfirm,
    children,
}) => {
    const _id = getNodeIdComposer(sectionId);

    return (
        <ModalFooter justifyContent="space-between">
            <HStack spacing="24px">
                <Button
                    id={_id('confirm_models')}
                    onClick={onConfirm}
                    variant="outline"
                    isDisabled={isDisabled}
                >
                    confirm models
                </Button>
                {children}
            </HStack>
            <PaginationWithQuery total={total} />
        </ModalFooter>
    );
};
