import { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { SelectFieldProps } from '@shared/components/forms-v2';
import { getInitialState, getStates } from './helpers';

export const useAddressStateField = (
    countryFieldProps?: Omit<SelectFieldProps, 'name'>,
    stateFieldProps?: Omit<SelectFieldProps, 'name'>,
) => {
    const { getValues, setValue } = useFormContext();
    const [country, state] = useWatch({ name: ['country', 'state'] });

    const countryProps = useMemo(
        () => ({
            ...countryFieldProps,
            onChange: () => {
                const country = getValues('country');
                const prevState = getValues('state');
                const state = getInitialState(country);

                if (state !== prevState) {
                    setValue('state', state, { shouldDirty: true });
                }
            },
        }),
        [countryFieldProps, getValues, setValue],
    );

    const stateProps = useMemo(
        () => ({
            ...stateFieldProps,
            options: getStates(country!).map(({ name, code }) => ({ label: name, value: code })),
        }),
        [stateFieldProps, country],
    );

    return { country, state, countryProps, stateProps };
};
