import React, { createElement } from 'react';
import { BoxProps, Image } from '@chakra-ui/react';
import useObjectURL from 'use-object-url';
import { isImage } from './helpers';

interface Props extends BoxProps {
    file: File;
}

export const ImagePreview = ({ file, ...rest }: Props) => {
    const objectURL = useObjectURL(file)!;
    const title = `Preview of ${file.name}`;

    return createElement(Image, {
        src: objectURL,
        alt: title,
        title,
        onLoad: () => {
            objectURL && URL.revokeObjectURL(objectURL);
        },
        ...rest,
    });
};

export const FilePreview = ({ file, ...rest }: Props) => {
    return isImage(file) ? <ImagePreview file={file} {...rest} /> : null;
};
