import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import React, {
    ChangeEventHandler,
    MutableRefObject,
    ReactNode,
    forwardRef,
    useCallback,
    useState,
} from 'react';
import { HStack, Input, Text } from '@chakra-ui/react';
import {
    ActionMeta,
    GroupBase,
    OnChangeValue,
    SelectInstance,
    MultiValueProps,
    chakraComponents,
} from 'chakra-react-select';
import { dot } from '@app/styles/helpers';
import {
    Select,
    SelectProps,
    SelectComponent,
    OptionType,
    customizedSelectComponents,
} from './select';

export interface ColourOption extends OptionType {
    color: string;
    __isNew__?: boolean;
}

// we can't rely on the uuid of the colors because the backend always resets them on edit request,
// so we can't merge existing values and presets via uuid
export const createColorOption = ({ label, color }: { label: string; color: string }) => ({
    color,
    label,
    value: label.toLowerCase().replace(/\W/g, ''),
});

export const colorPresets = [
    {
        label: 'Aqua',
        color: '#00FFFF',
    },
    {
        label: 'Black',
        color: '#000000',
    },
    {
        label: 'Blue',
        color: '#0000FF',
    },
    {
        label: 'Fuchsia',
        color: '#FF00FF',
    },
    {
        label: 'Gray',
        color: '#808080',
    },
    {
        label: 'Green',
        color: '#008000',
    },
    {
        label: 'Navy',
        color: '#000080',
    },
    {
        label: 'Olive',
        color: '#808000',
    },
    {
        label: 'Orange',
        color: '#FFA500',
    },
    {
        label: 'Purple',
        color: '#800080',
    },
    {
        label: 'Red',
        color: '#FF0000',
    },
    {
        label: 'White',
        color: '#FFFFFF',
    },
    {
        label: 'Yellow',
        color: '#FFFF00',
    },
];

const defaultOptions = colorPresets.map(createColorOption);

const optionColorDotGap = '2';
const optionColorDotSize = '3.5';

const mergeOptions = (options: ColourOption[], by = 'value') => sortBy(uniqBy(options, by), by);

const MultiValue = (props: MultiValueProps<ColourOption, true, GroupBase<ColourOption>>) => {
    const { children, data, getValue, setValue, ...rest } = props;
    const handleChange: ChangeEventHandler<HTMLInputElement> = ({ target: { value: color } }) => {
        setValue(
            getValue().map(option => {
                if (option.value === data.value) {
                    return {
                        ...data,
                        color,
                    };
                }

                return option;
            }),
            'select-option',
        );
    };
    return (
        <chakraComponents.MultiValue data={data} getValue={getValue} setValue={setValue} {...rest}>
            <HStack spacing={1}>
                <HStack
                    position="relative"
                    w={optionColorDotSize}
                    h={optionColorDotSize}
                    overflow="hidden"
                    borderRadius="50%"
                >
                    <Input
                        type="color"
                        onChange={handleChange}
                        // onClick={e => e.stopPropagation()}
                        defaultValue={data.color}
                        // w="4em"
                        // h="4em"
                        w={optionColorDotSize}
                        h={optionColorDotSize}
                        padding="0"
                        position="absolute"
                        top="50%"
                        left="50%"
                        transform="translate(-50%,-50%)"
                    />
                </HStack>

                <span>{children}</span>
            </HStack>
        </chakraComponents.MultiValue>
    );
};

const customizedColorsSelectComponents = {
    ...customizedSelectComponents,
    MultiValue,
};

export type ColorsSelectProps = SelectProps<
    SelectComponent,
    ColourOption,
    true,
    GroupBase<ColourOption>
>;

export const ColorsSelect = forwardRef(
    (
        {
            value,
            defaultValue,
            onChange: propsOnChange,
            chakraStyles = {},
            ...props
        }: ColorsSelectProps,
        ref:
            | ((
                  instance: SelectInstance<ColourOption, true, GroupBase<ColourOption>> | null,
              ) => void)
            | MutableRefObject<SelectInstance<ColourOption, true, GroupBase<ColourOption>> | null>
            | null,
    ) => {
        const [options, setOptions] = useState(() => {
            const initValues = Array.isArray(value)
                ? value
                : Array.isArray(defaultValue)
                ? defaultValue
                : [];

            return mergeOptions([...initValues.map(createColorOption), ...defaultOptions]);
        });
        const onChange = useCallback(
            (newValue: OnChangeValue<ColourOption, true>, actionMeta: ActionMeta<ColourOption>) => {
                // here we reset __isNew__ flag for created option
                // @ts-ignore
                setOptions(prev => mergeOptions([...newValue, ...prev].map(createColorOption)));
                propsOnChange?.(newValue, actionMeta);
            },
            [],
        );
        const { multiValueLabel, option } = chakraStyles;

        return (
            <Select
                ref={ref}
                component={SelectComponent.Creatable}
                // menuIsOpen
                value={value}
                defaultValue={defaultValue}
                options={options}
                onChange={onChange}
                getNewOptionData={(inputValue: string, createLabel: ReactNode) => ({
                    ...createColorOption({ label: inputValue, color: '#ffffff' }),
                    label: createLabel,
                    __isNew__: true,
                })}
                components={customizedColorsSelectComponents}
                // noOptionsMessage={() => (
                //     <Text textStyle="typography-xs" color="font.100" fontWeight="700">
                //         Color not found
                //     </Text>
                // )}
                chakraStyles={{
                    ...chakraStyles,
                    multiValueLabel: (provided, props) => ({
                        ...provided,
                        fontWeight: 400,
                        ...multiValueLabel?.(provided, props),
                    }),
                    option: (provided, { data, ...rest }) => {
                        return {
                            ...provided,
                            ...(data.__isNew__
                                ? {}
                                : dot(data.color, optionColorDotGap, optionColorDotSize)),
                            ...option?.(provided, { data, ...rest }),
                        };
                    },
                }}
                {...props}
                isMulti
            />
        );
    },
);
