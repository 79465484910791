import * as yup from 'yup';
import { FieldConfigs } from '../types';

export const getSingleFieldConfig = <V>(config: FieldConfigs, value: V) => {
    const { name: fieldName, scheme } = config;
    return {
        field: config,
        data: { [fieldName]: value },
        schema: yup.object({ [fieldName]: scheme! }),
    };
};
