import { createSelector } from '@reduxjs/toolkit';
import { RootState } from '@app/types';
import { enhancedApi as api, KanbanDashboardConfig, WidgetUrlTemplates } from '@services/df/app';
import { DEFAULT_CURRENCY, DEFAULT_KANBAN_DASHBOARD_CONFIG } from './constants';

// app settings fields
export const appSettingsSelector = api.endpoints.backofficeSettingsRetrieve.select();
export const selectSettings = (state: RootState) => appSettingsSelector(state).data;
export const selectTimezone = (state: RootState) => {
    const timezone = selectSettings(state)?.timezone;
    return timezone ? timezone : 'UTC'; // server may respond with an empty string
};
export const selectCompanyName = (state: RootState) => selectSettings(state)?.company_title;
export const selectLanguages = createSelector(selectSettings, settings => {
    return settings?.lang_codes.map(language => {
        const [code, name] = Object.entries(language)[0];
        return {
            code,
            name,
        };
    });
});
export const selectKanbanConfig = (state: RootState) =>
    (selectSettings(state)?.kanban_dashboard_config as Required<KanbanDashboardConfig>) ||
    DEFAULT_KANBAN_DASHBOARD_CONFIG;
export const selectCurrency = (state: RootState) =>
    selectSettings(state)?.currency || DEFAULT_CURRENCY;
export const selectIsTrialsLeft = (state: RootState) =>
    (selectSettings(state)?.trials_left ?? 0) > 0;
export const selectMaterialTags = (state: RootState) => selectSettings(state)?.tags;
export const selectDefaultComplexityBracketingBoundaries = (state: RootState) =>
    selectSettings(state)?.default_boundaries;
export const selectMaterialTagOptions = createSelector(selectMaterialTags, tags =>
    tags?.map(tag => ({ value: tag, label: tag })),
);
export const selectEmailTypes = (state: RootState) => selectSettings(state)?.email_types;
export const selectPdfCss = (state: RootState) => selectSettings(state)?.custom_pdf_css;
export const selectCompanyLocale = (state: RootState) => selectSettings(state)?.locale;
export const selectCompanyPricingPlanFeatures = (state: RootState) =>
    selectSettings(state)?.features;

// todo move to entities/order/model
export const selectOrderStatuses = (state: RootState) =>
    selectSettings(state)?.order_status_options;

export const selectOrderStatusButtons = createSelector(selectOrderStatuses, statuses =>
    statuses?.map(({ code: value, label, custom_label }) => ({
        children: custom_label || label,
        value,
    })),
);
export const selectOrderStatusOptions = createSelector(selectOrderStatuses, statuses =>
    statuses?.map(({ code: value, label, custom_label }) => ({
        label: custom_label || label,
        value,
    })),
);

export const selectNnwUrlComposer = createSelector(selectSettings, settings => {
    type Args = Array<string | number>;

    function buildPathname(template: string, ...args: Args) {
        for (let i = 0; i < args.length; i++) {
            template = template.replaceAll(`{${i}}`, args[i].toString());
        }

        return template;
    }

    return (name: keyof WidgetUrlTemplates, ...args: Args) => {
        const { company_title, widget_site_url, widget_url_templates } = settings!;
        const urlObj = new URL(widget_site_url);
        const template = widget_url_templates[name];
        urlObj.pathname = buildPathname(template, company_title, ...args);
        return urlObj;
    };
});
