import flatten from 'lodash/flatten';
import { object } from 'yup';
import { FieldConfigs } from '../types';

export function reduceFormsScheme(...forms: Array<FieldConfigs[]>) {
    const schemaMap = flatten(forms).reduce(
        (acc, { name, scheme }) => Object.assign(acc, { [name]: scheme }),
        {},
    );

    return object(schemaMap);
}
